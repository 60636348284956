import React from 'react'
import { ContentConsumer } from '../../language'
import CMSContent from '../CMSContent/CMSContent'

const CONTENT_PREM_ERROR = 'PREM_ERROR_404'

const ErrorPage = () => (
  <ContentConsumer>
    {({ locale }) => (
      <CMSContent pageid={CONTENT_PREM_ERROR} cache={true} language={locale} />
    )}
  </ContentConsumer>
)

export default ErrorPage