import { access as GratisHPAccess } from '../../services/wsGratisHP'
import userRoles from '../../globals/userRoles'

export const isTeacherRoleAsync = (userInfo) => {
  return new Promise(resolve => {
    resolve(userInfo && (userInfo.role === userRoles.TEACHER))
  })
}

export const hasAccessToGratisHP = () => {
  return new Promise((resolve, reject) => {

    const callback = (error, data) => {
      if(error) return reject(error)
      return resolve(data.access === 0)
    }

    GratisHPAccess(callback)
  })
}

export const hasGXA = (userInfo) => {
  return new Promise(resolve => {
    resolve(userInfo && userInfo.gpaPermission)
  })
}

export const isTeacherDemoAsync = (userInfo) => {
  return new Promise(resolve => {
    resolve(userInfo && (userInfo.role === userRoles.TEACHER_DEMO))
  })
}
