import axios from 'axios';
import axiosConfig from '../config/axios';

const moveTeacherSchool = (idprofesor, idschool, premiumcode, cb) => {
    setChangeSchool(idprofesor, idschool, premiumcode, (err, data) => {
        if (err)
            cb(err)
        else
            cb(null, data)
    })
};

function setChangeSchool(idProfesor, idCentro, premiumCode, cb) {
    var headers = {
        'idcentro': idCentro,
        'premiumcode': premiumCode
    }
    var axiosInstance = axios.create(axiosConfig);
    // axiosInstance.post('/teacher/' + idProfesor, headers)
    axiosInstance.post('/teacher/', headers)
        .then((response) => {
            cb(null, response)
        })
        .catch((error) => {
            cb(error)
        })
}

export default moveTeacherSchool;