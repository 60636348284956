import React from 'react'
import GpaContact from '../GpaContact/GpaContact';
import { header_student, header_classroom, header_teacher } from '../../utils/gpa-utils'
import { parseToReactElement } from '../../utils/html'

const renderHeaderInput = (label, value, handler, type) => (
  <div className="row form-group">
    <label className="col-7 control-label">{label}:</label>
    <div className="col-4">
      <div className="centered">
        <div className="form-element">
          <input className="form-control" type="text" value={value} maxLength="4" size="20" placeholder="" onChange={event => handler(event, type)} />
        </div>
      </div>
    </div>
  </div>
)

const GpaOrderHeader = ({ students, classrooms, teachers, editableMaterial, onChangeHeader, content }) => {
  if (onChangeHeader) {
    return (
      <div>
        <p className="description">{parseToReactElement(content.gratisadoption.gxa_24)}:</p>
        {renderHeaderInput(content.gratisadoption.gxa_25, students, onChangeHeader, header_student)}
        {renderHeaderInput(content.gratisadoption.gxa_26, classrooms, onChangeHeader, header_classroom)}
        {renderHeaderInput(content.gratisadoption.gxa_27, teachers, onChangeHeader, header_teacher)}
      </div>
    )
  }
  return (
    <div>
      <p className="description">{content.gratisadoption.gxa_38}:</p>
      <div className="row recursos-centro justify-content-around">
        <div className="col-sm-12 col-md-12 col-lg-4 text-center">
          <span className="text-label">{content.gratisadoption.gxa_25}:&nbsp;</span>
          <span className="text-number">{students}</span>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 text-center">
          <span className="text-label">{content.gratisadoption.gxa_26}:&nbsp;</span>
          <span className="text-number">{classrooms}</span>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 text-center">
          <span className="text-label">{content.gratisadoption.gxa_27}:&nbsp;</span>
          <span className="text-number">{teachers}</span>
        </div>
      </div>
      {editableMaterial && (
        <div className='mt-4'>
          <p className="small">{content.gratisadoption.gxa_39}:</p>
          <p className="small">{content.gratisadoption.gxa_40}&nbsp;<GpaContact>{content.gratisadoption.gxa_36}</GpaContact></p>
        </div>
      )}
    </div>
  )
}

export default GpaOrderHeader