import React, { Fragment } from 'react'
import { Container, Row, Col, Alert, Form, Input } from 'reactstrap'
import ButtonPremium from '../../ButtonPremium/ButtonPremium'

const UserFinder = ({ mail, onChangeMail, onSubmitMail, error, content }) => (
  <Fragment>
    <Container className='user-finder'>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          {error && <Alert color='danger'>{error}</Alert>}
          <Form onSubmit={onSubmitMail}>
            <Container>
              <Row>
                <Col sm='10' className='pt-2'>
                  <Input
                    type='text' name='mail' id='mail' placeholder='USER MAIL' value={mail}
                    onChange={onChangeMail} autoComplete='off' tabIndex='1' invalid={error !== null} />
                </Col>
                <Col sm='2'>
                  <ButtonPremium type='submit' tabIndex='2' primary>Search</ButtonPremium>
                </Col>
              </Row>
            </Container>
          </Form>
        </Col>
      </Row>
    </Container>
  </Fragment>
)

export default UserFinder
