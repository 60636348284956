import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'
import { withContent } from '../../utils/hocs'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import { parseToReactElement } from '../../utils/html'
import _ from 'lodash'
import qs from 'qs'
import validator from 'validator'
import { interpolate, PLACEHOLDERS } from '../../language'
import envSettings from '../../globals/envSettings'

class ThirdPartiesError extends Component {
  // MBQ GOOGLE/MS SIGN IN AND REGISTER: Deep linking
  constructor(props) {
    super(props)
    this.state = {
      returnTo: '',
      signin: ''
    }
  }

  // MBQ GOOGLE/MS SIGN IN AND REGISTER: Deep linking
  componentDidMount() {
    const { returnTo } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    if (returnTo && validator.isURL(returnTo) && _.startsWith(returnTo, envSettings.APP_BASEURL, 0)) {
      this.setState({
        returnTo: encodeURIComponent(returnTo),
        signin: /oauth2-signin/.test(returnTo) ? _.replace(returnTo, new RegExp(`^${envSettings.APP_BASEURL}($|/)`), '/') : ''
      })
    }
  }

  render() {
    const { content } = this.props
    // MBQ GOOGLE/MS SIGN IN AND REGISTER: Deep linking
    const { returnTo, signin } = this.state

    return (
      <Container fluid={false} className="ThirdPartiesError">
        <Row>
          <Col md={{ size: 8, offset: 2 }}><h1>{content.useraccount.redirection_title}</h1></Col>
        </Row>
        <Row>
          <Col md={{ size: 8, offset: 2 }} className="text-center mt-3">
            <p>
              {/* parseToReactElement(content.useraccount.redirection_user_found, null,{ ADD_ATTR: ['target'] }) */}
              {parseToReactElement(
                interpolate(content.useraccount.redirection_user_found, PLACEHOLDERS.LINK_URL, signin ? `/app${signin}` : returnTo ? `/app/signin?returnTo=${returnTo}` : '/app/signin'),
                null, { ADD_ATTR: ['target'] })}
            </p>
          </Col>
        </Row>

        <Row>
          <Col className="text-center mt-3">{parseToReactElement(content.useraccount.redirection_forgot_password_text)}</Col>
        </Row>

        <Row>
          <Col className="text-center mt-3 mb-2">{parseToReactElement(content.useraccount.redirection_contact_us_text)}</Col>
        </Row>

        <Row className="mt-5">
          <Col md={{ size: 6, offset: 3 }} className="text-center">
            <Link className="link__help" to="/help">
              {content.sharedTerms.HELP_LINK}<span className="glyphicon glyphicon-question-sign"></span>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <Container>
              <Row>
                <Col className="mt-4">
                  {/*<ButtonPremium onClick={() => this.props.history.push('/register')}>{content.signin.signin_register_button}</ButtonPremium>*/}
                  <ButtonPremium onClick={() => this.props.history.push(returnTo ? `/register?returnTo=${returnTo}` : '/register')}>{content.signin.signin_register_button}</ButtonPremium>
                </Col>
                <Col className="mt-4">
                  {/*<ButtonPremium tabIndex="3" primary onClick={() => this.props.history.push("/signin")}>{content.signin.signin_button}</ButtonPremium>*/}
                  <ButtonPremium tabIndex="3" primary onClick={() => this.props.history.push(signin ? signin : returnTo ? `/signin?returnTo=${returnTo}` : '/signin')}>
                    {content.signin.signin_button}
                  </ButtonPremium>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(withContent(ThirdPartiesError))