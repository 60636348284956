import React, { Component } from 'react'
import { Container, Row, Col, Label, Input } from 'reactstrap'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import axios from 'axios'
import axiosConfig from '../../config/axios'

class NotificationGpa extends Component {
  constructor(props) {
    super(props)
    this.state = {
      noShowModal: false
    }
  }

  startGpa = () => {
    this.props.toggleModal(setTimeout(this.props.handleSubmit, 500))
  }

  handleSubmit = () => {
    if (this.state.noShowModal) {
      axios.create(axiosConfig).put('/notifications/gpa', { noShowModal: this.state.noShowModal })
        .then(response => {
          //console.log('update notification gpa', response)
          this.startGpa()
        })
        .catch(error => {
          console.error('update notification gpa error :', error)
          this.startGpa()
        })
    }
    else {
      this.startGpa()
    }
  }

  handleCheckChange = () => (
    this.setState(prevState => (
      { noShowModal: !prevState.noShowModal }
    ))
  )

  render() {
    const { content, toggleModal } = this.props
    return (
      <Container fluid className="container-notification-gpa">
        <Row>
          <Col sm="12" className="mb-3 mt-5">
            <p>{content.notifications.notification_gpa_3}</p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="checkbox">
            <Label>
              <Input type="checkbox" checked={this.state.noShowModal} onChange={this.handleCheckChange} />{content.notifications.notification_gpa_4}
            </Label>
          </Col>
        </Row>
        <Row>
          <Col sm="6" className="mt-4">
            <ButtonPremium onClick={toggleModal}>{content.sharedTerms.CANCEL_BUTTON_TEXT}</ButtonPremium>
          </Col>
          <Col sm="6" className="mt-4">
            <ButtonPremium onClick={this.handleSubmit} primary>{content.sharedTerms.START_BUTTON_TEXT}</ButtonPremium>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default NotificationGpa