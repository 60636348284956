import React from 'react'

import envSettings from '../../../globals/envSettings'
import { icon } from '../../ResourceList/resource'

const InstallerItem = (props) => {
  const { installer, handleLightBox } = props
  const isBlank = str => !str || /^\s*$/.test(str)
  const isAnimation = resourceType => resourceType === '{http://www.oupe.es/model}animacion'

  return (
    <tr className="resourceList__TRitem">
      <th className="resourceList__THicon d-none d-md-table-cell" >{ icon(installer) }</th>
      <td className="resourceList__TDitem">
        <div className="d-flex flex-column resourceList__title">
          {(!isBlank(installer.previewURL) || !isBlank(installer.downloadURL)) && !isAnimation(installer.type) &&
            <button className="text-left" onClick={() => handleLightBox(installer.uuid)} >
              <span>{installer.name}</span>
            </button>
          }
          {!isBlank(installer.externalURL) &&
            <a href={installer.externalURL} target="_blank" rel="noopener noreferrer" title={installer.name}>
              <span>{installer.name}</span>
            </a>
          }
          {!isBlank(installer.previewURL) && isAnimation(installer.type) &&
            <a href={`${envSettings.API_BASEURL}${installer.previewURL}`} target="_blank" rel="noopener noreferrer" title={installer.name}>
              <span>{installer.name}</span>
            </a>
          }
          {(isBlank(installer.previewURL) && isBlank(installer.externalURL) && isBlank(installer.downloadURL)) &&
            <span>{installer.name}</span>
          }
          <div className="mt-2">
            <span className="resourceList__title__unit border border-info rounded px-2 py-1 d-inline-block">{installer.unitName}</span>
          </div>
        </div>
      </td>
      <td className="resourceList__TDMulti d-none d-md-table-cell">
        {!isBlank(installer.downloadURL) &&
          <a href={`${envSettings.API_BASEURL}${installer.downloadURL}`} title={installer.name} >
            <span className="glyphicon glyphicon-download-alt" aria-hidden="true"></span>
          </a>
        }
      </td>
      <td className="resourceList__TDMulti d-none d-md-table-cell">
        {(!isBlank(installer.previewURL) || !isBlank(installer.downloadURL)) && !isAnimation(installer.type) &&
          <button onClick={() => handleLightBox(installer.uuid)}>
            <span className="glyphicon glyphicon-eye-open" aria-hidden="true"> </span>
          </button>
        }
        {!isBlank(installer.externalURL) &&
          <a href={installer.externalURL} title={installer.name}>
            <span className="glyphicon glyphicon-eye-open" aria-hidden="true"> </span>
          </a>
        }
        {!isBlank(installer.previewURL) && isAnimation(installer.type) &&
          <a href={`${envSettings.API_BASEURL}${installer.previewURL}`} target="_blank" rel="noopener noreferrer" title={installer.name}>
            <span className="glyphicon glyphicon-eye-open" aria-hidden="true"> </span>
          </a>
        }
        {(isBlank(installer.previewURL) && isBlank(installer.externalURL) && isBlank(installer.downloadURL)) &&
          <span className="glyphicon glyphicon-eye-open" aria-hidden="true"> </span>
        }
      </td>
      <td className="resourceList__TDLast"></td>
    </tr>
  )
}

export default InstallerItem

