import React from 'react'
import _ from 'lodash'
import { Container, Row, Col } from 'reactstrap'
import ButtonCoupons from '../../ButtonCoupons/ButtonCoupons'
import Products from './Products/Products'
import Resources from './Resources'
import ArrowSteps from './ArrowSteps'
import { parseToReactElement } from '../../../utils/html'
import { withContent } from '../../../utils/hocs'

import './TitlePage.css'

const TitlePage = ({
  content,
  title: { name, products, resources },
  token,
  onProductError,
  returnToLibrary,
  addToShoppingCart
}) => {
  const onReturnToLibrary = () => {
    products.filter((product) => product.new).forEach((product) => product.new = false)
    returnToLibrary()
  }

  return (
    <Container className="digitallibrary">
      <Row className="mt-3">
        <Col md="8" lg="10"><h1><span className="title-underlined"><strong>{name}</strong></span></h1></Col>
        <Col
          className="text-right"
          md="4"
          lg="2"
        >
          <ButtonCoupons primary onClick={onReturnToLibrary}>{_.get(content, 'coupons.digitallibrary_back', 'Volver atrás')}</ButtonCoupons>
        </Col>
      </Row>
      <Row className="mt-5 mb-2">
        <Col>
          <h3>
            <span>
              <strong>{_.get(content, 'coupons.digitallibrary_title_help')}</strong>
            </span>
          </h3>
        </Col>
      </Row>
      <Row className="my-2">
        <Col xs="12" lg="8" className="mr-lg-0 pr-lg-0">
          <ArrowSteps steps={[
            parseToReactElement(_.get(content, 'coupons.digitallibrary_help_step1')),
            parseToReactElement(_.get(content, 'coupons.digitallibrary_help_step2'))
          ]} />
        </Col>
        <Col xs="12" lg="4" className="w-100 ml-lg-0 pl-lg-0 helpcard-links-col">
          <div className='w-100 bg-white helpcard-links'>
            {parseToReactElement(_.get(content, 'coupons.digitallibrary_help_links'), null, { ADD_ATTR: ['target'] })}
          </div>
        </Col>
      </Row>
      <Row className="mt-5 mb-2">
        <Col>
          <h3>
            <span className="title-underlined">
              <strong>{_.get(content, 'coupons.digitallibrary_title_products')}</strong>
            </span>
          </h3>
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <Products
            token={token}
            products={products}
            onProductError={onProductError}
            addToShoppingCart={addToShoppingCart}
          />
        </Col>
      </Row>
      {resources && (resources.length > 0)
        ? (
          <>
            <Row className="my-3">
              <Col><h1>{_.get(content, 'coupons.digitallibrary_title_additionalresources', 'Recursos adicionales')}</h1></Col>
            </Row>
            <Row className="my-3">
              <Col><Resources resources={resources} /></Col>
            </Row>
          </>
        )
        : null}
    </Container>
  )
}

export default withContent(TitlePage)