import React, { Component, Fragment } from "react"
import { Progress } from 'reactstrap'
import { ContentConsumer } from '../../language'
import PDBanner from './PDBanner'
import PDMyCoursesTop from './PDMyCoursesTop'
import PDCatalogue from './PDCatalogue'
import PDCompletedCatalogue from './PDCompletedCatalogue'
import { getCatalogue, fillCurrentRegistration, TABS } from './utils'
import { withContent } from '../../utils/hocs'
import axios from 'axios'
import axiosConfig from '../../config/axios'

const axiosApi = axios.create(axiosConfig)

class PDTabMyCourses extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentRegistration: null,
      catalogue: [],
      loading: true
    }
  }

  getCompletedRegistrations = () => (
    axiosApi.get('/pd-courses/registrations?completed=1')
  )

  getCurrentAnnouncementData = announcementId => (
    Promise.all([
      this.getCompletedRegistrations(),
      axiosApi.get(`/pd-courses/announcement/${announcementId}/courses`),
      axiosApi.get(`/pd-courses/announcement/${announcementId}/registration`)
    ]).then(([{ data: completedRegistrations }, { data: courses }, { data: registration }]) => {
      if (!courses || courses.length === 0) {
        this.props.showError(TABS.ANNOUNCEMENT)
      } else {
        const catalogue = getCatalogue(this.props.cards, completedRegistrations, courses, registration)
        const currentRegistration = fillCurrentRegistration(registration, this.props.locale)
        this.setState({ currentRegistration, catalogue, loading: false })
      }
    })
      .catch(error => {
        console.error('PD-COURSES : ', error.toString())
        this.props.showError(TABS.ANNOUNCEMENT)
      })
  )

  getNonCurrentAnnouncementData = () => (
    this.getCompletedRegistrations()
      .then(({ data: completedRegistrations }) => {
        const catalogue = getCatalogue(this.props.cards, completedRegistrations)
        this.setState({ currentRegistration: null, catalogue, loading: false })
      })
      .catch(error => {
        console.error('PD-COURSES : ', error.toString())
        this.props.showError(TABS.ANNOUNCEMENT)
      })
  )

  componentDidUpdate(prevProps) {
    if (this.props.activeTab !== prevProps.activeTab && this.props.activeTab === TABS.MY_COURSES) {
      //console.log('WWWW componentDidUpdate TAB MY COURSES convocatoria ', this.props.announcement)
      if (this.props.announcement && this.props.announcement.isCurrent) {
        this.setState({ loading: true }, () => this.getCurrentAnnouncementData(this.props.announcement.id))
      } else {
        this.setState({ loading: true }, () => this.getNonCurrentAnnouncementData())
      }
    }
  }

  render() {
    const { currentRegistration, catalogue, loading } = this.state
    const { announcement, activeTab, showRegistration } = this.props

    return (
      <ContentConsumer>
        {({ content = {} }) => (
          content && (
            loading ?
              (
                <Progress animated color="primary" value="100">{content.pdcourses.loading}</Progress>
              )
              :
              (
                announcement === null || !announcement.isCurrent ?
                  (
                    <Fragment>
                      {
                        catalogue.length > 0 && (
                          <PDCatalogue
                            announcement={announcement}
                            cards={catalogue}
                            activeTab={activeTab}
                            className="pdTabAnnouncement" />

                        )
                      }
                      {
                        catalogue.length === 0 && (
                          <PDCompletedCatalogue moreCourses={announcement === null ? true : false} />
                        )
                      }
                    </Fragment>
                  )
                  :
                  (
                    <Fragment>
                      {!currentRegistration && catalogue.length === 0 && (
                        <PDCompletedCatalogue moreCourses={false} />
                      )}
                      {(currentRegistration || catalogue.length > 0) && (
                        <Fragment>
                          <PDBanner className="mycourses">
                            <PDMyCoursesTop registration={currentRegistration} announcement={announcement} showRegistration={showRegistration} />
                          </PDBanner>
                          {
                            catalogue.length > 0 && (
                              <PDCatalogue
                                announcement={announcement}
                                cards={catalogue}
                                activeTab={activeTab}
                                className="pdTabAnnouncement"
                                isCurrentRegistration={currentRegistration !== null} />
                            )
                          }
                          {
                            catalogue.length === 0 && (
                              <PDCompletedCatalogue moreCourses={true} />
                            )
                          }
                        </Fragment>
                      )}
                    </Fragment>
                  )
              )
          ))}
      </ContentConsumer>
    )
  }
}

export default withContent(PDTabMyCourses)