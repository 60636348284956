import React from 'react'
import _ from 'lodash'
import { Container, Row, Col } from 'reactstrap'
import cx from 'classnames'

import ButtonCoupons from '../ButtonCoupons/ButtonCoupons'
import { withContent } from '../../utils/hocs'
import { parseToReactElement } from '../../utils/html'
import { useScrollToTop } from '../../hooks'

const RedeemCouponError = (props) => {
  useScrollToTop()

  const { content, errorCode, onAccept } = props

  let isGeneric = false
  let title = ''
  let body = ''
  let button = ''

  if(errorCode === 400011) {
    title = _.get(content, 'coupons.redemption_error_used_code_title')
    body = _.get(content, 'coupons.redemption_error_used_code_body')
    button = _.get(content, 'coupons.redemption_error_used_code_button')
  } else if(errorCode === 400012) {
    title = _.get(content, 'coupons.redemption_error_code_not_redimable_yet_title')
    body = _.get(content, 'coupons.redemption_error_code_not_redimable_yet_body')
    button = _.get(content, 'coupons.redemption_error_code_not_redimable_yet_button')
  } else if(errorCode === 500998) {
    title = _.get(content, 'coupons.redemption_error_activation_title')
    body = _.get(content, 'coupons.redemption_error_activation_body')
    button = _.get(content, 'coupons.redemption_error_activation_button')
  } else {
    title = _.get(content, 'coupons.redemption_error_generic_title')
    body = _.get(content, 'coupons.redemption_error_generic_body')
    button = _.get(content, 'coupons.redemption_error_generic_button')
    isGeneric = true
  }
  return (
    <Container>
      { isGeneric && (
        <Row className={cx('my-3', { 'text-center': isGeneric })}>
          <Col md={{ size: 2, offset: 5 }}>
            <img
              className="w-75"
              src="https://online.oupe.es/OUP/Biblioteca/Iconos/manzana_error.png"
              alt="Error"
            />
          </Col>
        </Row>
      )}
      <Row className={cx('my-3', { 'text-center': isGeneric })}><Col><h1>{ title }</h1></Col></Row>
      <Row className={cx('my-3', { 'text-center': isGeneric })}><Col><p>{ parseToReactElement(body, null, { ADD_ATTR: ['target'] }) }</p></Col></Row>
      <Row className={cx('my-3', { 'text-center': isGeneric })}>
        <Col md={isGeneric ? { size: 12 } : { size: 4 }}>
          <ButtonCoupons
            primary
            onClick={onAccept}
          >
            { button }
          </ButtonCoupons>
        </Col>
      </Row>
    </Container>
  )
}

export default withContent(RedeemCouponError)
