import React, { useEffect, useState } from 'react'
import { Container, Row, Col, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import _ from 'lodash'
import axios from 'axios'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { RecaptchaTerms } from '../../../Recaptcha/Recaptcha'
import ButtonCoupons from '../../../ButtonCoupons/ButtonCoupons'

import { withContent } from '../../../../utils/hocs'

import axiosConfig from '../../../../config/axios'

const axiosClient = axios.create({ ...axiosConfig, timeout: 60000 })

const RedemptionFormModal = ({
  content,
  googleReCaptchaProps,
  couponUser,
  updateLibrary,
  onSuccess,
  onGenericError,
  onNotRedimableYetError,
  onActivationError,
  onError,
  onClose
}) => {
  const [coupon, setCoupon] = useState('')
  const [provinces, setProvinces] = useState([])
  const [province, setProvince] = useState(0)

  useEffect(() => {
    axiosClient.get('/province')
      .then((res) => {
        setProvinces(res.data)
      })
      .catch(() => onError())
  }, [onError])

  const submit = async () => {
    if(coupon === '') return

    const { email, age, marketing } = couponUser
    const recaptchaToken = await googleReCaptchaProps.executeRecaptcha('coupons')

    axiosClient.post(`/coupons/${coupon}`, { email, token: recaptchaToken, age: !!age, marketing: !!marketing, province: Number(province), sendEmail: false })
      .then(() => {
        updateLibrary()
        onSuccess()
      })
      .catch((err) => {
        if(err.response.data.code === 400011) {
          setCoupon('')
          setProvince(0)
          onError()
        } else if(err.response.data.code === 400012) {
          setCoupon('')
          setProvince(0)
          onNotRedimableYetError()
        } else if(err.response.data.code === 500998) {
          setCoupon('')
          setProvince(0)
          onActivationError()
        } else {
          setCoupon('')
          setProvince(0)
          onGenericError()
        }
      })
  }

  return (
    <>
      <ModalHeader className="text-center">
        {_.get(content, 'coupons.modal_redemptionform_header')}
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row
            className="my-3"
          >
            <Col size="12">
              {_.get(content, 'coupons.modal_redemptionform_body')}
            </Col>
          </Row>
          <Row
            className="my-3"
          >
            <Col size="12">
              <Input
                type="text"
                id="code"
                name="code"
                autoComplete="off"
                value={coupon}
                onChange={({ target: { value } }) => setCoupon(value.toUpperCase())}
                placeholder={content.coupons.redemption_type_code}
              />
            </Col>
          </Row>
          <Row>
            <Col size="12">
              <Input
                id="province"
                type="select"
                name="province"
                className="input_text"
                autoComplete="off"
                value={province}
                onChange={({ target: { value } }) => setProvince(Number(value))}
              >
                <option key={0} value={0}>{content.coupons.redemption_select_province}</option>
                {
                  provinces.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))
                }
              </Input>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter className="mb-3">
        <Container>
          <Row>
            <Col
              xs="12"
              sm="6"
              className="text-center my-2"
            >
              <ButtonCoupons
                primary
                disabled={province === 0 || coupon === ''}
                onClick={submit}
              >
                {_.get(content, 'coupons.modal_redemptionform_accept')}
              </ButtonCoupons>
            </Col>
            <Col
              xs="12"
              sm="6"
              className="text-center my-2"
            >
              <ButtonCoupons
                onClick={onClose}
              >
                {_.get(content, 'coupons.modal_redemptionform_cancel')}
              </ButtonCoupons>
            </Col>
          </Row>
          <Row className="my-3"><Col><RecaptchaTerms className="text-xs" /></Col></Row>
        </Container>
      </ModalFooter>
    </>
  )
}

export default withGoogleReCaptcha(withContent(RedemptionFormModal))
