import React, { Component } from "react"
import {
  Container, Row, Col, Progress, Form, FormGroup, Label, Input, CustomInput
} from 'reactstrap'
import { ContentConsumer } from '../../language'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import {
  checkCompletedCourses, REGISTRATION_ACTION,
  MIN_BIRTHDAY, MAX_BIRTHDAY, validateBirthday
} from './utils'
import axios from 'axios'
import axiosConfig from '../../config/axios'
import _ from 'lodash'

const axiosApi = axios.create(axiosConfig)

class PDRegistrationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      courses: null,
      courseId: 0,
      birthdayOn: false,
      birthday: '',
      errors: {
        course: true,
        birthday: false
      },
      loading: true,
      success: false
    }
  }

  componentDidMount() {
    Promise.all([
      axiosApi.get(`/pd-courses/announcement/${this.props.announcement.id}/courses`),
      axiosApi.get('/pd-courses/registrations?completed=1')
    ]).then(([{ data: courses }, { data: registrations }]) => {
      if (!courses || courses.length === 0) {
        this.props.showError()
      } else {
        courses = checkCompletedCourses(courses, registrations)
        this.setState({ courses, loading: false })
      }
    })
      .catch(error => {
        console.error('PD-COURSES : ', error.toString())
        this.props.showError()
      })
  }

  handleInputChange = (event) => {
    event.preventDefault()
    const name = event.target.name
    const value = event.target.value
    switch (name) {
      case 'course':
        this.setState({
          courseId: value,
          errors: { ...this.state.errors, course: value <= 0 }
        })
        break
      case 'birthday':
        this.setState({
          birthday: value,
          errors: { ...this.state.errors, birthday: !validateBirthday(value) }
        })
        break
      default:
        break;
    }
  }

  toogleBirthday = () => (
    this.setState({
      birthdayOn: !this.state.birthdayOn,
      birthday: '',
      errors: { ...this.state.errors, birthday: !this.state.birthdayOn }
    })
  )

  validateForm = () => (
    !_.compact(_.values(this.state.errors)).length
  )

  submit = () => (
    this.setState({ loading: true }, () => (
      axiosApi.post('/pd-courses/registration',
        {
          action: REGISTRATION_ACTION.INSERT,
          courseId: this.state.courseId,
          birthday: this.state.birthday,
          master: this.getCourseMaster(),
          announcementKey: this.props.announcement.announcementKey
        }).then(() => {
          this.setState({ success: true, loading: false })
        }).catch(error => {
          console.error('PD-COURSES : ', error.toString())
          this.props.showError()
        })
    ))
  )

  getCourseName = () => {
    const { courses, courseId } = this.state
    const course = courses.find(course => (course.id + '') === (courseId + ''))
    return course ? course.name : ''
  }

  getCourseMaster = () => {
    const { courses, courseId } = this.state
    const course = courses.find(course => (course.id + '') === (courseId + ''))
    return course ? course.lmsMasterCourseId : ''
  }

  render() {
    const { courses, courseId, birthdayOn, birthday, loading, success } = this.state
    const { closeModal } = this.props

    return (
      <ContentConsumer>
        {({ content = {} }) => (
          content && (
            loading ?
              (
                <Progress animated color="primary" value="100">{content.pdcourses.loading}</Progress>
              )
              :
              (
                success ?
                  (
                    <Container fluid={true} className="pdRegistrationOk pl-md-5 pr-md-5">
                      <Row>
                        <Col className="text-center pt-5">
                          <h6>{content.pdcourses.register_ok_title}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center pt-2 pb-5 pdRegistrationOk__course">
                          <h4>{this.getCourseName()}</h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center pt-5">
                          <h6>{content.pdcourses.register_ok_mail}</h6>
                        </Col>
                      </Row>
                      <Row className="pt-5 pb-5">
                        <Col md={{ size: 4, offset: 4 }} className="text-center">
                          <ButtonPremium onClick={closeModal} primary>
                            {content.pdcourses.button_accept}
                          </ButtonPremium>
                        </Col>
                      </Row>
                    </Container>
                  )
                  :
                  (
                    <Container fluid={true} className="pdRegistrationForm">
                      <Row>
                        <Col className="text-left pt-5" md={{ size: 10, offset: 1 }}>
                          <Form noValidate>
                            <FormGroup>
                              <Label for="course" className="label-course">{content.pdcourses.register_form_courses}</Label>
                              <Input
                                type="select"
                                id="course"
                                name="course"
                                autoComplete="off"
                                value={courseId}
                                onChange={this.handleInputChange}
                                className={courseId === 0 || courseId === '0' ? 'selected-default' :
                                  courseId < 0 || Number.parseInt(courseId) < 0 ? 'disabled-course' : 'selected-course'}>
                                <option key={0} value={0} className='selected-default'>{content.pdcourses.register_form_courses_option}</option>
                                {courses.map(course => (
                                  <option
                                    key={course.completed || !course.registrationEnabled ? Number.parseInt(course.id) * -1 : Number.parseInt(course.id)}
                                    value={course.completed || !course.registrationEnabled ? Number.parseInt(course.id) * -1 : Number.parseInt(course.id)}
                                    className={course.completed || !course.registrationEnabled ? 'disabled-course' : 'selected-course'}>
                                    {course.name}
                                    {course.completed ? ` (${content.pdcourses.register_form_course_completed})` : !course.registrationEnabled ? ` (${content.pdcourses.register_form_course_closed})` : ''}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                            <FormGroup className="pt-3">
                              <CustomInput
                                type="checkbox"
                                id="certificate"
                                name="certificate"
                                label={content.pdcourses.register_form_certificate}
                                onClick={this.toogleBirthday}
                              />
                            </FormGroup>
                            {
                              birthdayOn && (
                                <FormGroup>
                                  <Label for="birthday" className="label-birthday">{content.pdcourses.register_form_birthday}</Label>
                                  <Input
                                    type="date"
                                    id="birthday"
                                    name="birthday"
                                    autoComplete="off"
                                    value={birthday}
                                    max={MAX_BIRTHDAY}
                                    min={MIN_BIRTHDAY}
                                    onChange={this.handleInputChange}
                                    placeholder={'dd/mm/aaaa'}
                                    pattern="\d{4}-\d{2}-\d{2}"
                                  />
                                </FormGroup>
                              )
                            }
                            <FormGroup>
                              <Row className="mt-5 mb-5">
                                <Col md="6" lg={{ size: 4, offset: 1 }} className="my-2">
                                  <ButtonPremium onClick={closeModal}>{content.pdcourses.button_cancel}</ButtonPremium>
                                </Col>
                                <Col md="6" lg={{ size: 4, offset: 1 }} className="my-2">
                                  <ButtonPremium
                                    disabled={!this.validateForm()}
                                    onClick={this.submit}
                                    primary
                                  >
                                    {content.pdcourses.button_register}
                                  </ButtonPremium>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  )
              )
          )
        )}
      </ContentConsumer>
    )
  }
}

export default PDRegistrationForm