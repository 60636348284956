import { isPreProEnv, isProductionEnv } from '../../globals/envSettings'

const devSettings = {
  PHP_BASEURL : 'https://help.oupe.es/wp-json/wp/v2/',
  PHP_MAX_ITEMS : 50,
  PHP_TIMEOUT: 5000,
  PHP_CATEGORY_ROOT: 396,
  PHP_CAROUSEL_ROOT: 397,
  PHP_CONTENT_ROOT: 398,
  PHP_LANGUAGES_ROOT: 310
}

const preproSettings = {
  PHP_BASEURL : 'https://help.oupe.es/wp-json/wp/v2/',
  PHP_MAX_ITEMS : 50,
  PHP_TIMEOUT: 5000,
  PHP_CATEGORY_ROOT: 421,
  PHP_CAROUSEL_ROOT: 423,
  PHP_CONTENT_ROOT: 422,
  PHP_LANGUAGES_ROOT: 310
}

const proSettings = {
  PHP_BASEURL : 'https://help.oupe.es/wp-json/wp/v2/',
  PHP_MAX_ITEMS : 50,
  PHP_TIMEOUT: 5000,
  PHP_CATEGORY_ROOT: 424,
  PHP_CAROUSEL_ROOT: 426,
  PHP_CONTENT_ROOT: 425,
  PHP_LANGUAGES_ROOT: 310
}

let settings = devSettings

if(isProductionEnv()) settings = proSettings
else if(isPreProEnv()) settings = preproSettings

/*Object.assign(settings, { PHP_WP_ES: 311
                        , PHP_WP_EN: 312
                        , PHP_WP_FR: 313
                        , PHP_WP_IT: 314
                        , PHP_WP_EU: 315
                        , PHP_WP_CA: 316
                        , PHP_WP_VA: 317
                        , PHP_WP_GL: 318})*/

Object.assign(settings, { 'es': 311
                        , 'en': 312
                        , 'fr': 313
                        , 'it': 314
                        , 'eu': 315
                        , 'ca': 316
                        , 'va': 317
                        , 'gl': 318})

export const ClientSettings = {
  baseURL: settings.PHP_BASEURL,
  timeout: settings.PHP_TIMEOUT,
  crossdomain: true,
  withCredentials: false,
  responseType: 'json',
  responseEncoding: 'utf8'
};

export default settings
