import React, { Component } from "react"
import { Progress } from 'reactstrap'
import { ContentConsumer } from '../../language'
import PDCurrentRegistration from './PDCurrentRegistration'
import PDRegistrationForm from './PDRegistrationForm'
import axios from 'axios'
import axiosConfig from '../../config/axios'

const axiosApi = axios.create(axiosConfig)

class PDRegistration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      registration: null,
      loading: true
    }
  }

  componentDidMount() {
    axiosApi.get(`/pd-courses/announcement/${this.props.announcement.id}/registration`)
      .then(response => {
        const registration = response.data
        this.setState({ registration, loading: false })
      })
      .catch(error => {
        console.error('PD-COURSES : ', error.toString())
        this.props.showError()
      })
  }

  render() {
    const { registration, loading } = this.state
    const { announcement, cancel, showError, closeModal, reopenRegistration } = this.props

    return (
      <ContentConsumer>
        {({ content = {} }) => (
          content && (
            loading ?
              (
                <Progress animated color="primary" value="100">{content.pdcourses.loading}</Progress>
              )
              :
              (
                registration !== null ?
                  (
                    <PDCurrentRegistration registration={registration} cancel={cancel} showError={showError} closeModal={closeModal} reopenRegistration={reopenRegistration} />
                  )
                  :
                  (
                    <PDRegistrationForm announcement={announcement} showError={showError} closeModal={closeModal} />
                  )
              )
          )
        )}
      </ContentConsumer>
    )
  }
}

export default PDRegistration