import React, { Component } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import backend from '../../alfresco/getCMSContent'
import { parseToReactElement } from '../../utils/html'
import { getLocale } from '../../language'

class CMSContent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      page: {},
      receivedData: false,
      error: false
    }
  }

  signal = axios.CancelToken.source()

  componentDidMount() {
    const { pageid, cache } = this.props
    const language = this.props.language || getLocale()
    this.getCMSContent(pageid, language, cache)
  }

  componentDidUpdate(prevProps) {
    if (this.props.language && this.props.language !== prevProps.language) {
      const { pageid, cache, language } = this.props
      this.getCMSContent(pageid, language, cache)
    }
  }

  getCMSContent = (pageid, language, cache) => {
    const callback = (error, response) => {
      if (error) {
        if(!axios.isCancel(error)) {
          this.setState({
            error: error,
            receivedData: true,
            page: {}
          })
        }
      } else {
        this.setState({
          page: response.data,
          receivedData: true,
          error: false
        })
      }
    }
    backend.getCMSContent(null, pageid, language, cache, this.signal.token, callback)
  }

  componentWillUnmount() {
    this.signal.cancel()
  }

  render() {
    // MBQ 12/01/2023 : New wrapper component for showing contents
    const Wrapper = this.props.wrapper    

    if (this.state.receivedData) {
      if (!this.state.error) {
        //return (<div>{parseToReactElement(this.state.page.content, null, { ADD_ATTR: ['target'] })}</div>)
        return (Wrapper ?
          (
            this.state.page && this.state.page.content ?
              (
                <Wrapper {...this.props.wrapperProps}>
                  <div>{parseToReactElement(this.state.page.content, null, { ADD_ATTR: ['target'] })}</div>
                </Wrapper>
              )
              :
              (<div></div>)
          )
          :
          (
            <div>{parseToReactElement(this.state.page.content, null, { ADD_ATTR: ['target'] })}</div>
          )
        )
      } else {
        return (<div></div>)
      }
    } else {
      return (
        <div></div>
        // <div className="progress">
        //   <div className="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}>
        //     Loading content
        //   </div>
        // </div>
      )
    }
  }
}

CMSContent.propTypes = {
  pageid: PropTypes.string.isRequired,
  cache: PropTypes.bool,
  language: PropTypes.string,
  wrapper: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.func
  ]),
  wrapperProps: PropTypes.object
}

CMSContent.defaultProps = {
  cache: true,
  wrapperProps: {}
}

export default CMSContent