import validator from 'validator'
import { isValid } from 'better-dni'

import { SUPPORTED_LOCALES } from '../language'

const regexpAlphaWithSpaces = /^[A-ZÑÁÀÂÄÃÅÆÇÉÈÊËÍÌÏÎÓÒÔÖÕŒÚÙÛÜŸÝ \-'.ºª·]+$/i
const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])\S{6,}$/

const validEmail = (email) => !!email && validator.isEmail(email)
const validName = name => !!name && validator.matches(name, regexpAlphaWithSpaces) && (name.replace(/ /g, '').length >= 2)
const validSurname1 = surname => !!surname && validator.matches(surname, regexpAlphaWithSpaces) && (surname.replace(/ /g, '').length >= 2)
const validSurname2 = surname => !surname || (validator.matches(surname, regexpAlphaWithSpaces) && (surname.replace(/ /g, '').length >= 2))
const validPhone = (phone, mandatory = false) => (!mandatory && !phone) || (!!phone && (phone.length === 9) && validator.isNumeric(phone))
const validCard = (card, mandatory = false) => (!mandatory && !card) || (!!card && isValid(card))
const validIdSim = idSim => !!idSim && validator.isNumeric(idSim + '')
const validLanguage = language => !!language && SUPPORTED_LOCALES.includes(language)
const validPassword = password => !!password && validator.matches(password, regexPassword)
const validBoolean = value => typeof value === 'boolean'

export default {
  validEmail,
  validName,
  validSurname1,
  validSurname2,
  validPhone,
  validCard,
  validLanguage,
  validPassword,
  validIdSim,
  validBoolean
}
