import React, { useState, useEffect } from 'react'
import { Modal, Carousel, CarouselItem } from 'reactstrap'
import axios from 'axios'
import _ from 'lodash'
import validator from 'validator'

import Feature from '../Feature/Feature'
import { useFeature } from '../../contexts/Feature/FeatureContext'
import { withContent, withUserInfo } from '../../utils/hocs'
import { useViewport } from '../../hooks'
import TitleCarouselItem from './TitleCarouselItem'
import CloseButton from '../Buttons/CloseButton'

import GlobalSettings from '../../globals/envSettings'
import UserRoles from '../../globals/userRoles'

import Settings, { ClientSettings } from './settings'

const carouselClient = axios.create(ClientSettings)

const Type = {
  ALL: 'ALL',
  MEC: 'MEC',
  NO_MEC: 'NO_MEC',
  NONE: 'NONE'
}

const getType = (type) => {
  if(!type) return Type.ALL

  switch (type.trim().toUpperCase()) {
    case 'ALL':
    case 'SIEMPRE':
      return Type.ALL
    case 'NO_MEC':
      return Type.NO_MEC
    case 'MEC':
    case 'SI_MEC':
      return Type.MEC
    default:
      return Type.NONE
  }
}

const TitleCarousel = ({
  isOpen,
  isOnDemand,
  onClose,
  locale,
  userInfo: { mec, countryId, onBoardCarouselDate, role }
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [items, setItems] = useState(null)
  const { disableCarousel, wpUnavailable } = useFeature()
  const viewport = useViewport()

  useEffect(() => {
    if(!items) {
      // Retrieve title carousel posts
      carouselClient.get(`posts/?_embed=&per_page=${Settings.MAX_ITEMS}&categories=${Settings.CATEGORY_ROOT}`)
        .then(({ data }) => {
          const posts = data.reduce((acc, item) => {
            // Check if the item has the correct language
            if(!_.intersection(item.categories, [Settings.LANGUAGES_ROOT, Settings.LANGUAGES[locale]]).length) {
              return acc
            }
            const excerptData = validator.blacklist(_.get(item, 'excerpt.rendered', '').trim(), '<p></p>\n')
            const [order, type] = excerptData.split(':')

            // Check if user should see the item depending of his school type
            if(![Type.ALL, mec ? Type.MEC : Type.NO_MEC].find(item => item === getType(type))) return acc

            if(Number.isNaN(Number(order))) return acc

            const post = {
              id: item.id,
              title: _.get(item, 'title.rendered'),
              body: _.get(item, 'content.rendered'),
              order: Number(order),
              type: getType(type)
            }
            const image = _.get(item, ['_embedded', 'wp:featuredmedia', 0, 'media_details', 'sizes', 'full', 'source_url'])
            if(image) {
              post.image = {
                url: image,
                alt: _.get(item, ['_embedded', 'wp:featuredmedia', 0, 'alt-text'])
              }
              acc.push(post)
            }
            return acc
          }, [])

          if(posts.length === 0) disableCarousel()

          setItems(_.sortBy(posts, ['order']))
        })
        .catch(() => {
          wpUnavailable()
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const next = () => {
    if(activeIndex < items.length) setActiveIndex(activeIndex + 1)
  }
  const previous = () => {
    if(activeIndex > 0) setActiveIndex(activeIndex - 1)
  }

  if(items && items.length
    && (!onBoardCarouselDate || (onBoardCarouselDate && isOnDemand))
    && ([UserRoles.TEACHER, UserRoles.TEACHER_FAKE].find((item) => item === role))
    && (GlobalSettings.COUNTRY_ESP_ID === countryId)
    && viewport.breakpoint !== 'xs') {
    return (
      <Feature name="carousel">
        <Modal
          className="title-carousel-modal"
          isOpen={isOpen}
          size="lg"
          centered
        >
          <CloseButton
            className="position-absolute title-carousel-close"
            onClick={() => {
              setActiveIndex(0)
              onClose()
            }}
          />
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            keyboard={false}
            interval={false}
          >
            {
              items.map((item, index) => {
                const isFirst = index === 0
                const isLast = index === items.length - 1
                return (
                  <CarouselItem key={item.id}>
                    <TitleCarouselItem
                      title={item.title}
                      body={item.body}
                      image={item.image.url}
                      item={{
                        current: items[activeIndex],
                        all: items
                      }}
                      next={!isLast && next}
                      previous={!isFirst && previous}
                      isOnDemand={isOnDemand}
                    />
                  </CarouselItem>
                )
              })
            }
          </Carousel>
        </Modal>
      </Feature>
    )
  }

  return null
}

export default withUserInfo(withContent(TitleCarousel))
