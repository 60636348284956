import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Table } from 'reactstrap'
import { withUserInfo } from '../../../utils/hocs'
import { withRouter } from 'react-router-dom'
import { formatDate } from "../../../utils/format"
import { getInvoiceLaSalleId , getInvoicesLaSalleYear } from "../../../services/administrator/request"
import CreateInvoice from '../CreateInvoiceLaSalle/CreateInvoiceLaSalle'
import envSettings from '../../../globals/envSettings'


class invoiceAdministratorLaSalle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noResults: false,
      oneFieldRequired: false,
      showUserSearchPage: true,
      todos: []
    };
    this.searchByYear = this.searchByYear.bind(this)
    this.searchById = this.searchById.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange = () => this.setState({ noResults: false })

  onClickEnterId = ({ key }) => {
    if(key === 'Enter')
      this.searchById();
  }
  onClickEnterYear = ({ key }) => {
    if(key === 'Enter')
      this.searchByYear();
  }

  switchPages(data) {
    this.setState({
      noResults: false,
      oneFieldRequired: false,
      showUserSearchPage: data,
      todos: []
    });
  }

  resetUserValues() {
    this.year.value = ''
    this.id.value = ''
  }

  searchByYear() {
    const year = this.year.value.trim()
    getInvoicesLaSalleYear(year)
      .then(data => {
        if (data.status === 200) {
          let formatedData = data.data
          this.setState({
            todos: formatedData,
            noResults: formatedData.length > 0 ? false : true,
            oneFieldRequired: false,
          }, () => {
            this.resetUserValues()
          });
        }
      })
      .catch((err) => {
        this.setState({ noResults: false, todos: [] })
        this.resetUserValues()
      })
  }

  searchById() {
    const id = this.id.value.trim()
    getInvoiceLaSalleId(id)
      .then(data => {
        if (data.status === 200) {
          let formatedData = data.data
          this.setState({
            todos: formatedData,
            noResults: formatedData.length > 0 ? false : true,
            oneFieldRequired: false,
          }, () => {
            this.resetUserValues()
          });
        }
      })
      .catch((err) => {
        this.setState({ noResults: false, todos: [] })
        this.resetUserValues()
      })
  }

  userSearchPage() {
    return (
      <Fragment>
        <Container fluid>
          <div className="customer-user-screen-container">
            <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray">
              <Col xs="6" md="8"><h3 className="text-primary font-weight-bold mb-0">La Salle Invoice Search</h3></Col>
            </Row>
            <Form>
              <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray">
                <Col xs="12" sm="6" md="6" lg="3">
                  <FormGroup className="form-group">
                    <Label className="font-weight-bold" for="ID">Year</Label>
                    <Input type="text" size="100" name="year" id="year" placeholder="" innerRef={el => this.year = el} onChange={this.handleInputChange} onKeyPress={this.onClickEnterYear} />
                  </FormGroup>
                </Col>

                <Col xs="12" sm="6" md="12" lg="1">
                  <FormGroup className="form-group">
                    <div className="search-button">
                      <Button className="font-weight-normal" color="secondary" size="md" onClick={this.searchByYear}>Search Year</Button>
                    </div>
                  </FormGroup>
                </Col>

                <Col xs="12" sm="6" md="6" lg="3">
                  <FormGroup className="form-group">
                    <Label className="font-weight-bold" for="ID">identifier</Label>
                    <Input type="text" size="100" name="id" id="id" placeholder="" innerRef={el => this.id = el} onChange={this.handleInputChange} onKeyPress={this.onClickEnterId} />
                  </FormGroup>
                </Col>

                <Col xs="12" sm="6" md="12" lg="1">
                  <FormGroup className="form-group">
                    <div className="search-button">
                      <Button className="font-weight-normal" color="secondary" size="md" onClick={this.searchById}>Search Id</Button>
                    </div>
                  </FormGroup>
                </Col>

                <Col xs="12" sm="6" md="12" lg="1">
                  <FormGroup className="form-group">
                    <div className="search-button">
                      <Button className="font-weight-normal" color="secondary" size="md" onClick={() => this.switchPages(false)}>Create Invoice</Button>
                    </div>
                  </FormGroup>
                </Col>
                {this.state.noResults && <Col xs="12"><span className="font-weight-bold text-danger">No results found</span></Col>}
                {this.state.oneFieldRequired && <Col xs="12"><span className="font-weight-bold text-danger">Please enter atleast one field</span></Col>}
              </Row>
            </Form>
            <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray small">

              <Col xs="12" sm="12" md="5" lg="7">
                <Col className="col-3 col-sm-2 col-md-2 col-lg-2 d-inline-block text-left col"><span className="font-weight-bold">Records: {this.state.numberRecords}</span></Col>
              </Col>

            </Row>
            <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray small">
              <Col xs="12" sm="12" md="12" lg="12">
                <Table className="text-center" responsive={true}>
                  <thead>
                    <tr bgcolor="#5393d4">
                      <th>Identificador</th>
                      <th>Inicio</th>
                      <th>Fin</th>
                      <th>Proceso</th>
                      <th>Estado</th>
                      <th>Comentarios</th>
                      <th>Usuario</th>
                      <th>Descarga</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.todos.length > 0 && this.state.todos.map((data) => {
                        return (
                          <tr key={data.id}>
                            <td>{data.id}</td>
                            <td>{data.iniciofacturacion}</td>
                            <td>{data.finfacturacion}</td>
                            <td>{formatDate(data.inicioproceso, "DD/MM/YYYY")}</td>
                            <td>{data.finproceso ? data.status : "EN PROCESO"}</td>
                            <td>{data.comentario}</td>
                            <td>{data.user}</td>
                            { /* eslint-disable-next-line */ }
                            { data.finproceso && data.resultado ?  <td><a href={`${envSettings.API_BASEURL}/invoice/activations/lasalle/${data.id}`}><span className="glyphicon glyphicon-download-alt" aria-hidden="true"></span></a></td>
                              :
                              <td><span className="glyphicon glyphicon-download-alt" aria-hidden="true"></span></td>
                            }
                          </tr>
                        )
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
    );
  }

  gotoSearch = () => { this.setState({ showUserSearchPage: true }) }

  render() {
    const { showUserSearchPage } = this.state;
    if (showUserSearchPage)
      return this.userSearchPage()
    else
      return <CreateInvoice gotoSearch={this.gotoSearch} />
  }
}

export default withUserInfo(withRouter(invoiceAdministratorLaSalle))
