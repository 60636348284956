import React from 'react'

const defaultState = {
  isContentLoading: false,
  content: null,
  contentError: null,
  locale: null,
  contentUrl: null,
  refreshContent: () => true
}

const { Provider, Consumer } = React.createContext(defaultState)

export { Provider, Consumer, defaultState }