import React, { Component, Fragment } from 'react'
import { withUserInfo } from '../../../utils/hocs'
import { withRouter } from 'react-router-dom'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import { putCreateInvoice } from "../../../services/administrator/request"
import ModalAccept from '../../ModalAccept/ModalAccept';
import validator from 'validator'

class CreateInvoice extends Component {

  constructor(props) {
    super(props);
    this.state = {
      startdate: '',
      enddate: '',
      expirationdate: '',
      durationdays: '',
      comment: '',
      user: props.userInfo.mail,
      pid:'',
      openModal: false,
      popupMsg: ''
    };
    this.handleChange = this.handleChange.bind(this)
    this.newInvoice = this.newInvoice.bind(this)
    this.toggle = this.toggle.bind(this)
  }

  validDate(date) {
    if(date && (typeof date === 'string'))
      return date.match(/^\d{4}[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/)
    else return false
  }

  validForm() {
    return this.validDate(this.state.startdate) &&
      this.validDate(this.state.enddate) &&
      this.validDate(this.state.expirationdate) &&
      validator.isNumeric(this.state.durationdays)
  }

  resetUserValues() {
    // this.startdate.value = ''
    // this.enddate.value = ''
    // this.expirationdate.value = ''
    // this.durationdays.value = ''
    // this.comment.value = ''
    this.setState({
      openModal: false,
      popupMsg: '',
      pid:'',
      startdate: '',
      enddate: '',
      expirationdate: '',
      durationdays: '',
      comment: ''
    })
  }

  handleChange({ target: { name, value } }) {
    switch(name) {
      case "startdate":
      case "enddate":
      case "expirationdate":
        if(value.length<=10) this.setState({[name]: value});
        break;
      case "durationdays":
        if(value==='' || validator.isNumeric(value)) this.setState({[name]: value})
        break;
      default:
        this.setState({[name]: value})
    }
  }

  toggle() {
    this.setState({
      openModal: false,
      popupMsg: ''
    })
    this.resetUserValues();
    this.props.gotoSearch()
  }

  newInvoice() {
    const data = this.state
    putCreateInvoice(data)
      .then(rows => {
        this.setState({
          openModal: true,
          popupMsg: 'Sucessful',
          pid: rows.data.id
        })
      })
      .catch((err) => {
        this.setState({
          openModal: true,
          popupMsg: 'Error !!'
        })
      })
  }

  render() {
    return (
      <Fragment>
      <Container>
        <div className="customer-user-screen-container">

          <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray">
            <Col xs="6" md="8"><h3 className="text-primary font-weight-bold mb-0">Create New Blink Invoice</h3></Col>
          </Row>

          <Form>
            <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray">

              <Col xs="12" sm="6" md="6" lg="3">
                <FormGroup className="form-group">
                  <Label className="font-weight-bold">Start Date</Label>
                  <Input
                    type="text"
                    size="100"
                    name="startdate"
                    id="startdate"
                    valid={!!this.validDate(this.state.startdate)}
                    invalid={!!(this.state.startdate && !this.validDate(this.state.startdate))}
                    value={this.state.startdate}
                    placeholder="yyyy-mm-dd"
                    // innerRef={el => this.startdate = el}
                    onChange={this.handleChange}/>
                </FormGroup>
              </Col>

              <Col xs="12" sm="6" md="6" lg="3">
                <FormGroup className="form-group">
                  <Label className="font-weight-bold">End Date</Label>
                  <Input
                    type="text"
                    size="100"
                    name="enddate"
                    id="enddate"
                    value={this.state.enddate}
                    valid={!!this.validDate(this.state.enddate)}
                    invalid={!!(this.state.enddate && !this.validDate(this.state.enddate))}
                    placeholder="yyyy-mm-dd"
                    // innerRef={el => this.enddate = el}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>

              <Col xs="12" sm="6" md="6" lg="3">
                <FormGroup className="form-group">
                  <Label className="font-weight-bold">Expiration Date</Label>
                  <Input
                    type="text"
                    size="100"
                    value={this.state.expirationdate}
                    name="expirationdate"
                    id="expirationdate"
                    valid={!!this.validDate(this.state.expirationdate)}
                    invalid={!!(this.state.expirationdate && !this.validDate(this.state.expirationdate))}
                    placeholder="yyyy-mm-dd"
                    // innerRef={el => this.expirationdate = el}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>

              <Col xs="12" sm="6" md="6" lg="3">
                <FormGroup className="form-group">
                  <Label className="font-weight-bold">Duration Days</Label>
                  <Input
                    type="text"
                    size="100"
                    name="durationdays"
                    id="durationdays"
                    placeholder=""
                    value={this.state.durationdays}
                    // innerRef={el => this.durationdays = el}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>

              <Col xs="12" sm="12" md="12" lg="6">
                <FormGroup className="form-group">
                  <Label className="font-weight-bold">Comment</Label>
                  <Input
                    type="text"
                    size="100"
                    name="comment"
                    id="comment"
                    placeholder=""
                    value={this.state.comment}
                    //innerRef={el => this.comment = el}
                    onChange={this.handleChange} />
                </FormGroup>
              </Col>

              <Col xs="12" sm="6" md="12" lg="1">
                <FormGroup className="form-group">
                  <div className="search-button">
                    <Button className="font-weight-normal" color="secondary" size="md" disabled={ !this.validForm() } onClick={() => this.newInvoice()}>Create</Button>
                  </div>
                </FormGroup>
              </Col>

              <Col xs="12" sm="6" md="12" lg="1">
                <FormGroup className="form-group">
                  <div className="search-button">
                    <Button className="font-weight-normal" color="secondary" size="md" onClick={() =>  this.props.history.push('/') }>Cancel</Button>
                  </div>
                </FormGroup>
              </Col>

            </Row>
          </Form>

        </div>
        <ModalAccept isOpen={this.state.openModal} toggle={this.toggle} header={'Alert'}>
                        <div className="text-center font-weight-bold">{this.state.popupMsg} Proceso => {this.state.pid} </div>
        </ModalAccept>
      </Container>
    </Fragment>
    )
  }
}

export default withUserInfo(withRouter(CreateInvoice));
