import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Wrapper from './Wrapper'

// Settings constants
import envSettings from '../../../globals/envSettings'

class PDFViewer extends Component {

  viewerRef = React.createRef();

  componentDidMount() {
    const { data: { previewURL } } = this.props
    const element = this.viewerRef.current
    if(element){
      this.init(previewURL, element)
    }    
  }

  init = (source, element) => {
    const iframe = document.createElement('iframe');

    iframe.src = `${envSettings.STATIC_URL}/pdfjs/web/viewer.html?file=${envSettings.API_BASEURL}${source}`;
    iframe.width = '100%';
    iframe.height = '100%';

    element.appendChild(iframe);
  }


  render() {

    const { paddingBottom } = this.props

    return (
      <div className="resources__pdf" ref={this.viewerRef} id='viewer' style={{ width: '100%', height: '100%', paddingBottom }}></div>
    )
  }
}

PDFViewer.propTypes = {
  data: PropTypes.object.isRequired,
  paddingBottom: PropTypes.number.isRequired
}

export default Wrapper(PDFViewer)