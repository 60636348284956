import React, { Component } from 'react'
import { AuthConsumer } from 'react-check-auth'
import { invoiceRoles } from '../../globals/userRoles'
import userRoles from '../../globals/userRoles'
import UserSearchAdministrator from '../Administrator/UserSearchAdministrator'
import StudentsZoneAdministrator from '../Administrator/StudentsZoneAdministrator/StudentsZoneAdministrator'
import ProductAccessControlPanel from '../Administrator/ProductAccessControl/ProductAccessControlPanel'
import InvoicePanel from '../Administrator/InvoicePanel/InvoicePanel'
import MenuPanel from '../Administrator/MenuPanel/MenuPanel'
import { withUserInfo } from '../../utils/hocs'

class AdminPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      studentZone: false,
      accessControl: false,
      admin: false
    }
  }

  componentDidMount()
  {
    if (typeof this.props.history.location.state !== 'undefined' || this.props.userInfo.returnFromImpersonating) this.setState({admin: true})
  }

  handleStudentZone = (studentZone, admin) => this.setState({studentZone: studentZone , admin:admin})

  handlePanels = panel => {
    const newState = {}
    Object.entries(this.state).forEach(([ key ]) => {
      newState[key] = (key === panel)
    })   
    this.setState( newState )
  }

  render() {
    return (<AuthConsumer>
      {({ userInfo }) => {
       
        if ((userInfo && userRoles.INTERNAL_SUPPORT === userInfo.role) ||
            (userInfo && userRoles.CUSTOMER_SUPPORT === userInfo.role && (this.state.admin)))
          return <UserSearchAdministrator handleStudentZone={this.handleStudentZone} handlePanels={this.handlePanels} admin={this.state.admin}/>
        if (userInfo && userRoles.CUSTOMER_SUPPORT === userInfo.role && !this.state.studentZone && !this.state.accessControl && !this.state.admin) 
          return <MenuPanel handleStudentZone={this.handleStudentZone} handlePanels={this.handlePanels} />
        if (userInfo && userRoles.CUSTOMER_SUPPORT === userInfo.role && this.state.studentZone) 
          return <StudentsZoneAdministrator userInfo={this.userInfo} handleStudentZone={this.handleStudentZone} handlePanels={this.handlePanels} />
        if (userInfo && userRoles.CUSTOMER_SUPPORT === userInfo.role && this.state.accessControl) 
          return <ProductAccessControlPanel userInfo={this.userInfo} handlePanels={this.handlePanels} />

        if (userInfo && invoiceRoles.includes(userInfo.role))
          return <InvoicePanel />
        }
      }
    </AuthConsumer>)
  }
}

export default withUserInfo(AdminPanel)