import React, { Component } from 'react'
import axios from 'axios'
import { Container, Row, Col } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { withUserInfo } from '../../utils/hocs'
//import { FeatureContext } from '../../contexts/Feature/FeatureContext'
import { format } from '../../utils/string'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import axiosConfig from '../../config/axios'

class NotificationChangedAdoptions extends Component {

  //static contextType = FeatureContext

  resetAdoptions = () => {
    const { toggleModal } = this.props
    axios.create(axiosConfig).put('/notifications/changedAdoptions')
      //.then(() => { this.context.setModalFlag("notification", false) })
      .catch(() => {})
    toggleModal()
  }

  gotoEditLibrary = () => {
    const { history } = this.props
    history.push('/edit-library')
  }

  render() {
    const { content, userInfo: { schoolName } } = this.props
    return (
      <Container fluid className="container-notification-changed-adoptions">
        <Row>
          <Col className="mb-3 mt-4">
            <h1>{ content.notifications.notification_changed_adoptions_title }</h1>
          </Col>
        </Row>
        <Row>
          <Col className="mx-2 mb-3">
            <h2>
              { format(content.notifications.notification_changed_adoptions_subtitle, schoolName) }
            </h2>
          </Col>
        </Row>
        <Row className="mt-4 mb-2">
          <Col sm={{ size: 4, offset: 1 }}>
            <ButtonPremium onClick={() => {
              this.resetAdoptions()
            }}
            >
              { content.sharedTerms.CANCEL_BUTTON_TEXT }
            </ButtonPremium>
          </Col>
          <Col sm={{ size: 4, offset: 2 }}>
            <ButtonPremium
              onClick={() => {
                this.resetAdoptions()
                this.gotoEditLibrary()
              }}
              primary
            >
              { content.header.editLibrary_text }
            </ButtonPremium>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withUserInfo(withRouter(NotificationChangedAdoptions))
