import React, { Component } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Container, Row, Col } from 'reactstrap'
import qs from 'qs'

import { withContent } from '../../utils/hocs'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import { RecaptchaTerms } from '../Recaptcha/Recaptcha'

import axiosConfig from '../../config/axios'
import { parseToReactElement } from '../../utils/html'

const axiosClient = axios.create(Object.assign({}, axiosConfig, { timeout: 20000 }))

class TeacherActivation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      locked: false,
      success: false,
      error: false
    }
  }

  async componentDidMount() {
    const { idSim, hash } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    if(!idSim || !hash) this.props.history.push('/error/')
  }

  submit = async (event) => {
    event.preventDefault()
    this.setState({ locked: true })

    const { idSim, hash } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    const token = await this.props.googleReCaptchaProps.executeRecaptcha('activation')
    axiosClient.post(`/user/activation`, { idSim, hash, token })
      .then(async (response) => {
        this.setState({
          success: true,
          error: false
        })
      })
      .catch(async (err) => {
        this.setState({
          success: false,
          error: true
        })
      })
  }

  render() {
    const { content } = this.props
    const { success, error, locked } = this.state
    return (
      <Container fluid={false} className="TeacherActivation">
        {! error &&
          <Row>
            <Col md={{size:8, offset:2}}><h1>{!success ? content.useraccount.activate_account_title : content.useraccount.activate_account_success_title }</h1></Col>
          </Row>
        }
        {! error &&
          <Row>
            <Col md={{size:8, offset:2}}>{!success ? parseToReactElement(content.useraccount.activate_account_body) : parseToReactElement(content.useraccount.activate_account_success_body)}</Col>
          </Row>
        }
        { error &&
          <Row>
            <Col className="mt-5" md={{size:8, offset:2}}><p>{ parseToReactElement(content.useraccount.activate_account_error) }</p></Col>
          </Row>
        }
        { !error &&
          <Row className="mt-3">
            <Col md={{size: 4, offset: 4}} className="my-2" >
              { !success && <ButtonPremium disabled={locked} primary onClick={this.submit}>{content.useraccount.activate_account_button}</ButtonPremium> }
              { success && <ButtonPremium primary onClick={() => this.props.history.push("/signin")}>{content.header.login_text}</ButtonPremium> }
            </Col>
          </Row>
        }
        { error &&
          <Row className="mt-3">
            <Col md={{size: 4, offset: 4}} className="my-2" >
              <ButtonPremium primary onClick={() => this.props.history.push("/")}>{content.useraccount.goto_home}</ButtonPremium>
            </Col>
          </Row>
        }
        { !error && !success &&
          <Row className="my-3">
            <Col md={{size:8, offset:2}}><RecaptchaTerms /></Col>
          </Row>
        }
      </Container>
    )
  }
}

export default withRouter(withGoogleReCaptcha(withContent(TeacherActivation)))
