import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import AddCouponTitle from './AddCouponTitle'
import Title from './Title'

import './icons.css'

export default ({ onSelect, titles, updateLibrary, couponUser }) => (
  <Container className="px-md-0" fluid>
    <Row>
      { titles.map((title) => (
        <Col key={title.uuid} className="d-table-cell py-3" xs="12" md="4" lg="3">
          <Title
            title={title}
            couponUser={couponUser}
            onClick={() => onSelect(title.uuid)}
          />
        </Col>
      ))}
      {/* New coupon button */}
      <Col className="d-table-cell py-2" xs="12" md="4" lg="3">
        <AddCouponTitle
          couponUser={couponUser}
          updateLibrary={updateLibrary}
        />
      </Col>
    </Row>
  </Container>
)
