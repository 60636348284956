import React from 'react'
import _ from 'lodash'
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap'

import ButtonCoupons from '../../ButtonCoupons/ButtonCoupons'
import { parseToReactElement } from '../../../utils/html'
import { withContent } from '../../../utils/hocs'

import './WhereIsMyCodeModal.css'

const WhereIsMyCodeModal = ({ content, isOpen, onClose }) => (
  <Modal
    className="coupons-modal modal-whereismycode"
    isOpen={isOpen}
  >
    <ModalHeader className="text-center">
      {_.get(content, 'coupons.modal_whereismycode_header')}
    </ModalHeader>
    <ModalBody className="mb-3">
      <Container>
        <Row>
          <Col md="6" className="text-left">
            <Container className="h-100">
              <Row className="h-100">
                <Col className="my-auto">
                  { parseToReactElement(_.get(content, 'coupons.modal_whereismycode_body'), null, { ADD_ATTR: ['target'] }) }
                </Col>
                <Col className="mt-auto">
                  <ButtonCoupons
                    primary
                    onClick={onClose}
                  >
                    {_.get(content, 'coupons.modal_whereismycode_button')}
                  </ButtonCoupons>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md="6">
            <img
              className="img-fluid"
              src="https://static.oupe.es/img/WhereIsMyCode.png"
              alt="¿Donde está mi código?"
            />
          </Col>
        </Row>
      </Container>
    </ModalBody>
  </Modal>
)

export default withContent(WhereIsMyCodeModal)
