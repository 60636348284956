import React from 'react'
import ButtonPremium from '../../ButtonPremium/ButtonPremium'

const ManagedSurveyButton = ({ url, pollText }) => (
  <div className="ManagedSurvey d-flex flex-row">
    <ButtonPremium
      onClick={event => {
        event.preventDefault()
        window.open(url)
      }}
      primary
    >
      {pollText.button_survey}
    </ButtonPremium>
  </div>
)

export default ManagedSurveyButton