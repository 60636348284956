import React, { Component, Fragment } from "react"
import { Progress } from 'reactstrap'
import { ContentConsumer } from '../../language'
import PDBanner from './PDBanner'
import PDCompletedCourses from './PDCompletedCourses'
import PDCatalogue from './PDCatalogue'
import PDCompletedCatalogue from './PDCompletedCatalogue'
import { getCatalogue, TABS } from './utils'
import axios from 'axios'
import axiosConfig from '../../config/axios'

const axiosApi = axios.create(axiosConfig)

class PDTabCompletedCourses extends Component {
  constructor(props) {
    super(props)
    this.state = {
      completedRegistrations: null,
      catalogue: [],
      loading: true
    }
  }

  getCompletedRegistrations = () => (
    axiosApi.get('/pd-courses/registrations?completed=1')
      .then(({ data: completedRegistrations }) => {
        const catalogue = getCatalogue(this.props.cards, completedRegistrations)
        this.setState({ completedRegistrations, catalogue, loading: false })
      })
      .catch(error => {
        console.error('PD-COURSES : ', error.toString())
        this.props.showError(TABS.ANNOUNCEMENT)
      })
  )

  componentDidUpdate(prevProps) {
    if (this.props.activeTab !== prevProps.activeTab && this.props.activeTab === TABS.COMPLETED_COURSES) {
      this.setState({ loading: true }, () => this.getCompletedRegistrations())
    }
  }

  render() {
    const { completedRegistrations, catalogue, loading } = this.state
    const { announcement, activeTab } = this.props

    return (
      <ContentConsumer>
        {({ content = {} }) => (
          content && (
            loading ?
              (
                <Progress animated color="primary" value="100">{content.pdcourses.loading}</Progress>
              )
              :
              (
                <Fragment>
                  <PDBanner className="completed-courses">
                    <PDCompletedCourses registrations={completedRegistrations} />
                  </PDBanner>

                  {
                    catalogue.length > 0 && (
                      <PDCatalogue
                        announcement={announcement}
                        cards={catalogue}
                        activeTab={activeTab}
                        className="pdTabAnnouncement" />
                    )
                  }

                  {
                    catalogue.length === 0 && (
                      <PDCompletedCatalogue moreCourses={announcement === null ? true : false} />
                    )
                  }
                </Fragment>
              )
          ))}
      </ContentConsumer>
    )
  }
}

export default PDTabCompletedCourses