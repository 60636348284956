import React, { Fragment, useState, useEffect } from 'react'
import _ from 'lodash'
import { Modal, ModalHeader, ModalBody, Container, Row, Col, NavLink } from 'reactstrap'
import axios from 'axios'

import axiosConfig from '../../config/axios'
import { withContent, withUserInfo } from '../../utils/hocs'
import { Colors } from '../Header/components/Colors'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import CloseButton from '../Buttons/CloseButton'

const MyDelegates = ({ content }) => {
  const [delegates, setDelegates] = useState([])
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    const axiosClient = axios.create(axiosConfig)
    axiosClient.get('/school/delegates')
      .then((response) => {
        const theDelegates = response.data
        setDelegates(theDelegates)
      })
      .catch(() => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(delegates && delegates.length) {
    const toggleModal = () => setOpen(!isOpen)
    return (
      <>
        <NavLink
          className="cursor-pointer"
          onClick={(e) => {
            e.preventDefault()
            setOpen(true)
          }}
        >
          { _.get(content, 'header.my_delegates') }
        </NavLink>

        <Modal
          toggle={toggleModal}
          className="mydelegates-modal"
          isOpen={isOpen}
          centered
        >
          <CloseButton
            className="position-absolute mydelegates-close"
            onClick={toggleModal}
          />
          <ModalHeader
            cssModule={{ 'modal-title': 'w-100 text-center' }}
          >
            { _.get(content, 'delegates.mydelegates') }
          </ModalHeader>
          <Colors colors={['red', 'pink', 'yellow', 'green', 'aqua', 'blue']} />
          <ModalBody>
            <Container>
              { delegates.map((delegate, index, array) => (
                <Fragment
                  key={`${delegate.name}${delegate.surname1}`}
                >
                  <Row
                    className="mydelegates-item"
                  >
                    <Col className="mydelegates-name my-2" xs="12">{`${delegate.name} ${delegate.surname1}${delegate.surname2 ? ` ${delegate.surname2}` : ''}`}</Col>
                    { delegate.phone && <Col className="mydelegates-data my-1" xs="12">{`${_.get(content, 'delegates.phone')}: ${delegate.phone}`}</Col> }
                    { delegate.email && (
                      <Col className="mydelegates-data my-1" xs="12">
                        <span>{_.get(content, 'delegates.email')}: </span>
                        <a href={`mailto:${delegate.email.trim()}`}>{delegate.email.trim()}</a>
                      </Col>
                    )}
                  </Row>
                  { (index !== array.length - 1) && <hr /> }
                </Fragment>
              ))}
              <Row className="mt-3">
                <Col xs="12" md={{ size: 10, offset: 1 }} xl={{ size: 6, offset: 3 }}>
                  <ButtonPremium primary className="mydelegates-close-button w-100" onClick={toggleModal}>{_.get(content, 'delegates.close')}</ButtonPremium>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </>
    )
  }

  return null
}

export default withContent(withUserInfo(MyDelegates))
