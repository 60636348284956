import React, { Fragment } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import AuthRoute from './AuthRoute'
import NoAuthRoute from './NoAuthRoute'
import CookieBanner from '../components/CookieBanner/CookieBanner'
import Header from '../components/Header/Header'
import WarningBanner from '../components/WarningBanner/WarningBanner'
import Footer from '../components/Footer/Footer'
import Signin from '../components/Signin/Signin'
import HomePrivate from '../components/HomePrivate/HomePrivate'
import Title from '../components/Title/Title'
import ChangeSchool from '../components/ChangeSchool/ChangeSchool'
import EditLibrary from '../components/EditLibrary/EditLibrary'
import GratisHP from '../components/GratisHP/GratisHP'
import HPSuccess from '../components/HPSuccess/HPSuccess'
import HPError from '../components/HPError/HPError'
import ActivationCode from '../components/ActivationCode/ActivationCode'
import GpaOrders from '../components/GpaOrders/GpaOrders'
import GpaOrder from '../components/GpaOrder/GpaOrder'
import EditAccount from '../components/EditAccount/EditAccount'
import ChangePassword from '../components/ChangePassword/ChangePassword'
import ActivateLicense from '../components/ActivateLicense/ActivateLicense'
import Chat from '../components/Chat/Chat'
import HelpPage from '../components/HelpPage/HelpPage'
import ContactPage from '../components/ContactPage/ContactPage'
import GpaTermsPage from '../components/GpaTermsPage/GpaTermsPage'
import ErrorPage from '../components/ErrorPage/ErrorPage'
import AdminPanel from '../components/AdminPanel/AdminPanel'
import InvoiceAdministrator from '../components/Administrator/InvoiceAdministrator/InvoiceAdministrator'
import InvoiceAdministratorLaSalle from '../components/Administrator/InvoiceAdministratorLaSalle/InvoiceAdministratorLaSalle'
import EditFictitiousUser from '../components/Administrator/CreateFictitiousUser'
import EditOUPUser from '../components/Administrator/CreateOUPUser';
import UserLicenses from'../components/Administrator/UserLicenses';
// >>MBQ IMPERSONATION
import Administration from '../components/Administration/Administration'
// MBQ IMPERSONATION<<
import envSettings from '../globals/envSettings'
import TeacherRegistration from '../components/TeacherRegistration/TeacherRegistration'
import TeacherActivation from '../components/TeacherActivation/TeacherActivation'
import PasswordForgot from '../components/PasswordForgot/PasswordForgot'
import PasswordReset from '../components/PasswordReset/PasswordReset'
import BlinkActivationError from "../components/BlinkActivationError/BlinkActivationError"
import ThirdPartiesError from "../components/ThirdPartiesError/ThirdPartiesError"
import BlinkCptHelpPage from '../components/BlinkCptHelpPage/BlinkCptHelpPage'
import PDSection from '../components/ProfessionalDevelopment/PDSection'
import PDAsset from '../components/ProfessionalDevelopment/PDAsset'
import NotificationsPage from '../components/ResourceNotifications/NotificationsPage'
import PDCourses from '../components/PDCourses/PDCourses'
import PublicHomePage from '../components/PublicHomePage/PublicHomePage'
import StudentsZone from '../components/Students/StudentsZone'
import OAuth2Signin from '../components/OAuth2Signin/OAuth2Signin'
import OAuth2Authorize from '../components/OAuth2Authorize/OAuth2Authorize'
import OAuth2Error from '../components/OAuth2Error/OAuth2Error'
import ProductViewer from '../components/ProductViewer/ProductViewer'
import ClassroomError from 'components/Classroom/Errors/ClassroomError'
import MarsupialBlinkTest from '../components/MarsupialBlinkTest/MarsupialBlinkTest'
import { GoogleClassroomProvider } from 'contexts/GoogleClassroomContext'

import { PORTAL, ROUTE_PROFILE } from './utils'

const AppRouter = () => (
  <BrowserRouter basename={envSettings.APP_BASEPATH}>
    <Fragment>
      <Route component={CookieBanner} />
      <Route component={Header} />
      <Route component={WarningBanner} />
      <div className="main-content">
        <Switch>
          <AuthRoute exact path="/" component={HomePrivate} portal={PORTAL.APP} routeProfile={ROUTE_PROFILE.ALL} />
          <AuthRoute path="/title/:titleid/unit/:unitid?/cat/:catid?/subcat/:subcatid?" component={ (props) => (<GoogleClassroomProvider><Title {...props} /></GoogleClassroomProvider>)} />
          <AuthRoute path="/change-school" component={ChangeSchool} />
          <AuthRoute path="/edit-library" component={EditLibrary} />
          <AuthRoute path='/gratishp/HPSuccess' component={HPSuccess} />
          <AuthRoute path='/gratishp/HPError' component={HPError} />
          <AuthRoute path='/gratishp' component={GratisHP} />
          <AuthRoute path="/activation-code" component={ActivationCode} />
          <AuthRoute path="/gratis-adoption/:orderId" component={GpaOrder} />
          <AuthRoute path="/gratis-adoption" component={GpaOrders} />
          <AuthRoute path='/edit-account' component={EditAccount} portal={PORTAL.APP} routeProfile={ROUTE_PROFILE.CUSTOMER} />
          <AuthRoute path='/change-password' component={ChangePassword} portal={PORTAL.APP} routeProfile={ROUTE_PROFILE.CUSTOMER} />
          <AuthRoute path='/activate-license' component={ActivateLicense} portal={PORTAL.APP} routeProfile={ROUTE_PROFILE.CUSTOMER} />
          <Route path="/student/:token/cat/:catid?/subcat/:subcatid?" component={StudentsZone} />
          <Route path="/student/:token" component={StudentsZone} />
          <Route path="/help" component={HelpPage} />
          <Route path="/contact" component={ContactPage} />
          <AuthRoute path="/terms-gratis-adoption" component={GpaTermsPage} />
          <Route path="/error" component={ErrorPage} />
          <NoAuthRoute path="/register/:id?/:hash?" component={TeacherRegistration} />
          <NoAuthRoute path="/activation" component={TeacherActivation} />
          <NoAuthRoute path="/forgot-password" component={PasswordForgot} />
          <NoAuthRoute path="/reset-password" component={PasswordReset} />
          <Route path="/activation-error" component={BlinkActivationError} />
          <Route path="/third-parties-error" component={ThirdPartiesError} />
          <Route path="/blinkcpt-help" component={BlinkCptHelpPage} />
          <Route path="/classroom-unauthorized" component={() => <ClassroomError errorId='401' />} />
          <Route path="/classroom-notfound" component={() => <ClassroomError errorId='404' />} />
          <Route path="/classroom-error" component={() => <ClassroomError errorId='500' />} />
          <AuthRoute path="/professional-development/:assetid" component={PDAsset} />
          <AuthRoute path="/professional-development" component={PDSection} />
          <AuthRoute path="/notifications" component={NotificationsPage} />
          <AuthRoute path="/pd-courses" component={PDCourses} />
          { /* >>MBQ IMPERSONATION */}
          <AuthRoute path='/administration' component={Administration} routeProfile={ROUTE_PROFILE.ALL} />
          { /* MBQ IMPERSONATION<< */}

          <AuthRoute path="/admin/blink" component={InvoiceAdministrator} routeProfile={ROUTE_PROFILE.ADMIN} />
          <AuthRoute path="/admin/lasalle" component={InvoiceAdministratorLaSalle} routeProfile={ROUTE_PROFILE.ADMIN} />
          <AuthRoute path="/admin" component={AdminPanel} routeProfile={ROUTE_PROFILE.ADMIN} />
	        <AuthRoute path="/edit/customer/user/licenses/:userId"component={(props)=><UserLicenses{...props}/>} routeProfile={ROUTE_PROFILE.ADMIN}/>
          <AuthRoute path="/edit/customer/user/:role/:userId" component={(props) => <EditFictitiousUser type='EDIT' {...props} />} routeProfile={ROUTE_PROFILE.ADMIN} />
          <AuthRoute path="/edit/internal/user/:role/:userId" component={(props) => <EditOUPUser type='EDIT' {...props} />} routeProfile={ROUTE_PROFILE.ADMIN} />

          { /* >>MBQ TODO Sample routes */}
          <AuthRoute path="/customers" component={() => <h3>ROUTE PREMIUM AND PLUS CUSTOMERS</h3>} portal={PORTAL.APP} routeProfile={ROUTE_PROFILE.CUSTOMER} />
          <AuthRoute path="/customers-plus" component={() => <h3>ROUTE PLUS CUSTOMERS</h3>} portal={PORTAL.PLUS} routeProfile={ROUTE_PROFILE.CUSTOMER} />
          <AuthRoute path="/students" component={() => <h3>ROUTE PLUS STUDENTS</h3>} portal={PORTAL.PLUS} routeProfile={ROUTE_PROFILE.CUSTOMER_PLUS} />
          { /* MBQ TODO Sample routes<< */}

          <Route path="/signin" component={Signin} />
          <Route path="/home" component={PublicHomePage} />
          <Route path="/oauth2-signin" component={OAuth2Signin} />
          <Route path="/oauth2-authorize" component={OAuth2Authorize} />
          <Route path="/oauth2-error" component={OAuth2Error} />

          <Route path="/product-viewer" component={ProductViewer} />

          { /* MBQ MAY 2024 POC MARSUPIAL BLINK */}
          <Route path="/marsupialblink-test" component={MarsupialBlinkTest} />

          <Route component={ErrorPage} />
        </Switch>
      </div>
      <Route component={Chat} />
      <Route component={Footer} />
    </Fragment>
  </BrowserRouter>
)

export default AppRouter