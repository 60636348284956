import envSettings, { isPreProEnv, isProductionEnv } from '../../globals/envSettings'
import axiosConfig from '../../config/axios'

const PDCOURSES_WP_SETTINGS = {
  LANGUAGES_ROOT: 310,
  CARD_CATEGORY: 761
}

// MBQ TODO REVIEW CARD_CATEGORY
const PDCOURSES_WP_SETTINGS_PRE = {
  CARD_CATEGORY: 765
}

// MBQ TODO REVIEW CARD_CATEGORY
const PDCOURSES_WP_SETTINGS_PRO = {
  CARD_CATEGORY: 766
}

const getPDCoursesSettings = () => (
  isPreProEnv() ?
    { ...PDCOURSES_WP_SETTINGS, ...PDCOURSES_WP_SETTINGS_PRE }
    :
    isProductionEnv() ?
      { ...PDCOURSES_WP_SETTINGS, ...PDCOURSES_WP_SETTINGS_PRO }
      :
      PDCOURSES_WP_SETTINGS
)


export const ClientSettings = {
  ...axiosConfig,
  baseURL: envSettings.WORDPRESS_API_BASEURL,
  withCredentials: false
}

const moreInformationOupPage = {
  es: 'https://www.oup.es/es/cursos-LOMLOE-online',
  en: 'https://www.oup.es/en/LOMLOE-online-courses',
  it: 'https://www.oup.es/en/LOMLOE-online-courses'
}

export const moreInformationUrl = locale => (moreInformationOupPage[locale])

export const contactMail = 'atencionalcliente@oup.es'

export const contactPhone = '916 270 180'

export default getPDCoursesSettings()