import React, { Component, Fragment } from 'react'
import { withUserInfo } from '../../../utils/hocs'
import { withRouter } from 'react-router-dom'
import validator from 'validator'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ROLES from '../../../globals/userRoles'
import ModalAccept from '../../ModalAccept/ModalAccept';
import { Container, Row, Col, Button, Form, Input } from 'reactstrap';
import { characterLength, emailRegex, passwordRegex, getStatusMessage } from "../../../utils/format";
import UserDetail from "../FormComponent";
import { getInternalSupportUserDataById, getInternalSupportDataActions, putInternalSupportUserDataById, postInternalSupportUserData, putInternalSupportDataStatusById } from "../../../services/administrator/request";
import languages from "../../../data/language.json";

class CreateOUPUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            surname1: '',
            surname2: '',
            email: '',
            password: '',
            confirmPassword: '',
            role: '',
            initial_role: '',
            language: '',
            latestActions: [],
            active: true,
            adminFictitious: false,
            buttonText: 'Deactivate',
            openModal: false,
            errors: {
                name: false,
                surname1: false,
                surname2: false,
                email: false,
                password: false,
                confirmPassword: false,
                role: false,
                language: false
            },
            errorMsg: {
                name: false,
                email: false,
                password: false,
                role: false,
                language: false
            }
        };
        this.handleInputChange = this.handleInputChange.bind(this)
        this.checkbox = this.checkbox.bind(this)
        this.create = this.create.bind(this)
        this.errorMsg = this.errorMsg.bind(this)
        this.toggle = this.toggle.bind(this)
        console.log("CreateOUPUser", props);
    }

    checkbox({ target: { name, checked } }) {
        this.setState({
            [name]: checked
        });
    }

    setButtonText = (value) => (value === 0) ? 'Activate' : 'Deactivate'

    showError(error) {
        console.log(error)
      this.setState({
            popupMsg: getStatusMessage(error),
            openModal: true
      })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const { type, match: { params } } = this.props;
        if (type === 'EDIT') {
            Promise.all([getInternalSupportUserDataById(params.userId), getInternalSupportDataActions(params.userId)])
                .then(([result1, result2]) => {
                    if (result1.status === 200 && result2.status === 200) {
                        this.setState({
                            "id": result1.data.id,
                            "name": result1.data.name,
                            "surname1": result1.data.surname1,
                            "surname2": result1.data.surname2,
                            "email": result1.data.email,
                            "language": result1.data.language,
                            "role": result1.data.role,
                            "initial_role": result1.data.role,
                            "password": result1.data.password,
                            'confirmPassword': result1.data.password,
                            "active": result1.data.active,
                            "adminFictitious": !!result1.data.adminFictitious,
                            "buttonText": this.setButtonText(result1.data.active),
                            "creationDate": result1.data.creationDate,
                            "lastAccessDate": result1.data.lastAccessDate,
                            "latestActions": result2.data
                        });
                    }
                })
                .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
            console.log("CreateOUPUser-componentDidMount", params);
        }
    }


    toggle() {
        const { type } = this.props;
        this.setState({ openModal: false }, () => {
            if (type === 'CREATE')
                this.props.back(true);
            else
                this.setState({ openModal: false }, () => this.props.history.push('/admin'));
        })
    }

    isValid = () => !_.compact(_.values(this.state.errors)).length && this.state.name && this.state.surname1 &&
        this.state.email && this.state.password === this.state.confirmPassword && this.state.language &&
        this.state.role && [ROLES.INTERNAL_SUPPORT].find(role => role === _.get(this.props, 'userInfo.role', undefined))


    create() {
        const { name, surname1, surname2, email, password, confirmPassword, role, active, adminFictitious, language } = this.state;
        const { type, match: { params } } = this.props;

        let data = {
            userId: params.userId,
            name,
            surname1,
            surname2,
            email,
            password,
            // confirmPassword,
            language,
            role,
            active,
            adminFictitious
            // creationDate: type === 'CREATE' ? new Date() : creationDate,
            // lastAccessDate: type === 'CREATE' ? new Date() : lastAccessDate
        };

        if (this.isValid()) {
            console.log('created');
            this.setState({
                errorMsg: {
                    name: false,
                    email: false,
                    password: false,
                    role: false,
                    language: false
                }
            });

            if (type === 'CREATE') {
                delete data['userId'];
                postInternalSupportUserData(data)
                    .then(result => {
                        this.setState({
                            popupMsg: getStatusMessage(result.status),
                            openModal: true
                        })
                    })
                    .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
            }
            else if (type === 'EDIT') {
                putInternalSupportUserDataById(data)
                    .then(result => {
                        this.setState({
                            popupMsg: getStatusMessage(result.status),
                            openModal: true
                        })
                    })
                    .catch((err) => {
                        this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status)
                    })
                    
            }
        }
        else {
            console.log(this.state.errors);
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        name: !validator.matches(name, characterLength),
                        surname1: !validator.matches(surname1, characterLength),
                        surname2: _.isEmpty(surname2) ? false : !validator.matches(surname2, characterLength),
                        email: !validator.matches(email, emailRegex),
                        password: type === 'CREATE' ? (!validator.matches(password, passwordRegex)) : (_.isEmpty(password) ? false : !validator.matches(password, passwordRegex)),
                        confirmPassword: type === 'CREATE' ? (!validator.matches(confirmPassword, passwordRegex)) : (_.isEmpty(confirmPassword) ? false : !validator.matches(confirmPassword, passwordRegex) || !(password === confirmPassword)),
                        role: _.isEmpty(role) ? true : false,
                        language: _.isEmpty(language) ? true : false
                    }
                }
            }, () => this.errorMsg());
        }
    }

    errorMsg() {
        this.setState({
            errorMsg: {
                name: (this.state.errors.name || this.state.errors.surname1 || this.state.errors.surname2),
                email: (this.state.errors.email),
                password: this.state.errors.password || this.state.errors.confirmPassword || this.state.password !== this.state.confirmPassword,
                role: (this.state.errors.role),
                language: (this.state.errors.language)
            }
        });
    }

    handleInputChange = ({ target: { name, value } }) => {

        switch (name) {
            case "name":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !validator.matches(value, characterLength)
                        }
                    }
                })
                break
            case "surname1":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !validator.matches(value, characterLength)
                        }
                    }
                })
                break
            case "surname2":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: _.isEmpty(value) ? false : !validator.matches(value, characterLength)
                        }
                    }
                })
                break
            case "email":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !validator.matches(value, emailRegex)
                        }
                    }
                })
                break
            case "password":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: _.isEmpty(value) ? false : !validator.matches(value, passwordRegex)
                        }
                    }
                })
                break
            case "confirmPassword":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: (this.state.password === value) ? false : !validator.matches(value, passwordRegex)
                        }
                    }
                })
                break
            case "role":
                //this.setState({ [name]: value })                
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        adminFictitious: false,
                        errors: {
                            ...prevState.errors,
                            [name]: _.isEmpty(value) ? true : false
                        }
                    }
                })
                break
            case "language":
                    //this.setState({ [name]: value })                
                    this.setState((prevState) => {
                        return {
                            [name]: value,
                            errors: {
                                ...prevState.errors,
                                [name]: _.isEmpty(value) ? true : false
                            }
                        }
                    })
                    break
            default:
                this.setState({ [name]: value })
        }
    }

    activate(d) {
        const { match: { params } } = this.props;
        let data = {
            userId: params.userId,
            active: d
        }

        putInternalSupportDataStatusById(data)
            .then(result => {
                console.log('activate', result.data);
                this.setState({
                    popupMsg: getStatusMessage(result.status),
                    openModal: true
                })
            })
            .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
    }

    render() {
        const { type, history, userInfo } = this.props;
        const { name, surname1, surname2, active, adminFictitious, buttonText } = this.state;
        return (
            <Fragment>
                <Container>
                    <div className="create-oup-user-container">
                        <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray">
                            {type === 'CREATE' && <Col xs="6" md="8"><h3 className="text-primary font-weight-bold mb-0">Create OUP User</h3></Col>}
                            {type === 'EDIT' && <Col xs="6" md="8"><h3 className="text-primary font-weight-bold mb-0">{name} {surname1} {surname2}</h3></Col>}
                            <Col xs="6" md="4">
                                <div className="float-right">
                                    {/*{(type === 'EDIT' && active) && <Button className="font-weight-normal" color="secondary" size="sm" onClick={() => this.activate(false)}>Deactivate</Button>}
                                    {(type === 'EDIT' && !active) && <Button className="font-weight-normal" color="secondary" size="sm" onClick={() => this.activate(true)}>Activate</Button>}
                                    */}
                                    {(type === 'EDIT') && <Button className="font-weight-normal" color="secondary" size="sm" onClick={() => this.activate(!+active)}>{buttonText}</Button>}
                                </div>
                            </Col>
                        </Row>
                        <Row className="pt-2 pb-2 ml-1 mr-1">
                            <Col xs="1" md="1"><div className="font-weight-bold mb-0">ID </div></Col>
                            <Col xs="11" md="11"><div className="font-weight-normal mb-0">{name} {surname1}</div></Col>
                        </Row>
                        <Form>
                            <UserDetail type='names' {...this.state} adminFictitious={userInfo.adminFictitious} handleInputChange={this.handleInputChange} />
                            <Row className="pt-2 pb-2 ml-1 mr-1">
                                <UserDetail type='email' size='8' {...this.state} adminFictitious={userInfo.adminFictitious} handleInputChange={this.handleInputChange} />
                                <UserDetail type='dropdown' {...this.state} adminFictitious={userInfo.adminFictitious} labelName="Role" name='role' 
                                options={ (type === 'CREATE') ? [{ uuid: "", name: "Select role" }, { uuid: "USER_INTERNAL", name: "USER_INTERNAL" }, { uuid: "CUSTOMER_SUPPORT", name: "CUSTOMER-SUPPORT" }, { uuid: "INTERNAL_SUPPORT", name: "INTERNAL-SUPPORT" }]
                                          : (
                                             (this.state.initial_role === "USER_INTERNAL") ? [{ uuid: "USER_INTERNAL", name: "USER_INTERNAL" }] :
                                             [{ uuid: "CUSTOMER_SUPPORT", name: "CUSTOMER-SUPPORT" }, { uuid: "INTERNAL_SUPPORT", name: "INTERNAL-SUPPORT" }]
                                            )                                    
                                    } 
                                value={this.state.role} handleInputChange={this.handleInputChange} />
                            </Row>
                            <Row className="pt-2 pb-2 ml-1 mr-1">
                                <UserDetail type='password' passwordEdit={this.props.type} {...this.state} adminFictitious={userInfo.adminFictitious} handleInputChange={this.handleInputChange} />
                                <UserDetail type='dropdown' {...this.state} adminFictitious={userInfo.adminFictitious} labelName="Language" name='language' 
                                            options= { languages.map((i) => {return {uuid:i.id, name: i.name}}) }
                                            value={this.state.language} handleInputChange={this.handleInputChange} />
                            </Row>

                            {this.state.role === ROLES.CUSTOMER_SUPPORT &&
                                <Row className='pt-2 pb-2 ml-4 mr-1'>
                                <Col xs="12" sm="6" md="6" lg="12">
                                    <div><Input type="checkbox" name="adminFictitious"
                                        checked={adminFictitious}
                                        onChange={this.checkbox}
                                    /> Admin Fictitious</div>
                                </Col>
                            </Row>
                            }

                            <UserDetail type='showErrorMessages' {...this.state} />
                            {type === "EDIT" && <Row className="pt-2 pb-2 ml-1 mr-1">
                                <Col className="font-weight-bold" xs="12" sm="12" md="12" lg="12"> LATEST ACTIONS</Col>
                            </Row>}
                            {this.state.latestActions.map((d, i) => (<Row key={i} className="pb-2 ml-1 mr-1">
                                <Col className="mt-2" xs="6" sm="6" md="6" lg="4">{d.date}</Col>
                                <Col className="mt-2" xs="6" sm="6" md="6" lg="4">{d.email}</Col>
                                <Col className="mt-2" xs="6" sm="6" md="6" lg="4">{d.action}</Col>
                            </Row>
                            ))}
                            <Row className="pb-2 ml-1 mr-1">
                                <Col xs="3" sm="3" md="3" lg="2">
                                    <Button className="font-weight-normal" color="secondary" size="md" onClick={() => { if (type === 'CREATE') { return this.props.back(true) } else if (type === 'EDIT') { history.push('/admin') } }}>Cancel</Button>
                                </Col>
                                <Col xs="3" sm="3" md="3" lg="2">
                                    {type === 'CREATE' && <Button className="font-weight-normal" color="secondary" size="md" onClick={this.create}>Create</Button>}
                                    {type === 'EDIT' && <Button className="font-weight-normal" color="secondary" size="md" onClick={this.create}>Save</Button>}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <ModalAccept isOpen={this.state.openModal} toggle={this.toggle} header={'Alert'}>
                        <div className="text-center font-weight-bold">{this.state.popupMsg}</div>
                    </ModalAccept>
                </Container>
            </Fragment>
        );
    }
}

CreateOUPUser.prototypes = {
    type: PropTypes.string.isRequired
};

export default withUserInfo(withRouter(CreateOUPUser));