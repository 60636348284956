import React from 'react'
import _ from 'lodash'

import { parseToReactElement } from '../../../utils/html'
import BasicModal from './BasicModal'
import { withContent } from '../../../utils/hocs'

const EmailWillReceiveModal = ({ content, isOpen, onClose }) => (
  <BasicModal
    header={_.get(content, 'coupons.modal_emailwillreceive_header')}
    body={parseToReactElement(_.get(content, 'coupons.modal_emailwillreceive_body'), null, { ADD_ATTR: ['target'] })}
    button={_.get(content, 'coupons.modal_emailwillreceive_button')}
    isOpen={isOpen}
    onClose={onClose}
  />
)

export default withContent(EmailWillReceiveModal)
