import React from 'react'
import { ContentConsumer } from '../../language'
import { Container, Row, Col } from 'reactstrap'
import CMSContent from '../CMSContent/CMSContent'

const CONTENT_PREM_CURSOSPD = 'PREM_CURSOSPD'

const PDHeader = () => (
  <ContentConsumer>
    {({ locale }) => (
      <Container fluid={true} className="pdHeader py-5">
        <Row className="justify-content-center">
          <Col className="w-100 text-center col-md-10 offset-md-1 col-xl-8 offset-xl-2">
            <CMSContent pageid={CONTENT_PREM_CURSOSPD} cache={true} language={locale} />
          </Col>
        </Row>
      </Container>
    )}
  </ContentConsumer>
)

export default PDHeader