import axios from 'axios'
import axiosConfig from '../config/axios'
import validator from 'validator'
const axiosInstance = axios.create(axiosConfig)

export function sendOrder(order,cb,cbe){
    axiosInstance.post('/gratishp/order',order)
        .then((response)=>{
            cb(null,response.data)
        })
        .catch((error)=>{
            console.log(error)
            cbe({})
        })
}

// MBQ Simultaneous promises instead of sequential callbacks
/*
export function orderId(cb){
    axiosInstance.get('gratishp/orderid')
    .then((response)=>{
        cb(null,response.data)
    })
    .catch((error)=>{
        console.log(error)
        cb({})
    })
}
*/
export const getOrderId = () => (
    new Promise((resolve, reject) => (
        axiosInstance.get('gratishp/orderid')
            .then(response => {
                if (response.data && response.data.id
                    && validator.isInt(response.data.id + '', { gt: 0 }))
                    return resolve(Number(response.data.id))
                return reject()
            })
            .catch(error => {
                console.log(error)
                return reject()
            })
    ))
)

// MBQ Simultaneous promises instead of sequential callbacks
/*
export function vat(cb){
    axiosInstance.get('gratishp/vat')
    .then((response)=>{
        cb(null,response.data)
    })
    .catch((error)=>{
        console.log(error)
        cb({})
    })
}
*/
export const getVAT = () => (
    new Promise((resolve, reject) => (
        axiosInstance.get('gratishp/vat')
            .then(response => {
                if (response.data && response.data.vat
                    && validator.isNumeric(response.data.vat + '')
                    && Number(response.data.vat) > 0)
                    return resolve(Number(response.data.vat))
                return reject()
            })
            .catch(error => {
                console.log(error)
                return reject()
            })
    ))
)

// MBQ Get order cost
export const getOrderCost = () => (
    new Promise((resolve, reject) => (
        axiosInstance.get('gratishp/order/cost')
            .then(response => {
                if (response.data && response.data.ordercost
                    && validator.isNumeric(response.data.ordercost + '')
                    && Number(response.data.ordercost) > 0)
                    return resolve(Number(Number(response.data.ordercost).toFixed(2)))
                return reject()
            })
            .catch(error => {
                console.log(error)
                return reject()
            })
    ))
)

export const getOrderChildren = () => (
    new Promise(resolve => (
        axiosInstance.get('gratishp/order/children')
            .then(response => {
                if (response.data && response.data.children && response.data.children.length) {
                    const children = response.data.children.reduce((acc, child) => {
                        if (child.id && validator.isInt(child.id + '', { gt: 0 })
                            && child.schoolId && validator.isInt(child.schoolId + '', { gt: 0 })
                            && child.schooName && !validator.isEmpty(child.schooName, { ignore_whitespace: true })
                            && child.schoolPostalCode && validator.matches(child.schoolPostalCode, /^\d{5}$/) && Number(child.schoolPostalCode) > 0
                            && child.name && !validator.isEmpty(child.name, { ignore_whitespace: true })
                            && child.surname1 && !validator.isEmpty(child.surname1, { ignore_whitespace: true })
                            && child.birthDate && validator.matches(child.birthDate, /^\d{4}-\d{2}-\d{2}$/)
                            && child.levels && child.levels.length) {
                            const levels = child.levels.reduce((acc2, level) => {
                                if (level.C_NIVEL && validator.isInt(level.C_NIVEL + '', { gt: 0 })
                                    && level.D_NIVEL && !validator.isEmpty(level.D_NIVEL, { ignore_whitespace: true }))
                                    acc2.push(level)
                                return acc2
                            }, [])
                            if (levels.length) {
                                child.levels = levels
                                acc.push(child)
                            }
                        }
                        return acc
                    }, [])
                    return resolve(children)
                }
                return resolve()
            })
            .catch(error => {
                console.log(error)
                return resolve()
            })
    ))
)

export function adoptionDecisions(cb){
    axiosInstance.get('gratishp/adoptiondecisions')
    .then((response)=>{
        cb(null,response.data)
    })
    .catch((error)=>{
        console.log(error)
        cb({})
    })
}

export function isbnCost(isbn,cb){
    axiosInstance.get('gratishp/cost/'+isbn)
    .then((response)=>{
        cb(null,response.data)
    })
    .catch((error)=>{
        console.log(error)
        cb({})
    })
}

export function centersByZipCode(zipcode,cb){
    axiosInstance.get('gratishp/centersbyzipcode/'+zipcode)
    .then((response)=>{
        cb(null,response.data)
    })
    .catch((error)=>{
        console.log(error)
        cb({})
    })
}

export function direction(school,cb){
    axiosInstance.get('gratishp/direction/'+school)
    .then((response)=>{cb(null,response.data)})
    .catch((error)=>{
        console.log(error)
        cb({})
    })
}

export function access(cb){
    axiosInstance.get('gratishp/validateaccess/')
    .then((response)=>{cb(null,response.data)})
    .catch((error)=>{
        console.log(error)
        cb({})
    })
}

// MBQ Get levels of children from previous year
/*export function schoolLevels(school,cb){
    axiosInstance.get('gratishp/levels/'+school)
    .then((response)=>{
        cb(null,response.data)
    })
    .catch((error)=>{
        console.log(error)
        cb({})
    })
}*/
export const schoolLevels = (school, childId) => (
    new Promise((resolve, reject) => {
        const endpointUrl = `gratishp/levels/${school}${childId ? `?idhijo=${childId}` : ''}`
        axiosInstance.get(endpointUrl)
            .then(response => {
                if (response.data && response.data.levels && response.data.levels.length) {
                    const levels = response.data.levels.reduce((acc, level) => {
                        if (level.C_NIVEL && validator.isInt(level.C_NIVEL + '', { gt: 0 })
                            && level.D_NIVEL && !validator.isEmpty(level.D_NIVEL, { ignore_whitespace: true }))
                            acc.push(level)
                        return acc
                    }, [])
                    if (levels.length) return resolve(levels)
                    return reject()
                }
                return reject()
            })
            .catch(error => {
                console.log(error)
                reject()
            })
    })
)

export function schoolLevelSubjects(school,level,cb){
    axiosInstance.get('gratishp/subjects/'+school+'/'+level)
    .then((response)=>{
        cb(null,response.data)
    })
    .catch((error)=>{
        console.log(error)
        cb({})
    })
}

export function schoolLevelSubjectISBNS(school,level,subject,teacher,teacherschool,cb){
    axiosInstance.get('gratishp/isbns/'+school+'/'+level+'/'+subject+'/'+teacher+'/'+teacherschool)
    .then((response)=>{
        cb(null,response.data)
    })
    .catch((error)=>{
        console.log(error)
        cb({})
    })
}

export function uploadImage(data,cb){
    axiosInstance.post('/gratishp/image',data)
        .then((response)=>{
            cb(null,response.data)
        })
        .catch((error)=>{
            console.log(error)
            cb({})
        })
}

export function deleteImage(image,cb){
    axiosInstance.get('/gratishp/deleteimage/'+image)
        .then((response)=>{
            cb(null,response.data)
        })
        .catch((error)=>{
            console.log(error)
            cb({})
        })
}

// MBQ Exclude children from previous year
/*export function uploadImages(hijos,cb1,cb2){    
    Promise.all(
        hijos.reduce((add,hijo)=>{        
          add.push(axiosInstance({
              method:'post',
              url:'/gratishp/image',
              timeout:200000,
              data:hijo.data              
            }))            
            return add
        },[])
    ).then(cb1).catch((err)=>{cb2(err)})    
}*/
export function uploadImages(hijos, cb1, cb2) {
    const children = hijos.filter(child => !child.id)
    if (children.length) {
        Promise.all(
            children.map(child => (
                axiosInstance({
                    method: 'post',
                    url: '/gratishp/image',
                    timeout: 200000,
                    data: child.data
                })
            )
            )).then(cb1).catch((err) => { cb2(err) })
    } else {
        cb1()
    }
}

export function deleteImages(hijos,cb){            
    // JGB - We need to find a better way to do this
    // Promise.all(
    //     hijos.reduce((add,hijo)=>{            
    //         add.push(axiosInstance.get('/gratishp/deleteimage/'+hijo.libro_familia))
    //         return add
    //     },[])
    // ).then(cb).catch(cb)  
    cb();  
}

export function pay(id_peticion,total,cb,cb2){    
    axiosInstance.get('/gratishp/pay/'+id_peticion+'/'+total)
        .then((response)=>{
            cb(response.data)
        })
        .catch((error)=>{
            console.log(error)
            cb2(error)
        })
}