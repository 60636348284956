import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
//import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import { parseToReactElement } from '../../utils/html'
import ButtonPremium from '../ButtonPremium/ButtonPremium'

class PHPContentItem extends Component {
  render() {
    const {title, contentItem, image, content} = this.props

    if(!title || !contentItem || !image ) return null
    else
    return (
        <Row className="homepage-content-item d-flex justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="4">
            <img className="img-fluid" alt={ title } src={ image.url } />
          </Col>
          <Col xs="12" lg="6" xl="8">
              <Row className="leadsection">
                <Col xs="12"><h2>{ title }</h2></Col>
                <Col xs="12"><div className="lead">{ parseToReactElement(contentItem) }</div></Col>
                <Col xs="8" sm="6" lg="6" xl="4">
                  <ButtonPremium primary onClick={() => this.props.history.push('/register')}>{content.sharedTerms.REGISTER_BUTTON_TEXT || "Regístrate ya"}</ButtonPremium>
                </Col>
              </Row>
          </Col>
        </Row>
    )
  }
}

export default withRouter(PHPContentItem)