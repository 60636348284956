import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'reactstrap'
import cx from 'classnames'
import PropTypes from 'prop-types'

import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import PDItem from './PDItem'

function CustomNextArrow(props) {
  const { onClick } = props
  return (
    <div
      className="starred-next"
      onClick={onClick}
    >
      <span className="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
    </div>
  )
}

function CustomPrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="starred-prev"
      onClick={onClick}
    >
      <span className="glyphicon glyphicon-menu-left"></span>
    </div>
  )
}

class PDCarouselStarred extends Component {

  render() {
    const { assets, className } = this.props
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <CustomNextArrow />,
      prevArrow: <CustomPrevArrow />
    }
    if(!assets || !assets.length) return null
    else return (
      <div className={cx("pdcarousel-starred", className)}>
        <Container>
          <Row>
            <Col xs={{ size: 10, offset: 1}} xl={{ size: 12, offset: 0}}>
              <Slider
                className="w-100 mx-auto"
                {...settings}>
                {assets.map(asset => <Fragment key={asset.uuid}><PDItem {...{...asset, isStarred: true}} /></Fragment>)}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

PDCarouselStarred.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  assets: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    smallThumbnail: PropTypes.string,
    largeThumbnail: PropTypes.string
  }))
}

export default PDCarouselStarred
