import React from 'react'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import qs from 'qs'

import { ContentProvider, getLocale } from './language'
import envSettings from './globals/envSettings'

import Coupons from './components/Coupons/Coupons'

export default () => (
  // <BrowserRouter basename={envSettings.APP_BASEPATH}>
  <GoogleReCaptchaProvider reCaptchaKey={envSettings.RECAPTCHA_CLIENT_ID}>
    <ContentProvider contentUrl={envSettings.CONTENT_URL} locale={getLocale()}>
      <BrowserRouter>
        <>
          <div className="main-content">
            <MainComponent />
          </div>
        </>
      </BrowserRouter>
    </ContentProvider>
  </GoogleReCaptchaProvider>
)

const useQuery = () => qs.parse(useLocation().search, { ignoreQueryPrefix: true })

const MainComponent = () => {
  const { token, action, orderid, result } = useQuery()
  return (
    <Coupons
      token={token}
      action={action}
      orderId={orderid}
      result={result}
    />
  )
}