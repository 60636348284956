import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import ButtonCoupons from '../../ButtonCoupons/ButtonCoupons'

// import './bootstrap.css'
import './BasicModal.css'

const BasicModal = ({ header, body, button, isOpen, onClose }) => (
  <Modal
    className="coupons-modal"
    isOpen={isOpen}
  >
    <ModalHeader className="text-center">
      {header}
    </ModalHeader>
    <ModalBody>
      {body}
    </ModalBody>
    <ModalFooter className="mb-3">
      <ButtonCoupons
        primary
        onClick={onClose}
      >
        {button}
      </ButtonCoupons>
    </ModalFooter>
  </Modal>
)

export default BasicModal
