import React from 'react'
import cx from 'classnames'

export default ({ current, all }) => (
  <>
    {
      all.map((item) => {
        const isCurrent = item.id === current.id
        return (
          <div
            key={item.id}
            className="title-carousel-indicator"
          >
            <div
              className={cx({ 'title-carousel-indicator-circle-active': isCurrent }, 'title-carousel-indicator-circle')}
            />
          </div>
        )
      })
    }
  </>
)
