import React, { Component, Fragment } from 'react'
import { Progress, Container, Row, Col } from 'reactstrap'
import GpaContact from '../GpaContact/GpaContact'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import axios from 'axios'
import axiosConfig from '../../config/axios'

class GpaModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      alert: null,
      isLoading: true
    }
  }

  componentDidMount() {
    this.sendOrder()
  }

  sendOrder = () => {
    const { alert } = this.state
    const { order, toggleBack } = this.props
    this.setState({ ...this.state, isLoading: true, error: null, alert: null })
    axios.create(axiosConfig).put(`/gratis-adoption/orders/${order.idSolicitud}`, { order, alert })
      .then(response => {
        //console.log('PUT RESPONSE', response)
        const alert = (200 === response.status) ? response.data.alert : null
        this.setState({ ...this.state, isLoading: false, error: null, alert }, toggleBack(!alert))
      })
      .catch(error => {
        console.error('error : ', error)
        this.setState({ ...this.state, isLoading: false, error, alert: null }, toggleBack(true))
      })
  }

  render() {
    const { content, toggleModal } = this.props
    const { error, alert, isLoading } = this.state
    return (
      <Container fluid className="container-gpa-modal">
        {isLoading && <Row><Col><Progress animated color="primary" value="100">{content.sharedTerms.LOADING_MESSAGE}</Progress></Col></Row>}
        {error &&
          <Fragment>
            <Row><Col><h5 className='alert'>{content.gratisadoption.gxa_32}</h5></Col></Row>
            <Row><Col><p>{content.gratisadoption.gxa_50}</p></Col></Row>
            <Row><Col sm={{ size: 6, offset: 3 }} className="mt-4">
              <ButtonPremium onClick={toggleModal} primary>{content.gratisadoption.gxa_5}</ButtonPremium>
            </Col></Row>
          </Fragment>}
        {alert &&
          <Fragment>
            <Row><Col><h5 className='alert'>{content.gratisadoption.gxa_32}</h5></Col></Row>
            <Row><Col><p className='header'>{content.gratisadoption.gxa_33_1}</p></Col></Row>
            <Row><Col><p>{content.gratisadoption.gxa_33_2}</p></Col></Row>
            <Row>
              <Col sm="5" className="mt-4">
                <ButtonPremium onClick={toggleModal}>{content.gratisadoption.gxa_5}</ButtonPremium>
              </Col>
              <Col sm="7" className="mt-4">
                <ButtonPremium onClick={this.sendOrder} primary>{content.gratisadoption.gxa_34}</ButtonPremium>
              </Col>
            </Row>
          </Fragment>
        }
        {!isLoading && !error && !alert &&
          <Fragment>
            <Row><Col><p>{content.gratisadoption.gxa_35}</p></Col></Row>
            <Row><Col><p>{content.gratisadoption.gxa_40}</p></Col></Row>
            <Row><Col><p><GpaContact>{content.gratisadoption.gxa_36}</GpaContact></p></Col></Row>
            <Row><Col sm={{ size: 6, offset: 3 }} className="mt-4">
              <ButtonPremium onClick={toggleModal} primary>{content.gratisadoption.gxa_46}</ButtonPremium>
            </Col></Row>
          </Fragment>
        }
      </Container>
    )
  }
}

export default GpaModal