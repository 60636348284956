import React from 'react'
import _ from 'lodash'
import { Container, Row, Col } from 'reactstrap'
import { withContent } from '../../../utils/hocs'
import { formatAmount } from '../../../utils/format'
import { format } from '../../../utils/string'

const OrderCartItem = ({ content, product, product: { vat } }) => (
  <Container className="my-5 px-0" fluid>
    <Row className="my-3 py-2 align-items-center">
      <Col xs="8">{product.name}</Col>
      <Col className="text-right font-cross-out font-color-red">
        {`${formatAmount(product.price)} €`}
      </Col>
    </Row>
    <Row className="py-2">
      <Col xs="8">
        {_.get(content, 'coupons.purchase_confirmation_cart_price_discount')}
      </Col>
      <Col className="text-right">
        {`${formatAmount(product.priceWithDiscount)} €`}
      </Col>
    </Row>
    <Row className="py-2">
      <Col xs="8">
        {format(_.get(content, 'coupons.purchase_confirmation_cart_vat', 'IVA {vat}%'), { vat })}
      </Col>
      <Col className="text-right">
        {`${formatAmount(product.vatAmount)} €`}
      </Col>
    </Row>
  </Container>
)

export default withContent(OrderCartItem)