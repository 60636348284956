import React, { Component, Fragment } from 'react'
import {
  Container, Row, Col, Button, Form, FormGroup, Label, Input, Table
} from 'reactstrap'
import Modal2Buttons from '../../Modal2Buttons/Modal2Buttons'
import ProductAccessLog from './ProductAccessLog'
import validator from 'validator'
import _ from 'lodash'
import {
  searchProductAccessControl, doProductAccessControlOperation
} from "../../../services/administrator/request"
import { getStatusMessage } from "../../../utils/format"
import { PRODUCT_ACCESS_CONTROL_OPERATIONS } from '../constants'

class ProductAccessControlPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: '',
      coupon: '',
      email: '',
      resultList: [],
      showError: false,
      errorMessage: null,
      showResetModal: false,
      itemToReset: null,
      showLog: false,
      itemToCheckLog: null,
      logRows: [],
      error: {
        token: false,
        coupon: false,
        email: false
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleInputChange = ({ target: { name, value } }) => {
    let newValue = value.trim().toLowerCase()
    let isError = false
    switch (name) {
      case "token":
        isError = (newValue.length && !validator.isUUID(newValue))
        break
      case "coupon":
        newValue = newValue.toUpperCase()
        break
      case "email":
        isError = (newValue.length && !validator.isEmail(newValue))
        break
      default:
        break
    }
    return this.setState(prevState => ({
      [name]: newValue,
      resultList: [],
      errorMessage: null,
      showError: false,
      error: {
        ...prevState.error,
        [name]: isError
      }
    }))
  }

  onEnter = ({ key }) => {
    if (key === 'Enter') this.search()
  }

  search = () => {
    if (
      _.compact(_.values(_.pick(this.state, ['token', 'coupon', 'email']))).length &&
      !_.compact(_.values(this.state.error)).length
    ) {
      this.setState({
        showError: false, errorMessage: null, resultList: []
      }, () => {
        searchProductAccessControl(_.pick(this.state, ['token', 'coupon', 'email']))
          .then(res => {
            const { data } = res
            if (data && data.length) {
              this.setState({
                token: '',
                coupon: '',
                email: '',
                resultList: data,
                showError: false,
                errorMessage: null
              })
            } else {
              this.setState({
                token: '',
                coupon: '',
                email: '',
                resultList: [],
                showError: true,
                errorMessage: 'No hay resultados'
              })
            }
          })
          .catch(err => {
            console.error('ERROR : ', err.toString())
            const status = err.response && err.response.status ? err.response.status : 500
            this.setState({
              token: '',
              coupon: '',
              email: '',
              resultList: [],
              showError: true,
              errorMessage: getStatusMessage(status, 'es')
            })
          })
      })
    } else {
      this.setState(prevState => ({
        showError: true,
        errorMessage: _.compact(_.values(prevState.error)).length ?
          'Por favor, revisa los campos de búsqueda' : 'Por favor, indica al menos un campo de búsqueda',
        resultList: []
      }))
    }
  }

  openResetModal = productKey => {
    const itemToReset = this.state.resultList.find(item => item.productKey === productKey)
    if (itemToReset) {
      this.setState({
        showResetModal: true,
        itemToReset,
        showError: false,
        errorMessage: null
      })
    } else {
      this.setState({
        showResetModal: false,
        itemToReset: null,
        showError: true,
        errorMessage: getStatusMessage(500, 'es')
      })
    }
  }

  closeResetModal = () => (
    this.setState({
      showResetModal: false,
      itemToReset: null,
      showError: false,
      errorMessage: null
    })
  )

  reset = itemToReset => {
    this.setState({
      showError: false,
      errorMessage: null,
      showResetModal: false,
      itemToReset: null
    }, () => {
      doProductAccessControlOperation(
        itemToReset.productKey,
        PRODUCT_ACCESS_CONTROL_OPERATIONS.RESET,
        _.pick(itemToReset, ['orderId', 'detailId', 'registeredDevices'])
      )
        .then(() => {
          const result = this.state.resultList.map(item => {
            if (item.productKey === itemToReset.productKey) {
              item.registeredDevices = 0
            }
            return item
          })
          this.setState({ resultList: result })
        })
        .catch(err => {
          console.error('ERROR : ', err.toString())
          const status = err.response && err.response.status ? err.response.status : 500
          this.setState({
            showError: true,
            errorMessage: getStatusMessage(status, 'es')
          })
        })
    })
  }

  viewLog = productKey => {
    const itemToCheckLog = this.state.resultList.find(item => item.productKey === productKey)
    if (itemToCheckLog) {
      doProductAccessControlOperation(
        itemToCheckLog.productKey,
        PRODUCT_ACCESS_CONTROL_OPERATIONS.LOG,
        _.pick(itemToCheckLog, ['orderId', 'detailId'])
      )
        .then(res => {
          const { data } = res
          if (data && data.log && data.log.length) {
            this.setState({
              showLog: true,
              itemToCheckLog,
              logRows: data.log,
              showError: false,
              errorMessage: null
            })
          } else {
            this.setState({
              showLog: false,
              itemToCheckLog: null,
              logRows: [],
              showError: true,
              errorMessage: 'No hay resultados'
            })
          }
        })
        .catch(err => {
          console.error('ERROR : ', err.toString())
          const status = err.response && err.response.status ? err.response.status : 500
          this.setState({
            showLog: false,
            itemToCheckLog: null,
            logRows: [],
            showError: true,
            errorMessage: getStatusMessage(status, 'es')
          })
        })
    } else {
      this.setState({
        showLog: false,
        itemToCheckLog: null,
        logRows: [],
        showError: true,
        errorMessage: getStatusMessage(500, 'es')
      })
    }
  }

  back = () => {
    this.state.showLog ?
      this.setState({
        showLog: false,
        itemToCheckLog: null,
        logRows: [],
        showError: false,
        errorMessage: null
      })
      :
      this.props.handlePanels()
  }

  render() {
    const {
      token, coupon, email, resultList, showError, errorMessage,
      showResetModal, itemToReset, showLog, itemToCheckLog, logRows,
      error: { token: tokenError, coupon: couponError, email: emailError }
    } = this.state

    return (
      <Fragment>
        <Container
          className="customer-user-screen-container product-access-control"
          fluid={true}
        >
          <Row
            className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray"
          >
            <Col>
              <h3 className="text-primary font-weight-bold mb-0">Control Acceso Producto</h3>
            </Col>
          </Row>

          {showLog ?
            (<ProductAccessLog item={itemToCheckLog} log={logRows} />)
            :
            (
              <Fragment>
                <Form>
                  <Row
                    className="align-items-center pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray"
                  >
                    <Col xs='12' md='6' lg='3'>
                      <FormGroup className={tokenError ? 'form-group-error' : ''}>
                        <Label className="font-weight-bold" for="token">
                          Token Biblioteca Digital
                        </Label>
                        <Input
                          type="text"
                          size="100"
                          name="token"
                          id="token"
                          value={token} placeholder=""
                          onChange={this.handleInputChange}
                          onKeyPress={this.onEnter}
                          className={tokenError ? 'is-invalid' : ''}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs='12' md='6' lg='3'>
                      <FormGroup className={couponError ? 'form-group-error' : ''}>
                        <Label className="font-weight-bold" for="coupon">
                          Cupón
                        </Label>
                        <Input
                          type="text"
                          size="100"
                          name="coupon"
                          id="coupon"
                          value={coupon}
                          placeholder=""
                          onChange={this.handleInputChange}
                          onKeyPress={this.onEnter}
                          className={couponError ? 'is-invalid' : ''}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs='12' md='6' lg='3'>
                      <FormGroup className={emailError ? 'form-group-error' : ''}>
                        <Label className="font-weight-bold" for="email">
                          Correo Electrónico
                        </Label>
                        <Input
                          type="text"
                          size="100"
                          name="email"
                          id="email"
                          value={email}
                          placeholder=""
                          onChange={this.handleInputChange}
                          onKeyPress={this.onEnter}
                          className={emailError ? 'is-invalid' : ''}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs='12' md='6' lg='3'>
                      <Button
                        className="font-weight-normal"
                        color="secondary"
                        size="md"
                        onClick={this.search}
                      >
                        Buscar
                      </Button>
                    </Col>
                  </Row>
                </Form>

                {showError && errorMessage && (
                  <Row
                    className="align-items-center pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray"
                  >
                    <Col xs="12">
                      <span className="font-weight-bold text-danger">
                        {errorMessage}
                      </span>
                    </Col>
                  </Row>
                )}

                {resultList && resultList.length > 0 && (
                  <Row className="gray small">
                    <Col xs="12">
                      <Table size="sm" responsive={true}>
                        <thead>
                          <tr bgcolor="#5393d4">
                            <th className="align-middle">Biblioteca Digital</th>
                            <th className="align-middle">Correo electrónico</th>
                            <th className="align-middle">Cupón</th>
                            <th className="align-middle">Producto interno online</th>
                            <th className="align-middle">Licencia</th>
                            <th className="align-middle">Nº dispositivos</th>
                            <th className="align-middle" colSpan="2">Resetear</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resultList.map((product, index) => (
                            <tr key={index}>
                              <td className="align-middle">{product.token}</td>
                              <td className="align-middle">{product.email}</td>
                              <td className="align-middle">{product.coupon}</td>
                              <td className="align-middle">{product.name}</td>
                              <td className="align-middle">{product.license}</td>
                              <td className="align-middle text-center">
                                {product.registeredDevices === -1 ? 0 : product.registeredDevices}
                              </td>
                              <td className="align-middle text-center">
                                <Button
                                  disabled={product.registeredDevices < 1}
                                  title="Resetear"
                                  className="glyphicon glyphicon-remove btn-sm"
                                  aria-hidden="true"
                                  onClick={() => this.openResetModal(product.productKey)}
                                />
                              </td>
                              <td className="align-middle text-center">
                                <Button
                                  title="Log"
                                  className="glyphicon glyphicon-eye-open btn-sm"
                                  aria-hidden="true"
                                  onClick={() => this.viewLog(product.productKey)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                )}
              </Fragment>
            )
          }

          <Row className="align-items-center pt-2 pb-2 ml-1 mr-1">
            <Col>
              <Button
                className="font-weight-normal"
                color="secondary"
                size="md"
                onClick={this.back}
              >
                Volver
              </Button>
            </Col>
          </Row>
        </Container>

        {itemToReset && (
          <Modal2Buttons
            isOpen={showResetModal}
            toggle={this.closeResetModal}
            header="Resetear dispositivos"
            primaryButtonLabel="Aceptar"
            secondaryButtonLabel="Cancelar"
            primaryButtonOnClick={() => this.reset(itemToReset)}
            secondaryButtonOnClick={this.closeResetModal}
          >
            <ul>
              <li>
                <strong>Biblioteca Digital:</strong>&nbsp;{itemToReset.token}
              </li>
              <li>
                <strong>Correo electrónico:</strong>&nbsp;{itemToReset.email}
              </li>
              <li>
                <strong>Cupón:</strong>&nbsp;{itemToReset.coupon}
              </li>
              <li>
                <strong>Producto:</strong>&nbsp;{itemToReset.name}
              </li>
              <li>
                <strong>Licencia:</strong>&nbsp;{itemToReset.license}
              </li>
              <li>
                <strong>Nº dispositivos:</strong>&nbsp;{itemToReset.registeredDevices}
              </li>
            </ul>
            <p className="text-center">
              ¿Confirmas el borrado de estos dispositivos?
            </p>
          </Modal2Buttons>
        )}
      </Fragment>
    )
  }
}

export default ProductAccessControlPanel