import React from 'react'
import { Button } from 'reactstrap'
import { withUserInfo } from '../../../utils/hocs'
import { withRouter } from 'react-router-dom'
import { PANELS } from '../constants'

const MenuPanel = (props) => {
  //const { handleStudentZone, handlePanels } = props
  const { handlePanels } = props
  return (
    <div className="menu-panel d-flex">
      <div className="menu-panel-button-container p-5 m-auto">
        {/*
        <Button className="menu-panel-button font-weight-normal mr-5" color="secondary" size="md" onClick={() => handleStudentZone(false, true)}>Administration</Button>
        <Button className="menu-panel-button font-weight-normal ml-5" color="secondary" size="md" onClick={() => handleStudentZone(true, false)}>SZ Administration</Button>
        */}
        <div className="d-flex justify-content-around">
          <Button className="menu-panel-button font-weight-normal m-2" color="secondary" size="md" onClick={() => handlePanels(PANELS.ADMIN)}>Administration</Button>
          <Button className="menu-panel-button font-weight-normal m-2" color="secondary" size="md" onClick={() => handlePanels(PANELS.STUDENT_AREA)}>SZ Administration</Button>
          <Button className="menu-panel-button font-weight-normal m-2" color="secondary" size="md" onClick={() => handlePanels(PANELS.ACCESS_CONTROL)}>Product Access Control</Button>
        </div>
      </div>
    </div>
  )
}

export default withUserInfo(withRouter(MenuPanel))