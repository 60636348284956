import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withRouter, NavLink } from 'react-router-dom'

import { ContentConsumer } from '../../language'

import envSettings from '../../globals/envSettings'

class SliderItem extends Component {

  render() {
    const { nodeRef, name, promotion } = this.props
    return (
      <ContentConsumer>
        {({ content }) => (content && (
          <NavLink exact={true}
            className="asset-card"
            to={{
              pathname: `/title/${nodeRef}/unit/all/cat/all/subcat/all`,
              search: `?initTab=0`
            }}
          >
            <div key={nodeRef} className="item-holder">
              <img className="myLibrarySlider__img rounded" src={`${envSettings.API_BASEURL}/cover/${nodeRef}`} alt={name} title={name} />
              {promotion === 1 && <div className="promotion-sticker">{content.mylibrary.promotion_sticker}</div>}
            </div>

          </NavLink>
        ))}
      </ContentConsumer>
    )
  }
}

SliderItem.propTypes = {
  nodeRef: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  promotion: PropTypes.number.isRequired
}

export default withRouter(SliderItem)
