// Libraries
import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'reactstrap';

export const Colors = (props) => {

  const { colors } = props

  return (
    <Row className="rainbow">
      {colors.map((d, idx) => (
        <Col key={idx} className={`rainbow__${d}`} />
      ))}
    </Row>
  );
};

Colors.propTypes = {
  colors: PropTypes.array
};