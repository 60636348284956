import React, { Fragment } from 'react';
import { Form,FormGroup,Label,Input,FormFeedback,Container,Row,Col } from 'reactstrap'
import {ContentConsumer} from '../../language'
import activateLicenseConstants from'../ActivateLicense/activateLicencseConstants'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import { Help } from'../Links/Links'
import { RecaptchaTerms } from '../Recaptcha/Recaptcha'

export const ActivateLicenseForm=(props)=>{
  const {manageOnSubmit,manageOnCancel,handleChangeCode,code,error,buttonLabel,data,errorLabel,buttonCancelLabel,status}=props
  return(
    <ContentConsumer>
    {({content={}})=>(content&&(
      <Fragment>
        <Form>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            {(data||(status&&status!==activateLicenseConstants.KO_LOCATE))?
              (data&&status!==activateLicenseConstants.KO_ACTIVATION)?
              <Container>
                {status===activateLicenseConstants.OK_ACTIVATION&&(<p className="searchOk"><strong>{content.activateLicense.exito13}</strong></p>)}
                <p className="searchOk"><strong>{code}</strong></p>
                <p className="searchOk"><strong>{content.activateLicense.productoDigital7} </strong>{data.productName}</p>
                <p className="searchOk"><strong>{content.activateLicense.libro8} </strong>{data.titleName}</p>
                <p className="searchOk"><strong>{content.activateLicense.fechaDeCaducidad9} </strong>{new Date(data.expires).getDate()+'/'+(new Date(data.expires).getMonth()+1)+'/'+new Date(data.expires).getFullYear()}</p>
                {status===activateLicenseConstants.OK_ACTIVATION&&(<p><strong>{content.activateLicense.exito14}</strong></p>)}
              </Container>
              :
              <Container>
                <p><strong>{content.activateLicense.error16}</strong></p>
                <p><strong>{content.activateLicense.error17+' '}</strong><a href='/app/Contact'><strong>{content.activateLicense.error18}</strong></a><strong>{' '+content.activateLicense.error19}</strong></p>
              </Container>
            :
            <Container>
              <Label for="" className="mr-sm-2 schoolFinder__label">{content.activateLicense.inputLabel3}</Label>
              <Input
                className="schoolFinder__input text-center"
                onChange={handleChangeCode}
                name="code"
                value={code}
                invalid={error.code?true:false}
                maxLength={14}
                autoComplete="off"
                placeholder={content.activateLicense.codigoDeActivacion2}
                />
                <FormFeedback>{errorLabel}</FormFeedback>
              </Container>
            }
          </FormGroup>
          <div className="mr-sm-2">
            <Row className="mt-3">
              <Col className="text-center">
                <Help/>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col sm={{size: 4, offset: (status===activateLicenseConstants.KO_ACTIVATION||
                                          status===activateLicenseConstants.OK_ACTIVATION)?"4":"2"}}
                   primary={(status===activateLicenseConstants.KO_ACTIVATION||
                             status===activateLicenseConstants.OK_ACTIVATION)?true:false}>
                <ButtonPremium  primary={(status===activateLicenseConstants.KO_ACTIVATION||
                                status===activateLicenseConstants.OK_ACTIVATION)?true:false}
                                onClick={manageOnCancel}>
                  {buttonCancelLabel}
                </ButtonPremium>
                </Col>
                {(!status||
                 (status!==activateLicenseConstants.KO_ACTIVATION&&
                  status!==activateLicenseConstants.OK_ACTIVATION))&&
                  (<Col sm="4">
                  <ButtonPremium  onClick={manageOnSubmit} primary>
                    {buttonLabel}
                  </ButtonPremium>
                  </Col>
                )}
            </Row>
          </div>
          <div className="my-2"><RecaptchaTerms /></div>
        </Form>
      </Fragment>
    ))}
    </ContentConsumer>
  )
}
