import React from 'react'
import { ContentConsumer } from '../../language'
import CMSContent from '../CMSContent/CMSContent'
import { isProductViewer } from '../../globals/envSettings'

const CONTENT_FOOTER = 'PREM_FOOTER'

const Footer = ({ location: { pathname } }) => {
  const productViewer = isProductViewer(pathname)
  return !productViewer ?
    (
      <ContentConsumer>
        {({ locale }) => (
          <CMSContent pageid={CONTENT_FOOTER} cache={true} language={locale} />
        )}
      </ContentConsumer>
    )
    :
    ( null )
}

export default Footer