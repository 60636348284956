import React from 'react'
import cx from 'classnames'

import { nFormatter } from '../../utils/format'

import thumbUpActive from './like_active.svg'
import thumbUpInactive from './like_inactive.svg'
import thumbDownActive from './dislike_active.svg'
import thumbDownInactive from './dislike_inactive.svg'

export default (props) => (
  <div className={ cx('thumbs', 'd-flex justify-content-around', props.className) }>
    <div className={ cx('thumb d-flex', { 'thumb-active': props.thumbUp, 'thumb-inactive': !props.thumbUp }) } onClick={ () => props.onUpdate(!props.thumbUp, false) }>
      <div className="mx-1"><img alt="" src={ props.thumbUp ? thumbUpActive : thumbUpInactive } /></div>
      <div className="d-inline-block mx-1 text-noselect">{ nFormatter(props.thumbsUp) }</div>
    </div>
    <div className={ cx('thumb d-flex', { 'thumb-active': props.thumbDown, 'thumb-inactive': !props.thumbDown }) } onClick={ () => props.onUpdate(false, !props.thumbDown) }>
      <div className="thumb-img mx-1"><img alt="" src={ props.thumbDown ? thumbDownActive : thumbDownInactive } /></div>
      <div className="d-inline-block mx-1 text-noselect">{ nFormatter(props.thumbsDown) }</div>
    </div>
  </div>
)
