import React,{Component,Fragment}from"react"
import _ from"lodash"
import axios from"axios"
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { Container,Row } from"reactstrap"

import { withUserInfo, withContent }from"../../utils/hocs"
import axiosConfig from"../../config/axios"
import PDCarousel from"../ProfessionalDevelopment/PDCarousel"

class PDNews extends Component{
  constructor(props){
    super(props)
    this.state={
      assets:undefined
    }
  }
  async getAssets(){
    try{
      const axiosInstance=axios.create(axiosConfig)
      const response=await axiosInstance.get("pd")
      if(response&&response.data&&response.data.categories.length&&response.data.assets&&response.data.assets.length){
	      const assets=_.sortBy(response.data.assets.map(asset=>({...asset,category:response.data.categories.filter(category=>category.uuid===asset.parent)[0].title})),['date']).reverse().slice(0,12)
        this.setState({assets:assets})
      }
    }
    catch(err){
      this.setState({categories:undefined})
    }
  }
  componentDidMount(){
    this.props.userInfo&&this.props.userInfo.pdPermission&&this.getAssets()
  }
  render(){
    const{assets}=this.state
    const{ className, content,userInfo}=this.props
    return(
      <Container className={cx(className)}>
        {userInfo&&userInfo.pdPermission&&assets&&(
	  <Fragment>
	    <Row className="professional-development">
              <PDCarousel
                className="my-5"
                title={content.sliderpd.sliderPD_title}
                titleClassName="pdnews-title text-uppercase"
                linkAfterTitle={ <span className="pdnews-title-link ml-2"> <Link to='/professional-development'>{ content.assets.view_all }</Link></span> }
                assets={assets}
              />
	    </Row>
          </Fragment>
        )}
      </Container>
    )
  }
}
export default withContent(withUserInfo(PDNews))
