import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { ContentConsumer } from '../../language'
import { Container, Row, Col, Progress } from 'reactstrap'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import { RecaptchaTerms } from '../Recaptcha/Recaptcha'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import BlinkCptLicenceContent from '../BlinkCptLicenceContent/BlinkCptLicenceContent'
import PropTypes from 'prop-types'
import axios from 'axios'
import axiosConfig from '../../config/axios'
import envSettings from '../../globals/envSettings'
import validator from 'validator'
import Url from 'url-parse'

class BlinkCptLicenceForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  acceptLicence = () => (
    this.setState({ loading: true }, async () => {
      window.scrollTo(0, 0)
      const { clienId, redirectUri, blinkState, history } = this.props
      const token = await this.props.googleReCaptchaProps.executeRecaptcha('oauth2Authorize')
      return axios.create(axiosConfig).post(
        `${envSettings.API_BASEURL}/oauth2/authorize`,
        { client_id: clienId, redirect_uri: redirectUri, state: blinkState, token }
      ).then(response => {
        const redirectUrl = response && response.data && response.data.redirectUrl ? response.data.redirectUrl : null
        if (redirectUrl && validator.isURL(redirectUrl)) {
          const url = new Url(redirectUrl, true)
          if (url.query && url.query.code) return window.location.href = redirectUrl
          console.error('KO OAUTH2 AUTHORIZE : Invalid redirectUrl', redirectUrl)
          return history.push('/oauth2-error')
        }
        console.error('KO OAUTH2 AUTHORIZE : Invalid redirectUrl', redirectUrl)
        return history.push('/oauth2-error')
      }).catch(error => {
        console.error('KO OAUTH2 AUTHORIZE :', error)
        return history.push('/oauth2-error')
      })
    })
  )

  render() {
    const { loading } = this.state
    return (
      <ContentConsumer>
        {({ content }) => content && (
          <Fragment>
            {loading && <Progress animated color="primary" value="100">{content.sharedTerms.LOADING_MESSAGE}</Progress>}
            <Container fluid={false} className="BlinkCptLicenceForm">
              <Row>
                <Col md={{ size: 8, offset: 2 }} className="text-center">
                  <h1 className="h1__oauth2">{content.oauth.authorize_title}</h1>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs={{ size: 12 }} md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }} className="text-center">
                  <BlinkCptLicenceContent />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={{ size: 6, offset: 3 }} className="text-center">
                  <Link className="link__help" to="/blinkcpt-help" target="_blank">
                    {content.sharedTerms.HELP_LINK}<span className="glyphicon glyphicon-question-sign"></span>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 8, offset: 2 }} sm={{ size: 6, offset: 3 }} md={{ size: 4, offset: 4 }} className="mt-4 text-center">
                  <Container>
                    <Row>
                      <Col xs={{ size: 12 }} lg={{ size: 8, offset: 2 }} className="text-center">
                        <ButtonPremium onClick={this.acceptLicence} tabIndex="1" primary>
                          {content.sharedTerms.ACCEPT_BUTTON_TEXT}
                        </ButtonPremium>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col md={{ size: 8, offset: 2 }} className="text-center my-2">
                  <RecaptchaTerms />
                </Col>
              </Row>
            </Container>
          </Fragment>
        )}
      </ContentConsumer>
    )
  }
}

BlinkCptLicenceForm.propTypes = {
  clienId: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired
}

export default withRouter(withGoogleReCaptcha(BlinkCptLicenceForm))