import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { AuthConsumer } from 'react-check-auth'
import envSettings from '../../globals/envSettings'

const scriptText =
  `
var Comm100API=Comm100API||{};(function(t){function e(e){var a=document.createElement("script"),c=document.getElementsByTagName("script")[0];a.type="text/javascript",a.async=!0,a.src=e+t.site_id,c.parentNode.insertBefore(a,c)}t.chat_buttons=t.chat_buttons||[],t.chat_buttons.push({code_plan:1952,div_id:"comm100-button-1952"}),t.site_id=1000332,t.main_code_plan=1952,e("https://ent.comm100.com/chatserver/livechat.ashx?siteId="),setTimeout(function(){t.loaded||e("https://appmax1.comm100.com/chatserver/livechat.ashx?siteId=")},5e3)})(Comm100API||{})
Comm100API.chatGroup = 1;
`
class Chat extends Component {
  componentDidMount() {
    const { userInfo } = this.props
    if (userInfo && userInfo.countryId && userInfo.countryId === envSettings.COUNTRY_ESP_ID) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.appendChild(document.createTextNode(scriptText))
      this.instance.appendChild(script)
    }
  }

  componentWillUnmount() {
    const { userInfo } = this.props
    if (userInfo && userInfo.countryId && userInfo.countryId === envSettings.COUNTRY_ESP_ID) {
      ReactDOM.unmountComponentAtNode(document.getElementById('comm100-button-1952'))
      ReactDOM.unmountComponentAtNode(document.getElementById('chat-script'))
    }
  }

  render() {
    const { userInfo } = this.props
    return (userInfo && userInfo.countryId && userInfo.countryId === envSettings.COUNTRY_ESP_ID) ?
      (
        <Fragment>
          <div id="comm100-button-1952">&nbsp;</div>
          <div id="chat-script" ref={el => (this.instance = el)} />
        </Fragment>
      )
      :
      null
  }
}

export default props => (
  <AuthConsumer>
    {({ userInfo }) => (
      <Chat userInfo={userInfo} {...props} />
    )}
  </AuthConsumer>
)