import {
  convertStringToDateWithoutTime, compareDates, formatDateToLocaleDateShort
} from '../../utils/date'
import validator from 'validator'
import { interpolate, PLACEHOLDERS } from '../../language'
import envSettings from '../../globals/envSettings'

export const backupCards = [
  /*{
    title: 'A practical guide to the LOMLOE',
    content: '<ul><li>In English</li><li>For all ELT teachers</li><li>30 hours</li><li>6 modules</li><li>Practical focus on the key pillars of the educational reform</li></ul>',
    masterCourse: 'Master_LOMLOE1_GUIDE'
  },
  {
    title: 'Guía práctica para la LOMLOE',
    content: '<ul><li>En castellano</li><li>Para todos los profesores</li><li>30 horas</li><li>6 módulos</li><li>Enfoque práctico en los pilares fundamentales de la reforma educativa</li></ul>',
    masterCourse: 'Master_LOMLOE2_GUIA'
  },
  {
    title: 'Linguistic Mediation for Secondary teachers',
    content: '<ul><li>In English</li><li>For ELT Secondary teachers</li><li>15 hours</li><li>6 modules</li><li>Practical focus on the main characteristics of mediation</li></ul>',
    masterCourse: 'Master_LOMLOE3_MEDIATION'
  },
  {
    title: 'Liderazgo Educativo',
    content: '<ul><li>En castellano</li><li>Para directores, coordinadores, equipos de titularidad</li><li>15 horas</li><li>3 módulos</li><li>Bienestar organizacional</li><li>Herramientas de gestión y resolución de conflictos</li></ul>',
    masterCourse: 'Master_LIDERAZGO1'
  },
  {
    title: 'Mediation for Primary teachers',
    content: '<ul><li>In English</li><li>For ELT Primary teachers</li><li>15 hours</li><li>4 modules</li><li>Practical focus on the main characteristics of Mediation</li></ul>',
    masterCourse: 'Master_MEDIATIONPRIMARY1'
  }
  */
  {
    title: 'Classroom Language',
    content: '<ul><li>In English</li><li>For all ELT and CLIL Primary and Secondary teachers</li><li>15 hours</li><li>3 modules</li><li>Practical vocabulary and classroom expressions</li><li>Pronunciation and intonation practice</li></ul>',
    masterCourse: 'Master_CLASSROOM1'
  },
  {
    title: 'Bienestar en el aula',
    content: '<ul><li>En castellano</li><li>Para profesorado de todas las etapas</li><li>15 horas</li><li>4 módulos</li><li>El bienestar en el aula: beneficios y aplicación práctica.</li><li>Conceptos neurocientíficos aplicados al aula.</li></ul>',
    masterCourse: 'Master_BIENESTAR1'
  }
]

export const adjustCards = cards => {
  let styleCardsCol = 'text-left'
  if (cards && cards.length && cards.length === 1) {
    styleCardsCol = styleCardsCol + ' offset-md-3 offset-lg-4'
  }
  return styleCardsCol
}

// MBQ TODO Revisar uso de announcement.isCurrent con SP convocatoria
export const fillAnnouncement = (announcement, locale) => {
  let result = null
  if (announcement) {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const startDate = convertStringToDateWithoutTime(announcement.registrationStartDate)
    startDate.setHours(0, 0, 0, 0)
    const endDate = convertStringToDateWithoutTime(announcement.registrationEndDate)
    endDate.setHours(0, 0, 0, 0)
    announcement.registrationStartDateShort = formatDateToLocaleDateShort(startDate, locale)
    announcement.registrationEndDateShort = formatDateToLocaleDateShort(endDate, locale)
    announcement.registrationOn = (
      announcement.isCurrent &&
      [0, 2].includes(compareDates(startDate, today)) &&
      [0, 1].includes(compareDates(endDate, today))
    ) ? true : false
    if (!announcement.registrationOn && announcement.nextAnnouncement && announcement.nextAnnouncement.registrationStartDate) {
      announcement.nextAnnouncement.registrationStartDateShort =
        formatDateToLocaleDateShort(convertStringToDateWithoutTime(announcement.nextAnnouncement.registrationStartDate), locale)
    }
    result = announcement
  }
  return result
}

export const fillCurrentRegistration = (registration, locale) => {
  let result = null
  if (registration) {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const startDate = registration.courseAccessDate ?
      convertStringToDateWithoutTime(registration.courseAccessDate) : convertStringToDateWithoutTime(registration.courseStartDate)
    startDate.setHours(0, 0, 0, 0)
    const endDate = convertStringToDateWithoutTime(registration.courseEndDate)
    endDate.setHours(0, 0, 0, 0)
    registration.courseStartDateShort = formatDateToLocaleDateShort(startDate, locale)
    registration.courseEndDateShort = formatDateToLocaleDateShort(endDate, locale)
    registration.accessOn = (
      registration.courseLtiKey &&
      registration.courseLtiSecret &&
      registration.courseLtiUrl &&
      validator.isURL(registration.courseLtiUrl) &&
      [0, 2].includes(compareDates(startDate, today)) &&
      [0, 1].includes(compareDates(endDate, today))
    ) ? true : false
    result = registration
  }
  return result
}

export const sanitizeField = (field, blacklist = ['<p>', '</p>', '\n']) => {
  let result = field
  blacklist.forEach(element => {
    result = result.replace(element, '')
  })
  return result
}

export const checkCompletedCourses = (courses, registrations) => {
  if (!registrations || registrations.length === 0) return courses
  return courses.map(course => {
    course.completed = registrations.some(registration => (
      registration.lmsMasterCourseId === course.lmsMasterCourseId)
    )
    return course
  })
}

export const getCatalogue = (cards, completedRegistrations, courses, registration) => {
  let catalogue = []
  if (cards && cards.length > 0) {
    catalogue = cards.reduce((acc, card) => {
      if (
        (completedRegistrations && completedRegistrations.length &&
          completedRegistrations.some(registration => registration.lmsMasterCourseId === card.masterCourse)) ||
        (registration && registration.lmsMasterCourseId === card.masterCourse) ||
        (courses && courses.length &&
          !courses.some(course => course.lmsMasterCourseId === card.masterCourse))
      )
        return acc
      acc.push(card)
      return acc
    }, [])
  }
  return catalogue
}

export const MIN_BIRTHDAY = "1900-01-01"

export const getMaxBithday = () => {
  const today = new Date()
  const maxDate = `${today.getFullYear()}-${((today.getMonth() + 1) + '').padStart(2, '0')}-${(today.getDate() + '').padStart(2, '0')}`
  return maxDate
}

export const MAX_BIRTHDAY = getMaxBithday()

export const validateBirthday = birthday => {
  if (!birthday || !/^\d{4}-\d{1,2}-\d{1,2}$/.test(birthday)) return false
  const birthdayDate = convertStringToDateWithoutTime(birthday)
  birthdayDate.setHours(0, 0, 0, 0)
  const minDate = convertStringToDateWithoutTime(MIN_BIRTHDAY)
  minDate.setHours(0, 0, 0, 0)
  const maxDate = convertStringToDateWithoutTime(MAX_BIRTHDAY)
  maxDate.setHours(0, 0, 0, 0)
  if (
    ![0, 2].includes(compareDates(minDate, birthdayDate)) ||
    ![0, 1].includes(compareDates(maxDate, birthdayDate))
  ) return false
  return true
}

export const REGISTRATION_ACTION = {
  INSERT: 'ALTA',
  DELETE: 'BAJA'
}

export const TABS = {
  ANNOUNCEMENT: '1',
  MY_COURSES: '2',
  COMPLETED_COURSES: '3'
}

export const getTitle = (announcement, isCurrentRegistration, glossary_pdcourses) => {
  let title = null
  if (isCurrentRegistration) {
    title = glossary_pdcourses.mycourses_catalogue_title_1
  } else if (announcement && announcement.isCurrent && announcement.registrationOn) {
    title = interpolate(glossary_pdcourses.current_announcement_title_1, PLACEHOLDERS.NAME, announcement.description)
  } else {
    title = glossary_pdcourses.noncurrent_announcement_title_1
  }
  return title
}

export const getSubTitle = (announcement, isCurrentRegistration, glossary_pdcourses) => {
  let subTitle = null
  if (isCurrentRegistration) {
    subTitle = glossary_pdcourses.mycourses_catalogue_title_2
    //} else if (announcement && announcement.isCurrent && announcement.registrationOn) {
  } else if (announcement && announcement.isCurrent) {
    if (announcement.registrationOn) {
      subTitle = interpolate(glossary_pdcourses.current_announcement_title_2, PLACEHOLDERS.DATE, announcement.registrationEndDateShort)
    } else {
      subTitle = announcement.nextAnnouncement && announcement.nextAnnouncement.registrationStartDateShort ?
        interpolate(glossary_pdcourses.noncurrent_announcement_title_2, PLACEHOLDERS.DATE, announcement.nextAnnouncement.registrationStartDateShort)
        : ''
    }
  } else if (announcement && !announcement.isCurrent) {
    subTitle = interpolate(glossary_pdcourses.noncurrent_announcement_title_2, PLACEHOLDERS.DATE, announcement.registrationStartDateShort)
  } else {
    subTitle = ''
  }
  return subTitle
}

export const getCourseMiniature = (masterCourse, locale) => (
  `${envSettings.STATIC_URL || 'https://static.dev.oupe.es'}/pdcourses/${masterCourse}_${locale}.png`
)