import envSettings, { isPreProEnv, isProductionEnv } from '../../globals/envSettings'
import axiosConfig from '../../config/axios'

const PROMOTION_WP_SETTINGS = {
  MAX_ITEMS: 100,
  LANGUAGES_ROOT: 310,
  HOME_CATEGORY: 656,
  TITLE_DETAIL_CATEGORY: 657
}

// MBQ TODO REVIEW TITLE_DETAIL_CATEGORY
const PROMOTION_WP_SETTINGS_PRE = {
  HOME_CATEGORY: 679,
  TITLE_DETAIL_CATEGORY: 657
}

// MBQ TODO REVIEW TITLE_DETAIL_CATEGORY
const PROMOTION_WP_SETTINGS_PRO = {
  HOME_CATEGORY: 682,
  TITLE_DETAIL_CATEGORY: 657
}

const getPromotionSettings = () => (
  isPreProEnv() ?
    { ...PROMOTION_WP_SETTINGS, ...PROMOTION_WP_SETTINGS_PRE }
    :
    isProductionEnv() ?
      { ...PROMOTION_WP_SETTINGS, ...PROMOTION_WP_SETTINGS_PRO }
      :
      PROMOTION_WP_SETTINGS
)


export const ClientSettings = {
  ...axiosConfig,
  baseURL: envSettings.WORDPRESS_API_BASEURL,
  withCredentials: false
}

export default getPromotionSettings()