export const regexpAlphaWithSpaces = /^[A-ZÑÁÀÂÄÃÅÆÇÉÈÊËÍÌÏÎÓÒÔÖÕŒÚÙÛÜŸÝ \-'.ºª·]+$/i
export const regexAlphaNumeric = /^[0-9A-Z]+$/i
export const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])\S{6,}$/

export const validateDate = date => {
  // First check for the pattern
  if(!/^\d{4}-\d{1,2}-\d{1,2}$/.test(date))
      return false;

  // Parse the date parts to integers
  var parts = date.split("-");
  var day = parseInt(parts[2], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[0], 10);

  // Check the ranges of month and year

  var dt = new Date();

  //if(year >= dt.getFullYear() || month === 0 || month > 12)
  if(year > dt.getFullYear() || month === 0 || month > 12)
      return false;

  var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

  // Adjust for leap years
  if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
      monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}
