import React from 'react'

import ButtonPremium from '../ButtonPremium/ButtonPremium'
import Feature from '../Feature/Feature'

import { withContent, withUserInfo } from '../../utils/hocs'

import envSettings from '../../globals/envSettings'
import UserRoles from '../../globals/userRoles'

const NeedHelpButton = ({ content, userInfo, onClick }) => {
  if(([UserRoles.TEACHER, UserRoles.TEACHER_FAKE].find((item) => item === userInfo.role))
    && (envSettings.COUNTRY_ESP_ID === userInfo.countryId)) {
    return (
      <Feature name="carousel">
        <div className="d-none d-sm-block mt-5">
          <ButtonPremium
            primary
            onClick={onClick}
          >
            {content.titledetail.need_help}
          </ButtonPremium>
        </div>
      </Feature>
    )
  }
  return null
}

export default withContent(withUserInfo(NeedHelpButton))
