import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom'
// import Jumbotron from '../Jumbotron/Jumbotron'
import { AuthConsumer } from 'react-check-auth'
import TopBanners from '../TopBanners/TopBanners'
// import { isTeacherRoleAsync, isTeacherDemoAsync, hasAccessToGratisHP } from '../Banner/validators'
import MyLibrarySlider from '../MyLibrarySlider/MyLibrarySlider'
import BreakingNews from '../BreakingNews/BreakingNews'
import News from '../News/News'
import Notifications from '../Notifications/Notifications'
import PromotionHome from '../PromotionHome/PromotionHome'
import PlusLibrary from '../PlusLibrary/PlusLibrary'
import { PROFILES } from '../../globals/userRoles'
import { isPlusPortal } from '../../globals/envSettings'
import PDNews from"../PDNews/PDNews"
import Background from './HPHeader.png'
import { ManagedSurvey } from 'components/Polls'
import { PARENT_COMPONENT } from 'components/Polls/ManagedSurvey/ManagedSurvey'

const HomePrivate = () => (
  <AuthConsumer>
    {({ userInfo }) => {
      if (userInfo.profile === PROFILES.SUPPORT_USER) {
        return <Redirect to='/admin' />
      }
      if (userInfo.profile === PROFILES.CUSTOMER_USER_PREMIUM) {
        return (
          !isPlusPortal() ?
            (
              <Fragment>
                <Notifications />
                <div
                  style={{
                    width: '100%',
                    height: 'auto',
                    backgroundImage: `url(${Background})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                  className="homeprivate py-5">
                  <TopBanners />
                </div>
                <BreakingNews className="my-5" />
                {/* <Notifications /> */}
                <div className="myLibrarySlider-container py-5">
                  <MyLibrarySlider />
                </div>
                <PDNews/>
                <div className="news-container pb-5">
                  <News className="pt-4" />
                </div>
                <PromotionHome />
                <ManagedSurvey parent={PARENT_COMPONENT.HOME} />
              </Fragment>
            )
            :
            (
              <PlusLibrary />
            )
        )
      }
      if (userInfo.profile === PROFILES.CUSTOMER_USER_PLUS) {
        return <PlusLibrary />
      }
      return <Redirect to='/error' />
    }}
  </AuthConsumer>
)

export default HomePrivate