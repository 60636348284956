import React from 'react'
import { ContentConsumer } from '../../language'
import CMSContent from '../CMSContent/CMSContent'

const CONTENT_PREM_CONTACT = 'PREM_CONTACT'

const ContactPage = () => (
  <ContentConsumer>
    {({ locale }) => (
      <CMSContent pageid={CONTENT_PREM_CONTACT} cache={true} language={locale} />
    )}
  </ContentConsumer>
)

export default ContactPage