const changeschoolConstants = {
    NO_SEARCH: 0,
    OK_LOCATE: 1,
    KO_LOCATE: 2,
    KO_MOVE: 3,
    KO_PERMISION: 4,
    SUCCESS: 5,
    KO_CHANGESCHOOL: 6,
    KO_SAMESCHOOL: 7,
    KO_OTHERCOUNTRY: 8
}

export default changeschoolConstants