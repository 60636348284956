import React, { Component } from 'react'
import _ from 'lodash'
//import mockUp from './MockUp'
import axios from 'axios'
import PHPSettings, { ClientSettings } from './settings'

import PHPContent from './PHPContent'
import PHPHeader from './PHPHeader'

class PHPShowElement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      carouselItems: [],
      contentItems: [],
      error: false
    }
  }
    
  componentDidMount() {
    this.getAssets(PHPSettings[this.props.language])
  }

  getAssets(language) {
    const categories = [PHPSettings.PHP_CAROUSEL_ROOT, PHPSettings.PHP_CONTENT_ROOT]
    const catList = categories.map((id) => (id)).join('+')
    const PhpClient = axios.create(ClientSettings)

    PhpClient.get(`posts/?_embed=&per_page=${PHPSettings.PHP_MAX_ITEMS}&categories=${catList}`)
      .then((response) => {
        //let carouselItems = mockUp.map(item => {
        let listOfItems = response.data.map(item => {
          const element = {
             id : item.id,
             date: item.date && new Date(item.date),
             slug: item.slug,
             status : item.status,
             categories: item.categories,
             title : _.get(item, 'title.rendered'),
             contentItem : _.get(item, 'content.rendered'),
             excerpt: _.get(item, 'excerpt.rendered')
          }

          let image = _.get(item, ['_embedded', 'wp:featuredmedia', 0, 'media_details', 'sizes', 'full', 'source_url'])

          if(image) {
            element.image = {
              url : image,
              alt :_.get(item, [ '_embedded', 'wp:featuredmedia', 0, 'alt-text' ])
              }
          }
          return element
        })

        const languages = _.compact([ language, PHPSettings.PHP_LANGUAGES_ROOT ])
        const carouselItems = listOfItems.filter(item=> item.categories.some((val)=>languages.indexOf(val)!==-1) && item.categories.includes(PHPSettings.PHP_CAROUSEL_ROOT))
        const contentItems = listOfItems.filter(item=> item.categories.some((val)=>languages.indexOf(val)!==-1) && item.categories.includes(PHPSettings.PHP_CONTENT_ROOT))

        this.setState({ carouselItems : _.sortBy(carouselItems, ['date']).reverse()
                      , contentItems : _.sortBy(contentItems, ['date']).reverse()
                      })
      })
      .catch((error) => {
        this.setState({ error })
      })
  }

  render() {
    const { carouselItems, contentItems, error } = this.state
    const { content } = this.props

    if(error || (carouselItems.length===0 && contentItems.length===0)) return []
    else
    return (
      <div>
        {
        carouselItems && carouselItems.length !==0 &&
        <div className="homepage-header">
          <PHPHeader
            title="Header"
            assets={carouselItems}
            content={content}
          />
        </div>
        }
        {
        contentItems && contentItems.length !==0 &&
        <div className="homepage-content">
          <PHPContent
            title="Content"
            assets={contentItems}
            content={content}
          />
        </div>
        }
      </div>
    )
  }
}

export default PHPShowElement