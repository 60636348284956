import React, { Component } from 'react'
import { Progress } from 'reactstrap'
import axios from 'axios'
import axiosConfig from '../../config/axios'
import NewsItem from './NewsItem'
import { AuthConsumer } from 'react-check-auth'
import { ContentConsumer } from '../../language'
import { Container, Row, Col, Nav, NavItem, NavLink as Navlink } from 'reactstrap'
import userRoles from '../../globals/userRoles'
import _ from 'lodash'
import cx from 'classnames'

import NewsSettings, { ClientSettings } from './settings'

class News extends Component {
    constructor(props) {
        super(props)
        this.state = {
          newsFeed: [],
          error: false,
          activeTab: 0,
          filteredNewsFeed: []
        }
    }

  getFilteredNewsFeed = async(newsFeed, categories) => {
    if (this.props.userInfo.role !== userRoles.USER_INTERNAL ) {
      // Get valid categories
      try {
        const axiosInstance = axios.create(axiosConfig)
        const response = await axiosInstance.get('news')
        const preferences = response.data
        const languages = _.compact([ preferences.language, NewsSettings.NEWS_LANGUAGES_ROOT ])
        const stages = [ ...preferences.stage, NewsSettings.NEWS_STAGES_ROOT ]
        const subjects = [ ...preferences.subject, NewsSettings.NEWS_AREAS_ROOT ]

        // The countries is a special case, we must take user region, his country and the whole root
        // We will take the whole country tree in order to use the same url for every
        const regions = _.compact([ preferences.region, preferences.country, NewsSettings.NEWS_COUNTRIES_ROOT ])

        // Filter the news
        newsFeed = newsFeed && newsFeed.reduce((acc, news) => {
          if(!_.intersection(news.filterCategories,languages).length) return acc
          if(!_.intersection(news.filterCategories,stages).length) return acc
          if(!_.intersection(news.filterCategories,subjects).length) return acc
          if(!_.intersection(news.filterCategories,regions).length) return acc

          delete news.filterCategories
          acc.push(news)
          return acc
        }, [])
      } catch(error) {
        this.setState({error})
      }
    }

    const categoriesWithContent = categories.filter(item => newsFeed.find(news => news.categories && news.categories.find(newsCat => newsCat && (newsCat.id === item.id))))
    // If we have only 1 category, "All" must not be shown
    if(categoriesWithContent.length>1) {
      categoriesWithContent.unshift({ id: 0, slug: 'all', name: 'All' })
    }

    this.setState({
        newsFeed,
        filteredNewsFeed: newsFeed,
        categories : categoriesWithContent,
        activeTab : (categoriesWithContent.length ===0) || (categoriesWithContent.length > 1)?0:categoriesWithContent[0].id})
    }

    componentDidMount() {
      this.getNewsFeed()
    }

    getNewsFeed = () => {
      let categories = []

      const NewsClient = axios.create(ClientSettings)

      NewsClient.get(`categories?parent=${NewsSettings.NEWS_CATEGORY_ROOT}`)
        .then(response => {

          // We must remove suffix to the slug
          // We only have 6 different colors
          categories = response.data && response.data.map((item, index) => Object.assign({}, _.pick(item, ['id','slug', 'name', 'description']), { slug: item.slug && item.slug.replace(/_.*/, '') }, { color : index % 6 }))

          this.setState({ categories, activeTab: 0 })

          const catList = categories.map((cat) => (cat.id)).join('+')

          NewsClient.get(`posts/?_embed=&per_page=${NewsSettings.NEWS_MAX_ITEMS}&categories=${catList}`)
            .then((response) => {
              // Simplify backend object
              let newsFeed = response.data.reduce((acc, item) => {
                const news = {
                  id : item.id,
                  date: item.date && new Date(item.date),
                  slug: item.slug,
                  status : item.status,
                  title : _.get(item, 'title.rendered'),
                  content : _.get(item, 'content.rendered'),
                  excerpt: _.get(item, 'excerpt.rendered'),
                  sticky: item.sticky,
                  filterCategories: item.categories,
                  categories: [_.first(item.categories && (item.categories.length >0) && item.categories.reduce((acc,idCategory) => {
                    const category = categories.find(category => category.id===idCategory)
                    if(category) acc.push(category)
                    return acc
                  }, []))]
                }

                let image = _.get(item, ['_embedded', 'wp:featuredmedia', 0, 'media_details', 'sizes', 'full', 'source_url'])

                if(image) {
                  news.image = {
                    url: image,
                    alt: _.get(item, [ '_embedded', 'wp:featuredmedia', 0, 'alt-text' ])
                  }
                }

                if(news.id && news.title && news.content && news.image) acc.push(news)
                return acc
              }, [])

              // We must show sticky news first
              newsFeed.sort((a,b) => {
                if(a.sticky && !b.sticky) return -1
                if(a.sticky && b.sticky) return 0
                if(!a.sticky && b.sticky) return 1
                return 0
              })

              // Now we have to filter the news.
              // Get user preferences
              this.getFilteredNewsFeed(newsFeed, categories)
          })
            .catch((error) => {
              this.setState({ error })
            })
        })
        .catch(error => {
          this.setState({ error })
        })
    }

    handleTab = (tab) => {
      if (this.state.activeTab !== tab && this.state.categories.some((cat) => cat.id === tab)) {
        // 0 is the id for "All"
        (tab !== 0) ?
          this.setState({
            activeTab: tab,
            // News may have multiple categories
            filteredNewsFeed: this.state.newsFeed.filter((news) => news.categories.find(newsCat => newsCat && newsCat.id === tab))
          }) :
          this.setState({
            activeTab: tab,
            filteredNewsFeed: this.state.newsFeed
          })
      }
    }

    render() {
      const { className } = this.props
      const { filteredNewsFeed, newsFeed, error, activeTab, categories } = this.state
      if(error || newsFeed.length===0) return []
      else
        return (
          <ContentConsumer>
            {({ content = {}, isContentLoading }) => {
              if (content) {
                if (isContentLoading) {
                  return <Progress animated color="primary" value="100" > {content.news.news_loading} </Progress>
                }
                return <Container className={cx("newsFeed py-5", className)}>
                  <Row className="mb-3">
                    <Col lg="4" xs="12">
                      <h1 className="text-uppercase"> {content.news.news_title}</h1>
                    </Col>
                    <Col lg="8" xs="12" className="d-flex justify-content-md-end justify-content-center" >
                      <Nav className="newsFeed__navTab flex-column flex-md-row" tabs>
                        {typeof categories !== "undefined" && categories.map((cat) => (
                          <NavItem key={cat.id}>
                            <Navlink
                              className={`newsFeed__navTab__link ml-md-4 p-2 text-center text-md-left ${cat.id !== 0 ? 'text-uppercase' : ''} ${activeTab === cat.id ? 'active' : ''}`}
                              onClick={() => this.handleTab(cat.id)}>
                              { _.get(content.news, `news_category_${cat.slug}`, cat.name) }
                            </Navlink>
                          </NavItem>
                        ))}
                      </Nav>
                    </Col>
                  </Row>
                  <Row>
                    { !error && filteredNewsFeed.length > 0 && filteredNewsFeed.map((news) => (
                      <Col key={news.id} xs="6" md="4" lg="3">
                        <NewsItem news={news} />
                      </Col>
                    ))}
                  </Row>
                </Container>
                }
              }
            }
          </ContentConsumer>
        )
    }
}

export default props => (
  <AuthConsumer>
    {({ userInfo }) => <News userInfo={userInfo} {...props} />}
  </AuthConsumer>
)

//export default News
