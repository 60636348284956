import React from 'react'

import audio from './icons/audio.png'
import calc from './icons/calc.png'
import compressed from './icons/compressed.png'
import doc from './icons/doc.png'
import exe from './icons/exe.png'
import image from './icons/image.png'
import link from './icons/link.png'
import online from './icons/online.png'
import pdf from './icons/pdf.png'
import ppt from './icons/ppt.png'
import video from './icons/video.png'

export const icon = ({ externalURL, type, mimetype }) => {
  const toImg = icon => <img src={icon} alt="" />

  if((type === '{http://www.oupe.es/model}animacion') || (type === '{http://www.oupe.es/model}generadorEvaluacion') || (type === '{http://www.oupe.es/model}libroDigital')) {
    return toImg(online)
  }

  if(externalURL) return toImg(link)

  switch(mimetype) {
    case 'image/jpeg':
    case 'image/png':
      return toImg(image)
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
      return toImg(ppt)
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'text/plain':
    case 'application/rtf':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
    case 'application/vnd.oasis.opendocument.text':
    case 'application/vnd.ms-word.document.macroenabled.12':
    case 'application/vnd.ms-word.template.macroenabled.12':
    case 'application/xml':
      return toImg(doc)
    case 'application/zip':
    case 'application/x-rar-compressed':
      return toImg(compressed)
    case 'application/pdf':
      return toImg(pdf)
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return toImg(calc)
    case 'application/x-dosexec':
    case 'applicatioan/vnd.adobe.air-application-installer-package+zip':
      return toImg(exe)
    case 'audio/mpeg':
    case 'audio/x-wav':
    case 'midi':
    case 'wav':
    case 'wma':
    case 'cda':
    case 'ogg':
    case 'ogm':
    case 'aac':
    case 'ac3':
    case 'flac':
      return toImg(audio)
    case 'video/mp4':
    case 'video/mpeg':
    case 'video/x-msvideo':
    case 'video/x-flv':
    case 'video/ogg':
    case 'video/x-ms-wmv':
    case 'video/x-matroska':
      return toImg(video)
    default:
      return toImg(doc)
  }
}
