export const MIN_BIRTHDATE = "1990-01-01"

const getMaxBithdate = () => {
  const today = new Date()
  const maxDate = `${today.getFullYear()}-${((today.getMonth() + 1) + '').padStart(2, '0')}-${(today.getDate() + '').padStart(2, '0')}`
  return maxDate
}
export const MAX_BIRTHDATE = getMaxBithdate()

const sonConstants={
  SERVIDOR_PORTADAS:'https://portadas.oupe.es/',
  T_NOMBRE_INPUT_MAXLENGTH:50,
  T_APELLIDO1_INPUT_MAXLENGTH:50,
  T_APELLIDO2_INPUT_MAXLENGTH:50,
  CODIGO_POSTAL_REGEX:'^d{5}(?:[-s]d{4})?$',
  CODIGO_POSTAL_LENGTH:5  
}
export default sonConstants