import React from 'react'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { ContentConsumer } from '../../language'
import { withUserInfoReloadable } from '../../utils/hocs'
import { withRouter } from 'react-router-dom'
import activationCodeConstants from './activationCodeConstants'
import userRoles from '../../globals/userRoles'
import wsCheckPremiumCode from '../../services/wsCheckPremiumCode'
import { Alert, Container } from 'reactstrap'
import axios from 'axios'
import axiosConfig from '../../config/axios'
import { ActivationCodeForm } from '../ActivationCodeForm/ActivationCodeForm'

class ActivationCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      status: activationCodeConstants.NO_SEARCH,
      data: {},
      isCenterLoading: false
    }

    this.manageOnSubmit = this.manageOnSubmit.bind(this)
    this.manageOnCancel = this.manageOnCancel.bind(this)
    this.activateCode = this.activateCode.bind(this)
    this.handleStatus = this.handleStatus.bind(this)
    this.handleChangeCode = this.handleChangeCode.bind(this)
    this.handleSubmitGetCenter = this.handleSubmitGetCenter.bind(this)
    this.handleShowSpinner = this.handleShowSpinner.bind(this)
  }

  componentDidMount() {
    document.body.classList.add("activationCode__modal-open")
  }

  componentWillUnmount() {
    document.body.classList.remove("activationCode__modal-open")
  }

  goTo = async (location) => {
    const { refreshAuth, history } = this.props
    await refreshAuth()
    history.push(location)
  }

  manageOnSubmit(e, status, userSchool) {
    e.preventDefault()
    if (status===activationCodeConstants.OK_ACTIVATION) {
      this.goTo("/edit-library")
    }
    else {
      if (status===activationCodeConstants.OK_LOCATE) this.activateCode(e)
      else this.handleSubmitGetCenter(e, userSchool)
    }
  }

  manageOnCancel(status) {
    switch (status) {
      case activationCodeConstants.OK_LOCATE:this.handleStatus(activationCodeConstants.NO_SEARCH, {})
      break
      case activationCodeConstants.OK_ACTIVATION:this.goTo("/")
      break
      default:this.props.toggleModal()
    }
  }

  async activateCode(e) {
    this.handleShowSpinner()
    e.preventDefault()

    const axiosInstance = axios.create(axiosConfig)
    const token = await this.props.googleReCaptchaProps.executeRecaptcha('activation')
    axiosInstance.put(`/user/role/TEACHER/${this.state.code}?token=${token}`)
      .then(response => {
        this.handleStatus(activationCodeConstants.OK_ACTIVATION, {})
      })
      .catch(error => {
        this.handleStatus(activationCodeConstants.KO_ACTIVATION, {})
      })
  }

  handleStatus(status, data) {
    this.setState({ status: status, data: data, isCenterLoading: false })
  }

  handleChangeCode(code) {
    this.setState({ code: code })
  }

  handleShowSpinner() {
    this.setState({ isCenterLoading: true })
  }

  async handleSubmitGetCenter(e, userSchool) {
    this.handleShowSpinner()
    e.preventDefault()
    const code = this.state.code

    if (!code) this.handleStatus(activationCodeConstants.KO_LOCATE, {})

    const token = await this.props.googleReCaptchaProps.executeRecaptcha('activation')
    wsCheckPremiumCode(code, token, (err, data) => {
      if (data && userSchool === data.c_centro) {
        this.handleStatus(activationCodeConstants.OK_LOCATE, data)
      }
      else {
        this.handleStatus(activationCodeConstants.KO_LOCATE, {})
      }
    })
  }

  manageStatus(status, dataCenter, premiumCode, userInfo, content) {
    let component = null

    switch (status) {
      case activationCodeConstants.NO_SEARCH:
        component =
        <ActivationCodeForm userInfo={userInfo}
                            paragraph={content.activationcode.activationcode_insert_premiumcode_text}
                            showAlert={false}
                            showForm={true}
                            showDataCenter={false}
                            labelPremiumCode={content.activationcode.activationcode_premiumcode_label_text}
                            placeHolderPremiumCode={content.activationcode.activationcode_premiumcode_placeholder}
                            premiumCode={premiumCode}
                            help={content.header.help_text}
                            handleChangeCode={this.handleChangeCode}
                            manageOnCancel={this.manageOnCancel}
                            cancelButton={content.sharedTerms.CANCEL_BUTTON_TEXT}
                            manageOnSubmit={this.manageOnSubmit}
                            acceptButton={content.sharedTerms.ACTIVATE_BUTTON_TEXT}
                            status={status}
                             />
        break
      case activationCodeConstants.OK_LOCATE:
        component =
        <ActivationCodeForm userInfo={userInfo}
                            paragraph={content.activationcode.activationcode_ok_premiumcode_text}
                            showAlert={false}
                            showForm={true}
                            showDataCenter={true}
                            dataCenter={dataCenter}
                            help={content.header.help_text}
                            manageOnCancel={this.manageOnCancel}
                            cancelButton={content.sharedTerms.RETURN_BUTTON_TEXT}
                            manageOnSubmit={this.manageOnSubmit}
                            acceptButton={content.sharedTerms.ACTIVATE_BUTTON_TEXT}
                            status={status}
       />
        break
      case activationCodeConstants.KO_LOCATE:
        component =
        <ActivationCodeForm userInfo={userInfo}
                            paragraph={content.activationcode.activationcode_insert_premiumcode_text}
                            showAlert={true}
                            alertMessage={content.activationcode.activationcode_error_premiumcode_text}
                            showForm={true}
                            showDataCenter={false}
                            labelPremiumCode={content.activationcode.activationcode_premiumcode_label_text}
                            placeHolderPremiumCode={content.activationcode.activationcode_premiumcode_placeholder}
                            premiumCode={premiumCode}
                            help={content.header.help_text}
                            handleChangeCode={this.handleChangeCode}
                            manageOnCancel={this.manageOnCancel}
                            cancelButton={content.sharedTerms.CANCEL_BUTTON_TEXT}
                            manageOnSubmit={this.manageOnSubmit}
                            acceptButton={content.sharedTerms.ACTIVATE_BUTTON_TEXT}
                            status={status}
        />
        break
      case activationCodeConstants.OK_ACTIVATION:
        component =
        <ActivationCodeForm userInfo={userInfo}
                            paragraph={content.activationcode.activationcode_ok_activation_text}
                            showAlert={false}
                            showForm={false}
                            showDataCenter={false}
                            labelPremiumCode={content.activationcode.activationcode_premiumcode_label_text}
                            placeHolderPremiumCode={content.activationcode.activationcode_premiumcode_placeholder}
                            premiumCode={premiumCode}
                            help={content.header.help_text}
                            handleChangeCode={this.handleChangeCode}
                            manageOnCancel={this.manageOnCancel}
                            cancelButton={content.sharedTerms.ACCEPT_BUTTON_TEXT}
                            manageOnSubmit={this.manageOnSubmit}
                            acceptButton={content.header.editLibrary_text}
                            status={status}
        />
        break
      case activationCodeConstants.KO_ACTIVATION:
        component =
        <ActivationCodeForm userInfo={userInfo}
                            paragraph={content.sharedTerms.ERROR_TEXT}
                            showAlert={false}
                            showForm={false}
                            showDataCenter={false}
                            labelPremiumCode={content.activationcode.activationcode_premiumcode_label_text}
                            placeHolderPremiumCode={content.activationcode.activationcode_premiumcode_placeholder}
                            premiumCode={premiumCode}
                            help={content.header.help_text}
                            handleChangeCode={this.handleChangeCode}
                            manageOnCancel={this.manageOnCancel}
                            cancelButton={content.sharedTerms.CANCEL_BUTTON_TEXT}
                            manageOnSubmit={this.manageOnSubmit}
                            acceptButton={content.sharedTerms.ACTIVATE_BUTTON_TEXT}
                            status={status}
        />
        break
      default:
        component =
        <ActivationCodeForm userInfo={userInfo}
                            paragraph={content.activationcode.activationcode_insert_premiumcode_text}
                            showAlert={false}
                            showForm={true}
                            showDataCenter={false}
                            labelPremiumCode={content.activationcode.activationcode_premiumcode_label_text}
                            placeHolderPremiumCode={content.activationcode.activationcode_premiumcode_placeholder}
                            premiumCode={premiumCode}
                            help={content.header.help_text}
                            handleChangeCode={this.handleChangeCode}
                            manageOnCancel={this.manageOnCancel}
                            cancelButton={content.sharedTerms.CANCEL_BUTTON_TEXT}
                            manageOnSubmit={this.manageOnSubmit}
                            acceptButton={content.sharedTerms.ACTIVATE_BUTTON_TEXT}
                            status={status}
                             />
        break
    }
    return component
  }

  render() {
    return (<ContentConsumer>
      {({ content = {}, isContentLoading }) => (content && (
        this.props.userInfo.role !== userRoles.TEACHER_DEMO ?

          <div className="alertMessage" ><Alert color="danger">{content.sharedTerms.ERROR_TEXT}</Alert></div>
          :
          (this.state.isCenterLoading) ?
            <Container className="activationCode">
              {(this.state.status === activationCodeConstants.OK_LOCATE) ?
              <p>{content.activationcode.activationcode_premiumcode_spinner_label} </p>
              :
              <p>{content.activationcode.activationcode_search_spinner_label} </p>
              }
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status" />
              </div>
            </Container>
            :
              <div>{this.manageStatus( this.state.status, this.state.data, this.state.code, this.props.userInfo, content)}</div>
      ))}
    </ContentConsumer>)
  }
}

export default withGoogleReCaptcha(withRouter(withUserInfoReloadable(ActivationCode)))
