import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { Container, Row, Col, Modal as ModalRT,
  ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import cx from 'classnames'
import _ from 'lodash'

import { withContent } from '../../utils/hocs'
import { Colors } from '../Header/components/Colors'
import ButtonPremium from '../ButtonPremium/ButtonPremium'

const ModalAccept = (props) => {
  const { content, isOpen, toggle, className, children, header, subtitle, buttonOnClick, size } = props
  const colors = [ 'pink', 'yellow', 'green', 'blue']

  return (
    <ModalRT isOpen={isOpen} toggle={toggle} size={size} className={cx("modal-accept", className)}>
      <Colors colors={colors} />
      {header &&
        <ModalHeader className="modal__header text-center" toggle={toggle}>
          {header}
          { subtitle && <Fragment><br /><span className='small'>{subtitle}</span></Fragment>}
        </ModalHeader>
      }
      <ModalBody>
        {children}
      </ModalBody>
      <ModalFooter>
        <Container fluid={false}>
          <Row>
            <Col sm={{ size: 6, offset: 3}}>
              <ButtonPremium onClick={buttonOnClick?buttonOnClick:toggle} primary>
                { _.get(content, ['sharedTerms', 'ACCEPT_BUTTON_TEXT'], 'Accept') }
              </ButtonPremium>
            </Col>
          </Row>
        </Container>
      </ModalFooter>
    </ModalRT>
  )
}

ModalAccept.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  header: PropTypes.string,
  subtitle: PropTypes.string,
  size: PropTypes.string,
  buttonOnClick: PropTypes.func
}

export default withContent(ModalAccept)
