import React, { Component, Fragment } from 'react'
import { AuthConsumer } from 'react-check-auth'
import { Progress, Alert } from 'reactstrap'
import { ContentConsumer, interpolate, PLACEHOLDERS } from '../../language'
import { NavLink, Redirect } from 'react-router-dom'
import axios from 'axios'
import axiosConfig from '../../config/axios'
import GpaNavbar from '../GpaNavbar/GpaNavbar'
import GpaTitleView from '../GpaTitleView/GpaTitleView'
import GpaOrderCardDetail from '../GpaOrderCardDetail/GpaOrderCardDetail'
import GpaOrderRemark from '../GpaOrderRemark/GpaOrderRemark'
import GpaOrderHeader from '../GpaOrderHeader/GpaOrderHeader'
import GpaOderMaterials from '../GpaOderMaterials/GpaOderMaterials'
import GpaModal from '../GpaModal/GpaModal'
import Modal from '../Modal/Modal'
import {
  gpa_url, header_student, header_classroom, header_teacher,
  material_minus, material_plus, material_edit, stateData,
  getOrderTitle, validateNumber, validateAddMaterial, validateOrder,
  isEditableMaterial, resetMaterials
} from '../../utils/gpa-utils'
import { parseToReactElement } from '../../utils/html'
import validator from 'validator'

class GpaOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      data: null,
      links: [
        {
          id: 1,
          url: gpa_url,
          label: props.content.gratisadoption.gxa_1
        }
      ],
      authorized: false,
      isSending: false,
      showModal: false,
      back: false
    }
  }

  componentDidMount() {
    if (!this.props.userInfo || !this.props.userInfo.gpaPermission || !this.props.userInfo.positionId) {
      this.props.history.push("/")
    } else {
      this.getOrder()
    }
  }

  getOrder = () => {
    const orderId = this.props.match.params.orderId
    if (!validator.isInt(orderId + '', { gt: 0 })) {
      const error = `Invalid order id: ${orderId}`
      console.error('error : ', error)
      this.setState({ error })
    } else {
      axios.create(axiosConfig).get(`/gratis-adoption/orders/${orderId}`)
        .then(response => {
          const label = getOrderTitle(response.data, parseInt(this.props.userInfo.id, 10), this.props.content)
          this.setState(currentState => (
            ({
              data: response.data,
              links: currentState.links.concat([
                {
                  id: 2,
                  url: null,
                  label: label
                }
              ])
            })
          ))
        })
        .catch(error => {
          console.error('error : ', error)
          this.setState({ error: error })
        })
    }
  }

  toggleModal = () => this.setState(prevState => ({
    showModal: !prevState.showModal,
    isSending: !prevState.isSending
  }))

  toggleBack = back => this.setState({ back })

  handleChangeHeader = (event, type) => {
    const value = validateNumber(event.target.value.trim())
    switch (type) {
      case header_student:
        this.setState(currentState => (
          ({
            data: { ...currentState.data, alumnosPeticion: value }
          })
        ))
        break
      case header_classroom:
        this.setState(currentState => (
          ({
            data: { ...currentState.data, aulasPeticion: value, materiales: resetMaterials(currentState.data.materiales, type) }
          })
        ))
        break
      case header_teacher:
        this.setState(currentState => (
          ({
            data: { ...currentState.data, profesoresPeticion: value, materiales: resetMaterials(currentState.data.materiales, type) }
          })
        ))
        break
      default:
        console.warn('unsupported type ', type)
        break
    }
  }

  handleChangeMaterial = (event, materialId, actionType) => {
    let change = false
    let updatedMaterial = null
    let indexMaterial = -1
    const material = this.state.data.materiales.filter((item, index) => {
      if (item.idMaterial === materialId) {
        indexMaterial = index
        //return item
        return true
      }
      return false
    })
    if (material.length) updatedMaterial = Object.assign({}, material[0])

    if (updatedMaterial) {
      switch (actionType) {
        case material_minus:
          if (updatedMaterial.unidadesActual) {
            updatedMaterial.unidadesActual--
            change = true
          }
          break
        case material_plus:
          updatedMaterial.unidadesActual++
          if (validateAddMaterial(updatedMaterial, this.state.data.profesoresPeticion, this.state.data.aulasPeticion)) {
            change = true
          }
          break
        case material_edit:
          updatedMaterial.unidadesActual = validateNumber(event.target.value.trim())
          if (validateAddMaterial(updatedMaterial, this.state.data.profesoresPeticion, this.state.data.aulasPeticion)) {
            change = true
          }
          break
        default:
          console.warn('unsupported actionType ', actionType)
          break
      }

      if (change)
        this.setState(currentState => {
          let materials = currentState.data.materiales.filter(item => item.idMaterial !== materialId)
          materials.splice(indexMaterial, 0, updatedMaterial)
          return {
            data: Object.assign(currentState.data, { materiales: materials })
          }
        })
    } else {
      console.warn('unsupported materialId ', materialId)
    }
  }

  handleChangeAuthorized = event => (
    this.setState({ authorized: event.target.checked })
  )

  handleSendOrder = () => {
    if (validateOrder(this.state.data, this.state.authorized)) {
      this.toggleModal()
    }
  }

  renderCheckAuthorized = schoolName => (
    <div className="row">
      <div className="col-sm-12">
        <div className="form-group has-feedback mt-4">
          <div className="checkbox">
            <label>
              <input type="checkbox" checked={this.state.authorized} onChange={this.handleChangeAuthorized} />&nbsp;
              {parseToReactElement(interpolate(this.props.content.gratisadoption.gxa_10, PLACEHOLDERS.SCHOOL, schoolName.toUpperCase()), null, { ADD_ATTR: ['target'] })}
            </label>
          </div>
        </div>
      </div>
    </div>
  )

  renderButtons = (editableMaterial, orderTitle) => {
    if (editableMaterial) {
      const sendEnabled = validateOrder(this.state.data, this.state.authorized)
      return (
        <div className="row form-group mt-5">
          <div className="col-sm-12 offset-md-1 col-md-5 mb-4">
            <NavLink exact={true} to={gpa_url} className='btn btn-modal-plus-inverse btn-block cancelButton'>{this.props.content.gratisadoption.gxa_5}</NavLink>
          </div>
          <div className="col-sm-12 col-md-5">
            {
              sendEnabled && !this.state.isSending ?
                (<button className="btn btn-premium btn-block" onClick={this.handleSendOrder}><strong>{orderTitle}</strong></button>)
                :
                (<button className="btn btn-premium btn-block" disabled><strong>{orderTitle}</strong></button>)
            }
          </div>
        </div>
      )
    } else {
      return (
        <div className="row form-group mt-5">
          <div className="col-sm-12 offset-md-3 col-md-6">
            <NavLink exact={true} to={gpa_url} className='btn btn-modal-plus-inverse btn-block cancelButton'>{this.props.content.gratisadoption.gxa_5}</NavLink>
          </div>
        </div>
      )
    }
  }

  render() {
    const { userInfo, content } = this.props
    if (!userInfo || !userInfo.gpaPermission || !userInfo.positionId) {
      return (<Progress animated color="primary" value="100" />)
    }

    const { error, data, links, showModal, back } = this.state
    const userId = parseInt(userInfo.id, 10)

    if (back && !showModal) {
      return (
        <Fragment>
          <Progress animated color="primary" value="100">{content.sharedTerms.LOADING_MESSAGE}</Progress>
          <Redirect to='/gratis-adoption' />
        </Fragment>
      )
    }
    if (!error && !data) {
      return (<Progress animated color="primary" value="100">{content.sharedTerms.LOADING_MESSAGE}</Progress>)
    }
    if (error) {
      return (
        <div className='container-gpa'>
          <GpaNavbar links={links} />
          <div className="container">
            <div className="row adopcion-recursos">
              <div className="col-sm-12">
                <p>&nbsp;</p>
                <Alert color="danger">{content.gratisadoption.gxa_50}</Alert>
              </div>
            </div>
          </div>
        </div>
      )
    }
    const orderTitle = getOrderTitle(data, userId, content)
    const editableMaterial = isEditableMaterial(data, userId)
    return (
      <Fragment>

        {/* Modal */}
        <Modal
          className='gpa__modal'
          isOpen={showModal}
          toggle={this.toggleModal}
          header={content.gratisadoption.gxa_1}>
          <GpaModal
            content={content}
            toggleModal={this.toggleModal}
            toggleBack={this.toggleBack}
            order={data} />
        </Modal>

        {/* Order */}
        <div className='container-gpa'>
          <GpaNavbar links={links} />
          <div className="container">
            <div className="row adopcion-recursos">
              <div className="col-sm-12">

                {/* Title */}
                <div className="text-center"><GpaTitleView label={orderTitle} /></div>

                <div className="row row-sm-center">
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="well well-cards-material">

                          {/* Card */}
                          <GpaOrderCardDetail data={data} stateData={stateData[data.estado.idEstado]} content={content} />

                          {/* Remarks */}
                          {data.comentarioPublico && <GpaOrderRemark remark={data.comentarioPublico} stateId={data.estado.idEstado} content={content} />}

                          {/* Header */}
                          <div className="row">
                            <div className="col-sm-12">
                              <hr />
                              {
                                data.pedidoModificable ?
                                  (<GpaOrderHeader students={data.alumnosPeticion} classrooms={data.aulasPeticion} teachers={data.profesoresPeticion} onChangeHeader={this.handleChangeHeader} content={content} />)
                                  :
                                  (<GpaOrderHeader students={data.alumnosPeticion} classrooms={data.aulasPeticion} teachers={data.profesoresPeticion} editableMaterial={editableMaterial} content={content} />)
                              }
                            </div>
                          </div>

                          {/* Materials */}
                          <div className="row">
                            <GpaOderMaterials materials={data.materiales} editableHeader={data.pedidoModificable} editableMaterial={editableMaterial} onChangeMaterial={this.handleChangeMaterial} content={content} />
                          </div>

                          {/* Check authorized */}
                          {data.pedidoModificable && this.renderCheckAuthorized(userInfo.schoolName)}

                          {/* Buttons */}
                          {this.renderButtons(editableMaterial, orderTitle)}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default props => (
  <AuthConsumer>
    {({ userInfo }) => (
      <ContentConsumer>
        {
          ({ content = {} }) => (
            (content && <GpaOrder userInfo={userInfo} content={content} {...props} />)
          )}
      </ContentConsumer>
    )}
  </AuthConsumer>
)