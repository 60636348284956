import React, { useEffect } from 'react'

import Feature from 'components/Feature/Feature'
import { useGoogleClassroom } from 'contexts/GoogleClassroomContext'

const GoogleClassroomButton = ({ uuid, title, url }) => {
  const { loaded: scriptLoaded } = useGoogleClassroom()

  const divname = `btn_gc_${uuid.replaceAll("-","")}`

  useEffect(() => {
    if(scriptLoaded) {
      window.gapi.sharetoclassroom.render(
        divname,
        {
          title,
          url
        }
      )
    }
  }, [scriptLoaded])

  if(scriptLoaded) {
    return (
      <div
          key={divname}
          id={divname}
      />
    )
  } else {
    return null
  }

}

export default (props) => (
  <Feature name="classroom">
    <GoogleClassroomButton {...props} />
  </Feature>
)
