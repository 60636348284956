import NotificationGpa from '../NotificationGpa/NotificationGpa'
import NotificationChangedAdoptions from '../NotificationChangedAdoptions/NotificationChangedAdoptions'

const goToGpa = () => {
  const gpaLink = document.getElementById('navlink-gpa')
  if(gpaLink) gpaLink.click()
}

const notificationType = {
  gpa: {
    component: NotificationGpa,
    titleContentKey: 'notification_gpa_1',
    subtitleContentKey: 'notification_gpa_2',
    prefixClass: 'notification__gpa',
    submit: goToGpa
  },
  changedAdoptions: {
    component: NotificationChangedAdoptions,
    prefixClass: 'notification__changed_adoptions',
    modalSize: 'lg'
  }
}

export default notificationType
