import React, { Component, Fragment } from 'react'
import { Card, Container, Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types'

import axios from 'axios'
import axiosConfig from '../../../config/axios'

import envSettings from '../../../globals/envSettings'
import ButtonPremium from '../../ButtonPremium/ButtonPremium'
import Modal from '../../Modal/Modal'
import CMSContent from '../../CMSContent/CMSContent'

import cover from './resources/cover.png'

import { withContent } from '../../../utils/hocs'

const axiosClient = axios.create(Object.assign({}, axiosConfig))

class StudentsLink extends Component {
  constructor() {
    super()

    this.state = {
      disclaimer: false,
      error: undefined,
      link: false,
      copied: false,
      token: undefined
    }
  }

  getToken = () => {
    const data = { uuid: this.props.title.uuid }
    axiosClient.put('/student/sharetitle', data)
      .then(response => {
        if(response && response.data && response.data.uuid) {
          this.setState({
            token: response.data.uuid,
            disclaimer: false,
            link: true
          })
        }
      })
      .catch(err => {
        if(err && err.response && err.response.status === 401) this.setState({ error: this.props.content.student.create_link_unauthorized })
        else this.setState({ error: this.props.content.sharedTerms.ERROR_TEXT })
      })
  }

  getLink = () => {
    return `${envSettings.APP_BASEURL}/student/${this.state.token}`
  }

  copyLink = text => {
    const textarea = document.createElement("textarea")
    textarea.value = text
    document.body.appendChild(textarea)
    textarea.style.position = "absolute"
    textarea.style.top = "-1000px"
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
    this.setState({ copied: true })
  }

  render() {
    const { content, locale } = this.props
    return (
      <Fragment>
        <div className="plus__Col position-relative pb-5">
          <Card className="p-0 m-0">
            <Container className="w-100 p-0 m-0">
              <Row>
                <Col>
                  <div className="products__card text-center w-100 h-100">
                    <img className="student-link-cover" src={cover} alt="" />
                    <div className="student-link-front text-center">
                      <div className="d-flex student-link-title align-items-center">
                        <div className="my-auto w-100 px-2 text-center text-noselect">{ content.student.students_zone }</div>
                      </div>
                    </div>
                    <div className="w-100 px-3 student-link-share">
                      <div className="d-flex w-100 student-link-share-content justify-content-center align-items-center px-2">
                        <div className="m-auto text-noselect">{ content.student.share_it_with_your_class }</div>
                      </div>
                    </div>
                    <div className={`d-flex flex-column p-2 justify-content-center products__card__inside__mask position-absolute w-100 h-100`}>
                      <Button
                        onClick={ () => this.setState({ disclaimer: true }) }
                        className="my-1 text-uppercase blink_code_students w-100"
                      >
                        { content.student.get_link }
                      </Button>
                    </div>
                    <div className="w-100 products__card__inside__legend student-link-legend">
                      { content.student.students_zone }
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Card>
        </div>
        <Modal
          isOpen={ this.state.disclaimer || !!(this.state.token && this.state.link) }
          toggle={ () => this.setState({ disclaimer: false, link: false, error: undefined }) }
          size="lg"
          className="position-relative"
        >
          { this.state.disclaimer &&
          <Container>
            <Row>
              <Col>
                <CMSContent pageid="STUDENTZONE_AGREEMENT" language={locale} />
              </Col>
            </Row>
            <Row className="my-4">
              <Col
                xs={{ size: 8, offset: 2 }}
                sm={{ size: 6, offset: 3 }}
                lg={{ size: 4, offset: 4 }}

              >
                <ButtonPremium primary onClick={ this.getToken }>{ content.student.accept_and_continue }</ButtonPremium>
              </Col>
            </Row>
            { this.state.error &&
            <Row className="my-4">
              <Col className="d-flex justify-content-center">
                <div className="text-white bg-danger px-3">{ this.state.error }</div>
              </Col>
            </Row>
            }
          </Container>
          }
          { !!this.state.token && this.state.link &&
          <Container>
            <Row className="my-5">
              <div className="w-100 mx-5 student-link-linkmodal-container">
                <div className="w-100 student-link-linkmodal-title py-1 text-center">{ content.student.share_it_with_your_class }</div>
                <div className="w-100 student-link-linkmodal-link py-5 text-center">
                  <span ref={ link => this.link = link }>{ this.getLink() }</span>
                </div>
              </div>
            </Row>
            {
              document.queryCommandSupported('copy') &&
            <Row>
              <Col
                xs={{ size: 8, offset: 2 }}
                lg={{ size: 3, offset: 2 }}
                sm={{ size: 6, offset: 3 }}
                className="py-2"
              >
                <ButtonPremium primary onClick={ (e) => {
                  e.preventDefault()
                  this.copyLink(this.getLink())
                }}>
                  { content.student.copy_link }
                </ButtonPremium>
              </Col>
              <Col
                xs={{ size: 8, offset: 2 }}
                lg={{ size: 3, offset: 2 }}
                sm={{ size: 6, offset: 3 }}
                className="py-2"
              >
                <ButtonPremium primary onClick={ (e) => {
                  e.preventDefault()
                  window.open(this.getLink(), "_blank")
                }}>
                  { content.student.open_link }
                </ButtonPremium>
              </Col>
            </Row>
            }
            { this.state.copied &&
            <Row className="my-4">
              <Col className="d-flex justify-content-center">
                <div className="text-white bg-success px-3">{ content.student.link_copied }</div>
              </Col>
            </Row>
            }
            <Row className="py-2">
              <Col>
                <CMSContent pageid="STUDENTZONE_COPYLINKTEXT" language={locale} />
              </Col>
            </Row>
          </Container>
          }
        </Modal>
      </Fragment>
    )
  }
}

StudentsLink.propTypes = {
  title: PropTypes.object.isRequired
}

export default withContent(StudentsLink)
