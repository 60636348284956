import React, { Component } from "react"
import { Redirect } from 'react-router-dom'
import { TabContent, TabPane, Nav, NavItem, NavLink as NavLinkReactstrap } from 'reactstrap'
import { ContentConsumer } from '../../language'
import PDTabAnnouncement from './PDTabAnnouncement'
import PDTabMyCourses from './PDTabMyCourses'
import PDTabCompletedCourses from './PDTabCompletedCourses'
import PDHeader from './PDHeader'
import PDModal from './PDModal'
//import PDContact from './PDContact'
import PDCoursesSettings, { ClientSettings } from './settings'
import { backupCards, fillAnnouncement, sanitizeField, TABS } from './utils'
import { withContent, withUserInfo } from '../../utils/hocs'
import axios from 'axios'
import axiosConfig from '../../config/axios'

const axiosApi = axios.create(axiosConfig)
const axiosWp = axios.create(ClientSettings)

class PDCourses extends Component {
  constructor(props) {
    super(props)
    this.state = {
      announcement: null,
      cards: null,
      loadingAnnouncement: true,
      activeTab: TABS.ANNOUNCEMENT,
      error: false,
      registration: false,
      cancel: false
    }
  }

  getAnnouncement = () => (
    axiosApi.get('/pd-courses/announcement').then(response => response.data)
      .catch(error => {
        console.error('PD-COURSES announcement : ', error.toString())
        return null
      })
  )

  getCardsWp = () => (
    axiosWp.get(`posts/?categories=${PDCoursesSettings.CARD_CATEGORY}`)
      .then(({ data: posts }) => (
        posts.reduce((acc, post) => {
          if (!post.categories.includes(PDCoursesSettings.LANGUAGES_ROOT) ||
            !post.title.rendered || !post.title.rendered.length ||
            !post.content.rendered || !post.content.rendered.length ||
            !post.excerpt.rendered || !post.excerpt.rendered.length
          ) return acc
          post.title.rendered = sanitizeField(post.title.rendered)
          post.content.rendered = sanitizeField(post.content.rendered)
          post.excerpt.rendered = sanitizeField(post.excerpt.rendered)
          acc.push(({
            title: post.title.rendered.trim(),
            content: post.content.rendered.trim(),
            masterCourse: post.excerpt.rendered.trim()
          }))
          return acc
        }, [])
      )).catch(error => {
        console.error('PD-COURSES cards WP : ', error.toString())
        return null
      })
  )

  async componentDidMount() {
    if (this.props.userInfo && this.props.userInfo.pdCourses) {
      let announcement = null
      let cardsWp = null
      try {
        announcement = fillAnnouncement(await this.getAnnouncement(), this.props.locale)
        cardsWp = await this.getCardsWp()
        this.setState({ announcement, cards: cardsWp && cardsWp.length > 0 ? cardsWp : backupCards, loadingAnnouncement: false })
      } catch (error) {
        console.error('PD-COURSES : ', error.toString())
        this.setState({ announcement, cards: cardsWp && cardsWp.length > 0 ? cardsWp : backupCards, loadingAnnouncement: false })
      }
    }
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }

  showRegistration = cancel => (
    cancel === true ?
      this.setState({ error: false, registration: true, activeTab: TABS.ANNOUNCEMENT, cancel })
      : this.setState({ error: false, registration: true, activeTab: TABS.ANNOUNCEMENT, cancel: false })
  )

  showError = tab => (
    tab ?
      this.setState({ error: true, registration: false, activeTab: tab })
      : this.setState({ error: true, registration: false })
  )

  closeModal = () => (
    this.setState({ error: false, registration: false, cancel: false })
  )

  reopenRegistration = () => (
    this.setState({ error: false, registration: false, cancel: false }, () => (this.showRegistration()))
  )

  render() {
    const { announcement, cards, loadingAnnouncement, activeTab, registration, error, cancel } = this.state
    const { userInfo } = this.props
    return (
      userInfo && userInfo.pdCourses ?
        (
          <ContentConsumer>
            {({ content = {} }) => (
              content && (
                <div className="pdCourses">
                  <PDHeader />
                  <Nav fill className='pdCourses__tab'>
                    <NavItem active={activeTab === TABS.ANNOUNCEMENT} className='pdCourses__item'>
                      <NavLinkReactstrap
                        active={activeTab === TABS.ANNOUNCEMENT}
                        onClick={() => this.toggleTab(TABS.ANNOUNCEMENT)}
                      >
                        {content.pdcourses.tab_home}
                      </NavLinkReactstrap>
                    </NavItem>
                    <NavItem active={activeTab === TABS.MY_COURSES} className='pdCourses__item'>
                      <NavLinkReactstrap
                        active={activeTab === TABS.MY_COURSES}
                        onClick={() => this.toggleTab(TABS.MY_COURSES)}
                      >
                        {content.pdcourses.tab_user_courses}
                      </NavLinkReactstrap>
                    </NavItem>
                    <NavItem active={activeTab === TABS.COMPLETED_COURSES} className='pdCourses__item'>
                      <NavLinkReactstrap
                        active={activeTab === TABS.COMPLETED_COURSES}
                        onClick={() => this.toggleTab(TABS.COMPLETED_COURSES)}
                      >
                        {content.pdcourses.tab_completed_courses}
                      </NavLinkReactstrap>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab} className='pdCourses__tab-content'>
                    <TabPane tabId={TABS.ANNOUNCEMENT} className='pdCourses__tab-pane'>
                      <PDTabAnnouncement
                        announcement={announcement}
                        cards={cards}
                        loading={loadingAnnouncement}
                        activeTab={activeTab}
                        showRegistration={this.showRegistration} />
                    </TabPane>

                    <TabPane tabId={TABS.MY_COURSES} className='pdCourses__tab-pane'>
                      <PDTabMyCourses
                        announcement={announcement}
                        cards={cards}
                        activeTab={activeTab}
                        showRegistration={this.showRegistration}
                        showError={this.showError} />
                    </TabPane>

                    <TabPane tabId={TABS.COMPLETED_COURSES} className='pdCourses__tab-pane'>
                      {/* <PDContact title='tab_completed_courses' /> */}
                      <PDTabCompletedCourses
                        announcement={announcement}
                        cards={cards}
                        activeTab={activeTab}
                        showError={this.showError} />
                    </TabPane>
                  </TabContent>

                  <PDModal
                    announcement={announcement}
                    registration={registration}
                    error={error}
                    cancel={cancel}
                    showError={this.showError}
                    closeModal={this.closeModal}
                    reopenRegistration={this.reopenRegistration}
                  />
                </div>
              ))}
          </ContentConsumer>
        )
        :
        (
          <Redirect to='/' />
        )
    )
  }
}

export default withContent(withUserInfo(PDCourses))