import React, { Component, Fragment } from 'react'
import { withUserInfo } from '../../../utils/hocs'
import { withRouter } from 'react-router-dom'
import validator from 'validator'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ROLES from '../../../globals/userRoles'
import ModalAccept from '../../ModalAccept/ModalAccept';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import CustomerUserEdit from '../CustomerUserEdit';
import { characterLength, emailRegex, passwordRegex, getStatusMessage } from "../../../utils/format";
import UserDetail from "../FormComponent";
import userRoles from "../../../globals/userRoles";
import StudentEdit from '../StudentEdit';
import { getCustomerSupportDataById, getCustomerSupportDataActions, getCustomerSupportTitles, getCustomerSupportAdoptionLanguage, getCustomerSupportAdoptionEditons, getCustomerSupportDataSchoolRegions, getCustomerSupportLibraryById, putCustomerSupportDataById, putCustomerSupportLibraryById, postCustomerSupportData, deleteCustomerSupportDataById } from "../../../services/administrator/request";
import { Promise } from 'q'
import languages from "../../../data/language.json";
import Modal2Buttons from '../../Modal2Buttons/Modal2Buttons'

class CreateFictitiousUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            surname1: '',
            surname2: '',
            email: '',
            password: '',
            confirmPassword: '',
            language: '',
            schoolName: '',
            cau: [],
            adoptions: [],
            openModal: false,
            openDelete: false,
            popupMsg: '',
            latestActions: [],
            adoptionListSelected: [],
            titleList: [],
            titleSearch: false,
            titleNotFound: false,
            tilesListSelected: [],
            adoptionLanguagesList: [],
            adoptionEditionsList: [],
            titleValuesSelected: [],
            resources: false,
            downloadResources: false,
            showError: false,
            errors: {
                name: false,
                surname1: false,
                surname2: false,
                email: false,
                password: false,
                confirmPassword: false,
                adoptions: false,
                language: false
            },
            errorMsg: {
                name: false,
                email: false,
                password: false,
                adoptions: false,
                language: false
            }
        };
        this.handleInputChange = this.handleInputChange.bind(this)
        this.create = this.create.bind(this)
        this.errorMsg = this.errorMsg.bind(this)
        this.toggle = this.toggle.bind(this)
        this.addExistingDataToAdoption = this.addExistingDataToAdoption.bind(this)
        this.showError = this.showError.bind(this)
        console.log("CreateFictitiousUser", props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const { type, match: { params } } = this.props;


        Promise.all([getCustomerSupportAdoptionLanguage(), getCustomerSupportAdoptionEditons(), getCustomerSupportDataSchoolRegions()])
            .then(([result1, result2, result3]) => {
                if (result1.status === 200 && result2.status === 200 && result3.status === 200) {
                    if (type === 'EDIT') {
                        Promise.all([getCustomerSupportDataById(params.userId), getCustomerSupportDataActions(params.userId), getCustomerSupportLibraryById(params.userId)])
                            .then(([result4, result5, result6]) => {
                                if (result4.status === 200 && result5.status === 200 && result6.status === 200) {
                                    console.log('YYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY')
                                    console.log(result4, result5);
                                    this.setState({
                                        'userData': result4.data,
                                        'card': result4.data.card,
                                        'ccaaId': result4.data.ccaaId,
                                        'creationDate': result4.data.creationDate,
                                        'downloadResources': result4.data.downloadResources,
                                        'email': result4.data.email,
                                        'guardian': result4.data.guardian,
                                        'id': result4.data.id,
                                        'language': result4.data.language,
                                        'lastAccessDate': result4.data.lastAccessDate,
                                        'mktPrefs': result4.data.mktPrefs,
                                        'name': result4.data.name,
                                        'password': result4.data.password,
                                        'confirmPassword': result4.data.password,
                                        'phone': result4.data.phone,
                                        'role': result4.data.role,
                                        'schoolId': result4.data.schoolId,
                                        'schoolName': result4.data.schoolName,
                                        'self-editable': result4.data['self-editable'],
                                        'surname1': result4.data.surname1,
                                        'surname2': result4.data.surname2,
                                        'validatedEmail': result4.data.validatedEmail,
                                        'latestActions': result5.data,
                                        adoptionLanguagesList: result1.data,
                                        adoptionEditionsList: result2.data,
                                        cau: result3.data
                                    }, () => this.addExistingDataToAdoption(result6.data))
                                } else this.showError('404')
                            })
                            .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
                    } else this.setState({
                                         adoptionLanguagesList: result1.data,
                                         adoptionEditionsList: result2.data,
                                         cau: result3.data
                                         });
                } else this.showError('404')
            })
            .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
    }

    isValid = () => !_.compact(_.values(this.state.errors)).length && this.state.name && this.state.surname1 && this.state.language &&
        this.state.email && this.state.password === this.state.confirmPassword &&
        this.state.adoptions.length > 0 && [ROLES.CUSTOMER_SUPPORT].find(role => role === _.get(this.props, 'userInfo.role', undefined))

    create() {
        const { name, surname1, surname2, email, password, confirmPassword, adoptions, downloadResources, schoolName, language } = this.state;
        const { type, match: { params } } = this.props;

        let data = {
            // OUPE change
            //userId: type === 'CREATE' ? Math.floor((Math.random() * 1000000000) + 1) : params.userId,
            userId: type === 'CREATE' ? null : params.userId,
            name,
            surname1,
            surname2,
            email,
            password: password ? password : '',
            // confirmPassword: confirmPassword ? confirmPassword : undefined,
            // OUPE change
            downloadResources: !!downloadResources,
            role: type === 'CREATE' ? 'TEACHER_FAKE' : params.role,
            ccaaId: this.ccaaId.value,
            schoolName,
            language: this.language.value
            // creationDate: type === 'CREATE' ? new Date() : creationDate,
            // lastAccessDate: type === 'CREATE' ? new Date() : lastAccessDate,
        };

        if (this.isValid()) {
            console.log('created');
            if (type === 'CREATE') {
                postCustomerSupportData(data)
                    .then(result => {
                        console.log(result)
                        if (result.status === 200)
                            return putCustomerSupportLibraryById({
                                userId: result.data.userId,
                                adoptions: adoptions.map(d => { return { 'uuid': d.uuid, 'adoption-language': d['adoption-language'], 'adoption-edition': d['adoption-edition'] } })
                            });
                    })
                    .then(result => {
                        this.setState({
                            popupMsg: getStatusMessage(result.status),
                            openModal: true,
                            errorMsg: {
                                name: false,
                                email: false,
                                password: false,
                                adoptions: false,
                                language: false
                            }
                        })
                    })
                    .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
            }
            else if (type === 'EDIT') {
                // OUPE change
                const sendData = Object.assign(_.pick(data, [ 'name', 'surname1', 'surname2', 'email', 'downloadResources', 'ccaaId', 'language', 'password']), {userId: params.userId})

                putCustomerSupportDataById(sendData)
                    .then(result => {
                        if (result.status === 200)
                            return putCustomerSupportLibraryById({
                                userId: params.userId,
                                adoptions: adoptions.map(d => { return { 'uuid': d.uuid, 'adoption-language': d['adoption-language'], 'adoption-edition': d['adoption-edition'] } })
                            });
                        })
                    .then(result => {
                        this.setState({
                          popupMsg: getStatusMessage(result.status),
                          openModal: true,
                          errorMsg: {
                            name: false,
                            email: false,
                            password: false,
                            adoptions: false,
                            language: false
                            }
                        })
                    })
                    .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
            }
        }
        else {
            console.log(this.state.errors);
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        name: !validator.matches(name, characterLength),
                        surname1: !validator.matches(surname1, characterLength),
                        surname2: _.isEmpty(surname2) ? false : !validator.matches(surname2, characterLength),
                        email: !validator.matches(email, emailRegex),
                        password: type === 'CREATE' ? (!validator.matches(password, passwordRegex)) : (_.isEmpty(password) ? false : !validator.matches(password, passwordRegex)),
                        confirmPassword: type === 'CREATE' ? (!validator.matches(confirmPassword, passwordRegex)) : (_.isEmpty(confirmPassword) ? false : !validator.matches(confirmPassword, passwordRegex) || !(password === confirmPassword)),
                        language: _.isEmpty(language) ? true : false,
                        adoptions: !(adoptions.length > 0)
                    }
                }
            }, () => this.errorMsg());
        }
    }

    clearSelected() {
      const elements = document.getElementById("titleList").options
      for(var i = 0; i < elements.length; i++){
        elements[i].selected = false
      }
    }

    showError(error) {
      this.setState({
            titleList: [],
            popupMsg: getStatusMessage(error),
            openModal: true,
            showError: true
      })
    }

    delete() {
        const { match: { params } } = this.props;
        deleteCustomerSupportDataById(params.userId)
            .then(result => {
                this.setState({
                    popupMsg: getStatusMessage(result.status),
                    openModal: true
                })
            })
            .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
    }

    errorMsg() {
        this.setState({
            errorMsg: {
                name: (this.state.errors.name || this.state.errors.surname1 || this.state.errors.surname2),
                email: (this.state.errors.email),
                password: this.state.errors.password || this.state.errors.confirmPassword || this.state.password !== this.state.confirmPassword,
                adoptions: this.state.errors.adoptions,
                language: this.state.errors.language
            }
        });
    }

    toggle() {
        const { type } = this.props;
        this.setState({ openModal: false }, () => {
            if (type === 'CREATE') {
              if (!this.state.showError) this.props.back(true);
              else this.setState({ openModal: false, showError: false })
            }
            else {
              this.setState({ openModal: false }, () => this.props.history.push( {pathname: '/admin', state: { admin: true }}))}
        })
    }


    handleInputChange = ({ target: { name, value, options } }) => {

        switch (name) {
            case "name":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !validator.matches(value, characterLength)
                        }
                    }
                })
                break
            case "surname1":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !validator.matches(value, characterLength)
                        }
                    }
                })
                break
            case "surname2":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: _.isEmpty(value) ? false : !validator.matches(value, characterLength)
                        }
                    }
                })
                break
            case "email":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !validator.matches(value, emailRegex)
                        }
                    }
                })
                break
            case "password":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: _.isEmpty(value) ? false : !validator.matches(value, passwordRegex)
                        }
                    }
                })
                break
            case "confirmPassword":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: (this.state.password === value) ? false : !validator.matches(value, passwordRegex)
                        }
                    }
                })
                break
            case "language":
                    this.setState((prevState) => {
                        return {
                            [name]: value,
                            errors: {
                                ...prevState.errors,
                                [name]: _.isEmpty(value) ? true : false
                            }
                        }
                    })
                    break
            case "adoptions":
                let valuesSelected = [];
                for (var i = 0, l = options.length; i < l; i++) {
                    if (options[i].selected) {
                        valuesSelected.push(Number(options[i].index));
                    }
                }
                this.setState((prevState) => {
                    return {
                        adoptionListSelected: this.state.adoptions.filter((data, index) => valuesSelected.indexOf(index) !== -1).map(d => d.name),
                        errors: {
                            ...prevState.errors,
                            [name]: !(valuesSelected.length > 0)
                        }
                    }
                }, () => console.log('adoptionListSelectedIndex', this.state.adoptionListSelected))
                break
            case "titleList":
                let titleValuesSelected = [];
                for (var j = 0, k = options.length; j < k; j++) {
                    if (options[j].selected) {
                        titleValuesSelected.push({ uuid: options[j].value, name: options[j].label });
                    }
                }
                this.setState({
                    titleValuesSelected
                }, () => console.log('titleValuesSelected', this.state.titleValuesSelected))
                break
            default:
                this.setState({ [name]: value })
        }
    }

    uniqueArray(arr) {
        var newarr = [];
        var unique = {};

        arr.forEach(function (item) {
            if (!unique[item.name]) {
                newarr.push(item);
                unique[item.name] = item;
            }
        });

        return newarr;
    }

    addExistingDataToAdoption(exitingAdoptionData) {
        let adoptions = [];

        adoptions = exitingAdoptionData.map(data => {
            let adoption_languages_name = this.state.adoptionLanguagesList.filter(d => d.uuid === data['adoption-language']).map(d => d.name);
            let adoption_editions_name = this.state.adoptionEditionsList.filter(d => d.uuid === data['adoption-edition']).map(d => d.name);
            return {
                ...data,
                "adoption-language": data['adoption-language'], "adoption-edition": data['adoption-edition'],
                "name": `${data.name} (${adoption_languages_name.toString()} - ${adoption_editions_name.toString()})`
            }
        });

        this.setState({
            adoptions: this.uniqueArray([...this.state.adoptions, ...adoptions])
        }, () => {
            this.setState({
                errors: {
                    adoptions: !(this.state.adoptions.length > 0)
                }
            });
        });
    }

    addAdoption() {
        let adoptions = [];
        let adoption_languages_name = this.state.adoptionLanguagesList.filter(d => d.uuid === this.adoption_languages.value).map(d => d.name);
        let adoption_editions_name = this.state.adoptionEditionsList.filter(d => d.uuid === this.adoption_editions.value).map(d => d.name);

        adoptions = this.state.titleValuesSelected.map(d => {
            return {
                ...d,
                "adoption-language": this.adoption_languages.value, "adoption-edition": this.adoption_editions.value,
                "name": `${d.name} (${adoption_languages_name.toString()} - ${adoption_editions_name.toString()})`
            }
        })
        this.setState({
            adoptions: this.uniqueArray([...this.state.adoptions, ...adoptions])
        }, () => {
            this.setState({
                errors: {
                    adoptions: !(this.state.adoptions.length > 0)
                }
            });
        });
    }

    removeAdoption() {
        this.setState({
            adoptions: this.state.adoptions.filter((d, i) => this.state.adoptionListSelected.indexOf(d.name) === -1)
        }, () => {
            this.setState({
                errors: {
                    adoptions: !(this.state.adoptions.length > 0)
                }
            });
        });
    }

    searchTitles() {
        this.clearSelected()
        if (this.title.value.length >= 3)
            getCustomerSupportTitles(this.title.value)
                .then(result => {
                    if (result.status === 200 && result.data.length !== 0) {
                        this.setState({
                            titleList: result.data,
                            titleSearch: false,
                            titleNotFound: false,
                            titleValuesSelected: []
                        })}
                    else {
                      //PREM-3636
                      //this.showError('404')
                      this.setState({
                        titleList: [],
                        titleSearch: false,
                        titleNotFound: true,
                        titleValuesSelected: []
                    })
                    }
                })
                .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
        else {
            this.setState({
                titleList: [],
                titleSearch: true,
                titleNotFound: false,
                titleValuesSelected: []
            })
        }
    }


    downloadResources({ target: { checked } }) {
        console.log('downloadResources', checked);
        this.setState({
            downloadResources: checked
        });
    }

    toggleDelete = () => this.setState(
        prevState => ({openDelete: !prevState.openDelete
    }))

    showFictitiousUserPage() {
        const { type, history, userInfo, match: { params: { userId } } } = this.props;
        const { openDelete } = this.state
        console.log('showFictitiousUserPage', this.state.adoptions);
        return (
            <Fragment>
              {openDelete && <Modal2Buttons
                isOpen={openDelete}
                toggle={() => this.toggleDelete()}
                header="Confirm Operation"
                primaryButtonLabel="Accept"
                secondaryButtonLabel="Cancel"
                primaryButtonOnClick={ () => this.delete() }
                secondaryButtonOnClick={ () => this.setState({ openDelete: false}) }
                >
                <p className="text-center">{"Are you sure you want to delete this user?"}</p>
              </Modal2Buttons>
                
                }

                <Container>
                    <div className="create-fictitious-user-container">
                        {type === 'EDIT' && <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray">
                            <Col xs="6" md="8"><h3 className="text-primary font-weight-bold mb-0">Edit Fictitious User</h3></Col>
                            <Col xs="6" md="4">
            {userInfo.adminFictitious && <div className="float-right"><Button className="font-weight-normal" color="secondary" size="sm" onClick={() => this.toggleDelete()}>Delete</Button></div>}
            </Col>
                        </Row>}
                        <Row className="pt-2 pb-2 ml-1 mr-1">
                            <Col xs="12" md="12"><div className="font-weight-bold mb-0">TEACHER FAKE</div></Col>
                        </Row>
                        <Row className="pt-2 pb-2 ml-1 mr-1">
                            <Col xs="1" md="1"><div className="font-weight-bold mb-0">ID </div></Col>
                            <Col xs="11" md="11"><div className="font-weight-normal mb-0">{userId}</div></Col>
                        </Row>
                        <Form>
                            <UserDetail type='names' {...this.state} adminFictitious={userInfo.adminFictitious} handleInputChange={this.handleInputChange} />
                            <Row className="pt-2 pb-2 ml-1 mr-1">
                                <UserDetail type='email' size='4' {...this.state} adminFictitious={userInfo.adminFictitious} handleInputChange={this.handleInputChange} />
                                <Col xs="12" sm="6" md="6" lg="4">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="cau">Language</Label>
                                        <Input type="select" name="language" id="language" placeholder="" disabled={!userInfo.adminFictitious}
                                            value={this.state.language}
                                            valid={!this.state.errors.language && this.state.language !== ''}
                                            invalid={this.state.errors.language}
                                            innerRef={el => this.language = el}
                                            onChange={this.handleInputChange}>
                                            {languages.map((d, i) => <option key={i} value={d.id}>{d.name}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="pt-2 pb-2 ml-1 mr-1">
                                <UserDetail type='password' passwordEdit={this.props.type} adminFictitious={userInfo.adminFictitious} {...this.state} handleInputChange={this.handleInputChange} />
                            </Row>
                            <Row className="pt-2 pb-2 ml-1 mr-1">
                                <Col xs="12" sm="6" md="6" lg="4">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="schoolName">Education centre:</Label>
                                        <Input disabled={true} type="text" size="100" name="schoolName" id="schoolName" placeholder="" innerRef={el => this.schoolName = el}
                                            defaultValue={this.state.schoolName}
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="6" md="6" lg="4">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="cau">Centre's autonomous community</Label>
                                        <Input type="select" name="ccaaId" id="ccaaId" placeholder="" disabled={(type === 'CREATE') ? false : true}
                                            value={this.state.ccaaId}
                                            innerRef={el => this.ccaaId = el}
                                            onChange={this.handleInputChange}>
                                            {this.state.cau.map((d, i) => <option key={i} value={d.id}>{d.name}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="pt-2 pb-2 ml-1 mr-1">
                                <Col xs="12" sm="6" md="10" lg="10">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="titles">Titles</Label>
                                        <Input type="text" size="100" name="title" id="title" placeholder="" innerRef={el => this.title = el} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="6" md="2" lg="2">
                                    <FormGroup className="form-group">
                                        <div className="search-button">
                                            <Button disabled={!userInfo.adminFictitious} className="font-weight-normal" color="secondary" size="md" onClick={() => this.searchTitles()}>Search</Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {this.state.titleSearch && <Row className="ml-1 mr-1">
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <div className="text-danger font-weight-bold">
                                        Please enter minimum 3 characters to search
                                    </div>
                                </Col>
                            </Row>}
                            {this.state.titleNotFound && <Row className="ml-1 mr-1">
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <div className="text-danger font-weight-bold">
                                        Title not found
                                    </div>
                                </Col>
                            </Row>}
                            <Row className="pt-2 pb-2 ml-1 mr-1">
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <FormGroup className="form-group">
                                        <Input type="select" name="titleList" id="titleList" placeholder="" innerRef={el => this.titleList = el} multiple
                                            onChange={this.handleInputChange}
                                        >
                                            {this.state.titleList.map((d, i) => <option key={i} value={d.uuid}>{d.name}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="pt-2 pb-2 ml-1 mr-1">
                                <Col xs="12" sm="6" md="6" lg="4">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="language">Language</Label>
                                        <Input type="select" name="adoption_languages" id="adoption_languages" placeholder=""
                                            innerRef={el => this.adoption_languages = el}
                                            value={this.state.adoption_languages}
                                            onChange={this.handleInputChange}>
                                            {this.state.adoptionLanguagesList.map((d, i) => <option key={i} value={d.uuid}>{d.name}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="6" md="6" lg="4">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="language">Edition</Label>
                                        <Input type="select" name="adoption_editions" id="adoption_editions" placeholder=""
                                            innerRef={el => this.adoption_editions = el}
                                            value={this.state.adoption_editions}
                                            onChange={this.handleInputChange}>
                                            {this.state.adoptionEditionsList.map((d, i) => <option key={i} value={d.uuid}>{d.name}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="6" md="6" lg="4">
                                    <FormGroup className="form-group">
                                        <div className="add-adoption">
                                            <Button  disabled={!userInfo.adminFictitious} className="font-weight-normal" color="secondary" size="md" onClick={() => this.addAdoption()}>Add adoption</Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="pt-2 pb-2 ml-1 mr-1">
                                <Col xs="12" sm="12" md="9" lg="9">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="language">Adoptions</Label>
                                        <Input type="select" name="adoptions" id="adoptions" placeholder="" innerRef={el => this.adoptions = el} multiple
                                            onChange={this.handleInputChange}
                                            valid={!this.state.errors.adoptions && this.state.adoptions.length > 0}
                                            invalid={this.state.errors.adoptions}>
                                            {this.state.adoptions.map((data, index) => <option key={index} value={data.uuid}>{data.name}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="6" md="3" lg="3">
                                    <FormGroup className="form-group">
                                        <div className="remove-adoption">
                                            <Button  disabled={!userInfo.adminFictitious} className="font-weight-normal" color="secondary" size="md" onClick={() => this.removeAdoption()}>Remove adoption</Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="pt-2 pb-2 ml-1 mr-1">
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox"  disabled={!userInfo.adminFictitious} checked={this.state.downloadResources} onChange={(e) => this.downloadResources(e)} />{' '}
                                            Download resources available
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <UserDetail type='showErrorMessages' {...this.state} />
                            {type === "EDIT" && <Row className="pt-2 pb-2 ml-1 mr-1">
                                <Col className="font-weight-bold" xs="12" sm="12" md="12" lg="12"> LATEST ACTIONS</Col>
                            </Row>}
                            {this.state.latestActions.map((d, i) => (<Row key={i} className="pb-2 ml-1 mr-1">
                                <Col className="mt-2" xs="6" sm="6" md="6" lg="4">{d.date}</Col>
                                <Col className="mt-2" xs="6" sm="6" md="6" lg="4">{d.email}</Col>
                                <Col className="mt-2" xs="6" sm="6" md="6" lg="4">{d.action}</Col>
                            </Row>
                            ))}
                            <Row className="pt-5 pb-2 ml-1 mr-1">
                                <Col xs="3" sm="3" md="3" lg="2">
                                    <Button className="font-weight-normal" color="secondary" size="md" onClick={() => { if (type === 'CREATE') { return this.props.back(true) } else if (type === 'EDIT') { history.push( {pathname: '/admin', state: { admin: true }}) } }}>Cancel</Button>
                                </Col>
                                <Col xs="3" sm="3" md="3" lg="2">
                                    {type === 'CREATE' && <Button className="font-weight-normal" color="secondary" size="md" onClick={this.create}>Create</Button>}
                                    {type === 'EDIT' && <Button className="font-weight-normal" color="secondary" size="md"  disabled={!userInfo.adminFictitious} onClick={this.create}>Save</Button>}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <ModalAccept isOpen={this.state.openModal} toggle={this.toggle} header={'Alert'}>
                        <div className="text-center font-weight-bold">{this.state.popupMsg}</div>
                    </ModalAccept>
                </Container>
            </Fragment>
        );
    }

    render() {
        const { type, match: { params: { role } } } = this.props;
        if (type === 'CREATE') {
            return this.showFictitiousUserPage();
        }
        else if (type === 'EDIT') {
            switch (role) {
                case userRoles.TEACHER_FAKE:
                    return this.showFictitiousUserPage();
                case userRoles.STUDENT:
                    return <StudentEdit {...this.props} />
                default:
                    return <CustomerUserEdit {...this.props} />
            }
        }

    }
}

CreateFictitiousUser.prototypes = {
    type: PropTypes.string.isRequired
};

export default withUserInfo(withRouter(CreateFictitiousUser));
