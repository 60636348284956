import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { AuthConsumer } from 'react-check-auth'
import { withRouter } from 'react-router-dom'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Container, Row, Col, Form, FormGroup, Label, Input, CustomInput, FormText, FormFeedback, Fade } from 'reactstrap'
import ButtonThirdParties from '../ButtonThirdParties/ButtonThirdParties'
import { isValid } from 'better-dni'
import validator from 'validator'
import _ from 'lodash'
import qs from 'qs'

import { withContent } from '../../utils/hocs'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import Modal2Buttons from '../Modal2Buttons/Modal2Buttons'
import ModalAccept from '../ModalAccept/ModalAccept'
import ModalSchoolByPostalCode from '../ModalSchoolByPostalCode/ModalSchoolByPostalCode'

import axiosConfig from '../../config/axios'
import envSettings from '../../globals/envSettings'
import { regexpAlphaWithSpaces, regexPassword, regexAlphaNumeric } from '../../utils/formValidator'
import teacherValidator from '../../validators/teacher'
import { ForgotPassword, Help } from '../Links/Links'
import { format } from '../../utils/string'
import { parseToReactElement } from '../../utils/html'
import { RecaptchaTerms } from '../Recaptcha/Recaptcha'
import { interpolate, PLACEHOLDERS } from '../../language'

const axiosClient = axios.create(Object.assign({}, axiosConfig, { timeout: 20000 }))

const padCard = (value) => {
  let card = value
  if(card.length < 9) {
    if(card.charAt(0).match(/[0-9]/)) {
      card = _.padStart(card, 9, '0')
    } else if(card.charAt(0).match(/[x-zX-Z]/)) {
      card = card.charAt(0) + _.padStart(card.substring(1), 8, '0')
    }
  }
  return card
}

const checkValueFromThirdParties = (value) => (value === '' || !validator.matches(value, regexpAlphaWithSpaces) || value.replace(/ /g, '').length < 2)

class TeacherRegistration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      password2: '',
      name: '',
      surname1: '',
      surname2: '',
      card: '',
      phone: '',
      premiumCode: '',
      premiumCodeValidated: undefined,
      mktPrefs: false,
      school: {
        id: undefined,
        name: '',
        country: undefined
      },
      terms: false,
      loading: false,
      errors: {
        name: false,
        surname1: false,
        surname2: false,
        email: false,
        email_in_plus: false,
        password: false,
        password2: false,
        card: false,
        phone: false,
        school: false,
        premiumCode: false,
        user_exist: false,
        card_exist: false,
        generic_error: false,
        unvalidated: false
      },
      showSchoolFinder: false,
      success: false,
      showModal: false,
      fromThirdParties: false,
      idaccountexternalplatform: '',
      idexternalplatform: '',
      platformName: '',
      id: '',
      returnTo: ''
    }
  }

  componentDidMount() {
    const { id, hash, returnTo } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { history } = this.props

    //console.log('ID: ', id, ' HASH: ', hash)
    if (id && hash) {
      axiosClient.post('/third-parties/checkredirect', { id, hash })
        .then(async (response) => { //console.log('DATA REDIRECT: ', response)
          this.setState({
            id: id,
            email: response.data.result.mail,
            name: response.data.result.name,
            surname1: response.data.result.surname1,
            surname2: response.data.result.surname2,
            idaccountexternalplatform: response.data.result.idaccountexternalplatform,
            idexternalplatform: response.data.result.idexternalplatform,

            // MBQ GOOGLE/MS REGISTER: Sign in and deep linking
            platformName: response.data.result.platformName,
            returnTo: response.data.result.returnTo ?
              _.startsWith(response.data.result.returnTo, envSettings.APP_BASEURL, 0) ?
                _.replace(response.data.result.returnTo, new RegExp(`^${envSettings.APP_BASEURL}($|/)`), '/') : '/' : '/',

            showModal: true,
            errors: {
              ...this.state.errors, name: checkValueFromThirdParties(response.data.result.name)
              , surname1: checkValueFromThirdParties(response.data.result.surname1)
              , surname2: response.data.result.surname2 !== '' && checkValueFromThirdParties(response.data.result.surname2)
            }
          })
        })
        .catch(async () => history.push("/error"))
    }
    // MBQ GOOGLE/MS SIGN IN AND REGISTER: Deep linking
    else {
      if (returnTo && validator.isURL(returnTo) && _.startsWith(returnTo, envSettings.APP_BASEURL, 0)) {
        this.setState({
          returnTo: encodeURIComponent(_.replace(returnTo, new RegExp(`^${envSettings.APP_BASEURL}($|/)`), '/'))
        })
      } else {
        this.setState({ returnTo: '/' })
      }
    }
  }

  toggleModal = () => { 
    window.scrollTo(0, 0)
    //this.props.history.push("/register")
    this.setState(prevState => ({error: '', showModal: !prevState.showModal, fromThirdParties:true}))
    //window.history.replaceState({}, document.title, "/app/register")
  }

  validateForm = (setErrors=false, fromThirdParties=false) => {
    const { name, surname1, surname2, email, password, password2, card, phone, school, terms } = this.state
    const errors = {
      name: !teacherValidator.validName(name),
      surname1: !teacherValidator.validSurname1(surname1),
      surname2: !teacherValidator.validSurname2(surname2),
      email: fromThirdParties ? false : !teacherValidator.validEmail(email),
      password: fromThirdParties ? false : !teacherValidator.validPassword(password),
      password2: fromThirdParties ? false : (password2 === '') || (password !== password2),
      school: !school || !school.id,
      card: !teacherValidator.validCard(padCard(card), school.country === envSettings.COUNTRY_ESP_ID),
      phone: phone !== '' && !teacherValidator.validPhone(phone, school.country === envSettings.COUNTRY_ESP_ID),
      terms: !terms
    }
    if(setErrors) this.setState({errors: {...this.state.errors, ...errors}})
    return !_.compact(_.values(errors)).length
  }

  handleInputChange = (event) => {
    // Limit input on certain fields
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const PREMIUM_CODE_LENGTH = 10;

    switch(name) {
      case "name":
      case "surname1":
        this.setState((prevState) => {
          return {
            [name]: (value === '' || validator.matches(value, regexpAlphaWithSpaces)) ? value : prevState[name],
            errors: {
              ...prevState.errors,
              [name]: value.replace(/ /g, '').length < 2
            }
          }
        });
        break;
      case "surname2":
          this.setState((prevState) => {
              return {
                  [name]: (value === '' || validator.matches(value, regexpAlphaWithSpaces)) ? value : prevState[name],
                  errors: {
                      ...prevState.errors,
                      [name]: value.replace(/ /g, '').length < 2 && value !== ''
                  }
              }
          });
          break;
      case "email":
          this.setState((prevState) => {
              return {
                  email: value,
                  errors: {
                    ...prevState.errors,
                    email: !validator.isEmail(value),
                    email_in_plus: false
                  }
              }
          });
          break;
      case "card":
          this.setState((prevState) => {
            const card = padCard(value)
            return {
              card: (value === '' || validator.matches(card, regexAlphaNumeric)) ? value : prevState.card,
              errors: {
                ...prevState.errors,
                card: !isValid(card),
                card_exist: false
              }
            }
          })
          break;
      case "phone":
          this.setState((prevState) => {
              return {
                  phone:
                      (value === '' || validator.isNumeric(value + '')) && (value + '').length < 10
                          ? value
                          : prevState[name],
                      errors: {
                          ...prevState.errors,
                          phone: (value.length < 9 && value.length > 0)
                      }
              }
          });
          break;
      case "password":
          this.setState((prevState) => {
              return {
                  password: value,
                  errors: {
                    ...prevState.errors,
                    password: !validator.matches(value, regexPassword),
                    password2: value !== prevState.password2

                  }
              }
          });
          break;
      case "password2":
          this.setState((prevState) => {
              return {
                  password2: value,
                  errors: {
                      ...prevState.errors,
                      password2: (prevState.password !== value)
                  }
              }
          });
          break;
      case "premiumCode":

          value.length <= 10 && this.setState((prevState) => {
              return {
                premiumCode: (value === '' || (validator.matches(value, regexAlphaNumeric) && value.length <= PREMIUM_CODE_LENGTH)) ? value.toUpperCase() : prevState.premiumCode,
                errors: {
                  ...prevState.errors,
                  premiumCode: !(validator.matches(value, regexAlphaNumeric) && value.length === PREMIUM_CODE_LENGTH)
                }
              }
          });
          break;
      default:
          this.setState({ [name]: value })
          break;
    }
  }

  handleClickNoPremiumCode = (event) => {
    event.preventDefault();
    this.setState({ showSchoolFinder: true })
  }

  handleClickValidatePremiumCode = async (event) => {
    event.preventDefault();
    const token = await this.props.googleReCaptchaProps.executeRecaptcha('registration')
    axiosClient.get(`/school/${this.state.premiumCode}?token=${token}`)
      .then(async (response) => {
        this.setState({
          school: {
            id: response.data.c_centro,
            name: response.data.d_centro,
            country: response.data.c_pais
          },
          premiumCodeValidated: this.state.premiumCode,
          errors: { ...this.state.errors, school: false }
        })
      })
      .catch(async (err) => {
        this.setState({
          premiumCodeValidated: undefined,
          errors: {
            ...this.state.errors,
            premiumCode: true
          }
        })
      })
  }

  sendValidationEmail = async (event) => {
    event.preventDefault()
    const token = await this.props.googleReCaptchaProps.executeRecaptcha('registration')
    axiosClient.post('/user/sendactivation', { email : this.state.email, token })
      .then(async (result) => this.setState({
        success: true,
        fromThirdParties: false,
        errors: { ...this.state.errors, unvalidated: false }
      }))
      .catch(async () => this.setState({
        errors: { ...this.state.errors, unvalidated: false }
        }))
  }

  checkError = (err) => {
    if(err.response && err.response.data && err.response.data.code && (err.response.data.code === 400001)) {
      this.setState({
        loading: false,
        errors: { ...this.state.errors, user_exist: true, generic_error: false }
      })
    } else if(err.response && err.response.data && err.response.data.code && (err.response.data.code === 400002)) {
      this.setState({
        loading: false,
        errors: { ...this.state.errors, unvalidated: true, generic_error: false }
      })
    } else if(err.response && err.response.data && err.response.data.code && (err.response.data.code === 500002)) {
      this.setState({
        loading: false,
        errors: { ...this.state.errors, card_exist: true, generic_error: false }
      })
    } else if(err.response && err.response.data && err.response.data.code && (err.response.data.code === 500003)) {
      this.setState({
        loading: false,
        errors: { ...this.state.errors, email_in_plus: true, generic_error: false }
      })
    } else {
      this.setState({
        loading: false,
        errors: { ...this.state.errors, generic_error: true }
      })
    }
  }

  submit = async (event) => {
    event.preventDefault()
    this.setState({ loading : true }, async () => {
      if(this.validateForm(true, this.state.fromThirdParties)) {
        const user = _.pick(this.state, ['name', 'surname1', 'surname2', 'email', 'password'])
        user.language = this.props.locale
        user.schoolId = this.state.school.id
        user.mktPrefs = !this.state.mktPrefs
        user.token = await this.props.googleReCaptchaProps.executeRecaptcha('registration')

        if(this.state.school.country === envSettings.COUNTRY_ESP_ID) {
          user.card = padCard(this.state.card)
          user.phone = this.state.phone
        }

        if(this.state.premiumCodeValidated) user.premiumCode = this.state.premiumCodeValidated

        if (this.state.fromThirdParties) {
          user.idaccountexternalplatform = this.state.idaccountexternalplatform
          user.idexternalplatform = this.state.idexternalplatform
          user.fromThirdParties = this.state.fromThirdParties
          user.id = this.state.id
          // MBQ GOOGLE/MS REGISTER: Sign in and deep linking
          user.platformName = this.state.platformName
          axiosClient.post('/user/withoutactivation', user)
          .then((response) => {
            this.setState({ success: true, loading: false })
          })
          .catch(async (err) => this.checkError(err))
        } else {
          axiosClient.post('/user', user)
          .then((response) => {
            this.setState({ success: true, loading: false })
          })
          .catch(async (err) => this.checkError(err))
        }
      }
    })
  }

  // MBQ GOOGLE/MS REGISTER: Sign in and deep linking
  accessPortal = async refreshAuth => {
    await refreshAuth()
    this.props.history.push(this.state.returnTo)
  }

  render() {
    const { content } = this.props
    const { showModal, returnTo } = this.state
    return (
      <AuthConsumer>
        {({ refreshAuth }) => (
          <Container fluid={false} className="TeacherRegister">
            {showModal && <ModalAccept className="resources__modal"
              isOpen={showModal}
              toggle={() => this.toggleModal()}
              header={content.useraccount.create_account_title}>
              <p align="center">{content.useraccount.redirection_user_notfound}</p>
            </ModalAccept>}

            <Row>
              <Col><h1>{content.useraccount.create_account_title}</h1></Col>
            </Row>

            <Row>
              <Col md={{ size: 6, offset: 3 }}><h5>{content.useraccount.access_data}</h5></Col>
            </Row>

            {/*{!this.state.fromThirdParties && <Row className="mt-2">
              <Col md={{ size: 6, offset: 3 }}><h6><b>{content.useraccount.register_existing_account}</b></h6></Col>
            </Row>
            }*/}

            {!this.state.fromThirdParties && <Row>
              <Col md={{ size: 6, offset: 3 }} className="text-center">
                <Container>
                  <Row>
                    <Col className="mt-4">
                      {/* <ButtonThirdParties id="google" returnTo={`${envSettings.APP_BASEURL}/register`} routeOrigin="REGISTER" userPlatform={envSettings.PLATFORM_GOOGLE_ID} disabled={this.state.fromThirdParties} /> */}
                      <ButtonThirdParties id="google" returnTo={`${envSettings.APP_BASEURL}${returnTo}`} routeOrigin="REGISTER" userPlatform={envSettings.PLATFORM_GOOGLE_ID} disabled={this.state.fromThirdParties} text={content.useraccount.register_existing_account}/>
                    </Col>
                    <Col className="mt-4">
                      {/* <ButtonThirdParties id="microsoft" returnTo={`${envSettings.APP_BASEURL}/register`} routeOrigin="REGISTER" userPlatform={envSettings.PLATFORM_MICROSOFT_ID} disabled={this.state.fromThirdParties} /> */}
                      <ButtonThirdParties id="microsoft" returnTo={`${envSettings.APP_BASEURL}${returnTo}`} routeOrigin="REGISTER" userPlatform={envSettings.PLATFORM_MICROSOFT_ID} disabled={this.state.fromThirdParties} text={content.useraccount.register_existing_account}/>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            }

            {!this.state.fromThirdParties && <Row className="mt-5 mb-3">
              <Col md={{ size: 6, offset: 3 }}><h6><b>{content.useraccount.register_email}</b></h6></Col>
            </Row>
            }

            <Row>
              <Col md={{ size: 6, offset: 3 }}>
                <Form noValidate>

                  {this.state.fromThirdParties && parseToReactElement(interpolate(content.useraccount.register_third_parties_title, PLACEHOLDERS.THIRD_PARTY, (this.state.idexternalplatform === envSettings.PLATFORM_GOOGLE_ID ? 'Google' : (this.state.idexternalplatform === envSettings.PLATFORM_MICROSOFT_ID ? 'Microsoft' : ''))), null, { ADD_ATTR: ['target'] })}
                  <FormGroup>
                    <Label for="email">{content.useraccount.email}</Label>
                    <Input
                      id="email"
                      type="email"
                      name="email"
                      autoComplete="off"
                      disabled={this.state.fromThirdParties}
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      valid={!this.state.errors.email && !this.state.errors.email_in_plus && this.state.email !== ''}
                      invalid={this.state.errors.email || this.state.errors.email_in_plus}
                    />
                    {this.state.errors.email && <FormFeedback>{content.useraccount.error_registration_email}</FormFeedback>}
                    {this.state.errors.email_in_plus && <FormFeedback>{content.useraccount.error_email_in_plus}</FormFeedback>}
                  </FormGroup>
                  {!this.state.fromThirdParties && <FormGroup>
                    <Label for="password">{content.useraccount.password}</Label>
                    <Input
                      id="password"
                      type="password"
                      name="password"
                      autoComplete="off"
                      disabled={this.state.fromThirdParties}
                      value={this.state.password}
                      onChange={this.handleInputChange}
                      valid={!this.state.errors.password && this.state.password !== ''}
                      invalid={this.state.errors.password}
                    />
                    <FormFeedback>{content.useraccount.password_invalid}</FormFeedback>
                  </FormGroup>
                  }
                  {!this.state.fromThirdParties && <FormGroup>
                    <Label for="password2">{content.useraccount.password2}</Label>
                    <Input
                      id="password2"
                      type="password"
                      name="password2"
                      autoComplete="off"
                      disabled={this.state.fromThirdParties}
                      value={this.state.password2}
                      onChange={this.handleInputChange}
                      valid={!this.state.errors.password2 && this.state.password !== '' && (this.state.password === this.state.password2)}
                      invalid={this.state.errors.password2}
                    />
                    {(this.state.password2 === '') && <FormFeedback>{content.useraccount.error_password_confirmation_empty}</FormFeedback>}
                    {(this.state.password !== this.state.password2) && <FormFeedback>{content.useraccount.password_confirmation_invalid}</FormFeedback>
                    }
                  </FormGroup>
                  }
                  <h5>{content.useraccount.school_data}</h5>
                  <FormGroup>
                    <Label for="premiumCode">{content.useraccount.premium_code}</Label>
                    <Input
                      id="premiumCode"
                      type="text"
                      name="premiumCode"
                      autoComplete="off"
                      value={this.state.premiumCode}
                      onChange={this.handleInputChange}
                      valid={this.state.premiumCodeValidated === this.state.premiumCode}
                      invalid={this.state.errors.premiumCode && (this.state.school && !this.state.school.id)}
                      disabled={!!(this.state.school && this.state.school.id)}
                    />
                    <FormFeedback>{content.useraccount.error_premium_code}</FormFeedback>
                    <FormText color="none" className="formtext">{content.useraccount.premium_code_help}</FormText>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md="6" className="my-2">
                        <ButtonPremium onClick={this.handleClickNoPremiumCode} disabled={!!(this.state.school && this.state.school.id)}>{content.useraccount.no_premium_code}</ButtonPremium>
                      </Col>
                      <Col md="6" className="my-2">
                        <ButtonPremium primary onClick={this.handleClickValidatePremiumCode} disabled={this.state.premiumCode === '' || this.state.errors.premiumCode || !!(this.state.school && this.state.school.id)}>{content.useraccount.check_premium_code}</ButtonPremium>
                      </Col>
                    </Row>
                  </FormGroup>
                  {this.state.errors.school && <div className="error">{content.useraccount.error_school}</div>}
                  {this.state.school && this.state.school.id && (
                    <div className="school-info">
                      <div className="school-info-school">{this.state.school.name}</div>
                    </div>
                  )}
                  {this.state.school && this.state.school.id && (
                    <Fragment>
                      <h5>{content.useraccount.teacher_data}</h5>
                      <FormGroup>
                        <Label for="name">{content.useraccount.name}</Label>
                        <Input
                          id="name"
                          type="text"
                          name="name"
                          autoComplete="off"
                          value={this.state.name}
                          onChange={this.handleInputChange}
                          valid={!this.state.errors.name && this.state.name !== ''}
                          invalid={this.state.errors.name}
                        />
                        {this.state.name === '' ?
                          <FormFeedback>{content.useraccount.error_name}</FormFeedback> :
                          <FormFeedback>{content.useraccount.error_name_invalid}</FormFeedback>
                        }
                      </FormGroup>
                      <FormGroup>
                        <Label for="surname1">{content.useraccount.surname1}</Label>
                        <Input
                          id="surname1"
                          type="text"
                          name="surname1"
                          autoComplete="off"
                          value={this.state.surname1}
                          onChange={this.handleInputChange}
                          valid={!this.state.errors.surname1 && this.state.surname1 !== ''}
                          invalid={this.state.errors.surname1}
                        />
                        {this.state.surname1 === '' ?
                          <FormFeedback>{content.useraccount.error_surname1}</FormFeedback> :
                          <FormFeedback>{content.useraccount.error_surname1_invalid}</FormFeedback>
                        }
                      </FormGroup>
                      <FormGroup>
                        <Label for="surname2">{content.useraccount.surname2}</Label>
                        <Input
                          id="surname2"
                          type="text"
                          name="surname2"
                          autoComplete="off"
                          value={this.state.surname2}
                          onChange={this.handleInputChange}
                          valid={!this.state.errors.surname2 && this.state.surname2 !== ''}
                          invalid={this.state.errors.surname2}
                        />
                        <FormFeedback>{content.useraccount.error_surname2_invalid}</FormFeedback>
                      </FormGroup>
                      {(this.state.school && (this.state.school.country === envSettings.COUNTRY_ESP_ID)) &&
                        <Fragment>
                          <FormGroup>
                            <Label for="card">{content.useraccount.card}</Label>
                            <Input
                              id="card"
                              type="text"
                              name="card"
                              autoComplete="off"
                              value={this.state.card}
                              onChange={this.handleInputChange}
                              valid={!this.state.errors.card && (this.state.card !== '') && !this.state.errors.card_exist}
                              invalid={this.state.errors.card || this.state.errors.card_exist}
                            />
                            {(this.state.card === '') && <FormFeedback>{content.useraccount.error_card_empty}</FormFeedback>}
                            {(this.state.card !== '') && this.state.errors.card && <FormFeedback>{content.useraccount.error_card_invalid}</FormFeedback>}
                            {this.state.errors.card_exist && <FormFeedback>{parseToReactElement(interpolate(interpolate(content.useraccount.error_card_exist, PLACEHOLDERS.LINK_URL, envSettings.CONTACT_URL), PLACEHOLDERS.FORGOT_PASSWORD, envSettings.PASSWORD_URL), null, { ADD_ATTR: ['target'] })}</FormFeedback>}
                          </FormGroup>
                          <FormGroup>
                            <Label for="phone">{content.useraccount.phone}</Label>
                            <Input
                              id="phone"
                              type="phone"
                              name="phone"
                              autoComplete="off"
                              value={this.state.phone}
                              onChange={this.handleInputChange}
                              valid={!this.state.errors.phone && this.state.phone !== ''}
                              invalid={this.state.errors.phone}
                            />
                            <FormFeedback>{content.useraccount.error_phone}</FormFeedback>
                          </FormGroup>
                        </Fragment>
                      }
                      <CustomInput
                        type="checkbox"
                        id="mktPrefs"
                        name="mktPrefs"
                        label={content.useraccount.mkt_prefs}
                        value={this.state.mktPrefs}
                        onChange={this.handleInputChange}
                      />
                      <CustomInput
                        type="checkbox"
                        id="terms"
                        name="terms"
                        label={parseToReactElement(content.useraccount.registration_terms, null, { ADD_ATTR: ['target'] })}
                        value={this.state.terms}
                        onChange={this.handleInputChange}
                        invalid={this.state.errors.terms}
                      />
                      <Row className="mt-5 mb-5">
                        <Col md={{ size: 4, offset: 2 }} className="my-2">
                          <ButtonPremium onClick={() => this.props.history.push("/")}>
                            {content.sharedTerms.CANCEL_BUTTON_TEXT}
                          </ButtonPremium>
                        </Col>
                        <Col md="4" className="my-2">
                          <ButtonPremium disabled={!this.validateForm(false, this.state.fromThirdParties) || this.state.loading} primary onClick={this.submit}>
                            {content.sharedTerms.ACCEPT_BUTTON_TEXT}
                          </ButtonPremium>
                        </Col>
                      </Row>
                      {this.state.errors.card_exist &&
                        <Row>
                          <Col>
                            <p className="text-center error">{parseToReactElement(interpolate(content.useraccount.error_card_exist, PLACEHOLDERS.LINK_URL, envSettings.CONTACT_URL), null, { ADD_ATTR: ['target'] })}</p>
                          </Col>
                        </Row>
                      }
                      {this.state.errors.generic_error &&
                        <Row>
                          <Col>
                            <p className="error">{parseToReactElement(content.useraccount.registration_generic_error)}</p>
                          </Col>
                        </Row>
                      }
                      {this.state.loading &&
                        <Row>
                          <Col>
                            <Fade in={this.state.loading} tag="p" className="text-center">{content.useraccount.registration_loading}</Fade>
                          </Col>
                        </Row>
                      }
                    </Fragment>)}

                  <Row className="mt-5">
                    <Col className="text-center">
                      <Help />
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col className="small">{parseToReactElement(content.useraccount.privacy_policy, null, { ADD_ATTR: ['target'] })}</Col>
                  </Row>
                  <Row className="my-3">
                    <Col><RecaptchaTerms /></Col>
                  </Row>
                </Form>
              </Col>
              <ModalSchoolByPostalCode
                isOpen={this.state.showSchoolFinder}
                onData={(school) => this.setState({
                  premiumCodeValidated: undefined,
                  school,
                  showSchoolFinder: false,
                  errors: { ...this.state.errors, school: false }
                })}
                onClose={() => this.setState({ showSchoolFinder: false })}
              />
              {
                // toggle={() => window.location = envSettings.APP_BASEURL }
              }
              <ModalAccept
                isOpen={this.state.success && !this.state.fromThirdParties}
                toggle={() => this.props.history.push("/")}
                header={content.useraccount.check_email}
              >
                <p>{parseToReactElement(format(content.useraccount.registration_success_1, { email: this.state.email }))}</p>
                <p>{content.useraccount.registration_success_2}</p>
              </ModalAccept>

              <ModalAccept
                isOpen={this.state.success && this.state.fromThirdParties}
                toggle={() => this.accessPortal(refreshAuth)}
                header={content.emails.welcome_title}
              >
                <p>{parseToReactElement(format(content.useraccount.activate_account_success_body, { email: this.state.email }))}</p>
              </ModalAccept>

              <Modal2Buttons
                isOpen={this.state.errors.unvalidated}
                toggle={() => this.setState({ errors: { ...this.state.errors, unvalidated: false } })}
                header={content.useraccount.register_title}
                primaryButtonLabel={content.useraccount.resend_email}
                secondaryButtonLabel={content.useraccount.change_email}
                primaryButtonOnClick={this.sendValidationEmail}
                secondaryButtonOnClick={() => this.setState({ errors: { ...this.state.errors, unvalidated: false } })}
              >
                <p className="text-center">{parseToReactElement(format(content.useraccount.email_in_use_text, { email: this.state.email }))}</p>
                <p className="text-center">{content.useraccount.check_email_text}</p>
                <p className="text-center"><Help /></p>
              </Modal2Buttons>
              <Modal2Buttons
                isOpen={this.state.errors.user_exist}
                toggle={() => this.setState({ errors: { ...this.state.errors, user_exist: false } })}
                header={content.useraccount.create_account_title}
                primaryButtonLabel={content.header.login_text}
                secondaryButtonLabel={content.useraccount.change_email}
                primaryButtonOnClick={() => this.props.history.push("/signin")}
                secondaryButtonOnClick={() => this.setState({ errors: { ...this.state.errors, user_exist: false } })}
              >
                <p className="text-center">{parseToReactElement(format(content.useraccount.email_in_use_text, { email: this.state.email }))}</p>
                <p className="text-center">{content.useraccount.login_suggestion_text}</p>
                <p className="text-center"><ForgotPassword /></p>
                <p className="text-center"><Help /></p>
              </Modal2Buttons>
            </Row>
          </Container>
        )}
      </AuthConsumer>
    )
  }
}

export default withRouter(withGoogleReCaptcha(withContent(TeacherRegistration)))