import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Container, Col, Row, Jumbotron } from 'reactstrap'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import { parseToReactElement } from '../../utils/html'

class PHPHeader extends Component {

  render() {
    const { assets, content } = this.props

    if (!assets || !assets.length) return null
    else return (
      <Jumbotron 
        fluid 
        className="jumbotron" 
        style={{backgroundImage: `url(${assets[0].image.url})`}}
        >
        <Container>
          <Row className="align-items-end">
            <Col sm="12" lg="8">
              <h1>{assets[0].title}</h1>
              <div className="lead">{parseToReactElement(assets[0].contentItem)}</div>
            </Col>
            <Col xs={{size: 8, offset: 2}} sm={{size: 8, offset: 4}} md={{size: 6, offset: 6 }} lg={{size: 4, offset: 0}}>
              <ButtonPremium primary className="btn-lg" onClick={() => this.props.history.push('/register')}>{content.sharedTerms.REGISTER_BUTTON_TEXT || "Regístrate ya"}</ButtonPremium>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    )
  }
}

export default withRouter(PHPHeader)