import React from 'react'
import _ from 'lodash'
import { Container, Row, Col } from 'reactstrap'
import OrderCartItem from './OrderCartItem'
import { withContent } from '../../../utils/hocs'
import { formatAmount } from '../../../utils/format'

const OrderCart = ({ content, order }) => (
  <Container fluid className="font-small-9">
    <Row className="my-3 px-0 border-bottom-blue">
      <Col className="px-0">
        <h4>{_.get(content, 'coupons.purchase_confirmation_cart_title')}</h4>
      </Col>
    </Row>

    <Row>
      <Col className="px-0">
        <strong>{_.get(content, 'coupons.purchase_confirmation_cart_article')}</strong>
      </Col>
      <Col className="px-0 text-right">
        <strong>{_.get(content, 'coupons.purchase_confirmation_cart_total')}</strong>
      </Col>
    </Row>

    {
      order.products.map(product => (
        <Row key={`${product.id}`}>
          <Col className="mx-0 px-0">
            <OrderCartItem product={product} />
          </Col>
        </Row>
      ))
    }

    <Row className="py-3 border-top-blue">
      <Col className="px-0">
        <strong>{`${_.get(content, 'coupons.purchase_confirmation_cart_total').toUpperCase()}:`}</strong>
      </Col>
      <Col className="px-0 text-right">
        <strong>{`${formatAmount(order.totalAmount)} €`}</strong>
      </Col>
    </Row>

  </Container>
)

export default withContent(OrderCart)