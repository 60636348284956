import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { Progress, Container } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import axios from 'axios'
import _ from 'lodash'

import Jumbotron from '../Jumbotron/Jumbotron'
import Resources from '../Resources/Resources'
import Tabs from '../Tabs/Tabs'
import DigitalContent from '../DigitalContent/DigitalContent'
import TitleCarousel from '../TitleCarousel/TitleCarousel'
import Poll from '../Polls/Poll'

import axiosConfig from '../../config/axios'
import { ContentConsumer, getLocale } from '../../language'
import { AuthConsumer } from 'react-check-auth'
import userRoles from '../../globals/userRoles'

class TitleContext extends Component {

  state = {
    carousel: true,
    carouselOnDemand: false,
    isLoading: true,
    error: null,
    hasPlusPremium: true
  }

  componentDidMount() {
    const { match: { params: { titleid } } } = this.props

    const promises = [
      this.fetchPromise(`/title/${titleid}`, 'detail'),
      this.fetchPromise(`/title/${titleid}/products`, 'products')
    ]

    Promise.all(promises)
      .then(resolve => {
        const detail = resolve.find(data => data.name === 'detail')
        const products = resolve.find(data => data.name === 'products')

        //const { units, info, resources, categories } = detail.data
        const { units, info, resources, categories, editions } = detail.data        

        const installers = resources.filter(resource => resource.isInstaller)

        this.setState({
          units,
          info,
          resources,
          categories,
          editions, // MBQ Edition-language filter for internal user resources
          installers,
          PlusPremiumData: products.data,
          hasPlusPremium: products.data.length > 0 ? true : false,
          isLoading: false
        })

      })
      .catch(reject => { this.setState({ error: reject }) })
  }

  fetchPromise = (url, dataName) => new Promise((resolve, reject) => {
    const axiosInstance = axios.create(
      { ...axiosConfig, headers: { 'Accept-Language': getLocale(this.props.userInfo.language) } }
    )
    axiosInstance.get(url)
      .then(response => {
        response.name = dataName
        resolve(response)
      })
      .catch(error => { reject(error) })
  })

  render() {

    const parsed = queryString.parse(window.location.search)
    const checkCurrentView = () => parseInt(parsed.initTab)

    //const { error, isLoading, info, units, categories, resources, PlusPremiumData, hasPlusPremium, installers, carousel, carouselOnDemand } = this.state,
    const {
        error,
        isLoading,
        info,
        units,
        categories,
        resources,
        editions, // MBQ Edition-language filter for internal user resources
        PlusPremiumData,
        hasPlusPremium,
        installers,
        carousel,
        carouselOnDemand
      } = this.state,
      // MBQ PROMOTIONS
      //{ match: { params } } = this.props
      {
        match: { params },
        userInfo: { role, countryId },
      } = this.props;
      // MBQ PROMOTIONS

    params.showOnlyNew = _.has(parsed, 'new')

    const plusLength = PlusPremiumData ? PlusPremiumData.length : null,
      PlusPremiumRouterError = !hasPlusPremium && checkCurrentView() === 1

    if (error || PlusPremiumRouterError || (checkCurrentView() !== 0 && checkCurrentView() !== 1)) return <Redirect to={`/error`} />


    return (
      <ContentConsumer>
        {({ content }) => (content && (
          <Fragment>
            {!isLoading ?
              <Fragment>
                <Jumbotron
                  params={params}
                  info={info}
                  units={units}
                  categories={categories}
                  role={role} // MBQ PROMOTIONS
                  countryId={countryId} // MBQ PROMOTIONS
                />
                <TitleCarousel
                  isOpen={carousel || carouselOnDemand}
                  isOnDemand={!!carouselOnDemand}
                  onClose={() => this.setState({ carousel: false, carouselOnDemand: false })}
                />
                <Container className="resources py-4" >
                  <Tabs
                    currentView={checkCurrentView()}
                    content={content}
                    hasPlusPremium={hasPlusPremium}
                    plusLength={plusLength}
                  />
                  {checkCurrentView() === 0 &&
                    <Resources
                      params={params}
                      resources={resources}
                      units={units}
                      editions={editions} // MBQ Edition-language filter for internal user resources
                      openCarousel={() => this.setState({ carouselOnDemand: true })}
                    />
                  }
                  <AuthConsumer>
                    {({ userInfo }) => (
                      <Fragment>
                        {((hasPlusPremium || (installers && installers.length)) && checkCurrentView() === 1 && !(userInfo.role === userRoles.USER)) ?
                          <DigitalContent
                            data={PlusPremiumData.map(product => ({ ...product, titleUuid: info.uuid }))}
                            installers={installers}
                            title={info}
                            openCarousel={() => this.setState({ carouselOnDemand: true })}
                          />
                          :
                          null
                        }
                      </Fragment>
                    )}
                  </AuthConsumer>
                </Container>
                <Poll />
              </Fragment>
              : <Progress animated color="primary" value="100" >
                {content.titledetail.loading_library}
              </Progress>
            }
          </Fragment>
        ))}
      </ContentConsumer>

    )
  }
};

const Title = props =>
  <AuthConsumer>
    {({ userInfo }) =>
      <TitleContext
        userInfo={userInfo}
        match={props.match}
      />
    }
  </AuthConsumer>

Title.propTypes = {
  match: PropTypes.object.isRequired
}

export default Title;
