import { useFeature } from '../../contexts/Feature/FeatureContext'
import _ from 'lodash'

export default ({ name, children }) => {
  const feature = _.get(useFeature(), name)

  if(!children) return null

  //if(feature) return children
  if(feature) {
    if(typeof feature === 'function') {
      const result = feature()
      return result ? children : null
    }
    return children
  }

  return null
}