import React, { Component } from 'react'
import WPDataConsumer from '../../contexts/WPData/WPDataConsumer'
import PromotionBanner from './PromotionBanner'
import PropTypes from 'prop-types'
import userRoles from "../../globals/userRoles"
import PromotionTitleSettings, { ClientSettings, PROMOTION_TYPE } from './settings'
import validator from 'validator'
import axios from 'axios'
import axiosConfig from '../../config/axios'
import _ from 'lodash'

const axiosApi = axios.create(axiosConfig)
const axiosWp = axios.create(ClientSettings)

const ERRORS = {
  preferences: {
    ad: 'error-preferences-ad',
    dm: 'error-preferences-dm'
  },
  wordpress: 'error-wordpress'
}

const getContextProps = (props, contextValue) => {
  const { adTitlePromotionBanners, dmTitlePromotionBanners, setTitlePromotionBanners,
    setDmTitlePromotionBanners, getTitleBanner, deleteAdTitlePromotionBanner } = contextValue
  if (!props.idTitle && [userRoles.TEACHER, userRoles.TEACHER_FAKE].includes(props.role))
    return ({ adTitlePromotionBanners, setTitlePromotionBanners })
  if (!props.idTitle && userRoles.TEACHER_DEMO === props.role)
    return ({ dmTitlePromotionBanners, setDmTitlePromotionBanners })
  if (props.idTitle && props.isAdoption)
    return ({
      adTitlePromotionBanners, setTitlePromotionBanners,
      getTitleBanner, deleteAdTitlePromotionBanner
    })
  return ({ dmTitlePromotionBanners, setDmTitlePromotionBanners, getTitleBanner })
}

class PromotionTitle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      promotion: null,
      type: null
    }
  }

  getPreferences = type => (
    axiosApi.get(`/promotions-title/preferences?type=${type}`).then(response => response.data)
      .catch(error => {
        const e = new Error(error.message)
        e.name = ERRORS.preferences[type]
        throw e
      })
  )

  getWpPosts = preferences => (
    axiosWp.get(`posts/?_embed=&per_page=${PromotionTitleSettings.MAX_ITEMS}&categories=${PromotionTitleSettings.BANNER_CATEGORY}`)
      .then(({ data: posts }) => (
        posts.reduce((acc, post) => {
          if (preferences.promotions.includes(post.id) ||
            ![...preferences.languagesWp, PromotionTitleSettings.LANGUAGES_ROOT]
              .find(languageId => post.categories.includes(languageId)) ||
            !post.title.rendered || !post.title.rendered.length ||
            !post.content.rendered || !post.content.rendered.length ||
            !post.excerpt.rendered || !post.excerpt.rendered.length)
            return acc

          const blacklist = ['<p>', '</p>', '\n']
          blacklist.forEach(element => {
            post.excerpt.rendered = post.excerpt.rendered.replace(element, '')
          })
          post.excerpt.rendered = post.excerpt.rendered.trim()

          if (/^DM:0$/gi.test(post.excerpt.rendered) && this.props.role === userRoles.TEACHER) {
            acc.push(({
              id: post.id,
              title: post.title.rendered,
              content: post.content.rendered,
              languages: post.categories.filter(category => (
                [...preferences.languagesWp, PromotionTitleSettings.LANGUAGES_ROOT].includes(category)))
            }))
            return acc
          }

          if (/^AD:\d+(,\d+)*@http(s)?:\/\/.+$/gi.test(post.excerpt.rendered)) {
            if (!post._embedded['wp:featuredmedia'] || !post._embedded['wp:featuredmedia'].length ||
              post._embedded['wp:featuredmedia'][0].media_type !== 'image')
              return acc

            const [titles, urlMoreInfo] = post.excerpt.rendered.split('@')
            if (!validator.isURL(urlMoreInfo)) return acc
            const titlesAd = titles.split(':')[1].split(',').map(title => parseInt(title)).filter(title => preferences.schoolAdoptions.includes(title))
            if (!titlesAd.length) return acc
            acc.push(({
              id: post.id,
              title: post.title.rendered,
              content: post.content.rendered,
              image: {
                alt: post._embedded['wp:featuredmedia'][0].alt_text || post._embedded['wp:featuredmedia'][0].title.rendered,
                url: {
                  full: post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
                  medium: post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url,
                  thumbnail: post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url
                }
              },
              languages: post.categories.filter(category => (
                [...preferences.languagesWp, PromotionTitleSettings.LANGUAGES_ROOT].includes(category))),
              idTitles: _.sortedUniq(_.sortBy(titlesAd)),
              moreInfo: urlMoreInfo
            }))
            return acc
          }

          return acc
        }, [])
      )).catch(error => {
        const e = new Error(error.message)
        e.name = ERRORS.wordpress
        throw e
      })
  )

  getDmWpPosts = preferences => (
    axiosWp.get(`posts/?categories=${PromotionTitleSettings.BANNER_CATEGORY}&search=DM:0`)
      .then(({ data: posts }) => (
        posts.reduce((acc, post) => {
          if (![...preferences.languagesWp, PromotionTitleSettings.LANGUAGES_ROOT]
            .find(languageId => post.categories.includes(languageId)) ||
            !post.content.rendered || !post.content.rendered.length ||
            !post.excerpt.rendered || !post.excerpt.rendered.length ||
            !/^DM:0$/gi.test(validator.blacklist(post.excerpt.rendered.trim(), '<p></p>\n')))
            return acc
          acc.push(({
            id: post.id,
            title: post.title.rendered,
            content: post.content.rendered,
            languages: post.categories.filter(category => (
              [...preferences.languagesWp, PromotionTitleSettings.LANGUAGES_ROOT].includes(category)))
          }))
          return acc
        }, [])
      )).catch(error => {
        const e = new Error(error.message)
        e.name = ERRORS.wordpress
        throw e
      })
  )

  openInformation = (idTitle, idPromotion, titlePromotion, urlInformation) => (
    axiosApi.post(`/promotions-title/${idPromotion}`, { idTitle, titlePromotion, urlInformation })
      .catch(error => {
        console.error('PROMOTIONS-TITLE : ', error.toString())
      })
  )

  closeBanner = idPromotion => (
    axiosApi.put(`/promotions-title/${idPromotion}`)
      .then(async () => {
        await this.props.deleteAdTitlePromotionBanner(idPromotion)
      }).catch(error => {
        console.error('PROMOTIONS-TITLE : ', error.toString())
      }).finally(() => {
        this.setState({ promotion: null, type: null })
      })
  )

  async componentDidMount() {
    try {
      const { role, idTitle, isAdoption } = this.props
      let preferences = null
      let wpPosts = null
      if (!idTitle && [userRoles.TEACHER, userRoles.TEACHER_FAKE].includes(role)) {
        if (!this.props.adTitlePromotionBanners) {
          preferences = await this.getPreferences(PROMOTION_TYPE.AD)
          wpPosts = await this.getWpPosts(preferences)
          this.props.setTitlePromotionBanners(wpPosts)
        }
      } else if (!idTitle && userRoles.TEACHER_DEMO === role) {
        if (!this.props.dmTitlePromotionBanners) {
          preferences = await this.getPreferences(PROMOTION_TYPE.DM)
          wpPosts = await this.getDmWpPosts(preferences)
          this.props.setDmTitlePromotionBanners(wpPosts)
        }
      } else if (idTitle && isAdoption) {
        if (!this.props.adTitlePromotionBanners) {
          preferences = await this.getPreferences(PROMOTION_TYPE.AD)
          wpPosts = await this.getWpPosts(preferences)
          await this.props.setTitlePromotionBanners(wpPosts)
          if (wpPosts.length > 0) {
            const promotion = this.props.getTitleBanner(preferences.languageId, PromotionTitleSettings.LANGUAGES_ROOT, idTitle)
            if (promotion)
              this.setState({ promotion, type: PROMOTION_TYPE.AD })
          }
        } else {
          if (this.props.adTitlePromotionBanners.length > 0) {
            preferences = await this.getPreferences(PROMOTION_TYPE.AD)
            const promotion = this.props.getTitleBanner(preferences.languageId, PromotionTitleSettings.LANGUAGES_ROOT, idTitle)
            if (promotion)
              this.setState({ promotion, type: PROMOTION_TYPE.AD })
          }
        }
      } else {
        if (!this.props.dmTitlePromotionBanners) {
          preferences = await this.getPreferences(PROMOTION_TYPE.DM)
          wpPosts = await this.getDmWpPosts(preferences)
          await this.props.setDmTitlePromotionBanners(wpPosts)
          if (wpPosts.length > 0) {
            const promotion = this.props.getTitleBanner(preferences.languageId, PromotionTitleSettings.LANGUAGES_ROOT)
            if (promotion)
              this.setState({ promotion, type: PROMOTION_TYPE.DM })
          }
        } else {
          if (this.props.dmTitlePromotionBanners.length > 0) {
            preferences = await this.getPreferences(PROMOTION_TYPE.DM)
            const promotion = this.props.getTitleBanner(preferences.languageId, PromotionTitleSettings.LANGUAGES_ROOT)
            if (promotion)
              this.setState({ promotion, type: PROMOTION_TYPE.DM })
          }
        }
      }
    } catch (error) {
      switch (error.name) {
        case ERRORS.preferences.ad:
          console.error('PROMOTIONS-TITLE : ', error.toString())
          this.props.setTitlePromotionBanners([])
          break
        case ERRORS.preferences.dm:
          console.error('PROMOTIONS-TITLE : ', error.toString())
          this.props.setDmTitlePromotionBanners([])
          break
        default:
          console.error('PROMOTIONS-TITLE : ', error.toString())
          break
      }
    }
  }

  render() {
    const { promotion, type } = this.state
    return promotion && (
      type === PROMOTION_TYPE.AD ?
        (
          <PromotionBanner promotion={promotion} type={type} idTitle={this.props.idTitle}
            openInformation={this.openInformation} closeBanner={this.closeBanner} />
        )
        :
        (
          <PromotionBanner promotion={promotion} type={type} />
        )
    )
  }
}

PromotionTitle.propTypes = {
  role: PropTypes.string.isRequired,
  idTitle: PropTypes.number,
  isAdoption: PropTypes.bool
}

export default props => (
  <WPDataConsumer>{contextValue => (
    <PromotionTitle {...getContextProps(props, contextValue)} {...props} />
  )}
  </WPDataConsumer>
)