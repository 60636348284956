import axios from 'axios'
import axiosConfig from '../config/axios'
const axiosInstance = axios.create(axiosConfig)
export function validateLicense(code, token, cb){
  axiosInstance.get(`license/user/${code}?token=${token}`)
  .then((response)=>cb(null,response.data))
  .catch((error)=>cb(error))
}
export function activateLicense(code, token, cb){
  axiosInstance.put(`library/product/${code}?token=${token}`)
  .then((response)=>cb(null,response.data))
  .catch((error)=>cb(error))
}
export function lti(title,cb,cbe){
  axiosInstance.post('/license/lti/oauth',{titleUuid:title})
  .then((response)=>cb(response.data))
  .catch((error)=>{
    console.log(error)
    cbe({})
  })
}
