import React, { useState } from 'react'
import _ from 'lodash'
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Container, Row, Col, Input
} from 'reactstrap'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import validator from 'validator'
import axios from 'axios'

import ButtonCoupons from '../../ButtonCoupons/ButtonCoupons'
import { parseToReactElement } from '../../../utils/html'
import { withContent } from '../../../utils/hocs'

import axiosConfig from '../../../config/axios'

import './LostAccessModal.css'

const RESULT = {
  SUCCESS: 'success',
  ERROR: 'error'
}

const LostAccessModal = ({ content, isOpen, onClose, googleReCaptchaProps }) => {
  const [email, setEmail] = useState('')
  const [valid, setValid] = useState(false)
  const [result, setResult] = useState(null)

  const handleInputChange = (event) => {
    const { target: { value } } = event
    setEmail(value)
    setValid(validator.isEmail(value))
  }
  const submit = async () => {
    const axiosClient = axios.create({ ...axiosConfig, timeout: 60000 })
    const token = await googleReCaptchaProps.executeRecaptcha('coupons')

    axiosClient.post('/coupons/forgot', { email, token })
      .then(() => setResult(RESULT.SUCCESS))
      .catch(() => setResult(RESULT.ERROR))
  }

  return (
    <Modal
      className="coupons-modal"
      isOpen={isOpen}
    >
      <ModalHeader className="text-center">
        { result === null && _.get(content, 'coupons.modal_lostaccess_header') }
        { result === RESULT.SUCCESS && _.get(content, 'coupons.modal_lostaccess_success_header') }
        { result === RESULT.ERROR && _.get(content, 'coupons.modal_lostaccess_error_header') }
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row className="mb-3">
            <Col>
              { result === null && parseToReactElement(_.get(content, 'coupons.modal_lostaccess_body'), null, { ADD_ATTR: ['target'] }) }
              { result === RESULT.SUCCESS && parseToReactElement(_.get(content, 'coupons.modal_lostaccess_success_body'), null, { ADD_ATTR: ['target'] }) }
              { result === RESULT.ERROR && parseToReactElement(_.get(content, 'coupons.modal_lostaccess_error_body'), null, { ADD_ATTR: ['target'] }) }
            </Col>
          </Row>
          { result === null && (
            <Row>
              <Col xs="10">
                <Input
                  type="email"
                  id="forgot_email"
                  name="forgot_email"
                  autoComplete="off"
                  value={email}
                  onChange={handleInputChange}
                  placeholder={content.coupons.redemption_type_email}
                  valid={!!email && valid}
                  invalid={!!email && !valid}
                />
              </Col>
              <Col className="d-flex px-0" xs="2">
                <ButtonCoupons
                  className="coupons-modal-lostaccess m-auto"
                  primary
                  disabled={!valid}
                  onClick={() => submit()}
                >
                  {_.get(content, 'coupons.modal_lostaccess_submit')}
                </ButtonCoupons>
              </Col>
            </Row>
          )}
        </Container>
      </ModalBody>
      <ModalFooter className="mb-3">
        <ButtonCoupons
          primary
          onClick={() => {
            onClose()
            setEmail('')
            setValid(false)
            setResult(null)
          }}
        >
          { result === null && _.get(content, 'coupons.modal_lostaccess_button') }
          { result === RESULT.SUCCESS && _.get(content, 'coupons.modal_lostaccess_success_button') }
          { result === RESULT.ERROR && _.get(content, 'coupons.modal_lostaccess_error_button') }
        </ButtonCoupons>
      </ModalFooter>
    </Modal>
  )
}

export default withGoogleReCaptcha(withContent(LostAccessModal))
