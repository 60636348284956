import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import cx from 'classnames'
import { Row, Col } from 'reactstrap'

import Categories from '../../Categories/Categories'
import ResourceList from '../../ResourceList/ResourceList'
import Audio from '../../Resources/components/Audio'
import Video from '../../Resources/components/Video'
import PDFViewer from '../../Resources/components/PDFViewer'
import NoViewer from '../../Resources/components/NoViewer'
import CMSContent from '../../CMSContent/CMSContent'
import { withContent } from '../../../utils/hocs'
// import { getLocale } from '../../../language'

// Settings constants
import envSettings from '../../../globals/envSettings'

const STUDENTS_UNIT = 'student'

class Resources extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      ShowLightBox: false,
      filteredResources: [],
      lightBoxData: {
        component: null,
        showZoom: false,
        data: null
      }
    }
  }

  componentDidMount() {
    const { catid, subcatid, title: { resources, units }} = this.props
    const unit = units.find(unit => unit.name.toLowerCase() === STUDENTS_UNIT)
    if(!unit) this.setState({ filteredResources: undefined })
    else this.filterResources(resources, filteredResources => {
      this.validateUrl(filteredResources, unit.uuid, catid, subcatid)
    })
  }

  validateUrl = (filteredResources, unitid, catid, subcatid) => {

    const hasUnit = filteredResources.find(data => data.unitUuid === unitid) || null

    if (!hasUnit) this.setState({ error: true })

    else if (catid !== 'all' && subcatid !== 'all') {
      const hasParent = hasUnit.parentIds.find(data => data.parentId === catid) || null

      if (!hasParent) this.setState({ error: true })
      else {
        const hasCategories = hasParent.categories.find(data => data.categoryId === subcatid) || null

        if (!hasCategories) this.setState({ error: true })
        else this.setState({ filteredResources, unitid })
      }
    }

    else if (catid !== 'all') {
      const hasParent = hasUnit.parentIds.find(data => data.parentId === catid) || null

      if (!hasParent) this.setState({ error: true })
      else this.setState({ filteredResources, unitid })
    }

    else if (catid === 'all' && subcatid !== 'all') this.setState({ error: true })
    else this.setState({ filteredResources, unitid })
  }

  filter = arr => arr.reduce((accumulator, currentValue) => {

    if (Array.isArray(currentValue.categories)) {
      currentValue.categories.forEach(data => {

        let hasParentId = accumulator.find(acc => acc.parentId === data.parentId) || null;

        if (!hasParentId) {
          let categories = [{ ...data, resources: [currentValue] }]
          accumulator.push({
            parentId: data.parentId,
            parentName: data.parentName,
            categories
          })
        }

        else {
          const hasCategory = hasParentId.categories.find(acc => acc.categoryId === data.categoryId) || null;
          if (!hasCategory) {
            hasParentId.categories.push({ ...data, resources: [currentValue] })
            hasParentId.categories = _.sortBy(hasParentId.categories, 'name')
          }
          else {
            hasCategory.resources.push(currentValue)
            hasCategory.resources = _.sortBy(hasCategory.resources, 'name')
          }
        }
      }
      )
    }

    return _.sortBy(accumulator, 'parentName')

  }, [])

  filterResources = (resources, callback) => {

    const allResources = {
      parentIds: this.filter(resources),
      unitUuid: 'all'
    }

    const filteredResources = resources.reduce((accumulator, currentValue) => {

      const hasValue = accumulator.find(unit => unit.unitUuid === currentValue.unitUuid) || null;
      if (!hasValue) {
        const newResources = resources.filter(resource => resource.unitUuid === currentValue.unitUuid);
        accumulator.push({
          unitUuid: currentValue.unitUuid,
          parentIds: this.filter(newResources)
        })
      }

      return accumulator

    }, [ allResources ])

    callback(filteredResources)
  }

  updateLightBoxState = (Comp, data) => {
    const newlightBoxData = {
      component: Comp === data.previewURL ? `${envSettings.API_BASEURL}${data.previewURL}` : <Comp data={data} />,
      showZoom: Comp === data.previewURL ? true : false,
      data
    }
    this.setState({ lightBoxData: newlightBoxData })
  }

  handleLightBox = uuid => {

    if (!uuid) this.setState({ ShowLightBox: false })
    else {
      const { title: { resources } } = this.props,
        data = resources.find(data => data.uuid === uuid )

      this.setState({ ShowLightBox: true })

      const audioPlayer = /mpeg|x-wav/.test(data.mimetype),
        videoPlayer = /mp4/.test(data.mimetype),
        isBlank = !data.previewURL || /^\s*$/.test(data.previewURL),
        isImage = /jpg|jpeg|png/.test(data.mimetype)

      if (!isBlank && !audioPlayer && !videoPlayer && !isImage) this.updateLightBoxState(PDFViewer, data)
      else if (audioPlayer) this.updateLightBoxState(Audio, data)
      else if (videoPlayer) this.updateLightBoxState(Video, data)
      else if (isImage) this.updateLightBoxState(data.previewURL, data)
      else this.updateLightBoxState(NoViewer, data)

    }
  }

  render() {

    const { catid, subcatid, token, locale } = this.props,
      { filteredResources, error, ShowLightBox, lightBoxData, unitid } = this.state

    if (error) return <Redirect to={`/error`} />

    let hasResources = filteredResources && (filteredResources.length !==0)
    if(hasResources) {
      const data = filteredResources.find(item => item.unitUuid === unitid)
      if(!data) hasResources = false
      else {
        const resources = data.parentIds
          .filter(data => data.parentId === catid || catid === 'all')
        if(!resources) hasResources = false
        else hasResources = !!resources.filter(data => data.categoryId === subcatid || subcatid === 'all')
        hasResources = !!resources.length
      }
    }

    return (
      <Fragment>
        <Row>
          <Col>
            { hasResources ?
            <div className="resources__schema">
              <Row>
                <Col className={cx({
                  'd-none' : (catid && catid !== 'all') || (subcatid && subcatid !== 'all'),
                  'd-lg-block' : true
                })} xl="3" lg="3" md="12" xs="12" >
                  <Categories
                    filteredResults={filteredResources}
                    params={{ titleid: token, catid }}
                    onlyCat={false}
                    getLink={ (token, unitid, catid, subcatid) => `/student/${token}/cat/${catid}/subcat/${subcatid}` }
                  />
                </Col>
                <Col xl="9" lg="9" md="12" xs="12" className="overflow-hidden">
                  <div className="resources__listContainer p-4 mt-3">
                    <ResourceList
                      filteredResults={filteredResources}
                      params={{ titleid: token, catid, subcatid, unitid }}
                      lightBoxData={lightBoxData}
                      ShowLightBox={ShowLightBox}
                      handleLightBox={this.handleLightBox}
                      students
                    />
                  </div>
                </Col>
              </Row>
            </div>
            : <CMSContent pageid="PREM_NO_RESULTS" language={locale} />
            }
          </Col>
        </Row>
      </Fragment>
    )
  }
}

Resources.propTypes = {
  units: PropTypes.array,
  params: PropTypes.object
}

export default withContent(Resources)
