import React from 'react'
import Modal from '../Modal/Modal'
import PDRegistration from './PDRegistration'
import PDError from './PDError'

const PDModal = ({ announcement, registration, error, cancel, showError, closeModal, reopenRegistration }) => (
  (registration || error) && (
    <Modal
      className={`pdCourses__modal ${registration ? 'registration' : 'error'}`}
      isOpen={registration || error}
      toggle={closeModal}
      size="lg"
      rainbow={false}
    >
      {
        registration ?
          (
            <PDRegistration announcement={announcement} cancel={cancel} showError={showError} closeModal={closeModal} reopenRegistration={reopenRegistration} />
          )
          :
          (
            <PDError closeModal={closeModal} />
          )
      }
    </Modal>
  )
)

export default PDModal