import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import CMSContent from '../CMSContent/CMSContent'
import { withContent } from '../../utils/hocs'
import { isProductViewer } from '../../globals/envSettings'

class CookieBanner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false
    }
  }

  componentDidMount() {
    const productViewer = isProductViewer(this.props.location.pathname)
    if(!productViewer) {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted')
      this.setState({ visible: !cookiesAccepted })
    }
  }

  onAccept = () => {
    localStorage.setItem('cookiesAccepted', true)
    this.setState({ visible: false })
  }

  render() {
    const { content, locale } = this.props
    const { visible } = this.state

    if(visible) {
      return (
        <article className="cookieBanner">
          <Container fluid>
            <Row>
              <Col className="d-block d-lg-flex">
                <div className="text-left text">
                  <CMSContent pageid="PREM_COOKIEBANNER" language={locale} />
                </div>
                <div className="buttons">
                  <ButtonPremium
                    id="cookieBannerInfo"
                    onClick={() =>{window.open(content.cookiebanner.findoutmore_link, "_blank")}}
                  >{ content.cookiebanner.findoutmore }  <span className="glyphicon glyphicon-share ml-1" aria-hidden="true"></span></ButtonPremium>
                  <ButtonPremium
                    primary
                    id="cookieBannerClose"
                    onClick={this.onAccept}>{ content.cookiebanner.continue }</ButtonPremium>
                </div>
              </Col>
            </Row>
          </Container>
        </article>
      )
    }
    return null
  }
}

export default withContent(CookieBanner)
