import React from "react"
import { Container, Row, Col } from "reactstrap"
import { ContentConsumer } from "../../language"
import ButtonPremium from "../ButtonPremium/ButtonPremium"
import { Link } from "react-router-dom"
import appleImage from "../../assets/images/happy-apple.png"

const PDCompletedCatalogue = (props) => (
  <ContentConsumer>
    {({ content = {} }) =>
      content && (
        <Container fluid={true} className="pt-5 pb-5 pl-md-5 pr-md-5">
          <Row>
            <Col lg={{ size: 10, offset: 1 }} className="text-center">
              <Container className="pdCompletedCatalogue">
                <Row>
                  <Col xs="12" md="6" className="pt-5 mt-lg-5 text-left">
                    <p className="pt-lg-3 mt-lg-3">
                      <h5>
                        {props.moreCourses
                          ? content.pdcourses.completedcatalogue_more_courses
                          : content.pdcourses.completedcatalogue_no_more_courses}
                      </h5>
                    </p>
                    <p className="pt-5">
                      <ButtonPremium
                        tag={Link}
                        to={"/professional-development"}
                        rel="noopener noreferrer"
                        primary
                      >
                        {content.pdcourses.button_professional_development}
                      </ButtonPremium>
                    </p>
                  </Col>
                  <Col xs="12" md="6" className="order-first order-md-last">
                    <img
                      alt="completed catalogue"
                      src={appleImage}
                      className="miniature"
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      )
    }
  </ContentConsumer>
)

export default PDCompletedCatalogue