
//Customer
export const USER_ACTIONS_IMPERSONATE_POST = '/user/actions/impersonate';
export const STUDENTS_ZONE_SHARE_TITLE = '/admin/studentszone/sharetitle';
export const STUDENTS_ZONE_GLOBAL_VALUES = '/admin/studentszone/globalvalues';
export const STUDENTS_ZONE_TOKEN_VALUES = '/admin/studentszone/{token}/values';
export const STUDENTS_ZONE_BLOCK_TOKEN = '/admin/studentszone/{token}/block';
export const CUSTOMER_SUPPORT_USERS = '/admin/customer/users';
export const CUSTOMER_SUPPORT_USER_ID = '/admin/customer/user/{userId}';
export const CUSTOMER_SUPPORT_DELETE_USER = '/admin/customer/user/{userId}';
export const CUSTOMER_SUPPORT_USER_PUT = '/admin/customer/user/{userId}';
export const CUSTOMER_SUPPORT_EMAIL_PUT = '/admin/customer/user/{userId}/email';
export const CUSTOMER_SUPPORT_USER_POST = '/admin/customer/user';
export const CUSTOMER_SUPPORT_TITLES = '/admin/customer/titles';
export const CUSTOMER_SUPPORT_ADOPTION_LANGUAGE = '/admin/customer/adoption-languages';
export const CUSTOMER_SUPPORT_ADOPTION_EDITIONS = '/admin/customer/adoption-editions';
export const CUSTOMER_SUPPORT_LIBRARY = '/admin/customer/user/{userId}/library';
export const CUSTOMER_SUPPORT_LIBRARY_PUT = '/admin/customer/user/{userId}/library';
export const CUSTOMER_SUPPORT_USER_ACTIONS = '/admin/customer/user/{userId}/actions';
export const CUSTOMER_SUPPORT_USER_BLINK = '/admin/customer/user/{userId}/blink';
export const CUSTOMER_SUPPORT_USER_COUNT = '/admin/customer/user/{userRole}/count';
export const CUSTOMER_SUPPORT_SCHOOL_REGIONS = '/admin/customer/school-regions';
export const CUSTOMER_SUPPORT_USERS_COUNT_BY_ROLE = '/admin/customer/users-count-by-role';
export const CUSTOMER_SUPPORT_USER_LICENSES='/admin/customer/user/{userId}/licences'
export const CUSTOMER_SUPPORT_STUDENT_LOG="/admin/studentszone{/token}/activitylog"
export const PRODUCT_ACCESS_CONTROL_SEARCH = "/admin/product-access-control"
export const PRODUCT_ACCESS_CONTROL_OPERATION = '/admin/product-access-control/{productKey}/{operation}'

//Internal
export const INTERNAL_SUPPORT_USERS = '/admin/internal/users';
export const INTERNAL_SUPPORT_USER_ID = '/admin/internal/user/{userId}';
export const INTERNAL_SUPPORT_USER_ID_PUT = '/admin/internal/user/{userId}';
export const INTERNAL_SUPPORT_STATUS = '/admin/internal/user/{userId}/status';
export const INTERNAL_SUPPORT_USER_POST = '/admin/internal/user';
export const INTERNAL_SUPPORT_USER_ACTIONS = '/admin/internal/user/{userId}/actions';

//Invoice Blink
export const INVOICE_INVOICES_PER_YEAR = '/invoice/year/{year}'
export const INVOICE_INVOICES_PER_ID = '/invoice/{id}'
export const INVOICE_CREATE_INVOICE = '/invoice'

//Invoice LaSalle
export const INVOICE_LASALLE_PER_YEAR = '/invoice/lasalle/year/{year}'
export const INVOICE_LASALLE_PER_ID = '/invoice/lasalle/{id}'
export const INVOICE_LASALLE_CREATE_INVOICE = '/invoice/lasalle'