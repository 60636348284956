import React, { Component } from 'react'

const Wrapper = WrappedComponent => {

  return class extends Component {

    state = { paddingBottom: 0 }

    componentDidMount() {

      const footerNode = document.getElementById('lightBoxFooter'),
        footerNodePos = footerNode ? footerNode.getBoundingClientRect().height : 0

      this.setState({ paddingBottom: footerNodePos + 20 })
    }

    render() {

      const { paddingBottom } = this.state

      return (
        <WrappedComponent {...this.props} paddingBottom={paddingBottom} />
      )
    }
  }

}
export default Wrapper