import React from 'react'
import _ from 'lodash'

import { parseToReactElement } from '../../../utils/html'
import BasicModal from './BasicModal'
import { withContent } from '../../../utils/hocs'

const ValidEmailModal = ({ content, isOpen, onClose }) => (
  <BasicModal
    header={parseToReactElement(_.get(content, 'coupons.modal_validemail_header'))}
    body={parseToReactElement(_.get(content, 'coupons.modal_validemail_body'), null, { ADD_ATTR: ['target'] })}
    button={_.get(content, 'coupons.modal_validemail_button')}
    isOpen={isOpen}
    onClose={onClose}
  />
)

export default withContent(ValidEmailModal)
