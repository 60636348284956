import React, { Fragment } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { Container, Row, Col } from 'reactstrap'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

import ButtonCoupons from '../ButtonCoupons/ButtonCoupons'
import CMSContent from '../CMSContent/CMSContent'
import Library from './DigitalLibrary/Library'
import TitlePage from './DigitalLibrary/TitlePage'
import ShoppingCart from './ShoppingCart/ShoppingCart'
import { RecaptchaTerms } from '../Recaptcha/Recaptcha'

import { parseToReactElement } from '../../utils/html'
import { addScrollToTop } from '../../utils/buttons'
import { withContent } from '../../utils/hocs'

import './DigitalLibrary.css'

import axiosConfig from '../../config/axios'

const axiosClient = axios.create({ ...axiosConfig })

class DigitalLibrary extends React.Component {
  constructor() {
    super()
    this.state = {
      disclaimer: true,
      titles: [],
      errors: {
        token: false,
        product: false,
        generic: false
      },
      loading: true,
      newLicenses: false,
      email: '',
      shoppingCart: []
    }
  }

  agreedTerms = async () => {
    const { token, googleReCaptchaProps } = this.props
    const recaptchaToken = await googleReCaptchaProps.executeRecaptcha('coupons')
    axiosClient.get(`/coupons/${token}?token=${recaptchaToken}`)
      .then((response) => {
        this.setState({
          disclaimer: false,
          titles: (response.data && response.data.length > 0 && response.data[0].uuid) ? response.data : [],
          email: response.data && response.data.length ? response.data[0].email : '',
          errors: {}
        })
      })
      .catch((err) => {
        if(_.get(err, 'response.status') === 404) {
          this.setState({ errors: { generic: false, token: true, product: false } })
        } else {
          this.setState({ errors: { generic: true, token: false, product: false } })
        }
      })
  }

  selectTitle = (uuid) => {
    const { titles } = this.state
    const title = titles.find((item) => uuid === item.uuid)
    this.setState({ selectedTitle: title, newLicenses: false })
    window.scrollTo(0, 0)
  }

  getErrorText = () => {
    const { content } = this.props
    const { errors: { generic, token, product } } = this.state

    if(token) {
      return {
        title: _.get(content, 'coupons.digitallibrary_token_not_found_error_title'),
        body: _.get(content, 'coupons.digitallibrary_token_not_found_error_body'),
      }
    }

    if(product) {
      return {
        title: _.get(content, 'coupons.digitallibrary_product_expired_error_title'),
        body: _.get(content, 'coupons.digitallibrary_product_expired_error_body'),
      }
    }

    if(generic) {
      return {
        title: _.get(content, 'coupons.digitallibrary_generic_error_title'),
        body: _.get(content, 'coupons.digitallibrary_generic_error_body')
      }
    }

    return { title: '', body: '' }
  }

  // MBQ COUPONS-PRODUCT-PURCHASE (TODO Check unique products)
  addToShoppingCart = product => {
    this.setState({ shoppingCart: [...this.state.shoppingCart, product] })
    window.scrollTo(0, 0)
  }

  componentDidMount() {
    const { orderId, purchase, token } = this.props

    if (orderId && purchase) {
      this.setState({
        loading: false,
        disclaimer: false,
        shoppingCart: []
      })
    } else {
      axiosClient.get(`/coupons/${token}/payment`)
        .then(response => {
          const { data } = response
          if (data && data.result && data.library) {
            this.setState({
              loading: false,
              disclaimer: false,
              shoppingCart: [],
              titles: (data.library && data.library.length > 0 && data.library[0].uuid) ? data.library : [],
              email: data.library[0].email,
              errors: {},
              newLicenses: data.result === 'OK'
            })
          } else {
            this.setState({ loading: false })
          }
        })
        .catch((err) => {
          if (_.get(err, 'response.status') === 404) {
            this.setState({ errors: { generic: false, token: true, product: false }, loading: false })
          } else {
            this.setState({ errors: { generic: true, token: false, product: false }, loading: false })
          }
        })
    }
  }

  render() {
    const { content, token, orderId, purchase } = this.props
    const {
      disclaimer, selectedTitle, titles, email, province, age,
      marketing, shoppingCart, errors, newLicenses, loading
    } = this.state

    // MBQ COUPONS-PRODUCT-PURCHASE
    if (loading) {
      return null
    }

    if(_.compact(_.values(errors)).length) {
      const { title, body } = this.getErrorText()
      return (
        <Container>
          <Row className="my-3 text-center color-dark-grey"><Col><h1>{title}</h1></Col></Row>
          <Row className="my-3 text-center color-dark-grey"><Col>{parseToReactElement(body, null, { ADD_ATTR: ['target'] })}</Col></Row> 
          <Row className="mt-5 mb-3 text-center">
            <Col md={{ size: 4, offset: 4 }}>
              <ButtonCoupons
                primary
                onClick={() => {
                  this.setState({
                    errors: {
                      title: false,
                      product: false,
                      generic: false,
                      ioproduct: false
                    }
                  })
                }}
              >
                {_.get(content, 'coupons.digitallibrary_back')}
              </ButtonCoupons>
            </Col>
          </Row>
        </Container>        
      )
    }

    if ((shoppingCart && shoppingCart.length > 0) || (orderId && purchase)) {
      return (
        <ShoppingCart
          token={token}
          email={email}
          cart={shoppingCart}
          orderId={orderId}
          purchase={purchase}
          showConfirmation={shoppingCart && shoppingCart.length}
          onError={addScrollToTop(() => this.setState({ shoppingCart: [], errors: { ...errors, generic: true } }))}
          onCancel={addScrollToTop(() => this.setState({ shoppingCart: [] }))}
        />
      )
    }

    if(disclaimer) {
      return (
        <Container>
          <Row className="my-3"><Col><CMSContent pageid="BIBLIODIG_AGREEMENT" /></Col></Row>
          <Row className="my-3">
            <Col md={{ size: 4, offset: 4 }} xl={{ size: 2, offset: 5 }}>
              <ButtonCoupons primary onClick={addScrollToTop(this.agreedTerms)}>{_.get(content, 'coupons.digitallibrary_acceptterms_button', 'Aceptar y continuar')}</ButtonCoupons>
            </Col>
          </Row>
          <Row>
            <RecaptchaTerms className="m-5" />
          </Row>
        </Container>
      )
    }

    if(selectedTitle) {
      return (
        <TitlePage
          token={token}
          title={selectedTitle}
          onProductError={() => this.setState({ errors: { ...errors, product: true } })}
          returnToLibrary={addScrollToTop(() => this.selectTitle(null))}
          addToShoppingCart={this.addToShoppingCart}
        />
      )
    }

    return (
      <Fragment>
        {
          newLicenses && titles.length > 0 && (
            <div className="banner-success">
              <img alt="success" className="success-icon" src="https://static.oupe.es/img/icon_success.png" />
              <span className="success-text">{_.get(content, 'coupons.purchase_banner_newlicenses')}</span>
            </div>
          )
        }
        <Container>
          <Row className="my-3"><Col><h1><span className="title-underlined"><strong>{_.get(content, 'coupons.digitallibrary_title', 'Biblioteca Digital')}</strong></span></h1></Col></Row>
          {
            titles.length > 0 ?
              (
                <Row className="my-3">
                  <Col>
                    <p>{parseToReactElement(_.get(content, 'coupons.digitallibrary_instructions'))}</p>
                  </Col>
                </Row>
              )
              :
              (
                <Row className="my-2 color-dark-grey">
                  <Col>
                    <h2>{_.get(content, 'coupons.digitallibrary_no_titles', 'No tienes ningún libro activo actualmente')}</h2>
                  </Col>
                </Row>
              )
          }
          <Row className="my-3">
            <Col>
              <Library
                titles={titles}
                onSelect={this.selectTitle}
                updateLibrary={this.agreedTerms}
                couponUser={{
                  token,
                  email,
                  province,
                  age,
                  marketing
                }}
              />
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default withGoogleReCaptcha(withContent(DigitalLibrary))