import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { parseToReactElement } from '../../utils/html'
import NewsModal from '../News/NewsModal'
import { AuthConsumer } from 'react-check-auth'
import { ContentConsumer } from '../../language'

class BreakingNewsItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }

  render() {
    const { news } = this.props

    if (!news) return null
    else return (
      <AuthConsumer>
        {({ userInfo, isLoading, error }) => {
          if (isLoading) return []
          const locale = (userInfo && userInfo.language && userInfo.language.replace('_', '-')) || 'es-es'
          return (
            <ContentConsumer>
              {({ content = {} }) => {
                return (
                  <Fragment>
                    <Container className="breaking-news-item" onClick={this.toggle}>
                      <Row>
                        <Col xs="3" md="12" className="d-md-flex d-none p-0">
                          <div className="text-center my-auto embed-responsive embed-responsive-21by9">
                            <img className="p-1 p-md-3 img-fluid embed-responsive-item" src={news.image.url} alt={news.image.alt} />
                          </div>
                        </Col>
                        <Col xs="3" className="d-flex d-md-none p-0">
                          <div className="text-center my-auto embed-responsive embed-responsive-16by9">
                            <img className="p-2 p-md-3 img-fluid embed-responsive-item" src={news.image_medium.url} alt={news.image_medium.alt} />
                          </div>
                        </Col>
                        <Col xs="9" md="12" className="breaking-news-item-title my-2 px-2 px-md-3 d-flex d-md-block">
                          <div className="my-auto">{parseToReactElement(news.title)}</div>
                        </Col>
                      </Row>
                    </Container>
                    <NewsModal isOpen={this.state.modal} hideCategory={true} toggle={this.toggle} news={news} content={content} locale={locale} />
                  </Fragment>
                )
              }
              }
            </ContentConsumer>)
        }}
      </AuthConsumer>
    )
  }
}
export default BreakingNewsItem
