import React, { Component } from 'react'
import { ContentConsumer } from '../../language'
import wsMoveTeacherSchool from '../../services/wsMoveTeacherSchool'
import { Button, Form } from 'reactstrap'
import PropTypes from 'prop-types'

class ChangeSchoolCenterData extends Component {

  // Default props for shallow test
  static defaultProps = {
    data: {},
    idprofesor: '',
    status: {},
    action: () => null
  }

  constructor(props) {
    super(props)
    this.state = {
      idprofesor: this.props.idprofesor,
      code: this.props.code,
      data: this.props.data,
    }
    this.handleSubmitSetCenter = this.handleSubmitSetCenter.bind(this)
    this.handleSubmitFind = this.handleSubmitFind.bind(this)
  }

  handleSubmitFind(e) {
    e.preventDefault()
    this.props.action(null, this.props.status.NO_SEARCH)
  }

  handleSubmitSetCenter(e) {
    e.preventDefault()
    wsMoveTeacherSchool(this.state.idprofesor,
      this.state.data.c_centro,
      this.state.data.t_premium, (err, data) => {
        if (err) {
          console.log(err)
          this.props.action(this.state.data, this.props.status.KO_CHANGESCHOOL)
        } else {
          this.props.action(this.state.data, this.props.status.SUCCESS)
        }
      })
  }

  render() {
    return (<ContentConsumer>
      {({ content = {}, isContentLoading }) => (content && (

        <div className="text-center d-flex flex-column justify-content-center m-5 schoolCenterData">
          <h2 className="m-3">{content.changeschool.changeschool_title}</h2>
          <p>{content.changeschool.changeschool_heading_premium_code_belongs_to_this_school}</p>
          <h6>{this.state.data.d_centro}</h6>
          <h6>{this.state.data.t_nombrevia}&nbsp;{this.state.data.n_numerovia}</h6>
          <h6>{this.state.data.d_poblacion}&nbsp;{this.state.data.d_provincia}</h6>
          <h6>{this.state.data.d_pais}</h6>
          <p className="m-3">{content.changeschool.changeschool_heading_center_confirm_text}</p>
          <div className="d-flex justify-content-center">
            <Form onSubmit={this.handleSubmitSetCenter} onReset={this.handleSubmitFind} inline>
              <Button type="reset" className="mr-sm-2 schoolCenterData__button reset">{content.sharedTerms.BACK_BUTTON_TEXT}</Button>
              <Button type="submit" className="mr-sm-2 schoolCenterData__button">{content.sharedTerms.ACCEPT_BUTTON_TEXT}</Button>
            </Form>
          </div>
        </div>
      ))}
    </ContentConsumer>)
  }
}

ChangeSchoolCenterData.propTypes = {
  status: PropTypes.object.isRequired,
  action: PropTypes.func.isRequired,
  idprofesor: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
}

export default ChangeSchoolCenterData
