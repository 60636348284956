import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import _ from 'lodash'
import cx from 'classnames'

import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, Button } from 'reactstrap'

import axiosConfig from '../../config/axios'

import { withContent } from '../../utils/hocs'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import { Colors } from '../Header/components/Colors'

class ModalSchoolByPostalCode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      postalCode: '',
      countryId: 0,
      countries: [],
      schoolId: 0,
      schools: [],
      errors: {
        noSchoolByPostalCode: false
      }
    }
  }

  reset() {
    this.setState({
      postalCode: '',
      countryId: undefined,
      schoolId: undefined,
      schools: []
    })
  }
  componentDidMount() {
    const axiosClient = axios.create(axiosConfig)
    axiosClient.get('/country')
      .then((result) => this.setState({ countries: result.data }))
      .catch(() => this.props.onClose())
  }

  handleInputChange = (event) => {
    event.preventDefault()
    const name = event.target.name
    const value = event.target.value
    switch(name) {
      case 'postalCode':
        this.setState({ postalCode: value, errors: { ...this.state.errors, noSchoolByPostalCode: false }})
        break
      case 'country':
        this.setState({ countryId: Number(value), schools: [], schoolId: undefined })
        break
      case 'school':
        this.setState({ schoolId: Number(value) })
        break
      default:
        break
    }
  }

  getSchools = (event) => {
    event.preventDefault()
    const axiosClient = axios.create(axiosConfig)
    axiosClient.get(`/schools/${this.state.countryId}/${this.state.postalCode}`)
      .then((result) => this.setState({ schools: result.data, errors: {...this.state.errors, noSchoolByPostalCode: result.data.length === 0 }}))
      .catch(() => this.setState({ schools: [] }))
  }

  submit = (event) => {
    event.preventDefault()
    // eslint-disable-next-line
    const school = this.state.schools.find(school => school.c_centro === this.state.schoolId)
    this.props.onData({
      id: school.c_centro,
      name: school.d_centro,
      country: school.c_pais
    })
    this.reset()
    this.props.onClose()
  }

  render() {
    const { isOpen, className, content } = this.props
    const colors = [ 'red', 'pink', 'yellow', 'green', 'aqua', 'blue' ]

    return (
      <Modal isOpen={isOpen} className={cx("modal-school-modal", className)}>
        <Colors colors={colors} />
        <ModalHeader className="modal__header text-center">{ content.useraccount.school_data }</ModalHeader>
        <ModalBody>
          <Form noValidate onSubmit={e => { e.preventDefault() }}>
            <p className="modal-school-subtitle mb-4">{content.useraccount.school_by_postal_code_subtitle}</p>
            <FormGroup>
              <Label for="country">{content.useraccount.school_data}</Label>
              <Input
                id="country"
                type="select"
                name="country"
                autoComplete="off"
                value={this.state.countryId}
                onChange={this.handleInputChange}>
                <option key={0} value={0}>{content.useraccount.country_choose}</option>
                {
                  _.sortBy(this.state.countries, ['name']).map(country => <option key={country.id} value={country.id}>{country.name}</option>)
                }
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="postalCode">{content.useraccount.postal_code}</Label>
              <InputGroup>
                <Input
                  id="postalCode"
                  type="text"
                  name="postalCode"
                  autoComplete="off"
                  onKeyPress={(e) => { if(e.key === 'Enter') this.getSchools(e) }}
                  disabled={!this.state.countryId}
                  value={this.state.postalCode}
                  onChange={this.handleInputChange}
                  onBlur={this.getSchools}
                />
                <InputGroupAddon addonType="append">
                  <Button onClick={this.getSchools} disabled={(this.state.countryId === 0) || (this.state.postalCode === '')}><span className="glyphicon glyphicon-search"></span></Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Input
                id="school"
                type="select"
                name="school"
                autoComplete="off"
                disabled={(this.state.countryId === 0) || (this.state.schools.length === 0) }
                onChange={this.handleInputChange}
                value={this.state.schoolId}>
                <option key={0} value={0}>{content.useraccount.school_choose}</option>
                {
                  _.sortBy(this.state.schools, ['d_centro']).map(school => <option key={school.c_centro} value={school.c_centro}>{school.d_centro}</option>)
                }
              </Input>
            </FormGroup>
          </Form>
          { this.state.errors.noSchoolByPostalCode && <p className="error">{ content.useraccount.no_school_by_postal_code }</p> }

        </ModalBody>
        <ModalFooter>
          <Container fluid={false}>
            <Row>
              <Col sm={{ size: 4, offset: 1}} className="my-2">
                <ButtonPremium className="button" onClick={this.props.onClose}>
                  { _.get(content, ['sharedTerms', 'CANCEL_BUTTON_TEXT'], 'Cancel') }
                </ButtonPremium>
              </Col>
              <Col sm={{ size: 4, offset: 1}} className="my-2">
                <ButtonPremium className="button" disabled={!this.state.schoolId || this.state.schoolId === '0'} onClick={this.submit} primary>
                  { _.get(content, ['sharedTerms', 'ACCEPT_BUTTON_TEXT'], 'Accept') }
                </ButtonPremium>
              </Col>
            </Row>
          </Container>
        </ModalFooter>

      </Modal>
    )
  }
}

ModalSchoolByPostalCode.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onData: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default withContent(ModalSchoolByPostalCode)
