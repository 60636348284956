// MBQ MAY 2024 POC MARSUPIAL BLINK
import envSettings from '../../globals/envSettings'

export const COUPONS_MARSUPIALBLINK_MOCKS = [
  {
    userId: 666999100,
    coupons: [
      {
        couponId: 666999000,
        products: [
          {
            name: 'Synchronize 1. Active Learning Kit',
            isbn: 9780194088923,
            token: 'OUPQYGUHOGT3A',
            licenseId: 69422080,
            urls: [
              `${envSettings.API_BASEURL}/coupons/666999000/product/9780194088923/license/69422080?user=666999100&token=OUPQYGUHOGT3A&access=coupon`,
              `${envSettings.API_BASEURL}/coupons/666999000/product/9780194088923/license/69422080?user=666999100&token=OUPQYGUHOGT3A&access=license`,
              `${envSettings.API_BASEURL}/coupons/666999000/product/9780194088923/license/69422080?user=666999100&token=OUPQYGUHOGT3A&access=user`
            ]
          },
          {
            name: 'All About Us Now 1',
            isbn: 9780194659314,
            token: 'OUP4D0HIX8MDP',
            licenseId: 69272662,
            urls: [
              `${envSettings.API_BASEURL}/coupons/666999000/product/9780194659314/license/69272662?user=666999100&token=OUP4D0HIX8MDP&access=coupon`,
              `${envSettings.API_BASEURL}/coupons/666999000/product/9780194659314/license/69272662?user=666999100&token=OUP4D0HIX8MDP&access=license`,
              `${envSettings.API_BASEURL}/coupons/666999000/product/9780194659314/license/69272662?user=666999100&token=OUP4D0HIX8MDP&access=user`
            ]
          },
          {
            name: 'Open Up 1',
            isbn: 9780194659253,
            token: 'OUPZUBQFX54A2',
            licenseId: 69272649,
            urls: [
              `${envSettings.API_BASEURL}/coupons/666999000/product/9780194659253/license/69272649?user=666999100&token=OUPZUBQFX54A2&access=coupon`,
              `${envSettings.API_BASEURL}/coupons/666999000/product/9780194659253/license/69272649?user=666999100&token=OUPZUBQFX54A2&access=license`,
              `${envSettings.API_BASEURL}/coupons/666999000/product/9780194659253/license/69272649?user=666999100&token=OUPZUBQFX54A2&access=user`
            ]
          }
        ],
      },
      {
        couponId: 666999001,
        products: [
          {
            name: 'On Track 1',
            isbn: 9780194088787,
            token: 'OUPR0XRGFI397',
            licenseId: 69272632,
            urls: [
              `${envSettings.API_BASEURL}/coupons/666999001/product/9780194088787/license/69272632?user=666999100&token=OUPR0XRGFI397&access=coupon`,
              `${envSettings.API_BASEURL}/coupons/666999001/product/9780194088787/license/69272632?user=666999100&token=OUPR0XRGFI397&access=license`,
              `${envSettings.API_BASEURL}/coupons/666999001/product/9780194088787/license/69272632?user=666999100&token=OUPR0XRGFI397&access=user`
            ]
          }
        ]
      }
    ]
  },
  {
    userId: 666999101,
    coupons: [
      {
        couponId: 666999002,
        products: [
          {
            name: 'Synchronize 1. Active Learning Kit',
            isbn: 9780194088923,
            token: 'OUP2QUKLLJIDF',
            licenseId: 69422081,
            urls: [
              `${envSettings.API_BASEURL}/coupons/666999002/product/9780194088923/license/69422081?user=666999101&token=OUP2QUKLLJIDF&access=coupon`,
              `${envSettings.API_BASEURL}/coupons/666999002/product/9780194088923/license/69422081?user=666999101&token=OUP2QUKLLJIDF&access=license`,
              `${envSettings.API_BASEURL}/coupons/666999002/product/9780194088923/license/69422081?user=666999101&token=OUP2QUKLLJIDF&access=user`
            ]
          },
          {
            name: 'All About Us Now 1',
            isbn: 9780194659314,
            token: 'OUPOP32IO5V3A',
            licenseId: 69272663,
            urls: [
              `${envSettings.API_BASEURL}/coupons/666999002/product/9780194659314/license/69272663?user=666999101&token=OUPOP32IO5V3A&access=coupon`,
              `${envSettings.API_BASEURL}/coupons/666999002/product/9780194659314/license/69272663?user=666999101&token=OUPOP32IO5V3A&access=license`,
              `${envSettings.API_BASEURL}/coupons/666999002/product/9780194659314/license/69272663?user=666999101&token=OUPOP32IO5V3A&access=user`
            ]
          },
          {
            name: 'Open Up 1',
            isbn: 9780194659253,
            token: 'OUPIRB1J0AEGX',
            licenseId: 69272650,
            urls: [
              `${envSettings.API_BASEURL}/coupons/666999002/product/9780194659253/license/69272650?user=666999101&token=OUPIRB1J0AEGX&access=coupon`,
              `${envSettings.API_BASEURL}/coupons/666999002/product/9780194659253/license/69272650?user=666999101&token=OUPIRB1J0AEGX&access=license`,
              `${envSettings.API_BASEURL}/coupons/666999002/product/9780194659253/license/69272650?user=666999101&token=OUPIRB1J0AEGX&access=user`
            ]
          },
          {
            name: 'On Track 1',
            isbn: 9780194088787,
            token: 'OUPUY4Q918GYF',
            licenseId: 69272633,
            urls: [
              `${envSettings.API_BASEURL}/coupons/666999002/product/9780194088787/license/69272633?user=666999101&token=OUPUY4Q918GYF&access=coupon`,
              `${envSettings.API_BASEURL}/coupons/666999002/product/9780194088787/license/69272633?user=666999101&token=OUPUY4Q918GYF&access=license`,
              `${envSettings.API_BASEURL}/coupons/666999002/product/9780194088787/license/69272633?user=666999101&token=OUPUY4Q918GYF&access=user`
            ]
          }
        ],
      }
    ]
  },
  {
    userId: 666999102,
    coupons: [
      {
        couponId: 666999003,
        products: [
          {
            name: 'Synchronize 1. Active Learning Kit',
            isbn: 9780194088923,
            token: 'OUP0XLUFMEGTH',
            licenseId: 69422082,
            urls: [
              `${envSettings.API_BASEURL}/coupons/666999003/product/9780194088923/license/69422082?user=666999102&token=OUP0XLUFMEGTH&access=coupon`,
              `${envSettings.API_BASEURL}/coupons/666999003/product/9780194088923/license/69422082?user=666999102&token=OUP0XLUFMEGTH&access=license`,
              `${envSettings.API_BASEURL}/coupons/666999003/product/9780194088923/license/69422082?user=666999102&token=OUP0XLUFMEGTH&access=user`
            ]
          },
          {
            name: 'All About Us Now 1',
            isbn: 9780194659314,
            token: 'OUP6EPONPSY0O',
            licenseId: 69272664,
            urls: [
              `${envSettings.API_BASEURL}/coupons/666999003/product/9780194659314/license/69272664?user=666999102&token=OUP6EPONPSY0O&access=coupon`,
              `${envSettings.API_BASEURL}/coupons/666999003/product/9780194659314/license/69272664?user=666999102&token=OUP6EPONPSY0O&access=license`,
              `${envSettings.API_BASEURL}/coupons/666999003/product/9780194659314/license/69272664?user=666999102&token=OUP6EPONPSY0O&access=user`
            ]
          },
          {
            name: 'On Track 1',
            isbn: 9780194088787,
            token: 'OUP2FDEM3U4VJ',
            licenseId: 69272634,
            urls: [
              `${envSettings.API_BASEURL}/coupons/666999003/product/9780194088787/license/69272634?user=666999102&token=OUP2FDEM3U4VJ&access=coupon`,
              `${envSettings.API_BASEURL}/coupons/666999003/product/9780194088787/license/69272634?user=666999102&token=OUP2FDEM3U4VJ&access=license`,
              `${envSettings.API_BASEURL}/coupons/666999003/product/9780194088787/license/69272634?user=666999102&token=OUP2FDEM3U4VJ&access=user`
            ]
          }
        ],
      },
      {
        couponId: 666999004,
        products: [
          {
            name: 'Open Up 1',
            isbn: 9780194659253,
            token: 'OUP6SGL2VTTB6',
            licenseId: 69272651,
            urls: [
              `${envSettings.API_BASEURL}/coupons/666999004/product/9780194659253/license/69272651?user=666999102&token=OUP6SGL2VTTB6&access=coupon`,
              `${envSettings.API_BASEURL}/coupons/666999004/product/9780194659253/license/69272651?user=666999102&token=OUP6SGL2VTTB6&access=license`,
              `${envSettings.API_BASEURL}/coupons/666999004/product/9780194659253/license/69272651?user=666999102&token=OUP6SGL2VTTB6&access=user`
            ]
          }
        ]
      }
    ]
  },
  {
    userId: '666999103X',
    coupons: [
      {
        couponId: 666999005,
        products: [
          {
            name: 'Synchronize 1. Active Learning Kit',
            isbn: 9780194088923,
            token: 'OUPKCVN5VANRS',
            licenseId: 69422083,
            urls: [
              `${envSettings.API_BASEURL}/coupons/666999005/product/9780194088923/license/69422083?user=666999103X&token=OUPKCVN5VANRS&access=coupon`,
              `${envSettings.API_BASEURL}/coupons/666999005/product/9780194088923/license/69422083?user=666999103X&token=OUPKCVN5VANRS&access=license`,
              `${envSettings.API_BASEURL}/coupons/666999005/product/9780194088923/license/69422083?user=666999103X&token=OUPKCVN5VANRS&access=user`
            ]
          }
        ]
      }
    ]
  },
  {
    userId: '666999104X',
    coupons: [
      {
        couponId: 666999006,
        products: [
          {
            name: 'Synchronize 1. Active Learning Kit',
            isbn: 9780194088923,
            token: 'OUPMEIY3NOFRV',
            licenseId: 69422084,
            urls: [
              `${envSettings.API_BASEURL}/coupons/666999006/product/9780194088923/license/69422084?user=666999104X&token=OUPMEIY3NOFRV&access=coupon`,
              `${envSettings.API_BASEURL}/coupons/666999006/product/9780194088923/license/69422084?user=666999104X&token=OUPMEIY3NOFRV&access=license`,
              `${envSettings.API_BASEURL}/coupons/666999006/product/9780194088923/license/69422084?user=666999104X&token=OUPMEIY3NOFRV&access=user`
            ]
          }
        ]
      }
    ]
  }
]