import React, { useState } from 'react'
import _ from 'lodash'
import { Container, Row, Col } from 'reactstrap'

import RedemptionModal from '../Modals/RedemptionModals/RedemptionModal'
import ButtonCoupons from '../../ButtonCoupons/ButtonCoupons'
import { withContent } from '../../../utils/hocs'

const AddCouponTitle = ({ content, couponUser, updateLibrary }) => {
  const [isModalOpen, showModal] = useState(false)
  return (
    <>
      <Container className="h-100 rounded-all bg-white p-3 bt-new-coupon" fluid>
        <Row className="h-25 top-label">
          <Col className="text-center w-100 px-5">
            <strong>
              {_.get(content, 'coupons.redeem_another_coupon_top_label')}
            </strong>
          </Col>
        </Row>
        <Row className="h-50 text-top">
          <Col className="d-flex h-100 py-2">
            <img className="m-auto new_coupon" src="https://online.oupe.es/OUP/Biblioteca/Iconos/icono_biblioteca.png" alt={_.get(content, 'coupons.redeem_another_coupon_button', 'Canjea otro código')} />
          </Col>
        </Row>
        <Row className="h-25">
          <Col className="d-flex w-100">
            <ButtonCoupons
              primary
              className="m-auto"
              onClick={() => showModal(true)}
            >
              {_.get(content, 'coupons.redeem_another_coupon_button', 'Valida otro cupón')}
            </ButtonCoupons>
          </Col>
        </Row>
      </Container>
      <RedemptionModal
        isOpen={isModalOpen}
        onClose={() => showModal(false)}
        couponUser={couponUser}
        updateLibrary={updateLibrary}
      />
    </>
  )
}

export default withContent(AddCouponTitle)
