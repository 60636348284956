import React from 'react';
import _ from 'lodash'
import { Button, CardImg, CardText, CardTitle,
    Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem } from 'reactstrap'
import { Colors } from '../Header/components/Colors'
import cx from 'classnames'
import { parseToReactElement } from '../../utils/html'

const NewsModal = props => {

    const { isOpen, hideCategory, toggle, news, content, locale } = props;

    return (
        <Modal isOpen={isOpen} className="newsItem" toggle={toggle}>
            <Colors colors={[ 'pink', 'yellow', 'green', 'blue']} />
            <ModalHeader toggle={toggle}>
            {
              !hideCategory &&
              <ListGroup className="categories">
                { news.categories.map(item => item && <ListGroupItem key={item.id} className={cx("category_full", `category__${item.color}`)}><CardText>{ _.get(content.news, `news_category_${item.slug}`, item.name) }</CardText></ListGroupItem>) }
              </ListGroup>
            }
            { news.image ? <CardImg top className="rounded-0" width="100%" src={news.image.url} alt={news.image.alt} /> : <div className={cx(`noimage_${news.categories.length}`)} /> }
            </ModalHeader>
            <ModalBody>
              <CardTitle>{parseToReactElement(news.title)}</CardTitle>
              { news.date &&  <CardText className="date"><i><span className="glyphicon glyphicon-time" />{news.date && news.date.toLocaleDateString(locale, { year: 'numeric', month: '2-digit', day: '2-digit' })}</i></CardText> }
              { news.content && parseToReactElement(news.content, null, { ADD_ATTR: ['target'] })}
            </ModalBody>
            <ModalFooter>
            <Button color="secondary" onClick={toggle}>{ _.get(content, ['sharedTerms', 'BACK_BUTTON_TEXT'], 'Back') }</Button>
            </ModalFooter>
        </Modal>
    )
}

export default NewsModal
