import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle,
  DropdownMenu, DropdownItem
} from 'reactstrap'
import { Link } from 'react-router-dom'
import SelectLanguage from '../../SelectLanguage/SelectLanguage'
import envSettings from '../../../globals/envSettings'
import MyDelegates from '../../MyDelegates/MyDelegates'
import NotificationBell from '../../ResourceNotifications/NotificationBell'
import ROLES, { PROFILES } from '../../../globals/userRoles'
import platformId from '../../../globals/platforms'
import { getShortLocale } from '../../../language'

const NavList = ({ content, userInfo, toggleResponsableModal, locale, onChangeLanguage }) => (
  <Nav className="ml-auto" navbar>
    {
      // userInfo && userInfo.gpaPermission && (
      // userInfo.positionId ?
      //   (<NavItem>
      //     <NavLink tag={Link} to={'/gratis-adoption'} id={'navlink-gpa'}>{content.header.request_materials_text}</NavLink>
      //   </NavItem>)
      //   :
      //   (<NavItem>
      //     <NavLink href='#a' id={'navlink-gpa'} onClick={toggleResponsableModal}>{content.header.request_materials_text}</NavLink>
      //   </NavItem>)
    // )
    }
    {userInfo && userInfo.pdPermission && <NavItem><NavLink tag={Link} to={'/professional-development'}>{content.assets.assets_link}</NavLink></NavItem> }
    {userInfo && userInfo.pdCourses && <NavItem><NavLink tag={Link} to={'/pd-courses'}>{content.pdcourses.menu}</NavLink></NavItem> }
    {userInfo && (userInfo.countryId === envSettings.COUNTRY_ESP_ID) && <NavItem><MyDelegates /></NavItem>}
    <SelectLanguage type="menu" content={content} locale={userInfo ? getShortLocale(userInfo.language) : locale} onChange={onChangeLanguage} />
    {userInfo && [ROLES.TEACHER, ROLES.TEACHER_DEMO, ROLES.TEACHER_FAKE].includes(userInfo.role) && <NavItem><NavLink tag={Link} to={'/notifications'}><NotificationBell /></NavLink></NavItem>}
    {!userInfo && <NavItem><NavLink tag={Link} to={location => ({ ...location, pathname: '/signin' })} className="btn-transparent ml-4">{content.header.login_text}</NavLink></NavItem>}
    {userInfo && (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle className="headerOUP__dropdown__toggle" nav caret>
          <span className={userInfo.impersonating ? 'user-impersonated' : ''}>
            <span className="glyphicon glyphicon-user"></span>
            {userInfo.name}
          </span>
        </DropdownToggle>
        <DropdownMenu className="headerOUP__dropdown" right>
          {[ROLES.TEACHER, ROLES.TEACHER_DEMO].includes(userInfo.role) &&
            <Fragment>
              {userInfo.platformId===platformId.LDAP &&
              <DropdownItem className="headerOUP__dropdown__item" tag={Link} to={'/change-password'}>
                <span className="glyphicon glyphicon-edit mr-2"></span>
                {content.useraccount.password_change_title}
              </DropdownItem>
              }
              <DropdownItem className="headerOUP__dropdown__item" tag={Link} to={'/edit-account'}>
                <span className="glyphicon glyphicon-edit mr-2"></span>
                {content.header.editAccount_text}
              </DropdownItem>
            </Fragment>
          }
          {[ROLES.TEACHER, ROLES.TEACHER_DEMO, ROLES.USER_INTERNAL].includes(userInfo.role) &&
          <DropdownItem tag={Link} className="headerOUP__dropdown__item" to={'/edit-library'}>
            <span className="glyphicon glyphicon-book mr-2"></span>
            {content.header.editLibrary_text}
          </DropdownItem>
          }
          {[ROLES.TEACHER,ROLES.TEACHER_FAKE].includes(userInfo.role)&&
            <Fragment>
              <DropdownItem className="headerOUP__dropdown__item" tag={Link} to={'/activate-license'}>
              <span className="glyphicon glyphicon-plus mr-2"></span>
              {content.activateLicense.menuLabel1}
              </DropdownItem>
          </Fragment>
          }
          {userInfo.impersonationUser &&
            <Fragment>
              {userInfo.profile === PROFILES.CUSTOMER_USER_PREMIUM && userInfo.impersonating &&
              <DropdownItem tag={Link} className="headerOUP__dropdown__item" to={'/administration'}>
                <Fragment>
                  <span className="glyphicon glyphicon-off mr-2"></span>
                  Impersonation off
                </Fragment>
              </DropdownItem>
              }
            </Fragment>
          }

          {/*
          {userInfo.impersonationUser &&
            <Fragment>
              {userInfo.profile === PROFILES.CUSTOMER_USER_PREMIUM &&
              <DropdownItem className="headerOUP__divider" divider />
              }
              <DropdownItem tag={Link} className="headerOUP__dropdown__item" to={'/administration'}>
                {!userInfo.impersonating ?
                  <Fragment>
                    <span className="glyphicon glyphicon-wrench mr-2"></span>
                    Impersonation on
                    </Fragment>
                  :
                  <Fragment>
                    <span className="glyphicon glyphicon-off mr-2"></span>
                    Impersonation off
                    </Fragment>
                }
              </DropdownItem>
            </Fragment>
          }*/}
          {(userInfo.profile === PROFILES.CUSTOMER_USER_PREMIUM || userInfo.impersonating) &&
          <DropdownItem className="headerOUP__divider" divider />
          }
          <DropdownItem className="headerOUP__dropdown__item" href={envSettings.LOGOUT_URL} >
            <span className="glyphicon glyphicon-remove mr-2"></span>
            {content.header.logout_text}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )}
  </Nav>
)

NavList.propTypes = {
  userInfo: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  content: PropTypes.object,
  toggleResponsableModal: PropTypes.func,
  locale: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  onChangeLanguage: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool
  ])
}

export default NavList
