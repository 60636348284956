import React, { Component, Fragment } from 'react'
import { Progress, Alert, Container, Row, Col, Label, Input, Form, FormGroup, FormFeedback } from 'reactstrap'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import axios from 'axios'
import axiosConfig from '../../config/axios'
import { interpolate, PLACEHOLDERS } from '../../language'
import { parseToReactElement } from '../../utils/html'

class GpaResponsableModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isResponsable: false,
      data: null,
      isLoading: true,
      position: 'noposition',
      error: null,
      errorPosition: false,
      errorResponsable: false
    }
  }

  componentDidMount() {
    this.getPositions()
  }

  componentWillUnmount() {
    document.body.style.paddingRight = null
  }

  getPositions = () => {
    axios.create(axiosConfig).get('/gratis-adoption/positions')
      .then(response => {
        //console.log('WWWW POSITONS', response.data)
        this.setState({
          data: response.data.positions,
          isLoading: false,
        })
      })
      .catch(error => {
        console.error('error : ', error)
        this.setState({
          error,
          isLoading: false,
        })
      })
  }

  goToGpa = async () => {
    await this.props.refreshAuth()
    this.props.history.push("/gratis-adoption")
  }

  handleSubmit = e => {
    e.preventDefault()
    const validPosition = this.validatePosition(this.state.position)
    const validaResponsable = this.validateResponsable(this.state.isResponsable)
    if (!validPosition || !validaResponsable) {
      this.setState({
        errorPosition: !validPosition,
        errorResponsable: !validaResponsable
      })
    } else {
      axios.create(axiosConfig).put('/gratis-adoption/responsable', { position: this.state.position })
        .then(response => {
          //console.log('update gpa reponsable', response)
          this.props.toggleModal(this.goToGpa)
        })
        .catch(error => {
          console.error('update gpa reponsable :', error)
          this.setState({
            error,
            data: null
          })
        })
    }
  }

  validatePosition = position => position !== 'noposition'
  validateResponsable = responsable => responsable === true

  handleSelectChange = e => (
    this.setState({
      position: e.target.value,
      errorPosition: !this.validatePosition(e.target.value)
    })
  )

  handleCheckChange = () => (
    this.setState(prevState => (
      {
        isResponsable: !prevState.isResponsable,
        errorResponsable: !this.validateResponsable(!prevState.isResponsable)
      }
    ))
  )

  render() {
    const { userInfo, content, toggleModal } = this.props
    const { isResponsable, data, isLoading, position, error, errorPosition, errorResponsable } = this.state
    return (
      <Container fluid className="container-gpa-responsablemodal">
        {isLoading && <Row><Col><Progress animated color="primary" value="100">{content.sharedTerms.LOADING_MESSAGE}</Progress></Col></Row>}
        {error && <Fragment>
          <Row><Col><Alert color="danger">{content.gratisadoption.gxa_50}</Alert></Col></Row>
          <Row><Col sm={{ size: 8, offset: 2 }} className="mt-4"><ButtonPremium onClick={toggleModal}>{content.sharedTerms.CANCEL_BUTTON_TEXT}</ButtonPremium></Col></Row>
        </Fragment>}
        {data &&
          <Fragment>
            <Row>
              <Col sm="12" className="mb-3 mt-3">
                <Form>
                  <FormGroup>
                    <Label className='label-position' for='select-position'>{content.gratisadoption.gxa_8}</Label>
                    <div className='select'>
                      <Input type="select" name="select" id='select-position' value={position} onChange={this.handleSelectChange} invalid={errorPosition} title={content.gratisadoption.gxa_9}>
                        <option key={'noposition'} value={'noposition'}>{content.gratisadoption.gxa_9}</option>
                        {
                          data.map(item => (
                            <option key={`${item.idCargo}##${item.desCargo}`} value={`${item.idCargo}##${item.desCargo}`}>{item.desCargo}</option>
                          ))
                        }
                      </Input>
                    </div>
                    {
                      errorPosition ?
                        <div className='error-position error-on'>{content.gratisadoption.gxa_51}</div>
                        :
                        <div className='error-position error-off'>{content.gratisadoption.gxa_51}</div>
                    }
                  </FormGroup>

                  <FormGroup>
                    <Label className='check-responsable'>
                      <Input type="checkbox" id='check-responsable' checked={isResponsable} onChange={this.handleCheckChange} invalid={errorResponsable} />
                      {parseToReactElement(interpolate(content.gratisadoption.gxa_10, PLACEHOLDERS.SCHOOL, userInfo.schoolName.toUpperCase()), null, { ADD_ATTR: ['target'] })}
                      <FormFeedback valid={false}>{content.gratisadoption.gxa_52}</FormFeedback>
                    </Label>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col sm="6" className="mt-4">
                <ButtonPremium onClick={toggleModal}>{content.sharedTerms.CANCEL_BUTTON_TEXT}</ButtonPremium>
              </Col>
              <Col sm="6" className="mt-4">
                <ButtonPremium onClick={this.handleSubmit} primary>{content.sharedTerms.START_BUTTON_TEXT}</ButtonPremium>
              </Col>
            </Row>
          </Fragment>
        }

      </Container>
    )
  }
}

export default GpaResponsableModal