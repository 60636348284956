import { isPreProEnv, isProductionEnv } from '../../globals/envSettings'

const devSettings = {
  BASEURL: 'https://help.oupe.es/wp-json/wp/v2/',
  MAX_ITEMS: 50,
  TIMEOUT: 5000,
  CATEGORY_ROOT: 737,
  LANGUAGES_ROOT: 310
}

const preproSettings = {
  BASEURL: 'https://help.oupe.es/wp-json/wp/v2/',
  MAX_ITEMS: 50,
  TIMEOUT: 5000,
  CATEGORY_ROOT: 736,
  LANGUAGES_ROOT: 310
}

const proSettings = {
  BASEURL: 'https://help.oupe.es/wp-json/wp/v2/',
  MAX_ITEMS: 50,
  TIMEOUT: 5000,
  CATEGORY_ROOT: 735,
  LANGUAGES_ROOT: 310
}

let settings = devSettings

if(isProductionEnv()) settings = proSettings
else if(isPreProEnv()) settings = preproSettings

Object.assign(settings, {
  LANGUAGES: {
    es: 311,
    en: 312,
    it: 314
  }
})

export const ClientSettings = {
  baseURL: settings.BASEURL,
  timeout: settings.TIMEOUT,
  crossdomain: true,
  withCredentials: false,
  responseType: 'json',
  responseEncoding: 'utf8'
}

export default settings
