import React, { useEffect, useState } from 'react'
import axios from 'axios'
import axiosConfig from '../../config/axios'

const getFeatureFlags = () => new Promise((resolve) => {
  const axiosClient = axios.create(axiosConfig)
  axiosClient.get('/features')
    .then(({ data }) => resolve(data))
    .catch(() => resolve({}))
})

export const FeatureContext = React.createContext()
export const useFeature = () => React.useContext(FeatureContext)

export const FeatureProvider = ({ children }) => {
  const [state, setState] = useState({})
  const [modalnotification, setModalnotification] = useState(null)
  const [modalpromotion, setModalpromotion] = useState(null)
  const [modallibrary, setModallibrary] = useState(null)
  const [modalPostloginEnabled, setModalPostloginEnabled] = useState(null)
  const wpUnavailable = () => setState({ ...state, carousel: false })
  const disableCarousel = () => setState({ ...state, carousel: false })

  const setModalFlag = (flag, value) => {
    switch (flag) {
      case 'notification':
        setModalnotification(value)
        break
      case 'promotion':
        setModalpromotion(value)
        break
      case 'library':
        setModallibrary(value)
        break
      default:
        console.log('Invalid flag')
        break      
    }
  }

  const checkModalsOff = () => {
    if (
      modalPostloginEnabled === true &&
      modalnotification === false &&
      modalpromotion === false &&
      modallibrary === false
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    getFeatureFlags()
      .then((featureFlags) => {
        setState(featureFlags)
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    if(modalPostloginEnabled === null) {
      if(modalnotification === true || modalpromotion === true || modallibrary === true) {
        setModalPostloginEnabled(false)
      } else {
        if(modalnotification === false && modalpromotion === false && modallibrary === false) {
          setModalPostloginEnabled(true)        
        }
      }
    }
  }, [modalnotification, modalpromotion, modallibrary, modalPostloginEnabled])


  return (
    <FeatureContext.Provider
      value={{
        ...state,
        modalnotification,
        modalpromotion,
        modallibrary,
        modalPostloginEnabled,
        disableCarousel,
        wpUnavailable,
        setModalFlag,
        checkModalsOff
      }}
    >
      {children}
    </FeatureContext.Provider>
  )
}