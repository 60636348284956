import { isPreProEnv, isProductionEnv } from '../../globals/envSettings'

let settings = {
  NEWS_BASEURL : 'https://help.oupe.es/wp-json/wp/v2/',
  NEWS_MAX_ITEMS : 50,
  NEWS_TIMEOUT: 5000,
  NEWS_CATEGORY_ROOT: 156,
  NEWS_AREAS_ROOT: 179,
  NEWS_COUNTRIES_ROOT: 178,
  NEWS_LANGUAGES_ROOT: 310,
  NEWS_STAGES_ROOT: 165,
  NEWS_BREAKINGNEWS_ROOT: 441
}

const preproSettings = {
  NEWS_BREAKINGNEWS_ROOT: 440
}

const proSettings = {
  NEWS_BREAKINGNEWS_ROOT: 428
}

if(isProductionEnv()) Object.assign(settings, proSettings)
else if(isPreProEnv()) Object.assign(settings, preproSettings)

export const ClientSettings = {
  baseURL: settings.NEWS_BASEURL,
  timeout: settings.NEWS_TIMEOUT,
  crossdomain: true,
  withCredentials: false,
  responseType: 'json',
  responseEncoding: 'utf8'
}

export default settings
