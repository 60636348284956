import _ from 'lodash'

export const characterLength =  /^[A-ZÑÁÀÂÄÃÅÆÇÉÈÊËÍÌÏÎÓÒÔÖÕŒÚÙÛÜŸÝ \-'.ºª·]{2,45}$/i;

export const emailRegex = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

//export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])\S{6,}$/;

export const nFormatter = (num, digits=0) => {
  const si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "K" },
    { value: 1E6, symbol: "M" }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for(i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) break
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol
}

export function formatDate(date, format) {
  if(!date)return null
  var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    switch (format) {
        case "DD/MM/YYYY":
            return [day, month, year].join('/');
        case "YYYY/MM/DD":
            return [year, month, day].join('/');
        case "YYYY-MM-DD":
            return [year, month, day].join('-');
        default:
            return [day, month, year].join('/');
    }
}

export function formatTime(date) {
    var d = new Date(date),
        hour = d.getHours(),
        minutes = d.getMinutes()

    minutes = d.getMinutes().toString().length === 1 ? "0" + d.getMinutes().toString() : d.getMinutes()

    return [hour, minutes].join(':');
}

export function formatDateTime(dateTime) {
    if(!dateTime)return null
    let [date] = dateTime.split(" ");
    let [day, month, year] = date.split("/");
    return [year, month, day].join('/');
}

export function getStatusMessage(status, lang = 'en') {
    let data = {
        200: lang === 'en' ? 'Operation successful' : 'Operación exitosa',
        400: lang === 'en' ? 'Bad Request' : 'Error: Petición incorrecta',
        401: lang === 'en' ? 'Unauthorized' : 'Error: No autorizado',
        403: lang === 'en' ? 'Forbidden Error' : 'Error: Prohibido',
        404: lang === 'en' ? 'No data found' : 'Error: Datos no encontrados',
        408: lang === 'en' ? 'Timeout exceeded' : 'Error: Tiempo de espera excedido',
        500: lang === 'en' ? 'An error has occurred. Please try again.' : 'Se ha producido un error. Inténtalo de nuevo.'
    }
    return data[status];
}

export const round2Decimals = value => (
    _.round(Number(value + ''), 2).toFixed(2)
)

export const formatAmount = value => (
    round2Decimals(value).replace('.', ',')
)

export const truncDecimals = value => (
    Math.trunc(value)
)