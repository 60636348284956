import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { estado_pendiente, getDefaultCover, DEFAULT_COVER } from '../../utils/gpa-utils'

const GpaOrderCardDetail = ({ data, stateData, content }) => (
  <div className="row">
    <div className="col-sm-12 col-md-5">
      {data.portadaTitulo ?
        <img src={data.portadaTitulo} title={data.desTitulo} alt={data.desTitulo} className='img-responsive image-recurso' onError={getDefaultCover} />
        :
        <img src={DEFAULT_COVER} title={data.desTitulo} alt={data.desTitulo} className='img-responsive image-recurso' />
      }
    </div>
    <div className="col-sm-12 col-md-7">
      <h4>{data.desTitulo}</h4>
      <p className="course">{`${data.desAsignatura} - ${data.desNivel}`}</p>
      {
        (data.estado.idEstado === estado_pendiente) ?
          (<p className="state mb-4 invisible"></p>)
          :
          (<p className="state mb-4">
            <span className={stateData.icon}>&nbsp;</span>
            <span className='help-state' href='#' id={`state-${data.idSolicitud}`}>
              {content.gratisadoption[stateData.label]}
            </span>
            <UncontrolledTooltip placement='top' target={`state-${data.idSolicitud}`} className='gpa-tooltip'>
              {content.gratisadoption[stateData.tooltip]}
            </UncontrolledTooltip>
          </p>)
      }
      {data.idProfesor && data.desProfesor && (<div><h6>{content.gratisadoption.gxa_23}</h6><p className="description">{data.desProfesor.toUpperCase()}</p></div>)}
    </div>
  </div>
)

export default GpaOrderCardDetail