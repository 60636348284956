import React, { Fragment } from 'react'
import { Row, Col, Table } from 'reactstrap'
import { PRODUCT_ACCESS_CONTROL_OPERATIONS } from '../constants'

const ProductAccessLog = ({ item, log }) => (
  <Fragment>
    <Row
      className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray"
    >
      <Col xs='12'>
        <ul>
          <li>
            <h5>
              <font className="text-primary font-weight-bold mb-0">
                Biblioteca Digital:
              </font>
              <span>&nbsp;{item.token}</span>
            </h5>
          </li>
          <li>
            <h5>
              <font className="text-primary font-weight-bold mb-0">
                Correo electrónico:
              </font>
              <span>&nbsp;{item.email}</span>
            </h5>
          </li>
          <li>
            <h5>
              <font className="text-primary font-weight-bold mb-0">
                Cupón:
              </font>
              <span>&nbsp;{item.coupon}</span>
            </h5>
          </li>
          <li>
            <h5>
              <font className="text-primary font-weight-bold mb-0">
                Producto:
              </font>
              <span>&nbsp;{item.name}</span>
            </h5>
          </li>
          <li>
            <h5>
              <font className="text-primary font-weight-bold mb-0">
                Licencia:
              </font>
              <span>&nbsp;{item.license}</span>
            </h5>
          </li>
          <li>
            <h5>
              <font className="text-primary font-weight-bold mb-0">
                Nº dispositivos:
              </font>
              <span>&nbsp;{item.registeredDevices}</span>
            </h5>
          </li>
        </ul>
      </Col>
    </Row>
    <Row className="gray small">
      <Col xs="12">
        <Table size="sm" responsive={true}>
          <thead>
            <tr bgcolor="#5393d4">
              <th colSpan="5" className="text-center align-middle">
                <h5>LOG DE ACCIONES</h5>
              </th>
            </tr>
            <tr bgcolor="#5393d4">
              <th className="align-middle">Administrador</th>
              <th className="align-middle">Acción</th>
              <th className="align-middle">Fecha</th>
              <th className="align-middle">Resultado</th>
              <th className="align-middle">Nº dispositivos reseteados</th>
            </tr>
          </thead>
          <tbody>
            {log.map((row, index) => (
              <tr key={index} className={row.result !== "OK" ? "error-log" : ""}>
                <td className="align-middle">{row.administrator}</td>
                <td className="align-middle">{row.action}</td>
                <td className="align-middle">{row.dateTxt}</td>
                <td className="align-middle">{row.result}
                  {row.result !== "OK" && row.message !== null && row.message.length > 0 && (
                    <span
                      title={row.message}
                      className="glyphicon glyphicon-eye-open btn-sm"
                      aria-hidden="true"
                    />
                  )}
                </td>
                <td className="align-middle">
                  {row.action === PRODUCT_ACCESS_CONTROL_OPERATIONS.RESET.toUpperCase() ?
                    row.devices : 'N/A'
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  </Fragment>
)

export default ProductAccessLog