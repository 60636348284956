import React from 'react'
import { Card,CardImg } from 'reactstrap';
import { Col } from 'reactstrap'
import envSettings from '../../globals/envSettings'
import PropTypes from 'prop-types'
import demo from '../../assets/images/demo.png'

export const DrawBook = (props) => {

  const { title, elementIsSelected, selectedLibrary, refreshLibrary, library, origin, isHovered, hoveredNoderef, handleHover } = props

  return (
    <Col className="editLibrary__col" xl="1" lg="2" md="3" sm="4" xs="6">
      <Card onClick={()=>refreshLibrary(title, library, selectedLibrary, origin)}
            className={`mb-4 editLibrary__col ${(elementIsSelected(title.nodeRef, selectedLibrary) || (!elementIsSelected(title.nodeRef, selectedLibrary) && isHovered && (hoveredNoderef === title.nodeRef))) ? 'editLibrary__border' :''}`}
            onMouseEnter={()=>handleHover(title.nodeRef, 'NOTLIBRARY')} onMouseLeave={()=>handleHover('', 'NOTLIBRARY')}
            >
        <CardImg
          top
          height="100%"
          style={ elementIsSelected(title.nodeRef, selectedLibrary) || (!elementIsSelected(title.nodeRef, selectedLibrary) && isHovered && (hoveredNoderef === title.nodeRef)) ? { opacity:0.2} : {opacity : 1} }
          alt={title.name}
          src={`${envSettings.API_BASEURL}${title.coverURL}`}
        />
        {title.adoption === 0 &&
          <img className="position-absolute myLibrary__thumbnail" src={demo} alt="Oxford Premium" />
        }
        {(elementIsSelected(title.nodeRef, selectedLibrary) || (!elementIsSelected(title.nodeRef, selectedLibrary) && isHovered && (hoveredNoderef === title.nodeRef))) &&
          <div onClick={()=>refreshLibrary(title, library, selectedLibrary, origin)}  className="editLibrary__check w-100 h-100 position-absolute d-flex align-items-center justify-content-center"
          >
            <span className="glyphicon glyphicon-ok rounded"  title={title.name}> </span>
          </div>
        }
      </Card>
  </Col>
  )
 }

 DrawBook.propTypes = {
  content: PropTypes.object
}
