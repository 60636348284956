import envSettings, { isPreProEnv, isProductionEnv } from '../../globals/envSettings'
import axiosConfig from '../../config/axios'

// MBQ TODO REVIEW MAX_ITEMS
const PROMOTION_TITLE_WP_SETTINGS = {
  MAX_ITEMS: 100, // 50??
  LANGUAGES_ROOT: 310,
  BANNER_CATEGORY: 657
}

// MBQ TODO REVIEW BANNER_CATEGORY
const PROMOTION_TITLE_WP_SETTINGS_PRE = {
  BANNER_CATEGORY: 680
}

// MBQ TODO REVIEW BANNER_CATEGORY
const PROMOTION_TITLE_WP_SETTINGS_PRO = {
  BANNER_CATEGORY: 683
}

const getPromotionTitleSettings = () => (
  isPreProEnv() ?
    { ...PROMOTION_TITLE_WP_SETTINGS, ...PROMOTION_TITLE_WP_SETTINGS_PRE }
    :
    isProductionEnv() ?
      { ...PROMOTION_TITLE_WP_SETTINGS, ...PROMOTION_TITLE_WP_SETTINGS_PRO }
      :
      PROMOTION_TITLE_WP_SETTINGS
)


export const ClientSettings = {
  ...axiosConfig,
  baseURL: envSettings.WORDPRESS_API_BASEURL,
  withCredentials: false
}

export const PROMOTION_TYPE = {
  AD: 'ad',
  DM: 'dm'
}

export default getPromotionTitleSettings()