import React, { Fragment } from 'react'
import { Table } from 'reactstrap'
import Lightbox from 'lightbox-react'

import envSettings from '../../../globals/envSettings'
import InstallerItem from './InstallerItem'

import Footer from '../../ResourceList/components/Footer'

import Audio from '../../Resources/components/Audio'
import Video from '../../Resources/components/Video'
import PDFViewer from '../../Resources/components/PDFViewer'
import NoViewer from '../../Resources/components/NoViewer'

class Offline extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showLightBox: false,
      lightBoxData: {
        component: null,
        showZoom: false,
        data: null
      }
    }
  }

  handleLightBox = uuid => {
    if (!uuid) this.setState({ showLightBox: false })
    else {

      const { installers } = this.props,
        data = installers.find(data => data.uuid === uuid )

      this.setState({ showLightBox: true })

      const audioPlayer = /mpeg|x-wav/.test(data.mimetype),
        videoPlayer = /mp4/.test(data.mimetype),
        isBlank = !data.previewURL || /^\s*$/.test(data.previewURL),
        isImage = /jpg|jpeg|png/.test(data.mimetype)

      if (!isBlank && !audioPlayer && !videoPlayer && !isImage) this.updateLightBoxState(PDFViewer, data)
      else if (audioPlayer) this.updateLightBoxState(Audio, data)
      else if (videoPlayer) this.updateLightBoxState(Video, data)
      else if (isImage) this.updateLightBoxState(data.previewURL, data)
      else this.updateLightBoxState(NoViewer, data)
    }
  }

  updateLightBoxState = (Comp, data) => {
    const newlightBoxData = {
      component: Comp === data.previewURL ? `${envSettings.API_BASEURL}${data.previewURL}` : <Comp data={data} />,
      showZoom: Comp === data.previewURL ? true : false,
      data
    }
    this.setState({ lightBoxData: newlightBoxData })
  }

  render() {
    const { installers } = this.props
    const { showLightBox, lightBoxData, lightBoxData: { component, showZoom } } = this.state
    const Header = ({ data: { name } }) => <span className="resourceList__LightBox__Name text-truncate">{name}</span>

    return (
      <Fragment>
        {(showLightBox && component) &&
          <Lightbox
            mainSrc={component}
            onCloseRequest={() => this.handleLightBox(null)}
            imageTitle={<Header data={lightBoxData.data} />}
            imageCaption={<Footer data={lightBoxData.data} />}
            discourageDownloads={true}
            enableZoom={showZoom}
          />
        }
        <div className="installers px-4">
          <Table borderless>
            <tbody>
              {
                installers.map((installer) => (
                <InstallerItem
                  key={ installer.uuid }
                  installer={ installer }
                  handleLightBox={ this.handleLightBox }
                />
                ))
              }
            </tbody>
          </Table>
        </div>
      </Fragment>
    )
  }
}

export default Offline
