// MBQ COUPONS-PRODUCT-PURCHASE
import React, { Component } from 'react'
import OrderConfirmation from './OrderConfirmation'
import PurchaseResult from './PurchaseResult'
import validator from 'validator'
import axios from 'axios'
import axiosConfig from '../../../config/axios'
import './ShoppingCart.css'

const axiosClient = axios.create({ ...axiosConfig, timeout: 60000 }) // TODO TIMEOUT ????

class ShoppingCart extends Component {
  constructor() {
    super()
    this.state = {
      order: {
        id: null,
        email: '',
        totalAmount: null,
        products: []
      },
      isValidEmail: true
    }
  }

  componentDidMount() {
    const { token, orderId, email, cart, purchase } = this.props

    if (orderId && purchase) {
      if (purchase === 'ok') {
        axiosClient.get(`/coupons/${token}/payment/${orderId}`)
          .then(response => {
            if (response.data) {
              const { data: { email } } = response
              this.setState({
                order: { ...this.state.order, email }
              })
            }
          })
      }
    } else {
      const totalAmount = cart.reduce((acc, product) => {
        acc = acc + product.totalAmount
        return acc
      }, 0)

      this.setState({
        order: {
          ...this.state.order,
          email,
          products: cart,
          totalAmount
        }
      })
    }
  }

  isValidEmail = () => {
    const { order: { email }, isValidEmail } = this.state
    return !!email && isValidEmail
  }

  changeEmail = event => {
    const { target: { value: email } } = event
    this.setState({
      order: {
        ...this.state.order,
        email: email.trim().toLowerCase()
      },
      isValidEmail: validator.isEmail(email)
    })
  }

  pay = () => (
    axiosClient.post(`/coupons/${this.props.token}/payment`, { order: this.state.order })
      .then(response => {
        const { data } = response

        if (data) {
          const formPayment = document.createElement('form')
          formPayment.setAttribute('action', data.paymentUrl)
          formPayment.setAttribute('method', 'POST')
          formPayment.setAttribute('style', "display:none")
          for (let k in data.paymentParameters) {
            let field = document.createElement('input')
            field.setAttribute('type', 'hidden')
            field.setAttribute('name', k)
            field.setAttribute('value', data.paymentParameters[k])
            formPayment.appendChild(field)
          }
          document.body.appendChild(formPayment)
          formPayment.submit()
        } else {
          console.error('PAYMENT KO : NO DATA')
          this.props.onError()
        }
      })
      .catch(error => {
        console.error('PAYMENT KO : ', error.toString())
        this.props.onError()
      })
  )

  returnToLibrary = () => {
    window.location.href = `/digital?token=${this.props.token}`
    window.scrollTo(0, 0)
  }

  render() {
    const { orderId, purchase, showConfirmation, onCancel } = this.props
    const { order, isValidEmail } = this.state

    if (showConfirmation) {
      return (
        <OrderConfirmation
          order={order}
          onCancel={onCancel}
          onSubmit={this.pay}
          onChangeEmail={this.changeEmail}
          isValidEmail={isValidEmail}
        />
      )
    }
    return (
      <PurchaseResult
        orderId={orderId}
        email={order.email}
        purchase={purchase}
        onReturn={this.returnToLibrary}
      />
    )
  }
}

export default ShoppingCart
