import React, { Component } from 'react';
import { AuthConsumer } from 'react-check-auth'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { RecaptchaTerms } from '../Recaptcha/Recaptcha'
import { ContentConsumer, interpolate, PLACEHOLDERS } from '../../language'
import wsCheckPremiumCode from '../../services/wsCheckPremiumCode'
import envSettings from '../../globals/envSettings'
import { parseToReactElement } from '../../utils/html'
import PropTypes from 'prop-types'
import validator from 'validator'
import { Form, FormGroup, Label, Input } from 'reactstrap';
import ButtonPremium from '../ButtonPremium/ButtonPremium'

class ChangeSchoolFinder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      code: '',
      status: props.status.NO_SEARCH,
      data: null,
      schoolName: props.schoolName,
      schoolAddress: props.schoolAddress,
      schoolProvince: props.schoolProvince
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmitGetCenter = this.handleSubmitGetCenter.bind(this)
  }

  handleChange(e) {
    const entrada = validator.whitelist(e.target.value.trim().toUpperCase(), '0-9A-Z')
    this.setState({ code: entrada })
  }

  async handleSubmitGetCenter(e) {
    e.preventDefault()
    const code = this.state.code
    if (code) {
      const token = await this.props.googleReCaptchaProps.executeRecaptcha('change_school')
      wsCheckPremiumCode(code, token, (err, data) => {
        if (data)
          this.props.action(data, this.props.status.OK_LOCATE)
        else {
          this.props.action(null, this.props.status.KO_LOCATE)
        }
      })
    }
  }

  render() {
    return (
      <AuthConsumer>
        {({ userInfo }) => (
          <ContentConsumer>
            {({ content = {}, isContentLoading }) => (content && (
              <div className="text-center d-flex flex-column justify-content-center m-5 schoolFinder">
                <h2 className="m-3">{content.changeschool.changeschool_title}</h2>
                <p>{content.changeschool.changeschool_heading_current_text}</p>
                <h6>{`${this.state.schoolName} (${this.state.schoolAddress} - ${this.state.schoolProvince})`}</h6>
                <div className="m-3">{parseToReactElement(interpolate(content.changeschool.changeschool_heading_new_text, PLACEHOLDERS.LINK_URL, envSettings.CONTACT_URL), null, { ADD_ATTR: ['target'] })}</div>
                <div className="d-flex justify-content-center" >
                  <Form onSubmit={this.handleSubmitGetCenter} inline>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                      <Label for="" className="mr-sm-2 schoolFinder__label">{content.changeschool.changeschool_label_premiumcode_text}</Label>
                      <Input className="schoolFinder__input text-center" onChange={this.handleChange} type="text" name="new-code" id="new-code" value={this.state.code} placeholder="" autoComplete="off" />
                    </FormGroup>
                    <div className="mr-sm-2 mx-auto"><ButtonPremium type="submit" primary>{content.sharedTerms.VALIDATE_BUTTON_TEXT}</ButtonPremium></div>
                  </Form>
                </div>
                <div className="mt-3"><RecaptchaTerms /></div>
              </div>
            ))}
          </ContentConsumer>
        )}
      </AuthConsumer>
    )
  }
}

ChangeSchoolFinder.propTypes = {
  status: PropTypes.object.isRequired,
  action: PropTypes.func.isRequired,
  schoolName: PropTypes.string,
  schoolAddress: PropTypes.string,
  schoolProvince: PropTypes.string
}

export default withGoogleReCaptcha(ChangeSchoolFinder)
