import React from 'react';
import { Row, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

const Tabs = (props) => {

  const {
    currentView,
    content: {
      titledetail: {
        teaching_materials,
        other_multimedia
      }
    },
    hasResources,
    hasPlusPremium,
    students
  } = props

  return (
    <Row className="border-bottom mb-4">
      <Nav className="resources__tab" tabs>
        { hasResources
          && (
            <NavItem className="resources__tab__item">
              <NavLink
                className={`${currentView === 0 ? 'active' : ''}`}
                tag={Link}
                to={{ search: '?initTab=0' }}
              >
                {students?props.content.student.teaching_materials:teaching_materials}
              </NavLink>
            </NavItem>
          )
        }
        {
          hasPlusPremium
            && (
              <NavItem className="resources__tab__item">
                <NavLink
                  className={`${currentView === 1 ? 'active' : ''}`}
                  tag={Link}
                  to={{ search: '?initTab=1' }}
                >
                  <span>{other_multimedia}</span>
                </NavLink>
              </NavItem>
            )
        }
      </Nav>
    </Row>
  )
}

Tabs.propTypes = {
  currentView: PropTypes.number.isRequired,
  content: PropTypes.object.isRequired,
  hasResources: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ]),
  hasPlusPremium: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ]),
  students: PropTypes.bool
}

Tabs.defaultProps = {
  hasResources: true,
  hasPlusPremium: true,
  students: false
}

export default Tabs;
