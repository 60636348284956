import React from 'react'
import { Button } from 'reactstrap'
import { withUserInfo } from '../../../utils/hocs'
import { withRouter } from 'react-router-dom'

const InvoicePanel = (props) => {
  return (
    <div className="invoice-panel d-flex">
      <div className="invoice-panel-button-container p-5 m-auto">
        <Button className="invoice-panel-button font-weight-normal mr-5" color="secondary" size="md" onClick={() => props.history.push('/admin/blink')}>Blink Invoices</Button>
        <Button className="invoice-panel-button font-weight-normal ml-5" color="secondary" size="md" onClick={() => props.history.push('/admin/lasalle')}>La Salle Invoices</Button>
      </div>
    </div>
  )
}

export default withUserInfo(withRouter(InvoicePanel))
