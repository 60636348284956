import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import { NavLink, Link } from 'react-router-dom';
import { Jumbotron, Row, Col } from 'reactstrap';

import envSettings from '../../globals/envSettings'
import cx from 'classnames'

class TitleJumbotron extends Component {

  state = { scroll: 0 }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    const el = document.getElementById(this.props.id);
    const elPos = el ? el.getBoundingClientRect().bottom : Math.pow(10, 1000)

    this.setState({ hidePoint: elPos })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => this.setState({ scroll: window.pageYOffset });

  render() {
    const { scroll, hidePoint } = this.state
    const fixedPoint = scroll > hidePoint
    const { id, title, to } = this.props

    return (
      <Fragment>
        { fixedPoint &&
        <Jumbotron className="jumbotronOUP jumbotronOUP--fixed">
          <Row>
            {title.name && (
              <Col xl="1" md="2" xs="3" className="d-flex justify-content-start" >
                { to ?
                  <NavLink tag={Link} className="jumbotronOUP__img" to={ to }>
                    <img className="rounded" src={`${envSettings.API_BASEURL}${title.cover}`} alt={title.name} />
                  </NavLink>
                  :
                  <img className="rounded" src={`${envSettings.API_BASEURL}${title.cover}`} alt={title.name} />
                }
              </Col>
            )}
            <Col xl="11" md="10" xs="9" className="d-flex" >
              <div className="align-self-center my-2">
                { to ?
                <NavLink tag={Link} to={ to }>
                  <h2>{title.name}</h2>
                </NavLink>
                :
                <h2>{title.name}</h2>
                }

                <hr className="my-2" />
                <p className="lead">
                  { title.subject && title.subject.map((data, idx) =>
                    <span key={data.categoryId}>{data.name}</span>
                  )}
                </p>
              </div>
            </Col>
          </Row>
        </Jumbotron>
        }

        <Jumbotron id={ id } className={cx({jumbotronOUP: true, invisible: fixedPoint})}>
          <Row>
            {title.name && (
              <Col xl="1" md="2" xs="3" className="d-flex justify-content-start " >
                { to ?
                  <NavLink tag={Link} className="jumbotronOUP__img" to={ to }>
                    <img className="rounded" src={`${envSettings.API_BASEURL}${title.cover}`} alt={title.name} />
                  </NavLink>
                  :
                  <img className="rounded" src={`${envSettings.API_BASEURL}${title.cover}`} alt={title.name} />
                }
              </Col>
            )}
            <Col xl="11" md="10" xs="9" className="d-flex" >
              <div className="align-self-center my-2">
                { to ?
                <NavLink tag={Link} to={ to }>
                  <h2>{title.name}</h2>
                </NavLink>
                :
                <h2>{title.name}</h2>
                }
                <hr className="my-2" />
                <p className="lead">
                  { title.subject && title.subject.map((data, idx) =>
                    <span key={data.categoryId}>{data.name}</span>
                  )}
                </p>
              </div>
            </Col>
          </Row>
        </Jumbotron>
      </Fragment>
    )
  }
}

TitleJumbotron.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
}

export default TitleJumbotron
