import React from 'react';
import PropTypes from 'prop-types'

// Settings constants
import envSettings from '../../../globals/envSettings'

const Audio = ({ data: { previewURL, mimetype } }) => {
  return (
    <div className="h-100 w-100 d-flex justify-content-center align-items-center">
      <audio className="resources__audio" controls>
        <source src={`${envSettings.API_BASEURL}${previewURL}`} preload="auto" type={mimetype} />
      </audio>
    </div>
  );
};

Audio.propTypes = {
  data: PropTypes.object.isRequired
}

export default Audio;