import React from 'react'
import { NavLink } from 'react-router-dom'

const GpaNavbar = ({ links }) => (
  <ol className="breadcrumb">
    {
      links.map(link => (
        <li key={link.id}>
          {
            (link.url) ?
              <NavLink exact={true} to={link.url}>{link.label}</NavLink>
              :
              <span>{link.label}</span>
          }
        </li>
      ))
    }
  </ol>
)

export default GpaNavbar