import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { Button, Container, Row, Col } from 'reactstrap'
import axios from 'axios'
import CheckBox from '../../Forms/CheckBox'
import { ContentConsumer, getLocale } from "../../../language";
import CMSContent from '../../CMSContent/CMSContent'
import { AuthConsumer } from 'react-check-auth'
import axiosConfig from '../../../config/axios'
import digitalProducts from '../../../globals/digitalProducts'
import ButtonPremium from '../../ButtonPremium/ButtonPremium'
import { copyToClipboard, format } from '../../../utils/string'

class PremiumModal extends Component {

  arr = [
    { id: 0, name: 'Mark Knopfler', email: 'direstraits@nomail.com', startDate: '12/02/1977', checked: false },
    { id: 1, name: 'Eric Clapton', email: 'cream@nomail.com', startDate: '12/02/1977', checked: false },
    { id: 2, name: 'David Gilmour', email: 'pinkfloyd@nomail.com', startDate: '12/02/1977', checked: false }
  ]

  state = { data: null, allChecked: false, licencesBlinkStudent: null, codeCopied: false }

  componentDidMount() {
    const { hasCurtain, student, hasCBButton, productUuid } = this.props
    if (hasCurtain) {
      this.setState({ data: this.arr })
    }
    else if (hasCBButton && student) {
      const axiosInstance = axios.create(axiosConfig)
      axiosInstance.get(`/product/${productUuid}/licence`)
      .then(response => {
        this.setState({ licencesBlinkStudent: response.data })
      })
      .catch(error => console.warn(error, 'Handle error Premium Modal Get'))

    }
    else {
      console.warn('We don´t need to get aditional info from the API')
    }
  }

  handleCheckBox = event => {
    const { data } = this.state,
      id = parseInt(event.target.name)

    let checkedInput = data.find(data => data.id === id)
    checkedInput = { ...checkedInput, checked: !checkedInput.checked }

    const newData = data.filter(data => data.id !== id)
    newData.push(checkedInput)

    const hasAllChecked = newData.find(data => !data.checked) ? true : false

    this.setState({
      data: _.sortBy(newData, 'id'),
      allChecked: !hasAllChecked
    })
  }


  handleCheckBoxAll = () => {
    const { data, allChecked } = this.state
    const newData = data.map(data => { return { ...data, checked: !allChecked } })
    this.setState({
      data: newData,
      allChecked: !allChecked
    })
  }

  render() {

    const { token, date, userInfo, hasCBButton, hasOLBButton, hasPangeaButton, toggleModal, student } = this.props,
      { licencesBlinkStudent, data, allChecked, codeCopied } = this.state,
      students = data ? data.filter(data => data.checked).length : 0

    const licenceDates = (licencesBlinkStudent && licencesBlinkStudent.endDate) ? new Intl.DateTimeFormat(userInfo.language.replace('_', '-'), { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(licencesBlinkStudent.endDate)) : null

    return (
      <AuthConsumer>
        {({ userInfo }) =>
          <ContentConsumer>
            {({ content }) =>
              content && (
                <div className="mb-3 align-items-center  products__modal">
                  <div className="products__modal__token  rounded mx-auto">
                    <div className="products__modal__token__info p-2 d-flex justify-content-between">
                      <span className="text-uppercase">{content.titledetail.activation_code}</span>
                      {data &&
                        <CMSContent pageid="INSTRUCTIONS_PLUS" cache={true} language={userInfo ? getLocale(userInfo.language) : getLocale()} />
                      }
                      {licencesBlinkStudent &&
                        <CMSContent pageid="INSTRUCTIONS_BLINK_STUDENT" cache={true} language={userInfo ? getLocale(userInfo.language) : getLocale()} />
                      }
                    </div>
                    <div className="text-center products__modal__token__number" >
                      { (this.props.productType === digitalProducts.productType.OLB) &&
                        <div className="py-4 px-3" >{licencesBlinkStudent ? licencesBlinkStudent.token.match(/.{1,4}/g).join('-') : token.match(/.{1,4}/g).join('-')}</div>
                      }
                      { (this.props.productType !== digitalProducts.productType.OLB) &&
                        <div className="py-4 px-3" >{licencesBlinkStudent ? licencesBlinkStudent.token : token}</div>
                      }
                      {licencesBlinkStudent &&
                        <div className="text-uppercase products__modal__token__number__secondaryInfo p-2 d-flex justify-content-between">
                          <span>{content.titledetail.remaining_activations} {licencesBlinkStudent.activations}/{licencesBlinkStudent.freeActivations}</span>
                          <span>{content.titledetail.end_date}: {licenceDates}</span>
                        </div>
                      }
                      {data &&
                        <div className="text-uppercase products__modal__token__number__secondaryInfo p-2 d-flex justify-content-between">
                          <span>{content.titledetail.remaining_activations} 3/25</span>
                          <span>{content.titledetail.end_date}: {date}</span>
                        </div>
                      }
                    </div>
                  </div>
                  {data &&
                    <div className="products__modal__students mt-5">
                      <form>
                        <table className="table mb-5">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={allChecked}
                                      onChange={this.handleCheckBoxAll}
                                    />
                                    <span>{content.titledetail.name}</span>
                                  </label>
                                </div>
                              </th>
                              <th scope="col">{content.titledetail.email}</th>
                              <th scope="col">{content.titledetail.discharge_date}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((data, idx) =>
                              <tr key={idx}>
                                <th scope="row">
                                  <div className="form-check">
                                    <label className="form-check-label">
                                      <CheckBox
                                        id={data.id}
                                        checked={data.checked}
                                        handleCheckBox={this.handleCheckBox}
                                      />
                                      <span>{data.name}</span>
                                    </label>
                                  </div>
                                </th>
                                <td>{data.email}</td>
                                <td>{data.startDate}</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div>
                          <div className="w-100 text-center mb-3">{content.titledetail.help}<span className="products__modal__students__question ml-1 glyphicon glyphicon-question-sign"></span></div>
                          <div className="d-flex justify-content-between" >
                            <Button onClick={() => toggleModal()} className="products__modal__sendInfo" size="lg" color="primary" >{content.titledetail.cancel}</Button>
                            <Button className="products__modal__sendInfo" size="lg" color="secondary" >{content.titledetail.deactivate_codes} ({students})</Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  }

                  <Fragment>
                    {(hasCBButton && !student) &&
                      <div className="m-4 products__modal__instructions" >
                        <CMSContent pageid="INSTRUCTIONS_BLINK_TEACHER" cache={true} language={userInfo ? getLocale(userInfo.language) : getLocale()} />
                      </div>
                    }
                    {hasOLBButton &&
                      <div className="m-4 products__modal__instructions" >
                        <CMSContent pageid="INSTRUCTIONS_OLB" cache={true} language={userInfo ? getLocale(userInfo.language) : getLocale()} />
                      </div>
                    }
                    {hasPangeaButton && (
                      <>
                        <div className="m-4 products__modal__instructions" >
                          <CMSContent pageid="INSTRUCTIONS_GOPANGEA" cache={true} language={userInfo ? getLocale(userInfo.language) : getLocale()} />
                        </div>
                        <Container>
                          { codeCopied && (
                            <Row className="my-4">
                              <Col className="d-flex justify-content-center">
                                <div className="text-white bg-success px-3">{ _.get(content, 'titledetail.code_copied') }</div>
                              </Col>
                            </Row>
                          )}
                          { document.queryCommandSupported('copy') &&
                            <Row>
                              <Col
                                xs={{ size: 6, offset: 3 }}
                                sm={{ size: 4, offset: 4 }}
                                className="py-2"
                              >
                                <ButtonPremium primary onClick={ (e) => {
                                  e.preventDefault()
                                  window.open(format(content.titledetail.pangea_url_teacher, { license: token }), '_blank')
                                }}>
                                  { _.get(content, 'titledetail.go_to_go_pangea') }
                                </ButtonPremium>
                              </Col>
                            </Row>
                          }
                        </Container>
                      </>
                    )}
                  </Fragment>
                </div>
              )
            }
          </ContentConsumer>
        }
      </AuthConsumer>
    )
  }
}

export default PremiumModal
