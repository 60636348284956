import React, { Fragment } from 'react'
import Product from './Product'

import StudentsLink from './StudentsLink'

const Online = (props) => {
  const { products, title } = props
  return (
    <Fragment>
      <div className="plus__Row mx-2">
        { products.map((product, idx) => (
          <Product
            key={idx}
            data={product}
          />
        ))}
        {/* title.studentable && <StudentsLink title={title} /> */}
        { !title.demo && title.studentable && <StudentsLink title={title} /> }
      </div>
    </Fragment>
  )
}

export default Online
