import React, { Component, Fragment } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { withUserInfo } from '../../../utils/hocs'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Table } from 'reactstrap'
import CreateFictitiousUser from '../CreateFictitiousUser'
import CreateOUPUser from '../CreateOUPUser'
import userRoles, { customerUsers, internalUsers, users } from "../../../globals/userRoles"
import { formatDate, formatDateTime } from "../../../utils/format"
import { getCustomerSupportData, getInternalSupportUserData, postImpersonateUser, getCustomerSupportUsersCountByRole } from "../../../services/administrator/request"
import { getStatusMessage } from "../../../utils/format"
import ModalAccept from '../../ModalAccept/ModalAccept'
import userSearchLimits from "./UserSearchAdministratorConstants"
import envSettings from '../../../globals/envSettings'

class UserSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noResults: false,
            oneFieldRequired: false,
            userList: [],
            todos: [],
            currentPage: 1,
            todosPerPage: userSearchLimits.MAX_NOT_FICTITIOUS_SEARCH,
            showUserSearchPage: true,
            openModal: false,
            popupMsg: '',
            numberRecords: 0,
            countTeachers: [],
            teachers: 0,
            demo: 0,
            teachers_total: 0
        };
        this.search = this.search.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.selectPage = this.selectPage.bind(this)
        this.displayPageNumbers = this.displayPageNumbers.bind(this)
        this.switchTabs = this.switchTabs.bind(this)
        this.switchPages = this.switchPages.bind(this)
        this.userSearchPage = this.userSearchPage.bind(this)
        this.handlePagination = this.handlePagination.bind(this)
        this.sortBy = this.sortBy.bind(this)
        this.onClickEnter = this.onClickEnter.bind(this)
        this.toggle = this.toggle.bind(this)
        this.showError = this.showError.bind(this)
       // this.refreshTeachers = this.refreshTeachers.bind(this)
        console.log("CustomerUserSearchScreen", props)
    }

    componentDidMount() {
        const { userInfo } = this.props
        window.scrollTo(0, 0)
        if (userInfo.role === userRoles.CUSTOMER_SUPPORT) this.refreshTeachers()
    }

    toggle() {
      this.setState({ openModal: false })
    }

    showError(error) {
        this.setState({
            userList: [],
            popupMsg: getStatusMessage(error),
            openModal: true
        })
      }

    validateUserValues() {
        const { userInfo } = this.props;
        let link = '';
        let mark = false;

        let userSearch = {
            id: this.id.value.trim(),
            name: this.name.value.trim(),
            surname1: this.surname1.value.trim(),
            surname2: this.surname2.value.trim(),
            email: this.email.value.trim(),
            dni: userInfo.role === userRoles.CUSTOMER_SUPPORT ? this.dni.value.trim() : null,
            role : this.role.value
        };

        for (var key in userSearch) {
            if (userSearch[key]) {
                link += "&" + key + "=" + userSearch[key];
                mark = true;
            }
        }

        let url = mark ? "?" + link.substring(1, link.length) : "";
        return url;
    }

    resetUserValues() {
        //this.id.value = '';
        //this.name.value = '';
        //this.surname1.value = '';
        //this.surname2.value = '';
        //this.email.value = '';
        //this.dni.value = '';
    }

    handleInputChange = () => this.setState({ noResults: false })

    selectPage({ target: { value } }) {
        this.handlePagination(value)
    }

    handlePagination(currentPage) {
        const { todosPerPage } = this.state;
        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        this.setState({
            todos: this.state.userList.slice(indexOfFirstTodo, indexOfLastTodo),
            currentPage
        });
    }

    displayPageNumbers() {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.userList.length / this.state.todosPerPage); i++) {
            pageNumbers.push(i);
        }
        this.setState({
            pageNumbers: this.state.userList.length > 0 ? pageNumbers : null,
            currentPage: this.state.userList.length > 0 ? 1 : null,
            numberRecords: this.state.userList.length
        });
    }

    switchTabs(data) {
        console.log("switchTabs", data);
        const { pageNumbers, currentPage: currentPageValue } = this.state;
        let currentPage = Number(currentPageValue);
        if (pageNumbers && pageNumbers.length > 1) {
            switch (data) {
                case 'first':
                    this.setState({ currentPage: 1 }, () => this.handlePagination(this.state.currentPage))
                    break;
                case 'prev':
                    this.setState({ currentPage: currentPage > 1 ? currentPage - 1 : 1 }, () => this.handlePagination(this.state.currentPage))
                    break;
                case 'next':
                    this.setState({ currentPage: currentPage < pageNumbers.length ? currentPage + 1 : pageNumbers.length }, () => this.handlePagination(this.state.currentPage))
                    break;
                case 'last':
                    this.setState({ currentPage: pageNumbers.length }, () => this.handlePagination(this.state.currentPage))
                    break;
                default:
                    this.setState({ currentPage: 1 }, () => this.handlePagination(this.state.currentPage))
                    break;
            }
        }
    }

    search() {
        const { userInfo } = this.props;
        if (this.validateUserValues() && userInfo.role === userRoles.CUSTOMER_SUPPORT) {
            getCustomerSupportData(this.validateUserValues())
                .then(data => {
                    if (data.status === 200) {
                        
                        let formatedData = data.data.map(d => {return { ...d, creationDate: formatDateTime(d.creationDate), lastAccessDate: formatDateTime(d.lastAccessDate)}})
                        this.setState({
                            userList: formatedData,
                            todos: formatedData.slice(0, (this.role.value !== userRoles.TEACHER_FAKE ? this.state.todosPerPage : userSearchLimits.MAX_FICTITIOUS_SEARCH)),
                            noResults: formatedData.length > 0 ? false : true,
                            oneFieldRequired: false,
                        }, () => {
                            this.resetUserValues();
                            this.displayPageNumbers();
                        });
                    }
                })
                .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
        }
        else if (this.validateUserValues() && userInfo.role === userRoles.INTERNAL_SUPPORT) {
            getInternalSupportUserData(this.validateUserValues())
                .then(data => {
                    if (data.status === 200) {
                        let formatedData = data.data.map(d => { return { ...d, creationDate: formatDateTime(d.creationDate), lastAccessDate: formatDateTime(d.lastAccessDate) } })
                        this.setState({
                            userList: formatedData,
                            todos: formatedData.slice(0, this.state.todosPerPage),
                            noResults: formatedData.length > 0 ? false : true,
                            oneFieldRequired: false,
                        }, () => {
                            this.resetUserValues();
                            this.displayPageNumbers();
                        });
                    }
                })
                .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
        }
        else if (!this.validateUserValues()) {
            this.setState({
                oneFieldRequired: true,
                noResults: false,
                userList: [],
                todos: []
            }, () => this.displayPageNumbers());
        }
    }

    sortBy(data) {
        switch (data) {
            case "createDate":
                this.setState(prevState => {
                    return {
                        todos: !prevState.sortByCreateDateAsc ? _.orderBy(this.state.todos, ['creationDate'], ['asc']) : _.orderBy(this.state.todos, ['creationDate'], ['desc']),
                        sortByCreateDateAsc: !prevState.sortByCreateDateAsc
                    }
                })
                break;
            case "lastLogin":
                this.setState(prevState => {
                    return {
                        todos: !prevState.sortByLastLoginAsc ? _.orderBy(this.state.todos, ['lastAccessDate'], ['asc']) : _.orderBy(this.state.todos, ['lastAccessDate'], ['desc']),
                        sortByLastLoginAsc: !prevState.sortByLastLoginAsc
                    }
                })
                break;
            default:
                this.setState(prevState => {
                    return {
                        todos: !prevState.sortByCreateDateAsc ? _.orderBy(this.state.todos, ['creationDate'], ['asc']) : _.orderBy(this.state.todos, ['creationDate'], ['desc']),
                        sortByCreateDateAsc: !prevState.sortByCreateDateAsc
                    }
                })
                break;
        }
    };

    switchPages(data) {
        this.setState({
            showUserSearchPage: data,
            userList: [],
            todos: [],
            noResults: false,
            oneFieldRequired: false
        }, () => this.displayPageNumbers());
    }

    onClickEnter({ key }) {
        if (key === 'Enter')
            this.search();
    }

    handleImpersonate = (e, userId) => {
        e.preventDefault()
        postImpersonateUser({ userId: String(userId) })
        .then(response => {
                window.location.href = '/app'
        })
        .catch((err) => this.showError(500))
    }

    refreshTeachers = () => {
      getCustomerSupportUsersCountByRole()
      .then(response => {
        this.setState({countTeachers : response.data})
      })
      .catch((err) => this.showError(500))
    }
/*    refreshTeachers = () => {
      Promise.all([
        getCustomerSupportCount('TEACHER'),
        getCustomerSupportCount('TEACHER_DEMO')
      ])
      .then(([teachers, demo]) => {        
        //if (teachers.status === 200 && demo.status === 200) this.setState({ teachers: teachers.data.count, demo: demo.data.count, teachers_total: teachers.data.count + demo.data.count})
        if (teachers.status === 200 && demo.status === 200) this.setState({countTeachers : [teachers.data, demo.data, {role: "Total", count: teachers.data.count + demo.data.count}]})
      })
      .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
    }
   */
    userSearchPage() {
        const { userInfo, handleStudentZone, handlePanels, admin } = this.props;
        return (
            <Fragment>
                <Container fluid="true">
                    <div className="customer-user-screen-container">
                        <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray">
                            <Col xs="6" md="8"><h3 className="text-primary font-weight-bold mb-0">User Search</h3></Col>
                            {userInfo.role === userRoles.CUSTOMER_SUPPORT && userInfo.adminFictitious && <Col xs="6" md="4"><div className="float-right"><Button className="font-weight-normal" color="secondary" size="sm" onClick={() => this.switchPages(false)}>Add fictitious</Button></div></Col>}
                            {userInfo.role === userRoles.INTERNAL_SUPPORT && <Col xs="6" md="4"><div className="float-right"><Button className="font-weight-normal" color="secondary" size="sm" onClick={() => this.switchPages(false)}>Add user</Button></div></Col>}
                        </Row>
                        <Form>
                            <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray">
                                <Col xs="12" sm="6" md="6" lg="3">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="ID">ID</Label>
                                        <Input type="text" size="100" name="id" id="ID" placeholder="" innerRef={el => this.id = el} onChange={this.handleInputChange} onKeyPress={this.onClickEnter} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="6" md="6" lg="3">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="name">Name</Label>
                                        <Input type="text" size="100" name="name" id="name" placeholder="" innerRef={el => this.name = el} onChange={this.handleInputChange} onKeyPress={this.onClickEnter} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="6" md="6" lg="3">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="firstSurname">First Surname</Label>
                                        <Input type="text" size="100" name="surname1" id="firstSurname" placeholder="" innerRef={el => this.surname1 = el} onChange={this.handleInputChange} onKeyPress={this.onClickEnter} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="6" md="6" lg="3">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="secondSurname">Second Surname</Label>
                                        <Input type="text" size="100" name="surname2" id="secondSurname" placeholder="" innerRef={el => this.surname2 = el} onChange={this.handleInputChange} onKeyPress={this.onClickEnter} />
                                    </FormGroup>
                                </Col>
                                <Col xs="12" sm="6" md="6" lg="6">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="emailAddress">Email Address</Label>
                                        <Input type="email" size="100" name="email" id="emailAddress" placeholder="" innerRef={el => this.email = el} onChange={this.handleInputChange} onKeyPress={this.onClickEnter} />
                                    </FormGroup>
                                </Col>
                                {userInfo.role === userRoles.CUSTOMER_SUPPORT && <Col xs="12" sm="6" md="6" lg="3">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="DNI/NIE">DNI / NIE</Label>
                                        <Input type="text" size="100" name="dni" id="DNI/NIE" placeholder="" innerRef={el => this.dni = el} onChange={this.handleInputChange} onKeyPress={this.onClickEnter} />
                                    </FormGroup>
                                </Col>}
                                <Col xs="12" sm="6" md="6" lg="2">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="language">Role</Label>
                                        <Input type="select" name="adoption_languages" id="adoption_languages" placeholder="" innerRef={el => this.role = el}
                                            onChange={this.handleInputChange}>
                                            {userInfo.role === userRoles.CUSTOMER_SUPPORT ?
                                            ['', users.TEACHER, users.TEACHER_DEMO, users.TEACHER_FAKE].map((e) => <option key={e} value={e === users.TEACHER_DEMO ? userRoles.TEACHER_DEMO : 
                                                                                                                                          (e === users.TEACHER_FAKE ? userRoles.TEACHER_FAKE: e)}>{e}</option>) :
                                            ['', userRoles.CUSTOMER_SUPPORT, userRoles.INTERNAL_SUPPORT, userRoles.USER_INTERNAL].map((e) => <option key={e} value={e}>{e}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col xs="12" sm="6" md="12" lg="1">
                                    <FormGroup className="form-group">
                                        <div className="search-button">
                                            <Button className="font-weight-normal" color="secondary" size="md" onClick={this.search}>Search</Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                                {this.state.noResults && <Col xs="12"><span className="font-weight-bold text-danger">No results found</span></Col>}
                                {this.state.oneFieldRequired && <Col xs="12"><span className="font-weight-bold text-danger">Please enter atleast one field</span></Col>}
                            </Row>
                        </Form>
                        <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray small">                            
                                <Col xs="12" sm="2" md="2" lg="1"><span className="font-weight-bold">Records: {this.state.numberRecords}</span></Col>
                                {userInfo.role === userRoles.CUSTOMER_SUPPORT &&
                                  <Col xs="12" sm="10" md="10" lg="11">
                                    <Row>
                                      <Col xs="12" sm="2" md="2" lg="2"><div><Button className="font-weight-normal" color="secondary" size="sm" onClick={() => this.refreshTeachers()}>Refresh teachers</Button></div></Col>
                                        {/*<Col xs="12" sm="2" md="2" lg="2"><div><span className="font-weight-bold">Teachers: {this.state.teachers}</span></div></Col>
                                         <Col xs="12" sm="2" md="2" lg="2"><div><span className="font-weight-bold">Demo: {this.state.demo}</span></div></Col>
                                         <Col xs="12" sm="6" md="6" lg="6"><div><span className="font-weight-bold">Total: {this.state.teachers_total}</span></div></Col>
                                        */}
                                      {this.state.countTeachers.length > 0 && this.state.countTeachers.map((data, index) => (
                                      <Col xs="12" sm="auto" lg="auto" key={index}><div><span className="font-weight-bold"> {`${data.role} : ${data.count}`}  </span></div></Col>
                                      ))}
                                    </Row>
                                  </Col>
                                }
                                
                                {/*<Col className="col-3 col-sm-1 col-md-1 col-lg-1 d-inline-block text-left col"><span className="font-weight-bold">Page </span></Col>
                                <Col className="col-3 col-sm-1 col-md-1 col-lg-2 d-inline-block col">
                                    <FormGroup className="mb-0">
                                        <Input className={this.state.pageNumbers ? '' : 'disable'} type="select" name="selectMulti" id="exampleSelectMulti" onChange={this.selectPage} value={this.state.currentPage ? this.state.currentPage : ""}>
                                            {this.state.pageNumbers && this.state.pageNumbers.map((data, index) => (
                                                <option key={index} value={data}>{data}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col className="col-3 col-sm-2 col-md-2 col-lg-1 d-inline-block col"><span className="font-weight-bold">of {this.state.pageNumbers ? this.state.pageNumbers.length : 0}</span></Col>
                                */}
                     
                            {/*
                            <Col className="pagination-container col-sm-10 offset-1 offset-sm-2 offset-md-0 offset-lg-0" xs="12" md="7" lg="5">
                                <Pagination aria-label="Page navigation example">
                                    <PaginationItem onClick={() => this.switchTabs('first')}>
                                        <PaginationLink className={this.state.pageNumbers ? '' : 'disable'}>
                                            <span className="glyphicon glyphicon-step-backward" aria-hidden="true"></span> first
                                    </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem onClick={() => this.switchTabs('prev')}>
                                        <PaginationLink className={this.state.pageNumbers ? '' : 'disable'}>
                                            <span className="glyphicon glyphicon-triangle-left" aria-hidden="true"></span> Previous
                                    </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem onClick={() => this.switchTabs('next')}>
                                        <PaginationLink className={this.state.pageNumbers ? '' : 'disable'}>
                                            Next <span className="glyphicon glyphicon-triangle-right" aria-hidden="true"></span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem onClick={() => this.switchTabs('last')}>
                                        <PaginationLink className={this.state.pageNumbers ? '' : 'disable'}>
                                            Last<span className="glyphicon glyphicon-step-forward" aria-hidden="true"></span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </Col>
                            */}
                        </Row>
                        <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray small">
                            <Col xs="12" sm="12" md="12" lg="12">
                                <Table responsive={true}>
                                    <thead>
                                        <tr bgcolor="#5393d4">
                                            {userInfo.role === userRoles.CUSTOMER_SUPPORT &&<th></th>}
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>First Surname</th>
                                            <th>Second Surname</th>
                                            <th>Email Address</th>
                                            <th className="text-underline" onClick={() => this.sortBy('createDate')}>Create Date <span className={this.state.sortByCreateDateAsc ? "glyphicon glyphicon-triangle-top" : "glyphicon glyphicon-triangle-bottom"} aria-hidden="true"></span></th>
                                            <th className="text-underline" onClick={() => this.sortBy('lastLogin')}>Last Login <span className={this.state.sortByLastLoginAsc ? "glyphicon glyphicon-triangle-top" : "glyphicon glyphicon-triangle-bottom"} aria-hidden="true"></span></th>
                                            <th>Role</th>
                                            {userInfo.role === userRoles.CUSTOMER_SUPPORT &&<th></th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.todos.length > 0 && this.state.todos.map((data, index) => (
                                            <tr key={index}>
                                                {userInfo.role === userRoles.CUSTOMER_SUPPORT && customerUsers.includes(data.role) &&
                                                <td><Button className="font-weight-small" color="secondary" size="sm" onClick={(e) => this.handleImpersonate(e, data.id)}>Impersonate</Button></td>}
                                                {userInfo.role === userRoles.CUSTOMER_SUPPORT && <td>{(customerUsers.includes(data.role)) ? <Link to={`/edit/customer/user/${data.role}/${data.id}`}>{data.id}</Link> : data.id}</td>}
                                                {userInfo.role === userRoles.INTERNAL_SUPPORT && <td>{(internalUsers.includes(data.role)) ? <Link to={`/edit/internal/user/${data.role}/${data.id}`}>{data.id}</Link> : data.id}</td>}
                                                <td>{data.name}</td>
                                                <td>{data.surname1}</td>
                                                <td>{data.surname2}</td>
                                                <td>{data.email}</td>
                                                <td>{formatDate(data.creationDate, "DD/MM/YYYY")}</td>
                                                <td>{formatDate(data.lastAccessDate, "DD/MM/YYYY")}</td>
                                                <td>{users[data.role]}</td>
                                                {userInfo.role === userRoles.CUSTOMER_SUPPORT && [userRoles.TEACHER, userRoles.TEACHER_DEMO, userRoles.TEACHER_FAKE].includes(data.role) &&
                                                <td><a href={`${envSettings.API_BASEURL}/admin/customer/user/${data.id}/licences?excel`} target="_blank" rel="noopener noreferrer"><span className="glyphicon glyphicon-download-alt" aria-hidden="true"></span> </a></td>}

                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <ModalAccept isOpen={this.state.openModal} toggle={this.toggle} header={'Alert'}>
                            <div className="text-center font-weight-bold">{this.state.popupMsg}</div>
                        </ModalAccept>

                        {admin && <Row className="pt-2 pb-2 ml-1 mr-1">
			              <Col xs="12"sm="6"md="6">
                            {/* <Button className="font-weight-normal"color="secondary"size="md" onClick={()=> handleStudentZone(false, false)}>Go back</Button> */}
			                <Button className="font-weight-normal"color="secondary"size="md" onClick={handlePanels}>Go back</Button>
			              </Col>
			            </Row>
                        }
                    </div>
                </Container>
            </Fragment>
        );
    }

    render() {
        const { showUserSearchPage } = this.state;
        const { userInfo: { role } } = this.props;

        if (showUserSearchPage)
            return this.userSearchPage();
        else if (!showUserSearchPage) {
            switch (role) {
                case userRoles.CUSTOMER_SUPPORT:
                    return <CreateFictitiousUser type={'CREATE'} back={(data) => this.switchPages(data)} />
                case userRoles.INTERNAL_SUPPORT:
                    return <CreateOUPUser type={'CREATE'} back={(data) => this.switchPages(data)} />
                default:
                    return <CreateFictitiousUser type={'CREATE'} back={(data) => this.switchPages(data)} />
            }
        }
    }
}

UserSearch.propTypes = {
    userInfo: PropTypes.object.isRequired
};

export default withUserInfo(withRouter(UserSearch))