import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap'

import { withContent } from '../../utils/hocs'
import { nFormatter } from '../../utils/format'
import envSettings from '../../globals/envSettings'

class PDItem extends Component {
  gotoDetail = () => {
    const { uuid, history } = this.props
    history.push(`/professional-development/${uuid}`)
  }

  smallThumbnailUrl() {
    const { uuid } = this.props
    return `${envSettings.API_BASEURL}/pd/${uuid}/smallThumbnail`
  }

  render() {
    const { content, isStarred, title, category, author, views, viewed } = this.props
    return (
      isStarred ?
      <Container
        className="asset-starred"
        onClick={ this.gotoDetail }
      >
          <Row className="h-100">
            <Col
              className="py-3 d-flex"
              xs="12"
              lg="7">
              <div className="my-auto">
                <div className="asset-starred-category">{ category }</div>
                <div className="asset-starred-title">{ title }</div>
              </div>
            </Col>
            <Col
              className="text-center mt-3 mt-lg-0"
              xs="12"
              lg="5">
                <img className="w-100" alt={ title } src={ this.smallThumbnailUrl() } /></Col>
          </Row>
        </Container>
      :
      <Card
        className="asset-card"
        onClick={ this.gotoDetail }
      >
        <div className="asset-card-image">
          <img className="img-responsive" alt={ title } src={ this.smallThumbnailUrl() } />
          { viewed ? <div className="asset-card-image-viewed"><span className="glyphicon glyphicon-eye-open" aria-hidden="true"></span></div> : null }
        </div>
        <CardBody className="p-3">
          <CardText tag="div" className="asset-card-category">{ category }</CardText>
          <CardTitle className="mt-3 asset-card-title">{ title }</CardTitle>
          <div className="d-flex mt-3 asset-card-author">
            <div className="d-inline-block pr-2 text-truncate">{ author }</div>
            <div className="d-inline-block pl-2 text-nowrap asset-card-views">{ nFormatter(views) } { content.assets.views }</div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

PDItem.propTypes = {
  uuid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  smallThumbnail: PropTypes.string,
  largeThumbnail: PropTypes.string,
  isStarred: PropTypes.bool,
  views: PropTypes.number,
  viewed: PropTypes.bool
}

export default withContent(withRouter(PDItem))
