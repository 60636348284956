import React from 'react'
import { Button } from 'reactstrap'

import './styles.css'

const ButtonCoupons = (props) => (
  <Button
    {...props}
    primary={'' /* to avoid warning */}
    className={`button ${!!props.primary ? 'button-primary' : 'button-secondary'} ${props.className ? props.className : ''}`}
  >
    {props.children}
  </Button>
)

export default ButtonCoupons
