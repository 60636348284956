// MBQ oupesim roles at the moment
const userRoles = {
    TEACHER: 'TEACHER',
    TEACHER_DEMO: 'TEACHER_DEMO',
    TEACHER_PREMIUM: 'TEACHER_PREMIUM',
    STUDENT: "STUDENT",
    TEACHER_FAKE: 'TEACHER_FAKE',
    USER_INTERNAL: 'USER_INTERNAL',
    USER: 'USER',
    INTERNAL: 'INTERNAL',
    DIGITAL_SUPPORT: 'DIGITAL_SUPPORT',
    CGC: 'CGC',
    ADMINISTRATOR: 'ADMINISTRATOR',
    CUSTOMER_SUPPORT: 'CUSTOMER_SUPPORT',
    INTERNAL_SUPPORT: 'INTERNAL_SUPPORT',
    INVOICE: 'INVOICE',
}

export const users = {
  TEACHER: "TEACHER",
  STUDENT: "STUDENT",
  TEACHER_DEMO: "DEMO",
  TEACHER_PREMIUM: "PREMIUM",
  TEACHER_FAKE: "FAKE",
  USER_INTERNAL: "USER_INTERNAL",
  CUSTOMER_SUPPORT: "CUSTOMER-SUPPORT",
  INTERNAL_SUPPORT: "INTERNAL-SUPPORT",
  USER: "USER",
  INTERNAL: "INTERNAL",
};

export const PROFILES = {
  CUSTOMER_USER_PREMIUM: 'CUSTOMER_USER_PREMIUM',
  CUSTOMER_USER_PLUS: 'CUSTOMER_USER_PLUS',
  SUPPORT_USER: 'SUPPORT_USER'
}

export const productUserType = {
  STUDENT: 'Alumno',
  TEACHER: 'Profesor',
  BOTH: 'Ambos',
  DEMO: 'Demo',
}

export const adminRoles = [userRoles.CUSTOMER_SUPPORT, userRoles.INTERNAL_SUPPORT]

export const invoiceRoles = [userRoles.INVOICE]

export const customerUsers = [userRoles.TEACHER, userRoles.STUDENT, userRoles.TEACHER_DEMO, userRoles.TEACHER_PREMIUM, userRoles.TEACHER_FAKE]

export const internalUsers = [userRoles.USER_INTERNAL, userRoles.CUSTOMER_SUPPORT, userRoles.INTERNAL_SUPPORT]

export const blinkCptRoles = [userRoles.TEACHER, userRoles.TEACHER_DEMO, userRoles.TEACHER_FAKE, userRoles.USER_INTERNAL]

export default userRoles;
