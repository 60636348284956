import React, { Fragment }  from 'react'
import PropTypes from 'prop-types';
import { Container, Row, Col, Modal as ModalRT, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import cx from 'classnames'

import { Colors } from '../Header/components/Colors'
import ButtonPremium from '../ButtonPremium/ButtonPremium'

const Modal2Buttons = (props) => {
  const { isOpen, toggle, className, children, header, subtitle,
  primaryButtonLabel, secondaryButtonLabel, primaryButtonOnClick, secondaryButtonOnClick } = props
  const colors = [ 'pink', 'yellow', 'green', 'blue']

  return (
    <ModalRT isOpen={isOpen} toggle={toggle} className={cx("modal-2-buttons", className)}>
      <Colors colors={colors} />
      {header &&
        <ModalHeader className="modal__header text-center" toggle={toggle}>
          {header}
          { subtitle && <Fragment><br /><span className='small'>{subtitle}</span></Fragment>}
        </ModalHeader>
      }
      <ModalBody>
        {children}
      </ModalBody>
      <ModalFooter>
        <Container fluid={false}>
          <Row>
            <Col sm={{ size: 4, offset: 1}}>
              <ButtonPremium onClick={ secondaryButtonOnClick }>
                { secondaryButtonLabel }
              </ButtonPremium>
            </Col>
            <Col sm={{ size: 4, offset: 1}}>
              <ButtonPremium onClick={ primaryButtonOnClick } primary>
                { primaryButtonLabel }
              </ButtonPremium>
            </Col>
          </Row>
        </Container>
      </ModalFooter>
    </ModalRT>
  )
}

Modal2Buttons.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  header: PropTypes.string,
  subtitle: PropTypes.string,
  primaryButtonLabel: PropTypes.string.isRequired,
  secondaryButtonLabel: PropTypes.string.isRequired,
  primaryButtonOnClick: PropTypes.func.isRequired,
  secondaryButtonOnClick: PropTypes.func.isRequired
}

export default Modal2Buttons
