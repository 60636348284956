import React from 'react'
import { Container, Row, Col, Nav, NavItem } from 'reactstrap'

import Online from './components/Online'
import Offline from './components/Offline'

import NeedHelpButton from '../Buttons/NeedHelpButton'

import { withContent } from '../../utils/hocs'
import { parseToReactElement } from '../../utils/html'

const tabs = {
  ONLINE: 0,
  OFFLINE: 1
}

class DigitalContent extends React.Component {
  constructor(props) {
    super(props)
    const { data } = this.props
    this.state = {
      selected: data && data.length ? tabs.ONLINE : tabs.OFFLINE
    }
  }

  render() {
    const { data, installers, title, openCarousel } = this.props
    const { selected } = this.state

    return (
      <Container fluid className="products">
        <Row>
          <Col lg={{ size: 2, offset: 0 }} xs={{ size: 8, offset: 2 }}>
            <Nav vertical>
            { data && data.length ?
              <NavItem
                className="products-menu-item mb-3 py-2"
                active={ selected === tabs.ONLINE }
                onClick={ () => this.setState({ selected: tabs.ONLINE })}
              >
                { parseToReactElement(this.props.content.titledetail.online_button) }
              </NavItem>
                : null
            }
            { installers && installers.length ?
              <NavItem
                className="products-menu-item mb-3 py-2"
                active={ selected === tabs.OFFLINE }
                onClick={ () => this.setState({ selected: tabs.OFFLINE })}
              >
                { parseToReactElement(this.props.content.titledetail.offline_button) }
              </NavItem> : null
            }
            </Nav>
            <NeedHelpButton
              onClick={openCarousel}
            />
          </Col>
          <Col lg="10" xs="12">
            { selected === tabs.ONLINE &&
              <Online
                products={data}
                title={title}
              />
            }
            { selected === tabs.OFFLINE &&
              <Offline
                installers={installers}
              />
            }
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withContent(DigitalContent)
