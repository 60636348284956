import React from 'react'
import { AuthConsumer } from 'react-check-auth'
import { ContentConsumer } from '../../language'
import CMSContent from '../CMSContent/CMSContent'

// MBQ OSP-2150 MAY 2024 : Same content for public and private Help page (PREM_HELPPRIV)
//const CONTENT_PREM_HELP_PUB = 'PREM_HELP'
const CONTENT_PREM_HELP_PUB = 'PREM_HELPPRIV'
const CONTENT_PREM_HELP_PRV = 'PREM_HELPPRIV'

const HelpPage = () => (
  <AuthConsumer>
    {({ userInfo }) => (
      <ContentConsumer>
        {({ locale }) => (
          <CMSContent pageid={userInfo ? CONTENT_PREM_HELP_PRV : CONTENT_PREM_HELP_PUB} cache={true} language={locale} />
        )}
      </ContentConsumer>
    )}
  </AuthConsumer>
)

export default HelpPage