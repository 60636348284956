import React from 'react'
import { Col, Nav, NavItem, NavLink as Navlink } from 'reactstrap';
//import PropTypes from 'prop-types'

export const DrawTab = (props) => {

  const { to, onClick, active } = props

  return (
    <Col>
      <Nav className="editLibrary__tab" >
        <NavItem className="editLibrary__tab__item">
          <Navlink display="inline-block"
            to={ {to} }
            active={active}
            disabled={active}
            onClick={onClick}>
              {to}
          </Navlink>
        </NavItem>
      </Nav>
    </Col>
  )
 }
/*
 DrawTab.propTypes = {
  content: PropTypes.object
}
*/