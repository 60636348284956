import { PROFILES } from '../globals/userRoles'
import { isPlusPortal } from '../globals/envSettings'

export const PORTAL = {
  PREMIUM: 'oxfordpremium', /* default */
  PLUS: 'oxfordplus',
  APP: 'app' /* PREMIUM and PLUS */
}

export const ROUTE_PROFILE = {
  ALL: 'all', /* all profiles */
  ADMIN: 'admin', /* SUPPORT_USER */
  CUSTOMER: 'customer', /* CUSTOMER_USER_PREMIUM and CUSTOMER_USER_PLUS */
  CUSTOMER_PREMIUM: 'customer_premium', /* CUSTOMER_USER_PREMIUM (default) */
  CUSTOMER_PLUS: 'customer_plus', /* CUSTOMER_USER_PLUS */
}

export const isAllowedPath = (userProfile, routeProfile, portal) => {
  if (
    (isPlusPortal() && (
      userProfile === PROFILES.SUPPORT_USER
      ||
      (!portal || portal === PORTAL.PREMIUM)
    ))
    ||
    (!isPlusPortal() && (
      userProfile === PROFILES.CUSTOMER_USER_PLUS
      ||
      portal === PORTAL.PLUS
    ))
  ) return false

  let allowed = false
  if (!routeProfile || routeProfile === ROUTE_PROFILE.CUSTOMER_PREMIUM) {
    if (userProfile === PROFILES.CUSTOMER_USER_PREMIUM) allowed = true
  } else {
    switch (routeProfile) {
      case ROUTE_PROFILE.ALL:
        allowed = true
        break
      case ROUTE_PROFILE.ADMIN:
        if (userProfile === PROFILES.SUPPORT_USER) allowed = true
        break
      case ROUTE_PROFILE.CUSTOMER_PLUS:
        if (userProfile === PROFILES.CUSTOMER_USER_PLUS) allowed = true
        break
      case ROUTE_PROFILE.CUSTOMER:
        if ([PROFILES.CUSTOMER_USER_PREMIUM, PROFILES.CUSTOMER_USER_PLUS].includes(userProfile)) allowed = true
        break
      default:
        allowed = false
        break
    }
  }
  return allowed
}