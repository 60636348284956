import React, { useState } from 'react'
import Lightbox from 'lightbox-react'

import envSettings from '../../../globals/envSettings'
import { icon } from '../../ResourceList/resource'
import Audio from '../../Resources/components/Audio'
import Video from '../../Resources/components/Video'
import PDFViewer from '../../Resources/components/PDFViewer'
import NoViewer from '../../Resources/components/NoViewer'
import Footer from '../../ResourceList/components/Footer'

import './icons.css'

const ResourceListItem = (props) => {
  const { resource } = props
  const {
    downloadURL,
    externalURL,
    previewURL,
    type,
    mimetype,
    name,
    size,
    description
  } = resource
  const [isOpen, setOpen] = useState(false)

  const isBlank = (str) => !str || /^\s*$/.test(str)
  const isAnimation = (resourceType) => resourceType === '{http://www.oupe.es/model}animacion'
  const isZip = /zip/.test(mimetype)

  const getPreviewer = () => {
    const audioPlayer = /mpeg|x-wav/.test(mimetype)
    const videoPlayer = /mp4/.test(mimetype)
    const isEmpty = !previewURL || /^\s*$/.test(previewURL)
    const isImage = /jpg|jpeg|png/.test(mimetype)

    if(!isEmpty && !audioPlayer && !videoPlayer && !isImage) return <PDFViewer data={resource} />
    if(audioPlayer) return <Audio data={resource} />
    if(videoPlayer) return <Video data={resource} />
    if(isImage) return previewURL
    return <NoViewer data={resource} />
  }

  const isPDFPrevisualizable = () => {
    const audioPlayer = /mpeg|x-wav/.test(mimetype)
    const videoPlayer = /mp4/.test(mimetype)
    const isEmpty = !previewURL || /^\s*$/.test(previewURL)
    const isImage = /jpg|jpeg|png/.test(mimetype)

    if(!isEmpty && !audioPlayer && !videoPlayer && !isImage) return true
    return false
  }

  return (
    <>
      <tr className="d-flex align-items-center">
        <th className="resourceList_icon d-none d-md-table-cell">{icon({ externalURL, type, mimetype })}</th>
        <td className="resourceList_name px-2">{ name }</td>
        <td className="resourceList_button d-none d-md-table-cell">
          {
            (!isBlank(previewURL) || !isBlank(downloadURL)) && !isAnimation(type) && !isPDFPrevisualizable() && !isZip
            && (
              <span
                role="button"
                className="border-0"
                onClick={() => setOpen(true)}
              >
                <img
                  className="w-25px"
                  src="https://online.oupe.es/OUP/Biblioteca/Iconos/icono_Ojo.png"
                  alt=""
                />
              </span>
            )
          }
          {!isBlank(externalURL) && !isZip
            && (
              <span
                className="border-0"
                role="button"
                onClick={(e) => {
                  e.preventDefault()
                  window.open(externalURL, '_blank')
                }}
              >
                <img
                  className="w-25px"
                  src="https://online.oupe.es/OUP/Biblioteca/Iconos/icono_Ojo.png"
                  alt=""
                />
              </span>
            )
          }
          {!isBlank(previewURL) && isAnimation(type) && !isZip && (
            <a href={`${envSettings.API_BASEURL}${previewURL}`} target="_blank" rel="noopener noreferrer" title={name}>
              <span
                className="border-0"
                role="button"
              >
                <img
                  className="w-25px"
                  src="https://online.oupe.es/OUP/Biblioteca/Iconos/icono_Ojo.png"
                  alt=""
                />
              </span>
            </a>
          )}
              { /*(isBlank(previewURL) && isBlank(externalURL) && isBlank(downloadURL)) && (
            <span
              className="border-0"
              role="button"
            >
              <img className="w-25px" src="https://online.oupe.es/OUP/Biblioteca/Iconos/icono_Ojo.png" />
            </span>
          ) */}
        </td>

        <td className="resourceList_button d-none d-md-table-cell">
          {
            !isBlank(downloadURL)
            && (
              <a href={`${envSettings.API_BASEURL}${downloadURL}`} title={name}>
                <span
                  role="button"
                >
                  <img
                    className="w-25px"
                    src="https://online.oupe.es/OUP/Biblioteca/Iconos/icono_Descarga.png"
                    alt=""
                  />
                </span>
              </a>
            )
          }
        </td>
      </tr>
      {
        isOpen
        && (
          <Lightbox
            mainSrc={getPreviewer()}
            onCloseRequest={() => setOpen(false)}
            imageTitle={<h1>{name}</h1>}
            imageCaption={<Footer data={{ size, downloadURL, mimetype, description }} />}
            discourageDownloads
            reactModalStyle={{ overlay: { zIndex: 22223 }}}
            enableZoom={false}
          />
        )
      }
    </>
  )
}

export default ResourceListItem
