import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import envSettings from '../../globals/envSettings'

import { withContent } from '../../utils/hocs'

export const Contact = withContent((props) => (
  <Link
    className={cx("links__contact", props.className)}
    to={ props.link || '/contact'}
  >
      { props.content.sharedTerms.CONTACT_US_LINK }
  </Link>
))

export const ForgotPassword = withContent((props) => (
  <Link
    className={cx("links__forgotpassword", props.className)}
    to={ props.link || '/forgot-password'}
  >
    { props.content.useraccount.forgot_password }
  </Link>
))

export const Help = withContent((props) => (
  <span className={cx("links__help", props.className)}>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={ props.link || props.topic ? `${envSettings.APP_BASEURL}/help#${props.topic}` : `${envSettings.APP_BASEURL}/help`}
    >
      { props.content.sharedTerms.HELP_LINK }
    </a> <span className="glyphicon glyphicon-question-sign"></span>
  </span>
))
