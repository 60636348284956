import React from 'react'
import { AuthConsumer } from 'react-check-auth'

import { FeatureContext } from '../contexts/Feature/FeatureContext'
import { ContentConsumer, DEFAULT_LOCALE, validateLanguage } from '../language'

export const withUserInfo = Component => {
  return props => (
    <AuthConsumer>
      {({userInfo }) => userInfo && <Component {...props} userInfo={userInfo}/> }
    </AuthConsumer>
  )
}

export const withContent = Component => {
  return props => (
    <ContentConsumer>
      {({ content, locale }) => content && <Component {...props} content={content} locale={locale} /> }
    </ContentConsumer>
  )
}

export const withUserInfoReloadable = Component => {
  return props => (
    <AuthConsumer>
      {({ userInfo, refreshAuth }) => userInfo && <Component {...props} userInfo={userInfo} refreshAuth={refreshAuth} /> }
    </AuthConsumer>
  )
}

export const withFeature = (Component) => {
  return (props) => (
    <FeatureContext.Consumer>
      {(value) => value && <Component {...props} featureContext={value} /> }
    </FeatureContext.Consumer>
  )
}

export const withNavigatorLocale = (Component) => {
  const locale = validateLanguage(
    (
      window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language ||
      window.navigator.browserLanguage ||
      window.navigator.userLanguage
    ) || DEFAULT_LOCALE

    return (props) => (
      <Component
        {...props}
        locale={locale}
      />
    )
}
