// estados solicitud
export const estado_pendiente = 'NOSOLICITA'
export const estado_aprobada = 'APROBADA'
export const estado_enrevision = 'ENREVISION'
export const estado_rechazada = 'RECHAZADA'
export const estado_modificada = 'MODIFICADA'
export const estado_enviada = 'ENVIADA'

// info estado
export const stateData = {
  [estado_pendiente]: {
    icon: null,
    label: estado_pendiente,
    tooltip: null
  },
  [estado_aprobada]: {
    icon: 'glyphicon glyphicon-ok',
    label: estado_aprobada,
    tooltip: `${estado_aprobada}_tooltip`
  },
  [estado_enrevision]: {
    icon: 'glyphicon glyphicon-time',
    label: estado_enrevision,
    tooltip: `${estado_enrevision}_tooltip`
  },
  [estado_rechazada]: {
    icon: null,
    label: estado_rechazada,
    tooltip: null
  },
  [estado_modificada]: {
    icon: 'glyphicon glyphicon-remove',
    label: estado_modificada,
    tooltip: `${estado_modificada}_tooltip`
  },
  [estado_enviada]: {
    icon: 'glyphicon glyphicon-send',
    label: estado_enviada,
    tooltip: `${estado_enviada}_tooltip`
  }
}

// tipos de cabecera
export const header_student = '1'
export const header_classroom = '2'
export const header_teacher = '3'

// tipos de material
export const material_student = 'ALUM'
export const material_teacher = 'PROF'
export const material_classroom = 'AULA'
export const material_school = 'CENTRO'

// tipo de acción con materiales
export const material_minus = '-'
export const material_plus = '+'
export const material_edit = 'edit'

// botones
export const button_action_on = 'btn btn-premium btn-block'
export const button_action_off = 'btn btn-modal-plus-inverse btn-block'

// url
export const gpa_url = '/gratis-adoption'

// default cover
export const DEFAULT_COVER = 'https://portadas.oupe.es/default.png'

export const isActionRequired = (order, userId) => isEditableOrder(order, userId, [estado_modificada])

export const isEditableMaterial = (order, userId) => isEditableOrder(order, userId, [estado_modificada, estado_aprobada])

const isEditableOrder = (order, userId, states) => (
  (
    order.pedidoModificable
    ||
    (order.detalleModificable && order.idProfesor && order.idProfesor === userId && states.includes(order.estado.idEstado))
  )
)

export const getOrderButtonLabel = (order, userId, content) => (
  getOrderLabel(order, userId, content, false)
)

export const getOrderTitle = (order, userId, content) => (
  getOrderLabel(order, userId, content, true)
)

const getOrderLabel = (order, userId, content, isTitle) => {
  if (order.pedidoModificable && !order.idProfesor) {
    if (isTitle) return content.gratisadoption.gxa_31
    return content.gratisadoption.gxa_48
  }
  if (order.detalleModificable && order.idProfesor && order.idProfesor === userId) {
    if (order.estado.idEstado === estado_modificada) return content.gratisadoption.gxa_17
    return content.gratisadoption.gxa_22
  }
  return content.gratisadoption.gxa_19
}

export const validateNumber = (value) => {
  const number = parseInt(value, 10)
  return (number && !isNaN(number) && Number.isInteger(number) && number > 0) ? number : 0
}

export const validateAddMaterial = (material, teachers, classrooms) => {
  const units = material.unidadesActual + material.unidadesEnviadas
  let maxValue = 1
  if (material.tipoMaterial === material_student || material.tipoMaterial === material_teacher) {
    maxValue = teachers
  } else if (material.tipoMaterial === material_classroom) {
    maxValue = classrooms
  }
  if (units <= maxValue) return true
  return false
}

export const validateOrder = (order, authorized) => {
  if (order.pedidoModificable && (!order.alumnosPeticion || !order.aulasPeticion || !order.profesoresPeticion || !authorized)) return false
  const units = order.materiales.reduce((result, material) => (result + material.unidadesActual), 0)
  if (!units) return false
  const invalidMaterial = order.materiales.find(material => !validateAddMaterial(material, order.profesoresPeticion, order.aulasPeticion))
  if (invalidMaterial) return false
  return true
}

export const resetMaterials = (materials, headerType) => (
  materials.map(material => {
    if (
      (headerType === header_teacher && (material.tipoMaterial === material_student || material.tipoMaterial === material_teacher))
      ||
      (headerType === header_classroom && material.tipoMaterial === material_classroom)
    ) material.unidadesActual = 0
    return material
  })
)

export const getDefaultCover = e => e.target.src = DEFAULT_COVER