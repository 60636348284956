export const format = (string, ...args) => {
  if(!args.length) return string
  let returnValue = string
  const type = typeof args[0]
  const keys = (type === 'string') || (type === 'number') ? Array.prototype.slice.call(args) : args[0]
  for(let key in keys) returnValue = returnValue.replace(new RegExp('\\{' + key + '\\}', 'gi'), keys[key])
  return returnValue
}

export function copyToClipboard(text) {
  const textarea = document.createElement('textarea')
  textarea.value = text
  document.body.appendChild(textarea)
  textarea.style.position = 'absolute'
  textarea.style.top = '-1000px'
  textarea.select()
  document.execCommand('copy')
  document.body.removeChild(textarea)
}
