import React, { Component, Fragment } from 'react'
import _ from 'lodash'

import axios from 'axios'
import axiosConfig from '../../config/axios'

import { withUserInfo } from '../../utils/hocs'
import PDCarousel from './PDCarousel'
import PDCarouselStarred from './PDCarouselStarred'

class PDSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      starred: undefined,
      categories: undefined
    }
  }

  componentDidMount() {
    const { history, userInfo } = this.props
    if (!userInfo || !userInfo.pdPermission) {
      history.push("/error")
    } else {
      window.scrollTo(0, 0)

      // Get assets from backend
      this.getAssets()
    }
  }

  async getAssets() {
    try {
      const axiosInstance = axios.create(axiosConfig)
      const response = await axiosInstance.get('pd')
      if(response && response.data &&
        response.data.categories && response.data.categories.length &&
        response.data.assets && response.data.assets.length) {
        const categories = response.data.categories.map(category => ({
          ...category,
          assets: _.sortBy(response.data.assets.filter(asset => asset.parent === category.uuid).map(asset => ({ ...asset, category: category.title })), ['order'])
        }))
        const starred = {
          uuid: 'starred',
          name: 'starred',
          assets: _.sortBy(response.data.assets.filter(starredAsset => starredAsset.starred>0).map(asset => ({
            ...asset,
            category: _.get(response.data.categories.find(category => category.uuid === asset.parent), 'title', ''),
            order: asset.starred
          })), ['order'])
        }
        // Sort categories by name
        this.setState({ starred, categories: _.sortBy(categories, ['name']) })
      }
    } catch (err) {
      this.setState({ starred: undefined, categories: undefined })
    }
  }

  render() {
    const { starred, categories } = this.state
    return (
      <div className="professional-development">
        {
          starred &&
            <PDCarouselStarred
              className="mb-5"
              title="starred"
              assets={starred.assets}
            />
        }
        {
          categories && categories.map(category => (
            <Fragment key={category.uuid}>
              <PDCarousel
                className="my-5"
                title={category.title}
                assets={category.assets}
              />
            </Fragment>
          ))
        }
      </div>
    )
  }
}

export default withUserInfo(PDSection)
