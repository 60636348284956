import React, { Component, Fragment } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Jumbotron as JumbotronRT, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import PromotionTitle from '../PromotionTitle/PromotionTitle'
import envSettings from '../../globals/envSettings'
import { ContentConsumer } from '../../language'
import userRoles from"../../globals/userRoles"

import cx from 'classnames'

class Jumbotron extends Component {

  state = { scroll: 0 }

  componentDidMount() {
    window.scrollTo(0,0)
    window.addEventListener('scroll', this.handleScroll);
    const el = document.getElementById('jumbotronOUP');
    const elPos = el ? el.getBoundingClientRect().bottom : Math.pow(10, 1000)

    this.setState({ hidePoint: elPos })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => this.setState({ scroll: window.pageYOffset });

  render() {

    const { scroll, hidePoint } = this.state,
      fixedPoint = scroll > hidePoint

    // MBQ PROMOTIONS
    //const { info, params: { titleid, unitid }, units } = this.props
    const { info, params: { titleid, unitid }, units, role, countryId } = this.props
    // MBQ PROMOTIONS

    const hasUnit = units.find(data => data.uuid === unitid)

    return (
      <Fragment>
        <ContentConsumer>
          {({ content }) => (content && (
            <Breadcrumb className="breadcrumbOUP position-relative" >
              <BreadcrumbItem tag={Link} to={`/`} className="breadcrumbOUP__item">
                {content.titledetail.my_library}
              </BreadcrumbItem>
              {hasUnit ? <BreadcrumbItem
                tag={Link}
                to={{ pathname: `/title/${titleid}/unit/all/cat/all/subcat/all`, search: "?initTab=0" }}
                className="breadcrumbOUP__item"
              >
                {info.name}
              </BreadcrumbItem>
                : <BreadcrumbItem className="breadcrumbOUP__item">{info.name}</BreadcrumbItem>
              }
              {hasUnit &&
                <BreadcrumbItem className="breadcrumbOUP__item">{hasUnit.name}</BreadcrumbItem>
              }
            </Breadcrumb>
          ))}
        </ContentConsumer>

        {fixedPoint &&

          <JumbotronRT className="jumbotronOUP jumbotronOUP--fixed">
            <Row>
              {info.name && (
                <Col xl="1" md="2" xs="3" className="d-flex justify-content-start " >
                  <NavLink className="jumbotronOUP__img" to={{ pathname: `/title/${titleid}/unit/all/cat/all/subcat/all`, search: "?initTab=0" }}>
                    <img className="rounded" src={`${envSettings.API_BASEURL}${info.cover}`} alt={info.name} />
                  </NavLink>
                </Col>
              )}
              <Col xl="11" md="10" xs="9" className="d-flex" >
                <div className="align-self-center my-2">
                  <NavLink to={{ pathname: `/title/${titleid}/unit/all/cat/all/subcat/all`, search: "?initTab=0" }}>
                    <h2>{info.name}</h2>
                  </NavLink>

                  <hr className="my-2" />
                  <p className="lead">
                    {info.subject && info.subject.map((data, idx) =>
                      <span key={idx}>{data.name}</span>
                    )}
                  </p>
                </div>
              </Col>
            </Row>
          </JumbotronRT>
        }

          <JumbotronRT id="jumbotronOUP" className={cx({jumbotronOUP: true, invisible: fixedPoint})}>
            <Row>
              {info.name && (
                <Col xl="1" md="2" xs="3" className="d-flex justify-content-start " >
                  <NavLink className="jumbotronOUP__img" to={{ pathname: `/title/${titleid}/unit/all/cat/all/subcat/all`, search: "?initTab=0" }}>
                    <img className="rounded" src={`${envSettings.API_BASEURL}${info.cover}`} alt={info.name} />
                  </NavLink>
                </Col>
              )}
              <Col xl="11" md="10" xs="9" className="d-flex" >
                <div className="align-self-center my-2">
                  <NavLink to={{ pathname: `/title/${titleid}/unit/all/cat/all/subcat/all`, search: "?initTab=0" }}>
                    <h2>{info.name}</h2>
                  </NavLink>

                  <hr className="my-2" />
                  <p className="lead">
                    {info.subject && info.subject.map((data, idx) =>
                      <span key={idx}>{data.name}</span>
                    )}
                  </p>
                </div>
              </Col>
            </Row>
          </JumbotronRT>

        {/* MBQ PROMOTIONS */}
        {
          [userRoles.TEACHER, userRoles.TEACHER_FAKE, userRoles.TEACHER_DEMO].includes(role)
          && countryId === envSettings.COUNTRY_ESP_ID
          && <PromotionTitle role={role} idTitle={info.idSim} isAdoption={!info.demo} />
        }
        {/* MBQ PROMOTIONS */}

      </Fragment>
    );
  }
}

export default Jumbotron;
