import React, { Component, Fragment } from 'react'
import { AuthConsumer } from 'react-check-auth'
import { ContentConsumer } from '../../language'
import { Container, Row, Col, Progress, Alert, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import UserFinder from './components/UserFinder'
import UserData from './components/UserData'
import validator from 'validator'
import axios from 'axios'
import axiosConfig from '../../config/axios'
import userRoles from '../../globals/userRoles'

class UserAdministration extends Component {

  constructor(props) {
    super(props)
    this.state = {
      mail: '',
      user: null,
      error: null,
      loading: true
    }
  }

  componentDidMount() {
    if (this.props.userInfo.impersonating) {
      const axiosInstance = axios.create(axiosConfig)
      axiosInstance.post('/user/actions/impersonate')
        .then(response => {
          window.location.href = '/app'
        }).catch(error => {
          //console.error(error)
          this.handleError(this.props.content.sharedTerms.ERROR_TEXT)
        })
    } else {
      this.toggleLoading()
    }
  }

  isValidUser = user => {
    if (!user
      || !user.id || !validator.isInt(String(user.id))
      || !user.schoolId || !validator.isInt(String(user.schoolId))
      || !user.countryId || !validator.isInt(String(user.countryId))
      || !user.mail || !validator.isEmail(user.mail)
      || !user.role || !validator.isIn(user.role, [userRoles.TEACHER, userRoles.TEACHER_DEMO, userRoles.TEACHER_FAKE]))
      return false
    return true
  }

  toggleLoading = () => this.setState({ ...this.state, loading: !this.state.loading, user: null, error: null })

  handleSuccess = data => this.setState({ ...this.state, loading: false, user: data, error: null })

  handleError = (error, data) => this.setState({ ...this.state, loading: false, user: data, error, mail: '' })

  handleChangeMail = event => {
    const mail = event.target.value.trim().toLowerCase()
    this.setState({ mail, error: null })
  }

  handleSubmitMail = event => {
    event.preventDefault()
    const mail = this.state.mail
    if (mail) {
      this.toggleLoading()
      if (validator.isEmail(mail) && mail !== this.props.userInfo.mail) {
        const axiosInstance = axios.create(axiosConfig)
        axiosInstance.post('/user/actions/search', { mail })
          .then(response => {
            const user = response.data
            this.isValidUser(user) ? this.handleSuccess(user) : this.handleError('Invalid mail')
          }).catch(error => {
            //console.error(error)
            this.handleError(error.response && error.response.status === 400 ? 'Invalid mail' : 'User not found')
          })
      } else {
        this.handleError('Invalid mail')
      }
    }
  }

  handleImpersonate = event => {
    event.preventDefault()
    const user = this.state.user
    this.toggleLoading()
    const axiosInstance = axios.create(axiosConfig)
    axiosInstance.post('/user/actions/impersonate', { userId: user.id })
      .then(response => {
        window.location.href = '/app'
      }).catch(error => {
        //console.error(error)
        this.handleError(this.props.content.sharedTerms.ERROR_TEXT, user)
      })
  }

  handleRender() {
    const { userInfo, content } = this.props
    const { mail, user, error, loading } = this.state
    let node = null
    if (loading) node = <Progress animated color="primary" value="100">Loading</Progress>
    else if (userInfo.impersonating && error) node = <Alert color="danger">{content.sharedTerms.ERROR_TEXT}</Alert>
    else if (!user) node = <UserFinder mail={mail} onChangeMail={this.handleChangeMail} onSubmitMail={this.handleSubmitMail} error={error} content={content} />
    else node = <UserData user={user} onImpersonate={this.handleImpersonate} error={error} content={content} />
    return node
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb>
          <BreadcrumbItem active>User Impersonation</BreadcrumbItem>
        </Breadcrumb>
        <Container fluid={true} className='user-administration'>
          <Row>
            <Col sm='12' className='py-5'>{this.handleRender()}</Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default () => (
  <AuthConsumer>
    {({ userInfo }) => (
      <ContentConsumer>
        {
          ({ content = {} }) => (
            (content && <UserAdministration userInfo={userInfo} content={content} />)
          )}
      </ContentConsumer>
    )}
  </AuthConsumer>
)
