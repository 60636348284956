import React, { Component } from 'react'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import {
  Container, Row, Col, Form, FormGroup, FormFeedback,
  Nav, NavItem, NavLink, Label, Input, CustomInput, Alert
} from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'
import validator from 'validator'
import _ from 'lodash'
import axios from 'axios'

import axiosConfig from '../../config/axios'
import { ContentConsumer } from '../../language'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
/* import ButtonThirdParties from '../ButtonThirdParties/ButtonThirdParties' */
import envSettings from '../../globals/envSettings'
import { withUserInfoReloadable } from '../../utils/hocs'
import ModalAccept from '../ModalAccept/ModalAccept'
import ROLES from '../../globals/userRoles'
import { RecaptchaTerms } from '../Recaptcha/Recaptcha'
import platformId  from '../../globals/platforms'
import queryString from 'query-string'
import Modal2Buttons from '../Modal2Buttons/Modal2Buttons'
/*
import google from '../../assets/images/google.png'
import microsoft from '../../assets/images/microsoft.png'
*/
import svgGoogle from './google.svg'
import svgMicrosoft from './microsoft.svg'
import { Help } from'../Links/Links'
import { parseToReactElement } from '../../utils/html'
const axiosClient = axios.create(Object.assign({}, axiosConfig, { timeout: 20000 }))

class EditAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      country: _.get(props, 'userInfo.countryId', undefined),
      email: _.get(props, 'userInfo.mail', ''),
      name: _.get(props, 'userInfo.name', ''),
      surname1: _.get(props, 'userInfo.surname', ''),
      surname2: _.get(props, 'userInfo.surname2', ''),
      phone: _.get(props, 'userInfo.phone', ''),
      mktPrefs: !_.get(props, 'userInfo.mktPrefs', false),
      success: false,
      failure: false,
      password1: '',
      password2: '',
      changePassword: false,
      changePasswordErrors: {
        password1: false,
        password2: false
      },
      changeThirdParties: false,
      id: '',
      returnTo: '',
      routeOrigin: '',
      userPlatform: ''
    }

    this.state.errors = {
      name: this.state.name === '',
      surname1: this.state.surname1 === '',
      surname2: false,
      email: this.state.email === '',
      email_duplicated: false,
      phone: this.state.phone !== '' && (!validator.isNumeric(this.state.phone + '') || (this.state.phone.length !== 9))
    }

    if(this.state.country !== envSettings.COUNTRY_ESP_ID) {
      delete this.state.phone
      delete this.state.errors.phone
    }

    this.state.previous = _.pick(this.state, ['name', 'surname1', 'surname2', 'email', 'phone', 'mktPrefs'])
  }

  gotoHome = async () => {
    const { refreshAuth, history } = this.props
    await refreshAuth()
    history.push("/")
  }

  gotoOK = async () => {
    const { refreshAuth, history } = this.props
    await refreshAuth()
    history.push("/edit-account?error=SHOW-OK")
  }

  gotoDISONNECTOK = async () => {
    const { refreshAuth, history } = this.props
    await refreshAuth()
    history.push("/edit-account?error=SHOW-DISCONNECT-OK")
  }

  isValid = () => !_.compact(_.values(this.state.errors)).length && [ ROLES.TEACHER, ROLES.TEACHER_DEMO ].find(role => role === _.get(this.props, 'userInfo.role', undefined))

  isChangePasswordValid = () => !_.compact(_.values(this.state.changePasswordErrors)).length && this.state.password1 && this.state.password2 &&
    [ ROLES.TEACHER, ROLES.TEACHER_DEMO ].find(role => role === _.get(this.props, 'userInfo.role', undefined))

  submit = async () => {
    const { previous } = this.state

    if(this.isValid()) {
      const dataToPick = [ 'email', 'name', 'surname1', 'surname2', 'mktPrefs' ]
      if(this.state.country === envSettings.COUNTRY_ESP_ID) dataToPick.push('phone')

      const data = _.pick(this.state, dataToPick)

      if(!_.isEqual(previous, data)) {
        // This value has different meaning in backend so it has to be changed
        data.mktPrefs = !data.mktPrefs
        const token = await this.props.googleReCaptchaProps.executeRecaptcha('edit_account')
        data.token = token
        axiosClient.put('/user', data)
          .then((response) => {
            this.setState({ success: true })
          })
          .catch((error) => {
            const duplicatedEmail = _.get(error, "response.data.errors.duplicatedEmail", false)
            this.setState({
              failure: true,
              errors: {
                ...this.state.errors,
                email_duplicated: duplicatedEmail
              }
            })
          })
      } else {
        this.setState({ success: true })
      }
    } else {
      this.setState({ failure: true })
    }

  }

    handleInputChange = (event) => {
      // Limit input on certain fields
      const target = event.target
      const value = target.type === 'checkbox' ? target.checked : target.value
      const name = target.name
      const regexpAlphaWithSpaces = /^[A-ZÑÁÀÂÄÃÅÆÇÉÈÊËÍÌÏÎÓÒÔÖÕŒÚÙÛÜŸÝ \-'.ºª·]+$/i

      switch(name) {
        case "name":
        case "surname1":
          this.setState((prevState) => {
            return {
              [name]: (value === '' || validator.matches(value, regexpAlphaWithSpaces)) ? value : prevState[name],
              errors: {
                ...prevState.errors,
                [name]: (value.replace(/ /g, '').length < 2) || (value.length > 45)
              }
            }
          })
          break
        case "surname2":
          this.setState((prevState) => {
            return {
              [name]: (value === '' || validator.matches(value, regexpAlphaWithSpaces)) ? value : prevState[name],
              errors: {
                ...prevState.errors,
                [name]: (value !== '') && ((value.replace(/ /g, '').length < 2) || (value.length > 45))
              }
            }
          })
          break
        case "email":
          this.setState((prevState) => {
            return {
              email: value,
              errors: {
                ...prevState.errors,
                email: !validator.isEmail(value),
                email_duplicated: false
              }
            }
          })
          break
        case "phone":
          this.setState((prevState) => {
            return {
              phone:
                ((value === '') || validator.isNumeric(value + '')) && ((value + '').length < 10) ? value : prevState[name],
              errors: {
                ...prevState.errors,
                phone: (value.length < 9 && value.length > 0)
              }
            }
          })
          break
        default:
          this.setState({ [name]: value })
          break
      }
    }

    handleInputChangePassword= (event) => {
      const { name, value } = event.target

      const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])\S{6,}$/;

      switch(name) {
        case "password1":
          this.setState((prevState) => {
            return {
              [name]: value,
              changePasswordErrors: {
                ...prevState.changePasswordErrors,
                [name]: !validator.matches(value, regexPassword),
                password2: !!prevState.password2 && (prevState.password2 !== value)
              }
            }
          })
          break
        case "password2":
          this.setState((prevState) => {
            return {
              [name]: value,
              changePasswordErrors: {
                ...prevState.changePasswordErrors,
                [name]: this.state.password1 !== value
              }
            }
          })
          break
        default:
          this.setState({ [name]: value })
      }
    }

    changePasswordSubmit = async () => {
      if(this.isChangePasswordValid()) {
        this.setState({ changePassword: false })
        const axiosClient = axios.create(axiosConfig)
        axiosClient.put('/disconnect-third-parties', { password: this.state.password1 })
        .then((response) => {
          this.props.history.push ('/edit-account?error=DISCONNECT-OK' )
        })
        .catch((error) => {
          this.props.history.push ('/edit-account?error=DISCONNECT-KO' )
        })
      }
    }

    changeThirdParties = ( id, returnTo, routeOrigin, userPlatform ) => {
      this.setState({ changeThirdParties: false })
      window.location.href = `${envSettings.API_BASEURL}/third-parties/${id}?returnTo=${returnTo}&routeOrigin=${routeOrigin}&userPlatform=${userPlatform}`
    }

    render() {
        const { error } = queryString.parse( this.props.location.search )
			  const svgImageData={'google':svgGoogle,'microsoft':svgMicrosoft}
        const thirdParties = this.props.userInfo.platformId === platformId.GOOGLE ? "google" : "microsoft"
        if( error && error === 'OK' ){
          this.gotoOK()
          return(<></>)
        }
        if( error && error === 'DISCONNECT-OK' ){
          this.gotoDISONNECTOK()
          return(<></>)
        }

        return (
          <ContentConsumer>
          {({ content }) => (content && (
            <Container fluid={false} className="editAccount">
              <Row>
                <Col><h1>{content.useraccount.edit_account_title}</h1></Col>
              </Row>
              {error && error==='SHOW-OK' && (
                <Alert className="oupe-banner" color="success">
      {content.useraccount.texto_linked}
    </Alert>
              )}
              {error && error==='SHOW-DISCONNECT-OK' && (
                <Alert className="oupe-banner" color="success">
                {content.useraccount.texto_desvinculado_ok}
                </Alert>
              )}
              {error && error==='SAME-PLATFORM' && (
                <Alert className="oupe-banner" color="danger">You are already linked with this platform.</Alert>
              )}
              {error && error==='NOT-LDAP' && (
                <Alert className="oupe-banner" color="danger">You must first unlink from the other platform.</Alert>
              )}
              {error && error==='OTHER-USER' && (
                <Alert className="oupe-banner" color="danger">
                {parseToReactElement(content.useraccount.existe_email)}
                </Alert>
              )}
              { error &&
                error !== 'OTHER-USER' &&
                error !== 'SAME-PLATFORM' &&
                error !== 'NOT-LDAP' &&
                error !== 'OTHER-USER' &&
                error !== 'SHOW-OK' &&
                error !== 'SHOW-DISCONNECT-OK' &&
                ( <Alert className="oupe-banner" color="danger">
                    {content.useraccount.texto_error_inesperado}
                  </Alert> )
              }
              <Row>
                <Col sm={{size:6, offset:3}}>
                  <Form noValidate>
                    <h5>{content.useraccount.access_data}</h5>
                    <FormGroup>
                      <Label for="email">{content.useraccount.email}</Label>
                      <Input
                        id="email"
                        type="email"
                        name="email"
                        placeholder={content.useraccount.email}
                        autoComplete="off"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        valid={!(this.state.errors.email || this.state.errors.email_duplicated)}
                        invalid={(this.state.errors.email || this.state.errors.email_duplicated)}
                        disabled={this.props.userInfo.platformId!==platformId.LDAP}
                        />
                        <FormFeedback>{
                          this.state.errors.email ?
                            content.useraccount.error_email :
                            content.useraccount.error_email_duplicated}
                        </FormFeedback>
                    </FormGroup>
                    {( this.props.userInfo.platformId===platformId.LDAP ) && (
                    <Nav className="editAccount__nav">
                      <NavItem>
                        <NavLink tag={ Link } to="/change-password">{content.useraccount.password_change_title}</NavLink>
                        </NavItem>
                    </Nav>)}

              <Row>
                      {this.props.userInfo.platformId!==platformId.LDAP && (
                      <Col>
                        <ButtonPremium id={`${thirdParties}`}
                          className="img-container"
                          size="lg"
                          onClick={() => this.setState({ changePassword: true })}>
                        <img src={svgImageData[this.props.userInfo.platformId===platformId.GOOGLE?"google":"microsoft"]} alt={`${this.props.thirdParties}`}/>
                        {content.useraccount.disconnect_from} {this.props.userInfo.platformId===platformId.GOOGLE?"Google":"Microsoft"}
                        </ButtonPremium>
                      </Col>
                      )}
                      {( this.props.userInfo.platformId===platformId.LDAP ||
                          this.props.userInfo.platformId===platformId.MICROSOFT ) && (
                        <Col>
                        <ButtonPremium id="google"
                          className="img-container"
                          size="lg"
                          disabled={this.props.userInfo.platformId!==platformId.LDAP}
                          onClick={() => this.setState({
                            changeThirdParties: true,
                            id: "google",
                            returnTo: 'http://oxfordpremium.local.oupe.es/app/edit-account',
                            routeOrigin: "EDITACCOUNT",
                            userPlatform: envSettings.PLATFORM_GOOGLE_ID
                          })}>
                            <img src={svgImageData["google"]} alt="google" />
                            {content.useraccount.connect_with} Google
                        </ButtonPremium>
                      </Col>
                        )}
                      {( this.props.userInfo.platformId===platformId.LDAP ||
                          this.props.userInfo.platformId===platformId.GOOGLE ) && (
                      <Col>
                        <ButtonPremium id="microsoft"
                          className="img-container"
                          size="lg"
                          disabled={this.props.userInfo.platformId!==platformId.LDAP}
                          onClick={() => this.setState({
                            changeThirdParties: true,
                            id: "microsoft",
                            returnTo: 'http://oxfordpremium.local.oupe.es/app/edit-account',
                            routeOrigin: "EDITACCOUNT",
                            userPlatform: envSettings.PLATFORM_MICROSOFT_ID
                          })}>
                            <img src={svgImageData["microsoft"]} alt="microsoft" />
                            {content.useraccount.connect_with} Microsoft
                        </ButtonPremium>
                      </Col>
                      )}
              </Row>

                    <h5>{content.useraccount.teacher_data}</h5>
                    <div className="school-info">
                      <div className="school-info-title">{ content.changeschool.changeschool_heading_current_text }</div>
                      <div className="school-info-school">{ this.props.userInfo.schoolName} {this.props.userInfo.mec?`(`+this.props.userInfo.mec+`)`:null}</div>
                    </div>

                    { (this.props.userInfo.role === ROLES.TEACHER) &&
                      <Nav className="change-school-link__nav">
                        <NavItem>
                          <NavLink tag={Link} to="/change-school">
                            { content.changeschool.changeschool_title }
                          </NavLink>
                        </NavItem>
                      </Nav>
                    }

                    <FormGroup>
                      <Label for="name">{content.useraccount.name}</Label>
                      <Input
                        id="name"
                        type="text"
                        name="name"
                        placeholder={content.useraccount.name}
                        autoComplete="off"
                        value={this.state.name}
                        onChange={this.handleInputChange}
                        valid={!this.state.errors.name}
                        invalid={this.state.errors.name}
                      />
                      <FormFeedback>{content.useraccount.error_name}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="surname1">{content.useraccount.surname1}</Label>
                      <Input
                        id="surname1"
                        type="text"
                        name="surname1"
                        placeholder={content.useraccount.surname1}
                        autoComplete="off"
                        value={this.state.surname1}
                        onChange={this.handleInputChange}
                        valid={!this.state.errors.surname1}
                        invalid={this.state.errors.surname1}
                      />
                      <FormFeedback>{content.useraccount.error_surname1}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="surname2">{content.useraccount.surname2}</Label>
                      <Input
                        id="surname2"
                        type="text"
                        name="surname2"
                        placeholder={content.useraccount.surname2}
                        autoComplete="off"
                        value={this.state.surname2}
                        onChange={this.handleInputChange}
                        valid={!this.state.errors.surname2}
                        invalid={this.state.errors.surname2}
                      />
                    </FormGroup>
                    {/* Only show phone for Spanish teachers */}
                    {
                      (this.state.country === envSettings.COUNTRY_ESP_ID) &&
                        <FormGroup>
                          <Label for="phone">{content.useraccount.phone}</Label>
                          <Input
                            id="phone"
                            type="phone"
                            name="phone"
                            placeholder={content.useraccount.phone}
                            autoComplete="off"
                            value={this.state.phone}
                            onChange={this.handleInputChange}
                            valid={!this.state.errors.phone}
                            invalid={this.state.errors.phone}
                          />
                          <FormFeedback>{content.useraccount.error_phone}</FormFeedback>
                        </FormGroup>
                    }
                    <CustomInput
                      type="checkbox"
                      id="mktPrefs"
                      name="mktPrefs"
                      label={content.useraccount.mkt_prefs}
                      checked={this.state.mktPrefs}
                      onChange={this.handleInputChange}
                    />
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col sm={{size: 6, offset:3}}>
                  <Container>
                    <Row className="mt-2">
                      <Col xs="12" md={{size: 10, offset: 1}} lg={{size: 4, offset:1}} className="mt-3">
                        <ButtonPremium onClick={this.gotoHome}>
                          {content.sharedTerms.CANCEL_BUTTON_TEXT}
                        </ButtonPremium>
                      </Col>
                      <Col xs="12" md={{size: 10, offset: 1}} lg={{size: 4, offset:1}} className="mt-3">
                        <ButtonPremium onClick={this.submit} disabled={!this.isValid()} primary>
                          {content.useraccount.submit}
                        </ButtonPremium>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <Row className="my-3">
                <Col md={{size:6, offset:3}}><RecaptchaTerms /></Col>
              </Row>
              <ModalAccept isOpen={this.state.failure} toggle={() => this.setState({failure: false})} header={content.useraccount.edit_account_title}>
                {content.useraccount.edit_account_failure}
              </ModalAccept>
              <ModalAccept isOpen={this.state.success} toggle={this.gotoHome} header={content.useraccount.edit_account_title}>
                {content.useraccount.edit_account_success}
              </ModalAccept>
              <Modal2Buttons
                isOpen={ this.state.changePassword }
                toggle={() => this.setState({ changePassword: false })}
                header={content.useraccount.header_new_password}
                primaryButtonLabel={content.useraccount.button_aceptar_new_password}
                secondaryButtonLabel={content.useraccount.button_cancelar_new_password}
                primaryButtonOnClick={ () => this.changePasswordSubmit() }
                secondaryButtonOnClick={ () => this.setState({ changePassword: false })}>
              {content.useraccount.texto_desvincular.replace('Microsoft|Google',this.props.userInfo.platformId===platformId.GOOGLE?"Google":this.props.userInfo.platformId===platformId.MICROSOFT?"Microsoft":"")}
                <Form noValidate>
                  <FormGroup>
                    <Label for="password1">{content.useraccount.password_new}</Label>
                    <Input
                      id="password1"
                      type="password"
                      name="password1"
                      placeholder={content.useraccount.password_new}
                      autoComplete="off"
                      value={this.state.password1}
                      onChange={this.handleInputChangePassword}
                      valid={!this.state.changePasswordErrors.password1 && this.state.password1!==''}
                      invalid={this.state.changePasswordErrors.password1}
                    />
                    <FormFeedback>{ content.useraccount.password_invalid }</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="password2">{ content.useraccount.password_confirmation }</Label>
                    <Input
                      id="password2"
                      type="password"
                      name="password2"
                      placeholder={content.useraccount.password_confirmation}
                      autoComplete="off"
                      value={this.state.password2}
                      onChange={this.handleInputChangePassword}
                      valid={!this.state.changePasswordErrors.password2 && this.state.password2!==''}
                      invalid={this.state.changePasswordErrors.password2}
                    />
                    <FormFeedback>{ content.useraccount.password_confirmation_invalid }</FormFeedback>
                  </FormGroup>
                  <Row className="mt-3">
                    <Col className="text-center">
                      <Help/>
                    </Col>
                  </Row>
                </Form>
              </Modal2Buttons>
              <Modal2Buttons
                isOpen={ this.state.changeThirdParties }
                toggle={() => this.setState({ changeThirdParties: false })}
                header={this.state.id==="google"?content.useraccount.connect_with_header+" Google":this.state.id==="microsoft"?content.useraccount.connect_with_header+" Microsoft":""}
                primaryButtonLabel={content.useraccount.button_aceptar_vincular}
                secondaryButtonLabel={content.useraccount.button_cancelar_vincular}
                primaryButtonOnClick={ () => this.changeThirdParties(this.state.id, this.state.returnTo, this.state.routeOrigin, this.state.userPlatform) }
                secondaryButtonOnClick={ () => this.setState({ changeThirdParties: false })}>
                {parseToReactElement(content.useraccount.texto_pre_vincular.replace(/Microsoft\|Google/g,this.state.id==="google"?"Google":this.state.id==="microsoft"?"Microsoft":""))}
                <Row className="mt-3">
                  <Col className="text-center">
                    <Help/>
                  </Col>
                </Row>
              </Modal2Buttons>
            </Container>
          ))}
          </ContentConsumer>
      )
    }
}

export default withRouter(withGoogleReCaptcha(withUserInfoReloadable(EditAccount)))
