import React from 'react'

import { withContent } from '../../utils/hocs'

import RedeemCoupon from './RedeemCoupon'
import DigitalLibrary from './DigitalLibrary'

import './Coupons.css'

const Coupons = (props) => {
  const { token, action, orderId, result } = props

  if (!token && (!action || action !== 'purchase')) return <RedeemCoupon />

  return <DigitalLibrary token={token} orderId={orderId} purchase={result} />
}

export default withContent(Coupons)