import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { AuthConsumer } from 'react-check-auth'
import NewsModal from './NewsModal'
import { Card, CardImg, CardText, CardBody, CardTitle } from 'reactstrap'
import cx from 'classnames'
import { parseToReactElement } from '../../utils/html'
import { ContentConsumer } from '../../language'

class NewsItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }))
    }

    render() {
      const { news } = this.props

      // If the news doesn't have image nor excerpt the bgcolor must be category's color
      const cardClass = cx({
        newsItem : true,
        [`newsItem__${news.categories[0].color}`]: !news.image && !news.excerpt
      })

      return (
        <AuthConsumer>
        {({userInfo, isLoading, error}) => {
          if(isLoading) return []
          const locale = (userInfo && userInfo.language && userInfo.language.replace('_','-')) || 'es-es'
          return (
            <ContentConsumer>
              {({ content = {} }) => {
                return (
                  <Fragment>
                    <Card className={ cardClass } onClick={this.toggle}>
                      <CardTitle className={cx("category", "py-1 m-0 border-bottom", `category__${news.categories[0].color}`)}>{ _.get(content.news, `news_category_${news.categories[0].slug}`, news.categories[0].name) }</CardTitle>
                      <div className="news-card-img-container border-bottom text-center embed-responsive embed-responsive-16by9">
                        <CardImg className="img-fluid embed-responsive-item news-card-img rounded-0 m-auto" width="100%" height="100%" src={news.image.url} alt={news.image.alt} />
                      </div>
                      <CardBody>
                        <CardTitle className="news-title my-3">{parseToReactElement(news.title)}</CardTitle>
                        <CardText className="date">{news.date && news.date.toLocaleDateString(locale, { year: 'numeric', month: '2-digit', day: '2-digit' })}</CardText>
                      </CardBody>
                    </Card>
                    <NewsModal isOpen={this.state.modal} toggle={this.toggle} news={news} content={content} locale={locale} />
                  </Fragment>
                )
              }
            }
            </ContentConsumer>)
        }}
        </AuthConsumer>
    )
  }
}
export default NewsItem
