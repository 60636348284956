import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { libraryTypes } from '../../globals/title'
import { withContent } from '../../utils/hocs'
import SliderItem from './SliderItem'

class BooksSlider extends Component {
  constructor(props) {
    super(props)
    this.slider = null
  }

  previous = (e) => {
    e.preventDefault()
    this.slider.slickPrev()
  }
  next = (e) => {
    e.preventDefault()
    this.slider.slickNext()
  }

  render() {
    const { assets, content, hasLibrary, isLibrarySelected, switchLibrary } = this.props
    const settings = {
      dots: false,
      arrows: false,
      infinite: false,
      slidesToShow: 8,
      slidesToScroll: 8,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            infinite: false,
            slidesToShow: 8,
            slidesToScroll: 8
          }
        },
        {
          breakpoint: 992,
          settings: {
            infinite: false,
            slidesToShow: 6,
            slidesToScroll: 6
          }
        },
        {
          breakpoint: 768,
          settings: {
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 576,
          settings: {
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]
    }
    if(!assets || !assets.length) return null
    else return (
      <div className="slider">
        <Row className="d-flex">
          <Col xs="12" md="6" className="mb-3" >
            <h1 className="mb-0 slider-title text-uppercase">{content.mylibrary.mylibrary_label_library}
              <span className="totalNumber ml-1">{assets.length}</span>
              <Link
                className="edit ml-2 text-capitalize"
                to="/edit-library"
              >
                {content.mylibrary.mylibrary_button_edit}
              </Link>
            </h1>
          </Col>

          <Col xs="12" md="6" className="d-flex justify-content-md-end justify-content-center" >
            <Nav className="newsFeed__navTab flex-column flex-md-row" tabs>
              { [libraryTypes.ADOPTED, libraryTypes.DEMO, libraryTypes.RESOURCES].map((libraryType) => (
                hasLibrary(libraryType) && (
                <NavItem key={libraryType}>
                  <NavLink
                    className={`newsFeed__navTab__link ml-md-4 p-2 text-center text-md-left text-uppercase ${isLibrarySelected(libraryType) ? 'active' : ''}`}
                    onClick={() => switchLibrary(libraryType)}>
                    { _.get(content, `mylibrary.mylibrary_librarytype_${libraryType}`) }
                  </NavLink>
                </NavItem>
              )))}
            </Nav>
          </Col>
          <Col className="text-right" xs="12">
            <div className="d-inline slider-nav mr-2" onClick={this.previous}>&lt; { content.assets.previous }</div>
            <div className="d-inline slider-nav ml-2" onClick={this.next}>{ content.assets.next } &gt;</div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Slider
              ref={c => this.slider = c}
              {...settings}>
              {/* {assets.map(asset => <Fragment key={asset.nodeRef}><Container className="pl-0 my-1"><SliderItem {...asset} /></Container> </Fragment>)} */}
              {assets.map(asset => <Fragment key={asset.nodeRef}><Container className="pl-2 pb-3 my-1"><SliderItem {...asset} /></Container> </Fragment>)}
            </Slider>
          </Col>
        </Row>
      </div>
    )
  }
}

BooksSlider.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.shape({
    idTitulo: PropTypes.number,
    nodeRef: PropTypes.string,
    name: PropTypes.string,
    stage: PropTypes.string,
    coverURL: PropTypes.string,
    adoption: PropTypes.number,
    /* MBQ PROMOTIONS */
    //titleType: PropTypes.string,
    titleType: PropTypes.number,
    promotion: PropTypes.number,
    /* MBQ PROMOTIONS */
    origin: PropTypes.string
  }))
}

export default withContent(BooksSlider)
