import React from 'react'
import { Progress } from 'reactstrap'
import { ContentConsumer } from '../../language'
import PDCatalogue from './PDCatalogue'

const PDTabAnnouncement = props => (
  <ContentConsumer>
    {({ content = {} }) => (
      content && (
        props.loading ?
          (
            <Progress animated color="primary" value="100">{content.pdcourses.loading}</Progress>
          )
          :
          (
            <PDCatalogue {...props} className="pdTabAnnouncement" />
          )
      )
    )}
  </ContentConsumer>
)

export default PDTabAnnouncement