import React, { Component } from 'react'
import axios from 'axios'

import axiosConfig from '../../config/axios'
import userRoles from '../../globals/userRoles'
import { withUserInfo } from '../../utils/hocs'

class NotificationBell extends Component {
  constructor(props) {
    super(props)
    this.state = {
      enabled: true,
      newNotifications: false
    }
  }

  componentDidMount() {
    // Only available for teacher, demo and fake roles
    const { userInfo } = this.props
    if(userInfo
       && [
         userRoles.TEACHER,
         userRoles.TEACHER_DEMO,
         userRoles.TEACHER_FAKE
       ].includes(userInfo.role)) {
      const axiosInstance = axios.create(axiosConfig)
      axiosInstance.get('/user/newNotifications')
        .then(({ data }) => {
          this.setState({ enabled: true, newNotifications: !!data.newNotifications })
        })
        .catch(() => this.setState({ enabled: false }))
    } else this.setState({ enabled: false })
  }

  render() {
    const { enabled, newNotifications } = this.state

    if(!enabled) return null
    return (
      <div className="notification-bell">
        <span className="glyphicon glyphicon-bell" aria-hidden="true" />
        { newNotifications && <span className="notification-badge" />}
      </div>
    )
  }
}

export default withUserInfo(NotificationBell)
