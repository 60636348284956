import React from 'react'

const CheckBox = props => {

  const { id, checked, handleCheckBox, disabled } = props

  return (
    <input
      className="form-check-input"
      name={id}
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={(event) => handleCheckBox(event)}
    />
  )
}

export default CheckBox
