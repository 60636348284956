import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash'
import cx from 'classnames'
import { Container, Table } from 'reactstrap'
import axios from 'axios'

import axiosConfig from '../../config/axios'
import { format } from '../../utils/string'
import { parseToReactElement } from '../../utils/html'
import { formatStrDateToLocaleDateShort } from '../../utils/date'
import { withContent, withUserInfo } from '../../utils/hocs'
import envSettings from '../../globals/envSettings'
import userRoles from '../../globals/userRoles'

class NotificationsPage extends Component {
  constructor(props) {
    super(props)
    this.state = { notifications: [] }
  }

  componentDidMount() {
    const { locale, history } = this.props
    const axiosInstance = axios.create(axiosConfig)
    // Only available for teacher, demo and fake roles
    const { userInfo } = this.props
    if(userInfo
       && [
         userRoles.TEACHER,
         userRoles.TEACHER_DEMO,
         userRoles.TEACHER_FAKE
       ].includes(userInfo.role)) {
      axiosInstance.get('/user/notifications')
        .then(({ data: notifications }) => {
          if(notifications) {
            const sortedNotifications = _.reverse(_.sortBy(notifications, ['date'])).map((notification) => ({
              key: `${notification.titleUuid}${notification.unitUuid}`,
              url: `title/${notification.titleUuid}/unit/${notification.unitUuid}/cat/all/subcat/all?new&initTab=0`,
              unit: _.get(notification, 'unitName', ''),
              title: _.get(notification, 'titleName', ''),
              newfromlogin: notification.newfromlogin,
              date: formatStrDateToLocaleDateShort(notification.date, locale)
            }))
            this.setState({ notifications: sortedNotifications })
          } else this.setState({ notifications: [] })
        })
        .catch(() => history.push('/error'))
    } else history.push('/error')
  }

  render() {
    const { content } = this.props
    const { notifications } = this.state
    return (
      <Container className="notifications-page pt-5">
        <h1>{ content.notifications.notifications_title }</h1>
        <h2 className="mt-3">{ format(content.notifications.notifications_subtitle, envSettings.RESOURCE_NEW_THRESHOLD) }</h2>
        <div>
          { parseToReactElement(content.notifications.notifications_clarification) }
        </div>
        <hr className="my-3" />
        <Table borderless>
          <tbody>
            {
              notifications.map(({ key, title, unit, url, date, newfromlogin }) => (
                <tr key={key} className={cx({ 'font-weight-bold': newfromlogin })}>
                  <td className="px-0"><Link to={url}>{ format(content.notifications.notification_unit, unit, title) }</Link></td>
                  <td className="text-right px-0">{ date }</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Container>
    )
  }
}

export default withRouter(withContent(withUserInfo(NotificationsPage)))
