import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import cx from 'classnames'
import { Row, Col, CustomInput, Input, FormGroup } from 'reactstrap'
import unorm from 'unorm'
import axios from 'axios'

import axiosConfig from '../../config/axios'
import Units from '../Units/Units'
import Categories from '../Categories/Categories'
import ResourceList from '../ResourceList/ResourceList'
import Audio from './components/Audio'
import Video from './components/Video'
import PDFViewer from './components/PDFViewer'
import NoViewer from './components/NoViewer'
import SearchInput from '../SearchInput/SearchInput'
import CMSContent from '../CMSContent/CMSContent'
import { withContent, withUserInfo } from '../../utils/hocs'
import { getLocale } from '../../language'
import NeedHelpButton from '../Buttons/NeedHelpButton'

// Settings constants
import envSettings from '../../globals/envSettings'

// MBQ Edition-language filter for internal user resources
import userRoles from '../../globals/userRoles'
import { unitIds as UNITS } from '../../globals/title'

const axiosClient = axios.create(Object.assign({}, axiosConfig, { timeout: 20000 }))

class Resources extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      searchInput: '',
      searchInputDebounced: '',
      showOnlyNew: !!_.get(props, 'params.showOnlyNew', false),
      ShowLightBox: false,
      filteredResources: [],
      shownResources: [],
      lightBoxData: {
        component: null,
        showZoom: false,
        data: null
      },
      // MBQ Edition-language filter for internal user resources
      edition: -1,
      edtionObj: null,
      language: -1,
      searchEdLangOn: true
    }
  }

  // MBQ Edition-language filter for internal user resources
  handleEditionChange = event => {
    const edition = this.props.editions.find(item => (item.id + '') === event.target.value)

    const newState = {
      edition: edition ? edition.id : -1,
      edtionObj: edition || null,
      language: -1
    }

    let reload = false

    this.setState((state, props) => {
      if (state.language != null && state.language >= 0) {
        reload = true
      }
      return ({ ...newState })
    }, () => {
      if (reload) {
        this.searchResources()
      }
    })
  }

  componentDidMount() {
    const { params: { unitid, catid, subcatid }, resources } = this.props
    this.filterResources(resources, filteredResources => {
      this.validateUrl(filteredResources, unitid, catid, subcatid)
    })
  }

  // MBQ Edition-language filter for internal user resources
  //setResults = filteredResources => this.setState({ filteredResources, shownResources: filteredResources }, this.searchResources)
  setResults = (filteredResources, searchEdLangOn) => this.setState({ filteredResources, shownResources: filteredResources, searchEdLangOn }, this.searchResources)

  validateUrl = (filteredResources, unitid, catid, subcatid) => {

    const hasUnit = filteredResources.find(data => data.unitUuid === unitid) || null

    // MBQ Edition-language filter for internal user resources
    const searchEdLangOn = this.props.userInfo.role === userRoles.USER_INTERNAL && unitid &&
      unitid.toLowerCase() !== UNITS.FAVOURITES &&
      (
        unitid.toLowerCase() === UNITS.ALL ||
        (this.props.units && this.props.units.find(unit => unit.uuid === unitid && unit.name.toLowerCase() !== UNITS.DEMO))
      )

    if (!hasUnit) this.setState({ error: true })

    else if (catid !== 'all' && subcatid !== 'all') {
      const hasParent = hasUnit.parentIds.find(data => data.parentId === catid) || null

      if (!hasParent) this.setState({ error: true })
      else {
        const hasCategories = hasParent.categories.find(data => data.categoryId === subcatid) || null

        if (!hasCategories) this.setState({ error: true })
        // MBQ Edition-language filter for internal user resources
        //else this.setResults(filteredResources)
        else this.setResults(filteredResources, searchEdLangOn)        
      }
    }

    else if (catid !== 'all') {
      const hasParent = hasUnit.parentIds.find(data => data.parentId === catid) || null

      if (!hasParent) this.setState({ error: true })
      // MBQ Edition-language filter for internal user resources
      //else this.setResults(filteredResources)
      else this.setResults(filteredResources, searchEdLangOn)      
    }

    else if (catid === 'all' && subcatid !== 'all') this.setState({ error: true })
    // MBQ Edition-language filter for internal user resources
    //else this.setResults(filteredResources)
    else this.setResults(filteredResources, searchEdLangOn)
  }

  filter = arr => arr.reduce((accumulator, currentValue) => {

    if (Array.isArray(currentValue.categories)) {
      currentValue.categories.forEach(data => {

        let hasParentId = accumulator.find(acc => acc.parentId === data.parentId) || null;

        if (!hasParentId) {
          let categories = [{ ...data, resources: [currentValue] }]
          accumulator.push({
            parentId: data.parentId,
            parentName: data.parentName,
            categories
          })
        }

        else {
          const hasCategory = hasParentId.categories.find(acc => acc.categoryId === data.categoryId) || null;
          if (!hasCategory) {
            hasParentId.categories.push({ ...data, resources: [currentValue] })
            hasParentId.categories = _.sortBy(hasParentId.categories, 'name')
          }
          else {
            hasCategory.resources.push(currentValue)
            hasCategory.resources = _.sortBy(hasCategory.resources, 'name')
          }
        }
      }
      )
    }

    return _.sortBy(accumulator, 'parentName')

  }, [])

  filterResources = (resources, callback) => {

    const allResources = {
      parentIds: this.filter(resources),
      unitUuid: 'all'
    }

    const favs = {
      unitUuid: 'favs',
      parentIds: this.filter(resources.filter(res => res.favourite))
    }

    const filteredResources = resources.reduce((accumulator, currentValue) => {

      const hasValue = accumulator.find(unit => unit.unitUuid === currentValue.unitUuid) || null;
      if (!hasValue) {
        const newResources = resources.filter(resource => resource.unitUuid === currentValue.unitUuid);
        accumulator.push({
          unitUuid: currentValue.unitUuid,
          parentIds: this.filter(newResources)
        })
      }

      return accumulator

    }, [favs, allResources])

    callback(filteredResources)
  }

  updateLightBoxState = (Comp, data) => {
    const newlightBoxData = {
      component: Comp === data.previewURL ? `${envSettings.API_BASEURL}${data.previewURL}` : <Comp data={data} />,
      showZoom: Comp === data.previewURL ? true : false,
      data
    }
    this.setState({ lightBoxData: newlightBoxData })
  }

  handleLightBox = uuid => {

    if (!uuid) this.setState({ ShowLightBox: false })
    else {

      const { resources } = this.props,
        data = resources.find(data => data.uuid === uuid )

      this.setState({ ShowLightBox: true })

      const audioPlayer = /mpeg|x-wav/.test(data.mimetype),
        videoPlayer = /mp4/.test(data.mimetype),
        isBlank = !data.previewURL || /^\s*$/.test(data.previewURL),
        isImage = /jpg|jpeg|png/.test(data.mimetype)

      if (!isBlank && !audioPlayer && !videoPlayer && !isImage) this.updateLightBoxState(PDFViewer, data)
      else if (audioPlayer) this.updateLightBoxState(Audio, data)
      else if (videoPlayer) this.updateLightBoxState(Video, data)
      else if (isImage) this.updateLightBoxState(data.previewURL, data)
      else this.updateLightBoxState(NoViewer, data)

    }
  }

  searchResources = () => {
    // MBQ Edition-language filter for internal user resources
    //const { filteredResources, searchInput, showOnlyNew } = this.state
    const {
      filteredResources, searchInput, showOnlyNew,
      edition, language
    } = this.state    

    const strToSearch = searchInput ? searchInput.trim() : undefined
    const textSearch = !(!strToSearch || strToSearch.length<3)

    // MBQ Edition-language filter for internal user resources
    //if(!textSearch && !showOnlyNew) this.setState({ searchInputDebounced: '', shownResources: filteredResources })
    if (!textSearch && !showOnlyNew &&
      (
        this.props.userInfo.role !== userRoles.USER_INTERNAL ||
        (
          (edition == null || edition < 0) &&
          (language == null || language < 0)
        )
      )
    ) {
      this.setState({ searchInputDebounced: '', shownResources: filteredResources })
    } else {
      const shownResources = []
      for(let i=0;i<filteredResources.length;i++) {
        const originalUnit = filteredResources[i]
        let insertUnit = false
        const unit = {
          parentIds: [],
          unitUuid: originalUnit.unitUuid
        }
        if(originalUnit.parentIds) {
          for(let j=0;j<originalUnit.parentIds.length;j++) {
            const originalCategory = originalUnit.parentIds[j]
            let insertCategory = false
            const category = {
              parentId: originalCategory.parentId,
              parentName: originalCategory.parentName,
              categories: []
            }
            if(originalCategory.categories) {
              for(let k=0;k<originalCategory.categories.length;k++) {
                const originalSubcategory = originalCategory.categories[k]
                if(originalSubcategory.resources) {

                  // MBQ Edition-language filter for internal user resources
                  const resources = originalSubcategory.resources.filter(resource => {
                    /*
                    if(textSearch && (unorm.nfd(resource.name).toLowerCase().indexOf(unorm.nfd(strToSearch).toLowerCase()) !== -1) && !showOnlyNew) return true
                    if(showOnlyNew && resource.new && !textSearch) return true
                    if(textSearch && (unorm.nfd(resource.name).toLowerCase().indexOf(unorm.nfd(strToSearch).toLowerCase()) !== -1) && showOnlyNew && resource.new) return true
                    return false
                    */
                    if (textSearch && (unorm.nfd(resource.name).toLowerCase().indexOf(unorm.nfd(strToSearch).toLowerCase()) === -1)) {
                      return false
                    }

                    if (showOnlyNew && !resource.new) {
                      return false
                    }

                    if (this.props.userInfo.role === userRoles.USER_INTERNAL &&
                      unit.unitUuid.toLowerCase() !== UNITS.FAVOURITES &&
                      edition != null && edition >= 0 &&
                      language != null && language >= 0 &&
                      (
                        (!resource.editions || resource.editions.length <= 0 || !resource.editions.includes(edition)) ||
                        (!resource.languages || resource.languages.length <= 0 || !resource.languages.includes(language)) ||
                        resource.unitName.toLowerCase() === UNITS.DEMO
                      )
                    ) {
                      return false
                    }

                    return true
                  })

                  if(resources.length) {
                    const subcategory = _.cloneDeep(originalSubcategory)
                    subcategory.resources = resources
                    insertCategory = true
                    insertUnit = true
                    category.categories.push(subcategory)
                  }
                }
              }
            }
            if(insertCategory) unit.parentIds.push(category)
          }
        }
        if(insertUnit) shownResources.push(unit)
      }

      this.setState({ searchInputDebounced: searchInput, shownResources })
    }
  }

  searchResourcesDebounced = _.debounce(this.searchResources, 500)

  changeFav = (resource) => {
    const { params: { titleid }, resources } = this.props
    resource.favourite = !resource.favourite
    const { filteredResources } = this.state
    const favs = filteredResources.find(unit => unit.unitUuid === 'favs')
    favs.parentIds = this.filter(resources.filter(res => res.favourite))
    this.setState({ filteredResources }, this.searchResources)
    const favourites = resources.filter(res => res.favourite).map(res => res.uuid)
    axiosClient.put(`/favourites/${titleid}`, favourites)
      .catch(() => {})
  }

  render() {
    // MBQ Edition-language filter for internal user resources
    const { units, editions, params, params: { unitid, catid, subcatid }, openCarousel, userInfo } = this.props,
      { shownResources, error, ShowLightBox, lightBoxData, searchInput, searchInputDebounced,
        edition, language, searchEdLangOn } = this.state

    if (error) return <Redirect to={`/error`} />

    // MBQ Edition-language filter for internal user resources
    //const onlyCat = (!unitid || unitid === 'all') && (!catid || catid === 'all') && (!subcatid || subcatid === 'all') && (!searchInputDebounced || searchInputDebounced.trim() === '')
    const onlyCat = 
      (!unitid || unitid === 'all') && (!catid || catid === 'all') && (!subcatid || subcatid === 'all') &&
      (!searchInputDebounced || searchInputDebounced.trim() === '') &&
      (edition == null || edition < 0) &&
      (language == null || language < 0)
                      
    // MBQ Edition-language filter for internal user resources
    //let hasResources = shownResources && (shownResources.length !==0)
    let hasResources = shownResources && shownResources.length > 0

    if(hasResources) {
      const data = shownResources.find(item => item.unitUuid === unitid)
      if(!data) hasResources = false
      else {
        const resources = data.parentIds
          .filter(data => data.parentId === catid || catid === 'all')
        if(!resources) hasResources = false
        else hasResources = !!resources.filter(data => data.categoryId === subcatid || subcatid === 'all')
        hasResources = !!resources.length
      }
    }

    return (
      <>
        <Row>
          {/*
            // MBQ Edition-language filter for internal user resources
            <Col className="resources__new__filter text-center" lg="2" md="6" xs="12">
          */}
          <Col
            className={cx('resources__new__filter',
                        {
                          'text-center': true,
                          'mb-sm-1': (userInfo.role === userRoles.USER_INTERNAL)
                        }
            )}
            xs="12"
            md={userInfo.role === userRoles.USER_INTERNAL ? { size: 3 } : { size: 6 }}
            lg={userInfo.role === userRoles.USER_INTERNAL ? { size: 3 } : { size: 2 }}
          >
            <span
              onClick={ e => {
                this.setState({ showOnlyNew: !this.state.showOnlyNew }, () => this.searchResources())
              }}>{ this.props.content.titledetail.new_resource_switch }</span>
            <CustomInput
              className="ml-2 d-inline"
              type="switch"
              id="newResourcesSwitch"
              name="newResourcesSwitch"
              checked={ this.state.showOnlyNew }
              onChange={ e => {
                this.setState({ showOnlyNew: !this.state.showOnlyNew }, () => this.searchResources())
              }}
            />
          </Col>

          {/* // MBQ Edition-language filter for internal user resources */}
          {
            userInfo.role === userRoles.USER_INTERNAL && (
              <Col
                className={cx({
                  'my-3': (userInfo.role === userRoles.USER_INTERNAL),
                  'my-md-0': (userInfo.role === userRoles.USER_INTERNAL)
                }
                )}
                xs="12"
                md={userInfo.role === userRoles.USER_INTERNAL ? { size: 5 } : { size: 4 }}
                lg={userInfo.role === userRoles.USER_INTERNAL ? { size: 6 } : { size: 7 }}
              >
                <FormGroup className="d-flex float-lg-right">
                  <Input
                    type="select"
                    name="edition"
                    id="edition"
                    placeholder={this.props.content.titledetail.edition}
                    value={edition}
                    onChange={this.handleEditionChange}
                    disabled={(editions == null || editions.length <= 0 || !searchEdLangOn)}
                  >
                    <option value={-1}>{this.props.content.titledetail.edition}</option>
                    {
                      editions != null &&
                      editions.length > 0 &&
                      editions.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)
                    }
                  </Input>
                  <Input
                    type="select"
                    name="language"
                    id="language"
                    placeholder={this.props.content.titledetail.language}
                    className="ml-3"
                    value={language}
                    onChange={({ target: { value } }) => {
                      this.setState(
                        { language: _.parseInt(value) },
                        this.searchResources
                      )
                    }}
                    disabled={
                      this.state.edtionObj == null ||
                      this.state.edtionObj.languages == null ||
                      this.state.edtionObj.languages.length <= 0 ||
                      !searchEdLangOn
                    }
                  >
                    <option value={-1}>{this.props.content.titledetail.language}</option>
                    {
                      this.state.edtionObj != null &&
                      this.state.edtionObj.languages != null &&
                      this.state.edtionObj.languages.length > 0 &&
                      this.state.edtionObj.languages.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)
                    }
                  </Input>
                </FormGroup>
              </Col>
            )
          }

          {/*
            // MBQ Edition-language filter for internal user resources
            <Col lg={{size:3, offset:7}} md={{size:6, offset:6}} xs="12">
          */}
          <Col
            xs="12"
            md={userInfo.role === userRoles.USER_INTERNAL ? { size: 4 } : { size: 6, offset: 6 }}
            lg={userInfo.role === userRoles.USER_INTERNAL ? { size: 3 } : { size: 3, offset: 7 }}
          >
            <SearchInput
              id="searchResources"
              name="searchResources"
              value={ searchInput }
              onChange={ value => this.setState({ searchInput: value }, () => this.searchResourcesDebounced()) }
            />
          </Col>
        </Row>
        <Row>
          <Col className={cx({
            'd-none': (unitid && unitid !== 'all') || (catid && catid !== 'all') || (subcatid && subcatid !== 'all'),
            'd-lg-block': true
          })} lg="2" md="12" xs="12">
            {/*
              // MBQ Edition-language filter for internal user resources
              <Units
                units={units}
                params={params}
                onClick={() => this.setState({ searchInput: '' }, () => this.searchResources()) }
              />
            */}
            <Units
              units={units}
              params={params}
              onClick={
                unitName => this.setState(
                  {
                    searchInput: '',
                    edition: -1,
                    edtionObj: null,
                    language: -1,
                    searchEdLangOn: (!unitName || (unitName && unitName.toLowerCase() !== UNITS.FAVOURITES && unitName.toLowerCase() !== UNITS.DEMO))
                  },
                  () => {
                    this.searchResources()
                  }
                )}
            />
            <NeedHelpButton
              onClick={openCarousel}
            />
          </Col>
          <Col lg="10" md="12" xs="12">
            { hasResources
              ? (
                <div className="resources__schema">
                  <Row>
                    <Col
                      className={cx({
                        'd-none': (unitid && unitid !== 'all') || (catid && catid !== 'all') || (subcatid && subcatid !== 'all'),
                        'd-lg-block': true
                      })}
                      lg={onlyCat ? 12 : 3}
                      md="12"
                      xs="12"
                    >
                      <Categories
                        filteredResults={shownResources}
                        params={params}
                        onlyCat={onlyCat}
                      />
                    </Col>
                    {
                      !onlyCat
                      && (
                        <Col lg="9" md="12" xs="12" className="overflow-hidden">
                          <div className="resources__listContainer p-4 mt-3">
                            <ResourceList
                              filteredResults={shownResources}
                              params={params}
                              lightBoxData={lightBoxData}
                              ShowLightBox={ShowLightBox}
                              handleLightBox={this.handleLightBox}
                              changeFav={this.changeFav}
                            />
                          </div>
                        </Col>
                      )
                    }
                  </Row>
                </div>
              )
              : <CMSContent pageid="PREM_NO_RESULTS" language={this.props.userInfo ? getLocale(this.props.userInfo.language) : getLocale()} />
            }
          </Col>
        </Row>
      </>
    )
  }
}

Resources.propTypes = {
  units: PropTypes.array,
  params: PropTypes.object
}

export default withContent(withUserInfo(Resources))