import React, { Component } from 'react'
import { AuthConsumer } from 'react-check-auth'
import { Progress, Alert } from 'reactstrap'
import { ContentConsumer } from '../../language'
import axios from 'axios'
import axiosConfig from '../../config/axios'
import GpaNavbar from '../GpaNavbar/GpaNavbar'
import GpaHeader from '../GpaHeader/GpaHeader'
import GpaTitleView from '../GpaTitleView/GpaTitleView'
import GpaOrderCardItem from '../GpaOrderCardItem/GpaOrderCardItem'
import { stateData, isActionRequired } from '../../utils/gpa-utils'

class GpaOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      areas: null,
      links: [
        {
          id: 1,
          url: null,
          label: props.content.gratisadoption.gxa_1
        }
      ]
    }
  }

  componentDidMount() {
    if (!this.props.userInfo || !this.props.userInfo.gpaPermission || !this.props.userInfo.positionId) {
      this.props.history.push("/")
    } else {
      this.getOrders()
    }
  }

  getOrders = () => {
    axios.create(axiosConfig).get('/gratis-adoption/orders')
      .then((response) => {
        //console.log('ORDERS RECUPERADAS', response.data)
        this.setState({ areas: response.data })
      })
      .catch((error) => {
        console.error('error : ', error)
        this.setState({ error: error })
      })
  }

  renderTitle = (areas, userId) => {
    let count = 0
    if (areas && areas.Areas && areas.Areas.length) {
      areas.Areas.map(area => {
        let accumulator = area.Titulos.reduce((result, title) => {
          if (isActionRequired(title, userId)) {
            return ++result
          } else {
            return result
          }
        }, 0)
        return count += accumulator
      })
    }
    return (<GpaTitleView label={this.props.content.gratisadoption.gxa_1} count={count} />)
  }

  renderAreas = (areas, userId) => (
    areas.map(area => (
      <div className="row" key={area.Area}>
        <div className="col-12">
          <h3>{area.Area}</h3>
          <div className="row">{this.renderOrders(area.Titulos, userId)}</div>
        </div>
      </div>
    ))
  )

  renderOrders = (orders, userId) => (
    orders.map(order => (
      <GpaOrderCardItem key={order.idSolicitud} data={order} userId={userId} stateData={stateData[order.estado.idEstado]} actionRequired={isActionRequired(order, userId)} baseUrl={this.props.match.url} content={this.props.content} />
    ))
  )

  render() {
    const { userInfo, content } = this.props
    if (!userInfo || !userInfo.gpaPermission || !userInfo.positionId) {
      return (<Progress animated color="primary" value="100" />)
    }

    const { error, areas, links } = this.state
    const userId = parseInt(userInfo.id, 10)

    return (
      <div className='container-gpa'>
        <GpaNavbar links={links} />
        <div className="container">
          <div className="row adopcion-recursos">
            <div className="col-sm-12">
              {this.renderTitle(areas, userId)}
              <GpaHeader school={userInfo.schoolName} position={userInfo.positionName} content={content} />
              {error && <Alert color="danger">{content.gratisadoption.gxa_50}</Alert>}
              {!error && !areas && <Progress animated color="primary" value="100">{content.sharedTerms.LOADING_MESSAGE}</Progress>}
              {!error && areas && (!areas.Areas || !areas.Areas.length) && <Alert color="primary">{content.sharedTerms.NO_RESULTS_MESSAGE}</Alert>}
              {areas && areas.Areas && areas.Areas.length > 0 && this.renderAreas(areas.Areas, userId)}
            </div>
          </div>
        </div>
      </div>
    )

  }
}

export default props => (
  <AuthConsumer>
    {({ userInfo }) => (
      <ContentConsumer>
        {
          ({ content = {} }) => (
            (content && <GpaOrders userInfo={userInfo} content={content} {...props} />)
          )}
      </ContentConsumer>
    )}
  </AuthConsumer>
)