import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'
import cx from 'classnames'

import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { withContent } from '../../utils/hocs'
import PDItem from './PDItem'

class PDCarousel extends Component {
  constructor(props) {
    super(props)
    this.slider = null
  }

  previous = (e) => {
    e.preventDefault()
    this.slider.slickPrev()
  }
  next = (e) => {
    e.preventDefault()
    this.slider.slickNext()
  }

  render() {
    const { assets, title, titleClassName, content, className, linkAfterTitle } = this.props
    const settings = {
      dots: false,
      arrows: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 992,
          settings: {
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
    if(!assets || !assets.length) return null
    else return (
      <Container className={ cx("pdcarousel my-5", className) }>
        <Row className="mb-3 d-flex">
          <Col xl="10" className="px-lg-2">
            <span className={ cx("pdcarousel-title align-text-bottom", titleClassName ) } >{ title }</span>{ linkAfterTitle ? linkAfterTitle : null }
          </Col>
          <Col className="text-right d-flex" xl="2">
            <div className="mt-auto ml-auto">
              <div className="d-inline pdcarousel-nav mr-1" onClick={this.previous}>&lt; { content.assets.previous }</div>
              <div className="d-inline pdcarousel-nav ml-1" onClick={this.next}>{ content.assets.next } &gt;</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Slider
              ref={c => this.slider = c}
              {...settings}>
              {assets.map(asset => <Fragment key={asset.uuid}><PDItem {...asset} /></Fragment>)}
            </Slider>
          </Col>
        </Row>
      </Container>
    )
  }
}

PDCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string,
  className: PropTypes.string,
  linkAfterTitle: PropTypes.element,
  assets: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    smallThumbnail: PropTypes.string,
    largeThumbnail: PropTypes.string
  }))
}

export default withContent(PDCarousel)
