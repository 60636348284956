import React from 'react'
import _ from 'lodash'

import envSettings from '../../../../globals/envSettings'
import DigitalProducts from '../../../../globals/digitalProducts'
import { withContent } from '../../../../utils/hocs'
import { format } from '../../../../utils/string'
import './ProductButton.css'

const OLBButton = withContent(({ content }) => (
  <a
    className="button-olb button-product"
    href={_.get(content, 'coupons.digitallibrary_olb_url', 'https://www.oxfordlearnersbookshelf.com')}
    target="_blank"
    rel="noreferrer"
  >
    { _.get(content, 'coupons.digitallibrary_olb_button', 'Activar en Oxford Learner\'s Bookshelf') }
  </a>
))

const PangeaButton = withContent(({ content, license }) => (
  <a
    className="button-pangea button-product"
    href={format(_.get(content, 'coupons.digitallibrary_pangea_url', 'https://app.gopangea.org/signup/oup?code={license}'), { license })}
    target="_blank"
  >
    { _.get(content, 'coupons.digitallibrary_pangea_button', 'Activar en Go Pangea') }
  </a>
))

const BlinkButton = withContent(({ content }) => (
  <a
    className="button-blink button-product"
    href={_.get(content, 'coupons.digitallibrary_blink_url', 'https://www.blinklearning.com')}
    target="_blank"
    rel="noreferrer"
  >
    { _.get(content, 'coupons.digitallibrary_blink_button', 'Activar en BlinkLearning') }
  </a>
))

const InternalButton = withContent(({ content, token, product, resource, license }) => (
  <a
    className="button-product"
    href={format(
      _.get(content, 'coupons.digitallibrary_internal_url', '{api}/coupons/{token}/product/{product}/resource/{resource}/license/{license}'),
      {
        api: envSettings.API_BASEURL,
        token,
        product,
        resource,
        license
      }
    )}
    target="_blank"
    rel="noopener noreferrer"
  >
    { _.get(content, 'coupons.digitallibrary_internal_button', 'Abrir mi libro') }
  </a>
))

const ExternalButton = withContent(({ content, url }) => (
  <a
    className="button-product"
    href={url}
    target="_blank"
    rel="noreferrer"
  >
    { _.get(content, 'coupons.digitallibrary_external_button', 'Abrir mi libro') }
  </a>
))

const ProductButton = ({ token, product }) => {
  const { type, uuid, resourceuuid, license, url } = product
  const { productType } = DigitalProducts
  if(type === productType.BLINK) return <BlinkButton />
  if(type === productType.OLB) return <OLBButton />
  if(type === productType.PANGEA) {
    return (
      <PangeaButton
        license={license}
      />
    )
  }
  if(type === productType.ONLINE_EXTERNAL) {
    return (
      <ExternalButton
        url={url}
      />
    )
  }
  if(type === productType.ONLINE_INTERNAL) {
    return (
      <InternalButton
        token={token}
        product={uuid}
        resource={resourceuuid}
        license={license}
      />
    )
  }
  return null
}

export default ProductButton