import React, { Component } from 'react'
import axios from 'axios'
import { Container, Row, Col, Input, CustomInput, FormGroup, FormFeedback } from 'reactstrap'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import validator from 'validator'
import _ from 'lodash'
import cx from 'classnames'

import ButtonCoupons from '../ButtonCoupons/ButtonCoupons'
import { RecaptchaTerms } from '../Recaptcha/Recaptcha'
import Forgot from './Forgot'
import RedeemCouponSuccessful from './RedeemCouponSuccessful'
import RedeemCouponError from './RedeemCouponError'
import InfoBox from './InfoBox/InfoBox'

import { withContent } from '../../utils/hocs'
import { parseToReactElement } from '../../utils/html'
import {
  ActivateLicensesModal,
  EmailWillReceiveModal,
  LostAccessModal,
  ValidEmailModal,
  WhereIsMyCodeModal
} from './Modals/Modals'

import axiosConfig from '../../config/axios'

import './RedeemCoupon.css'

const axiosClient = axios.create({ ...axiosConfig, timeout: 60000 })

const ModalLink = ({ className, onClick, children }) => (
  <div
    className={cx('pt-2', className)}
    role="link"
    onClick={onClick}
    onKeyDown={onClick}
    tabIndex={-1}
  >
    {children}
  </div>
)

const scrollToTop = () => window.scrollTo(0,0)

class RedeemCoupon extends Component {
  constructor() {
    super()
    this.state = {
      code: '',
      email: '',
      email2: '',
      provinces: [],
      province: '0',
      age: false,
      marketing: false,
      redeemed: false,
      forgotUrlSuccess: false,
      errors: {
        code: false,
        email: false,
        email2: false,
        forgotUrl: false
      },

      activatelicensesmodal: false,
      emailwillreceivemodal: false,
      lostaccessmodal: false,
      validemailmodal: false,
      whereismycodemodal: false
    }
  }

  componentDidMount() {
    axiosClient.get('/province')
      .then((res) => {
        const { data: provinces } = res
        this.setState({ provinces })
      })
  }

  isValid = () => {
    let valid = true
    const { code, email, email2, province } = this.state
    //valid = valid && age
    valid = valid && !!email && (email === email2) && validator.isEmail(email)
    valid = valid && code
    valid = valid && (province !== '0')

    return !!valid
  }

  handleInputChange = (event) => {
    const { target } = event
    const value = target.type === 'checkbox' ? target.checked : target.value
    const { name } = target

    switch (name) {
      case 'code':
        this.setState((prevState) => (
          {
            [name]: value.toUpperCase(),
            errors: {
              ...prevState.errors,
              [name]: value.length < 2
            }
          }
        ))
        break
      case 'email':
        this.setState((prevState) => (
          {
            [name]: value,
            errors: {
              ...prevState.errors,
              email: !validator.isEmail(value),
              email2: prevState.email2 && prevState.email2 !== value
            }
          }
        ))
        break

      case 'email2':
        this.setState((prevState) => (
          {
            [name]: value,
            errors: {
              ...prevState.errors,
              [name]: prevState.email !== value
            }
          }
        ))
        break
      default:
        this.setState({ [name]: value })
    }
  }

  submit = async () => {
    if(!this.isValid()) return

    const { code, email, age, marketing, province } = this.state
    const { googleReCaptchaProps } = this.props
    const token = await googleReCaptchaProps.executeRecaptcha('coupons')

    axiosClient.post(`/coupons/${code}`, { email, token, age: !!age, marketing: !!marketing, province: Number(province) })
      .then(() => {
        this.setState({ redeemed: true })
      })
      .catch((err) => {
        // GoTo Error page
        this.setState({ error: _.get(err, 'response.data.code', 500) })
      })
  }

  render() {
    const { content } = this.props
    const {
      code, email, email2, province, age, marketing,
      error, redeemed, errors, forgotUrlSuccess, provinces
    } = this.state
    const {
      activatelicensesmodal, emailwillreceivemodal, lostaccessmodal,
      validemailmodal, whereismycodemodal
    } = this.state

    if(error) {
      return (
        <RedeemCouponError
          errorCode={error}
          onAccept={() => this.setState({ error: false, code: '', email: '', email2: '', age: false, marketing: false, province: '0' })}
        />
      )
    }
    if(forgotUrlSuccess) {
      scrollToTop()
      return (
        <Container>
          <Row className="my-3"><Col><h1>{ _.get(content, 'coupons.redemption_forgot_success_title') }</h1></Col></Row>
          <Row className="my-3"><Col>{ parseToReactElement(_.get(content, 'coupons.redemption_forgot_success_body'), null, { ADD_ATTR: ['target'] }) }</Col></Row>
          <Row className="my-3">
            <Col>
              <ButtonCoupons
                primary
                onClick={() => this.setState({ forgotUrlSuccess: false })}
              >
                { _.get(content, 'coupons.redemption_forgot_success_button') }
              </ButtonCoupons>
            </Col>
          </Row>
        </Container>
      )
    }
    if(redeemed) {
      return (
        <RedeemCouponSuccessful
          email={email}
          onRedeemCoupon={() => this.setState({ code: '', redeemed: false })}
        />
      )
    }
    return (
      <>
        <Container>
          <Row className="my-4"><Col><h1 className="coupons_title">{ content.coupons.redemption_welcome_title }</h1></Col></Row>
          <Row className="my-4 d-none d-md-block"><Col>{ parseToReactElement(content.coupons.redemption_welcome_body, null, { ADD_ATTR: ['target'] }) }</Col></Row>
          <Row className="my-4 d-none d-md-block">
            <Container>
              <Row className="mx-md-5 mx-lg-0">
                <InfoBox
                  label="1"
                  body={_.get(content, 'coupons.redemption_infobox_1_body')}
                  linkText={_.get(content, 'coupons.redemption_infobox_1_link')}
                  onClick={() => this.setState({ whereismycodemodal: true })}
                />
                <InfoBox
                  label="2"
                  body={_.get(content, 'coupons.redemption_infobox_2_body')}
                  linkText={_.get(content, 'coupons.redemption_infobox_2_link')}
                  onClick={() => this.setState({ emailwillreceivemodal: true })}
                />
                <InfoBox
                  label="3"
                  body={_.get(content, 'coupons.redemption_infobox_3_body')}
                  linkText={_.get(content, 'coupons.redemption_infobox_3_link')}
                  onClick={() => this.setState({ lostaccessmodal: true })}
                />
                <InfoBox
                  label="4"
                  body={_.get(content, 'coupons.redemption_infobox_4_body')}
                  linkText={_.get(content, 'coupons.redemption_infobox_4_link')}
                  onClick={() => this.setState({ activatelicensesmodal: true })}
                />
              </Row>
            </Container>
          </Row>
          <Row className="my-5"><Col><h1 className="coupons_title">{ content.coupons.redemption_form_title }</h1></Col></Row>
          <Row className="my-3">
            <Col
              md="9"
              lg="6"
              className="h-100"
            >
              <Input
                type="text"
                id="code"
                name="code"
                autoComplete="off"
                value={code}
                onChange={this.handleInputChange}
                placeholder={content.coupons.redemption_type_code}
              />
            </Col>
            <Col
              md="3"
              lg="6"
            >
              <ModalLink
                className="modal_help_link"
                onClick={() => this.setState({ whereismycodemodal: true })}
              >
                {content.coupons.redemption_code_help}
              </ModalLink>
            </Col>
          </Row>
          <Row className="my-3">
            <Col
              md="9"
              lg="6"
            >
              <FormGroup className="mb-0">
                <Input
                  type="email"
                  id="email"
                  name="email"
                  autoComplete="off"
                  value={email}
                  onChange={this.handleInputChange}
                  placeholder={content.coupons.redemption_type_email}
                  valid={!!email && !errors.email}
                  invalid={!!errors.email}
                  onPaste={(e) => e.preventDefault()}
                />
                <FormFeedback>{content.coupons.redemption_email_invalid}</FormFeedback>
              </FormGroup>
            </Col>
            <Col
              md="3"
              lg="6"
            >
              <ModalLink
                className="modal_help_link"
                onClick={() => this.setState({ validemailmodal: true })}
              >
                {content.coupons.redemption_email_help}
              </ModalLink>
            </Col>
          </Row>
          <Row className="my-3">
            <Col
              md="9"
              lg="6"
            >
              <FormGroup className="mb-0">
                <Input
                  type="email"
                  id="email2"
                  name="email2"
                  autoComplete="off"
                  value={email2}
                  onChange={this.handleInputChange}
                  placeholder={content.coupons.redemption_type_confirm_email}
                  valid={!!email2 && !errors.email2}
                  invalid={!!errors.email2}
                  onPaste={(e) => e.preventDefault()}
                />
                <FormFeedback>{content.coupons.redemption_email_mismatch}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-3">
            <Col
              md="9"
              lg="6"
            >
              <Input
                id="province"
                type="select"
                name="province"
                className="input_text"
                autoComplete="off"
                value={province}
                onChange={this.handleInputChange}
              >
                <option key={0} value={0}>{content.coupons.redemption_select_province}</option>
                {
                  provinces.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))
                }
              </Input>
            </Col>
          </Row>
          <Row className="my-3"><Col>{ parseToReactElement(content.coupons.redemption_privacy_policy, null, { ADD_ATTR: ['target'] }) }</Col></Row>
          <Row className="my-3">
            <Col>
              <CustomInput
                className="custom-checkbox-inline input_text"
                type="checkbox"
                id="marketing"
                name="marketing"
                label={content.coupons.redemption_check_marketing}
                checked={marketing}
                onChange={this.handleInputChange}
                inline
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <CustomInput
                className="custom-checkbox-inline input_text"
                type="checkbox"
                id="age"
                name="age"
                label={content.coupons.redemption_check_age}
                checked={age}
                onChange={this.handleInputChange}
                inline
              />
            </Col>
          </Row>
          <Row className="my-4">
            <Col md="3">
              <ButtonCoupons
                primary
                disabled={!this.isValid()}
                onClick={this.submit}
              >
                { content.coupons.redemption_validate_button }
              </ButtonCoupons>
            </Col>
          </Row>
          <Row className="my-3"><Col><RecaptchaTerms className="text-xs" /></Col></Row>
          <Row className="pt-3 mb-3 border-top border-top-1">
            <Col>
              <Forgot
                onSuccess={() => this.setState({ forgotUrlSuccess: true })}
                onError={() => this.setState({ error: 500999 })}
              />
            </Col>
          </Row>
        </Container>
        <ActivateLicensesModal
          isOpen={activatelicensesmodal}
          onClose={() => this.setState({ activatelicensesmodal: false })}
        />
        <EmailWillReceiveModal
          isOpen={emailwillreceivemodal}
          onClose={() => this.setState({ emailwillreceivemodal: false })}
        />
        <LostAccessModal
          isOpen={lostaccessmodal}
          onClose={() => this.setState({ lostaccessmodal: false })}
        />
        <ValidEmailModal
          isOpen={validemailmodal}
          onClose={() => this.setState({ validemailmodal: false })}
        />
        <WhereIsMyCodeModal
          isOpen={whereismycodemodal}
          onClose={() => this.setState({ whereismycodemodal: false })}
        />
      </>
    )
  }
}

export default withGoogleReCaptcha(withContent(RedeemCoupon))
