import React from 'react'
import PropTypes from 'prop-types'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import TitleBanner from '../TitleBanner/TitleBanner'
import { ContentConsumer } from '../../language'
import { PROMOTION_TYPE } from './settings'
import { parseToReactElement } from '../../utils/html'

const PromotionBanner = props => (
  props.type === PROMOTION_TYPE.AD ?
    (
      <ContentConsumer>
        {({ content = {} }) => (
          content && (
            <div className='promotion-title'>
              <TitleBanner className='promotion-ad'
                content={
                  <div className='div__content'>
                    {parseToReactElement(props.promotion.content, null, { ADD_ATTR: ['target'], FORBID_ATTR: ['style', 'class'] })}
                  </div>
                }
                title={
                  <div className='div__title'>
                    <h5 className="font-weight-bold">
                      {parseToReactElement(props.promotion.title, null, { FORBID_ATTR: ['style', 'class'] })}
                    </h5>
                  </div>
                }
                image={
                  <img className='w-100' src={props.promotion.image.url.full} alt={props.promotion.image.alt} />
                }
                mainButton={
                  <ButtonPremium onClick={e => {
                    e.preventDefault()
                    props.openInformation(props.idTitle, props.promotion.id, props.promotion.title, props.promotion.moreInfo)
                    window.open(props.promotion.moreInfo, "_blank")
                  }} tabIndex="1" primary>{content.titledetail.learn_more}</ButtonPremium>
                }
                closeButton={
                  <div className='div__close text-left mt-2' onClick={() => props.closeBanner(props.promotion.id)}>
                    {content.titledetail.not_show_again}
                  </div>
                }
              />
            </div>
          )
        )}
      </ContentConsumer>
    )
    :
    (
      <div className='promotion-title'>
        <TitleBanner className='promotion-dm'
          content={
            <div className='div__content'>
              {parseToReactElement(props.promotion.content, null, { ADD_ATTR: ['target'], FORBID_ATTR: ['style', 'class'] })}
            </div>
          }
        />
      </div>
    )
)

PromotionBanner.propTypes = {
  promotion: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  idTitle: PropTypes.number,
  openInformation: PropTypes.func,
  closeBanner: PropTypes.func
}

export default PromotionBanner