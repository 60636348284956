import React from 'react'
import cx from 'classnames'
import { Row, Col } from 'reactstrap'
import axios from 'axios'

import { parseToReactElement } from '../../utils/html'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import RoundedButton from '../Buttons/RoundedButton'
import TitleCarouselIndicators from './TitleCarouselIndicators'
import { useViewport } from '../../hooks'
import { withContent, withUserInfoReloadable } from '../../utils/hocs'
import axiosConfig from '../../config/axios'

const axiosClient = axios.create(axiosConfig)

const TitleCarouselItem = ({
  content,
  title,
  body,
  image,
  item: { current, all },
  next,
  previous,
  isOnDemand,
  userInfo: { onBoardCarouselDate },
  refreshAuth
}) => {
  const viewport = useViewport()

  const getMiniButtonSize = () => {
    if(viewport.breakpoint === 'xl') return '53px'
    if(viewport.breakpoint === 'lg') return '42px'
    if(viewport.breakpoint === 'md') return '32px'
    if(viewport.breakpoint === 'sm') return '27.6px'
    return '32px'
  }

  const MiniButton = ({ children, className, onClick }) => (
    <RoundedButton
      className={cx('title-carousel-button-mini', className)}
      size={getMiniButtonSize()}
      onClick={onClick}
    >
      { children }
    </RoundedButton>
  )

  return (
    <div className="title-carousel-item">
      <Row noGutters>
        <Col className="title-carousel-item-col-body" sm="6">
          <div className="title-carousel-row-body align-items-center">
            <h1 className="title-carousel-item-title">{ title }</h1>
            <div className="title-carousel-item-col-body-body">{ parseToReactElement(body, null, { ADD_ATTR: ['target'] }) }</div>
          </div>
          <div className="title-carousel-row-controls">
            { previous && !next && <div className="title-carousel-row-controls-button-large"><ButtonPremium primary onClick={previous}>{content.titledetail.previous}</ButtonPremium></div> }
            { !previous && next && <div className="title-carousel-row-controls-button-large"><ButtonPremium primary onClick={next}>{content.titledetail.next}</ButtonPremium></div>}
            { previous && next
                && (
                  <div className="title-carousel-row-controls-mini-buttons">
                    <MiniButton onClick={previous}><span className="glyphicon glyphicon-chevron-left" aria-hidden="true" /></MiniButton>
                    <MiniButton onClick={next}><span className="glyphicon glyphicon-chevron-right" aria-hidden="true" /></MiniButton>
                  </div>
                )}
            <div className="title-carousel-row-controls-indicators">
              <TitleCarouselIndicators
                current={current}
                all={all}
              />
            </div>
          </div>
          { !onBoardCarouselDate && previous && !next && (
            <div
              className="title-carousel-row-bottom d-flex justify-content-end"
              onClick={() => {
                axiosClient.put('/user/disabletitlecarousel')
                  .then(() => {
                    refreshAuth()
                  })
                  .catch(() => {})
              }}
            >
              <div className="cursor-pointer text-right my-auto">
                {content.titledetail.do_not_show_again}
              </div>
            </div>
          )}
        </Col>
        <Col sm="6" className="title-carousel-item-col-image d-flex p-0 justify-content-center">
          <img
            src={image}
            alt={title}
          />
        </Col>
      </Row>
    </div>
  )
}

export default withUserInfoReloadable(withContent(TitleCarouselItem))
