import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import ButtonCoupons from '../../ButtonCoupons/ButtonCoupons'
import { withContent } from '../../../utils/hocs'
import _ from 'lodash'

const PurchaseResult = ({ content, email, purchase, onReturn }) => (
  <Container className="w-100 h-100 px-lg-5 purchase-result" fluid>
    <Row className="my-5">
      <Col xs="12" md="8" className="mt-0">
        <h1 className="title">
          <span className="title-underlined">
            <strong>
              {_.get(content, purchase === 'ok' ? 'coupons.purchase_success_title' : 'coupons.purchase_error_title')}
            </strong>
          </span>
        </h1>
      </Col>
    </Row>

    <Row className="my-3 subtitle">
      <Col>
        {_.get(content, purchase === 'ok' ? 'coupons.purchase_success_body_1' : 'coupons.purchase_error_body_1')}
      </Col>
    </Row>

    <Row className="my-3 description">
      <Col>
        <p>
          {_.get(content, purchase === 'ok' ? 'coupons.purchase_success_body_2' : 'coupons.purchase_error_body_2')}
        </p>
        {purchase === 'ok' && (
          <p><strong>{email}</strong></p>
        )}
      </Col>
    </Row>

    <Row className="my-3">
      <Col>
        <ButtonCoupons primary onClick={onReturn}>
          {_.get(content, 'coupons.purchase_back_library_button')}
        </ButtonCoupons>
      </Col>
    </Row>
  </Container>
)

export default withContent(PurchaseResult)