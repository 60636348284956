import React from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap'
import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from '../../language'

const renderMenu = (content, locale, onChange) => (
  <UncontrolledDropdown nav inNavbar>
    <DropdownToggle className="selectlanguage__dropdown__toggle" nav caret>
      <span>{content.sharedTerms[`LANGUAGE_${locale.toUpperCase()}_TEXT`]}</span>
    </DropdownToggle>
    <DropdownMenu className="selectlanguage__dropdown" right>
      {
        sortBy(SUPPORTED_LOCALES.map((language) => (
          <DropdownItem key={language} className="selectlanguage__dropdown__item" onClick={() => onChange(language)}>
            {content.sharedTerms[`LANGUAGE_${language.toUpperCase()}_TEXT`]}
          </DropdownItem>
        )), [function(o) { return o.props.children }])
      }
    </DropdownMenu>
  </UncontrolledDropdown>
)

const renderSelect = (content, attr) => (
  <Input
    type="select"
    {...attr}>
    {
      sortBy(SUPPORTED_LOCALES.sort().map((language) => (
        <option key={language} value={language}>{content.sharedTerms[`LANGUAGE_${language.toUpperCase()}_TEXT`]}</option>
      )), [function(o) { return o.props.children }])
    }
  </Input>
)

const renderComponent = (type, content, attr) => {
  let component = null
  switch (type) {
    case 'menu':
      component = renderMenu(content, attr.locale, attr.onChange)
      break
    case 'select':
      component = renderSelect(content, attr)
      break
    default:
      component = renderMenu(content, attr.locale, attr.onChange)
      break
  }
  return component
}

const SelectLanguage = ({ type, content, ...rest }) => (
  renderComponent(type, content, { ...rest })
)

SelectLanguage.propTypes = {
  type: PropTypes.string,
  content: PropTypes.object,
  locale: PropTypes.string,
  onChange: PropTypes.func
}

SelectLanguage.defaultProps = {
  type: 'menu',
  locale: DEFAULT_LOCALE
}

export default SelectLanguage
