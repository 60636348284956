import React, { Fragment } from 'react'
import { Container, Row, Col, Alert, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import ButtonPremium from '../../ButtonPremium/ButtonPremium'

const UserData = ({ user, onImpersonate, error, content }) => (
  <Fragment>
    {error && <Alert color="danger">{error}</Alert>}
    <Container className='user-data'>
      <Row>
        <Col sm='12'>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>MAIL</th>
                <th>ROLE</th>
                <th>SCHOOL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope='row'>{user.id}</th>
                <td>{`${user.name} ${user.surname} ${user.surname2}`}</td>
                <td>{user.mail}</td>
                <td>{user.role}</td>
                <td>{`(${user.schoolId}) ${user.schoolName} (${user.countryName})`}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className='mt-5'>
        <Col sm={{ size: 4, offset: 2 }}>
          <ButtonPremium tag={Link} to={'/'}>{content.sharedTerms.CANCEL_BUTTON_TEXT}</ButtonPremium>
        </Col>
        <Col sm='4'>
          <ButtonPremium onClick={onImpersonate} primary>Impersonate</ButtonPremium>
        </Col>
      </Row>
    </Container>
  </Fragment>
)

export default UserData
