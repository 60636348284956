import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Container, Col, Row } from 'reactstrap'

import { withContent, withUserInfo } from '../../utils/hocs'
import envSettings from '../../globals/envSettings'
import axios from 'axios'
import axiosConfig from '../../config/axios'
import { parseToReactElement } from '../../utils/html'
import { nFormatter } from '../../utils/format'
import Thumbs from '../Thumbs/Thumbs'

class PDAsset extends Component {
  constructor(props) {
    super(props)
    this.state = {
      asset: undefined,
      viewed: false
    }
  }

  componentDidMount() {
    const { history, userInfo } = this.props
    if (!userInfo || !userInfo.pdPermission) {
      history.push("/error")
    } else {
      const { match: { params: { assetid } } } = this.props
      this.getAsset(assetid)
    }
  }

  handleView = (preview = false) => {
    if(!this.state.played) {
      const { asset: { uuid } } = this.state
      const axiosInstance = axios.create(axiosConfig)
      const url = `pd/${uuid}/views${preview ? '?preview' : ''}`
      axiosInstance.post(url)
        .then(() => {})
        .catch(() => {})
      this.setState({ played: true, views: this.state.views+1 })
    }
  }

  handleLikes = (thumbUp, thumbDown) => {
    const axiosInstance = axios.create(axiosConfig)
    const { asset: { uuid } } = this.state
    const url = `pd/${uuid}/thumbs`
    const value = thumbUp ? 1 : thumbDown ? -1 : 0

    const { thumbUp: previousThumbUp, thumbDown: previousThumbDown } = this.state

    axiosInstance.put(url, { like: value })
      .then(() => {})
      .catch(() => {})
    this.setState({
      thumbsUp: thumbUp > previousThumbUp ? this.state.thumbsUp+1 : thumbUp < previousThumbUp ? this.state.thumbsUp-1 : this.state.thumbsUp,
      thumbsDown: thumbDown > previousThumbDown ? this.state.thumbsDown+1 : thumbDown < previousThumbDown ? this.state.thumbsDown-1: this.state.thumbsDown,
      thumbUp,
      thumbDown
    })
  }

  goBack = () => {
    const { history } = this.props
    history.goBack()
  }

  largeThumbnailUrl = () => {
    const { asset: { uuid } } = this.state
    return `${envSettings.API_BASEURL}/pd/${uuid}/largeThumbnail`
  }

  content = () => {
    const { asset: { uuid } } = this.state
    return `${envSettings.API_BASEURL}/pd/${uuid}/content`
  }

  preview = () => {
    const { asset: { uuid } } = this.state
    return `${envSettings.API_BASEURL}/pd/${uuid}/content?preview`
  }

  isVideo = () => {
    const { asset } = this.state
    if(!asset) return false
    return ['video/mp4', 'video/webm', 'video/ogg'].find(type => type === asset.type) !== undefined
  }

  isAudio = () => {
    const { asset } = this.state
    if(!asset) return false
    return ['audio/mpeg', 'audio/ogg', 'audio/wav'].find(type => type === asset.type) !== undefined
  }

  async getAsset(uuid) {
    try {
      const axiosInstance = axios.create(axiosConfig)
      const response = await axiosInstance.get(`pd/${uuid}`)
      if(response && response.data) {
        const asset = response.data
        this.setState({
          asset,
          thumbsUp: asset.totalThumbsUp,
          thumbsDown: asset.totalThumbsDown,
          thumbUp: asset.thumbUp,
          thumbDown: asset.thumbDown,
          views: asset.views
        })
      }
    } catch (err) {
      const { history } = this.props
      history.push('/error')
    }
  }

  render() {
    const { content, userInfo } = this.props
    const { asset } = this.state
    const locale = (userInfo && userInfo.language && userInfo.language.replace('_','-')) || 'es-es'

    if(!asset) return null
    return (
      <Fragment>
        <Container className="my-3 asset">
          <Row>
            <Col>
              <div className="asset-back d-inline" onClick={ this.goBack }>&lt; { content.assets.back }</div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="asset-preview-container">
          <Container className="mt-2 mx-auto">
            <Row>
              <Col className="px-0" xs={{ size: 12, offset: 0}} md={{ size: 8, offset:2}} lg={{ size: 6, offset: 3}}>
                <div className="asset-preview w-100">
                {
                  this.isVideo() ?
                    <video className="w-100 d-block" poster={ this.largeThumbnailUrl() } onPlay={ this.handleView } controls controlsList="nodownload">
                      <source src={ this.preview() } type={ asset.type } />
                    </video>
                    :
                  this.isAudio() ?
                    <Fragment>
                      <img className="w-100" alt={ asset.title } src={ this.largeThumbnailUrl() }/>
                      <audio className="px-1 w-100 d-block" onPlay={ this.handleView } controls controlsList="nodownload">
                        <source src={ this.preview() } type={ asset.type} />
                      </audio>
                    </Fragment>
                    :
                    <a onClick={ () => this.handleView(true) } href={ this.content() }><img className="w-100" alt={ asset.title } src={ this.largeThumbnailUrl() }/></a>
                }
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container className="my-2 my-sm-4">
          <Row>
            <Col xs={{ size: "12", order: 2}} sm={{ size: "6", order:1 }}><div className="mb-2 asset-category">{ asset.category.title }</div></Col>
            <Col xs={{ size: "12", order: 1}} sm={{ size: "6", order:2 }} className="mb-3 mb-sm-0 d-flex justify-content-end">
              <div className="asset-views text-noselect text-nowrap">{ nFormatter(this.state.views) } { content.assets.views }</div>
              <span className="asset-social-separator mx-2 text-noselect">·</span>
              <Thumbs
                className="text-noselect"
                thumbsUp={this.state.thumbsUp}
                thumbsDown={this.state.thumbsDown}
                thumbUp={this.state.thumbUp}
                thumbDown={this.state.thumbDown}
                onUpdate={this.handleLikes}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-2 asset-title">{ asset.title }</div>
            </Col>
          </Row>
          <Row className="asset-date">
            <Col>{asset.date && new Date(asset.date).toLocaleDateString(locale, { year: 'numeric', month: '2-digit', day: '2-digit' })}</Col>
          </Row>
          <Row>
            <Col>
              <div className="mt-1 mb-5 asset-description">{ parseToReactElement(asset.description, null,{ ADD_ATTR: ['target'] }) }</div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default withUserInfo(withRouter(withContent(PDAsset)))
