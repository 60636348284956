import React,{Component}from"react"
import{Button}from"reactstrap"
import{lti}from'../../services/wsLicense'
export default class ButtonLti extends Component{
  constructor(props){
    super(props)
    this.activateLicenses=this.activateLicenses.bind(this)
  }
  activateLicenses(titleUuid,e){
    e.preventDefault()
    lti(
      titleUuid,
      (data)=>{
        const form=document.createElement("form")
        form.setAttribute("action",data.action)
	form.setAttribute("method",data.method)
        form.setAttribute("target","_blanck")
        form.setAttribute("style","display:none")
          for(let k in data.parameters){
            let field=document.createElement('input')
            field.setAttribute('type','hidden')
            field.setAttribute('name',k)
            field.setAttribute('value',data.parameters[k])
            form.appendChild(field)
          }
          document.body.appendChild(form)
          form.submit()
      },
      (error)=>console.log('error',error)
    )
  }
  render(){
    const className = this.props.className ? this.props.className : "edit__library__accept"
    return (
      <Button
        className={ className }
        onClick={(e)=>this.activateLicenses(this.props.titleUuid,e)}>
        {this.props.children}
      </Button>
    )
  }
}
