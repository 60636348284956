import React from 'react'
import _ from 'lodash'
import { Card, CardImg, CardText } from 'reactstrap'
import cx from 'classnames'
import axios from 'axios'

import { convertStringToDate, formatDateToLocaleDateAndYearShort } from '../../../utils/date'
import { withContent } from '../../../utils/hocs'

import axiosConfig from '../../../config/axios'

import './Title.css'

const Title = ({
  content,
  onClick: gotoTitle,
  title,
  title: { uuid, cover, name, expiration, new: isNew },
  couponUser: { token }
}) => {
  const axiosClient = axios.create(axiosConfig)
  const onClick = () => {
    if(isNew) {
      axiosClient.delete(`/coupons/${token}/title/${uuid}/novelties`)
        // eslint-disable-next-line
        .then(() => title.new = false)
        .catch(() => {})
    }
    gotoTitle()
  }
  return (
    <Card
      className={
        cx(
          'h-100',
          'rounded-all',
          'bg-white',
          'p-3',
          'cursor-pointer',
          { 'coupons-title-new': isNew }
        )
      }
      onClick={onClick}
    >
      { isNew && (
        <div className="coupons-title-new-label">
          <div className="m-auto">
            {_.get(content, 'coupons.digitallibrary_new')}
          </div>
        </div>
      )}
      <CardImg
        top
        className="rounded-all"
        width="100%"
        src={cover}
        alt={name}
        title={name}
      />
      <CardText className="pt-3 text-center">
        <strong>{`${_.get(content, 'coupons.digitallibrary_title_expires', 'Válido hasta:')} `}</strong>
        { formatDateToLocaleDateAndYearShort(convertStringToDate(expiration), 'es') }
      </CardText>
    </Card>
  )
}

export default withContent(Title)
