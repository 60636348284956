import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { AuthProvider, AuthConsumer } from 'react-check-auth'
import { Progress } from 'reactstrap'

import { ContentProvider, getLocale } from './language'
import AppRouter from './routers/AppRouter'
import { FeatureProvider } from './contexts/Feature/FeatureContext'
import { PollConfigProvider } from './contexts/Polls/PollContext'
import WPDataProvider from './contexts/WPData/WPDataProvider'
import envSettings from './globals/envSettings'

const App = (props) => {

  return (
    <GoogleReCaptchaProvider reCaptchaKey={ envSettings.RECAPTCHA_CLIENT_ID }>
      <AuthProvider authUrl={envSettings.AUTH_URL}>
        <AuthConsumer>
          {({ userInfo, isLoading, error }) => {
            if (isLoading) {
              console.log("isLoading")
              return (<Progress animated color="primary" value="100" />)
            }
            if (error) {
              console.log("error")
              return (
                <ContentProvider contentUrl={envSettings.CONTENT_URL} locale={getLocale()}>
                  <AppRouter />
                </ContentProvider>
              )
            }
            if (!userInfo) {
              console.log("no userInfo")
              return (<Progress animated color="primary" value="100" />)
            }
            console.log(userInfo)
            return (
              <ContentProvider contentUrl={envSettings.CONTENT_URL} locale={getLocale(userInfo.language)}>
                <FeatureProvider>
                  <WPDataProvider>
                    <PollConfigProvider>
                      <AppRouter />
                    </PollConfigProvider>
                  </WPDataProvider>
                </FeatureProvider>
              </ContentProvider>
            )
          }}
        </AuthConsumer>
      </AuthProvider>
    </GoogleReCaptchaProvider>
  )
}

export default App

// MBQ TODO TEST CI DEV 2
