import React from 'react'
import { ContentConsumer } from '../../language'
import PropTypes from 'prop-types'
import { Alert, Form } from 'reactstrap'
import ButtonPremium from '../ButtonPremium/ButtonPremium'

const ChangeSchoolError = (props) => (
  (<ContentConsumer>
    {({ content = {}, isContentLoading }) => (content && (

      <div className="text-center d-flex flex-column justify-content-center m-5 changeSchoolError">
        <h2 className="m-3">{content.changeschool.changeschool_title}</h2>
        <div className="d-flex justify-content-center" >
          <Alert className="changeSchoolError__alert" color="danger">{props.errorMessage}</Alert>
        </div>
        <div className="d-flex justify-content-center" >
          <Form onSubmit={() => props.action(null, props.status.NO_SEARCH)} inline>
            <ButtonPremium type="submit">{content.sharedTerms.BACK_BUTTON_TEXT}</ButtonPremium>
          </Form>
        </div>
      </div>
    ))}
  </ContentConsumer>)
)

ChangeSchoolError.propTypes = {
  status: PropTypes.object.isRequired,
  action: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired
}

export default ChangeSchoolError