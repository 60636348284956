import React, { Component, Fragment } from 'react'
import { ContentConsumer } from '../../language'
import { Container, Row, Col, Progress } from 'reactstrap'
import qs from 'qs'
import validator from 'validator'
import _ from 'lodash'
import { parseToReactElement } from '../../utils/html'

class ProductViewer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect_uri: null,
      error: null,
      loading: true
    }
  }

  redirectToUri = uri => {
    window.location.href = uri
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const { redirect_uri, error } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

    if (!error && redirect_uri && validator.isURL(redirect_uri) &&
      /^(http(s?):\/\/)?(([a-zA-Z\-_0-9]+)\.)*oupe\.es($|\/.*)/.test(redirect_uri)) {
      this.setState({ redirect_uri, loading: false })
    } else {
      this.setState({ error, loading: false })
    }
  }

  render() {
    const { redirect_uri, error, loading } = this.state
    return (
      <ContentConsumer>
        {({ content }) => content && (
          <Fragment>
            {loading && (
              <Progress animated color="primary" value="100">{content.sharedTerms.LOADING_MESSAGE}</Progress>
            )}

            {!loading && redirect_uri ?
              (this.redirectToUri(redirect_uri))
              :
              (
                <Container className="w-100 h-100 px-lg-5 ct-product-error" fluid={true}>
                  <Row className="my-5">
                    <Col xs="12" md="8" className="mt-0">
                      <h1 className="title">
                        <span className="title-underlined">
                          {error === '403' ?
                            (<strong>{_.get(content, 'coupons.digitallibrary_ioproduct_error_title')}</strong>)
                            :
                            (<strong>{_.get(content, 'coupons.digitallibrary_generic_error_title')}</strong>)
                          }
                        </span>
                      </h1>
                    </Col>
                  </Row>
                  <Row className="my-3 description">
                    <Col>
                      {error === '403' ?
                        (parseToReactElement(
                          _.get(content, 'coupons.digitallibrary_ioproduct_error_body'),
                          null,
                          { ADD_ATTR: ['target'] }
                        ))
                        :
                        (parseToReactElement(
                          _.get(content, 'coupons.digitallibrary_generic_error_body'),
                          null,
                          { ADD_ATTR: ['target'] }
                        ))
                      }
                    </Col>
                  </Row>
                </Container>
              )
            }
          </Fragment>
        )}
      </ContentConsumer>
    )
  }
}

export default ProductViewer