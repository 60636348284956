import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { ContentConsumer } from '../../language'
import { getCourseMiniature } from './utils'

const PDCompletedCourses = ({ registrations }) => (
  <ContentConsumer>
    {({ content = {}, locale }) => (
      content && (
        !registrations || registrations.length === 0 ?
          (
            <Container fluid={true} className="completed-courses no-registration">
              <Row className="align-items-center">
                <Col xl={{ size: 10, offset: 1 }} sm="12" className="text-left">
                  <h4 className="label">{content.pdcourses.completedcourses_no_registration}</h4>
                </Col>
              </Row>
            </Container>
          )
          :
          (
            registrations.map(registration => (
              <Container fluid={true} className="completed-courses registration mb-4" key={registration.registrationId}>
                <Row>
                  <Col sm="12">
                    <Container fluid={true} className="pl-0 pr-0">
                      <Row className="align-items-center">
                        <Col sm="12" md="2" className="text-left">                          
                          <img
                            alt={registration.courseTitle}
                            src={getCourseMiniature(registration.lmsMasterCourseId, (locale === 'es' ? locale : 'en'))}
                            className="miniature"
                          />
                        </Col>
                        <Col sm="12" md="10" className="text-left pt-5 pt-md-0">
                          <p className="course-title">{registration.courseTitle}</p>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            ))
          )
      ))}
  </ContentConsumer>
)

export default PDCompletedCourses