import React, { Fragment } from 'react'
import GpaContact from '../GpaContact/GpaContact'
import { material_plus, material_edit, material_minus } from '../../utils/gpa-utils'

const renderMaterials = (materials, editableMaterial, handler) => {
  return materials.map((material, index) => (
    <div key={material.idMaterial} className={index % 2 === 0 ? 'row align-items-center form-group row-shadow' : 'row align-items-center form-group'}>
      {
        editableMaterial ?
          (
            <Fragment>
              <div className="col-xs-12 col-sm-6 col-md-7 text-left">
                <label className="control-label text-center mb-xs-0 mx-auto">{material.desMaterial}</label>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-5 mt-2 mb-2 text-center">
                <button className="btn-add-delete minus" onClick={() => handler(null, material.idMaterial, material_minus)}>-</button>&nbsp;
                <input type="text" value={material.unidadesActual} data-enviadas={material.unidadesEnviadas} maxLength="4" size="" placeholder="" className={`form-control material ${material.tipoMaterial}`} onChange={event => handler(event, material.idMaterial, material_edit)} />&nbsp;
                <button className="btn-add-delete plus" onClick={() => handler(null, material.idMaterial, material_plus)}>+</button>
              </div>
            </Fragment>
          )
          :
          (
            <Fragment>
              <div className="col-7 text-left">
                <label className="control-label mx-auto">{material.desMaterial}</label>
              </div>
              <div className="col-5 mt-2 text-center">
                <p className='text-number'>
                  <span className={`text-number material ${material.tipoMaterial}`}><strong>{material.unidadesActual}</strong></span>
                </p>
              </div>
            </Fragment>
          )
      }
    </div>
  ))
}

const GpaOderMaterials = ({ materials, editableHeader, editableMaterial, onChangeMaterial, content }) => (
  <div className="col-sm-12 pedir-material">
    <hr />
    {
      editableMaterial ?
        <p className="mt-2 mb-4">{content.gratisadoption.gxa_28}:</p>
        :
        <p className="text-label mt-4 mb-4"><strong>{content.gratisadoption.gxa_41}</strong></p>
    }
    {editableMaterial && !editableHeader && <p className="text-label mt-2 mb-4"><strong>{content.gratisadoption.gxa_41}</strong></p>}

    <div className="row title-table mb-4">
      <div className="col-7 text-left">{content.gratisadoption.gxa_29}</div>
      <div className={editableMaterial ? 'col-5 text-center d-none d-sm-block' : 'col-5 text-center'}>{content.gratisadoption.gxa_30}</div>
    </div>

    {renderMaterials(materials, editableMaterial, onChangeMaterial)}

    {!editableMaterial && (
      <div className="row">
        <div className="col-sm-12  mt-4">
          <p className="small">{content.gratisadoption.gxa_39}:</p>
          <p className="small">{content.gratisadoption.gxa_42}</p>
          <p className="small">
            {content.gratisadoption.gxa_43}&nbsp;<GpaContact>{content.gratisadoption.gxa_36}</GpaContact>
          </p>
        </div>
      </div>
    )}
  </div>
)

export default GpaOderMaterials