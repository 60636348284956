import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { Progress, Container, Row, Col } from 'reactstrap'
import axios from 'axios'
import cx from 'classnames'

import axiosConfig from '../../config/axios'
import { AuthConsumer } from 'react-check-auth'
import { ContentConsumer } from '../../language'
import userRoles from '../../globals/userRoles'
import BreakingNewsItem from './BreakingNewsItem'

import NewsSettings, { ClientSettings } from './settings'

class BreakingNews extends Component {
    constructor(props) {
        super(props)
        this.state = {
          newsFeed: [],
          error: false,
          filteredNewsFeed: []
        }
    }

  getFilteredNewsFeed = async(newsFeed) => {

    if (this.props.userInfo.role !== userRoles.USER_INTERNAL ) {
      // Get valid categories
      try {
        const axiosInstance = axios.create(axiosConfig)
        const response = await axiosInstance.get('news')
        const preferences = response.data
        const languages = _.compact([ preferences.language, NewsSettings.NEWS_LANGUAGES_ROOT ])
        const stages = [ ...preferences.stage, NewsSettings.NEWS_STAGES_ROOT ]
        const subjects = [ ...preferences.subject, NewsSettings.NEWS_AREAS_ROOT ]
        const regions = _.compact([ preferences.region, preferences.country, NewsSettings.NEWS_COUNTRIES_ROOT ])

        // Filter the news
        newsFeed = newsFeed && newsFeed.reduce((acc, news) => {
          if(!_.intersection(news.filterCategories,languages).length) return acc
          if(!_.intersection(news.filterCategories,stages).length) return acc
          if(!_.intersection(news.filterCategories,subjects).length) return acc
          if(!_.intersection(news.filterCategories,regions).length) return acc

          delete news.filterCategories
          acc.push(news)
          return acc
        }, [])
      } catch(error) {
        this.setState({error})
      }
    }

    const assets = _.sortBy(newsFeed , ['categories[0].slug'])

    this.setState({
      newsFeed,
      filteredNewsFeed: assets
    })
  }

    componentDidMount() {
      this.getNewsFeed()
    }

    getNewsFeed = () => {
      let categories = []

      const NewsClient = axios.create(ClientSettings)

      NewsClient.get(`categories?parent=${NewsSettings.NEWS_BREAKINGNEWS_ROOT}`)
        .then(response => {
          categories = response.data && response.data.map((item, index) => Object.assign({}, _.pick(item, ['id','slug', 'name', 'description']), { slug: item.slug && item.slug.replace(/_.*/, '') }))
          const catList = categories.map((cat) => (cat.id)).join('+')
          NewsClient.get(`posts/?_embed=&per_page=${NewsSettings.NEWS_MAX_ITEMS}&categories=${catList}`)
            .then((response) => {
              const newsFeed = response.data.reduce((acc, item) => {
                let news = {
                  id : item.id,
                  slug: item.slug,
                  title : _.get(item, 'title.rendered'),
                  excerpt: _.get(item, 'excerpt.rendered'),
                  content: _.get(item, 'content.rendered'),
                  filterCategories: item.categories,
                  categories: item.categories && (item.categories.length >0) && item.categories.reduce((acc,idCategory) => {
                    const category = categories.find(category => category.id===idCategory)
                    if(category) acc.push(category)
                    return acc
                  }, [])
                }

                let image = _.get(item, ['_embedded', 'wp:featuredmedia', 0, 'media_details', 'sizes', 'full', 'source_url'])
                let image_medium = _.get(item, ['_embedded', 'wp:featuredmedia', 0, 'media_details', 'sizes', 'medium', 'source_url'])

                if(image) {
                  news.image = {
                    url : image,
                    alt :_.get(item, [ '_embedded', 'wp:featuredmedia', 0, 'alt-text' ])
                  }
                }
                if(image_medium) {
                  news.image_medium = {
                    url : image_medium,
                    alt :_.get(item, [ '_embedded', 'wp:featuredmedia', 0, 'alt-text' ])
                  }
                } else if(image) {
                  news.image_medium = {
                    url : image,
                    alt :_.get(item, [ '_embedded', 'wp:featuredmedia', 0, 'alt-text' ])
                  }
                }

                if(news.id && news.title && news.content && news.image && news.image_medium) acc.push(news)
                return acc
              }, [])

              this.getFilteredNewsFeed(newsFeed)
          })
            .catch((error) => {
              this.setState({ error })
            })
        })
        .catch(error => {
          this.setState({ error })
        })
    }

  render() {
    const { className } = this.props
    const { filteredNewsFeed, newsFeed, error } = this.state
    const news = _.take(filteredNewsFeed, 3)

    if (error || newsFeed.length === 0) return []
    else
      return (
        <ContentConsumer>
          {({ content = {}, isContentLoading }) => {
            if (content) {
              if (isContentLoading) {
                return <Progress animated color="primary" value="100" > {content.news.news_loading} </Progress>
              }
              return (
                <Fragment>
                  <Container className={cx("my-5 breaking-news", className)}>
                    <Row className="mb-3">
                      <Col>
                        <h1 className="text-uppercase">{content.news.breakingnews_title}</h1>
                      </Col>
                    </Row>
                    <Row>
                    { news.map(news => (
                        <Col key={news.id} className="mb-3 mb-md-0" xs="12" md={true}>
                          <BreakingNewsItem news={ news } />
                        </Col>
                      ))
                    }
                    </Row>
                  </Container>
                </Fragment>
              )
            }
          }}
        </ContentConsumer>
      )
  }
}

export default props => (
  <AuthConsumer>
    {({ userInfo }) => <BreakingNews userInfo={userInfo} {...props} />}
  </AuthConsumer>
)


