import React from 'react'
import { NavLink } from 'react-router-dom'
import { UncontrolledTooltip } from 'reactstrap'
import GpaOrderdButton from '../GpaOrderButton/GpaOrderButton'
import { estado_pendiente, getDefaultCover, DEFAULT_COVER } from '../../utils/gpa-utils'

const GpaOrderCardItem = ({ data, userId, stateData, actionRequired, baseUrl, content }) => (
  <div className='col-sm-12 col-md-6'>
    <div className='well well-cards-adopcion'>
      {actionRequired && <span className="badge number-adopcion pull-right">&nbsp;</span>}
      <div className='row row-sm-center'>
        <div className='col-sm-12 col-md-4 col-lg-5'>
          <NavLink exact={true} to={`${baseUrl}/${data.idSolicitud}`}>
            {data.portadaTitulo ?
              <img src={data.portadaTitulo} title={data.desTitulo} alt={data.desTitulo} className='img-responsive image-recurso' onError={getDefaultCover} />
              :
              <img src={DEFAULT_COVER} title={data.desTitulo} alt={data.desTitulo} className='img-responsive image-recurso' />
            }
          </NavLink>
        </div>
        <div className='col-sm-12 col-md-8 col-lg-7 text-center'>
          <h4>{data.desTitulo}</h4>
          <p className='course'>{`${data.desAsignatura} - ${data.desNivel}`}</p>
          {
            (data.estado.idEstado === estado_pendiente) ?
              (<p className="state invisible"></p>)
              :
              (<p className='state'>
                <span className={stateData.icon}>&nbsp;</span>
                <span className='help-state' href='#' id={`state-${data.idSolicitud}`}>
                  {content.gratisadoption[stateData.label]}
                </span>
                <UncontrolledTooltip placement='top' target={`state-${data.idSolicitud}`} className='gpa-tooltip'>
                  {content.gratisadoption[stateData.tooltip]}
                </UncontrolledTooltip>
              </p>)
          }
          <GpaOrderdButton order={data} userId={userId} actionRequired={actionRequired} baseUrl={baseUrl} content={content} />
        </div>
      </div>
    </div>
  </div>
)

export default GpaOrderCardItem