import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import { ContentConsumer } from '../../language'
import { getCourseMiniature } from './utils'
import envSettings from '../../globals/envSettings'

const PDMyCoursesTop = ({ registration, announcement, showRegistration }) => (
  <ContentConsumer>
    {({ content = {}, locale }) => (
      content && (
        registration !== null ?
          (
            <Container fluid={true} className="mycourses registration">
              <Row>
                <Col sm="12">
                  <Container fluid={true} className="pl-0 pr-0">
                    <Row className="align-items-center">
                      <Col sm="12" md="2" className="text-left">
                        <img
                          alt={registration.courseTitle}
                          src={getCourseMiniature(registration.lmsMasterCourseId, (locale === 'es' ? locale : 'en'))}
                          className="miniature"
                        />
                      </Col>
                      <Col sm="12" md="5" lg="6" className="text-left pt-5 pt-md-0">
                        <p className="course-title">{registration.courseTitle}</p>
                        <p>{content.pdcourses.mycourses_end_date}&nbsp;{registration.courseEndDateShort}</p>
                      </Col>
                      <Col sm="12" md="5" lg="4" className="text-right pt-5 pt-md-0">
                        <Container fluid={true} className="pl-0 pr-0">
                          <Row>
                            <Col sm="12" md="6">
                              <ButtonPremium
                                disabled={!registration.accessOn}
                                tag={"a"}
                                href={`${envSettings.API_BASEURL}/pd-courses/announcement/${registration.announcementId}/registration/${registration.registrationId}`}
                                target="_blank" rel="noopener noreferrer" primary
                              >
                                {content.pdcourses.button_access}
                              </ButtonPremium>
                            </Col>
                            <Col sm="12" md="6" className="pt-2 pt-md-0">
                              <ButtonPremium
                                disabled={!announcement.registrationOn}
                                onClick={() => showRegistration(true)}
                              >
                                {content.pdcourses.button_cancel}
                              </ButtonPremium>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          )
          :
          (
            <Container fluid={true} className="mycourses no-registration">
              <Row className="align-items-center">
                <Col xl={{ size: 10, offset: 1 }} className="text-center col-12">
                  <Container fluid={true}>
                    <Row className="align-items-center">
                      <Col className="text-left col-12 col-md-7 col-lg-6">
                        <h4 className="label">{content.pdcourses.mycourses_no_registration}</h4>
                      </Col>
                      <Col className="text-right col-12 col-md-4 offset-md-1 offset-lg-2 pt-5 pt-md-0">
                        <ButtonPremium
                          disabled={!announcement.registrationOn}
                          onClick={showRegistration}
                          primary
                        >
                          {content.pdcourses.button_register}
                        </ButtonPremium>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          )
      ))}
  </ContentConsumer>
)

export default PDMyCoursesTop