import React, { Component } from 'react'
import WPDataContext from './WPDataContext'
import PropTypes from 'prop-types'

class WPDataConsumer extends Component {
  render() {
    return (
      <WPDataContext.Consumer>
        {props => this.props.children({ ...props })}
      </WPDataContext.Consumer>
    )
  }
}

WPDataConsumer.propTypes = {
  children: PropTypes.func.isRequired
}

export default WPDataConsumer