import React from 'react'
import _ from 'lodash'
import { Container, Row, Col, Input } from 'reactstrap'
import { withContent } from '../../../utils/hocs'
import { parseToReactElement } from '../../../utils/html'

const OrderEmail = ({ content, email, onChange, isValid }) => (
  <Container className="my-0 mx-0" fluid>
    <Row className="my-3 px-0 border-bottom-blue">
      <Col className="px-0">
        <h4>{_.get(content, 'coupons.purchase_confirmation_email_title')}</h4>
      </Col>
    </Row>
    <Row className="my-3 px-0">
      <Col className="px-0">
        <p className='font-medium-1'>
          {parseToReactElement(_.get(content, 'coupons.purchase_confirmation_email_body_1'))}
        </p>
        <p className='mt-5 font-medium-1'>
          <strong>
            {parseToReactElement(_.get(content, 'coupons.purchase_confirmation_email_body_2'))}
          </strong>
        </p>
        <p className='mt-2 font-small-9'>
          {_.get(content, 'coupons.purchase_confirmation_email_body_3')}
        </p>
      </Col>
    </Row>
    <Row className="my-4 px-0">
      <Col className="pl-0 pr-0 pr-lg-5">
        <Input
          type="email"
          id="order_email"
          name="order_email"
          autoComplete="off"
          value={email}
          onChange={onChange}
          placeholder={''}
          valid={!!email && isValid}
          invalid={!!email && !isValid}
        />
      </Col>
    </Row>
  </Container>
)

export default withContent(OrderEmail)