import React from 'react'
import _ from 'lodash'
import { Container, Row, Col } from 'reactstrap'
import OrderEmail from './OrderEmail'
import OrderCart from './OrderCart'
import ButtonCoupons from '../../ButtonCoupons/ButtonCoupons'
import { withContent } from '../../../utils/hocs'
import { parseToReactElement } from '../../../utils/html'

const OrderConfirmation = ({
  content,
  order,
  onCancel,
  onSubmit,
  onChangeEmail,
  isValidEmail
}) => (
  <Container className="w-100 h-100 px-lg-5 font-color-black order-confirmation" fluid>
    <Row className="my-3">
      <Col xs="12" md="8" className="order-last order-md-first mt-5 mt-md-0">
        <h1 className="title">
          <span className="title-underlined">
            <strong>{_.get(content, 'coupons.purchase_confirmation_title')}</strong>
          </span>
        </h1>
      </Col>
      <Col className="text-right" xs="12" md="4">
        <ButtonCoupons primary onClick={onCancel}>
          {_.get(content, 'coupons.purchase_confirmation_cancel_button')}
        </ButtonCoupons>
      </Col>
    </Row>

    <Row className="px-3 px-md-4 px-lg-5 py-1 py-md-3 mx-0 bg-white">
      <Col xs="12" md="6" lg="8" className="mx-0 pl-0 pr-0 pr-md-4 pb-4 pb-md-0 border-bottom-blue-small">
        <OrderEmail email={order.email} onChange={onChangeEmail} isValid={isValidEmail} />
      </Col>
      <Col xs="12" md="6" lg="4" className="mx-0 pl-4 pr-0">
        <OrderCart order={order} />
      </Col>
    </Row>

    <Row className="px-3 px-md-4 px-lg-5 py-1 py-md-3 mx-0 bg-white">
      <Col className="px-0 mb-3">
        <p className='font-small-9 font-color-grey'>
          {parseToReactElement(_.get(content, 'coupons.purchase_confirmation_terms'), null, { ADD_ATTR: ['target'] })}
        </p>
      </Col>
    </Row>

    <Row className="mt-4 mb-0 mx-1 border-top-blue">
      <Col></Col>
    </Row>

    <Row className="my-3">
      <Col xs="12" md="8">
        <Container fluid className="pl-0 pr-0">
          <Row className="align-items-center">
            <Col className="text-right" xs="3" md="2">
              <img alt='padlock' className="padlock-icon" src="https://static.oupe.es/img/padlock2.png" />
            </Col>
            <Col className="pl-0 padlock-legend">
              <p className="w-100 font-small-9">
                {parseToReactElement(_.get(content, 'coupons.purchase_confirmation_tpv'))}
              </p>
            </Col>
          </Row>
        </Container>
      </Col>
      <Col className="text-right mt-5 mt-md-0" xs="12" md="4">
        <ButtonCoupons primary onClick={onSubmit} disabled={!isValidEmail}>
          {_.get(content, 'coupons.purchase_confirmation_submit_button')}
        </ButtonCoupons>
      </Col>
    </Row>
  </Container>
)

export default withContent(OrderConfirmation)