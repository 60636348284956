import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import axios from 'axios'
import { Collapse, Navbar, NavbarToggler, NavbarBrand } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { AuthConsumer } from 'react-check-auth'
import axiosConfig from '../../config/axios'
import { ContentConsumer } from '../../language'
import NavList from './components/navList'
// MBQ TODO export default Colors
import { Colors } from './components/Colors'
import Modal from '../Modal/Modal'
import GpaResponsableModal from '../GpaResponsableModal/GpaResponsableModal'
import logo from '../../assets/images/Logo-Oxford-Premium.png'
import demo from '../../assets/images/demo.png'
import userRoles from '../../globals/userRoles'
import { isClassroomError, isStudentArea, isProductViewer } from '../../globals/envSettings'
import { Poll, ManagedSurvey } from 'components/Polls'
import { PARENT_COMPONENT } from 'components/Polls/ManagedSurvey/ManagedSurvey'


const colors = ['red', 'pink', 'yellow', 'green', 'aqua', 'blue']

const axiosClient = axios.create({ ...axiosConfig })

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false, showResponsableModal: false }
  }

  handleCollapse = () => this.setState(prevState => ({
    isOpen: !prevState.isOpen
  }))

  toggleResponsableModal = fn => {
    this.setState(prevState => ({
      showResponsableModal: !prevState.showResponsableModal
    }))
    if (fn && typeof fn === 'function') fn()
  }

  render() {
    const { isOpen, showResponsableModal } = this.state
    const studentArea = isStudentArea(this.props.location.pathname)
    const classroomError = isClassroomError(this.props.location.pathname)
    const productViewer = isProductViewer(this.props.location.pathname)

    return (
      !productViewer ?
        (
          <div>
            <Navbar className="headerOUP" color="dark" expand="md">
              <AuthConsumer>
                {({ userInfo, refreshAuth }) => (
                  <ContentConsumer>
                    {({ content, locale, refreshContent }) => (content &&
                      <Fragment>
                        {((studentArea || classroomError) && !userInfo)
                          ? (
                            <NavbarBrand>
                              <img src={logo} className="mr-2" alt="Oxford Premium" />
                            </NavbarBrand>
                          )
                          : (
                            <NavbarBrand tag={NavLink} to="/">
                              <img src={logo} className="mr-2" alt="Oxford Premium" />
                              {userInfo && userInfo.role === userRoles.TEACHER_DEMO && <img src={demo} alt="Oxford Premium" />}
                            </NavbarBrand>
                          )
                        }
                        {(!(studentArea || classroomError) || userInfo)
                          && <Fragment>
                            <Poll />                            
                            <NavbarToggler className="headerOUP__toggler" onClick={this.handleCollapse} />
                            <Collapse id="collapseTest" isOpen={isOpen} navbar>
                              <ManagedSurvey parent={PARENT_COMPONENT.HEADER} />
                              <NavList
                                content={content}
                                userInfo={userInfo}
                                toggleResponsableModal={userInfo && this.toggleResponsableModal}
                                locale={!userInfo && locale}
                                onChangeLanguage={(language) => {
                                  if (userInfo) {
                                    axiosClient.put('/user/language', { language })
                                      .then(refreshAuth)
                                      .catch(() => { })
                                  } else {
                                    refreshContent(language)
                                  }
                                }} />
                            </Collapse>
                            {userInfo &&
                              <Modal className={'responsable_modal'} isOpen={showResponsableModal}
                                toggle={this.toggleResponsableModal}
                                header={content.gratisadoption.gxa_1}
                                subtitle={content.gratisadoption.gxa_3}
                              >
                                <GpaResponsableModal userInfo={userInfo} refreshAuth={refreshAuth} history={this.props.history} content={content} toggleModal={this.toggleResponsableModal} />
                              </Modal>
                            }
                          </Fragment>
                        }
                      </Fragment>
                    )}
                  </ContentConsumer>
                )}
              </AuthConsumer>
            </Navbar>
            <Colors colors={colors} />
          </div>
        )
        :
        ( null )
    )
  }
}

// MBQ TODO colors no viene del padre, se define en el componente actual como array invariable,
// por lo que es más una constante que una prop
// Trasladar colors al componente Colors en lugar de pasárselo como prop ????
// Revisar impacto en test
/*Header.propTypes = {
  colors: PropTypes.array
}
Header.defaultProps = {
  colors: []
}*/

export default Header