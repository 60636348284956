import React, { Component } from 'react'
import { Container, Row } from 'reactstrap'
import cx from 'classnames'

import Banner from './Banner'
import Modal from '../Modal/Modal'
import ActivationCode from'../ActivationCode/ActivationCode'
import { isTeacherRoleAsync, hasAccessToGratisHP, isTeacherDemoAsync, hasGXA } from './validators'
import { withContent, withUserInfo } from '../../utils/hocs'

class TopBanners extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activationCode: false
    }
  }

  render() {
    const { className, content, userInfo } = this.props
    const banners = []
    banners.push({
      contentId: 'PREM_CHANGESCHOOL',
      target: '/change-school',
      visible: isTeacherRoleAsync(userInfo)
    })
    banners.push({
      contentId: 'PREM_BANNERGXA',
      target: '/gratis-adoption',
      visible: hasGXA(userInfo)
    })
    banners.push({
      contentId: 'PREM_BANNERGRATISHPS',
      target: '/gratishp',
      visible: hasAccessToGratisHP()
    })
    banners.push({
      contentId: 'PREM_BANNERCODIGO',
      visible: isTeacherDemoAsync(userInfo),
      component: () => (
        <Modal
          className="mylibrary__modal_sendInfo"
          isOpen={ this.state.activationCode }
          toggle={() => this.setState({ activationCode: !this.state.activationCode })}
          header={ content.activationcode.activationcode_premiumcode_label_text }>
          <ActivationCode
            toggleModal={() => this.setState({ activationCode: !this.state.activationCode })}
          />
        </Modal>
      ),
      onClick: () => this.setState({ activationCode: true })
    })
    return (
      <Container className={cx("banners", className)}>
        <Row>
          { banners.map((banner, index) => (
              <Banner key={index} { ...banner } />
            ))
          }
        </Row>
      </Container>
    )
  }
}

export default withContent(withUserInfo(TopBanners))
