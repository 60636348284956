import React, { Fragment } from 'react'
import _ from 'lodash'
import { Container, Row, Col } from 'reactstrap'
import cx from 'classnames'

import License from './License'
import ProductButton from './ProductButton'
import ButtonCoupons from '../../../ButtonCoupons/ButtonCoupons'
import DiscountBall from './DiscountBall'
import DigitalProducts from '../../../../globals/digitalProducts'
import { withContent } from '../../../../utils/hocs'
import { convertStringToDate, formatDateToLocaleDateAndYearShort } from '../../../../utils/date'
import { formatAmount, truncDecimals } from '../../../../utils/format'

import './Product.css'

const PAYMENT_STATUS = {
  UNPAID: "UNPAID",
  PAID: "PAID",
  FREE: "FREE",
}

const Product = ({
  content,
  token,
  product,
  product: { cover, expiration, license, name, shortName, type, paymentStatus, discount, price, totalAmount, new: isNew },
  addToShoppingCart,
  onError
}) => {
  const { productType } = DigitalProducts
  return (
    <Container
      className={cx(
        'py-3',
        'ct-product',
        paymentStatus === PAYMENT_STATUS.UNPAID ? 'promotion' : 'no-promotion',
        { 'coupons-product-new': isNew }
      )}
      fluid
    >
      { isNew && (
        <div className="coupons-product-new-label">
          <div className="m-auto">
            {_.get(content, 'coupons.digitallibrary_new')}
          </div>
        </div>
      )}
    {/*<Container className="py-4 bg-white ct-product" fluid> */}
      <Row className="h-100 py-2 py-md-0 px-0 align-items-center">
        <Col className="d-table-cell py-1 d-flex" xs="12" md="4">
          <div className="product-cover">
            <img width="100%" className="m-auto rounded-all" src={cover} alt={name} />
            {/*
            {paymentStatus === PAYMENT_STATUS.UNPAID && (
              <small className='product-price'>
                {_.get(content, 'coupons.digitallibrary_price')}
                <span className='pvp'>{` ${formatAmount(price)}€`}</span>
                <span>{` ${formatAmount(totalAmount)}€`}</span>
              </small>
            )}
            */}
            {paymentStatus !== PAYMENT_STATUS.UNPAID && productType.PANGEA === type && (
              <span className="w-100 text-center product-whatispangea">
                <a className="product-whatispangea" target="_blank" rel="noopener noreferrer" href={_.get(content, 'coupons.digitallibrary_what_is_pangea_url')}>
                  {_.get(content, 'coupons.digitallibrary_what_is_pangea')}
                </a>
              </span>
            )}
            {
              [PAYMENT_STATUS.FREE, PAYMENT_STATUS.UNPAID].includes(paymentStatus) && (
                <DiscountBall
                  label={
                    paymentStatus === PAYMENT_STATUS.FREE ?
                      _.get(content, 'coupons.digitallibrary_free') : `-${truncDecimals(discount)}%`
                  }
                  type={paymentStatus.toLowerCase()} />
              )
            }
          </div>
        </Col>
        <Col className="d-table-cell text-left pt-1 my-0 col-data" xs="12" md="8">
          <Container className="w-100 px-0 py-0 ct-data" fluid>
            {paymentStatus === PAYMENT_STATUS.UNPAID && (
              <Row className="py-2 py-md-0 align-items-start row-promotion-label">
                <Col>{_.get(content, 'coupons.digitallibrary_promotion_label')}</Col>
              </Row>
            )}

            <Row className="py-2 py-md-0 product-name">
              <Col>
                {
                  shortName.endsWith('...') ?
                    (<span title={name} className="truncate">{shortName}</span>)
                    :
                    (<span>{name}</span>)
                }
              </Col>
            </Row>

            {paymentStatus === PAYMENT_STATUS.UNPAID && (
              <Fragment>
                <Row className="py-2 py-md-0 row-price">
                  <Col className="my-auto">
                    <div className="my-auto product-price">
                      {_.get(content, 'coupons.digitallibrary_price')}
                      <span className='pvp'>{` ${formatAmount(price)}€`}</span>
                      <span>{` ${formatAmount(totalAmount)}€`}</span>
                    </div>
                  </Col>
                </Row>
                <Row className="py-2 py-md-0 align-items-center row-promotion">
                  <Col className="product-promotion">
                    <span>{_.get(content, 'coupons.digitallibrary_promotion_date')}</span>&nbsp;
                    <span>
                      {formatDateToLocaleDateAndYearShort(convertStringToDate(product.promotionEndDate), 'es')}
                    </span>
                  </Col>
                </Row>
                <Row className="pt-2 pt-md-0 align-items-end text-right row-button">
                  <Col>
                    <ButtonCoupons
                      primary
                      className="product-bt-purchase"
                      onClick={() => addToShoppingCart(product)}
                    >
                      {_.get(content, 'coupons.digitallibrary_purchase')}
                    </ButtonCoupons>
                  </Col>
                </Row>
              </Fragment>
            )}

            {paymentStatus !== PAYMENT_STATUS.UNPAID && (
              <Fragment>
                {[productType.OLB, productType.BLINK, productType.PANGEA].includes(type) && (
                  <Row className="py-2 py-md-0 align-items-center row-license">
                    <Col>
                      <License
                        label={_.get(content, 'coupons.digitallibrary_activation_code')}
                        alt={_.get(content, 'coupons.digitallibrary_license_copy')}
                        license={license}
                      />
                    </Col>
                  </Row>
                )}

                <Row className={`py-2 py-md-0 align-items-center ${[productType.ONLINE_EXTERNAL, productType.ONLINE_INTERNAL].includes(type) ? 'row-expires-40' : 'row-expires'}`}>
                  <Col
                    className={`product-label-small ` +
                      `${[productType.ONLINE_EXTERNAL, productType.ONLINE_INTERNAL].includes(type) ? 'color-primary-blue' : ''}`}
                  >
                    {productType.OLB === type && (
                      <span>
                        {_.get(content, 'coupons.digitallibrary_title_expires_olb')}
                      </span>
                    )}
                    {productType.OLB !== type && (
                      <span>
                        {_.get(content, 'coupons.digitallibrary_title_expires', 'Válido hasta')}&nbsp;
                        {formatDateToLocaleDateAndYearShort(convertStringToDate(expiration), 'es')}
                      </span>
                    )}
                  </Col>
                </Row>
                <Row className="pt-2 pt-md-0 align-items-end text-right row-button">
                  <Col>
                    <ProductButton
                      token={token}
                      product={product}
                      onError={onError}
                    />
                  </Col>
                </Row>
              </Fragment>
            )}
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default withContent(Product)
