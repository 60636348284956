import React, { Component } from 'react'
import { AuthConsumer } from 'react-check-auth'
import { ContentConsumer } from '../../language'
import { Container } from 'reactstrap'

import CMSContent from '../CMSContent/CMSContent'
import { getLocale } from '../../language'
import { Button, Col, Row } from 'reactstrap';

class HPError extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <AuthConsumer>
        {({ userInfo }) => {
          return (
            <ContentConsumer>
              {({ content }) => {
                return (
                  content &&
                  (<Container>
                    <div className="HPError py-3" >
                      <Row className="border-top border-bottom py-3">
                        <CMSContent pageid="PREM_HPSKO" cache={true} language={userInfo ? getLocale(userInfo.language) : getLocale()} />
                      </Row>
                      <Row>
                        <Col className="d-flex justify-content-center py-3">
                          <Button className="HPError__accept" onClick={ () => window.location.href = `/app` }>{content.sharedTerms.ACCEPT_BUTTON_TEXT}</Button>
                        </Col>
                      </Row>
                    </div>
                  </Container>)
                )
              }}
            </ContentConsumer>
          )
        }}
      </AuthConsumer>
    )
  }
}
export default HPError
