import React from 'react'
import _ from 'lodash'
import { Container, Row, Col, Input } from 'reactstrap'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import validator from 'validator'
import axios from 'axios'

import ButtonCoupons from '../ButtonCoupons/ButtonCoupons'
import { withContent } from '../../utils/hocs'
import { parseToReactElement } from '../../utils/html'

import axiosConfig from '../../config/axios'

const axiosClient = axios.create({ ...axiosConfig, timeout: 60000 })

class Forgot extends React.Component {
  constructor() {
    super()
    this.state = {
      email: '',
      isValid: false
    }
  }

  handleInputChange = (event) => {
    const { target: { value } } = event
    this.setState({ email: value, isValid: validator.isEmail(value) })
  }

  isValid = () => {
    const { email, isValid } = this.state
    return !!email && isValid
  }

  submit = async () => {
    const { googleReCaptchaProps, onError, onSuccess } = this.props
    const { email } = this.state
    const token = await googleReCaptchaProps.executeRecaptcha('coupons')

    axiosClient.post('/coupons/forgot', { email, token })
      .then(() => onSuccess())
      .catch(() => onError())
  }

  render() {
    const { content } = this.props
    const { email, isValid } = this.state
    return (
      <Container className="px-md-0" fluid>
        <Row><Col><h2 className="coupons_title">{ _.get(content, 'coupons.redemption_forgot_title') }</h2></Col></Row>
        <Row className="my-2"><Col>{ parseToReactElement(_.get(content, 'coupons.redemption_forgot_body'), null, { ADD_ATTR: ['target'] }) }</Col></Row>
        <Row className="my-2">
          <Col md="6">
            <Input
              type="email"
              id="forgot_email"
              name="forgot_email"
              autoComplete="off"
              value={email}
              onChange={this.handleInputChange}
              placeholder={content.coupons.redemption_type_email}
              valid={!!email && isValid}
              invalid={!!email && !isValid}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <ButtonCoupons
              primary
              disabled={!this.isValid()}
              onClick={this.submit}
            >
              { _.get(content, 'coupons.redemption_forgot_button') }
            </ButtonCoupons>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withGoogleReCaptcha(withContent(Forgot))
