
import DOMPurify from 'dompurify'
import Parser from 'html-react-parser'

export function sanitize(htmlString, sanitizeOptions = {}) {
    return DOMPurify.sanitize(htmlString, sanitizeOptions)
}

export function parseToReactElement(htmlString, parserOptions = {}, sanitizeOptions = {}) {
    return Parser(sanitize(htmlString, sanitizeOptions), parserOptions)
}