const dateShortOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
const dateYearShortOptions = { year: '2-digit', month: '2-digit', day: '2-digit' }
const dateLongOptions = { year: 'numeric', month: 'long', day: 'numeric' }

export const formatDateToLocaleDateShort = (date, locale) => (
  formatDateToLocaleDate(date, locale, dateShortOptions)
)

export const formatDateToLocaleDateAndYearShort = (date, locale) => (
  formatDateToLocaleDate(date, locale, dateYearShortOptions)
)

export const formatStrDateToLocaleDateShort = (strDate, locale) => (
  formatDateToLocaleDate(convertStringToDate(strDate), locale, dateShortOptions)
)

export const formatDateToLocaleDateLong = (date, locale) => (
  formatDateToLocaleDate(date, locale, dateLongOptions)
)

export const formatStrDateToLocaleDateLong = (strDate, locale) => (
  formatDateToLocaleDate(convertStringToDate(strDate), locale, dateLongOptions)
)

export const formatDateToLocaleDate = (date, locale, options = dateShortOptions) => (
  date.toLocaleDateString(locale, options)
)

export const compareStrDates = (strDate1, strDate2) => (
  compareDates(convertStringToDate(strDate1), convertStringToDate(strDate2))
)

export const compareDates = (date1, date2) => {
  const time1 = date1.getTime()
  const time2 = date2.getTime()
  return time1 === time2 ? 0 : time1 > time2 ? 1 : 2
}

export const convertStringToDate = strDate => new Date(strDate)

export const convertStringToDateWithoutTime = strDate => {
  const dateSplit = strDate.split(/T\s*/)
  return new Date(dateSplit[0].replace(/-/g, "/"))
}