export const adoptionTypes = {
  NO_TYPE: 'SIN_TIPO',
  ADOPTION: 'ADOPCION',
  DEMO: 'DEMO',
  PROMOTIONAL: 'PROMOCIONAL',
  RESOURCES: 'RECURSO'
}

export const libraryTypes = {
  ADOPTED: 'adopted',
  DEMO: 'demo',
  RESOURCES: 'resources'
}

// MBQ Edition-language filter for internal user resources
export const unitIds = {
  ALL: 'all',
  FAVOURITES: 'favs',
  DEMO: 'demo'
}