// Libraries
import React, { Component, Fragment } from 'react'
import { AuthConsumer } from 'react-check-auth'
import { Redirect, withRouter } from 'react-router-dom'
import _ from 'lodash'

import {
  Container, Row, Col, Alert, Input,
  TabContent, TabPane, NavLink as Navlink,
  Button, Nav, NavItem
} from 'reactstrap'

import axios from 'axios'
import axiosConfig from '../../config/axios'
import envSettings from '../../globals/envSettings'

import { DrawBook } from '../DrawBook/DrawBook'
import { DrawTab } from '../DrawTab/DrawTab'
import { ContentConsumer } from '../../language'

import CheckBox from '../Forms/CheckBox'
import Modal from '../Modal/Modal'
import userRoles from '../../globals/userRoles'
import editlibraryConstants from './editlibraryConstants'
import { adoptionTypes, libraryTypes } from '../../globals/title'

class EditLibrary extends Component {

  constructor(props) {
    super(props)
    this.state = {
        library: []
      , notLibrary: []
      , selected: libraryTypes.ADOPTED
      , selectedLibrary: []
	    , suggestedLibrary: []
      , error: ''
      , stages: []
      , allStages: []
      , adoptionStages: []
      , demoStages: []
      , activeTab: '0'
      , checkedAdoption: true
      , checkedDemo: false
      , disableAdoption: false
      , disableDemo: false
      , totalAdopted: 0
      , totalDemo: 0
      , redirect: false
      , showModal: false
      , isHovered: false
      , hoveredNoderef: ''
      , isHoveredLibrary: false
    }

    this.setRedirect = this.setRedirect.bind(this)
    this.calculateTotals = this.calculateTotals.bind(this)
    this.refreshLibrary = this.refreshLibrary.bind(this)
    this.elementIsSelected = this.elementIsSelected.bind(this)
    this.totalSelected = this.totalSelected.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleChangeAdoption = this.handleChangeAdoption.bind(this)
    this.handleChangeDemo = this.handleChangeDemo.bind(this)
    this.setStages = this.setStages.bind(this)
    this.filteredByChecks = this.filteredByChecks.bind(this)
    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.handleHover = this.handleHover.bind(this)
  }

  switchLibrary = (libraryType) => {
    if(libraryType === libraryTypes.ADOPTED
      || libraryType === libraryTypes.DEMO) {
      this.setState({ selected: libraryType })
    }
  }

  filterByLibrary = (books, libraryType) => {
    if(!books) return []
    if(libraryType === libraryTypes.ADOPTED) {
      return books.filter((book) => book.adoptionType === adoptionTypes.ADOPTION)
    }
    if(libraryType === libraryTypes.DEMO) {
      return books.filter((book) => book.adoptionType === adoptionTypes.DEMO)
    }
    return []
  }

  isLibrarySelected = (libraryType) => {
    const { selected } = this.state
    return selected === libraryType
  }

  handleHover(noderef, block){
    // Control handleHover an element on library (above block) or candidates (bottom block)
    this.setState(prevState => ({
        isHovered: (block !== 'LIBRARY') ? !prevState.isHovered : false,
        isHoveredLibrary: (block === 'LIBRARY') ? !prevState.isHoveredLibrary : false,
        hoveredNoderef: noderef
    }))
  }

  toggleModal = () => this.setState(
      prevState => ({error: '', showModal: !prevState.showModal
  }))

  getSuggestedTitles = _.debounce((text, callback) => {
    const axiosInstance = axios.create(axiosConfig);
    axiosInstance.get(`library/all/search?search=${text}`)
      .then(response => {
        //console.log('[EDIT LIBRARY] LLAMADA A LA API CORRECTA: ' , response)
        callback(response)
      })
      .catch(error => {
        //console.log('[EDIT LIBRARY] LLAMADA A LA API INCORRECTA: ' , error)
        callback(error)
      })
  } , editlibraryConstants.DEBOUNCE_INTERVAL)

  getLibrary = (callback) => {
    const axiosInstance = axios.create(axiosConfig);
    axiosInstance.get('/library/all')
      .then(response => {
        //console.log('[EDIT LIBRARY] LLAMADA A LA API CORRECTA: ' , response)
        callback(response)
      })
      .catch(error => {
        //console.log('[EDIT LIBRARY] LLAMADA A LA API INCORRECTA: ' , error)
        callback(error)
      })
  }

  putLibrary = (library, callback) => {
    const axiosInstance = axios.create(axiosConfig);
    axiosInstance.put('/library', library)
      .then(response => {
        //console.log('[PUT LIBRARY] LLAMADA A LA API CORRECTA: ' , response)
        callback(response)
      })
      .catch(error => {
        //console.log('[PUT LIBRARY] LLAMADA A LA API INCORRECTA: ' , error)
        callback(error)
      })
  }

  confirmLibrary = async (library, selectedLibrary, refreshAuth, e) => {
    e.preventDefault()
    const { history } = this.props
    const output = library.filter((element) => {
                                          return selectedLibrary.indexOf(element) < 0
                                          //}).map(item=>item.idTitulo + '-' + item.adoption)
                                        }).map(item => `${item.idTitulo}-${item.adoption}-${item.promotion || '0'}`)
    //console.log('SELECTED LIBRARY: ', selectedLibrary)
    //console.log('LIBRARY: ', library)
    //console.log('OUTPUT: ', output)
    this.putLibrary({library:output}, async (response)=>{
      if (response.data === 'OK') {
        await refreshAuth()
        history.push("/")
      } else {
        if (response.response.status === 400) {
          this.toggleModal()
        } else {
          this.setError(response.response.data.message)
        }
      }
    })

  }

  componentDidMount() {
    this.getLibrary( res => {
      if (res.data) {
        const all = []

        //console.log('[EDIT LIBRARY] DATA OBTENIDA DE LA API: ' , res.data)

        const library = res.data.filter(item=> item.origin === 'LIBRARY')
        const notLibrary = res.data.filter(item=> item.origin === 'NOTLIBRARY')
        const selectedLibrary = library.map(item=> item.nodeRef)
        const stages = this.setStages(notLibrary)

        stages.initialStages = all.concat(stages.initialStages)
        stages.allStages = all.concat(stages.allStages)
        stages.adoptionStages = all.concat(stages.adoptionStages)
        stages.demoStages = all.concat(stages.demoStages)

        let totals = {0:0, 1:0}
        if (typeof notLibrary !== 'undefined' && notLibrary.length >= 0) { totals = this.calculateTotals(notLibrary)}

        this.setState({
          library: library,
          selectedLibrary: selectedLibrary,
          notLibrary: notLibrary,
          stages: stages.initialStages,
          allStages: stages.allStages,
          adoptionStages: stages.adoptionStages,
          demoStages: stages.demoStages,
          totalAdopted: totals[1],
          totalDemo: totals[0],
          checkedAdoption: (totals[1] === 0) ? false : true,
          checkedDemo: (totals[1] !== 0) ? false : ((totals[0] !== 0) ? true: false),
          disableAdoption: (totals[1] === 0) ? true : false,
          disableDemo: (totals[1] === 0) ? true : ((totals[0] === 0) ? true: false)
        })

      } else {
        console.log(res.response)
        //this.setState({ error: res.response.status })
      }
    })
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }

  setError = (message) => {
    this.setState({
      redirect: true,
      error: message
    })
  }

  calculateTotals(library) {
    if (typeof library !== 'undefined' && library.length >= 0) {
      const adoptions = library.reduce( (countValues, values) => {
        countValues[values.adoption] = (countValues[values.adoption] || 0) + 1
        return countValues
      } , {0:0, 1:0})

      return adoptions
    }

  }

  setStages(library) {
    let allStages = []
    let adoptionStages = []
    let demoStages = []
    let initialStages = []

    allStages = _.uniq(library.map(item => item.stage))
    adoptionStages = _.uniq(library.filter(item=> item.adoption === 1).map(item=>item.stage))
    demoStages = _.uniq(library.filter(item=> item.adoption === 0).map(item=>item.stage))
    initialStages = ((adoptionStages.length === 0) ? demoStages: adoptionStages)

    return {
      initialStages: initialStages,
      allStages: allStages,
      adoptionStages: adoptionStages,
      demoStages: demoStages
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  handleChangeAdoption() {
    const { checkedAdoption, checkedDemo } = this.state
    const newStages = (this.state.checkedAdoption ? this.state.demoStages : (this.state.checkedDemo ? this.state.allStages : this.state.adoptionStages ))

    this.setState({
      stages: newStages,
      checkedAdoption: !checkedAdoption,
      checkedDemo : !checkedDemo,
      activeTab: '0'
    })
  }

  handleChangeDemo() {
    const newStages = (this.state.checkedDemo ? this.state.adoptionStages : (this.state.checkedAdoption ? this.state.allStages : this.state.demoStages ))

    this.setState({
      stages: newStages,
      checkedAdoption: !this.state.checkedAdoption,
      checkedDemo: !this.state.checkedDemo,
      activeTab: '0'
    })
  }

  elementIsSelected = (noderef, arr) => {
    if (typeof arr !== 'undefined' && arr.length > 0){
      return (arr.includes(noderef))
    } else {
      return false
    }
  }

  totalSelected = (arr) => {
    return arr.length
  }

  filteredByChecks (item) {
    return ((this.state.checkedAdoption && this.state.checkedDemo) ||
            (item.adoption === 1 && this.state.checkedAdoption) ||
            (item.adoption === 0 && this.state.checkedDemo))
  }

  refreshLibrary = (element, arr, arrSelected, origin) => {

    if ((origin === 'arrLibrary') || (origin === 'arrSelectedLibrary' && this.elementIsSelected(element.nodeRef, arrSelected))) {
      arr = arr.filter(item=> item.nodeRef !== element.nodeRef)
      arrSelected = arrSelected.filter(item=> item !== element.nodeRef)
    } else {
      arr = arr.concat(element)
      arrSelected = arrSelected.concat(element.nodeRef)
    }

    this.setState({ library: arr
                  , selectedLibrary: arrSelected
//                  , isHovered: false
//                  , isHoveredLibrary: false
                })
  }

  handleTitleChange = (e) => {
    e.preventDefault()

    if (e.target.value.length >= editlibraryConstants.MIN_SEARCH_LENGTH) {
      this.getSuggestedTitles(e.target.value, res => {
        if (res.data) {
          /*this.setState({suggestedLibrary: res.data})*/
          this.setState({notLibrary: []})
          this.setState({notLibrary: res.data})
        } else {
          console.log('ERROR getSuggestedTitles')
        }
      })
    } else return this.setState({notLibrary: []})
  }

  render() {
    const { showModal, redirect, selected, selectedLibrary } = this.state
    const library = this.filterByLibrary(this.state.library, selected)
    const notLibrary = this.filterByLibrary(this.state.notLibrary, selected)
    const libraryCounter = {
      "all": selectedLibrary.length,
      [libraryTypes.ADOPTED]: _.intersection(this.filterByLibrary(this.state.library, libraryTypes.ADOPTED).map(book => book.nodeRef), selectedLibrary).length,
      [libraryTypes.DEMO]: _.intersection(this.filterByLibrary(this.state.library, libraryTypes.DEMO).map(book => book.nodeRef), selectedLibrary).length
    }

    return (
    <Fragment>
      {
        redirect && this.state.error === '' && (<Redirect to='/' />)
      }

      {
        redirect && this.state.error !== '' && <Redirect to='/error' />
      }

      <AuthConsumer>
        {({ userInfo, refreshAuth }) => {
          return (
            <ContentConsumer>
              {({ content }) => {
                return (content &&
                  <Fragment>
                  {showModal && <Modal
                  className="resources__modal"
                  isOpen={showModal}
                  toggle={() => this.toggleModal()}
                  header={content.editlibrary.editlibrary_max_size_library}
                > </Modal>}
                  <div>
                    <Container className="myLibrary my-4" >
                      <Row>
                        <Col md="4" xs="12" className="mb-3" >
                          <h1>{content.editlibrary.editlibrary_label_library}
                            <span className="totalNumber ml-2">{libraryCounter.all}</span>
                          </h1>
                        </Col>
                        <Col md="8" xs="12" className="text-right justify-content-end edit__library" >
                          <div className="d-inline-flex justify-content-md-end justify-content-center col-12 col-lg-8 mb-2">
                            <Nav className="editLibrary__navTab mr-md-3 d-flex d-md-inline-flex flex-column flex-md-row" tabs>
                                { [libraryTypes.ADOPTED, libraryTypes.DEMO].map((libraryType) => (
                                <NavItem key={libraryType}>
                                  <Navlink
                                    className={`newsFeed__navTab__link ml-md-4 p-2 text-center text-md-left text-uppercase ${this.isLibrarySelected(libraryType) ? 'active' : ''}`}
                                    onClick={() => this.switchLibrary(libraryType)}>
                                    { _.get(content, `mylibrary.mylibrary_librarytype_${libraryType}`) } ({ libraryCounter[libraryType] })
                                  </Navlink>
                                </NavItem>
                              ))}
                            </Nav>
                          </div>
                          <Button className="edit__library__accept"
                            disabled={
                              ((this.totalSelected(this.state.selectedLibrary) > editlibraryConstants.MAX_LIBRARY_SIZE && userInfo.role === userRoles.USER_INTERNAL) ||
                                this.totalSelected(this.state.selectedLibrary) === 0)}
                            onClick={(e) => this.confirmLibrary(this.state.library, this.state.selectedLibrary, refreshAuth, e)}
                            outline >{content.editlibrary.editlibrary_button_confirm}
                          </Button>
                        </Col>
                      </Row>

                      {/*<Row>*/}
                      <Row className="ml-xl-2">
                        {this.state.error !== '' && (
                          <Alert color="danger">{content.editlibrary.editlibrary_error_library} {this.state.error}</Alert>

                        )}

                        {library.map((title) => (
                          <Col xl="1" lg="2" md="3" sm="3" xs="6" className="pb-2" key={title.nodeRef}>
                            {/* <Col xl="1" lg="2" md="3" sm="4" xs="6" key={title.nodeRef} > */}

                            <Navlink
                              exact="true"
                              className="edit__library__thumbnail thumbnail position-relative d-flex justify-content-center"
                              onClick={() => this.refreshLibrary(title, this.state.library, this.state.selectedLibrary, 'arrLibrary')}
                              onMouseEnter={() => this.handleHover(title.nodeRef, 'LIBRARY')} onMouseLeave={() => this.handleHover('', 'LIBRARY')}
                            >
                              {/*<img className={`myLibrary__img ${(this.state.isHoveredLibrary && (this.state.hoveredNoderef === title.nodeRef)) ? 'editLibrary__border' :''}`}*/}
                              <img className={`myLibrary__img-edit ${(this.state.isHoveredLibrary && (this.state.hoveredNoderef === title.nodeRef)) ? 'border-solid' : ''}`}
                                style={(this.state.isHoveredLibrary && (this.state.hoveredNoderef === title.nodeRef)) ? { opacity: 0.2 } : { opacity: 1 }}
                                src={`${envSettings.API_BASEURL}${title.coverURL}`}
                                alt={title.name} />

                              {/*(this.state.isHoveredLibrary && (this.state.hoveredNoderef === title.nodeRef)) &&
                                <i className="position-absolute myLibrary__remove glyphicon glyphicon-remove-circle"></i>
                              */}
                              {(this.state.isHoveredLibrary && (this.state.hoveredNoderef === title.nodeRef)) &&
                                <i className="position-absolute myLibrary__remove-edit glyphicon glyphicon-remove-circle"></i>
                              }

                              {title.promotion === 1 && <div className="promotion-sticker-edit">{content.editlibrary.promotion_sticker}</div>}

                            </Navlink>
                          </Col>
                        ))}
                      </Row>
                    </Container>

                  <Fragment>
                    {userInfo.role === userRoles.USER_INTERNAL ?
                      <div>
                        <div className="main">
                          <div className="input-group">
                            <div className="input-group-btn">
                              <button className="btn btn-default" type="submit" disabled={true}><i className="glyphicon glyphicon-search"></i></button>
                            </div>
                            <Input
                              placeholder={content.editlibrary.editlibrary_search}
                              className="col-md-8 mb-3"
                              type="text"
                              name='title'
                              onChange={e => this.handleTitleChange(e)}
                            />
                          </div>
                        </div>
                        <Row>
                          {notLibrary.map((title) => {
                            return (
                              <DrawBook
                                key={title.nodeRef}
                                title={title}
                                elementIsSelected={this.elementIsSelected}
                                selectedLibrary={this.state.selectedLibrary}
                                refreshLibrary={this.refreshLibrary}
                                library={library}
                                origin='arrSelectedLibrary'
                                content={content}
                                isHovered={this.state.isHovered}
                                hoveredNoderef={this.state.hoveredNoderef}
                                handleHover={this.handleHover}
                                />
                              )
                            })
                          }
                        </Row>
                        {/*<Suggestions results={this.state.suggestedLibrary} />*/}
                      </div>
                      :
                      <Fragment>
                        <Container className="resources py-5">
                              <div className="d-flex justify-content-between align-items-center" >
                                <h2 className="edit__library__h2"  id="aui_3_2_0_1130">{content.editlibrary.editlibrary_label_select}</h2>
                                <Nav className="editLibrary__navTab d-none d-md-block d-md-inline-flex flex-column flex-md-row" tabs>
                                    { [libraryTypes.ADOPTED, libraryTypes.DEMO].map((libraryType) => (
                                    <NavItem key={libraryType}>
                                      <Navlink
                                        className={`editLibrary__navTab__link ml-md-4 p-2 text-center text-md-left text-uppercase ${this.isLibrarySelected(libraryType) ? 'active' : ''}`}
                                        onClick={() => this.switchLibrary(libraryType)}>
                                        { _.get(content, `mylibrary.mylibrary_librarytype_${libraryType}`) + ` (${libraryType === libraryTypes.ADOPTED ? this.state.totalAdopted : this.state.totalDemo})` }
                                      </Navlink>
                                    </NavItem>
                                  ))}
                                </Nav>
                              </div>
                            <Row className="d-flex justify-content-between">
                              {/* TODO REVISAR */}
                              <DrawTab to={content.editlibrary.editlibrary_stage_all} active={this.state.activeTab === '0' ? true : false} onClick={() => { this.toggle('0') }} />
                              {/* TODO REVISAR */}
                              {this.state.stages.map(item=> {
                                    return (
                                      <DrawTab
                                        key={item}
                                        to={item}
                                        active={this.state.activeTab === item ? true : false}
                                        onClick={() => { this.toggle(item) }}
                                        />
                                    )
                                  })
                              }
                            </Row>

                            <TabContent className="mt-3" activeTab={this.state.activeTab}>
                              <TabPane key = '0' tabId='0'>
                                <Row>
                                  {notLibrary.map((title) => {
                                    return (
                                      <DrawBook
                                        key={title.nodeRef}
                                        title={title}
                                        elementIsSelected={this.elementIsSelected}
                                        selectedLibrary={this.state.selectedLibrary}
                                        refreshLibrary={this.refreshLibrary}
                                        library={this.state.library}
                                        origin='arrSelectedLibrary'
                                        content={content}
                                        isHovered={this.state.isHovered}
                                        hoveredNoderef={this.state.hoveredNoderef}
                                        handleHover={this.handleHover}
                                        />
                                      )
                                    })
                                  }
                                </Row>
                              </TabPane>

                              {this.state.stages.map(stage=> {
                                return (
                                <TabPane key={stage} tabId={stage}>
                                  <Row>
                                    {
                                    notLibrary.filter(item=> (item.stage === stage)).map((title) => {
                                      return <DrawBook
                                        key={title.nodeRef}
                                        title={title}
                                        elementIsSelected={this.elementIsSelected}
                                        selectedLibrary={this.state.selectedLibrary}
                                        refreshLibrary={this.refreshLibrary}
                                        library={this.state.library}
                                        origin='arrSelectedLibrary'
                                        isHovered={this.state.isHovered}
                                        hoveredNoderef={this.state.hoveredNoderef}
                                        handleHover={this.handleHover}
                                      />
                                    })
                                    }
                                  </Row>
                                </TabPane>)
                                }
                              )}
                            </TabContent>
                        </Container>
                      </Fragment>
                    }
                  </Fragment>


                </div>

              </Fragment>)}}

            </ContentConsumer>
          )
        }}
      </AuthConsumer>
    </Fragment>
    )
  }

}

export default withRouter(EditLibrary)
