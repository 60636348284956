import './utils/polyfills'

import React from 'react'
import ReactDOM from 'react-dom'

import 'lightbox-react/style.css'
import './scss/main.scss'

import App from './App'
import Coupons from './Coupons'

if(process.env.REACT_APP_BUILD === 'coupons') {
  ReactDOM.render(<Coupons />, document.getElementById('coupons'))
} else if(process.env.REACT_APP_BUILD === 'coupons-local') {
  ReactDOM.render(<Coupons />, document.getElementById('oxford-premium'))
} else {
// MBQ TODO PREM-2458 Desactivamos serviceworker
//import registerServiceWorker from './registerServiceWorker';
  ReactDOM.render(<App />, document.getElementById('oxford-premium'))
}
// MBQ TODO PREM-2458 Desactivamos serviceworker
//registerServiceWorker();
