import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { ContentConsumer } from '../../language'
import CMSContent from '../CMSContent/CMSContent'

const CONTENT_OAUTH2_ERROR = 'BLINKCPT_UNAUTHORIZED'

const OAuth2Error = () => (
  <ContentConsumer>
    {({ locale }) => (
      <Container fluid={false} className="OAuth2Error">
        <Row>
          <Col md={{ size: 12 }} className="text-center">
            <CMSContent pageid={CONTENT_OAUTH2_ERROR} cache={true} language={locale} />
          </Col>
        </Row>
      </Container>
    )}
  </ContentConsumer>
)

export default OAuth2Error