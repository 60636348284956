// MBQ 12/01/2023 : New banner for showing warnings to the final client
import React from 'react'
import { ContentConsumer } from '../../language'
import { Alert } from 'reactstrap'
import CMSContent from '../CMSContent/CMSContent'

const CONTENT_PREM_WARNING_BANNER = 'PREM_WARNING_BANNER'

const WarningBanner = () => (
    <ContentConsumer>
        {({ locale }) => (
            <CMSContent
                pageid={CONTENT_PREM_WARNING_BANNER}
                cache={true}
                language={locale}
                wrapper={Alert}
                wrapperProps={{ className: 'warning-banner', color: 'warning' }}
            />
        )}
    </ContentConsumer>
)

export default WarningBanner