import React from "react";
import { ContentConsumer } from "../../../language";

const NoViewer = () => {
  return (
    <ContentConsumer>
      {({ content }) =>
        content && (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <div className="p-5 rounded resources__noViewer text-center">
              <span className="glyphicon glyphicon-eye-close" />
              <h6 className="mt-3">{content.titledetail.noPreview}</h6>
            </div>
          </div>
        )
      }
    </ContentConsumer>
  );
};

export default NoViewer;
