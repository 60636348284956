import React from 'react'
import { ContentConsumer } from '../../language'
import { Container, Row, Col } from 'reactstrap'
import CMSContent from '../CMSContent/CMSContent'

const CONTENT_BLINKCPT_LICENCE = 'BLINKCPT_LICENCE'

const BlinkCptLicenceContent = () => (
  <ContentConsumer>
    {({ locale }) => (
      <Container className="BlinkCptLicenceContent">
        <Row>
          <Col lg={{ size: 12 }} className="text-center col-licence">
            <CMSContent pageid={CONTENT_BLINKCPT_LICENCE} cache={true} language={locale} />
          </Col>
        </Row>
      </Container>
    )}
  </ContentConsumer>
)

export default BlinkCptLicenceContent