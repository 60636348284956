import React, { Component } from 'react'
import { AuthConsumer } from 'react-check-auth'
import { ContentConsumer } from '../../language'
import { FeatureContext } from '../../contexts/Feature/FeatureContext'
import { Modal, ModalBody, ModalHeader, Container, Row, Col } from 'reactstrap'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import PromotionSettings, { ClientSettings } from './settings'
import axios from 'axios'
import axiosConfig from '../../config/axios'
import utils from './utils'
import { parseToReactElement } from '../../utils/html'

const axiosApi = axios.create(axiosConfig)
const axiosWp = axios.create(ClientSettings)

class PromotionHome extends Component {

  static contextType = FeatureContext

  constructor(props) {
    super(props)
    this.state = {
      promotion: null
    }
  }

  getPreferences = () => (
    axiosApi.get('/promotions/preferences').then(response => response.data)
  )

  getPosts = preferences => (
    axiosWp.get(`posts/?_embed=&per_page=${PromotionSettings.MAX_ITEMS}&categories=${PromotionSettings.HOME_CATEGORY}`)
      .then(response => response.data)
      .then(posts => {
        let genericPromotion = {}

        const promotions = posts.reduce((acc, post) => {
          if (preferences.promotions.includes(post.id)) return acc

          if (![preferences.languageId, PromotionSettings.LANGUAGES_ROOT]
            .find(languageId => post.categories.includes(languageId))) return acc

          if (!post.title.rendered || !post.content.rendered || !post.excerpt.rendered
            || !post._embedded['wp:featuredmedia'].length || post._embedded['wp:featuredmedia'][0].media_type !== 'image')
            return acc

          post.excerpt.rendered = utils.sanitizeString(post.excerpt.rendered, '<p></p>\n')

          if (/^AD:0$/gi.test(post.excerpt.rendered)) {
            genericPromotion = utils.getPromotionFromPost(post)
            return acc
          } else {
            if (!/^AD:\d+(,\d+)*@PM:\d+(,\d+)*$/gi.test(post.excerpt.rendered)) return acc

            const titles = post.excerpt.rendered.split('@')

            const titlesAd = utils.getPostTitles(titles[0])
            if (!titlesAd.find(title => preferences.adoptions.some(adoption => title === adoption))) return acc

            const titlesPm = utils.getPostTitles(titles[1]).filter(title => !preferences.schoolAdoptions.includes(title))
            if (!titlesPm.length) return acc

            acc.push(utils.getPromotionFromPost(post, titlesPm))
            return acc
          }
        }, [])

        if (promotions.length === 1) return promotions[0]

        if (promotions.length > 1) {
          if (Object.entries(genericPromotion).length === 0) return null

          genericPromotion.promotionsId = utils.getPromotionsId(promotions)
          genericPromotion.pm = utils.getPromotionsTitlesPm(promotions)
          return genericPromotion
        }

        return null
      })
  )

  acceptPromotion = () => {
    const { id, pm, promotionsId } = this.state.promotion
    const data = { promotionTitles: pm }
    data.promotionIds = promotionsId ? promotionsId : [id]
    return axiosApi.post('/promotions', data)
      .then(() => {
        console.log('PROMOTIONS OK')
      })
      .catch(error => {
        console.error('PROMOTIONS: ', error)
      }).finally(() => (
        this.setState({ promotion: null }, () => this.props.refreshAuth())
        /*this.setState({ promotion: null }, () => {
          this.context.setModalFlag("promotion", false)
          this.props.refreshAuth()
        })*/
      ))
  }

  async componentDidMount() {
    let promotion = null
    try {
      if (utils.isAuthorized(this.props.userInfo)) {
        const preferences = await this.getPreferences()
        if (preferences) {
          promotion = await this.getPosts(preferences)
          if (promotion) this.setState({ promotion })
        }
      }
    } catch (error) {
      console.error('PROMOTIONS: ', error)
    } finally {
      this.context.setModalFlag("promotion", promotion !== null)
    }
  }

  render() {
    const { promotion } = this.state
    return (
      promotion && (
        <ContentConsumer>
          {({ content = {} }) => (
            content && (
              <Modal isOpen={true} size="lg" centered={true} scrollable={false} className='PromotionHome'>
                <ModalHeader>
                  <Row className="justify-content-center">
                    <Col>
                      <h5 className="font-weight-bold">{parseToReactElement(promotion.title)}</h5>
                    </Col>
                  </Row>
                </ModalHeader>
                <ModalBody>
                  <Container fluid={true}>
                    <Row className="justify-content-center pb-4">
                      <Col xs={{ size: 12, order: 2 }} lg={{ size: 6, order: 1 }}>
                        <Container className="w-100 h-100">
                          <Row className="h-50 pt-4 justify-content-center">
                            {/*<Col className="text-justify">*/}
                            <Col>
                              {parseToReactElement(promotion.content, null, { FORBID_ATTR: ['style', 'class'] })}
                            </Col>
                          </Row>
                          <Row className="h-50 pt-4 justify-content-center">
                            <Col className="align-self-end">
                              <ButtonPremium onClick={this.acceptPromotion} tabIndex="1" primary>
                                {content.sharedTerms.ACCEPT_BUTTON_TEXT}
                              </ButtonPremium>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col xs={{ size: 12, order: 1 }} lg={{ size: 6, order: 2 }}>
                        <img src={promotion.image.url.full} alt={promotion.image.alt} />
                      </Col>
                    </Row>
                  </Container>
                </ModalBody>
              </Modal>
            )
          )}
        </ContentConsumer>
      )
    )
  }
}

export default props => (
  <AuthConsumer>
    {({ userInfo, refreshAuth }) => <PromotionHome userInfo={userInfo} refreshAuth={refreshAuth} {...props} />}
  </AuthConsumer>
)