import React, { Component } from 'react'
import { AuthConsumer } from 'react-check-auth'
import { ContentConsumer } from '../../language'
import { FeatureContext } from '../../contexts/Feature/FeatureContext'
import Modal from '../Modal/Modal'
import axios from 'axios'
import axiosConfig from '../../config/axios'
import type from './type'

class Notifications extends Component {

  static contextType = FeatureContext
  
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      notificationType: null
    }
  }

  componentDidMount() {
    this.getNotification()
  }

  getNotification = () => {
    axios.create(axiosConfig).get('/notifications')
      .then(response => {
        //console.log('WWWW NOTIFICATION', response.data)
        if (response.data && response.data.type && type[response.data.type]) {
          this.setState(
            {
              notificationType: response.data.type,
              showModal: true
            },
            () => {
              this.context.setModalFlag("notification", true)
            }
          )
        } else {
          this.context.setModalFlag("notification", false)
        }
      })
      .catch(error => {
        console.error('error : ', error)
        this.context.setModalFlag("notification", false)
      })
  }

  toggleModal = fn => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }))
    if (fn && typeof fn === 'function') fn()
  }

  render() {
    const { showModal, notificationType } = this.state
    const Notification = notificationType ? type[notificationType].component : null
    return (
      <AuthConsumer>
        {({ userInfo }) => (
          <ContentConsumer>
            {
              ({ content = {} }) => (
                (content && Notification &&
                  <Modal
                    className={type[notificationType].prefixClass ? type[notificationType].prefixClass : ''}
                    isOpen={showModal}
                    size={type[notificationType].modalSize}
                    toggle={this.toggleModal}
                    header={content.notifications[type[notificationType].titleContentKey]}
                    subtitle={type[notificationType].subtitleContentKey ? content.notifications[type[notificationType].subtitleContentKey] : ''}>
                    <Notification
                      userInfo={userInfo}
                      content={content}
                      toggleModal={this.toggleModal}
                      handleSubmit={type[notificationType].submit ? type[notificationType].submit : null} />
                  </Modal>
                )
              )}
          </ContentConsumer>
        )}
      </AuthConsumer>
    )
  }
}

export default Notifications
