import React, { Component, Fragment } from 'react'
import { Row, Col } from 'reactstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from 'prop-types'

class StudentsZoneBlock extends Component {
  componentWillUnmount() {
    this.props.handleRefresh()
  }

  render() {
    const { isCalendarOpen, selected, onInputClick, value, onChange, minDate, maxDate } = this.props
    return (
      <Fragment>
        <Row>
          <Col className="text-center"> 
          <DatePicker name="date"
                      selected={selected}
                      onInputClick={onInputClick}
                      value={value}
                      onChange={onChange}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      dateFormat="dd//MM/yyyy"
                      open={isCalendarOpen}
                      onClickOutside={onInputClick}
                      onSelect={onInputClick}
                      minDate={minDate}
                      maxDate={maxDate}
                    />
          </Col>
        </Row>
      </Fragment>                        
    )
  }
}

StudentsZoneBlock.propTypes = {
  onChange: PropTypes.func.isRequired
}
  
export default StudentsZoneBlock