import React from 'react'
import { Provider, defaultState } from '../context'
import PropTypes from 'prop-types'
import { DEFAULT_LOCALE, changeLocaleCookie } from '../index'

class ContentProvider extends React.Component {
  constructor(props) {
    super(props)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.fetchSuccess = this.fetchSuccess.bind(this)
    this.fetchFail = this.fetchFail.bind(this)
    this.refreshContent = this.refreshContent.bind(this)
    this.state = {
      ...defaultState,
      refreshContent: this.refreshContent,
      locale: props.locale,
      contentUrl: props.contentUrl
    }
  }

  componentDidMount() {
    const { contentUrl, locale } = this.state
    //console.log('componentDidMount', contentUrl, locale)
    if (contentUrl && locale) return this.fetchInfo()
    return Promise.reject()
  }

  toggleLoading() {
    this.setState({ ...this.state, isContentLoading: true, content: null, contentError: null })
  }

  fetchSuccess(data) {
    this.setState({ ...this.state, content: data, isContentLoading: false, contentError: null })
  }

  fetchFail(err) {
    this.setState({ ...this.state, content: null, isContentLoading: false, contentError: err })
  }

  refreshContent(newLocale) {
    const { contentUrl, locale } = this.state
    //console.log('refreshContent', 'locale', locale, 'newlocale', newLocale)
    if (contentUrl && locale && newLocale && newLocale !== locale) {
      this.setState({ locale: newLocale }, () => this.fetchInfo())
    }
  }

  fetchInfo() {
    const oThis = this;
    const { reqOptions } = this.props;

    let options = {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
    };

    if (!!reqOptions && typeof reqOptions === 'function') {
      options = reqOptions();
    } else if (!!reqOptions) {
      options = reqOptions;
    }

    this.toggleLoading();

    const { contentUrl, locale } = this.state
    //console.log('fetchInfo', contentUrl, locale)
    return fetch(`${contentUrl}/${locale}`, options)
      .then(function (response) {
        if (response.status !== 200) {
          return response.json()
            .then((r) => {
              return Promise.reject(r)
            });
        }
        return response.json();
      }).then(function (json) {
        oThis.fetchSuccess(json.data);
        changeLocaleCookie(locale)
      }).catch(function (ex) {
        oThis.fetchFail(ex);
      });
  }

  render() {
    return (
      <Provider value={this.state}>
        {this.props.children}
      </Provider>
    )
  }
}

ContentProvider.propTypes = {
  contentUrl: PropTypes.string.isRequired,
  locale: PropTypes.string,
  reqOptions: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  children: PropTypes.node
}

ContentProvider.defaultProps = {
  locale: DEFAULT_LOCALE
}

export default ContentProvider