import React, { Fragment } from 'react'
import axios from 'axios'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'
import Lightbox from 'lightbox-react'

import Footer from './components/Footer'

import { getLocale } from '../../language'
import { format } from '../../utils/string'
import { formatStrDateToLocaleDateShort } from '../../utils/date'
import { parseToReactElement } from '../../utils/html'
import { withContent } from '../../utils/hocs'
import { icon } from './resource'

// Settings constants
import axiosConfig from '../../config/axios'
import envSettings from '../../globals/envSettings'
import GoogleClassroomButton from 'components/Buttons/GoogleClassroomButton'

const Header = ({ data: { name } }) => <span className="resourceList__LightBox__Name text-truncate">{name}</span>

const ResourceList = props => {

  const { filteredResults, params: { catid, subcatid }, ShowLightBox, handleLightBox } = props
  const data = filteredResults.find(item => item.unitUuid === props.params.unitid);

  const { lightBoxData, lightBoxData: { component, showZoom } } = props

  const catFilter = data => data.parentId === catid || catid === 'all'
  const subcatFilter = data => data.categoryId === subcatid || subcatid === 'all'

  const isBlank = str => !str || /^\s*$/.test(str)

  const isAnimation = resourceType => resourceType === '{http://www.oupe.es/model}animacion'

  const registerView = (uuid) => {
    const { params: { titleid }, students: isStudents } = props
    const url = `/${isStudents ? 'student' : 'title'}/${titleid}/resource/${uuid}`
    axios.create(axiosConfig).post(url)
      .catch(() => {})
  }
  if(!filteredResults || !filteredResults.length || !data) return null

  else return (

    <Fragment>
      {(ShowLightBox && component) &&
        <Lightbox
          mainSrc={component}
          onCloseRequest={() => handleLightBox(null)}
          imageTitle={<Header data={lightBoxData.data} />}
          imageCaption={<Footer data={lightBoxData.data} />}
          discourageDownloads={true}
          enableZoom={showZoom}
        />
      }
      {data.parentIds.map((data, idx) => catFilter(data) && (

        <div className="resourceList" key={idx}>
          <h2 className="py-2">{data.parentName}</h2>
          {data.categories.map((data, idx) => subcatFilter(data) && (
            <div key={idx}>
              <h3 className="m-3">{data.name}</h3>
              <Table borderless>
                <tbody>
                  {data.resources.map((data, idx) => (
                    <tr className="resourceList__TRitem d-flex align-items-center" key={idx} >
                      <th className="resourceList__THicon d-none d-md-table-cell" >{ icon(data) }</th>
                      <td className="resourceList__TDitem">
                        <div className="d-flex flex-column resourceList__title">
                          {(!isBlank(data.previewURL) || !isBlank(data.downloadURL)) && !isAnimation(data.type) &&
                            <button className="text-left" onClick={() => handleLightBox(data.uuid)} >
                              <span>{data.name}</span>
                            </button>
                          }
                          {!isBlank(data.externalURL) &&
                            <a href={data.externalURL} target="_blank" rel="noopener noreferrer" title={data.name}>
                              <span onClick={() => registerView(data.uuid) }>{data.name}</span>
                            </a>
                          }
                          {!isBlank(data.previewURL) && isAnimation(data.type) &&
                            <a href={`${envSettings.API_BASEURL}${data.previewURL}`} target="_blank" rel="noopener noreferrer" title={data.name}>
                              <span>{data.name}</span>
                            </a>
                          }
                          {(isBlank(data.previewURL) && isBlank(data.externalURL) && isBlank(data.downloadURL)) &&
                            <span>{data.name}</span>
                          }
                          { !props.students &&
                          <div className="mt-2">
                            <span className="resourceList__title__unit border border-info rounded px-2 py-1 d-inline-block">{data.unitName}</span>
                            { data.new &&
                              <Fragment>
                                <span className="resourceList__title__new ml-3 d-inline-block">{ props.content.titledetail.new_resource_label }</span>
                                <span className="resourceList__title__updated ml-3 d-inline-block">{ parseToReactElement(format(props.content.titledetail.new_resource_updated, formatStrDateToLocaleDateShort(data.modified, getLocale(props.locale)))) }</span>
                              </Fragment>
                            }
                          </div>
                          }
                        </div>
                      </td>
                      <td className="resourceList__TDMulti d-none d-md-table-cell text-center">
                        { data.classroom && 
                          <GoogleClassroomButton
                            key={ data.uuid }
                            uuid={ data.uuid }
                            title={ data.name }
                            url={ data.classroom }
                          />
                        }
                      </td>
                      { !props.students &&
                      <td className="resourceList__TDMulti d-none d-md-table-cell text-center">
                        {
                          data.favourite
                          ?
                            <span onClick={ () => props.changeFav(data) } className="favourite-icon glyphicon glyphicon-star" aria-hidden="true"></span>
                          :
                            <span onClick={ () => props.changeFav(data) } className="favourite-icon glyphicon glyphicon-star-empty" aria-hidden="true"></span>
                        }
                      </td>
                      }
                      <td className="resourceList__TDMulti d-none d-md-table-cell text-center">
                        {!isBlank(data.downloadURL) &&
                          <a href={`${envSettings.API_BASEURL}${data.downloadURL}`} title={data.name} >
                            <span className="glyphicon glyphicon-download-alt" aria-hidden="true"></span>
                          </a>
                        }
                      </td>
                      <td className="resourceList__TDMulti d-none d-md-table-cell text-center">
                        {(!isBlank(data.previewURL) || !isBlank(data.downloadURL)) && !isAnimation(data.type) &&
                          <button onClick={() => handleLightBox(data.uuid)}>
                            <span className="glyphicon glyphicon-eye-open" aria-hidden="true"> </span>
                          </button>
                        }
                        {!isBlank(data.externalURL)
                         && <button
                            onClick={ (e) => {
                            e.preventDefault()
                            registerView(data.uuid)
                            window.open(data.externalURL, "_blank")
                          }}>
                            <span className="glyphicon glyphicon-eye-open" aria-hidden="true"> </span>
                          </button>
                        }
                        {!isBlank(data.previewURL) && isAnimation(data.type) &&
                          <a href={`${envSettings.API_BASEURL}${data.previewURL}`} target="_blank" rel="noopener noreferrer" title={data.name}>
                            <span className="glyphicon glyphicon-eye-open" aria-hidden="true"> </span>
                          </a>
                        }
                        {(isBlank(data.previewURL) && isBlank(data.externalURL) && isBlank(data.downloadURL)) &&
                          <span className="glyphicon glyphicon-eye-open" aria-hidden="true"> </span>
                        }
                      </td>
                      <td className="resourceList__TDLast"></td>
                    </tr>

                      ))}
                </tbody>
              </Table>
            </div>
          )
          )}
        </div>
      )
      )}
    </Fragment>
  )
}


ResourceList.propTypes = {
  filteredResults: PropTypes.array,
  params: PropTypes.object,
  changeFav: PropTypes.func,
  students: PropTypes.bool
};

ResourceList.defaultProps = {
  filteredResults: [],
  students: false
}

export default withContent((ResourceList))
