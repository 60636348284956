import React from 'react'
import { Card, CardBody, CardText, CardImg } from 'reactstrap';
import { Col } from 'reactstrap'
import envSettings from '../../globals/envSettings'
import PropTypes from 'prop-types'

export const DrawBook = (props) => {

  const { title, elementIsSelected, selectedLibrary, refreshLibrary, library, origin, isHovered, hoveredNoderef, handleHover } = props

  return (
    <Col className="editLibrary__col" xl="1" lg="2" md="3" sm="4" xs="6" >
      <Card onClick={()=>refreshLibrary(title, library, selectedLibrary, origin)}
            className={`mb-4 editLibrary__col ${(elementIsSelected(title.nodeRef, selectedLibrary) || (!elementIsSelected(title.nodeRef, selectedLibrary) && isHovered && (hoveredNoderef === title.nodeRef))) ? 'editLibrary__border' :''}`}
            onMouseEnter={()=>handleHover(title.nodeRef, 'NOTLIBRARY')} onMouseLeave={()=>handleHover('', 'NOTLIBRARY')}
            >
        <CardImg
          top
          height="100%"
          style={ elementIsSelected(title.nodeRef, selectedLibrary) || (!elementIsSelected(title.nodeRef, selectedLibrary) && isHovered && (hoveredNoderef === title.nodeRef)) ? { opacity:0.2} : {opacity : 1} }
          alt={title.name}
          src={`${envSettings.API_BASEURL}${title.coverURL}`}
        />
        {(elementIsSelected(title.nodeRef, selectedLibrary) || (!elementIsSelected(title.nodeRef, selectedLibrary) && isHovered && (hoveredNoderef === title.nodeRef))) &&
          <div onClick={()=>refreshLibrary(title, library, selectedLibrary, origin)}  className="editLibrary__check w-100 h-100 position-absolute d-flex align-items-center justify-content-center"
          >
            <span className="glyphicon glyphicon-ok"> </span>
          </div>
        }

        <CardBody className="p-0 editLibrary__colTitle" >
          <CardText style={ elementIsSelected(title.nodeRef, selectedLibrary) || (!elementIsSelected(title.nodeRef, selectedLibrary) && isHovered && (hoveredNoderef === title.nodeRef)) ? { opacity:0.2} : {opacity : 1} } >
            {title.name}
          </CardText>
        </CardBody>
      </Card>
    </Col>
  )
 }

 DrawBook.propTypes = {
  content: PropTypes.object
}
