import React from 'react'
import platformId  from '../../globals/platforms'
import envSettings from '../../globals/envSettings'
//import { Button } from 'reactstrap'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import google from '../../assets/images/google.svg'
import microsoft from '../../assets/images/microsoft.svg'

const ButtonThirdParties = props => {
  const imageData = {'google':google, 'microsoft':microsoft}
  const { id, returnTo, routeOrigin, userPlatform, text, disabled } = props
  return (
    <ButtonPremium
      id={`thirdParty${id}`}
      disabled={disabled}
      href={`${envSettings.API_BASEURL}/third-parties/${id}?returnTo=${returnTo}&routeOrigin=${routeOrigin}&userPlatform=${userPlatform}`} 
      className="img-container"
      size="lg">
        <img src={imageData[id]} alt={id} />
        {text} {userPlatform===platformId.GOOGLE?"Google":"Microsoft"}
    </ButtonPremium>
  )

}

export default ButtonThirdParties