import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import PHPContentItem from './PHPContentItem'
import { Container } from 'reactstrap'

class PHPContent extends Component {
  render() {
    const { assets, content } = this.props
    if(!assets || !assets.length) return null
    else return (
      <Container>
          {assets.map((asset, index) =>   
            <PHPContentItem 
              key={asset.id} 
              {...{...asset}} 
              content={content} 
            />
          )}
      </Container>
      )
  }
}

export default PHPContent