import React from 'react'
import { Button } from 'reactstrap'
import cx from 'classnames'
import PropTypes from 'prop-types'

const RoundedButton = ({ children, className, onClick, size }) => (
  <Button
    style={{ width: size, height: size, borderRadius: '50%' }}
    onClick={onClick}
    className={cx('button-rounded', className)}
  >
    {children}
  </Button>
)

RoundedButton.propTypes = {
  size: PropTypes.string,
  children: PropTypes.element.isRequired
}

RoundedButton.defaultProps = { size: '10px' }

export default RoundedButton
