import React from 'react'
import { usePollConfig } from 'contexts/Polls/PollContext'
import { withUserInfoReloadable, withContent } from '../../../utils/hocs'
import Feature from '../../Feature/Feature'
import ManagedSurveyModal from './ManagedSurveyModal'
import ManagedSurveyButton from './ManagedSurveyButton'
import _ from 'lodash'
import validator from 'validator'

export const PARENT_COMPONENT = {
  HOME: 'home',
  HEADER: 'header'
}

const ManagedSurvey = withUserInfoReloadable(withContent(({ parent, userInfo, refreshAuth, content: { polls: pollText }, locale }) => {
  const managedSurveyConfig = _.get(usePollConfig(), 'managedSurveyConfig')
  const url = _.get(managedSurveyConfig, 'url')
  const delayNumber = _.get(managedSurveyConfig, 'delayNumber')
  const maxDelayNumber = _.get(managedSurveyConfig, 'maxDelayNumber')
  const showButton = _.get(managedSurveyConfig, 'showButton')

  const surveyEnabled = (
    managedSurveyConfig &&
    url && validator.isURL(url) &&
    validator.isInt(delayNumber + '', { gt: -1 }) &&
    delayNumber <= maxDelayNumber &&
    _.values(PARENT_COMPONENT).includes(parent)
  )

  const modalEnabled = (
    surveyEnabled &&
    !showButton &&
    parent === PARENT_COMPONENT.HOME &&
    userInfo && userInfo.postLogin
  )

  const buttonEnabled = (
    surveyEnabled && showButton && parent === PARENT_COMPONENT.HEADER
  )

  if(modalEnabled) {
    return (
      <Feature name="checkModalsOff">
        <ManagedSurveyModal
          delayNumber={delayNumber}
          maxDelayNumber={maxDelayNumber}
          url={url}
          locale={locale}
          refreshAuth={refreshAuth}
          pollText={pollText}
        />
      </Feature>
    )
  }
  if(buttonEnabled) {
    return (
      <ManagedSurveyButton
        url={url}
        pollText={pollText}
      />
    )
  }
  return null
}))

export default (props) => (
  <Feature name="managedSurvey">
    <ManagedSurvey {...props} />
  </Feature>
)