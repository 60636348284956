import React, { useState } from 'react'

import { copyToClipboard } from '../../../../utils/string'

import '../icons.css'

const License = ({ label, alt, license }) => {
  const [copied, setCopied] = useState(false)

  return (
    <div className="align-items-center d-flex">
      <div className="product-license">
        <span>{label}</span>
        <br />
        {copied ? <mark>{license}</mark> : <span>{license}</span>}
      </div>
      <div className="d-flex text-center align-middle">
        <span className="rounded-circle bt-copy-license"
          role="button"
          onClick={() => {
            copyToClipboard(license)
            setCopied(true)
            setTimeout(() => setCopied(false), 1000)
          }}
        >
          <img
            className="w-20px ml-2"
            src="https://online.oupe.es/OUP/Biblioteca/Iconos/copyIcon.png"
            title={alt}
            alt={alt}
          />
        </span>
      </div>
    </div>
  )
}

export default License