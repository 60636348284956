import React, { Fragment } from 'react'
import { ContentConsumer } from '../../language'
import { AuthConsumer } from 'react-check-auth'
import { Alert, TabPane, Button, Card, CardImg, CardBody, CardText, Row, Col, FormFeedback, FormGroup, Label, Input, Container } from 'reactstrap';
import sonConstants, { MIN_BIRTHDATE, MAX_BIRTHDATE } from './sonConstants'
import { parseToReactElement } from '../../utils/html'

const Son = props => {
  // MBQ TODO Propagate from parent component only current child's props, instead of all children props
  
  const { hijo, error, listas } = props

  const errors = error.hijosError ? error.hijosError.find(data => data.numero_hijo === hijo.numero_hijo) : null,
    filterListas = listas.find(data => data.numero_hijo === hijo.numero_hijo) || null

  return (
    <AuthConsumer>
      {({ userInfo }) => (
        <ContentConsumer>
          {({ content }) => (content && (
            <TabPane tabId={hijo.numero_hijo.toString()}>
              
              <Row>
                <Col className="mt-5 mb-3 text-left" sm="12">
                    {/* <h5>{content.gratishp.gratishp_son_data_label + ' '}{hijo.t_nombre}</h5> */}
                    <h5>
                      {content.gratishp.gratishp_son_data_label} {hijo.t_nombre && hijo.t_nombre.trim() ? hijo.t_nombre.trim().toUpperCase() : content.gratishp.gratishp_son_data_label_generic}
                    </h5>
                  </Col>
              </Row>

              {!hijo.id && (
                <Row className="sons__row" form>
                  <Col md={6}>
                    <FormGroup>
                      <Fragment>
                        <FormGroup>
                          <Input
                            invalid={(errors && errors.t_nombre) ? true : false}
                            className="mb-3 sons__inputUser"
                            type="text"
                            name='t_nombre'
                            value={hijo.t_nombre}
                            onChange={e => props.handleChange(e, hijo.numero_hijo)}
                            placeholder={`${content.gratishp.gratishp_son_name_label} *`}
                            maxLength={sonConstants.T_NOMBRE_INPUT_MAXLENGTH}
                          />
                          <FormFeedback>{content.gratishp.gratishp_nombre_hijo_validation_label}</FormFeedback>
                        </FormGroup>
                      </Fragment>
                      <Fragment>
                        <FormGroup>
                          <Input
                            invalid={(errors && errors.t_apellido1) ? true : false}
                            className="mb-3 sons__inputUser"
                            type="text"
                            name='t_apellido1'
                            value={hijo.t_apellido1}
                            onChange={e => props.handleChange(e, hijo.numero_hijo)}
                            placeholder={`${content.gratishp.gratishp_apellido1_label} *`}
                            maxLength={sonConstants.T_APELLIDO1_INPUT_MAXLENGTH}
                          />
                          <FormFeedback>{content.gratishp.gratishp_apellido1_hijo_validation}</FormFeedback>
                        </FormGroup>
                      </Fragment>
                      <Fragment>
                        <FormGroup>
                          <Input
                            className="mb-3 sons__inputUser"
                            type="text"
                            name='t_apellido2'
                            value={hijo.t_apellido2}
                            onChange={e => props.handleChange(e, hijo.numero_hijo)}
                            placeholder={`${content.gratishp.gratishp_apellido2_label}`}
                            maxLength={sonConstants.T_APELLIDO2_INPUT_MAXLENGTH}
                          />
                        </FormGroup>
                      </Fragment>
                      <Fragment>
                        <FormGroup>
                          <Label>{content.gratishp.gratishp_f_nacimiento_label} *</Label>
                          <Row form>
                            <Input
                              invalid={(errors && errors.f_nacimiento) ? true : false}
                              className="mb-3 sons__inputDate"
                              type="date"
                              max={MAX_BIRTHDATE}
                              min={MIN_BIRTHDATE}
                              name="f_nacimiento"
                              value={hijo.f_nacimiento}
                              onChange={e => props.handleChange(e, hijo.numero_hijo)}
                              placeholder="dd/mm/yyyy"
                              pattern="\d{4}-\d{2}-\d{2}"
                            />
                            <FormFeedback className="sons__inputDate__fb" >{content.gratishp.gratishp_f_nacimiento_validation}</FormFeedback>
                          </Row>
                        </FormGroup>
                      </Fragment>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <h6>{content.gratishp.gratishp_libro_familia_label} *</h6>
                      <p>{content.gratishp.gratishp_attachment_validation}</p>
                      <Input
                        invalid={(errors && errors.libro_familia) ? true : false}
                        name='libro_familia'
                        type='file'
                        accept='image/jpeg'
                        onChange={e => props.handleFileUpload(e, hijo.numero_hijo)}
                        className="mb-3 sons__inputFile" />
                      <FormFeedback>{content.gratishp.gratishp_libro_familia_validation}</FormFeedback>

                      {hijo.imagen_libro_familia &&
                        <p><img className="img-thumbnail" src={hijo.imagen_libro_familia} alt="" /></p>
                      }
                    </FormGroup>
                  </Col>
                </Row>
              )}
              {props.libroFamiliaError.has(hijo.numero_hijo) &&
                <Row>
                  <Col className="my-2 text-center" sm="12">
                    <Alert className="my-2 hp__form__alert" color="danger">{content.gratishp.gratishp_attachment_validation}</Alert>
                  </Col>
                </Row>
              }

              {hijo.id && (
                <Fragment>
                  <Row form className="mb-lg-3">
                    <Col lg={6}>
                      <Input
                        type="text"
                        name='t_nombre'
                        value={hijo.t_nombre}
                        className="mb-3 sons__inputUser-disabled"
                        disabled
                      />
                    </Col>
                    <Col lg={6}>
                      <Input
                        type="text"
                        name='t_apellido1'
                        value={hijo.t_apellido1}
                        className="mb-3 sons__inputUser-disabled"
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="sons__row" form>
                    <Col lg={6}>
                      <Input
                        type="text"
                        name='t_apellido2'
                        value={hijo.t_apellido2}
                        className="mb-3 sons__inputUser-disabled"
                        disabled
                      />
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Container className="mt-3 mt-lg-0 pr-xl-0">
                          <Row>
                            <Col className="text-left text-lg-right pr-xl-0">
                              <Label>{content.gratishp.gratishp_f_nacimiento_label}</Label>
                            </Col>
                            <Col className="text-left text-lg-right pr-xl-0">
                              <Input
                                type="date"
                                name="f_nacimiento"
                                value={hijo.f_nacimiento}
                                className="mb-3 sons__inputDate"
                                disabled
                              />
                            </Col>
                          </Row>
                        </Container>
                      </FormGroup>
                    </Col>
                  </Row>
                </Fragment>
              )}              

              <Row>
                <Col className="mt-5 mb-3 text-left" sm="12">
                  <h5>{content.gratishp.gratishp_son_data_title}</h5>
                </Col>
              </Row>

              <Row className="sons__row" form>
                <Col md={6}>
                  <FormGroup>                                      
                    <div className="d-flex align-items-center">
                      <Input
                        className="mb-3"
                        type="text"
                        name='codigo_postal'
                        value={hijo.codigo_postal}
                        onChange={e => props.handleChange(e, hijo.numero_hijo)}
                        placeholder={`${content.gratishp.gratishp_codigo_postal_label} *`}
                        invalid={(errors && errors.codigo_postal) ? true : false}                      
                        maxLength={sonConstants.CODIGO_POSTAL_LENGTH}                             
                      />  
                      <FormFeedback>{content.gratishp.gratishp_codigo_postal_validation_label}</FormFeedback>                              
                      {props.searchFlags.searchingSchool && 
                        <div className="spinner-border spinner-border-sm text-primary hp__form__spinner" role="status">
                          <span className="sr-only">Loading...</span>
                        </div> }   
                    </div>                       
                  </FormGroup>
                </Col>

                {(filterListas && filterListas.centers) &&
                  <Col md={6}>
                    <FormGroup>
                      <div className="d-flex align-items-center">
                        <Input
                          invalid={(errors && errors.centers) ? true : false}
                          disabled={filterListas.centers.length > 0 ? false : true}
                          name='id_centro_hijo'
                          value={hijo.id_centro_hijo ? hijo.id_centro_hijo : '0'}
                          onChange={e => props.handleChange(e, hijo.numero_hijo)}
                          type="select"
                        >
                          {!(filterListas.centers.length > 0)
                            ? <option key='0' value='0'>{content.gratishp.gratishp_fill_postal_code_label_in_center}</option>
                            : <Fragment>
                              <option key='0' value='0'>{`${content.gratishp.gratishp_choose_center_label} *`}</option>
                              {filterListas.centers.map(center => (
                                <option key={center.id_centro} value={center.id_centro}>{content.gratishp.gratishp_choose_center_value_label + ' '}{center.d_centro}</option>
                              ))}
                              </Fragment>
                          }
                        </Input>
                        <FormFeedback>{content.gratishp.gratishp_centro_validation_label}</FormFeedback>
                      { props.searchFlags.searchingLevels && 
                        <div className="spinner-border spinner-border-sm text-primary hp__form__spinner-select" role="status">
                          <span className="sr-only">Loading...</span>
                        </div> }   
                      </div>                      
                    </FormGroup>
                  </Col>
                }
              </Row>

                
              {(props.zipCodeError.has(hijo.numero_hijo) || 
                props.componentesError.has(hijo.numero_hijo) || 
                props.levelError.has(hijo.numero_hijo) || 
                props.wrongZipCode.has(hijo.numero_hijo) || 
                props.noAdoptionsError.has(hijo.numero_hijo)) &&
                <Row>
                  <Col className="my-2 text-center" sm="12">
                    {/* Poner literal */}
                    {props.zipCodeError.has(hijo.numero_hijo) &&
                      <Alert className="my-2 hp__form__alert" color="danger">{content.gratishp.gratishp_zipCodeError}</Alert>
                    }
                    {props.componentesError.has(hijo.numero_hijo) &&
                      <Alert className="my-2 hp__form__alert" color="danger">{content.gratishp.gratishp_componentesError}</Alert>
                    }
                    {props.levelError.has(hijo.numero_hijo) &&
                      <Alert className="my-2 hp__form__alert" color="danger">{content.gratishp.gratishp_levelError}</Alert>
                    }                    
                    {props.wrongZipCode.has(hijo.numero_hijo) &&
                      <Alert className="my-2 hp__form__alert" color="danger">{content.gratishp.gratishp_wrong_zip_code}</Alert>
                    }                    
                    {props.noAdoptionsError.has(hijo.numero_hijo) &&
                      <Alert className="my-2 hp__form__alert" color="danger">{content.gratishp.gratishp_no_levels_founds}</Alert>
                    }                                        
                  </Col>
                </Row>
              }

              {(filterListas && filterListas.levels.length > 0) &&
                <Fragment>
                  <Row>
                    <Col className="mt-5 mb-3 text-left" sm="12">
                      <h5>{content.gratishp.gratishp_books_whised}</h5>                      
                      {/*<p className="text-left" >{content.gratishp.gratishp_books_whised_info}</p>*/}
                      {parseToReactElement(content.gratishp.gratishp_books_whised_info)}
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label>{content.gratishp.gratishp_choose_level_label} *</Label>
                        <div className="d-flex align-items-center">
                          <Input
                            //invalid={(errors && errors.levels) ? true : false}
                            disabled={filterListas.levels.length > 0 ? false : true}
                            name='level'
                            value={hijo.level ? hijo.level : '0'}
                            onChange={e => props.handleChange(e, hijo.numero_hijo)}
                            type="select"
                          >
                            {!(filterListas.levels.length > 0)
                              ? <option key='0' value='0'>{content.gratishp.gratishp_choose_level_label}</option>
                              : <Fragment>
                                <option key='0' value='0'>{`${content.gratishp.gratishp_choose_level_value_label}`}</option>
                                {filterListas.levels.map(level => (
                                  <option key={level.C_NIVEL} value={level.C_NIVEL}>{level.D_NIVEL}</option>
                                ))}
                                </Fragment>
                            }
                          </Input>
                          { props.searchFlags.searchingSubjects && 
                            <div className="spinner-border spinner-border-sm text-primary hp__form__spinner-select" role="status">
                              <span className="sr-only">Loading...</span>
                            </div> }  
                          <FormFeedback></FormFeedback>
                        </div>
                      </FormGroup>
                    </Col>

                    {(filterListas && filterListas.subjects) &&

                      <Col md={6}>
                        <FormGroup>
                          <Label>{content.gratishp.gratishp_choose_subject_label} *</Label>
                          <div className="d-flex align-items-center">
                            <Input
                              //invalid={(errors && errors.subjects) ? true : false}
                              disabled={filterListas.subjects.length > 0 ? false : true}
                              name='subject'
                              value={hijo.subject ? hijo.subject : '0'}
                              onChange={e => props.handleChange(e, props.hijo.numero_hijo, userInfo.schoolId, userInfo.id)}
                              type="select"
                            >
                              {!(filterListas.subjects.length > 0)
                                ? <option key='0' value='0'>{content.gratishp.gratishp_choose_level_value_label}</option>
                                : <Fragment>
                                  <option key='0' value='0'>{`${content.gratishp.gratishp_choose_subject_value_label}`}</option>
                                  {filterListas.subjects.map(subject => (
                                    <option key={subject.C_ASIGNATURA} value={subject.C_ASIGNATURA}>{subject.D_ASIGNATURA}</option>
                                  ))}
                                </Fragment>
                              }
                            </Input>
                            { props.searchFlags.searchingBooks && 
                              <div className="spinner-border spinner-border-sm text-primary hp__form__spinner-select" role="status">
                                <span className="sr-only">Loading...</span>
                              </div> }  
                          </div>
                          <FormFeedback></FormFeedback>
                        </FormGroup>
                      </Col>
                    }
                  </Row>

                  {/*
                  {(filterListas && filterListas.isbns.length > 0) &&
                    <Fragment>                      
                      <Row>
                        <Col className="mt-5 mb-3 text-center" sm="12">
                          <h5>{content.gratishp.gratishp_titulos_seleccionables_label}</h5>
                        </Col>
                      </Row>
                  */}
                  {(filterListas && filterListas.isbns.length > 0) &&
                    <Row>
                      {filterListas.isbns.map(isbn => (!hijo.componentes || !hijo.componentes.find(obj => obj.isbn === isbn.isbn)) &&
                        <Col className="mb-3" key={isbn.isbn} xs="12" sm="6" md="3" lg="2">
                          <Card>
                            <CardImg src={sonConstants.SERVIDOR_PORTADAS + isbn.isbn + '/Portadas/' + isbn.isbn + '_L38_04_m.jpg'} alt='' onError={props.defaultImage} />
                            <CardBody>
                              {/* MBQ TODO Show isbn */}
                              {/* <CardText>{isbn.titulo}</CardText> */}
                              <CardText>{isbn.titulo} - <strong className='dark'>ISBN {isbn.isbn}</strong></CardText>                              
                              {isbn.stock === 'N' && <CardText className="non-stock">{content.gratishp.nonstock_label}</CardText>}
                              <Button onClick={() => props.addTitle(hijo.numero_hijo, isbn.isbn)}>
                                {content.gratishp.gratishp_seleccionar_titulo_label}
                              </Button>
                            </CardBody>
                          </Card>
                        </Col>
                      )}
                    </Row>
                  }
                  {/*
                    </Fragment>
                  }
                  */}
                </Fragment>
              }

              {/*errors && errors.componentes &&
                <Alert className="my-2 hp__form__alert" color="danger">{content.gratishp.gratishp_algun_titulo_validation}</Alert>
              */}
              {errors && errors.componentes &&
                <Alert className="my-2 hp__form__alert" color="danger">{parseToReactElement(content.gratishp.gratishp_algun_titulo_validation)}</Alert>
              }

              {hijo.componentes &&
                <Fragment>
                  <Row>
                    <Col className="mt-5 mb-3 text-left" sm="12">
                      <h5>{content.gratishp.gratishp_titulos_seleccionados_label}</h5>
                    </Col>
                  </Row>

                  <Row>
                    {hijo.componentes.map(componente => (
                      <Col key={componente.isbn} xs="12" sm="6" md="3" lg="2">
                        <Card>
                          <CardImg src={sonConstants.SERVIDOR_PORTADAS + componente.isbn + '/Portadas/' + componente.isbn + '_L38_04_m.jpg'} alt='' onError={props.defaultImage} />
                          <CardBody>
                            {/* MBQ TODO Show isbn */}
                            {/* <CardText>{componente.titulo}</CardText> */}
                            <CardText>{componente.titulo} - <strong className='dark'>ISBN {componente.isbn}</strong></CardText>
                            {componente.stock === 'N' && <CardText className="non-stock">{content.gratishp.nonstock_label}</CardText>}
                            <Button onClick={() => props.deleteTitle(hijo.numero_hijo, componente.isbn)}>
                              {content.gratishp.gratishp_borrar_titulo_seleccionados_label}
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Fragment>
              }

            </TabPane>
          ))}
        </ContentConsumer>

      )
      }
    </AuthConsumer>
  );
};


export default Son