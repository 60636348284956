import React, { useEffect } from 'react'
import _ from 'lodash'

import Feature from '../Feature/Feature'
import { usePollConfig } from "../../contexts/Polls/PollContext"
import { withContent } from '../../utils/hocs'

import envSettings from '../../globals/envSettings'

const Poll = withContent(({ locale }) => {
  const pollConfig = _.get(usePollConfig(), 'pollConfig')

  const token = _.get(pollConfig, 'poll')
  const config = _.omit(pollConfig, ['poll', 'url'])

  const isPollEnabled = token && config

  useEffect(() => {
    if(isPollEnabled) {
      const setupScript = document.createElement('script')
      setupScript.type = 'text/javascript'
      setupScript.id = 'wootric-settings'
      setupScript.async = true
      setupScript.innerHTML = `
        wootric_no_surveyed_cookie = ${envSettings.WOOTRIC_NO_SURVEYED_COOKIE ? envSettings.WOOTRIC_NO_SURVEYED_COOKIE : 'false'}
        wootric_survey_immediately = ${envSettings.WOOTRIC_SURVEY_IMMEDIATELY ? envSettings.WOOTRIC_SURVEY_IMMEDIATELY : 'false'}
        window.wootricSettings = {
          email: '${pollConfig.id_profesor}',
          created_at: ${pollConfig.fecha_registro_date},
          account_token: "${token}",
          language: "${locale === 'es' ? 'ES' : 'EN'}",
          ${envSettings.WOOTRIC_MODAL_THEME ? `modal_theme: '${envSettings.WOOTRIC_MODAL_THEME}',` : '' }
          ${envSettings.WOOTRIC_MODAL_FOOTPRINT ? `modal_footprint: '${envSettings.WOOTRIC_MODAL_FOOTPRINT}',` : '' }
          ${envSettings.WOOTRIC_MODAL_POSITION ? `modal_position: '${envSettings.WOOTRIC_MODAL_POSITION}',` : '' }
          ${envSettings.WOOTRIC_ARIA !== undefined ? `aria: ${envSettings.WOOTRIC_ARIA},` : ''}
          ${envSettings.WOOTRIC_SCALE_COLOR ? `scale_color: '${envSettings.WOOTRIC_SCALE_COLOR}',` : '' }
          ${envSettings.WOOTRIC_SURVEY_TYPE_SCALE ? `survey_type_scale: ${envSettings.WOOTRIC_SURVEY_TYPE_SCALE},` : '' }
          properties: ${JSON.stringify(config)}
        }
      `
      if(document.getElementById('wootric-settings') == null) {
        document.body.appendChild(setupScript)
      }

      // Beacon
      const beacon = document.createElement('script')
      beacon.type = 'text/javascript'
      beacon.id = 'wootric-beacon'
      beacon.async = true

      beacon.src = 'https://cdn.wootric.com/wootric-sdk.js'
      beacon.onload = function () {
        window.wootric('run')
      }
      if(document.getElementById('wootric-beacon') == null) {
        document.body.appendChild(beacon)
      }
    }
  }, [
    locale,
    isPollEnabled,
    token,
    config,
    pollConfig
  ])

  if(isPollEnabled) {
    return <div />
  } else {
    return null
  }
})

export default () => (<Feature name="polls"><Poll /></Feature>)