import { useState, useEffect } from 'react'

export default () => {
  const [viewport, setViewport] = useState({
    width: undefined,
    height: undefined,
    breakpoint: undefined
  })

  const getBreakpoint = (width) => {
    if(width < 576) return 'xs'
    if(width < 768) return 'sm'
    if(width < 992) return 'md'
    if(width < 1200) return 'lg'
    if(width >= 1200) return 'xl'
    return undefined
  }

  const handleViewport = () => {
    setViewport({
      width: window.innerWidth,
      height: window.innerHeight,
      breakpoint: getBreakpoint(window.innerWidth)
    })
  }

  useEffect(() => {
    window.addEventListener('resize', handleViewport)
    handleViewport()
    return () => window.removeEventListener('resize', handleViewport)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return viewport
}
