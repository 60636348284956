import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Col } from 'reactstrap'

import { getLocale } from '../../language'
import backend from '../../alfresco/getCMSContent'
import { parseToReactElement } from '../../utils/html'
import { withUserInfo } from '../../utils/hocs'

class Banner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentId : props.contentId,
      error: false,
      language: props.userInfo ? getLocale(props.userInfo.language) : getLocale(),
      visible: false,
      visibleFn: props.visible
    }
  }

  signal = axios.CancelToken.source()

  componentDidMount() {
    const { contentId, language, visibleFn } = this.state
    if(!contentId || !visibleFn || !(visibleFn instanceof Promise)) {
      this.setState({error: true})
    } else {
      // We'll find out if the banner is visible
      visibleFn
      .then(visible => {
        if(visible) {
          // We'll look up for the content
          const callback = (error, response) => {
            if(error) {
              if(!axios.isCancel(error)) {
                this.setState({ error, visible })
              }
            } else {
              this.setState({ visible, content: response.data.content })
            }
          }
          backend.getCMSContent(null, contentId, language, true, this.signal.token, callback)
        }
      })
      .catch(error => {
        //this.setState({error})
      })
    }
  }

  componentWillUnmount() {
    this.signal.cancel()
  }

  render() {
    const { content, error, visible } = this.state
    const { target, component: Component, onClick } = this.props

    if(error || !visible) return []
    else return (
      <Col className="banner my-1 py-3 d-flex" xs="12" md={true}>
        <div className="m-auto d-inline-block" onClick={onClick}>
          { target ?
              <Link style={{display:'block'}} to={target}>
                <div>{ parseToReactElement(content) }</div>
              </Link>
              : parseToReactElement(content)
          }
          { Component ? <Component /> : null }
        </div>
      </Col>
    )
  }
}

Banner.propTypes = {
  contentId: PropTypes.string.isRequired,
  target: PropTypes.string,
  // We'll have to force async functions
  visible: PropTypes.instanceOf(Promise)
}

Banner.defaultProps = {
  visible: new Promise(resolve => resolve(true))
}

export default withUserInfo(Banner)
