import React from 'react';
import Wrapper from './Wrapper'
// Settings constants
import envSettings from '../../../globals/envSettings'
import PropTypes from 'prop-types'

const Video = ({ data: { previewURL, mimetype }, paddingBottom }) => {

  return (
    <div style={{ paddingBottom }} className="h-100 w-100 resources__video">
      <div className="h-100 w-100 d-flex justify-content-center align-items-center" >
        <video className="resources__video__height" preload="auto" controls>
          <source src={`${envSettings.API_BASEURL}${previewURL}`} type={mimetype} />
        </video>
      </div>
    </div>
  )
}

Video.propTypes = {
  data: PropTypes.object.isRequired
}

export default Wrapper(Video)