import React,{Component,Fragment}from"react"
import{withContent,withUserInfo}from"../../utils/hocs"
//import { FeatureContext } from '../../contexts/Feature/FeatureContext'
import{Modal,ModalBody,Row,Col,Button,ModalHeader,ModalFooter,Container}from"reactstrap"
import axios from"axios"
import axiosConfig from"../../config/axios"
import _ from"lodash"
import{DrawBook}from"../DrawBookGuidance/DrawBook"
import editlibraryConstants from"./ModalLibraryGuidanceConstants"
import userRoles from"../../globals/userRoles"

class ModalLibraryGuidance extends Component{

  //static contextType = FeatureContext

  constructor(props){
    super(props)
    this.state={
      library:[],
      notLibrary:[],
      selectedLibrary:[],
      suggestedLibrary:[],
      error:'',
      stages:[],
      allStages:[],
      adoptionStages:[],
      demoStages:[],
      activeTab:'0',
      checkedAdoption:true,
      checkedDemo:false,
      disableAdoption:false,
      disableDemo:false,
      totalAdopted:0,
      totalDemo:0,
      redirect:false,
      showModal:false,
      isHovered:false,
      hoveredNoderef:'',
      isHoveredLibrary:false
    }
    this.calculateTotals=this.calculateTotals.bind(this)
    this.elementIsSelected=this.elementIsSelected.bind(this)
    this.refreshLibrary=this.refreshLibrary.bind(this)
    this.handleHover=this.handleHover.bind(this)
    this.totalSelected = this.totalSelected.bind(this)
    this.setRedirect=this.setRedirect.bind(this)
  }
  getLibrary=(callback)=>{
    const axiosInstance=axios.create(axiosConfig)
    axiosInstance.get("/library/all")
    .then(response=>callback(response))
    .catch(error=>callback(error))
  }
  setStages(library){
    let allStages=[]
    let adoptionStages=[]
    let demoStages=[]
    let initialStages=[]
    allStages=_.uniq(library.map(item=>item.stage))
    adoptionStages=_.uniq(library.filter(item=>item.adoption===1).map(item=>item.stage))
    demoStages=_.uniq(library.filter(item=>item.adoption===0).map(item=>item.stage))
    initialStages=((adoptionStages.length===0)?demoStages:adoptionStages)
    return {
      initialStages:initialStages,
      allStages:allStages,
      adoptionStages:adoptionStages,
      demoStages:demoStages
    }
  }
  calculateTotals(library){
    if(typeof library!=='undefined'&&library.length>=0){
      const adoptions=library.reduce((countValues,values)=>{
        countValues[values.adoption]=(countValues[values.adoption]||0)+1
        return countValues
      },{0:0,1:0})
      return adoptions
    }
  }
  elementIsSelected=(noderef,arr)=>{
    if(typeof arr!=="undefined"&&arr.length>0)return(arr.includes(noderef))
    else return false
  }
  refreshLibrary=(element,arr,arrSelected,origin)=>{
    if((origin==='arrLibrary')||(origin==='arrSelectedLibrary'&&this.elementIsSelected(element.nodeRef,arrSelected))){
      arr=arr.filter(item=>item.nodeRef!==element.nodeRef)
      arrSelected=arrSelected.filter(item=>item!==element.nodeRef)
    }else{
      arr=arr.concat(element)
      arrSelected=arrSelected.concat(element.nodeRef)
    }
    this.setState({library:arr,selectedLibrary:arrSelected})
  }
  confirmLibrary=(library,selectedLibrary,e)=>{
    e.preventDefault()
    //const output=library.filter((element)=>{return selectedLibrary.indexOf(element)<0}).map(item=>item.idTitulo+'-'+item.adoption)
    const output = library.filter(element => selectedLibrary.indexOf(element) < 0).map(item => `${item.idTitulo}-${item.adoption}-0`)
    this.putLibrary({library:output},(response)=>{
      if(response.data==='OK')this.setRedirect()
      else this.setError(response.response.data.message)
    })
  }
  putLibrary=(library,callback)=>{
    const axiosInstance=axios.create(axiosConfig);
    axiosInstance.put('/library', library)
    .then(response=>callback(response))
    .catch(error=>callback(error))
  }
  handleHover(noderef,block){
    this.setState(prevState=>({
      isHovered:(block!=="LIBRARY")?!prevState.isHovered:false,
      isHoveredLibrary:(block==="LIBRARY")?!prevState.isHoveredLibrary:false,
      hoveredNoderef:noderef
    }))
  }
  totalSelected=(arr)=>(arr.length)
  
  setRedirect=()=>(this.setState({redirect:true}))
  /*setRedirect = () => {
    this.setState(
      { redirect: true },
      () => { this.context.setModalFlag("library", false) }
    )
  }*/

  setError=(message)=>{this.setState({redirect:true,error:message})}
  /*setError = message => {
    this.setState(
      { redirect: true, error: message },
      () => { this.context.setModalFlag("library", false) }
    )
  }*/

  componentDidMount(){
    this.getLibrary(res=>{
      if(res.data){
        const all=[]
        const library=res.data.filter(item=>item.origin==="LIBRARY")
        const tmpLibrary=res.data.filter(item=>item.origin==="NOTLIBRARY")
	let notLibrary
	switch(this.props.userInfo.role){
	  case userRoles.TEACHER:
	    notLibrary=tmpLibrary.filter(item=>item.adoption===1)
	    if(notLibrary.length===0)notLibrary=tmpLibrary.filter(item=>item.adoption===0)
	    break;
	  case userRoles.TEACHER_DEMO:
	    notLibrary=tmpLibrary.filter(item=>item.adoption===0)
	    break;
          default:
	    notLibrary=tmpLibrary.filter(item=>item.adoption===0)
	    break;
	}
        const selectedLibrary = library.map(item=>item.nodeRef)
        const stages=this.setStages(notLibrary)
        stages.initialStages=all.concat(stages.initialStages)
        stages.allStages=all.concat(stages.allStages)
        stages.adoptionStages=all.concat(stages.adoptionStages)
        stages.demoStages=all.concat(stages.demoStages)
        let totals={0:0,1:0}
        if(typeof notLibrary!=="undefined"&&notLibrary.length>=0){totals=this.calculateTotals(notLibrary)}
        this.setState({
          library:library,
          selectedLibrary:selectedLibrary,
          notLibrary:notLibrary,
          stages:stages.initialStages,
          allStages:stages.allStages,
          adoptionStages:stages.adoptionStages,
          demoStages:stages.demoStages,
          totalAdopted:totals[1],
          totalDemo:totals[0],
          checkedAdoption:(totals[1]===0)?false:true,
          checkedDemo:(totals[1]!==0)?false:((totals[0]!==0)?true:false),
          disableAdoption:(totals[1]===0)?true:false,
          disableDemo:(totals[1]===0)?true:((totals[0]===0)?true:false),
        })
      }
    })
  }
  render(){
    const {content,userInfo}=this.props
    return(
      <Fragment>
        {this.state.redirect&&this.state.error===''&&(window.location.href="/")}
        {this.state.redirect&&this.state.error!==''&&(window.location.href="/error")}
      <Modal isOpen={true}size="xl"scrollable={true}className="TeacherRegister">
        <ModalHeader className="guidance">
          <Row className="ml-5 mr-5">
            <Col>
              <h2 className="font-weight-bold">{content.guidance.guidance_title}</h2>
            </Col>
          </Row>
	        <Row className="ml-5 mr-5">
            <Col>
              <h6>{userInfo.role===userRoles.TEACHER?content.guidance.adoptions_description1.replace("XXX",userInfo.schoolName):content.guidance.noadoptions_description1.replace("XXX",userInfo.schoolName)}</h6>
            </Col>
          </Row>
	        <Row className="ml-5 mr-5">
            <Col>
              <h6>{userInfo.role===userRoles.TEACHER?content.guidance.adoptions_description2:content.guidance.noadoptions_description2}</h6>
            </Col>
          </Row>
	      </ModalHeader>
        <ModalBody>
	  <Container fluid={true}>
          <Row className="ml-5 mr-5">
            {this.state.notLibrary.map((title)=>{
              return(
                <DrawBook
                  key={title.nodeRef}
		  title={title}
                  elementIsSelected={this.elementIsSelected}
                  selectedLibrary={this.state.selectedLibrary}
                  refreshLibrary={this.refreshLibrary}
                  library={this.state.library}
                  origin="arrSelectedLibrary"
                  content={content}
                  isHovered={this.state.isHovered}
                  hoveredNoderef={this.state.hoveredNoderef}
                  handleHover={this.handleHover}
                />
              )
            })}
          </Row>
	  </Container>
	</ModalBody>
	<ModalFooter>
	  <Row className="ml-5 mr-5 mb-5 w-100">
	    <Col className="text-right justify-content-end edit__library">
              <Button
	        className="edit__library__accept"
                disabled={
                  ((this.totalSelected(this.state.selectedLibrary)>editlibraryConstants.MAX_LIBRARY_SIZE && userInfo.role===userRoles.USER_INTERNAL)||
                  this.totalSelected(this.state.selectedLibrary)===0)
		}
                onClick={(e)=>this.confirmLibrary(this.state.library,this.state.selectedLibrary,e)}
                outline>
	         {content.guidance.confirm_button}
              </Button>
            </Col>
	  </Row>
        </ModalFooter>
      </Modal>
      </Fragment>
    )
  }
}
export default withContent(withUserInfo(ModalLibraryGuidance))