import React from 'react'

export const defaultState = {
  adTitlePromotionBanners: null,
  dmTitlePromotionBanners: null,
  wpEnabled: true
}

const WPDataContext = React.createContext(defaultState)

export default WPDataContext