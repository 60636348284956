// MBQ 12/05/2021: New version
import React, { Component, Fragment } from 'react'
import { AuthConsumer } from 'react-check-auth'
import {
  sendOrder,
  // MBQ Simultaneous promises instead of sequential callbacks
  //orderId,
  //vat,
  getOrderId,
  getVAT,
  getOrderCost,
  getOrderChildren,
  //adoptionDecisions,
  centersByZipCode,
  schoolLevels,
  schoolLevelSubjects,
  schoolLevelSubjectISBNS,
  uploadImages,
  // JGB - We need to find a better and safer way to do this
  // deleteImages,
  pay,
  direction,
  access
} from '../../services/wsGratisHP'
import { ContentConsumer } from '../../language'
//import Select from '../Select/Select'
import Sons from '../Sons/Sons'
import { getLocale } from '../../language'
import CMSContent from '../CMSContent/CMSContent'
import Modal from '../Modal/Modal'
import {
  Container,
  Button,
  Progress,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
  FormFeedback,
  Alert
} from 'reactstrap';

import { validateDate } from '../../utils/formValidator'
import gratishpConstants from './gratishpConstants'
import { parseToReactElement } from '../../utils/html'

const HOME = '/app'

class GratisHP extends Component {
  constructor(props) {
    super(props)
    this.state = {
      order: {
        access: false,
        accessprogress: 0,
        id_profesor: '',
        id_centro_profesor: '',
        id_peticion: '',
        //n_profesores_departamento: '',
        n_profesores_departamento: null,
        //id_decision_adopcion: '',
        id_decision_adopcion: null,
        f_solicitud: '',
        //importePedido: '',
        iva: '',
        //totalPedido: '',
        //importeIVA: '',
        cost: 0,
        vatCost: 0,
        totalCost: 0,
        hijos: []
      },
      error: {},
      numero_hijo: 1,
      listas: [],
      images: [],
      legalAdvice: false,
      zipCodeError:new Set(),
      componentesError:new Set(),
      levelError:new Set(),
      wrongZipCode:new Set(),
      activeChild: '1',
      libroFamiliaError:new Set(),
      noAdoptionsError:new Set(),
      searchFlags: {
        searchingSchool: false,
        searchingLevels: false,
        searchingSubjects: false,
        searchingBooks: false
      }
    }
    this.handleSubmitSendOrder = this.handleSubmitSendOrder.bind(this)
    //this.handleChangeInputNumberTeachers = this.handleChangeInputNumberTeachers.bind(this)
    //this.handleChangeInput = this.handleChangeInput.bind(this)
    this.addSon = this.addSon.bind(this)

    // MBQ FIX Delete child & Activate tab
    //this.deleteSon = this.deleteSon.bind(this)

    this.addTitle = this.addTitle.bind(this)
    this.deleteTitle = this.deleteTitle.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.updateHijo = this.updateHijo.bind(this)
    this.updatePropiedadHijo = this.updatePropiedadHijo.bind(this)
    this.errorPedido = this.errorPedido.bind(this)
    this.toggle = this.toggle.bind(this)
    this.toggleCondicionesServicio = this.toggleCondicionesServicio.bind(this)
    this.tooglePasarela = this.tooglePasarela.bind(this)
    this.defaultImage = this.defaultImage.bind(this)
  }

  toggleCondicionesServicio(e, cancelar, userInfo) {
    if (cancelar) window.location.href = HOME
    else{
      window.scrollTo({
        top: 0,
        left: 0
      });
      this.getDirection(userInfo.schoolId)
      this.setState(prevState => ({ condiciones_servicio: !prevState.condiciones_servicio }))
    }
  }
  handleLegalAdvice() {
    this.setState(prevState => ({
      legalAdvice: !prevState.legalAdvice
    }))
    if (this.state.error.legalAdvice) this.setState(state => (({ error: { ...state.error, legalAdvice: false } })))
  }

  tooglePasarela(e, pagar,content) {
    if (pagar) {
      this.setState(prevState => ({ pagando: !prevState.pagando, progress: 0, progresstext: content.gratishp.gratishp_uploading_label}))
      uploadImages(
        this.state.order.hijos,
        () => {
          this.setState({ progress: 25, progresstext: content.gratishp.gratishp_sending_label})
          sendOrder(
            this.state.order,
            () => {
              this.setState({ progress: 50, progresstext: content.gratishp.gratishp_redirecting_label})
              pay(
                this.state.order.id_peticion,
                /*
                Math.round(this.state.order.hijos.reduce((total, hijo) => {
                  if (hijo.componentes) {
                    total += hijo.componentes.reduce((subtotal, componente) => {
                      return subtotal += componente.coste
                    }, 0)
                  }
                  return total
                }, 0) * (1 + this.state.order.iva / 100) * 100) / 100,
                */
                this.state.order.totalCost,
                (data) => {
                  this.setState({ progress: 75 })
                  if (data) {
                    const formPago = document.createElement("form")
                    formPago.setAttribute('action', data.action)
                    formPago.setAttribute('method', 'POST')
                    formPago.setAttribute('style', "display:none")
                    for (let k in data.parameters) {
                      let field = document.createElement("input")
                      field.setAttribute("type", "hidden")
                      field.setAttribute("name", k)
                      field.setAttribute("value", data.parameters[k])
                      formPago.appendChild(field)
                    }
                    document.body.appendChild(formPago)
                    this.setState({ progress: 100 })
                    formPago.submit()
                  } else {
                    this.setState(prevState => ({ pagando: !prevState.pagando }))
                    // Disabled
                    // deleteImages(this.state.order.hijos, () => { return })
                    return this.errorPedido(this.state.order)
                  }
                },
                (err) => {
                  this.setState(prevState => ({ pagando: !prevState.pagando }))
                  // Disabled
                  // deleteImages(this.state.order.hijos, () => { return })
                  return this.errorPedido(this.state.order)
                }
              )
            },
            () => {
              this.setState(prevState => ({ pagando: !prevState.pagando }))
              // Disabled
              // deleteImages(this.state.order.hijos, () => { return })
              return this.errorPedido(this.state.order)
            }
          )
        },
        (err) => {
          if (err) {
            this.setState(prevState => ({ pagando: !prevState.pagando }))
            // Disabled
            // deleteImages(this.state.order.hijos, () => { return })
            return this.errorPedido(this.state.order)
          }
        }
      )
    } else {
      if (!this.state.pagando) this.setState(prevState => ({ pasarelaDePago: !prevState.pasarelaDePago }))
    }
  }
  toggle(e,enviar,content) {
    //if (enviar) this.tooglePasarela()
    if (enviar)this.tooglePasarela(e,'pagar',content)
    else this.setState(prevState => ({ modal: !prevState.modal }))
  }

  // MBQ Simultaneous promises instead of sequential callbacks
  /*
  getOrderId(){
    orderId((err,data)=>{
      if(data)this.setState(state=>(({order:{...state.order,'id_peticion':data.id}})))
      if(err){
        alert('Se ha producido un error, intente volver a la página más tarde.')
        window.location.href=HOME
      }
    })
  }
  */

  // MBQ Simultaneous promises instead of sequential callbacks
  /*
  getVAT(){
    vat((err,data)=>{
      if(data)this.setState(state => (({ order: { ...state.order, 'iva': data.vat } })))
      if(err){
        alert('Se ha producido un error, intente volver a la página más tarde.')
        window.location.href=HOME
      }
    })
  }
  */

  // MBQ Simultaneous promises instead of sequential callbacks
  /*
  // MBQ Get order cost (initial version)
  getOrderCost = () => (
    oderCost().then(cost => {
      // MBQ TODO VAT: this.state.iva
      const vatCost = Number(((cost * 21) / 100).toFixed(2))
      const totalCost = cost + vatCost
      this.setState(state => ({
        order: { ...state.order, cost, vatCost, totalCost }
      }))      
    }).catch(() => {
      alert('Se ha producido un error, intente volver a la página más tarde.')
      return window.location.href = HOME
    })    
  )
  */
  
  getDirection(school) {
    direction(school, (err, data) => {
      if (data) {
        this.setState({ direction: data.direction })
      }
    })
  }
  /*
  getAdoptionDecisions() {
    adoptionDecisions((err,data)=>{
      if(data)this.setState({decisions:data.decisiones})
      if(err){
        alert('Se ha producido un error, intente volver a la página más tarde.')
        window.location.href=HOME
      }
    })
  }
  */
  getCentersByZipCode(zipcode, numero_hijo) {
    let copiaListas1 = this.state.listas.reduce((listas, lista) => {
      let copiaLista1 = Object.assign({}, lista)
      if (lista.numero_hijo === numero_hijo) {
        copiaLista1.centers = []
        copiaLista1.levels = []
        copiaLista1.subjects = []
        copiaLista1.isbns = []
      }
      listas.push(copiaLista1)
      return listas
    }, [])
    this.setState(prevState => ({
      listas: copiaListas1,
      searchFlags: {...prevState.searchFlags, searchingSchool: true }
    }))
    centersByZipCode(zipcode, (err, data) => {
      let newWrongZipCode=new Set(this.state.wrongZipCode)
      if (data) {
        let copiaListas2 = this.state.listas.reduce((listas, lista) => {
          let copiaLista2 = Object.assign({}, lista)
          if (lista.numero_hijo === numero_hijo) {
            copiaLista2.centers = data.centros
            copiaLista2.levels = []
            copiaLista2.subjects = []
            copiaLista2.isbns = []
          }
          listas.push(copiaLista2)
          return listas
        }, [])
        this.setState(prevState => ({ listas: copiaListas2, searchFlags: {...prevState.searchFlags, searchingSchool: false }}))
        this.updatePropiedadHijo(numero_hijo, 'id_centro_hijo', '0')
        if(data.centros.length===0){
          if(!newWrongZipCode.has(numero_hijo)){
            newWrongZipCode.add(numero_hijo)
            this.setState({wrongZipCode:newWrongZipCode})
          }
        }
        else{
          if(newWrongZipCode.has(numero_hijo)){
            newWrongZipCode.delete(numero_hijo)
            this.setState({wrongZipCode:newWrongZipCode})
          }
        }
      }else{
        if(!newWrongZipCode.has(numero_hijo)){
          newWrongZipCode.add(numero_hijo)
          this.setState({wrongZipCode:newWrongZipCode})
        }
        this.setState(prevState => ({searchFlags: {...prevState.searchFlags, searchingSchool: false }}))
      }
    })
  }

  // MBQ Get levels of children from previous year
  /*  
  getSchoolLevels(school, numero_hijo) {
    let copiaListas1 = this.state.listas.reduce((listas, lista) => {
      let copiaLista1 = Object.assign({}, lista)
      if (lista.numero_hijo === numero_hijo) {
        copiaLista1.levels = []
        copiaLista1.subjects = []
        copiaLista1.isbns = []
      }
      listas.push(copiaLista1)
      return listas
    }, [])
    this.setState(prevState => ({
      listas: copiaListas1,
      searchFlags: {...prevState.searchFlags, searchingLevels: true }
    }))
    schoolLevels(school, (err, data) => {    
      if (data) {
        let copiaListas2 = this.state.listas.reduce((listas, lista) => {
          let copiaLista2 = Object.assign({}, lista)
          if (lista.numero_hijo === numero_hijo) {
            copiaLista2.levels = data.niveles
            copiaLista2.subjects = []
            copiaLista2.isbns = []
          }
          listas.push(copiaLista2)
          return listas
        }, [])
        let newNoAdoptionsError=new Set(this.state.noAdoptionsError)
        if(data.niveles.length===0){
          if(!newNoAdoptionsError.has(numero_hijo)){
            newNoAdoptionsError.add(numero_hijo)
            this.setState({noAdoptionsError:newNoAdoptionsError})
          }
        }else{
          if(newNoAdoptionsError.has(numero_hijo)){
            newNoAdoptionsError.delete(numero_hijo)
            this.setState({noAdoptionsError:newNoAdoptionsError})
          }
        }
        this.setState(prevState => ({listas: copiaListas2, searchFlags: {...prevState.searchFlags, searchingLevels: false }}))
        this.updatePropiedadHijo(numero_hijo, 'level', '0')
      }
    })
  }
  */
  getSchoolLevels(school, numero_hijo) {
    let childId = null
    let newNoAdoptionsError = new Set(this.state.noAdoptionsError)
    const children = this.state.order.hijos.map(item => {
      const child = { ...item }
      if (child.numero_hijo === numero_hijo) {
        childId = child.id
        child.level = '0'
      }
      return child
    })
    const childrenLists = this.state.listas.map(item => {
      const list = { ...item }
      if (list.numero_hijo === numero_hijo) {
        list.levels = []
        list.subjects = []
        list.isbns = []
      }
      return list
    })
    // MBQ Do not call to the endpoint with center 0
    if (school === '0') {
      if (newNoAdoptionsError.has(numero_hijo)) newNoAdoptionsError.delete(numero_hijo)
      this.setState(prevState => ({
        order: {
          ...prevState.order,
          hijos: children
        },
        listas: childrenLists,
        searchFlags: { ...prevState.searchFlags, searchingLevels: false },
        noAdoptionsError: newNoAdoptionsError
      }))
    } else {
      this.setState(prevState => ({
        listas: childrenLists,
        searchFlags: { ...prevState.searchFlags, searchingLevels: true }
      }), () => (
        schoolLevels(school, childId).then(levels => {
          if (newNoAdoptionsError.has(numero_hijo)) newNoAdoptionsError.delete(numero_hijo)
          const children = this.state.order.hijos.map(item => {
            const child = { ...item }
            if (child.numero_hijo === numero_hijo) child.level = '0'
            return child
          })
          const childrenLists = this.state.listas.map(item => {
            const list = { ...item }
            if (list.numero_hijo === numero_hijo) list.levels = levels
            return list
          })
          this.setState(prevState => ({
            order: {
              ...prevState.order,
              hijos: children
            },
            listas: childrenLists,
            searchFlags: { ...prevState.searchFlags, searchingLevels: false },
            noAdoptionsError: newNoAdoptionsError
          }))
        }).catch(() => {
          if (!newNoAdoptionsError.has(numero_hijo)) newNoAdoptionsError.add(numero_hijo)
          this.setState(prevState => ({
            searchFlags: { ...prevState.searchFlags, searchingLevels: false },
            noAdoptionsError: newNoAdoptionsError
          }))
        })
      ))
    }
  }

  getSchoolLevelSubjecs(school, level, numero_hijo) {
    let copiaListas1 = this.state.listas.reduce((listas, lista) => {
      let copiaLista1 = Object.assign({}, lista)
      if (lista.numero_hijo === numero_hijo) {
        copiaLista1.subjects = []
        copiaLista1.isbns = []
      }
      listas.push(copiaLista1)
      return listas
    }, [])
    this.setState(prevState => ({
      listas: copiaListas1,
      searchFlags: {...prevState.searchFlags, searchingSubjects: true }
    }))
    schoolLevelSubjects(school, level, (err, data) => {
      if (data) {
        let copiaListas2 = this.state.listas.reduce((listas, lista) => {
          let copiaLista2 = Object.assign({}, lista)
          if (lista.numero_hijo === numero_hijo) {
            copiaLista2.subjects = data.asignaturas
            copiaLista2.isbns = []
          }
          listas.push(copiaLista2)
          return listas
        }, [])
        this.setState(prevState => ({ listas: copiaListas2, searchFlags: {...prevState.searchFlags, searchingSubjects: false }}))
        this.updatePropiedadHijo(numero_hijo, 'subject', '0')
      }
    })
  }
  getSchoolLevelSubjecISBNS(school, level, subject, teacher, id_centro_profesor, numero_hijo) {
    let copiaListas1 = this.state.listas.reduce((listas, lista) => {
      let copiaLista1 = Object.assign({}, lista)
      if (lista.numero_hijo === numero_hijo) {
        copiaLista1.isbns = []
      }
      listas.push(copiaLista1)
      return listas
    }, [])
    this.setState(prevState => ({
      listas: copiaListas1,
      searchFlags: {...prevState.searchFlags, searchingBooks: true }
    }))
    schoolLevelSubjectISBNS(school, level, subject, teacher, id_centro_profesor, (err, data) => {
      if (data) {
        let copiaListas2 = this.state.listas.reduce((listas, lista) => {
          let copiaLista2 = Object.assign({}, lista)
          if (lista.numero_hijo === numero_hijo) {
            copiaLista2.isbns = data.isbns
          }
          listas.push(copiaLista2)
          return listas
        }, [])
        this.setState(prevState => ({ listas: copiaListas2, searchFlags: {...prevState.searchFlags, searchingBooks: false }}))
        this.updatePropiedadHijo(numero_hijo, 'isbn', '0')
      }
    })
  }

  // MBQ TODO Make a single call to the api to get all the necessary data
  componentDidMount() {
    let d = new Date()
    access((err, data) => {
      if (data && data.access === 0) {
        this.setState(state => ({
          access: true,
          accessprogress: 100,
          order: { ...state.order, 'f_solicitud': d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear() }
        }));
        // MBQ Simultaneous promises instead of sequential callbacks
        /*
        this.getOrderId();
        this.getVAT();
        // MBQ Get order cost (initial version)
        this.getOderCost()
        */
        Promise.all([
          getOrderId(),
          getVAT(),
          getOrderCost(),
          getOrderChildren()
        ]).then(([orderId, vat, orderCost, orderChildren]) => {
          const orderVat = Number(((orderCost * vat) / 100).toFixed(2))
          const totalCost = orderCost + orderVat
          const children = []
          const childLists = []
          let activeChild = '1'
          if (orderChildren && orderChildren.length) {
            orderChildren.forEach(child => {
              children.push({
                id: child.id,
                numero_hijo: (children.length + 1),
                t_nombre: child.name,
                t_apellido1: child.surname1,
                t_apellido2: child.surname2,
                f_nacimiento: child.birthDate,
                id_centro_hijo: child.schoolId,
                codigo_postal: child.schoolPostalCode,
                level: null,
                libro_familia: null
              })
              childLists.push({
                numero_hijo: (children.length),
                centers: [{
                  id_centro: child.schoolId,
                  d_centro: child.schooName
                }],
                levels: child.levels,
                subjects: [],
                isbns: []
              })
            })
            activeChild = children.length.toString()
          }
          // MBQ Add an empty child if there are no children
          else {
            children.push({
              numero_hijo: 1,
              t_nombre: '',
              t_apellido1: '',
              t_apellido2: '',
              f_nacimiento: '',
              id_centro_hijo: null,
              codigo_postal: '',
              level: null,
              //libro_familia: null
              imagen_libro_familia: undefined,
              libro_familia: undefined,
              data: undefined
            })
            childLists.push({
              numero_hijo: 1,
              centers: [],
              levels: [],
              subjects: [],
              isbns: []
            })
          }
          this.setState(state => ({
            order: {
              ...state.order,
              id_peticion: orderId,
              iva: vat,
              cost: orderCost,
              vatCost: orderVat,
              totalCost,
              hijos: children
            },
            listas: childLists,
            numero_hijo: (children.length + 1),
            activeChild
          }))
        }).catch(() => {
          // MBQ TODO Reimplement error handling
          alert('Se ha producido un error, intente volver a la página más tarde.')
          return window.location.href = HOME
        })

        //this.getAdoptionDecisions();
      } else {
        // TODO: navigate to private home when we launch it.
        // TODO: obtain language and redirect to correct language on Liferay
        window.location.href = HOME
      }
    })
  }

  // MBQ TODO Reimplement error handling
  errorPedido(order) {
    alert('Se ha producido un error al enviar el pedido')
    //deleteImages(order.hijos,()=>{return})
  }
  
  handleSubmitSendOrder(e, content) {

    e.preventDefault()

    const { order, listas, legalAdvice } = this.state,
      newError = {}

    //if (/^\s*$/.test(order.n_profesores_departamento) || isNaN(order.n_profesores_departamento)) newError.n_profesores_departamento = true
    //if (order.id_decision_adopcion === '-1' || /^\s*$/.test(order.id_decision_adopcion)) newError.id_decision_adopcion = true
    if (order.hijos.length <= 0) newError.hijosEmpty = true

    if (!(order.hijos.length <= 0)) {

      const { hijos } = order,
        hijosError = []

      hijos.forEach(data => {

        const obj = { numero_hijo: data.numero_hijo }

        if (/^\s*$/.test(data.t_nombre)) obj.t_nombre = true
        if (/^\s*$/.test(data.t_apellido1)) obj.t_apellido1 = true

        if (!(validateDate(data.f_nacimiento))) obj.f_nacimiento = true

        //if (!data.libro_familia) obj.libro_familia = true
        if(!data.id && !data.libro_familia) obj.libro_familia = true

        if (!(/^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/.test(data.codigo_postal))) obj.codigo_postal = true
        // ERROR EN IE (PREM-2916)
        //if (data.data && data.data.get('libro_familia').size > gratishpConstants.FILESIZE) obj.imageFileSize = true
        if (!obj.codigo_postal) {
          const filterListas = listas.find(d => d.numero_hijo === data.numero_hijo)
          if (!(filterListas.levels && (filterListas.levels.length > 0))) obj.centers = true
          if (!(filterListas.subjects && (filterListas.subjects.length > 0))) obj.levels = true
          if (!(filterListas.isbns && (filterListas.isbns.length > 0))) obj.subjects = true
          if (!data.componentes || !(data.componentes.length > 0)) obj.componentes = true
        }

        // MBQ Do not validate repeated children
        //const noRepeatFilter = hijos.filter(d => d.t_nombre + d.t_apellido1=== data.t_nombre + data.t_apellido1)
        //if (noRepeatFilter.length > 1) newError.repeatSon = true

        hijosError.push(obj)
      })
      newError.hijosError = hijosError
    }

    if (!legalAdvice) newError.legalAdvice = true

    if (this.removeObj(newError)) { this.toggle() }
    else {
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth'
      // })

      this.setState({
        error:newError,
        zipCodeError: new Set(),
        componentesError: new Set(),
        levelError:new Set(),
        wrongZipCode:new Set(),
        libroFamiliaError:new Set()
      })
    }
  }

  removeObj(obj) {

    const newHijosError = obj.hijosError ? [...obj.hijosError] : null

    let hasSonsErrors = false

    if (newHijosError) {

      newHijosError.forEach(data => {
        const newData = { ...data }
        delete newData.numero_hijo
        if (!(Object.entries(newData).length === 0 && newData.constructor === Object)) {
          hasSonsErrors = true
          this.setState({ hasSonsErrors: true })
        }
      })
    }

    if (!hasSonsErrors) {
      const newObj = { ...obj }
      delete newObj.hijosError
      return Object.entries(newObj).length === 0 && newObj.constructor === Object
    }
    else return false

  }

  /*
  handleChangeInputNumberTeachers(e) {
    const name = e.target.name
    const value = e.target.value.replace(/\D/g, "")
    this.setState(prevState => ({
      order: {
        ...prevState.order,
        n_profesores_departamento: value > 99 ? prevState.order.n_profesores_departamento : value }
    }))
    if (this.state.error[name]) this.setState(state => (({ error: { ...state.error, [name]: false } })))
  }
  */

  /*
  handleChangeInput(e) {
    const name = e.target.name
    const value = e.target.value
    this.setState(state => (({ order: { ...state.order, [name]: value } })))
    if (this.state.error[name]) this.setState(state => (({ error: { ...state.error, [name]: false } })))
  }
  */

  handleChange(e, numero_hijo, id_centro_profesor, teacher) {
    this.updateHijo(numero_hijo, e.target.name, e.target.value, id_centro_profesor, teacher)
  }
  updateHijo(numero_hijo, name, value, id_centro_profesor, teacher) {
    let update = true
    let hijosCopiados = this.state.order.hijos.reduce((hijos, hijo) => {
      let copiaHijo = Object.assign({}, hijo)
      if (hijo.numero_hijo === numero_hijo) {
        if (name === 'codigo_postal')
          if (!hijo.componentes || hijo.componentes.length === 0) {
            if (value.length === 5) {
              this.getCentersByZipCode(value, numero_hijo)
            }
          } else {
            update = false
            let newZipCodeError=new Set(this.state.zipCodeError)
            if(!newZipCodeError.has(numero_hijo)){
              newZipCodeError.add(numero_hijo)
              this.setState({zipCodeError:newZipCodeError})
            }
          }
        if (name === 'id_centro_hijo')
          if (!hijo.componentes || hijo.componentes.length === 0) {
            this.getSchoolLevels(value, numero_hijo)
          } else {
            update = false
            let newComponentesError=new Set(this.state.componentesError)
            if(!newComponentesError.has(numero_hijo)){
              newComponentesError.add(numero_hijo)
              this.setState({componentesError:newComponentesError})
            }
          }
        if (name === 'level') {
          if (!hijo.componentes || hijo.componentes.length === 0) {
            //this.setState({ levelError: false })
            this.getSchoolLevelSubjecs(hijo.id_centro_hijo, value, numero_hijo)
          } else {
            update = false
            let newLevelError=new Set(this.state.levelError)
            if(!newLevelError.has(numero_hijo)){
              newLevelError.add(numero_hijo)
              this.setState({levelError:newLevelError})
            }
          }
        }
        if (name === 'subject') {
          this.getSchoolLevelSubjecISBNS(hijo.id_centro_hijo, hijo.level, value, teacher, id_centro_profesor, numero_hijo)

        }
        if (update) {
          copiaHijo[name] = value
        }
      }
      hijos.push(copiaHijo)
      return hijos
    }, [])
    if (id_centro_profesor && teacher && !this.state.id_centro_profesor && !this.state.id_profesor) {
      this.setState(state => (({ order: { ...state.order, 'id_centro_profesor': id_centro_profesor } })))
      this.setState(state => (({ order: { ...state.order, 'id_profesor': teacher } })))
    }
    if (this.state.error.hijosError) {
      let copiaErrores = this.state.error.hijosError.reduce((errores, error) => {
        let copiaError = Object.assign({}, error)
        if(error.numero_hijo === numero_hijo) {
          if (copiaError[name]) copiaError[name] = false
          if(name==='id_centro_hijo'&&copiaError['centers'])copiaError['centers']=false
          //if(name='level'&&copiaError['levels'])copiaError['levels']=false
          //if(name='subject'&&copiaError['subjects'])copiaError['subjects']=false
        }
        errores.push(copiaError)
        return errores
      }, [])
      this.setState(state => (({ error: { ...state.error, 'hijosError': copiaErrores } })))
      this.setState({ hasSonsErrors: false })
    }

    // MBQ Do not validate repeated children
    //if (name === 't_nombre' || name === 't_apellido1' || name === 't_apellido2') this.setState(state => (({ error: { ...state.error, 'repeatSon': false } })))

    this.setState(state => (({ order: { ...state.order, 'hijos': hijosCopiados } })))
  }
  updatePropiedadHijo(numero_hijo, name, value) {
    let hijosCopiados = this.state.order.hijos.reduce((hijos, hijo) => {
      let copiaHijo = Object.assign({}, hijo)
      if (hijo.numero_hijo === numero_hijo)
        copiaHijo[name] = value
      hijos.push(copiaHijo)
      return hijos
    }, [])
    this.setState(state => (({ order: { ...state.order, 'hijos': hijosCopiados } })))
  }
  addTitle(numero_hijo, isbn) {
    let hijosCopiados = this.state.order.hijos.reduce((hijos, hijo) => {
      let copiaHijo = Object.assign({}, hijo)
      if (copiaHijo.numero_hijo === numero_hijo) {
        let titulo = this.state.listas.filter(lista => lista.numero_hijo === numero_hijo)[0].isbns.filter(item => item.isbn === isbn)
        if (copiaHijo.componentes) {
          if (!copiaHijo.componentes.some(componente => (componente.isbn === isbn)))
            copiaHijo.componentes.push({ 'isbn': titulo[0].isbn, 'titulo': titulo[0].titulo, 'coste': titulo[0].coste, stock: titulo[0].stock })
        }
        else copiaHijo.componentes = [{ 'isbn': titulo[0].isbn, 'titulo': titulo[0].titulo, 'coste': titulo[0].coste, stock: titulo[0].stock }]
      }
      hijos.push(copiaHijo)
      return hijos
    }, [])
    if (this.state.error.hijosError) {
      let copiaErrores = this.state.error.hijosError.reduce((errores, error) => {
        let copiaError = Object.assign({}, error)
        if (error.numero_hijo === numero_hijo) {
          if (copiaError['componentes']) copiaError['componentes'] = false
        }
        errores.push(copiaError)
        return errores
      }, [])
      this.setState(state => (({ error: { ...state.error, 'hijosError': copiaErrores } })))
      this.setState({ hasSonsErrors: false })
    }
    this.setState(state => (({ order: { ...state.order, 'hijos': hijosCopiados } })))
  }
  deleteTitle(numero_hijo, isbn) {
    let hijosCopiados = this.state.order.hijos.reduce((hijos, hijo) => {
      let copiaHijo = Object.assign({}, hijo)
      if (copiaHijo.numero_hijo === numero_hijo)
        copiaHijo.componentes = copiaHijo.componentes.filter(componente => componente.isbn !== isbn)
        if(!copiaHijo.componentes||copiaHijo.componentes.length===0){
          let newZipCodeError=new Set(this.state.zipCodeError)
          if(newZipCodeError.has(numero_hijo)){
            newZipCodeError.delete(numero_hijo)
            this.setState({zipCodeError:newZipCodeError})
          }
          let newComponentesError=new Set(this.state.componentesError)
          if(newComponentesError.has(numero_hijo)){
            newComponentesError.delete(numero_hijo)
            this.setState({componentesError:newComponentesError})
          }
          let newLevelError=new Set(this.state.levelError)
          if(newLevelError.has(numero_hijo)){
            newLevelError.delete(numero_hijo)
            this.setState({levelError:newLevelError})
          }
        }
      hijos.push(copiaHijo)
      return hijos
    }, [])
    this.setState(state => (({ order: { ...state.order, 'hijos': hijosCopiados } })))
  }
  addSon() {
    let numero_hijo = this.state.numero_hijo
    let activeChild = numero_hijo
    let hijos = [...this.state.order.hijos]
    let hijo = {
      numero_hijo: numero_hijo,
      id_centro_hijo: null,
      t_nombre: '',
      t_apellido1: '',
      t_apellido2: '',
      f_nacimiento: '',
      codigo_postal: '',
      level: null,
      //libro_familia: null
      imagen_libro_familia: undefined,
      libro_familia: undefined,
      data: undefined
    }
    hijos.push(hijo)
    this.setState(state => (({ order: { ...state.order, 'hijos': hijos } })))
    let listas = [...this.state.listas]
    let lista = {
      numero_hijo: numero_hijo,
      centers: [],
      levels: [],
      subjects: [],
      isbns: []
    }
    listas.push(lista)
    this.setState({ listas: listas })
    numero_hijo = Number(numero_hijo) + 1
    this.setState({ numero_hijo: numero_hijo })
    this.setState(state => (({ error: { ...state.error, 'hijosEmpty': false } })))
    //this.setState({ activeChild: listas.length.toString() })
    this.setState({ activeChild: activeChild.toString() })
  }

  // MBQ FIX Delete child & Activate tab
  /*
  deleteSon(son) {
    let hijos = this.state.order.hijos.filter(item => item.numero_hijo !== son.numero_hijo);
    this.setState(state => (({ order: { ...state.order, 'hijos': hijos } })))
    let listas = this.state.listas.filter(item => item.numero_hijo !== son.numero_hijo)
    this.setState({ listas: listas })

    // MBQ Do not validate repeated children
    //this.setState(state => (({ error: { ...state.error, 'repeatSon': false } })))

    if(this.state.order.hijos){
      let hijosTabulables=this.state.order.hijos.filter(item=>item.numero_hijo!==son.numero_hijo)
      if(hijosTabulables.length>0){
        this.setState({activeChild: hijosTabulables[0].numero_hijo.toString()})
      }
    }
  }
  */
  // MBQ FIX Delete child & Activate tab
  onClickTab = (numero_hijo, deleteFlag) => {
    this.setState(prevState => {
      if (prevState.activeChild !== numero_hijo.toString())
        return { activeChild: numero_hijo.toString() }
    }, () => {
      if (!deleteFlag) return true
      const children = this.state.order.hijos.filter(child => child.numero_hijo !== numero_hijo)
      const childLists = this.state.listas.filter(list => list.numero_hijo !== numero_hijo)
      // MBQ Reset/update active child
      const activeChild = children.length ? children[0].numero_hijo.toString() : '1'
      // MBQ Reset/update count of children
      const numberChild = children.length ? (children[(children.length - 1)].numero_hijo + 1) : 2
      this.setState(prevState => ({
        order: { ...prevState.order, hijos: children },
        listas: childLists,
        activeChild,
        numero_hijo: numberChild
      }), () => {
        const children = this.state.order.hijos
        // MBQ Add an empty child if there are no children
        if (!children.length) {
          children.push({
            numero_hijo: 1,
            t_nombre: '',
            t_apellido1: '',
            t_apellido2: '',
            f_nacimiento: '',
            id_centro_hijo: null,
            codigo_postal: '',
            level: null,
            //libro_familia: null
            imagen_libro_familia: undefined,
            libro_familia: undefined,
            data: undefined
          })
          const childLists = this.state.listas
          childLists.push({
            numero_hijo: 1,
            centers: [],
            levels: [],
            subjects: [],
            isbns: []
          })
          this.setState(prevState => ({
            order: { ...prevState.order, hijos: children },
            listas: childLists
          }))
        }
      })
    })
  }

  handleFileUpload(e, numero_hijo) {
    // ERROR EN IE (PREM-2916)
    let newLibroFamiliaError=new Set(this.state.libroFamiliaError)
    if(e.target.files[0].size < gratishpConstants.FILESIZE){
      let hijosCopiados = this.state.order.hijos.reduce((hijos, hijo)=>{
        let copiaHijo = Object.assign({}, hijo)
        if (hijo.numero_hijo === numero_hijo) {
          copiaHijo['imagen_libro_familia'] = URL.createObjectURL(e.target.files[0])
          copiaHijo['libro_familia'] = this.state.order.id_peticion.toString() + numero_hijo.toString() + (e.target.files[0].name.slice((e.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) === '' ? '' : '.' + e.target.files[0].name.slice((e.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2))
          const data = new FormData()
          data.append('libro_familia', e.target.files[0])
          data.append('id_peticion', this.state.order.id_peticion)
          data.append('hijo', numero_hijo)
          copiaHijo['data'] = data
        }
        hijos.push(copiaHijo)
        return hijos
        }, [])
      if(this.state.error.hijosError){
        let copiaErrores = this.state.error.hijosError.reduce((errores, error) => {
          let copiaError = Object.assign({}, error)
          if (error.numero_hijo === numero_hijo) {
            if (copiaError[e.target.name]) copiaError[e.target.name] = false
          }
          errores.push(copiaError)
          return errores
          }, [])
        this.setState(state => (({ error: { ...state.error, 'hijosError': copiaErrores } })))
        this.setState({ hasSonsErrors: false })
      }
      this.setState(state => (({ order: { ...state.order, 'hijos': hijosCopiados } })))
      if(newLibroFamiliaError.has(numero_hijo)){
        newLibroFamiliaError.delete(numero_hijo)
        this.setState({libroFamiliaError:newLibroFamiliaError})
      }
    }else{
      if(!newLibroFamiliaError.has(numero_hijo)){
        newLibroFamiliaError.add(numero_hijo)
        this.setState({libroFamiliaError:newLibroFamiliaError})
      }
      let hijosCopiados = this.state.order.hijos.reduce((hijos, hijo) => {
        let copiaHijo = Object.assign({}, hijo)
        if (hijo.numero_hijo === numero_hijo) {
          copiaHijo['imagen_libro_familia'] = undefined
          copiaHijo['libro_familia'] = undefined
          copiaHijo['data'] = undefined
        }
        hijos.push(copiaHijo)
        return hijos
      }, [])
      this.setState(state=>(({order:{...state.order,'hijos':hijosCopiados}})))
    }
  }
  defaultImage(e) {
    e.target.src = gratishpConstants.NOT_FOUND_IMAGE
  }
  render() {

    const { error } = this.state

    return (
      <div style={{ backgroundColor: !this.state.condiciones_servicio ? '#eee' : '#fff' }}>
        <AuthConsumer>
          {({ userInfo }) => {
            return (
              <ContentConsumer>
                {({ content }) => {
                  return (content && (
                    <Fragment>
                      <Modal isOpen={!this.state.access}>
                        {!this.state.access &&
                          <Progress color="success" value="100" striped animated>{content.gratishp.gratishp_checking_access_label}</Progress>
                        }
                        </Modal>
                      {this.state.access &&
                        <Fragment>
                          {!this.state.condiciones_servicio &&
                            <Container className="hp__form py-3">
                              <div className="hp__modal" >
                                <div className="p-3">
                                  <div className="hp__modal__content">
                                    <CMSContent pageid='PREM_HPSCONDICIONES'
                                      language={getLocale(userInfo.language)} />
                                  </div>

                                    <Row className="hp__modal__footer">
                                      <Col xs="12" sm="6" className="text-center">
                                        <Button className="hp__modal__footer__decline" onClick={(e) => this.toggleCondicionesServicio(e, 'cancelar', userInfo)}>
                                          {content.gratishp.gratishp_modal_conditions_service_cancel_button_label}
                                        </Button>
                                      </Col>

                                      <Col xs="12" sm="6" className="text-center">
                                        <Button className="hp__modal__footer__accept" onClick={(e) => this.toggleCondicionesServicio(e, null, userInfo)}>
                                          {content.gratishp.gratishp_conditions_service_modal_accept_buton}
                                        </Button >
                                      </Col>
                                    </Row>

                                </div>
                              </div>
                            </Container>
                          }
                        </Fragment>
                      }
                      <Modal
                        className="summary__Modal"
                        isOpen={this.state.modal ? this.state.modal : false}
                        header={content.gratishp.gratishp_modal_summary_label + ' ' + this.state.order.id_peticion}
                      >
                        {this.state.pagando && (<Progress color="success" value={this.state.progress}>{this.state.progresstext}</Progress>)}

                        <h6>{userInfo.name}</h6>
                        <p>{content.gratishp.gratishp_resume_text_first}</p>
                        <p>{content.gratishp.gratishp_resume_text_second}</p>

                        <ListGroup className="mb-3">
                          <ListGroupItem><strong>{content.gratishp.gratishp_center_name_label + ' '}</strong><span>{userInfo.schoolName}</span></ListGroupItem>
                          <ListGroupItem><strong>{content.gratishp.gratishp_send_school_address}</strong> {this.state.direction ? this.state.direction : ''}</ListGroupItem>
                        </ListGroup>

                        {this.state.order.hijos && this.state.order.hijos.map(hijo => (
                          <Fragment key={hijo.numero_hijo}>
                            <h6 className="mt-3" >{`${content.gratishp.gratishp_modal_title_label} ${hijo.t_nombre} ${hijo.t_apellido1} ${hijo.t_apellido2}`}</h6>
                            <ListGroup className="summary__Modal__noBorder" flush>
                              {hijo.componentes && hijo.componentes.map(componente =>
                                <ListGroupItem
                                  key={componente.isbn}
                                  disabled
                                  tag="a"
                                  href="#"
                                >
                                  {/*
                                  <span>{componente.titulo},</span>
                                  <span> <i>{content.gratishp.gratishp_modal_coste_label} {componente.coste}</i></span>
                                  */}
                                  {/* MBQ TODO Show isbn */}
                                  {/* <span>{componente.titulo}</span> */}
                                  <span>{componente.titulo} - <strong className='dark'>ISBN {componente.isbn}</strong></span>
                                  {componente.stock === 'N' && <span><i>{` (${content.gratishp.nonstock_label})`}</i></span>}                                  
                                </ListGroupItem>
                              )}
                            </ListGroup>
                          </Fragment>

                        ))}

                        {this.state.order.hijos &&

                          <Row className="my-3 text-right justify-content-end">
                            <Col xs="auto">
                              {/*
                              <ListGroup>
                                <ListGroupItem><label>{content.gratishp.gratishp_modal_coste_sin_iva_label + ': ' + (Math.round(this.state.order.hijos.reduce((total, hijo) => {
                                  if (hijo.componentes) {
                                    total += hijo.componentes.reduce((subtotal, componente) => {
                                      return subtotal += componente.coste
                                    }, 0)
                                  }
                                  return total
                                }, 0) * 100) / 100).toFixed(2)}€</label></ListGroupItem>
                                <ListGroupItem><label>{content.gratishp.gratishp_modal_iva_label + ': ' + (Math.round(this.state.order.hijos.reduce((total, hijo) => {
                                  if (hijo.componentes) {
                                    total += hijo.componentes.reduce((subtotal, componente) => {
                                      return subtotal += componente.coste
                                    }, 0)
                                  }
                                  return total
                                }, 0) * (this.state.order.iva / 100) * 100) / 100).toFixed(2)}€
                                                     </label></ListGroupItem>
                                <ListGroupItem className="summary__Modal__total" ><label>{content.gratishp.gratishp_modal_coste_total_label + ': ' + (Math.round(this.state.order.hijos.reduce((total, hijo) => {
                                  if (hijo.componentes) {
                                    total += hijo.componentes.reduce((subtotal, componente) => {
                                      return subtotal += componente.coste
                                    }, 0)
                                  }
                                  return total
                                }, 0) * (1 + this.state.order.iva / 100) * 100) / 100).toFixed(2)}€
                                                     </label></ListGroupItem>

                              </ListGroup>
                              */}
                            <ListGroup>
                              <ListGroupItem>
                                <label>{`${content.gratishp.gratishp_modal_coste_sin_iva_label}: ${this.state.order.cost.toFixed(2)}€`}</label>
                              </ListGroupItem>
                              <ListGroupItem>
                                <label>{`${content.gratishp.gratishp_modal_iva_label}: ${this.state.order.vatCost.toFixed(2)}€`}</label>
                              </ListGroupItem>
                              <ListGroupItem className="summary__Modal__total">
                                <label>{`${content.gratishp.gratishp_modal_coste_total_label}: ${this.state.order.totalCost.toFixed(2)}€`}</label>
                              </ListGroupItem>
                            </ListGroup>
                          </Col>
                        </Row>

                        }
                        {/*
                        <Modal
                          isOpen={this.state.pasarelaDePago ? this.state.pasarelaDePago : false}
                          className="summary__pay"
                        >
                          {!this.state.pagando && (<h5>{content.gratishp.gratishp_modal_pay_label}</h5>)}
                          {this.state.pagando && (<Progress color="success" value={this.state.progress}>{this.state.progresstext}</Progress>)}

                          <div className="d-flex justify-content-between mt-2" >
                            <Button className="summary__Modal__decline" onClick={this.tooglePasarela} disabled={this.state.pagando}>
                              {content.gratishp.gratishp_modal_cancel_pay_button_label}
                            </Button>
                            <Button className="summary__Modal__accept" onClick={(e) => this.tooglePasarela(e, 'pagar',content)} disabled={this.state.pagando}>
                              {content.gratishp.gratishp_modal_pay_button_label}
                            </Button>
                          </div>

                        </Modal>
                        */}
                        <div className="d-flex justify-content-between" >
                          <Button className="summary__Modal__decline" onClick={this.toggle} disabled={this.state.pagando}>
                            {content.gratishp.gratishp_modal_cancel_send_button_label}
                          </Button>
                          <Button className="summary__Modal__accept" onClick={(e) => this.toggle(e,'enviar',content)} disabled={this.state.pagando}>
                            {content.gratishp.gratishp_modal_send_button_label}
                          </Button>
                        </div>

                      </Modal>

                      {this.state.condiciones_servicio && (
                        <Container className="hp__form my-5">
                          <h4 className="mb-3">{content.gratishp.gratishp_conditions_service_reminder}</h4>
                          <h3 className="text-left mb-3">{content.gratishp.gratishp_professional_data}</h3>
                          {/* <p><strong>{content.gratishp.gratishp_center_name_label + ' '}</strong><span>{userInfo.schoolName}</span></p> */}
                          <p><strong className="dark">{`${content.gratishp.gratishp_center_name_label} ${userInfo.schoolName}`}</strong></p>
                          <p>{content.gratishp.gratishp_receptor_center_confirmation_label}</p>

                          <Form onSubmit={(e) => this.handleSubmitSendOrder(e, content)}>
                            {/*                            
                            <Row className="align-items-end border-bottom" form>
                              <Col md={6}>
                                <FormGroup>
                                <Label>{`${content.gratishp.gratishp_n_profesores_departamento_label}: *`}</Label>
                                  <Input
                                    invalid={error.n_profesores_departamento ? true : false}
                                    name="n_profesores_departamento"
                                    value={this.state.order.n_profesores_departamento}
                                    type="text"
                                    onChange={this.handleChangeInputNumberTeachers}
                                  />
                                  <FormFeedback>{content.gratishp.gratishp_n_profesores_departamento_validation_label}</FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label>{`${content.gratishp.gratishp_decisions_label} *`}</Label>
                                  <Select
                                    invalid={error.id_decision_adopcion}
                                    options={this.state.decisions}
                                    order={this.state.order}
                                    handleChange={this.handleChangeInput} />
                                </FormGroup>
                              </Col>
                            </Row>
                            */}

                            {error.hijosEmpty &&
                              <Alert className="my-2 hp__form__alert" color="danger">{content.gratishp.gratishp_algun_hijo_relleno_validation_label}</Alert>
                            }

                            {this.state.hasSonsErrors &&
                              <Alert className="my-2 hp__form__alert" color="danger">{content.gratishp.gratishp_pestana_son_validation}</Alert>
                            }

                            {/* MBQ Do not validate repeated children */}
                            {/*error.repeatSon &&
                              <Alert className="my-2 hp__form__alert" color="danger">
                                {content.gratishp.gratishp_nombre_hijo_repetido_validation}
                              </Alert>
                            */}

                            <h3 className="text-left my-3">{content.gratishp.gratishp_son_data_info}</h3>
                            {/*parseToReactElement(content.gratishp.gratishp_son_data_info_description)*/}
                            {(this.state.order.hijos && this.state.order.hijos.length > 0 && this.state.order.hijos.some(child => child.id)) &&
                              (parseToReactElement(content.gratishp.gratishp_son_data_info_description))}

                            <Row className="align-items-end" form>
                              <Col md={12}>
                                {/* MBQ FIX Delete child & Activate tab */}
                                {/*
                                <Sons addSon={this.addSon}
                                  hijos={this.state.order.hijos}
                                  deleteSon={this.deleteSon}
                                  handleChange={this.handleChange}
                                  listas={this.state.listas}
                                  addTitle={this.addTitle}
                                  deleteTitle={this.deleteTitle}
                                  handleFileUpload={this.handleFileUpload}
                                  defaultImage={this.defaultImage}
                                  error={error}
                                  zipCodeError={this.state.zipCodeError}
                                  componentesError={this.state.componentesError}
                                  levelError={this.state.levelError}
                                  activeChild={this.state.activeChild}
                                  wrongZipCode={this.state.wrongZipCode}
                                  libroFamiliaError={this.state.libroFamiliaError}
                                  searchFlags={this.state.searchFlags}
                                  noAdoptionsError={this.state.noAdoptionsError}
                                />
                                */}
                                <Sons
                                  hijos={this.state.order.hijos}
                                  activeChild={this.state.activeChild}
                                  listas={this.state.listas}
                                  actionTab={this.onClickTab}
                                  addSon={this.addSon}
                                  handleChange={this.handleChange}
                                  handleFileUpload={this.handleFileUpload}
                                  addTitle={this.addTitle}
                                  deleteTitle={this.deleteTitle}
                                  defaultImage={this.defaultImage}
                                  searchFlags={this.state.searchFlags}
                                  error={error}
                                  zipCodeError={this.state.zipCodeError}
                                  componentesError={this.state.componentesError}
                                  levelError={this.state.levelError}
                                  wrongZipCode={this.state.wrongZipCode}
                                  libroFamiliaError={this.state.libroFamiliaError}
                                  noAdoptionsError={this.state.noAdoptionsError}
                                />
                              </Col>
                            </Row>
                            { parseToReactElement(`<p class="mt-3">${content.gratishp.gratishp_credentials_validation}</p>`, null, { ADD_ATTR: ['target'] }) }
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={this.state.legalAdvice}
                                  onChange={() => this.handleLegalAdvice()}
                                  invalid={error.legalAdvice ? true : false}
                                />
                                { parseToReactElement(`<span class="hp__modal__pointer mt-3">${content.gratishp.gratishp_legal_advice_validation}</span>`, null, { ADD_ATTR: ['target'] }) }
                                <span> *</span>
                                <FormFeedback>{content.gratishp.gratishp_legal_advice_validation_error}</FormFeedback>

                              </Label>

                            </FormGroup>
                            <p className="mt-3">{content.gratishp.gratishp_review_advice}</p>

                            <div className="mt-5 d-flex justify-content-center">
                              <Button className="hp__form__accept" type="submit" >{content.gratishp.gratishp_enviar_pedido_label}</Button>
                            </div>

                            {error.hijosEmpty &&
                              <Alert className="my-2 hp__form__alert" color="danger">{content.gratishp.gratishp_algun_hijo_relleno_validation_label}</Alert>
                            }

                            {error.legalAdvice &&
                              <Alert className="my-2 hp__form__alert" color="danger">{content.gratishp.gratishp_legal_advice_validation_error}</Alert>
                            }

                            {this.state.hasSonsErrors &&
                              <Alert className="my-2 hp__form__alert" color="danger">{content.gratishp.gratishp_pestana_son_validation}</Alert>
                            }

                            {/*                            
                            { (error.n_profesores_departamento || error.id_decision_adopcion) && // TODO: separar en dos avisos distintos
                              <Alert className="my-2 hp__form__alert" color="danger">{content.gratishp.gratishp_choose_decision_label_validation}</Alert>
                            }
                            */}

                            {/* MBQ Do not validate repeated children */}
                            {/*error.repeatSon &&
                              <Alert className="my-2 hp__form__alert" color="danger">
                                {content.gratishp.gratishp_nombre_hijo_repetido_validation}
                              </Alert>
                            */}
                          </Form>
                        </Container>
                      )}
                    </Fragment>
                  )
                  )
                }}
              </ContentConsumer>
            )
          }}
        </AuthConsumer>
      </div>
    )
  }
}
export default GratisHP