import axios from 'axios';
import axiosConfig from '../config/axios';

const checkPremiumCode = (code, token, cb) => {
    getSchoolData(code, token, (err, data) => {
        if (err)
            cb(err)
        else
            cb(null, data)
    })

};

function getSchoolData(premium_code, token, cb) {
    const axiosInstance = axios.create(axiosConfig);
    axiosInstance.get(`/school/${premium_code}?token=${token}`)
        .then((response) => {
            cb(null, response.data)
        })
        .catch((error) => {
            cb({})
        })
}

export default checkPremiumCode;
