import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import _ from 'lodash'
import axios from 'axios'
import validator from 'validator'
import { Container, Row, Col, Form, FormGroup, FormFeedback, Label, Input } from 'reactstrap'
import qs from 'qs'

import { regexPassword } from '../../utils/formValidator'
import { parseToReactElement } from '../../utils/html'
import axiosConfig from '../../config/axios'
import { withContent } from '../../utils/hocs'
import { Help } from '../Links/Links'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import { RecaptchaTerms } from '../Recaptcha/Recaptcha'

const axiosClient = axios.create(Object.assign({}, axiosConfig))

class PasswordReset extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password1: '',
      password2: '',
      success: false,
      failure: false,
      loading: false,
      errors: {
        password1: false,
        password2: false
      }
    }
  }

  componentDidMount() {
    const { hash } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if(!hash) this.props.history.push('/error/')
  }

  gotoHome = () => {
    this.props.history.push("/")
  }

  isValid = () => !_.compact(_.values(this.state.errors)).length && (this.state.password1 !== '') && (this.state.password2 !== '')

  submit = (event) => {
    event.preventDefault()
    if(this.isValid()) {
      this.setState({ loading: true }, async () => {
        const token = await this.props.googleReCaptchaProps.executeRecaptcha('password')
        const { hash } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        axiosClient.post('/password/reset',
          { password: this.state.password1, hash, token },
          { headers: { 'accept-language': this.props.locale } }
        )
          .then((response) => this.setState({ success : true, loading: false }))
          .catch((error) => this.setState({ failure : true, loading: false }))
      })
    }
  }

  handleInputChange = (event) => {
    const { name, value } = event.target

    switch(name) {
      case "password1":
        this.setState((prevState) => {
          return {
            [name]: value,
            errors: {
              ...prevState.errors,
              [name]: !validator.matches(value, regexPassword),
              password2: !!prevState.password2 && (prevState.password2 !== value)
            }
          }
        })
        break
      case "password2":
        this.setState((prevState) => {
          return {
            [name]: value,
            errors: {
              ...prevState.errors,
              [name]: (this.state.password1 !== value) // || !validator.matches(value, regexPassword)
            }
          }
        })
        break
      default:
        this.setState({ [name]: value })
    }
  }

  render() {
    const { content } = this.props
    if(this.state.success) {
      return (
        <Container fluid={false} className="PasswordReset">
          <Row>
            <Col><h1>{ content.useraccount.password_reset_success_title }</h1></Col>
          </Row>
          <Row className="mt-3">
            <Col md={{size:8, offset:2}}><p className="text-center">{ content.useraccount.password_reset_success_body }</p></Col>
          </Row>
          <Row>
            <Col md={{ size: 4, offset: 4}}>
              <ButtonPremium onClick={ () => this.props.history.push("/") } primary>{ content.useraccount.goto_home }</ButtonPremium>
            </Col>
          </Row>
        </Container>
      )
    } else if(this.state.failure) {
      return (
        <Container fluid={false} className="PasswordReset">
          <Row>
            <Col><h1>{ content.useraccount.password_reset_title }</h1></Col>
          </Row>
          <Row className="mt-3">
            <Col md={{size:8, offset:2}}><p className="text-center">{ parseToReactElement(content.useraccount.password_reset_link_invalid) }</p></Col>
          </Row>
          <Row>
            <Col md={{ size: 2, offset: 5}}>
              <ButtonPremium onClick={ () => this.props.history.push("/") } primary>{ content.sharedTerms.ACCEPT_BUTTON_TEXT }</ButtonPremium>
            </Col>
          </Row>
        </Container>
      )
    } else {
      return (
        <Container fluid={false} className="PasswordReset">
          <Row>
            <Col><h1>{ content.useraccount.password_reset_title }</h1></Col>
          </Row>
          <Row className="mt-3">
            <Col md={{size:6, offset:3}}><p>{ content.useraccount.password_reset_body }</p></Col>
          </Row>
          <Row>
            <Col md={{ size:6, offset:3 }}>
              <Form noValidate>
                <FormGroup>
                  <Label for="password1">{content.useraccount.password}</Label>
                  <Input
                    id="password1"
                    type="password"
                    name="password1"
                    autoComplete="off"
                    value={this.state.password1}
                    onChange={this.handleInputChange}
                    valid={!this.state.errors.password1 && this.state.password1 !== ''}
                    invalid={this.state.errors.password1}
                  />
                  <FormFeedback>{ content.useraccount.password_invalid }</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="password2">{content.useraccount.password2}</Label>
                  <Input
                    id="password2"
                    type="password"
                    name="password2"
                    autoComplete="off"
                    value={ this.state.password2 }
                    onChange={ this.handleInputChange }
                    valid={ !this.state.errors.password2 && this.state.password2 !==''  }
                    invalid={ this.state.errors.password2 }
                  />
                  <FormFeedback>{ content.useraccount.password_confirmation_invalid }</FormFeedback>
                </FormGroup>
              </Form>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={{size:6, offset:3}}><p className="text-center"><Help topic="olvidado" /></p></Col>
          </Row>

          <Row className="mt-3">
            <Col md={{size:6, offset:3}}>
              <Container>
                <Row>
                  <Col md={{size: 4, offset: 1}}>
                    <ButtonPremium onClick={this.gotoHome}>
                      {content.sharedTerms.CANCEL_BUTTON_TEXT}
                    </ButtonPremium>
                  </Col>
                  <Col md={{size: 4, offset: 2}}>
                    <ButtonPremium onClick={this.submit} disabled={!this.isValid()} primary>
                      { content.sharedTerms.ACCEPT_BUTTON_TEXT }
                    </ButtonPremium>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={{size:8, offset:2}}><p className="text-center"><RecaptchaTerms /></p></Col>
          </Row>
        </Container>
      )
    }
  }
}

export default withRouter(withGoogleReCaptcha(withContent(PasswordReset)))
