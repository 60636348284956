import React from 'react'
import { AuthConsumer } from 'react-check-auth'
import { ContentConsumer } from '../../language'
import { Button } from 'reactstrap'
import { withRouter } from 'react-router-dom'

const handleAccept = async (e, history, refreshAuth) => {
  e.preventDefault()
  await refreshAuth()
  history.push('/edit-library')
}

const ChangeSchoolSuccess = props => (
  <AuthConsumer>
    {({ refreshAuth }) => (
      <ContentConsumer>
        {({ content }) => (
          content &&
          <div className="text-center d-flex flex-column justify-content-center m-5 changeSchoolSuccess">
            <h2 className="m-3">{content.changeschool.changeschool_title}</h2>
            <p>{content.changeschool.changeschool_heading_success_text}</p>
            <p>{content.changeschool.changeschool_heading_login_text}</p>
            <div className="d-flex justify-content-center">
              <Button type="submit" onClick={e => handleAccept(e, props.history, refreshAuth)} className="mr-sm-2 changeSchoolSuccess__button">
                {content.sharedTerms.ACCEPT_BUTTON_TEXT}
              </Button>
            </div>
          </div>
        )}
      </ContentConsumer>

    )}
  </AuthConsumer>
)

export default withRouter(ChangeSchoolSuccess)