import React, { Component } from "react"
import { Container, Row, Col, Progress } from 'reactstrap'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import { ContentConsumer } from '../../language'
import { REGISTRATION_ACTION } from './utils'
import axios from 'axios'
import axiosConfig from '../../config/axios'

const axiosApi = axios.create(axiosConfig)

class PDCurrentRegistration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      success: false
    }
  }

  cancelRegistration = () => (
    this.setState({ loading: true }, () => (
      axiosApi.post('/pd-courses/registration',
        {
          action: REGISTRATION_ACTION.DELETE,
          courseId: this.props.registration.courseId,
          master: this.props.registration.lmsMasterCourseId
        }).then(() => {
          this.setState({ success: true, loading: false })
        }).catch(error => {
          console.error('PD-COURSES : ', error.toString())
          this.props.showError()
        })
    ))
  )

  render() {
    const { loading, success } = this.state
    const { registration, cancel, closeModal, reopenRegistration } = this.props
    return (
      <ContentConsumer>
        {({ content = {} }) => (
          content && (
            loading ?
              (
                <Progress animated color="primary" value="100">{content.pdcourses.loading}</Progress>
              )
              :
              (
                success ?
                  (
                    <Container fluid={true} className="pdCurrentRegistration pl-md-5 pr-md-5">
                      <Row>
                        <Col className="text-center pt-5">
                          <h6>{content.pdcourses.current_register_cancel_ok_title}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center pt-2 pb-5 pdCurrentRegistration__course">
                          <h4>{registration.courseTitle}</h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center pt-5">
                          <h6>{content.pdcourses.current_register_cancel_ok_register}</h6>
                          <h6>{content.pdcourses.current_register_cancel_ok_mail}</h6>
                        </Col>
                      </Row>
                      <Row className="pt-5 pb-5">
                        <Col md={{ size: 4, offset: 4 }} className="text-center">
                          <ButtonPremium onClick={cancel === true ? closeModal : reopenRegistration} primary>
                            {content.pdcourses.button_accept}
                          </ButtonPremium>
                        </Col>
                      </Row>
                    </Container>
                  )
                  :
                  (
                    <Container fluid={true} className="pdCurrentRegistration pl-md-5 pr-md-5">
                      <Row>
                        <Col className="text-center pt-5">
                          <h6>{content.pdcourses.current_register_title}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center pt-2 pb-5 pdCurrentRegistration__course">
                          <h4>{registration.courseTitle}</h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center pt-5">
                          <h6>{content.pdcourses.current_register_onecourse}</h6>
                          <h6>{content.pdcourses.current_register_cancel}</h6>
                        </Col>
                      </Row>
                      <Row className="mt-5 mb-5">
                        <Col md="6" lg={{ size: 4, offset: 1 }} className="my-2">
                          <ButtonPremium onClick={closeModal}>{content.pdcourses.button_goback}</ButtonPremium>
                        </Col>
                        <Col md="6" lg={{ size: 4, offset: 1 }} className="my-2">
                          <ButtonPremium
                            onClick={this.cancelRegistration}
                            primary
                          >
                            {content.pdcourses.button_accept}
                          </ButtonPremium>
                        </Col>
                      </Row>
                    </Container>
                  )
              )
          ))}
      </ContentConsumer>
    )
  }
}

export default PDCurrentRegistration