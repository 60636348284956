import React from 'react'
import { Card, CardBody, Button } from 'reactstrap'
import envSettings from '../../../globals/envSettings'
import { withContent } from '../../../utils/hocs'

const Product = (props) => {
  const { content, data, token, open } = props

  return (
    <div className="position-relative">
      <Card className="mb-4">
        <div className="products__card position-relative w-100 h-100">
          <img
            className="products__card__inside__thumbnail w-100"
            alt={data.title}
            src={`${envSettings.API_BASEURL}/cover/${data.uuid}`}
          />
          <div className={`d-flex flex-column p-2 justify-content-center products__card__inside__mask position-absolute w-100 h-100`}>
            <Button className="my-1 text-uppercase w-100" color="secondary" onClick={e => open(e, `${envSettings.API_BASEURL}/student/${token}/products/${data.uuid}/resources/${data.resourceUuid}`)}>            
              <span>{content.titledetail.open_title}</span>
              <span className="ml-1 glyphicon glyphicon-check"></span>
            </Button>
          </div>
        </div>

        <CardBody className="p-0" >
          <div className="products__card__inside__legend w-100 position-realtive" >
            <div className="py-1 px-2" >{data.title}</div>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default withContent(Product)