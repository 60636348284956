import React, { Component, Fragment } from 'react'
import { Row, Col, FormGroup, Label, Input } from 'reactstrap'
import PropTypes from 'prop-types'

class StudentsZoneUpdate extends Component {
  componentWillUnmount() {
    this.props.handleRefresh()
  }

  render() {
    const { increasement_percentage, different_devices, onChange, updateErrors } = this.props
    return (
      <Fragment>
        <Row>
          <Col xs="6" sm="6" md="6" lg="6"> 
            <FormGroup>                              
              <Label for="increasement_percentage">% Increase</Label>
                <Input
                  id="increasement_percentage"
                  type="number"
                  name="increasement_percentage"
                  placeholder=""
                  autoComplete="off"
                  value={increasement_percentage}
                  onChange={onChange}
                  valid={!updateErrors.increasement_percentage && increasement_percentage !== ''}
                  invalid={updateErrors.increasement_percentage}/>
            </FormGroup>
          </Col>
          <Col xs="6" sm="6" md="6" lg="6">
            <FormGroup> 
              <Label for="different_devices">Devices</Label>
                <Input
                  id="different_devices"
                  type="number"
                  name="different_devices"
                  placeholder=""
                  autoComplete="off"
                  value={different_devices}
                  onChange={onChange}
                  valid={!updateErrors.different_devices && different_devices !== ''}
                  invalid={updateErrors.different_devices}/>
            </FormGroup>
          </Col>
        </Row>

        {updateErrors.withoutChanges && <Row><Col className="text-center">No changes have been made</Col></Row>}
      </Fragment>                        
    )
  }
}

StudentsZoneUpdate.propTypes = {
  increasement_percentage: PropTypes.number.isRequired,
  different_devices: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
}
  
export default StudentsZoneUpdate