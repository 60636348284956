import userRoles from '../../globals/userRoles'
import envSettings from '../../globals/envSettings'
import _ from 'lodash'
import validator from 'validator'

const utils = {
  isAuthorized: user => (
    user.role === userRoles.TEACHER && user.countryId === envSettings.COUNTRY_ESP_ID && user.postLogin // && user.ccaaWithPromotions
  ),
  sanitizeString: (value, blacklist) => (
    validator.blacklist(value.trim(), blacklist)
  ),
  getPostTitles: titlles => (
    titlles.split(':')[1].split(',').map(title => parseInt(title))
  ),
  getPromotionFromPost: (post, titlesPm) => (
    {
      id: post.id,
      title: post.title.rendered,
      content: post.content.rendered,
      image: {
        alt: post._embedded['wp:featuredmedia'][0].alt_text || post._embedded['wp:featuredmedia'][0].title.rendered,
        url: {
          full: post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
          medium: post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url,
          thumbnail: post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url
        }
      },
      pm: _.sortedUniq(_.sortBy(titlesPm))
    }
  ),
  getPromotionsId: promotions => {
    const promotionsId = []
    promotions.forEach(promotion => promotionsId.push(promotion.id))
    return _.sortBy(promotionsId)
  },
  getPromotionsTitlesPm: promotions => (
    _.sortBy(promotions.reduce((acc, promotion) => _.union(acc, promotion.pm), []))
  )
}

export default utils