import React from 'react'
import { Jumbotron, Container, Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'

const TitleBanner = props => (
  <Jumbotron fluid className={`jumbotron__banner ${props.className}`}>
    <Container>
      <Row>
        {props.image && (
          <Col sm='3' className='d-none d-md-block align-self-center'>{props.image}</Col>
        )}
        <Col className='align-self-center'>
          <Container>
            {props.title && <Row><Col>{props.title}</Col></Row>}
            <Row><Col>{props.content}</Col></Row>
          </Container>
        </Col>
        {props.mainButton && <Col className='col-12 col-md-3 col-lg-2 align-self-end'>{props.mainButton}</Col>}
      </Row>
      {props.closeButton && <Row><Col>{props.closeButton}</Col></Row>}
    </Container>
  </Jumbotron>
)

TitleBanner.propTypes = {
  content: PropTypes.element.isRequired,
  title: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  image: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  mainButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  closeButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  className: PropTypes.string,
}

TitleBanner.defaultProps = {
  title: false,
  image: false,
  mainButton: false,
  closeButton: false,
  className: ''
}

export default TitleBanner