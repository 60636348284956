import React, { Component, Fragment, } from 'react'
import { withUserInfo } from '../../../utils/hocs'
import { withRouter } from 'react-router-dom'
import validator from 'validator'
import _ from 'lodash'
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ROLES from '../../../globals/userRoles'
import ModalAccept from '../../ModalAccept/ModalAccept';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { characterLength, emailRegex, passwordRegex, getStatusMessage, formatDate } from "../../../utils/format";
import UserDetail from "../FormComponent";
import { isValid } from "better-dni";
import { getCustomerSupportDataById, getCustomerSupportDataActions, putCustomerSupportDataById } from "../../../services/administrator/request";

class StudentEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            surname1: '',
            surname2: '',
            email: '',
            password: '',
            confirmPassword: '',
            latestActions: [],
            accountEdit: false,
            active: true,
            openModal: false,
            guardianDetails: false,
            gname: '',
            gsurname1: '',
            gsurname2: '',
            gemail: '',
            dni: '',
            date: formatDate(new Date(), 'DD/MM/YYYY'),
            isCalendarOpen: false,
            selectedDate: new Date(),
            errors: {
                name: false,
                surname1: false,
                surname2: false,
                email: false,
                password: false,
                confirmPassword: false,
                gname: false,
                gsurname1: false,
                gsurname2: false,
                gemail: false,
                dni: false
            },
            errorMsg: {
                name: false,
                email: false,
                password: false,
                gname: false,
                gemail: false,
                dni: false
            }
        };
        this.handleInputChange = this.handleInputChange.bind(this)
        this.create = this.create.bind(this)
        this.errorMsg = this.errorMsg.bind(this)
        this.toggle = this.toggle.bind(this)
        this.checkGuardian = this.checkGuardian.bind(this)
        this.onclickCalendar = this.onclickCalendar.bind(this)
        console.log("CreateOUPUser", props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.checkStudentAge(formatDate('2003-09-20', 'YYYY-MM-DD'), new Date());
        const { match: { params } } = this.props;
        Promise.all([getCustomerSupportDataById(params.userId), getCustomerSupportDataActions(params.userId)])
            .then(([result1, result2]) => {
                if (result1.status === 200 && result2.status === 200) {
                    this.setState({
                        'userData': result1.data,
                        'card': result1.data.card,
                        'ccaaId': result1.data.ccaaId,
                        'creationDate': result1.data.creationDate,
                        'downloadResources': result1.data.downloadResources,
                        'email': result1.data.email,
                        'guardianDetails': !_.isEmpty(result1.data.guardian),
                        'id': result1.data.id,
                        'language': result1.data.language,
                        'lastAccessDate': result1.data.lastAccessDate,
                        'mktPrefs': result1.data.mktPrefs,
                        'name': result1.data.name,
                        'password': result1.data.password,
                        'confirmPassword': result1.data.password,
                        'phone': result1.data.phone,
                        'role': result1.data.role,
                        'schoolId': result1.data.schoolId,
                        'schoolName': result1.data.schoolName,
                        'self-editable': result1.data['self-editable'],
                        'surname1': result1.data.surname1,
                        'surname2': result1.data.surname2,
                        'validatedEmail': result1.data.validatedEmail,
                        'latestActions': result2.data
                    });
                }
            });
        console.log("CreateOUPUser-componentDidMount", params);
    }

    handleStudentCalendar = date => {
        this.setState({
            selectedDate: date
        });
    };

    toggle() {
        const { type } = this.props;
        this.setState({ openModal: false }, () => {
            if (type === 'CREATE')
                this.props.back(true);
            else
                this.setState({ openModal: false }, () => this.props.history.push('/admin'));
        })
    }

    isValid = () => !_.compact(_.values(this.excludeProperties(this.state.errors))).length && this.state.name && this.state.surname1 &&
        this.state.email && this.state.password === this.state.confirmPassword && this.checkGuardian() && [ROLES.CUSTOMER_SUPPORT].find(role => role === _.get(this.props, 'userInfo.role', undefined))

    excludeProperties = ({ gname, gsurname1, gsurname2, gemail, dni, ...rest }) => rest

    checkGuardian() {
        if (this.state.guardianDetails) {
            if (!_.compact(_.values(this.state.errors)).length && this.state.gname !== '' && this.state.gsurname1 !== '' && this.state.gemail !== '' && this.state.dni !== '')
                return true;
            else
                return false;
        } else if (!this.state.guardianDetails) {
            return true;
        }
    }

    create() {
        const { name, surname1, surname2, email, password, confirmPassword, gname, gsurname1, gsurname2, gemail, dni, accountEdit, date, guardianDetails } = this.state;
        const { match: { params } } = this.props;

        let guardianData = {
            name: gname,
            surname1: gsurname1,
            surname2: gsurname2,
            email: gemail,
            dni
        };

        let data = {
            userId: params.userId,
            name,
            surname1,
            surname2,
            email,
            password,
            confirmPassword,
            date,
            guardian: guardianDetails ? guardianData : undefined,
            accountEdit
        };

        if (this.isValid()) {
            console.log('created');

            putCustomerSupportDataById(data)
                .then(result => {
                    this.setState({
                        popupMsg: getStatusMessage(result.status),
                        openModal: true,
                        errorMsg: {
                            name: false,
                            email: false,
                            password: false,
                            adoptions: false
                        }
                    })
                })
        }
        else {
            console.log(this.state.errors);
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        name: !validator.matches(name, characterLength),
                        surname1: !validator.matches(surname1, characterLength),
                        surname2: _.isEmpty(surname2) ? false : !validator.matches(surname2, characterLength),
                        email: !validator.matches(email, emailRegex),
                        password: _.isEmpty(password) ? false : !validator.matches(password, passwordRegex),
                        confirmPassword: _.isEmpty(confirmPassword) ? false : !validator.matches(confirmPassword, passwordRegex) || !(password === confirmPassword),
                        gname: !validator.matches(gname, characterLength),
                        gsurname1: !validator.matches(gsurname1, characterLength),
                        gsurname2: _.isEmpty(gsurname2) ? false : !validator.matches(gsurname2, characterLength),
                        gemail: !validator.matches(gemail, emailRegex),
                        dni: !isValid(dni)
                    }
                }
            }, () => this.errorMsg());
        }
    }

    errorMsg() {
        this.setState({
            errorMsg: {
                name: (this.state.errors.name || this.state.errors.surname1 || this.state.errors.surname2),
                email: (this.state.errors.email),
                password: this.state.errors.password || this.state.errors.confirmPassword || this.state.password !== this.state.confirmPassword,
                gname: this.state.guardianDetails ? (this.state.errors.gname || this.state.errors.gsurname1 || this.state.errors.gsurname2) : false,
                gemail: this.state.guardianDetails ? (this.state.errors.gemail) : false,
                dni: this.state.guardianDetails ? this.state.errors.dni : false
            }
        });
    }

    handleInputChange = ({ target: { name, value } }) => {

        switch (name) {
            case "name":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !validator.matches(value, characterLength)
                        }
                    }
                })
                break
            case "surname1":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !validator.matches(value, characterLength)
                        }
                    }
                })
                break
            case "surname2":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: _.isEmpty(value) ? false : !validator.matches(value, characterLength)
                        }
                    }
                })
                break
            case "email":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !validator.matches(value, emailRegex)
                        }
                    }
                })
                break
            case "password":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: _.isEmpty(value) ? false : !validator.matches(value, passwordRegex)
                        }
                    }
                })
                break
            case "confirmPassword":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: (this.state.password === value) ? false : !validator.matches(value, passwordRegex)
                        }
                    }
                })
                break
            case "gname":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !validator.matches(value, characterLength)
                        }
                    }
                })
                break
            case "gsurname1":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !validator.matches(value, characterLength)
                        }
                    }
                })
                break
            case "gsurname2":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: _.isEmpty(value) ? false : !validator.matches(value, characterLength)
                        }
                    }
                })
                break
            case "gemail":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !validator.matches(value, emailRegex)
                        }
                    }
                })
                break
            case "dni":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !isValid(value)
                        }
                    }
                })
                break
            case "date":
                this.setState({ [name]: value }, () => this.checkStudentAge(this.state.date, new Date()))
                break
            default:
                this.setState({ [name]: value })
        }
    }

    DaysInMonth(date2_UTC) {
        var monthStart = new Date(date2_UTC.getFullYear(), date2_UTC.getMonth(), 1);
        var monthEnd = new Date(date2_UTC.getFullYear(), date2_UTC.getMonth() + 1, 1);
        var monthLength = (monthEnd - monthStart) / (1000 * 60 * 60 * 24);
        return monthLength;
    }

    getStudentAge(date_1, date_2) {
        //convert to UTC
        var date2_UTC = new Date(Date.UTC(date_2.getUTCFullYear(), date_2.getUTCMonth(), date_2.getUTCDate()));
        var date1_UTC = new Date(Date.UTC(date_1.getUTCFullYear(), date_1.getUTCMonth(), date_1.getUTCDate()));

        //--------------------------------------------------------------
        var days = date2_UTC.getDate() - date1_UTC.getDate();
        if (days < 0) {

            date2_UTC.setMonth(date2_UTC.getMonth() - 1);
            days += this.DaysInMonth(date2_UTC);
        }
        //--------------------------------------------------------------
        var months = date2_UTC.getMonth() - date1_UTC.getMonth();
        if (months < 0) {
            date2_UTC.setFullYear(date2_UTC.getFullYear() - 1);
            months += 12;
        }
        //--------------------------------------------------------------
        var years = date2_UTC.getFullYear() - date1_UTC.getFullYear();

        return Number(years);
    }

    checkStudentAge(date_1, date_2) {
        this.setState({
            guardianDetails: this.getStudentAge(new Date(date_1), date_2) < 16,
        }, () => {
            if (!this.state.guardianDetails)
                this.setState(prevState => {
                    return {
                        ...prevState,
                        errors: {
                            ...prevState.errors,
                            gname: false,
                            gsurname1: false,
                            gsurname2: false,
                            gemail: false,
                            dni: false
                        },
                        errorMsg: {
                            ...prevState.errorMsg,
                            gname: false,
                            gemail: false,
                            dni: false
                        }
                    }
                }, () => this.errorMsg());
            else
                this.setState(prevState => {
                    return {
                        ...prevState,
                        gname: '',
                        gsurname1: '',
                        gsurname2: '',
                        gemail: '',
                        dni: '',
                        errors: {
                            ...prevState.errors,
                            gname: false,
                            gsurname1: false,
                            gsurname2: false,
                            gemail: false,
                            dni: false
                        },
                        errorMsg: {
                            ...prevState.errorMsg,
                            gname: false,
                            gemail: false,
                            dni: false
                        }
                    }
                }, () => this.errorMsg());
        });
    }

    onclickCalendar(e) {
        // this.date.focus();
        this.setState(prevState => {
            return {
                isCalendarOpen: !prevState.isCalendarOpen
            }
        });
    }

    onChangeDate(e) {
        this.setState({
            date: formatDate(e, "DD/MM/YYYY"),
            selectedDate: e
        }, () => this.checkStudentAge(e, new Date()));
    }

    render() {
        const { type, history, match: { params: { userId } } } = this.props;
        const { name, surname1, surname2 } = this.state;
        //const [selectedDate, setselectedDate] = useState(new Date());
        // const ExampleCustomInput = ({ value, onClick }) => (
        //     <button className="example-custom-input" onClick={onClick}>
        //       {value}
        //     </button>
        //   );

        return (
            <Fragment>
                <Container>
                    <div className="student-edit">
                        <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray">
                            <Col xs="6" md="8"><h3 className="text-primary font-weight-bold mb-0">{name} {surname1} {surname2}</h3></Col>
                        </Row>
                        <Row className='pt-2 pb-2 ml-1 mr-1'>
                            <Col xs="6" md="8"><h5 className="font-weight-bold mb-0">STUDENT</h5></Col>
                        </Row>
                        <Row className="pt-2 pb-2 ml-1 mr-1 mb-2 mt-2">
                            <Col xs="1" sm="1" md="1"><div className="font-weight-bold mb-0">ID </div></Col>
                            <Col xs="11" sm="3" md="3"><div className="font-weight-normal mb-0">{userId}</div></Col>
                            <Col xs="4" sm="2" md="2" className="date"><div className="font-weight-bold text-wrap mb-0">Date of birth</div></Col>
                            <Col xs="6" sm="4" md="3" className="date height">
                                {/* <Input type="date" name="date" innerRef={el => this.date = el} value={this.state.date} onChange={this.handleInputChange} /> */}

                                <DatePicker name="date"
                                    selected={this.state.selectedDate}
                                    onInputClick={this.onclickCalendar}
                                    value={this.state.date}
                                    onChange={(e) => this.onChangeDate(e)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    dateFormat="dd/MM/yyyy"
                                    open={this.state.isCalendarOpen}
                                    onClickOutside={this.onclickCalendar}
                                    onSelect={this.onclickCalendar}
                                    maxDate={new Date()}
                                />
                                {/* <Button className="font-weight-normal" color="secondary" size="md">
                                    <span className="glyphicon glyphicon-calendar" aria-hidden="true"></span>
                                </Button> */}
                            </Col>
                            <Col xs="2" sm="1" md="2" className="date height">
                                <Button className="font-weight-normal" color="secondary" size="md" onClick={this.onclickCalendar}>
                                    <span className="glyphicon glyphicon-calendar" aria-hidden="true"></span>
                                </Button>
                            </Col>
                        </Row>
                        <Row className='pt-2 pb-2 ml-1 mr-1'>
                            <Col xs="6" md="8"><div className="font-weight-bold mb-0">Student Details</div></Col>
                        </Row>
                        <Form>
                            <UserDetail type='names' {...this.state} handleInputChange={this.handleInputChange} />
                            <Row className="pt-2 pb-2 ml-1 mr-1">
                                <UserDetail type='email' size='4' {...this.state} handleInputChange={this.handleInputChange} />
                                <UserDetail type='password' passwordEdit={this.props.type} {...this.state} handleInputChange={this.handleInputChange} />
                            </Row>
                            {this.state.guardianDetails && <React.Fragment>
                                <Row className='pt-2 pb-2 ml-1 mr-1'>
                                    <Col xs="12" md="12"><div className="font-weight-bold mb-0">Father/Mother/Legal guardian details</div></Col>
                                </Row>
                                <Row className="pt-2 pb-2 ml-1 mr-1">
                                    <Col xs="12" sm="6" md="6" lg="4">
                                        <FormGroup className="form-group">
                                            <Label className="font-weight-bold" for="name">Name *</Label>
                                            <Input type="text" size="100" name="gname" id="gname" placeholder=""
                                                value={this.state.gname}
                                                onChange={this.handleInputChange}
                                                valid={!this.state.errors.gname && this.state.gname !== ''}
                                                invalid={this.state.errors.gname} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="6" md="6" lg="4">
                                        <FormGroup className="form-group">
                                            <Label className="font-weight-bold" for="firstSurname">First Surname *</Label>
                                            <Input type="text" size="100" name="gsurname1" id="gsurname1" placeholder=""
                                                value={this.state.gsurname1}
                                                onChange={this.handleInputChange}
                                                valid={!this.state.errors.gsurname1 && this.state.gsurname1 !== ''}
                                                invalid={this.state.errors.gsurname1} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="6" md="6" lg="4">
                                        <FormGroup className="form-group">
                                            <Label className="font-weight-bold" for="secondSurname">Second Surname</Label>
                                            <Input type="text" size="100" name="gsurname2" id="gsecondSurname" placeholder=""
                                                value={this.state.gsurname2}
                                                onChange={this.handleInputChange}
                                                valid={!this.state.errors.gsurname2 && this.state.gsurname2 !== ''}
                                                invalid={this.state.errors.gsurname2} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="pt-2 pb-2 ml-1 mr-1">
                                    <Col xs="12" sm="6" md="6" lg="4">
                                        <FormGroup className="form-group">
                                            <Label className="font-weight-bold" for="emailAddress">Email Address *</Label>
                                            <Input type="email" size="100" name="gemail" id="gEmailAddress" placeholder=""
                                                value={this.state.gemail}
                                                onChange={this.handleInputChange}
                                                valid={!this.state.errors.gemail && this.state.gemail !== ''}
                                                invalid={this.state.errors.gemail} />
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="6" md="6" lg="4">
                                        <FormGroup className="form-group">
                                            <Label className="font-weight-bold" for="DNI/NIE">DNI / NIE *</Label>
                                            <Input type="text" size="100" name="dni" id="DNI/NIE" placeholder=""
                                                value={this.state.dni}
                                                valid={!this.state.errors.dni && this.state.dni !== ''}
                                                invalid={this.state.errors.dni}
                                                onChange={this.handleInputChange} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </React.Fragment>
                            }
                            <Row className="pt-2 pb-2 ml-1 mr-1">
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" checked={this.state.accountEdit} name="accountEdit" onChange={(e) => this.setState({ accountEdit: e.target.checked })} />
                                            Account editable by the student
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <UserDetail type='showErrorMessages' {...this.state} />
                            <Row className="pt-2 pb-2 ml-1 mr-1">
                                <Col className="font-weight-bold" xs="12" sm="12" md="12" lg="12"> LATEST ACTIONS</Col>
                            </Row>
                            {this.state.latestActions.map((d, i) => (<Row key={i} className="pb-2 ml-1 mr-1">
                                <Col className="mt-2" xs="6" sm="6" md="6" lg="4">{d.date}</Col>
                                <Col className="mt-2" xs="6" sm="6" md="6" lg="4">{d.email}</Col>
                                <Col className="mt-2" xs="6" sm="6" md="6" lg="4">{d.action}</Col>
                            </Row>
                            ))}
                            <Row className="pb-2 ml-1 mr-1 mt-4">
                                <Col xs="3" sm="3" md="3" lg="2">
                                    <Button className="font-weight-normal" color="secondary" size="md" onClick={() => { if (type === 'CREATE') { return this.props.back(true) } else if (type === 'EDIT') { history.push('/admin') } }}>Cancel</Button>
                                </Col>
                                <Col xs="3" sm="3" md="3" lg="2">
                                    <Button className="font-weight-normal" color="secondary" size="md" onClick={this.create}>Save</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <ModalAccept isOpen={this.state.openModal} toggle={this.toggle} header={'Alert'}>
                        <div className="text-center font-weight-bold">{this.state.popupMsg}</div>
                    </ModalAccept>
                </Container>
            </Fragment>
        );
    }
}

StudentEdit.prototypes = {
    type: PropTypes.string.isRequired
};

export default withUserInfo(withRouter(StudentEdit));