import React,{Component}from"react"
import{withUserInfo}from"../../../utils/hocs"
import ROLES from"../../../globals/userRoles"
import{getStudentLog}from"../../../services/administrator/request"
import{getStatusMessage}from"../../../utils/format"
import"./formStyle.scss"
import{Container,Form,Row,Col,Table,Button}from"reactstrap"
import ModalAccept from"../../ModalAccept/ModalAccept"
class StudentLog extends Component{
	constructor(props){
    super(props)
		this.state={
      openModal:false
		}
		this.showError=this.showError.bind(this)
		this.toggle=this.toggle.bind(this)
	}
	componentDidMount(){
		if(this.props.userInfo.role&&this.props.userInfo.role===ROLES.CUSTOMER_SUPPORT){
			window.scrollTo(0,0)
			getStudentLog(this.props.token?this.props.token:undefined)
			.then(result=>{if(result.status===200)this.setState({"logs":result.data})})
			.catch(err=>{this.showError(err.code==='ECONNABORTED'?'400':err.response.status)})
		}
	}
  showError(error){
    this.setState({
      popupMsg:getStatusMessage(error),
			openModal:true
		})
	}
	toggle(){
		this.setState({openModal:false})
	}
  render(){
    if(!this.props.userInfo.role||(this.props.userInfo.role!==ROLES.CUSTOMER_SUPPORT)){
      this.props.history.push('/admin')
		}
		this.state.logs&&console.log(this.state.logs)
		/*const{match:{params}}=this.props
		const qparams=queryString.parse(decodeURIComponent(this.props.location.search))*/
		return(
      <React.Fragment>
			  <Container>
			    <Form>
			      <div classname="formContainer">
			        <Row className="pt-2 pb-2 m1-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray">
			          <Col xs="6"md="8"><h3 className="text-primary font-weight-bold mb-0">{this.props.token?this.props.token:'GLOBALS'}</h3>
			          </Col>
			        </Row>
			        {this.props.title&&(
			        <Row className="pt-2 pb-2 m1-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray">
			          <Col xs="6"md="8"><h3 className="text-primary font-weight-bold mb-0">{this.props.title}</h3>
			          </Col>
			        </Row>
              )}
			        <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray small"> 
			           <Col xs="12"sm="12"md="12"lg="12">
			             <Table responsive={true}>
			               <thead>
			                 <tr bgcolor="#5393d4">
			                   {/*
			                   <th>Title</th>
												 */}
			                   <th>Administrator</th>
			                   {this.props.token&&(
			                   <th>Action</th>
												 )}
			                   <th>Date</th>
			                   <th>Changes</th>
			                   <th>Message</th>
			                 </tr>
			               </thead>
			               <tbody>
			                 {this.state.logs&&this.state.logs.map((data,index)=>(
											 <tr key={index}>
												 {/*
			                   <td>{data.title}</td>
												 */}
			                   <td>{data.administrator}</td>
												 {this.props.token&&(
			                   <td>{data.action}</td>
												 )}
			                   <td>{data.date}</td>
			                   <td>{data.array_values}</td>
			                   <td>{data.message}</td>
											 </tr>
											 ))}
			               </tbody>
			             </Table>
			           </Col>
			        </Row>
			        <Row className="pt-2 pb-2 ml-1 mr-1">
			          <Col xs="12"sm="6"md="6">
			            <Button className="font-weight-normal"color="secondary"size="md" onClick={()=>{return this.props.back(true,undefined)}}>Go back</Button>
			          </Col>
			        </Row>
			      </div>
			    </Form>
			    <ModalAccept isOpen={this.state.openModal}toggle={this.toggle}header={'Alert'}>
			      <div className="text-center font-weight-bold">{this.state.popupMsg}</div>
			    </ModalAccept>
			  </Container>
			</React.Fragment>
		)
  }
}
export default withUserInfo(StudentLog)
