import React from 'react'
import _ from 'lodash'
import { Container, Row, Col, Nav, NavItem, Table } from 'reactstrap'

import ResourceListItem from './ResourceListItem'
import { withContent } from '../../../utils/hocs'

class Resources extends React.Component {
  constructor() {
    super()
    this.state = {
      categories: [],
      selectedCategory: null
    }
  }

  componentDidMount() {
    const { resources } = this.props
    const categories = resources.reduce((acc, resource) => {
      const { categories: [{ categoryId, name }] } = resource
      if(!acc.find((item) => item.uuid === categoryId)) {
        acc.push({ uuid: categoryId, name })
      }
      return acc
    }, [])
    const sortedCategories = _.sortBy(categories, ['name'])
    const selectedCategory = _.get(sortedCategories, '0.uuid')
    this.setState({ selectedCategory, categories: sortedCategories })
  }

  render() {
    const { categories, selectedCategory } = this.state
    const { resources: allResources } = this.props

    const resources = allResources.filter((resource) => selectedCategory === _.get(resource, 'categories.0.categoryId'))

    return (
      <Container fluid>
        <Row>
          <Col className="px-md-0">
            <Nav pills>
              {
                categories.map((category) => (
                  <NavItem
                    className={`mr-2 tag ${category.uuid === selectedCategory ? 'tag-active' : ''}`}
                    onClick={() => this.setState({ selectedCategory: category.uuid })}
                    key={category.uuid}
                  >
                    { category.name }
                  </NavItem>
                ))
              }
            </Nav>
          </Col>
        </Row>
        <Row className="mt-3">
          <Table className="w-100">
            <tbody>
              {
                resources.map((resource) => (
                  <ResourceListItem
                    key={resource.uuid}
                    resource={resource}
                  />
                ))
              }
            </tbody>
          </Table>
        </Row>
      </Container>
    )
  }
}

export default withContent(Resources)
