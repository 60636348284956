import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import './formStyle.scss';
import validator from 'validator';
import PropTypes from 'prop-types';
import _ from 'lodash';
import envSettings from '../../../globals/envSettings'
import ModalAccept from '../../ModalAccept/ModalAccept';
import ROLES, { users } from '../../../globals/userRoles'
import { characterLength, emailRegex, passwordRegex } from "../../../utils/format";
import UserDetail from "../FormComponent";
import { getStatusMessage } from "../../../utils/format";
import { getCustomerSupportDataById, getCustomerSupportDataActions, getCustomerSupportDataBlink, putEmailCustomerSupportDataById, putCustomerSupportDataById } from "../../../services/administrator/request";
import { regexAlphaNumeric } from '../../../utils/formValidator'
import { isValid } from 'better-dni'

const padCard = (value) => {
    let card = value
    if(card.length < 9) {
      if(card.charAt(0).match(/[0-9]/)) {
        card = _.padStart(card, 9, '0')
      } else if(card.charAt(0).match(/[x-zX-Z]/)) {
        card = card.charAt(0) + _.padStart(card.substring(1), 8, '0')
      }
    }
    return card
  }

const validCard = (card, mandatory = false) => (!mandatory && !card) || (!!card && isValid(card))

class CustomerUserEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: '',
            name: '',
            surname1: '',
            surname2: '',
            email: '',
            password: '',
            confirmPassword: '',
            card: '',
            latestActions: [],
            blink: [],
            idexternalplatform: 3,
            role: 'DIGITAL-SUPPORT',
            openModal: false,
            updates: false,
            training: false,
            phone: '',
            mktPrefs: false,
            errors: {
                name: false,
                surname1: false,
                surname2: false,
                email: false,
                password: false,
                confirmPassword: false,
                card: false,
                phone: false
            },
            errorMsg: {
                name: false,
                email: false,
                password: false,
            }
        };
        this.handleInputChange = this.handleInputChange.bind(this)
        this.save = this.save.bind(this);
        this.toggle = this.toggle.bind(this)
        this.errorMsg = this.errorMsg.bind(this)
        this.checkbox = this.checkbox.bind(this)
        this.activation = this.activation.bind(this)
        this.showError = this.showError.bind(this)
        console.log('customerUserEdit', props);
    }

    showError(error) {
        this.setState({
              popupMsg: getStatusMessage(error),
              openModal: true
        })
      }

    isValid = () => !_.compact(_.values(this.state.errors)).length && this.state.name && this.state.surname1 &&
        this.state.email && this.state.password === this.state.confirmPassword
        && [ROLES.CUSTOMER_SUPPORT].find(role => role === _.get(this.props, 'userInfo.role', undefined))

    componentDidMount() {
        window.scrollTo(0, 0);
        const { match: { params } } = this.props;

        Promise.all([getCustomerSupportDataById(params.userId), getCustomerSupportDataActions(params.userId), getCustomerSupportDataBlink(params.userId)])
            .then(([result1, result2, result3]) => {
                if (result1.status === 200 && result2.status === 200 && result3.status === 200) {
                    console.log(result1, result2);
                    this.setState({
                        'userData': result1.data,
                        'card': result1.data.card,
                        'ccaaId': result1.data.ccaaId,
                        'country': result1.data.country,
                        'creationDate': result1.data.creationDate,
                        'downloadResources': result1.data.downloadResources,
                        'email': result1.data.email,
                        'guardian': result1.data.guardian,
                        'id': result1.data.id,
                        'language': result1.data.language,
                        'lastAccessDate': result1.data.lastAccessDate,
                        'mktPrefs': !!result1.data.mktPrefs,
                        'name': result1.data.name,
                        'password': result1.data.password,
                        'confirmPassword': result1.data.password,
                        'phone': result1.data.phone,
                        'role': result1.data.role,
                        'schoolId': result1.data.schoolId,
                        'schoolName': result1.data.schoolName,
                        'self-editable': result1.data['self-editable'],
                        'surname1': result1.data.surname1,
                        'surname2': result1.data.surname2,
                        'validatedEmail': !!result1.data.validatedEmail,
                        'latestActions': result2.data,
                        'blink': result3.data,
                        'idexternalplatform': result1.idexternalplatform
                    })
                }
            })
            .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
    }


    save() {
        const { name, surname1, surname2, email, password, confirmPassword, mktPrefs, card, phone, validatedEmail, language } = this.state;
        const { match: { params } } = this.props;
        let data = {
            userId: params.userId,
            name,
            surname1,
            surname2,
            email,
            password,
            validatedEmail,
            mktPrefs,
            phone,
            card,
            language
        };

        if (this.isValid()) {
            console.log('saved: ', data);
            putCustomerSupportDataById(data)
                .then(result => {
                    this.setState({
                        popupMsg: getStatusMessage(result.status),
                        openModal: true,
                        errorMsg: {
                            name: false,
                            email: false,
                            password: false
                        }
                    })
                })
                .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
        }
        else {
            console.log(this.state.errors);
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        name: !validator.matches(name, characterLength),
                        surname1: !validator.matches(surname1, characterLength),
                        surname2: _.isEmpty(surname2) ? false : !validator.matches(surname2, characterLength),
                        email: !validator.matches(email, emailRegex),
                        password: _.isEmpty(password) ? false : !validator.matches(password, passwordRegex),
                        confirmPassword: _.isEmpty(confirmPassword) ? false : !validator.matches(confirmPassword, passwordRegex) || !(password === confirmPassword),
                        card: !validCard(padCard(card), this.state.country === envSettings.COUNTRY_ESP_ID)
                    }
                }
            }, () => this.errorMsg());
        }
    }

    activation(action) {
        const { match: { params } } = this.props;
        let data = {
            userId: params.userId,
            action
        };

        putEmailCustomerSupportDataById(data)
            .then(result => {
                this.setState({
                    popupMsg: getStatusMessage(result.status),
                    openModal: true
                })
            })
            .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
    }

    checkbox({ target: { name, checked } }) {
        this.setState({
            [name]: checked
        });
    }


    errorMsg() {
        this.setState({
            errorMsg: {
                name: (this.state.errors.name || this.state.errors.surname1 || this.state.errors.surname2),
                email: (this.state.errors.email),
                password: this.state.errors.password || this.state.errors.confirmPassword || this.state.password !== this.state.confirmPassword
            }
        });
    }
    toggle() {
        this.setState({ openModal: false }, () => {
            this.props.history.push( {pathname: '/admin', state: { admin: true }});
        })
    }

    handleInputChange = ({ target: { name, value } }) => {

        switch (name) {
            case "name":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !validator.matches(value, characterLength)
                        }
                    }
                })
                break
            case "surname1":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !validator.matches(value, characterLength)
                        }
                    }
                })
                break
            case "surname2":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: _.isEmpty(value) ? false : !validator.matches(value, characterLength)
                        }
                    }
                })
                break
            case "email":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: !validator.matches(value, emailRegex)
                        }
                    }
                })
                break
            case "password":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: _.isEmpty(value) ? false : !validator.matches(value, passwordRegex)
                        }
                    }
                })
                break
            case "confirmPassword":
                this.setState((prevState) => {
                    return {
                        [name]: value,
                        errors: {
                            ...prevState.errors,
                            [name]: (this.state.password === value) ? false : !validator.matches(value, passwordRegex)
                        }
                    }
                })
                break

                case "card":
                    this.setState((prevState) => {
                      const card = padCard(value)
                      return {
                        card: (value === '' || validator.matches(card, regexAlphaNumeric)) ? value : prevState.card,
                        errors: {
                          ...prevState.errors,
                          card: !isValid(card),
                          card_exist: false
                        }
                      }
                    })
                    break;

            case "role":
                this.setState({ [name]: value })
                break
            default:
                this.setState({ [name]: value })
        }
    }

    thirdParties = (value) => [envSettings.PLATFORM_MICROSOFT_ID, envSettings.PLATFORM_GOOGLE_ID].includes(value)
    externalPlatform = (value) => (value === envSettings.PLATFORM_MICROSOFT_ID ? 'Microsoft' : (value === envSettings.PLATFORM_GOOGLE_ID ? 'Google': ''))

    render() {
        console.log('CustomerUserEditPopup', this.props)
        const { id, name, surname1, schoolName, country, idexternalplatform } = this.state.userData;
        const { history, match: { params } } = this.props;
        return (
            <React.Fragment>
                <Container>
                    <Form>
                        <div className='formContainer'>
                            <Row className='pt-2 pb-2 ml-1 mr-1'>
                                <Col xs="6" md="8"><h3 className="text-primary font-weight-bold mb-0">{name} {surname1}</h3></Col>
                            </Row>
                            <Row className='pt-2 pb-2 ml-1 mr-1'>

                                <Col xs="6" md="8"><h5 className="font-weight-bold mb-0">{users[params.role]}</h5></Col>
                            </Row>
                            <Row className='pt-2 pb-2 ml-1 mr-1'>
                                <Col xs="6" md="8"><h5 className="font-weight-bold mb-0">ID: {id}</h5></Col>
                            </Row>
                            <UserDetail type='names' {...this.state} handleInputChange={this.handleInputChange} />
                            <Row className='pt-2 pb-2 ml-1 mr-1'>
                            <UserDetail type='email' disabled={this.thirdParties(idexternalplatform)} size='4' {...this.state} handleInputChange={this.handleInputChange} />
                                {/* <Col xs="12" sm="6" md="6" lg="4">
                                    <h4>
                                        {validatedEmail && <Badge variant="success" className="badge badge-success validated">Validated</Badge>}
                                        {!validatedEmail && <Button variant="success" className="badge badge-warning validated" onClick={() => this.activation('validate')}>Validate</Button>}
                                        {!validatedEmail && <Button variant="success" className="badge badge-secondary validated badge badge-secondary ml-4" onClick={() => this.activation('forward')}>Forward Email</Button>}
                                    </h4>
        </Col> */}
                                <Col xs="12" sm="6" md="6" lg="4">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="DNI/NIE">DNI / NIE</Label>
                                        <Input type="text" disabled={country !== envSettings.COUNTRY_ESP_ID} size="" name="card" id="card" placeholder=""
                                            valid={!this.state.errors.card || this.state.card === ''}
                                            invalid={this.state.errors.card}
                                            value={this.state.card}
                                            onChange={this.handleInputChange} />
                                    </FormGroup>
                                </Col>
                                {this.thirdParties(idexternalplatform) && <Col className="align-self-center mt-4" xs="12" sm="6" md="6" lg="4"> 
                                  <FormGroup className="form-group">
                                    <Label className="font-weight-normal" for="platform"> {`Connected with ${this.externalPlatform(idexternalplatform)} account`}</Label>
                                  </FormGroup>
                                </Col>}
                            </Row>
                            <Row className='pt-2 pb-2 ml-1 mr-1'>
                            <UserDetail type='password' disabled={this.thirdParties(idexternalplatform)} passwordEdit={this.props.type} {...this.state} handleInputChange={this.handleInputChange} />
                                <Col xs="12" sm="6" md="6" lg="4">
                                    <FormGroup className="form-group">
                                        <Label className="font-weight-bold" for="">Contact Phone Number</Label>
                                        <Input type="text" size="" name="phone" id="" placeholder=""
                                            defaultValue={this.state.phone}
                                            onChange={(e) => this.setState({ phone: e.target.value })}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='pt-2 pb-2 ml-2 mr-1'>
                                <Col xs="12" sm="6" md="6" lg="12">
                                    <span className="font-weight-bold">Education centre:</span>
                                    <span className='ml-5'>{schoolName}</span>
		                    {/*this.props.userInfo.role&&this.props.userInfo.role===ROLES.CUSTOMER_SUPPORT&&(<span className='ml-5'><Link to={`/edit/customer/user/licenses/${id}`}>Licences</Link></span>)*/} 
		                    {this.props.userInfo.role&&this.props.userInfo.role===ROLES.CUSTOMER_SUPPORT&&(<span className='ml-5'><Button className="font-weight-normal"color="secondary"size="md"onClick={()=>{history.push(`/edit/customer/user/licenses/${id}?`+encodeURIComponent(`rol=${users[params.role]}&name=${name}&surname1=${surname1}`))}}>Licences</Button></span>)} 
                                </Col>
                            </Row>
                            <Row className='pt-2 pb-2 ml-4 mr-1'>
                                <Col xs="12" sm="6" md="6" lg="12">
                                    <div><Input type="checkbox" name="mktPrefs"
                                        checked={this.state.mktPrefs}
                                        onChange={this.checkbox}
                                    /> Commercial information</div>
                                </Col>
                            </Row>
                            <UserDetail type='showErrorMessages' {...this.state} />

                            <Row className="pt-2 pb-2 ml-1 mr-1">
                                <Col className="font-weight-bold" xs="12" sm="12" md="12" lg="12"> LATEST ACTIONS</Col>
                            </Row>
                            {this.state.latestActions.map((d, i) => (<Row key={i} className="pb-2 ml-1 mr-1">
                                <Col className="mt-2" xs="6" sm="6" md="6" lg="4">{d.date}</Col>
                                <Col className="mt-2" xs="6" sm="6" md="6" lg="4">{d.email}</Col>
                                <Col className="mt-2" xs="6" sm="6" md="6" lg="4">{d.action}</Col>
                            </Row>
                            ))}

                            <Row className="pt-2 pb-2 ml-1 mr-1">
                                <Col className="font-weight-bold" xs="12" sm="12" md="12" lg="12"> BLINK LICENCES INTEGRATION</Col>
                            </Row>
                            <Row className="pb-2 ml-1 mr-1">
                                <Col className="font-weight-bold" xs="6" sm="6" md="2" lg="1">Blink Id</Col>
                                <Col className="font-weight-bold" xs="6" sm="6" md="2" lg="1">MEC</Col>
                                <Col className="font-weight-bold" xs="6" sm="6" md="3" lg="2">Date</Col>
                                <Col className="font-weight-bold overflow-auto" xs="6" sm="6" md="3" lg="4">Licences</Col>
                                <Col className="font-weight-bold" xs="6" sm="6" md="1" lg="1">Status</Col>
                                <Col className="font-weight-bold" xs="6" sm="6" md="3" lg="3">Error</Col>
                            </Row>

                            {this.state.blink.map((d, i) => (<Row key={i} className="pb-2 ml-1 mr-1">
                                <Col className="mt-2" xs="6" sm="6" md="2" lg="1">{d.blinkId}</Col>
                                <Col className="mt-2" xs="6" sm="6" md="2" lg="1">{d.mec}</Col>
                                <Col className="mt-2" xs="6" sm="6" md="3" lg="2">{d.fecharequest}</Col>
                                <Col className="mt-2 overflow-auto" xs="6" sm="6" md="3" lg="4">{d.licences}</Col>
                                <Col className="mt-2" xs="6" sm="6" md="1" lg="1">{d.status}</Col>
                                <Col className="mt-2" xs="6" sm="6" md="3" lg="3">{d.errorm}</Col>
                            </Row>
                            ))}

                            <Row className='pt-2 pb-2 ml-1 mr-1'>
                                <Col xs="12" sm="6" md="6" lg="3">
                                    <Button className="font-weight-normal" color="secondary" size="md" onClick={() => { history.push( {pathname: '/admin', state: { admin: true }}) }}>Cancel</Button>
                                    <Button variant="secondary" className='btn btn-secondary ml-5' onClick={this.save}>Save</Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                    <ModalAccept isOpen={this.state.openModal} toggle={this.toggle} header={'Alert'}>
                        <div className="text-center font-weight-bold">{this.state.popupMsg}</div>
                    </ModalAccept>
                </Container>
            </React.Fragment>

        )

    }
}
CustomerUserEdit.prototypes = {
    type: PropTypes.string.isRequired
};

export default withRouter(CustomerUserEdit);
