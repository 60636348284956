import React, { Component, Fragment } from 'react'
import axios from 'axios'
import axiosConfig from '../../config/axios'
import { FeatureContext } from '../../contexts/Feature/FeatureContext'
import envSettings from '../../globals/envSettings'
import { Container, Progress, Alert } from 'reactstrap'

import ModalLibraryGuidance from '../ModalLibraryGuidance/ModalLibraryGuidance'
import { getLocale } from '../../language'
import { ContentConsumer } from '../../language'
import { AuthConsumer } from 'react-check-auth'
import BooksSlider from './BooksSlider'
import PromotionTitle from '../PromotionTitle/PromotionTitle'
import CMSContent from '../CMSContent/CMSContent'
import userRoles from "../../globals/userRoles"
import { adoptionTypes, libraryTypes } from '../../globals/title'

class MyLibrarySlider extends Component {

  static contextType = FeatureContext

  constructor(props) {
    super(props);
    this.state = {
      library: {
        [libraryTypes.ADOPTED]: [],
        [libraryTypes.DEMO]: [],
        [libraryTypes.RESOURCES]: []
      },
      shownLibrary: [],
      error: '',
      selected: null,
      isLoading: true,
      showModal: false,
      userRole: props.userInfo.role,
      userLanguage: props.userInfo.language
    }

    this.toggleModal = this.toggleModal.bind(this)
  }


  toggleModal = () => this.setState(
    prevState => ({
      error: '', showModal: !prevState.showModal
    }))

  componentDidMount() {
    this.getMyLibrary()
  }

  hasLibrary = (libraryType) => {
    const { library } = this.state
    if(libraryType === libraryTypes.ADOPTED ||
      libraryType === libraryTypes.DEMO ||
      libraryType === libraryTypes.RESOURCES) {
      return !!library[libraryType].length
    }
    return false
  }

  isLibrarySelected = (libraryType) => {
    const { selected } = this.state
    return selected === libraryType
  }

  switchLibrary = (libraryType) => {
    const { library } = this.state
    if(libraryType === libraryTypes.ADOPTED ||
      libraryType === libraryTypes.DEMO ||
      libraryType === libraryTypes.RESOURCES) {
      this.setState({ shownLibrary: library[libraryType], selected: libraryType })
    }
  }

  getMyLibrary = () => {
    let shownLibrary = null
    const axiosInstance = axios.create(axiosConfig)

    axiosInstance.get('/library')
      .then((response) => {
        const titles = response.data
        const library = {
          [libraryTypes.ADOPTED]: titles.filter((title) => title.adoption === 1 && title.adoptionType !== adoptionTypes.RESOURCES),
          [libraryTypes.DEMO]: titles.filter((title) => title.adoption === 0 && title.adoptionType !== adoptionTypes.RESOURCES),
          [libraryTypes.RESOURCES]: titles.filter((title) => title.adoptionType === adoptionTypes.RESOURCES)
        }
        const selected = (library[libraryTypes.ADOPTED].length && libraryTypes.ADOPTED) || libraryTypes.DEMO
        
        shownLibrary = (library[libraryTypes.ADOPTED].length && library[libraryTypes.ADOPTED]) || library[libraryTypes.DEMO]
        
        this.setState(
          {
            // MBQ PROMOTIONS
            //library: _.sortBy(response.data, ['name']),
            library,
            selected,
            shownLibrary,
            // MBQ PROMOTIONS
            isLoading: false
          },
          () => {
            if(shownLibrary && shownLibrary.length > 0) {
              this.context.setModalFlag("library", false)
            } else {
              if([userRoles.TEACHER, userRoles.TEACHER_DEMO].includes(this.state.userRole)) {
                this.context.setModalFlag("library", true)
              } else {
                this.context.setModalFlag("library", false)
              }
            }
          }
        )
      })
      .catch((error) => {
        if (error.response) {
          //if (error.response.status === 404) return this.setState({ isLoading: false })
          if (error.response.status === 404) {
            return this.setState(
              { isLoading: false },
              () => { this.context.setModalFlag("library", true) }
            )
          }
          
          console.log(error)
          this.context.setModalFlag("library", false)

          if (error.response.status === 401) {
            window.location.href = envSettings.LOGIN_URL
          }
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response);

          // console.log(error.response.status);
          // console.log(error.response.headers);

        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }

        //this.setState({ error, isLoading: false })
        this.setState(
          { error, isLoading: false },
          () => { this.context.setModalFlag("library", false) }
        )

        return 'LIBRARY ERROR'
      })
  }

  render() {

    const { shownLibrary, selected, isLoading, error, userRole } = this.state

    return (
      <ContentConsumer>
        {({ content }) => (content && (
          <Fragment>
            {!isLoading ?
              <Fragment>
                {error !== '' ?
                  <div>
                    <Alert color="danger">{content.sharedTerms.ERROR_TEXT}</Alert>
                  </div>
                  :
                  <Fragment>
                    { shownLibrary&& shownLibrary.length > 0 ?
                      <Container className="myLibrarySlider">
                        <BooksSlider
                          assets={shownLibrary}
                          hasLibrary={this.hasLibrary}
                          isLibrarySelected={this.isLibrarySelected}
                          switchLibrary={this.switchLibrary}
                        />
                        {/* MBQ PROMOTIONS */}
                        {[userRoles.TEACHER, userRoles.TEACHER_FAKE, userRoles.TEACHER_DEMO].includes(userRole)
                          && this.props.userInfo.countryId === envSettings.COUNTRY_ESP_ID
                          && <PromotionTitle role={userRole} />}
                        {/* MBQ PROMOTIONS */}
                      </Container>
                      :
                      [userRoles.TEACHER, userRoles.TEACHER_DEMO].includes(userRole) ?
                        <ModalLibraryGuidance />
                        :
                        <CMSContent pageid="PREM_EMPTY_LIBRARY" cache={true} language={this.state.userLanguage ? getLocale(this.state.userLanguage) : getLocale()} />
                    }
                  </Fragment>
                }
              </Fragment>
              :
              <Progress animated color="primary" value="100" >
                {content.titledetail.loading_library}
              </Progress>
            }
          </Fragment>
        ))}
      </ContentConsumer>
    )
  }
}

const withUserContext = Component => {
  return props => {
    return (
      <AuthConsumer>
        {({ userInfo }) => <Component {...props} userInfo={userInfo} />}
      </AuthConsumer>
    )
  }
}

export default withUserContext(MyLibrarySlider)