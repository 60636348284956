import React from 'react'
import { Button } from 'reactstrap'
import _ from 'lodash'

import { withContent } from '../../../utils/hocs'
import { ContentConsumer } from '../../../language'

// Settings constants
import envSettings from '../../../globals/envSettings'

const Footer = ({ data: { size, downloadURL, mimetype, description }, content: { mimetypes } }) => {
  const format = _.get(mimetypes, mimetype)
  const afterSlashChars = mimetype.match(/\/([^/]+)\/?$/)[1]

  const formatBytes = bytes => {
    const kb = 1024
    const ndx = Math.floor(Math.log(bytes) / Math.log(kb))
    const fileSizeTypes = ["bytes", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"]

    const size = +(bytes / kb / kb).toFixed(2),
      type = fileSizeTypes[ndx]

    return `${size}${type}`
  }

  const isBlank = str => !str || /^\s*$/.test(str)

  return (

    <ContentConsumer>
      {({ content }) => (content && (
        <div id="lightBoxFooter" className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex flex-column m-0 p-0 resourceList__LightBox__Info text-uppercase">
            {!isBlank(mimetype) &&
              <div className="mr-2 text-truncate" >
                {content.titledetail.format}:
            <span className="ml-1">{format || afterSlashChars}</span>
              </div>
            }
            {!isBlank(size) &&
              <div className="mr-2 text-truncate" >
                {content.titledetail.size}:
            <span className="ml-1">{formatBytes(size)}</span>
              </div>
            }
            {!isBlank(description) &&
              <div className="mr-2 text-truncate" >
                {content.titledetail.description}:
            <span className="ml-1">{description}</span>
              </div>
            }

          </div>
          {!isBlank(downloadURL) &&
            <div className="d-flex justify-content-end resourceList__LightBox__Download">
              <a href={`${envSettings.API_BASEURL}${downloadURL}`} title="Descargar">
                <Button className="d-flex justify-content-between align-items-center" color="primary">
                  <span>{content.titledetail.download}</span>
                  <span className="glyphicon glyphicon-download-alt ml-2" aria-hidden="true"> </span>
                </Button>
              </a>
            </div>
          }
        </div>
      ))}
    </ContentConsumer>
  )
}

export default withContent(Footer)
