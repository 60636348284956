import React from 'react'
import { AuthConsumer } from 'react-check-auth'
import { Route, Redirect } from 'react-router-dom'
import { isAllowedPath } from './utils'

const AuthRoute = ({ component: Component, routeProfile, portal, ...rest }) => (
  <AuthConsumer>
    {({ userInfo }) => (
      userInfo ?
        (
          isAllowedPath(userInfo.profile, routeProfile, portal) ?
            <Route {...rest} render={props => <Component {...props} />} />
            :
            <Redirect to='/error' />
        )
        :
        (
          <Redirect to={{ pathname: '/home', state: { referrer: window.location.href } }} />
        )
    )}
  </AuthConsumer>
)

export default AuthRoute