import ROLES from '../../../globals/userRoles'
import React from 'react'
import { Row, Col, FormGroup, Label, Input } from 'reactstrap'
import PropTypes from 'prop-types'

function FormComponent(props) {
    const { type } = props;

    switch (type) {
        case 'names':
            return <Names {...props} />
        case 'email':
            return <Email {...props} />
        case 'password':
            return <Password {...props} />
        case 'dropdown':
            return <Dropdown {...props} />
        case 'showErrorMessages':
            return <ShowErrorMessages {...props} />
        default:
            return <Names {...props} />
    }
}

function Names(props) {
    return (
        <Row className="pt-2 pb-2 ml-1 mr-1">
            <Col xs="12" sm="6" md="6" lg="4">
                <FormGroup className="form-group">
                    <Label className="font-weight-bold" for="name">Name *</Label>
                    <Input type="text" size="100" name="name" id="name" placeholder=""
                        value={props.name}
                        onChange={props.handleInputChange}
                        valid={!props.errors.name && props.name !== ''}
                        invalid={props.errors.name} 
                        disabled={!props.adminFictitious && (props.role === ROLES.TEACHER_FAKE)}
                        autoComplete="off" />
                </FormGroup>
            </Col>
            <Col xs="12" sm="6" md="6" lg="4">
                <FormGroup className="form-group">
                    <Label className="font-weight-bold" for="firstSurname">First Surname *</Label>
                    <Input type="text" size="100" name="surname1" id="surname1" placeholder=""
                        value={props.surname1}
                        onChange={props.handleInputChange}
                        valid={!props.errors.surname1 && props.surname1 !== ''}
                        invalid={props.errors.surname1} 
                        disabled={!props.adminFictitious && (props.role === ROLES.TEACHER_FAKE)}
                        autoComplete="off"/>
                </FormGroup>
            </Col>
            <Col xs="12" sm="6" md="6" lg="4">
                <FormGroup className="form-group">
                    <Label className="font-weight-bold" for="secondSurname">Second Surname</Label>
                    <Input type="text" size="100" name="surname2" id="secondSurname" placeholder=""
                        value={props.surname2}
                        onChange={props.handleInputChange}
                        valid={!props.errors.surname2 && props.surname2 !== ''}
                        invalid={props.errors.surname2}
                        disabled={!props.adminFictitious && (props.role === ROLES.TEACHER_FAKE)}
                        autoComplete="off"/>
                </FormGroup>
            </Col>
        </Row>
    );
}

function Email(props) {
    return (
        <Col xs="12" sm="6" md="6" lg={props.size}>
            <FormGroup className="form-group">
                <Label className="font-weight-bold" for="emailAddress">Email Address *</Label>
                <Input type="email" size="100" name="email" id="emailAddress" placeholder="" disabled={props.disabled || (!props.adminFictitious && (props.role === ROLES.TEACHER_FAKE))}
                    value={props.email}
                    onChange={props.handleInputChange}
                    valid={!props.errors.email && props.email !== ''}
                    invalid={props.errors.email} 
                    autoComplete="off" />
            </FormGroup>
        </Col>
    );
}

function Password(props) {
    console.log('props: ', props)
    return (       
        <React.Fragment>
            {props.type === 'CREATE'} 
        {/* <Row className="pt-2 pb-2 ml-1 mr-1"> */}
            <Col xs="12" sm="6" md="6" lg="4">
                <FormGroup className="form-group">
                    <Label className="font-weight-bold" for="password">Password  {props.passwordEdit !== 'EDIT' && '*'} </Label>
                    <Input type="password" size="100" name="password" id="password" placeholder="" disabled={props.disabled || (!props.adminFictitious && (props.role === ROLES.TEACHER_FAKE))}
                        value={props.password}
                        onChange={props.handleInputChange}
                        valid={!props.errors.password && props.password !== ''}
                        invalid={props.errors.password}                         
                        autoComplete="off" />
                </FormGroup>
            </Col>
            <Col xs="12" sm="6" md="6" lg="4">
                <FormGroup className="form-group">
                    <Label className="font-weight-bold" for="confirmPassword">Confirm Password {props.passwordEdit !== 'EDIT' && '*'}</Label>
                    <Input type="password" size="100" name="confirmPassword" id="confirmPassword" placeholder="" disabled={props.disabled || (!props.adminFictitious && (props.role === ROLES.TEACHER_FAKE))}
                        value={props.confirmPassword}
                        onChange={props.handleInputChange}
                        valid={(!props.errors.confirmPassword) && props.confirmPassword !== '' && (props.password === props.confirmPassword)}
                        invalid={props.errors.confirmPassword || props.password !== props.confirmPassword}                         
                        autoComplete="off" />
                </FormGroup>
            </Col>
       {/* </Row> */}
        </React.Fragment>
    );
}

function Dropdown(props) {
    return (
        <Col xs="12" sm="6" md="6" lg="4">
            <FormGroup className="form-group">
                <Label className="font-weight-bold" for="language">{props.labelName}</Label>
                <Input type="select" name={props.name} id={props.name} placeholder="" disabled={!props.adminFictitious && (props.role === ROLES.TEACHER_FAKE)}
                    value={props.value}
                    valid={!props.errors[props.name] && props[props.name] !== ''}
                    invalid={props.errors[props.name]}
                    onChange={props.handleInputChange}>
                    {props.options.map((d, i) => <option key={i} value={d.uuid}>{d.name}</option>)}
                </Input>
            </FormGroup>
        </Col>
    );
}

function ShowErrorMessages(props) {
    return (
        <Row className="pt-2 pb-2 ml-1 mr-1">
            <Col xs="12" sm="12" md="12" lg="12">
                {props.errorMsg.name && <div className="text-danger font-weight-bold">
                    The Name/First Surname/Second Surname fields must contain between 2 and 45 characters and cannot contain numbers
                </div>}
                {props.errorMsg.email && <div className="text-danger font-weight-bold">
                    The email address is invalid
                </div>}
                {props.errorMsg.password && <div className="text-danger font-weight-bold">
                    The password must be minimum of 6 characters and include at least one lower case letter and one upper case letter, with no spaces.
                    The password and confirmation must match.
                </div>}
                {props.errorMsg.adoptions && <div className="text-danger font-weight-bold">
                    At least one title must be added
                </div>}
                {props.errorMsg.role && <div className="text-danger font-weight-bold">
                    The role must be filled in
                </div>}
                {props.errorMsg.language && <div className="text-danger font-weight-bold">
                    The language must be filled in
                </div>}
            </Col>
        </Row>
    );
}

FormComponent.prototypes = {
    type: PropTypes.string.isRequired
};

export default FormComponent;