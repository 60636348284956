import React, { useState } from 'react'
import _ from 'lodash'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import RedemptionFormModal from './RedemptionFormModal'
import ButtonCoupons from '../../../ButtonCoupons/ButtonCoupons'

import { parseToReactElement } from '../../../../utils/html'
import { withContent } from '../../../../utils/hocs'

const STEPS = {
  FORM: 'FORM',
  ERROR: 'ERROR',
  GENERICERROR: 'GENERICERROR',
  NOTREDIMABLEYETERROR: 'NOTREDIMABLEYETERROR',
  ACTIVATIONERROR: 'ACTIVATIONERROR',
  SUCCESS: 'SUCCESS'
}

const ModalWrapper = ({ isOpen, children }) => (
  <Modal
    className="coupons-modal"
    isOpen={isOpen}
  >
    {children}
  </Modal>
)

const RedemptionModal = ({ content, couponUser, updateLibrary, isOpen, onClose }) => {
  const [step, setStep] = useState(STEPS.FORM)

  const onCloseModal = () => {
    setStep(STEPS.FORM)
    onClose()
  }

  if(!isOpen) return null

  if(step === STEPS.FORM) {
    return (
      <ModalWrapper
        isOpen={isOpen}
      >
        <RedemptionFormModal
          isOpen
          couponUser={couponUser}
          updateLibrary={updateLibrary}
          onClose={() => onCloseModal()}
          onSuccess={() => setStep(STEPS.SUCCESS)}
          onError={() => setStep(STEPS.ERROR)}
          onGenericError={() => setStep(STEPS.GENERICERROR)}
          onNotRedimableYetError={() => setStep(STEPS.NOTREDIMABLEYETERROR)}
          onActivationError={() => setStep(STEPS.ACTIVATIONERROR)}
        />
      </ModalWrapper>
    )
  }

  if(step === STEPS.SUCCESS) {
    return (
      <ModalWrapper
        isOpen={isOpen}
      >
        <ModalHeader className="text-center">
          {parseToReactElement(_.get(content, 'coupons.modal_redemptionsuccess_header'))}
        </ModalHeader>
        <ModalBody>
          {parseToReactElement(_.get(content, 'coupons.modal_redemptionsuccess_body'), null, { ADD_ATTR: ['target'] })}
        </ModalBody>
        <ModalFooter className="mb-3">
          <ButtonCoupons
            primary
            onClick={onCloseModal}
          >
            {_.get(content, 'coupons.modal_redemptionsuccess_button')}
          </ButtonCoupons>
        </ModalFooter>
      </ModalWrapper>
    )
  }
  if(step === STEPS.ERROR) {
    return (
      <ModalWrapper
        isOpen={isOpen}
      >
        <ModalHeader className="text-center">
          {parseToReactElement(_.get(content, 'coupons.modal_redemptionerror_header'))}
        </ModalHeader>
        <ModalBody>
          {parseToReactElement(_.get(content, 'coupons.modal_redemptionerror_body'), null, { ADD_ATTR: ['target'] })}
        </ModalBody>
        <ModalFooter className="mb-3">
          <ButtonCoupons
            primary
            onClick={onCloseModal}
          >
            {_.get(content, 'coupons.modal_redemptionerror_button')}
          </ButtonCoupons>
        </ModalFooter>
      </ModalWrapper>
    )
  }
  if(step === STEPS.GENERICERROR) {
    return (
      <ModalWrapper
        isOpen={isOpen}
      >
        <ModalHeader className="text-center">
          {parseToReactElement(_.get(content, 'coupons.modal_genericerror_header'))}
        </ModalHeader>
        <ModalBody>
          {parseToReactElement(_.get(content, 'coupons.modal_genericerror_body'), null, { ADD_ATTR: ['target'] })}
        </ModalBody>
        <ModalFooter className="mb-3">
          <ButtonCoupons
            primary
            onClick={onCloseModal}
          >
            {_.get(content, 'coupons.modal_genericerror_button')}
          </ButtonCoupons>
        </ModalFooter>
      </ModalWrapper>
    )
  }
  if(step === STEPS.NOTREDIMABLEYETERROR) {
    return (
      <ModalWrapper
        isOpen={isOpen}
      >
        <ModalHeader className="text-center">
          {parseToReactElement(_.get(content, 'coupons.modal_notredimableyeterror_title'))}
        </ModalHeader>
        <ModalBody>
          {parseToReactElement(_.get(content, 'coupons.modal_notredimableyeterror_body'), null, { ADD_ATTR: ['target'] })}
        </ModalBody>
        <ModalFooter className="mb-3">
          <ButtonCoupons
            primary
            onClick={onCloseModal}
          >
            {_.get(content, 'coupons.modal_notredimableyeterror_button')}
          </ButtonCoupons>
        </ModalFooter>
      </ModalWrapper>
    )
  }
  if(step === STEPS.ACTIVATIONERROR) {
    return (
      <ModalWrapper
        isOpen={isOpen}
      >
        <ModalHeader className="text-center">
          {parseToReactElement(_.get(content, 'coupons.modal_activationerror_title'))}
        </ModalHeader>
        <ModalBody>
          {parseToReactElement(_.get(content, 'coupons.modal_activationerror_body'), null, { ADD_ATTR: ['target'] })}
        </ModalBody>
        <ModalFooter className="mb-3">
          <ButtonCoupons
            primary
            onClick={onCloseModal}
          >
            {_.get(content, 'coupons.modal_activationerror_button')}
          </ButtonCoupons>
        </ModalFooter>
      </ModalWrapper>
    )
  }
  return null
}

export default withContent(RedemptionModal)
