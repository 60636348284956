import React, { Component } from 'react'
import WPDataContext, { defaultState } from './WPDataContext'
import PropTypes from 'prop-types'

class WPDataProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...defaultState,
      setTitlePromotionBanners: this.setTitlePromotionBanners,
      setDmTitlePromotionBanners: this.setDmTitlePromotionBanners,
      getTitleBanner: this.getTitleBanner,
      deleteAdTitlePromotionBanner: this.deleteAdTitlePromotionBanner
    }
  }

  setTitlePromotionBanners = posts => {
    if (!posts.length)
      this.setState({ ...this.state, adTitlePromotionBanners: posts })
    else {
      const banners = {
        adTitlePromotionBanners: []
      }
      if (!this.state.dmTitlePromotionBanners) banners.dmTitlePromotionBanners = []
      posts.forEach(post => {
        if (post.idTitles) banners.adTitlePromotionBanners.push(post)
        else
          if (banners.dmTitlePromotionBanners) banners.dmTitlePromotionBanners.push(post)
      })
      this.setState({ ...this.state, ...banners })
    }
  }

  setDmTitlePromotionBanners = posts => this.setState({ ...this.state, dmTitlePromotionBanners: posts })

  getTitleBanner = (userLanguage, commonLanguage, idTitle) => {
    const { adTitlePromotionBanners, dmTitlePromotionBanners } = this.state
    let banner = null
    if (idTitle) {
      banner = adTitlePromotionBanners.find(item => item.idTitles.includes(idTitle) && item.languages.includes(userLanguage)) ||
        adTitlePromotionBanners.find(item => item.idTitles.includes(idTitle) && item.languages.includes(commonLanguage))
    } else {
      banner = dmTitlePromotionBanners.find(item => item.languages.includes(userLanguage)) ||
        dmTitlePromotionBanners.find(item => item.languages.includes(commonLanguage))
    }
    return banner
  }

  deleteAdTitlePromotionBanner = idPost => {
    const updatedBanners = this.state.adTitlePromotionBanners.filter(post => post.id !== idPost)
    this.setState({ ...this.state, adTitlePromotionBanners: updatedBanners })
  }

  render() {
    return (
      <WPDataContext.Provider value={this.state}>
        {this.props.children}
      </WPDataContext.Provider>
    )
  }
}

WPDataProvider.propTypes = {
  children: PropTypes.node
}

export default WPDataProvider