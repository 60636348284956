import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { AuthConsumer } from 'react-check-auth'
import { Container, Row, Col } from 'reactstrap'
import SigninForm from '../SigninForm/SigninForm.js'
import { RecaptchaTerms } from '../Recaptcha/Recaptcha'
import envSettings from '../../globals/envSettings'
import _ from 'lodash'
import qs from 'qs'
import validator from 'validator'

// MBQ TODO Add 3rd parties buttons: Google & Microsoft

const getReturnTo = props => {
  // MBQ GOOGLE/MS SIGN IN AND REGISTER: Deep linking
  const { returnTo } = qs.parse(props.location.search, { ignoreQueryPrefix: true })
  if (returnTo && validator.isURL(returnTo) && _.startsWith(returnTo, envSettings.APP_BASEURL, 0))
    return encodeURIComponent(returnTo)
  return encodeURIComponent(props.location && props.location.state && props.location.state.referrer ?
    props.location.state.referrer : envSettings.APP_BASEURL)
}

const accessPortal = (refreshAuth, history) => (
  async returnTo => {
    await refreshAuth()
    history.push(returnTo)
  }
)

const renderSigninForm = (returnTo, accessPortal) => (
  <SigninForm returnTo={returnTo} signinUrl={`${envSettings.API_BASEURL}/signin?returnTo=${returnTo}`} accessPortal={accessPortal} />
)

const Signin = props => (
  <Fragment>
    {props.userInfo ?
      (<Redirect to='/' />)
      :
      (<Fragment>
        {renderSigninForm(getReturnTo(props), accessPortal(props.refreshAuth, props.history))}
        <Container>
          <Row>
            <Col md={{ size: 8, offset: 2 }} className="text-center my-2">
              <RecaptchaTerms />
            </Col>
          </Row>
        </Container>
      </Fragment>)
    }
  </Fragment>
)

export default props => (
  <AuthConsumer>
    {({ userInfo, refreshAuth }) => <Signin {...props} userInfo={userInfo} refreshAuth={refreshAuth} />}
  </AuthConsumer>
)