import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types';

class CategoriesItem extends Component {

  state = { isOpen: true }

  componentDidUpdate(prevProps) {
    if (prevProps.params.catid !== this.props.params.catid) {
      const { data, params: { catid } } = this.props
      if (data.parentId === catid || catid === 'all') {
        this.setState({ isOpen: true })
      }
      else this.setState({ isOpen: false })
    }
  }

  handleCollapse = () => this.setState(prevState => ({
    isOpen: !prevState.isOpen
  }));

  render() {

    const { data, data: { categories }, unitid, params: { titleid, catid }, onlyCat, getLink } = this.props,
      { isOpen } = this.state

    const active = data.parentId === catid || catid === 'all' || !catid,
      categoriesLength = categories.length === 1 && catid !== 'all'

    return (
      <ul id={data.parentId} className={`${onlyCat ? 'card' : ''} ${!active ? 'categories__withOpacity' : ''} m-0 p-3 mb-2 categories__item`}>
        <li className="m-0 p-0">
          <span
            onClick={this.handleCollapse}
            className={`mr-2 glyphicon ${!isOpen ? 'glyphicon-triangle-right' : 'glyphicon-triangle-bottom'}`}>
          </span>
          <NavLink
            className={`${active ? 'secondaryActive' : ''}`}
            to={{
              pathname: getLink(titleid, unitid, data.parentId, 'all'),
              search: `?initTab=0`
            }}
          >
            {data.parentName}
          </NavLink>

          {isOpen &&
            <ul className="m-0 p-0 categories__resource">
              {categories.map((data, idx) => (
                <li className="m-3 p-0 " key={idx}>
                  <span className="mr-2 align-middle">└</span>
                  <NavLink
                    className={`${categoriesLength ? 'onlyActive' : ''}`}
                    to={{
                      pathname: getLink(titleid, unitid, data.parentId, data.categoryId),
                      search: `?initTab=0`
                    }}
                  >
                    {data.name}
                  </NavLink>
                </li>
              ))}

            </ul>
          }
        </li>

      </ul>
    );
  }
}

const Categories = (props) => {

  const { filteredResults, params, onlyCat, getLink } = props

  const unitid = params.unitid ? params.unitid : 'all'
  const selected = filteredResults.find(data => data.unitUuid === unitid)

  if(selected) return (

    <div className={`${onlyCat ? 'card-columns' : ''} categories p-3 m-0"`}>
      {selected.parentIds.map((data, idx) => (
        <CategoriesItem
          key={idx}
          data={data}
          unitid={unitid}
          params={params}
          onlyCat={onlyCat}
          getLink={getLink}
        />
      ))}
    </div>
  )
  else return null
};

Categories.propTypes = {
  filteredResults: PropTypes.array,
  onlyCat: PropTypes.bool,
  params: PropTypes.object,
  getLink: PropTypes.func.isRequired
};

Categories.defaultProps = {
  filteredResults: [],
  getLink: (titleid, unitid, catid, subcatid) => `/title/${titleid}/unit/${unitid ? unitid : 'all'}/cat/${catid ? catid : 'all'}/subcat/${subcatid ? subcatid : 'all'}`
};

export default Categories
