import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { ContentConsumer } from '../../language'
import { Container, Row, Col, Form, FormGroup, Input, FormFeedback, Progress, Alert } from 'reactstrap'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import ButtonThirdParties from '../ButtonThirdParties/ButtonThirdParties'
import PropTypes from 'prop-types'
import axios from 'axios'
import axiosConfig from '../../config/axios'
import _ from 'lodash'
import envSettings from '../../globals/envSettings'
import validator from 'validator'

class SigninForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mail: '',
      password: '',
      loading: false,
      failure: 0,
      errors: {
        mail: false,
        password: false
      }
    }
  }

  validateForm = () => !_.compact(_.values(this.state.errors)).length && (this.state.mail !== '') && (this.state.password !== '')

  handleChange = ({ target: { name, value } }) => {
    this.setState(prevState => (
      {
        [name]: name === 'mail' ? value.trim() : value,
        errors: {
          ...prevState.errors,
          [name]: !(value.length > 0) || (name === 'mail' && !validator.isEmail(value.trim()))
        },
        failure: 0
      }
    ))
  }

  handleEnter = e => {
    if (e.key === 'Enter') this.handleSubmit(e)
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.validateForm()) {
      this.setState({ loading: true }, async () => {
        window.scrollTo(0, 0)
        const { mail, password } = this.state
        const token = await this.props.googleReCaptchaProps.executeRecaptcha('signin')
        return axios.create(axiosConfig).post(this.props.signinUrl, { mail, password, token })
          .then(response => {
            if (this.props.oauth2) {
              this.props.accessPortal()
            } else {
              const returnTo = response && response.data && response.data.returnUrl ?
                _.startsWith(response.data.returnUrl, envSettings.APP_BASEURL, 0) ?
                  _.replace(response.data.returnUrl, new RegExp(`^${envSettings.APP_BASEURL}($|/)`), '/') : '/' : '/'
              this.props.accessPortal(returnTo)
            }
          })
          .catch(error => {
            console.error('KO SIGNIN error :', error)
            this.setState({
              failure: error.response && error.response.status ? error.response.status : 500, loading: false, mail: '', password: ''
            })
          })
      })
    }
  }

  render() {
    const { mail, password, loading, failure, errors } = this.state
    return (
      <ContentConsumer>
        {({ content }) => content && (
          <Fragment>
            {loading && <Progress animated color="primary" value="100">{content.sharedTerms.LOADING_MESSAGE}</Progress>}
            {failure > 0 && <Alert color="danger">{failure === 500 ? content.signin.signin_error_generic : content.signin.signin_error_credentials}</Alert>}
            <Container fluid={false} className="SigninForm">
              {this.props.oauth2 && (
                <Row>
                  <Col md={{ size: 8, offset: 2 }} className="text-center">
                    <h1 className="h1__oauth2">{content.oauth.signin_title}</h1>
                    <p className="p__oauth2">{content.oauth.signin_subtitle}</p>
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={{ size: 6, offset: 3 }} className="text-center">
                  <h1>{content.signin.signin_title}</h1>
                </Col>
              </Row>
              {/*<Row className="mt-3">
                <Col md={{ size: 6, offset: 3 }} className="text-left">
                  <h6>{content.signin.signin_with}</h6>
                </Col>
              </Row>*/}
              <Row>
                <Col md={{ size: 6, offset: 3 }}>
                  <Container>
                    <Row>
                      <Col className="mt-4">
                        <ButtonThirdParties id="google" returnTo={this.props.returnTo} routeOrigin="SIGNIN" userPlatform={envSettings.PLATFORM_GOOGLE_ID} disabled={false} text={content.signin.signin_with} />
                        {/* <Button id="google" type="button" className="img-container" outline color="primary" size="sm" onClick={() => this.handleConnect('google')}><img src={google} alt="google" /></Button>*/}
                      </Col>
                      <Col className="mt-4">
                        <ButtonThirdParties id="microsoft" returnTo={this.props.returnTo} routeOrigin="SIGNIN" userPlatform={envSettings.PLATFORM_MICROSOFT_ID} disabled={false} text={content.signin.signin_with} />
                        {/*<Button id="microsoft" href={`${envSettings.API_BASEURL}/signin/microsoft`} className="img-container" outline color="primary" size="sm" ><img src={microsoft} alt="microsoft" /></Button>*/}
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md={{ size: 6, offset: 3 }} className="text-left">
                  {content.signin.signin_account}
                </Col>
              </Row>
              <Form noValidate onSubmit={this.handleSubmit}>
                <Row className="mt-1">
                  <Col md={{ size: 6, offset: 3 }}>
                    <FormGroup>
                      <Input
                        type="email"
                        id="mail"
                        name="mail"
                        placeholder={content.signin.signin_mail}
                        value={mail}
                        tabIndex="1"
                        onChange={this.handleChange}
                        valid={!errors.mail && mail !== ''}
                        invalid={errors.mail}
                      />
                      <FormFeedback>{content.signin.signin_error_mail}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="password"
                        id="password"
                        name="password"
                        placeholder={content.signin.signin_password}
                        value={password}
                        tabIndex="2"
                        onChange={this.handleChange}
                        onKeyPress={this.handleEnter}
                        valid={!errors.password && password !== ''}
                        invalid={errors.password}
                      />
                      <FormFeedback>{content.signin.signin_error_password}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={{ size: 6, offset: 3 }}>
                    <Link className="link__forgotpassword" to="/forgot-password" target={this.props.oauth2 ? '_blank' : '_self'}>
                      {content.signin.signin_forgot_password}
                    </Link>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={{ size: 6, offset: 3 }} className="text-center">
                    <Link className="link__help" to={this.props.oauth2 ? '/blinkcpt-help' : '/help'} target={this.props.oauth2 ? '_blank' : '_self'}>
                      {content.sharedTerms.HELP_LINK}<span className="glyphicon glyphicon-question-sign"></span>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ size: 6, offset: 3 }}>
                    <Row>
                      <Col className="mt-4">
                        <ButtonPremium tag={Link} to={this.props.oauth2 ? `/register?returnTo=${this.props.returnTo}` : '/register'}>
                          {content.signin.signin_register_button}
                        </ButtonPremium>
                      </Col>
                      <Col className="mt-4">
                        <ButtonPremium
                          type="submit"
                          disabled={mail === '' || password === '' || errors.mail || errors.password || loading || failure > 0}
                          tabIndex="3" primary>{content.signin.signin_button}</ButtonPremium>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Container>
          </Fragment>
        )}
      </ContentConsumer>
    )
  }
}

SigninForm.propTypes = {
  signinUrl: PropTypes.string.isRequired,
  accessPortal: PropTypes.func.isRequired,
  oauth2: PropTypes.bool
}

SigninForm.defaultProps = {
  oauth2: false
}

export default withRouter(withGoogleReCaptcha(SigninForm))