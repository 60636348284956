import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { AuthConsumer } from 'react-check-auth'
import { ContentConsumer } from '../../language'
import { Container, Row, Col, Progress } from 'reactstrap'
import SigninForm from '../SigninForm/SigninForm.js'
import { RecaptchaTerms } from '../Recaptcha/Recaptcha'
import qs from 'qs'
import validator from 'validator'
import { blinkCptRoles } from '../../globals/userRoles'
import envSettings from '../../globals/envSettings'

// MBQ TODO Hide Premium Header and Footer
// MBQ TODO Add 3rd parties buttons: Google & Microsoft

class OAuth2Signin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      client_id: '',
      redirect_uri: '',
      blink_state: '',
      loading: true
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const { client_id, redirect_uri, state } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    if (client_id && redirect_uri && validator.isURL(redirect_uri)) {
      state ?
        this.setState({ client_id, redirect_uri: encodeURIComponent(redirect_uri), blink_state: encodeURIComponent(state), loading: false })
        :
        this.setState({ client_id, redirect_uri: encodeURIComponent(redirect_uri), loading: false })
    } else {
      this.setState({ loading: false })
    }
  }

  redirectSessionUser = role => (
    blinkCptRoles.includes(role) ?
      <Redirect to={`/oauth2-authorize?client_id=${this.state.client_id}&redirect_uri=${this.state.redirect_uri}${this.state.blink_state ? '&state=' + this.state.blink_state : ''}`} />
      :
      <Redirect to='/oauth2-error' />
  )

  getReturnTo = () => (
    `${envSettings.APP_BASEURL}${this.props.location.pathname}?client_id=${this.state.client_id}&redirect_uri=${this.state.redirect_uri}${this.state.blink_state ? '&state=' + this.state.blink_state : ''}`
  )

  renderSigninForm = (returnTo, refreshAuth) => (
    <SigninForm returnTo={encodeURIComponent(returnTo)} signinUrl={`${envSettings.API_BASEURL}/signin?returnTo=${returnTo}`} accessPortal={refreshAuth} oauth2={true} />
  )

  render() {
    const { client_id, redirect_uri, loading } = this.state
    return (
      <AuthConsumer>
        {({ userInfo, refreshAuth }) => (
          <ContentConsumer>
            {({ content }) => content && (
              <Fragment>
                {loading && <Progress animated color="primary" value="100">{content.sharedTerms.LOADING_MESSAGE}</Progress>}
                {!loading && (!client_id || !redirect_uri) && <Redirect to='/oauth2-error' />}
                {client_id && redirect_uri && (
                  userInfo ?
                    (this.redirectSessionUser(userInfo.role))
                    :
                    (<Fragment>
                      {this.renderSigninForm(this.getReturnTo(), refreshAuth)}
                      <Container>
                        <Row>
                          <Col md={{ size: 8, offset: 2 }} className="text-center my-2">
                            <RecaptchaTerms />
                          </Col>
                        </Row>
                      </Container>
                    </Fragment>)
                )}
              </Fragment>
            )}
          </ContentConsumer>
        )}
      </AuthConsumer>
    )
  }
}

export default OAuth2Signin