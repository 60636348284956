import axios from 'axios';
import axiosConfig from '../../config/axios'

import {
    USER_ACTIONS_IMPERSONATE_POST,
    STUDENTS_ZONE_SHARE_TITLE,
    STUDENTS_ZONE_GLOBAL_VALUES,
    STUDENTS_ZONE_TOKEN_VALUES,
    STUDENTS_ZONE_BLOCK_TOKEN,
    CUSTOMER_SUPPORT_USERS,
    CUSTOMER_SUPPORT_USER_ID,
    CUSTOMER_SUPPORT_DELETE_USER,
    CUSTOMER_SUPPORT_USER_PUT,
    CUSTOMER_SUPPORT_EMAIL_PUT,
    CUSTOMER_SUPPORT_USER_POST,
    CUSTOMER_SUPPORT_TITLES,
    CUSTOMER_SUPPORT_LIBRARY,
    CUSTOMER_SUPPORT_LIBRARY_PUT,
    CUSTOMER_SUPPORT_USER_ACTIONS,
    CUSTOMER_SUPPORT_USER_BLINK,
    CUSTOMER_SUPPORT_USER_COUNT,
    CUSTOMER_SUPPORT_USERS_COUNT_BY_ROLE,
    CUSTOMER_SUPPORT_USER_LICENSES,
	CUSTOMER_SUPPORT_STUDENT_LOG,
	INTERNAL_SUPPORT_USERS,
    INTERNAL_SUPPORT_USER_ID,
    INTERNAL_SUPPORT_USER_ID_PUT,
    INTERNAL_SUPPORT_STATUS,
    INTERNAL_SUPPORT_USER_ACTIONS,
    INTERNAL_SUPPORT_USER_POST,
    CUSTOMER_SUPPORT_ADOPTION_LANGUAGE,
    CUSTOMER_SUPPORT_ADOPTION_EDITIONS,
    CUSTOMER_SUPPORT_SCHOOL_REGIONS,
    INVOICE_INVOICES_PER_YEAR,
    INVOICE_INVOICES_PER_ID,
    INVOICE_CREATE_INVOICE,
    INVOICE_LASALLE_PER_YEAR,
    INVOICE_LASALLE_PER_ID,
    INVOICE_LASALLE_CREATE_INVOICE,
    PRODUCT_ACCESS_CONTROL_SEARCH,
    PRODUCT_ACCESS_CONTROL_OPERATION
} from '../../../src/api/EndPoints'

import envSettings from '../../globals/envSettings'

export const userService = {
    getCustomerSupportData,
    getCustomerSupportDataById,
    deleteCustomerSupportDataById,
    putCustomerSupportDataById,
    putEmailCustomerSupportDataById,
    postCustomerSupportData,
    getCustomerSupportTitles,
    getCustomerSupportLibraryById,
    putCustomerSupportLibraryById,
    getInternalSupportUserData,
    getInternalSupportUserDataById,
    putInternalSupportUserDataById,
    putInternalSupportDataStatusById,
    postInternalSupportUserData,
    getInvoicesYear,
    getInvoiceId,
    putCreateInvoice,
    searchProductAccessControl,
    doProductAccessControlOperation
};
const axiosInstance = axios.create(axiosConfig)
//let REACT_APP_API = process.env.REACT_APP_API || "https://api.oxfordpremium.dev.oupe.es";
let REACT_APP_API = envSettings.API_BASEURL

//Customer Support 
export function postImpersonateUser(payload) {
    return postApicall(REACT_APP_API + USER_ACTIONS_IMPERSONATE_POST, payload);
}

export function getCustomerSupportData(query) {
    return getApiCall(REACT_APP_API + CUSTOMER_SUPPORT_USERS + query);
}

export function getCustomerSupportDataById(userId) {
    return getApiCall(REACT_APP_API + CUSTOMER_SUPPORT_USER_ID.replace('{userId}', userId));
}

export function getCustomerSupportDataActions(userId) {
    return getApiCall(REACT_APP_API + CUSTOMER_SUPPORT_USER_ACTIONS.replace('{userId}', userId));
}

export function getCustomerSupportDataBlink(userId) {
    return getApiCall(REACT_APP_API + CUSTOMER_SUPPORT_USER_BLINK.replace('{userId}', userId));
}

export function getCustomerSupportCount(userRole) {
    return getApiCall(REACT_APP_API + CUSTOMER_SUPPORT_USER_COUNT.replace('{userRole}', userRole));
}

export function getCustomerSupportDataSchoolRegions() {
    return getApiCall(REACT_APP_API + CUSTOMER_SUPPORT_SCHOOL_REGIONS);
}

export function getCustomerSupportUsersCountByRole() {
    return getApiCall(REACT_APP_API + CUSTOMER_SUPPORT_USERS_COUNT_BY_ROLE);
}

export function deleteCustomerSupportDataById(userId) {
    return deleteApiCall(REACT_APP_API + CUSTOMER_SUPPORT_DELETE_USER.replace('{userId}', userId));
}

export function putCustomerSupportDataById(payload) {
    console.log(payload)
    return putApiCall(REACT_APP_API + CUSTOMER_SUPPORT_USER_PUT.replace('{userId}', payload.userId), removeIdFromPayload(payload));
}

const removeIdFromPayload = ({ userId, ...rest }) => rest

export function putEmailCustomerSupportDataById(payload) {
    let query = '?action=' + payload.action;
    return putApiCall(REACT_APP_API + CUSTOMER_SUPPORT_EMAIL_PUT.replace('{userId}', payload.userId) + query);
}

export function postCustomerSupportData(payload) {
    return postApicall(REACT_APP_API + CUSTOMER_SUPPORT_USER_POST, payload);
}

export function getCustomerSupportTitles(data) {
    let query = '?name=' + data;
    return getApiCall(REACT_APP_API + CUSTOMER_SUPPORT_TITLES + query);
}

export function getCustomerSupportAdoptionLanguage() {
    // We introduce a delay to prove https://oupespain.atlassian.net/browse/PREM-3623
    /*return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(getApiCall(REACT_APP_API + CUSTOMER_SUPPORT_ADOPTION_LANGUAGE))
        }, 2000)
      })*/

    return getApiCall(REACT_APP_API + CUSTOMER_SUPPORT_ADOPTION_LANGUAGE)
}

export function getCustomerSupportAdoptionEditons() {    
    return getApiCall(REACT_APP_API + CUSTOMER_SUPPORT_ADOPTION_EDITIONS);
}

export function getCustomerSupportLibraryById(userId) {
    return getApiCall(REACT_APP_API + CUSTOMER_SUPPORT_LIBRARY.replace('{userId}', userId));
}

export function getSZShareTitletData(query) {
    return getApiCall(REACT_APP_API + STUDENTS_ZONE_SHARE_TITLE + query);
}

export function getSZGlobalValues() {
    return getApiCall(REACT_APP_API + STUDENTS_ZONE_GLOBAL_VALUES);
}

export function getSZTokenValues(token) {
    return getApiCall(REACT_APP_API + STUDENTS_ZONE_TOKEN_VALUES.replace('{token}', token));
}

export function putSZGlobalValues(payload) {
    return putApiCall(REACT_APP_API + STUDENTS_ZONE_GLOBAL_VALUES, payload);
}

export function putSZTokenValues(payload, token) {
    return putApiCall(REACT_APP_API + STUDENTS_ZONE_TOKEN_VALUES.replace('{token}', token), payload);
}

export function putSZBlockToken(payload, token) {
    return putApiCall(REACT_APP_API + STUDENTS_ZONE_BLOCK_TOKEN.replace('{token}', token), payload);
}

export function putCustomerSupportLibraryById(payload) {
    return putApiCall(REACT_APP_API + CUSTOMER_SUPPORT_LIBRARY_PUT.replace('{userId}', payload.userId), removeIdFromPayload(payload));
}

export function getCustomerSupportUserLicenses(userId){
  console.log("userId",userId)
  console.log(REACT_APP_API+CUSTOMER_SUPPORT_USER_LICENSES.replace('{userId}',userId))
  return getApiCall(REACT_APP_API+CUSTOMER_SUPPORT_USER_LICENSES.replace('{userId}',userId))
}

//Internal Support
export function getInternalSupportUserData(query) {
    return getApiCall(REACT_APP_API + INTERNAL_SUPPORT_USERS + query);
}

export function getInternalSupportUserDataById(userId) {
    return getApiCall(REACT_APP_API + INTERNAL_SUPPORT_USER_ID.replace('{userId}', userId));
}

export function getInternalSupportDataActions(userId) {
    return getApiCall(REACT_APP_API + INTERNAL_SUPPORT_USER_ACTIONS.replace('{userId}', userId));
}

export function putInternalSupportUserDataById(payload) {
    console.log(payload)
    return putApiCall(REACT_APP_API + INTERNAL_SUPPORT_USER_ID_PUT.replace('{userId}', payload.userId), removeIdFromPayload(payload));
}

export function putInternalSupportDataStatusById(payload) {
    let query = '?active=' + payload.active;
    return putApiCall(REACT_APP_API + INTERNAL_SUPPORT_STATUS.replace('{userId}', payload.userId) + query);
}

export function postInternalSupportUserData(payload) {
    console.log(payload)
    return postApicall(REACT_APP_API + INTERNAL_SUPPORT_USER_POST, payload);
}
export function getStudentLog(token){
	return getApiCall(REACT_APP_API+CUSTOMER_SUPPORT_STUDENT_LOG.replace('{/token}',token?'/'+token:''))
}
// Invoices Blink
export function getInvoicesYear(year) {
  return getApiCall(REACT_APP_API + INVOICE_INVOICES_PER_YEAR.replace('{year}', year));
}

export function getInvoiceId(id) {
  return getApiCall(REACT_APP_API + INVOICE_INVOICES_PER_ID.replace('{id}', id));
}

export function putCreateInvoice(payload) {
  console.log(payload)
  return putApiCall(REACT_APP_API + INVOICE_CREATE_INVOICE, payload);
}

// Invoices LaSalle
export function getInvoicesLaSalleYear(year) {
  return getApiCall(REACT_APP_API + INVOICE_LASALLE_PER_YEAR.replace('{year}', year));
}

export function getInvoiceLaSalleId(id) {
  return getApiCall(REACT_APP_API + INVOICE_LASALLE_PER_ID.replace('{id}', id));
}

export function putCreateInvoiceLaSalle(payload) {
  console.log(payload)
  return putApiCall(REACT_APP_API + INVOICE_LASALLE_CREATE_INVOICE, payload);
}

// Product Access Control
export function searchProductAccessControl(payload) {
    //console.log('payload ', payload)
    //console.log('url ', REACT_APP_API + PRODUCT_ACCESS_CONTROL_SEARCH)
    return postApicall(REACT_APP_API + PRODUCT_ACCESS_CONTROL_SEARCH, payload)
}

export function doProductAccessControlOperation(productKey, operation, payload) {
    //console.log('productKey ', productKey)
    //console.log('operation ', operation)
    //console.log('payload ', payload)
    //console.log('url ', REACT_APP_API + PRODUCT_ACCESS_CONTROL_OPERATION.replace('{productKey}', productKey.replaceAll('#', '/')).replace('{operation}', operation))
    return postApicall(
        REACT_APP_API + PRODUCT_ACCESS_CONTROL_OPERATION
            .replace('{productKey}', productKey
                .replaceAll('#', '/'))
            .replace('{operation}', operation),
        payload
    )
}

function getOptions() {
    let options = {};
    if (localStorage.getItem('token')) {
        options.headers = { 'x-access-token': localStorage.getItem('token') };
    }
    return options;
}

// GET API CALL
function getApiCall(url) {
    return axiosInstance.get(url, getOptions()).then((response) => {
        return response;
    }).catch((err) => {
        console.log(err.response);
        throw err
    })
}

// POST API CALL
function postApicall(url, payload) {
    return axiosInstance.post(url, payload, getOptions()).then((response) => {
        return response;
    }).catch((err) => {
        console.log(err);
        throw err
    })
}

// PUT API CALL
function putApiCall(url, payload) {
    return axiosInstance.put(url, payload, getOptions()).then((response) => {
        return response;
    }).catch((err) => {
        console.log(err);
        throw err
    })
}

// DELETE API CALL
function deleteApiCall(url) {
    return axiosInstance.delete(url, getOptions()).then((response) => {
        return response;
    }).catch((err) => {
        console.log(err);
        throw err
    })
}