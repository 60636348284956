import React from 'react'

import { Col } from 'reactstrap'

import './InfoBox.css'

const InfoBox = (props) => {
  const { label, body, linkText, onClick } = props

  return (
    <Col
      className="infobox mb-md-4"
      md="6"
      lg="3"
    >
      <div className="infobox-header d-flex"><div className="m-auto">{ label }</div></div>
      <div className="infobox-card">
        <div className="infobox-body">{ body }</div>
        <div
          className="infobox-link"
          role="link"
          onClick={onClick}
          onKeyDown={onClick}
          tabIndex={0}
        >
          <div className="m-auto">{ linkText }</div>
        </div>
      </div>
    </Col>
  )
}

export default InfoBox
