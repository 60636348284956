import React, { Component } from 'react'
import { AuthConsumer } from 'react-check-auth'
import { Redirect } from 'react-router-dom'
import { withContent } from '../../utils/hocs'
import PHPShowElement from './PHPShowElement'

class PublicHomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { locale, content } = this.props
    return (
      <AuthConsumer>
        {({ userInfo }) => (
          userInfo ?
            (<Redirect to='/' />)
            :
            (<div className="homepage">
              <PHPShowElement language={locale} content={content} />
            </div>)
        )}
      </AuthConsumer>
    )
  }
}

export default withContent(PublicHomePage)