import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'

class SearchInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value,
    }
  }

  render() {
    const { id, name, value, className, onChange } = this.props

    return (
      <div className="search-input">
        <span className="glyphicon glyphicon-search"></span>
        <Input
          id={ id }
          type="text"
          name={ name }
          className={ className }
          autoComplete="off"
          value={ value }
          onChange={ (e) => {
            e.preventDefault()
            onChange(e.target.value)
          }}
        />
      </div>
    )
  }
}

SearchInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default SearchInput
