import React, { Component, Fragment } from 'react'
import { ContentConsumer } from '../../language'
import { AuthConsumer } from 'react-check-auth'

class PlusLibrary extends Component {
  render() {
    return (
      <AuthConsumer>
        {({ userInfo }) => {
          return <ContentConsumer>
            {({ content }) => (content && (
              <Fragment>
                <h3>PLUS LIBRARY</h3>
                <span>{`${userInfo.name} ${userInfo.surname} ${userInfo.surname2}`}</span>
              </Fragment>
            ))}
          </ContentConsumer>
        }}
      </AuthConsumer>
    )
  }
}

export default PlusLibrary