import React from 'react'

const GpaTitleView = ({ label, count = 0 }) => (
  <div className="text-center">
    {
      (count > 0) ?
        <h2>{label}&nbsp;<span className="badge number-adopcion">{count}</span></h2>
        :
        <h2>{label}</h2>
    }
  </div>
)

export default GpaTitleView