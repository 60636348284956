import React, { Fragment } from 'react'
import { Container, Row, Col, Card, CardTitle, CardText, CardFooter, CardLink } from 'reactstrap'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import { ContentConsumer } from '../../language'
import { moreInformationUrl } from './settings'
import { getTitle, getSubTitle, adjustCards, TABS } from './utils'
import { parseToReactElement } from '../../utils/html'

const PDCatalogue = ({ announcement, cards, activeTab, className, showRegistration, isCurrentRegistration }) => {
  const styleCardsCol = adjustCards(cards)
  return (
    <ContentConsumer>
      {({ content = {}, locale }) => (
        content && (
          <Container fluid={false} className={className}>
            <Row>
              <Col className="text-center pt-5">
                <h5>{getTitle(announcement, isCurrentRegistration, content.pdcourses)}</h5>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <h5>{getSubTitle(announcement, isCurrentRegistration, content.pdcourses)}</h5>
              </Col>
            </Row>

            <Row className="pt-5">
              <Fragment>
                {cards.length === 2 && <Col lg="2" className="col-cards-lg"><p>&nbsp;</p></Col>}
                {cards.map(card => (
                  <Col sm="12" md="6" lg="4" className={styleCardsCol} key={card.masterCourse}>
                    {/*<Col sm="12" md="6" lg="4" className="text-left" key={card.masterCourse}>*/}
                    <Card body className={`${className}__card pt-4 pb-3 px-4 mb-3`}>
                      <CardTitle tag="h5" className="pb-2">{parseToReactElement(card.title)}</CardTitle>
                      <CardText tag="div" className="pb-4 pt-4">{parseToReactElement(card.content, null, { FORBID_ATTR: ['style', 'class'] })}</CardText>
                      <CardFooter>
                        <CardLink href={moreInformationUrl(locale)} target="_blank">{content.pdcourses.more_info}</CardLink>
                      </CardFooter>
                    </Card>
                  </Col>
                ))}
                {cards.length === 2 && <Col lg="2" className="col-cards-lg"><p>&nbsp;</p></Col>}
              </Fragment>
            </Row>

            {
              activeTab === TABS.ANNOUNCEMENT ?
                (
                  <Row className="pt-5 pb-5">
                    <Col md={{ size: 4, offset: 4 }} className="text-center">
                      <ButtonPremium onClick={showRegistration}
                        disabled={!announcement || !announcement.registrationOn}
                        primary
                      >
                        {content.pdcourses.button_register}
                      </ButtonPremium>
                    </Col>
                  </Row>
                )
                :
                (
                  <Row className="pt-0 pb-3">
                    <Col><p>&nbsp;</p></Col>
                  </Row>
                )
            }
          </Container>
        ))}
    </ContentConsumer>
  )
}

export default PDCatalogue