// MBQ 07/06/2021: New version
//import React, { Component } from 'react'
import React from 'react'
import Son from '../Son/Son'
import { ContentConsumer } from '../../language'
import { TabContent, Nav, NavItem, NavLink, Button } from 'reactstrap'

/*
class Sons extends Component {

  state = { activeTab: null }
  
  componentDidMount(){
    this.props.hijos.length === 0 && this.props.addSon();
    this.setState({ activeTab: this.props.activeChild })
  }
  componentDidUpdate(prevProps) {    
    if (this.props.activeChild !== prevProps.activeChild) {
      this.setState({ activeTab: this.props.activeChild })
    }
  }

  toggle(tab) {
    const stringTab = tab.toString()

    if(this.state.activeTab !== stringTab){
      this.setState({
        activeTab: stringTab
      });
    }    
  }
  
  render() {
*/
const Sons = props => {
  const { hijos, activeChild, actionTab, addSon } = props
  return (
    <ContentConsumer>
      {({ content }) => content && (
        <div className="sons">
          {/*
          {this.props.hijos &&
              (
                <Nav tabs>
                  {this.props.hijos.map(hijo => (
                    <NavItem key={hijo.numero_hijo} >
                      <NavLink
                        className={`position-relative ${this.state.activeTab === hijo.numero_hijo.toString() ? 'active' : ''} mr-3 sons__tab`}                        
                        onClick={() => { this.toggle(hijo.numero_hijo); }}
                      >
                        {content.gratishp.gratishp_tab_label + ' '}{hijo.t_nombre}
                        <span className="sons__delete position-absolute" onClick={() =>this.props.deleteSon(hijo)} >X</span>
                      </NavLink>
                    </NavItem>
                  ))}

                  {this.props.hijos.length < 3 &&
                    <Button
                      onClick={this.props.addSon}
                      color="primary">
                      {content.gratishp.gratishp_add_son_label}
                    </Button>
                  }                  
                </Nav>
              )
            }
          */}
          {hijos && (
            <Nav tabs>
              {hijos.map(hijo => (
                <NavItem key={hijo.numero_hijo}>
                  <NavLink
                    className={`position-relative ${activeChild === hijo.numero_hijo.toString() ? 'active' : ''} mr-3 sons__tab`}
                    onClick={() => actionTab(hijo.numero_hijo)}
                  >
                    {hijo.t_nombre && hijo.t_nombre.trim() ? hijo.t_nombre.trim().toUpperCase() : content.gratishp.gratishp_tab_label}
                    <span className="sons__delete position-absolute" onClick={() => actionTab(hijo.numero_hijo, true)}>X</span>
                  </NavLink>
                </NavItem>
              ))}
              <Button onClick={addSon} color="primary">
                {content.gratishp.gratishp_add_son_label}
              </Button>
            </Nav>
          )}

          {/*
          { this.props.hijos.length > 0 &&
              
              <TabContent className="sons__pane p-2" activeTab={this.state.activeTab}>
                {this.props.hijos.length===1&&this.state.activeTab!==this.props.hijos[0].numero_hijo.toString()&&
                 this.setState({activeTab:this.props.hijos[0].numero_hijo.toString()})
                }                
                { this.props.hijos.map(hijo => (
                                        
                  <Son
                    key={hijo.numero_hijo}
                    hijo={hijo}
                    deleteSon={this.props.deleteSon}
                    handleChange={this.props.handleChange}
                    listas={this.props.listas.filter(lista => lista.numero_hijo === hijo.numero_hijo)}
                    addTitle={this.props.addTitle}
                    deleteTitle={this.props.deleteTitle}
                    handleFileUpload={this.props.handleFileUpload}
                    defaultImage={this.props.defaultImage} 
                    error={this.props.error}
                    zipCodeError={this.props.zipCodeError}
                    componentesError={this.props.componentesError}
                    levelError={this.props.levelError}
                    wrongZipCode={this.props.wrongZipCode}
                    libroFamiliaError={this.props.libroFamiliaError}    
                    searchFlags={this.props.searchFlags}
                    noAdoptionsError={this.props.noAdoptionsError}                
                    />

                ))}
              </TabContent>
            }
          */}
          {hijos.length > 0 && (
            <TabContent className="sons__pane p-2" activeTab={activeChild}>
              {/* MBQ TODO Propagate from parent component only current child's props, instead of all children props */}
              {hijos.map(hijo => (
                <Son
                  key={hijo.numero_hijo}
                  hijo={hijo}
                  listas={props.listas.filter(lista => lista.numero_hijo === hijo.numero_hijo)}
                  handleChange={props.handleChange}
                  handleFileUpload={props.handleFileUpload}
                  addTitle={props.addTitle}
                  deleteTitle={props.deleteTitle}
                  defaultImage={props.defaultImage}
                  searchFlags={props.searchFlags}
                  error={props.error}
                  zipCodeError={props.zipCodeError}
                  componentesError={props.componentesError}
                  levelError={props.levelError}
                  wrongZipCode={props.wrongZipCode}
                  libroFamiliaError={props.libroFamiliaError}
                  noAdoptionsError={props.noAdoptionsError}
                />
              ))}
            </TabContent>
          )}
        </div>
      )}
    </ContentConsumer>
  )
/*  } */
}

export default Sons