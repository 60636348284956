import axios from 'axios'
import axiosConfig from '../config/axios'

const getCMSContent = {
  getCMSContent: (err,pageid,language,cache,cancelToken,callback) => {
    if(err) callback(err)
    else {
      const axiosInstance = axios.create(axiosConfig)
      if(pageid && language) {
        let queryString = ''
        if(!cache) queryString = '?cache=false'
        axiosInstance.get(`/webContent/${pageid}/${language}${queryString}`, { cancelToken })
          .then((response) => {
            callback(null, response)
          })
          .catch((error) => {
            callback(error)
          })
      } else {
        callback(new Error("Parameter missing"))
      }
    }
  }
}

export default getCMSContent
