import React from 'react'
import { AuthConsumer } from 'react-check-auth'
import { ContentConsumer } from '../../language'
import ChangeSchoolFinder from '../ChangeSchoolFinder/ChangeSchoolFinder'
import ChangeSchoolCenterData from '../ChangeSchoolCenterData/ChangeSchoolCenterData'
import ChangeSchoolError from '../ChangeSchoolError/ChangeSchoolError'
import ChangeSchoolSuccess from '../ChangeSchoolSuccess/ChangeSchoolSuccess'
import userRoles from '../../globals/userRoles'
import changeschoolConstants from './changeschoolConstants'
import { Alert } from 'reactstrap';

class ChangeSchool extends React.Component {
  constructor() {
    super();
    this.state = {
      code: null,
      status: changeschoolConstants.NO_SEARCH,
      data: null,
    }
    this.handler = this.handler.bind(this);
    this.specificValidationAboutNewCenter = this.specificValidationAboutNewCenter.bind(this)
  }

  handler(new_data, new_status) {
    this.setState({ data: new_data, status: new_status })
  }

  specificValidationAboutNewCenter(status, userInfo) {
    if ((status === changeschoolConstants.OK_LOCATE) && (userInfo.schoolId === this.state.data.c_centro)) {
      return changeschoolConstants.KO_SAMESCHOOL
    }

    if ((status === changeschoolConstants.OK_LOCATE) && (userInfo.countryId !== this.state.data.c_pais)) {
      return changeschoolConstants.KO_OTHERCOUNTRY
    }

    return status
  }

  manageStatus(status, userInfo, content) {
    let component = null

    status = this.specificValidationAboutNewCenter(status, userInfo)

    switch (status) {
      case changeschoolConstants.NO_SEARCH:
        component =
          <div><ChangeSchoolFinder status={changeschoolConstants} action={this.handler} schoolName={userInfo.schoolName} schoolAddress={userInfo.ccaaName} schoolProvince={userInfo.countryName} /></div>
        break
      case changeschoolConstants.OK_LOCATE:
        component =
          <div><ChangeSchoolCenterData status={changeschoolConstants} idprofesor={userInfo.id} code={this.state.code} data={this.state.data} action={this.handler} /></div>
        break
      case changeschoolConstants.KO_LOCATE:
        component =
          <div><ChangeSchoolError status={changeschoolConstants} errorMessage={content.changeschool.changeschool_error_premiumcode_text} action={this.handler} /></div>
        break
      case changeschoolConstants.KO_SAMESCHOOL:
        component =
          <div><ChangeSchoolError status={changeschoolConstants} errorMessage={content.changeschool.changeschool_warning_samecenter_text} action={this.handler} /></div>
        break
      case changeschoolConstants.KO_OTHERCOUNTRY:
        component =
          <div><ChangeSchoolError status={changeschoolConstants} errorMessage={content.changeschool.changeschool_warning_othercountry_text} action={this.handler} /></div>
        break
      case changeschoolConstants.KO_CHANGESCHOOL:
      case changeschoolConstants.KO_MOVE:
      case changeschoolConstants.KO_PERMISION:
        component =
          <div><ChangeSchoolError status={changeschoolConstants} errorMessage={content.sharedTerms.ERROR_TEXT} action={this.handler} /></div>
        break
      case changeschoolConstants.SUCCESS:
        component =
          <div><ChangeSchoolSuccess /></div>
        break
      default:
        component =
          <div><ChangeSchoolFinder status={changeschoolConstants} action={this.handler} schoolName={userInfo.schoolName} schoolAddress={userInfo.ccaaName} schoolProvince={userInfo.countryName} /></div>
        break
    }
    return component
  }

  render() {
    const { status } = this.state
    return (
      <AuthConsumer>
        {({ userInfo }) => {
          if (!userInfo) {
            return null
          }
          return (<ContentConsumer>
            {({ content = {}, isContentLoading }) => (content && (
              userInfo.role !== userRoles.TEACHER ?

                <div className="alertMessage" ><Alert color="danger">{content.sharedTerms.ERROR_TEXT}</Alert></div>
                : <div>{this.manageStatus(status, userInfo, content)}</div>
            ))}
          </ContentConsumer>)
        }}
      </AuthConsumer>
    )
  }
}

export default ChangeSchool