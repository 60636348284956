import React from 'react'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { AuthConsumer } from 'react-check-auth'
import { ContentConsumer } from '../../language'
import ROLES from '../../globals/userRoles'
import { Alert } from 'reactstrap';
import activateLicenseConstants from'./activateLicencseConstants'
import{ActivateLicenseForm}from'../ActivateLicenseForm/ActivateLicenseForm'
import validator from 'validator'
import {validateLicense,activateLicense}from'../../services/wsLicense'
class ActivateLicense extends React.Component{
  constructor(props){
    super(props);
    this.state={
      code:'',
      status:activateLicenseConstants.NO_SEARCH,
      data:null,
      error:{code:false}
    }
    this.manageOnSubmit=this.manageOnSubmit.bind(this)
    this.manageOnCancel=this.manageOnCancel.bind(this)
    this.handleChangeCode=this.handleChangeCode.bind(this)
    this.handleStatus=this.handleStatus.bind(this)
  }
  goTo=(location)=>{
    const{history}=this.props
    history.push(location)
  }
  async manageOnSubmit(e){
    e.preventDefault()
    if(!this.state.error.code&&this.state.code!==''){
      let value=this.state.code.trim().replace(/-/g,"")
      if((validator.matches(value,/^[A-Za-z0-9]{13}$/)&&value.substr(0,3)==='OUP')||validator.matches(value,/^[0-9]{12}$/)){
        this.setState(state=>(({error:{...state.error,'code':false}})))
      }else{
        return this.setState(state=>(({error:{...state.error,'code':true}})))
      }
      if(this.state.status!==activateLicenseConstants.OK_LOCATE) {
        const token = await this.props.googleReCaptchaProps.executeRecaptcha('license')
        return validateLicense(value, token, (error,data)=>{
          if(data) return this.handleStatus(activateLicenseConstants.OK_LOCATE,data,{code:false})
          else return this.handleStatus(activateLicenseConstants.KO_LOCATE,null,{code:true})
        })
      }
      else {
        const token = await this.props.googleReCaptchaProps.executeRecaptcha('license')
        return activateLicense(this.state.code, token, (error,data)=>{
          if(data)return this.handleStatus(activateLicenseConstants.OK_ACTIVATION,data,{code:false})
          else return this.handleStatus(activateLicenseConstants.KO_ACTIVATION,null,{code:true})
        })
      }
    }
    return this.setState(state=>(({error:{...state.error,'code':true}})))
  }
  manageOnCancel(){
    if(this.state.status===activateLicenseConstants.OK_LOCATE){
      this.setState({code:''})
      return this.handleStatus(activateLicenseConstants.NO_SEARCH,null,{code:false})
    }
    return this.goTo("/")
  }
  handleChangeCode(e){
    let value=e.target.value.toUpperCase()
    return this.setState(state=>(({error:{...state.error,'code':false},code:value})))
  }
  handleStatus(status,data,error){
    this.setState({status:status,data:data,error:error})
  }
  manageStatus(status,content){
    let component=null
    switch(status){
      case activateLicenseConstants.NO_SEARCH:
        component=<ActivateLicenseForm
                    manageOnSubmit={this.manageOnSubmit}
                    manageOnCancel={this.manageOnCancel}
                    handleChangeCode={this.handleChangeCode}
                    code={this.state.code}
                    error={this.state.error}
                    buttonLabel={content.activateLicense.activar4}
                    buttonCancelLabel={content.activateLicense.cancelar5}
                    errorLabel={content.activateLicense.errorLabel6}
                    status={activateLicenseConstants.NO_SEARCH}/>
        break
      case activateLicenseConstants.OK_LOCATE:
        component=<ActivateLicenseForm
                    manageOnSubmit={this.manageOnSubmit}
                    manageOnCancel={this.manageOnCancel}
                    handleChangeCode={this.handleChangeCode}
                    code={this.state.code}
                    error={this.state.error}
                    buttonLabel={content.activateLicense.confirmar11}
                    buttonCancelLabel={content.activateLicense.atras10}
                    data={this.state.data}
                    status={activateLicenseConstants.OK_LOCATE}/>
        break
      case activateLicenseConstants.KO_LOCATE:
        component=<ActivateLicenseForm
                    manageOnSubmit={this.manageOnSubmit}
                    manageOnCancel={this.manageOnCancel}
                    handleChangeCode={this.handleChangeCode}
                    code={this.state.code}
                    error={this.state.error}
                    buttonLabel={content.activateLicense.activar4}
                    buttonCancelLabel={content.activateLicense.cancelar5}
                    errorLabel={content.activateLicense.errorLabel6}
                    status={activateLicenseConstants.KO_LOCATE}/>
        break
      case activateLicenseConstants.OK_ACTIVATION:
        component=<ActivateLicenseForm
                    manageOnCancel={this.manageOnCancel}
                    buttonCancelLabel={content.activateLicense.aceptar17}
                    data={this.state.data}
                    status={activateLicenseConstants.OK_ACTIVATION}/>
        break
        case activateLicenseConstants.KO_ACTIVATION:
          component=<ActivateLicenseForm
                      manageOnCancel={this.manageOnCancel}
                      buttonCancelLabel={content.activateLicense.aceptar17}
                      status={activateLicenseConstants.OK_ACTIVATION}/>
          break
      default:
        component=<div>ActivateLicense Default</div>
    }
    return component
  }
  render(){
    return(
      <AuthConsumer>
        {({userInfo})=>{
          if(!userInfo)return null
          return(
            <ContentConsumer>
              {({content={},isContentLoading})=>(
                content&&(
                  ![ROLES.TEACHER,ROLES.TEACHER_FAKE].includes(userInfo.role)?
                    <div className="alertMessage" ><Alert color="danger">{content.sharedTerms.ERROR_TEXT}</Alert></div>
                  : <div className="text-center d-flex flex-column justify-content-center m-5 schoolFinder">
                      <h2 className="m-3">{content.activateLicense.codigoDeActivacion2}</h2>
                      <div className="d-flex justify-content-center" >
                        {this.manageStatus(this.state.status,content)}
                      </div>
                    </div>
                )
              )}
            </ContentConsumer>
          )
        }}
      </AuthConsumer>
    )
  }
}
export default withGoogleReCaptcha(ActivateLicense)
