import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { ContentConsumer } from '../../language'

// MBQ Edition-language filter for internal user resources
import { unitIds as UNITS } from '../../globals/title'

const Units = props => {

  const { units } = props

  return (
    <Nav className="unitsOUP text-center" pills>
      <ContentConsumer>
        {({ content }) => (content && units && !!units.length && (
          <Fragment>
            <NavLink
              tag={Link}
              onClick={(e) => {
                // MBQ Edition-language filter for internal user resources
                //if(props.onClick) props.onClick()
                if(props.onClick) props.onClick(UNITS.FAVOURITES)
              }}
              to={{
                pathname: `/title/${props.params.titleid}/unit/favs/cat/all/subcat/all`,
                search: `?initTab=0`
              }}
              active={(props.params.unitid === 'favs') || !props.params.unitid}
              className="m-1 "
            >
              {content.titledetail.my_favourites}
            </NavLink>
            <NavLink
              tag={Link}
              onClick={(e) => {
                if(props.onClick) props.onClick()
              }}
              to={{
                pathname: `/title/${props.params.titleid}/unit/all/cat/all/subcat/all`,
                search: `?initTab=0`
              }}
              active={(props.params.unitid === 'all') || !props.params.unitid}
              className="m-1 "
            >
              {content.titledetail.view_all}
            </NavLink>
          </Fragment>
        ))}
      </ContentConsumer>


      {units.map((data, idx) => (

        <NavItem key={idx} className="m-1">
          <NavLink
            active={props.params.unitid === data.uuid ? true : false}
            className="py-1"
            tag={Link}
            onClick={(e) => {
              // MBQ Edition-language filter for internal user resources
              //if(props.onClick) props.onClick()
              if(props.onClick) props.onClick(data.name)
            }}
            to={{
              pathname: `/title/${props.params.titleid}/unit/${data.uuid}/cat/all/subcat/all`,
              search: `?initTab=0`
            }}
          >
            {data.name}
          </NavLink>
        </NavItem>

      ))

      }
    </Nav>
  )
}

Units.propTypes = {
  units: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired
}


export default Units