import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { AuthConsumer } from 'react-check-auth'
import { ContentConsumer } from '../../language'
import { Progress } from 'reactstrap'
import BlinkCptLicenceForm from '../BlinkCptLicenceForm/BlinkCptLicenceForm'
import qs from 'qs'
import validator from 'validator'
import { blinkCptRoles } from '../../globals/userRoles'

// MBQ TODO Hide Premium Header and Footer
// MBQ TODO Hide Chat?

class OAuth2Authorize extends Component {
  constructor(props) {
    super(props)
    this.state = {
      client_id: '',
      redirect_uri: '',
      blink_state: '',
      loading: true
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const { client_id, redirect_uri, state } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    if (client_id && redirect_uri && validator.isURL(redirect_uri)) {
      state ?
        this.setState({ client_id, redirect_uri: encodeURIComponent(redirect_uri), blink_state: encodeURIComponent(state), loading: false })
        :
        this.setState({ client_id, redirect_uri: encodeURIComponent(redirect_uri), loading: false })
    } else {
      this.setState({ loading: false })
    }
  }

  renderWithSession = role => (
    blinkCptRoles.includes(role) ?
      <BlinkCptLicenceForm clienId={this.state.client_id} redirectUri={this.state.redirect_uri} blinkState={this.state.blink_state} />
      :
      <Redirect to='/oauth2-error' />
  )

  render() {
    const { client_id, redirect_uri, blink_state, loading } = this.state
    return (
      <AuthConsumer>
        {({ userInfo }) => (
          <ContentConsumer>
            {({ content }) => content && (
              <Fragment>
                {loading && <Progress animated color="primary" value="100">{content.sharedTerms.LOADING_MESSAGE}</Progress>}
                {!loading && (!client_id || !redirect_uri) && <Redirect to='/oauth2-error' />}
                {client_id && redirect_uri && (
                  !userInfo ?
                    (<Redirect to={`/oauth2-signin?client_id=${client_id}&redirect_uri=${redirect_uri}${blink_state ? '&state=' + blink_state : ''}`} />)
                    :
                    (this.renderWithSession(userInfo.role))
                )}
              </Fragment>
            )}
          </ContentConsumer>
        )}
      </AuthConsumer>
    )
  }
}

export default OAuth2Authorize