import React from 'react'
import { Jumbotron, Container, Row, Col } from 'reactstrap'

const PDBanner = props => (
  <Jumbotron fluid className={`jumbotron__pdbanner ${props.className}`}>
    <Container fluid={true} className="pt-5 pb-5 pl-md-5 pr-md-5">
      <Row>
        <Col lg={{ size: 10, offset: 1 }} className="text-center">{props.children}</Col>
      </Row>
    </Container>
  </Jumbotron>
)

export default PDBanner