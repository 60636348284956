import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import './ArrowSteps.css'

const ArrowSteps = ({ steps }) => (
  <Container className="arrow-steps" fluid>
    <Row className="px-0 justify-content-end">
      {
        steps.map((step, index) => (
          <Col key={index} xs="12" md="6" className={`step order-${(index + 1)}`}>
            <div className="step-index d-flex">
              <div className="m-auto">{index + 1}</div>
            </div>
            <span>{step}</span>
          </Col>
        ))
      }
    </Row>
  </Container>
)

export default ArrowSteps