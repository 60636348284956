import React, { Component } from 'react'
import { AuthConsumer } from 'react-check-auth'
import { ContentConsumer } from '../../language'
import { Container } from 'reactstrap';
import CMSContent from '../CMSContent/CMSContent'
import { getLocale } from '../../language'
import { Button, Col, Row } from 'reactstrap';

class HPSuccess extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <AuthConsumer>
        {({ userInfo }) => {
          return (
            <ContentConsumer>
              {({ content }) => {
                return (
                  content &&
                  (<Container>
                    <div className="HPSuccess py-3" >
                      <Row>
                        <Col className="py-2">
                          <h6>{content.gratishp.gratishp_success_title}</h6>
                        </Col>
                      </Row>
                      <Row className="border-top border-bottom py-3">
                        <div className="mx-auto">
                          <CMSContent pageid="PREM_HPSOK" cache={true} language={userInfo ? getLocale(userInfo.language) : getLocale()} />
                        </div>
                      </Row>
                      <Row>
                        <Col className="d-flex justify-content-center py-3">
                          <Button className="HPSuccess__accept" onClick={ () => window.location.href = `/app` }>{content.sharedTerms.ACCEPT_BUTTON_TEXT}</Button>
                        </Col>
                      </Row>
                    </div>
                  </Container>)
                )
              }}
            </ContentConsumer>
          )
        }}
      </AuthConsumer>
    )
  }
}
export default HPSuccess
