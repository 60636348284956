import React from 'react'
import { AuthConsumer } from 'react-check-auth'
import CMSContent from '../CMSContent/CMSContent'
import { getLocale } from '../../language'

const CONTENT_GPATERMS = 'PREM_GXACONDICIONES'

const GpaTermsPage = () => (
  <AuthConsumer>
    {({ userInfo }) => (
      userInfo && (
        <CMSContent pageid={CONTENT_GPATERMS} cache={true} language={getLocale(userInfo.language)} />
      )
    )}
  </AuthConsumer>
)

export default GpaTermsPage