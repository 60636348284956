import React from "react"
import { estado_modificada } from '../../utils/gpa-utils'

const GpaOrderRemark = ({ remark, stateId, content }) => (
  <div className="row">
    <div className="col-sm-12 mt-4">
      <p className="small"><strong>{content.gratisadoption.gxa_37}:</strong></p>
      {
        stateId === estado_modificada ?
          <p className="small red">{remark}</p>
          :
          <p className="small">{remark}</p>
      }
    </div>
  </div>
)

export default GpaOrderRemark