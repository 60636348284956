import React from 'react'
import { AuthConsumer } from 'react-check-auth'
import { Route, Redirect } from 'react-router-dom'

const NoAuthRoute = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ userInfo }) => (
      userInfo ?
        <Redirect to={{ pathname: '/', state: { referrer: window.location.href } }} />
        :
        <Route {...rest} render={props => <Component {...props} />} />
    )}
  </AuthConsumer>
)

export default NoAuthRoute
