import React from 'react'
import _ from 'lodash'
import cx from 'classnames'

import { withContent } from '../../utils/hocs'

import svg from './close.svg'

const CloseButton = ({ className, onClick, content }) => (
  <div
    className={cx("button-close", className)}
    onClick={onClick}
  >
    <img src={svg} alt={_.get(content, 'sharedTerms.CLOSE_BUTTON_TEXT')} />
  </div>
)

export default withContent(CloseButton)
