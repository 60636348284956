import React from 'react'
import { ContentConsumer } from '../../language'
import CMSContent from '../CMSContent/CMSContent'

const CONTENT_BLINKCPT_HELP = 'BLINKCPT_HELP'

const BlinkCptHelpPage = () => (
  <ContentConsumer>
    {({ locale }) => (
      <CMSContent pageid={CONTENT_BLINKCPT_HELP} cache={true} language={locale} />
    )}
  </ContentConsumer>
)

export default BlinkCptHelpPage