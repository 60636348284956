import React from "react"
import GpaContact from '../GpaContact/GpaContact'

const GpaHeader = ({ school, position, content }) => (
  <div>
    <div className="row mb-5">
      <div className="col-md-8">{content.gratisadoption.gxa_13}<strong>&nbsp;{school}</strong></div>
      <div className="col-md-4">
        <div className="cargo-titulo">{content.gratisadoption.gxa_8}<strong>&nbsp;{position}</strong></div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-12 mb-5">
        <p>{content.gratisadoption.gxa_14}</p>
        <p><GpaContact>{content.gratisadoption.gxa_36}</GpaContact></p>
      </div>
    </div>
  </div>
)

export default GpaHeader