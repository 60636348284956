import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import { ContentConsumer } from '../../language'
import errorImage from '../../assets/images/error-image.jpg'

const PDError = props => (
  <ContentConsumer>
    {({ content = {} }) => (
      content && (
        <Container fluid={true} className="pdError">
          <Row>
            <Col className="text-center pt-5">
              <img alt="error" src={errorImage} />
            </Col>
          </Row>
          <Row>
            <Col className="text-center pt-5">
              <h6>{content.pdcourses.generic_error_1}</h6>
              <h6>{content.pdcourses.generic_error_2}</h6>
            </Col>
          </Row>
          <Row className="pt-5 pb-5">
            <Col className="text-center col-8 offset-2 col-lg-4 offset-lg-4">
              <ButtonPremium onClick={props.closeModal} primary>
                {content.pdcourses.button_accept}
              </ButtonPremium>
            </Col>
          </Row>
        </Container>
      ))}
  </ContentConsumer>
)

export default PDError