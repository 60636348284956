import React, { createContext, useContext, useEffect, useState } from 'react'
import axios from 'axios'

import { withUserInfo } from '../../utils/hocs'
import { useFeature } from '../Feature/FeatureContext'
import axiosConfig from "../../config/axios"
import userRoles from '../../globals/userRoles'

export const PollConfigContext = createContext()
export const usePollConfig = () => useContext(PollConfigContext)

const axiosInstance = axios.create(axiosConfig)

export const PollConfigProvider = withUserInfo(({ userInfo: { idSim, role }, children }) => {
  const {
    polls: pollsFeature,
    managedSurvey: managedSurveyFeature
  } = useFeature()
  const [ pollConfig, setPollConfig ] = useState()
  const [ managedSurveyConfig, setManagedSurveyConfig ] = useState()

  const managedSurveyEnabled = (
    managedSurveyFeature &&
    role && role === userRoles.TEACHER
  )

  useEffect(() => {
    if(pollsFeature) {
      axiosInstance.get("user/polls")
        .then((response) => {
          if(response.data && Object.keys(response.data).length) {
            setPollConfig(response.data)
          }
        })
        .catch(() => setPollConfig(null))
    }
  }, [idSim, pollsFeature])

  useEffect(() => {
    if(managedSurveyEnabled) {
      axiosInstance.get("user/managed-survey")
        .then((response) => {
          if(response.data && Object.keys(response.data).length) {
            setManagedSurveyConfig(response.data)
          }
        })
        .catch(error => {
          console.error("Error getting managed survey configuration: ", error.toString())
          setManagedSurveyConfig(null)
        })
    }
    }, [idSim, managedSurveyEnabled])

  return (
    <PollConfigContext.Provider
      value={{ pollConfig, managedSurveyConfig }}
    >
      {children}
    </PollConfigContext.Provider>
  )
})