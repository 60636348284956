import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import Product from './Product'

const Products = ({ token, products, onProductError, addToShoppingCart }) => (
  <Container className="h-100" fluid>
    <Row className='row-product-card'>
      {
        products.map((product) => (
          <Col key={`${product.id}`} className="px-2 py-3" xs="12" lg="6">
            <Product
              token={token}
              product={product}
              onError={onProductError}
              addToShoppingCart={addToShoppingCart}
            />
          </Col>
        ))
      }
    </Row>
  </Container>
)

export default Products
