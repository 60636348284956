import React from 'react'

import CMSContent from 'components/CMSContent/CMSContent'

import { withNavigatorLocale } from 'utils/hocs'

const ClassroomError = ({ errorId, locale }) => {
  let error = 'CLASSROOM_ERROR3'

  if(errorId === '401') error = 'CLASSROOM_ERROR1'
  else if(errorId === '404') error = 'CLASSROOM_ERROR2'

  return (
    <div className="my-5">
      <CMSContent
        pageid={error}
        cache={true}
        language={locale}
      />	
    </div>
  )
}

export default withNavigatorLocale(ClassroomError)