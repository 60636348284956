import React, { createContext, useContext, useEffect, useState } from 'react'
import { useFeature } from './Feature/FeatureContext'

export const GoogleClassroomContext = createContext()
export const useGoogleClassroom = () => useContext(GoogleClassroomContext)

export const GoogleClassroomProvider = ({ children }) => {
  const [ loaded, setLoaded ] = useState(false)
  const { classroom: enabled } = useFeature()

  useEffect(() => {
    if(enabled) {
      window.___gcfg = {
        parsetags: 'explicit'
      }
      const script = document.createElement('script')
      script.src = 'https://apis.google.com/js/platform.js'
      script.async = true
      script.defer = true
      script.onload = () => setLoaded(true)
      script.onerror = () => setLoaded(false)
      document.body.appendChild(script)
      return () => document.body.removeChild(script)
    }

  }, [enabled])

  return (
    <GoogleClassroomContext.Provider
      value={{ loaded }}
    >
      { children }
    </GoogleClassroomContext.Provider>
  )
}