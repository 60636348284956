import React, { Fragment } from 'react'
import { Alert, Container, Col, Form, FormGroup, Label, Input, NavLink } from 'reactstrap'
import { Link } from 'react-router-dom'
import validator from 'validator'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import { RecaptchaTerms } from '../Recaptcha/Recaptcha'

export const ActivationCodeForm = (props) => {
  const { userInfo, paragraph, showAlert, alertMessage, showForm, showDataCenter, dataCenter, labelPremiumCode
        , placeHolderPremiumCode, premiumCode, help, handleChangeCode, manageOnCancel, cancelButton
        , manageOnSubmit, acceptButton, status} = props

  const handleChange = (e) => {
    const data = validator.whitelist(e.target.value.trim().toUpperCase(), '0-9A-Z')
    handleChangeCode(data)
  }

  const onCancel = () => {
    manageOnCancel(status)
  }

  const onSubmit = (e) => {
    manageOnSubmit(e, status, userInfo.schoolId)
  }

  return (
    <Fragment>
        <Container className="activationCodeForm" >
        <Form >
            <FormGroup row>
            <Container className="activationCodeForm" >
                <p>{paragraph}</p>
            </Container>
            </FormGroup>

            {showAlert &&
            <div className="alertMessage" ><Alert color="danger" className="activationCodeForm__error">{alertMessage}</Alert></div>
            }

            {showForm &&
            <FormGroup row>
                {showDataCenter ?
                <Container className="activationCodeForm" >
                <p className="searchOk">{dataCenter.d_centro}</p>
                <p className="searchOk">{dataCenter.t_nombrevia}</p>
                <p className="searchOk">{dataCenter.d_poblacion}</p>
                <p className="searchOk">{dataCenter.d_pais}</p>
                </Container>
                :
                <Container className="activationCodeForm" >
                <Label for="code">{labelPremiumCode}</Label>
                <Input
                    maxLength="10"
                    onChange={handleChange}
                    type="text"
                    name="code"
                    id="code"
                    value={premiumCode}
                    placeholder={placeHolderPremiumCode}
                    autoComplete="off" />
                </Container>
            }
            </FormGroup>
            }

            <FormGroup row className="justify-content-center">
                <NavLink className="activationCodeForm__navLink" tag={Link} target="_blank" to={'/help'}>{help}
                <i className="glyphicon glyphicon-question-sign"></i>
                </NavLink>

            </FormGroup>

            <FormGroup row >
              <Col sm="5">
                <ButtonPremium onClick={onCancel}>
                  {cancelButton}
                </ButtonPremium>
              </Col>
              <Col sm="7">
                <ButtonPremium onClick={(e) => onSubmit(e)} primary>
                  {acceptButton}
                </ButtonPremium>
              </Col>
            </FormGroup>
        </Form>
          <div className="my-2"><RecaptchaTerms /></div>
        </Container>
    </Fragment>
  )
}
