import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import axios from 'axios'
import validator from 'validator'
import { Container, Row, Col, Form, FormGroup, FormFeedback, Label, Input } from 'reactstrap'

import axiosConfig from '../../config/axios'
import { withContent, withUserInfo } from '../../utils/hocs'
import ModalAccept from '../ModalAccept/ModalAccept'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import ROLES from '../../globals/userRoles'
import platformId  from '../../globals/platforms'

class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: '',
      password1: '',
      password2: '',
      success: false,
      failure: false,
      errors: {
        current: false,
        password1: false,
        password2: false,
        incorrectPassword: false
      }
    }
  }

  gotoHome = () => {
    this.props.history.push("/")
  }

  isValid = () => !_.compact(_.values(this.state.errors)).length && this.state.current && this.state.password1 && this.state.password2 &&
    [ ROLES.TEACHER, ROLES.TEACHER_DEMO ].find(role => role === _.get(this.props, 'userInfo.role', undefined))

  submit = () => {
    if(this.isValid()) {
      const axiosClient = axios.create(axiosConfig)
      axiosClient.put('/user/password', { current: this.state.current, new: this.state.password1})
        .then((response) => {
          this.setState({ success : true })
        })
        .catch((error) => {
          const incorrectPassword = _.get(error, "response.data.errors.incorrectPassword", false)
          this.setState({
            failure : true,
            errors: {
              ...this.state.errors,
              incorrectPassword
            }
          })
        })
    }
  }


  handleInputChange = (event) => {
    const { name, value } = event.target

    const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])\S{6,}$/;

    switch(name) {
      case "current":
        this.setState((prevState) => {
          return {
            [name]: value,
            errors: {
              ...prevState.errors,
              incorrectPassword: false,
              [name]: !(value.length > 0)
            }
          }
        })
        break
      case "password1":
        this.setState((prevState) => {
          return {
            [name]: value,
            errors: {
              ...prevState.errors,
              [name]: !validator.matches(value, regexPassword),
              password2: !!prevState.password2 && (prevState.password2 !== value)
            }
          }
        })
        break
      case "password2":
        this.setState((prevState) => {
          return {
            [name]: value,
            errors: {
              ...prevState.errors,
              [name]: this.state.password1 !== value
            }
          }
        })
        break
      default:
        this.setState({ [name]: value })
    }
  }

  render() {
    const { content,userInfo, history } = this.props
    if(userInfo.platformId!==platformId.LDAP){
      history.push('/error')
      return(<></>)      
    } else {    
    return (
      <Container fluid={false} className="change-password">
        <Row>
          <Col><h1>{ content.useraccount.password_change_title }</h1></Col>
        </Row>
        <Row>
          <Col sm={{ size:6, offset:3 }}>
            <Form noValidate>
              <FormGroup>
                <Label for="current">{content.useraccount.password_current}</Label>
                <Input
                  id="current"
                  type="password"
                  name="current"
                  placeholder={content.useraccount.password_current}
                  autoComplete="off"
                  value={this.state.current}
                  onChange={this.handleInputChange}
                  valid={!(this.state.errors.current || this.state.errors.incorrectPassword) && this.state.current!==''}
                  invalid={this.state.errors.current || this.state.errors.incorrectPassword }
                />
                <FormFeedback>
                  {
                    this.state.errors.current ?
                      content.useraccount.password_invalid :
                      content.useraccount.password_incorrect
                  }
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="password1">{content.useraccount.password_new}</Label>
                <Input
                  id="password1"
                  type="password"
                  name="password1"
                  placeholder={content.useraccount.password_new}
                  autoComplete="off"
                  value={this.state.password1}
                  onChange={this.handleInputChange}
                  valid={!this.state.errors.password1 && this.state.password1!==''}
                  invalid={this.state.errors.password1}
                />
                <FormFeedback>{ content.useraccount.password_invalid }</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="password2">{ content.useraccount.password_confirmation }</Label>
                <Input
                  id="password2"
                  type="password"
                  name="password2"
                  placeholder={content.useraccount.password_confirmation}
                  autoComplete="off"
                  value={this.state.password2}
                  onChange={this.handleInputChange}
                  valid={!this.state.errors.password2 && this.state.password2!==''}
                  invalid={this.state.errors.password2}
                />
                <FormFeedback>{ content.useraccount.password_confirmation_invalid }</FormFeedback>
              </FormGroup>
            </Form>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size:6, offset:3 }}>
              <Container>
                <Row className="mt-2">
                  <Col xs="12" md={{size: 10, offset: 1}} lg={{size: 4, offset:1}} className="mt-3">
                    <ButtonPremium onClick={this.gotoHome}>
                      {content.sharedTerms.CANCEL_BUTTON_TEXT}
                    </ButtonPremium>
                  </Col>
                  <Col xs="12" md={{size: 10, offset: 1}} lg={{size: 4, offset:1}} className="mt-3">
                    <ButtonPremium onClick={this.submit} disabled={!this.isValid()} primary>
                      { content.sharedTerms.ACCEPT_BUTTON_TEXT }
                    </ButtonPremium>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <ModalAccept isOpen={this.state.failure} toggle={() => this.setState({failure: false})} header={content.useraccount.password_change_title}>
            {content.useraccount.edit_account_failure}
          </ModalAccept>
          <ModalAccept isOpen={this.state.success} toggle={this.gotoHome} header={content.useraccount.password_change_title}>
            {content.useraccount.password_change_success}
          </ModalAccept>
      </Container>
    )
    }
  }
}

export default withUserInfo(withRouter(withContent(ChangePassword)))
