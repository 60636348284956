import React, { Fragment } from 'react'
import { AuthConsumer } from 'react-check-auth'
import { Redirect } from 'react-router-dom'
import UserAdministration from '../UserAdministration/UserAdministration'

const Administration = () => (
  <AuthConsumer>
    {({ userInfo }) => {
      if (!userInfo || !userInfo.impersonationUser) {
        return <Redirect to='/' />
      }
      return (
        <Fragment><UserAdministration /></Fragment>
      )
    }}
  </AuthConsumer>
)

export default Administration