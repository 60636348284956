import React from 'react'
import { Button } from 'reactstrap'

const ButtonPremium = props => (
  <Button
    {...props}
    primary={'' /* to avoid warning */}
    className={!!props.primary ? 'button-premium button-premium-primary' : 'button-premium button-premium-secondary'}
  >
    {props.children}
  </Button>
)

export default ButtonPremium
