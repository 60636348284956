// MBQ MAY 2024 POC MARSUPIAL BLINK
import React, { Fragment, useState } from "react"
import { Container, Row, Col, Card, CardTitle, CardText, CardFooter, CardLink } from 'reactstrap'
import { Form, FormGroup, Label, Input } from 'reactstrap'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import { COUPONS_MARSUPIALBLINK_MOCKS } from './mocks'
import validator from 'validator'
import { copyToClipboard } from '../../utils/string'
import envSettings from '../../globals/envSettings'

const printCardsTests = () => (
  COUPONS_MARSUPIALBLINK_MOCKS.map(user => (
    <Fragment key={user.userId}>
      <Row className="pt-5 pb-2">
        <Col>
          <h4 className="font-weight-bold">USER {user.userId}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          {
            user.coupons.map(coupon => (
              coupon.products.map(product => (
                <Card body key={coupon.couponId + product.isbn}
                  className="my-4 border border-dark rounded bg-secondary text-white font-weight-bold"
                >
                  <CardTitle tag="h5" className="pb-2 font-weight-bold">COUPON {coupon.couponId} - PRODUCT "{product.name}"</CardTitle>
                  <CardText tag="div" className="pb-4 pt-4">
                    <ul>
                      <li>ISBN {product.isbn}</li>
                      <li>TOKEN {product.token}</li>
                      <li>LICENCE ID {product.licenseId}</li>
                    </ul>
                  </CardText>
                  <CardFooter className="border border-0 rounded bg-white">
                    <div className="pb-4 pt-4">
                      <CardLink href={product.urls[0]} target="_blank" className="pb-4 pt-4">COUPON ACCESS ( {product.urls[0]} )</CardLink>
                      <span
                        className="rounded-circle text-center"
                        role="button"
                        style={{ cursor: "copy" }}
                        onClick={() => { copyToClipboard(product.urls[0]) }}
                      >
                        <img
                          className="w-20px ml-2"
                          src="https://online.oupe.es/OUP/Biblioteca/Iconos/copyIcon.png"
                          title="Copy URL"
                          alt="Copy URL"
                        />
                      </span>
                    </div>
                    <div className="pb-4 pt-4">
                      <CardLink href={product.urls[1]} target="_blank" className="pb-4 pt-4">LICENCE ACCESS ( {product.urls[1]} )</CardLink>
                      <span
                        className="rounded-circle text-center"
                        role="button"
                        style={{ cursor: "copy" }}
                        onClick={() => { copyToClipboard(product.urls[1]) }}
                      >
                        <img
                          className="w-20px ml-2"
                          src="https://online.oupe.es/OUP/Biblioteca/Iconos/copyIcon.png"
                          title="Copy URL"
                          alt="Copy URL"
                        />
                      </span>
                    </div>
                    <div className="pb-4 pt-4">
                      <CardLink href={product.urls[2]} target="_blank" className="pb-4 pt-4">USER ACCESS ( {product.urls[2]} )</CardLink>
                      <span
                        className="rounded-circle text-center"
                        role="button"
                        style={{ cursor: "copy" }}
                        onClick={() => { copyToClipboard(product.urls[2]) }}
                      >
                        <img
                          className="w-20px ml-2"
                          src="https://online.oupe.es/OUP/Biblioteca/Iconos/copyIcon.png"
                          title="Copy URL"
                          alt="Copy URL"
                        />
                      </span>
                    </div>
                  </CardFooter>
                </Card>
              ))
            ))
          }
        </Col>
      </Row>
    </Fragment>
  ))
)

const MarsupialBlinkTest = () => {
  const [state, setState] = useState({
    coupon: '',
    isbn: '',
    licenseId: '',
    token: '',
    userId: '',
    access: 'license',
    error: []
  })

  const changeValue = e => {
    e.preventDefault()
    const name = e.target.name
    const value = ["coupon", "licenseId", "userId"].includes(name) ?
      validator.whitelist(e.target.value.trim(), '0-9') : e.target.value.trim()

    if(state.error.includes(name)) {
      const errorFields = state.error.filter(item => item !== name)
      setState({ ...state, [name]: value, error: [...errorFields] })
    } else {
      setState({ ...state, [name]: value })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    const errorFields = []
    const mandatoryFields = ["coupon", "isbn", "licenseId", "token"]
    mandatoryFields.forEach(item => {
      if(state[item] === null || state[item] === "") {
        errorFields.push(item)
      }
    })
    if(state.access === 'user' && (state.userId === null || state.userId === "")) {
      errorFields.push("userId")
    }

    if(errorFields.length) {
      setState({ ...state, error: [...errorFields] })
    } else {
      const url = `${envSettings.API_BASEURL}/coupons/${state.coupon}/product/${state.isbn}/license/${state.licenseId}?` + 
                  `user=${state.userId}&token=${state.token}&access=${state.access}`
      window.open(url)
    }
  }

  return (
    <Container>
      <Row className="pt-5 pb-2">
        <Col>
          <h2 className="font-weight-bold">MARSUPIAL-BLINK TESTS</h2>
        </Col>
      </Row>
      {printCardsTests()}
      <Row className="pt-5 pb-2">
        <Col>
          <h4 className="font-weight-bold">CUSTOM REQUEST</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form inline>
            <Card body className="my-4 px-4 border border-dark rounded bg-light font-weight-bold">
              <FormGroup className="my-2 py-3 px-2">
                <Label for="coupon">*Coupon ID</Label>
                <Input
                  type="text" name="coupon" id="coupon" value={state.coupon}
                  onChange={changeValue} placeholder="" className="ml-3 mr-5 w-25" autoComplete="off"
                  invalid={state.error.includes("coupon")}
                />
                <Label for="isbn">*ISBN</Label>
                <Input
                  type="text" name="isbn" id="isbn" value={state.isbn}
                  onChange={changeValue} placeholder="" className="ml-3 w-50" autoComplete="off"
                  invalid={state.error.includes("isbn")}
                />
              </FormGroup>
              <FormGroup className="my-2 py-3 px-2">
                <Label for="licenseId">*Licence ID</Label>
                <Input
                  type="text" name="licenseId" id="licenseId" value={state.licenseId}
                  onChange={changeValue} placeholder="" className="ml-3 mr-5 w-25" autoComplete="off"
                  invalid={state.error.includes("licenseId")}
                />
                <Label for="token">*Token</Label>
                <Input
                  type="text" name="token" id="token" value={state.token}
                  onChange={changeValue} placeholder="" className="ml-3 w-50" autoComplete="off"
                  invalid={state.error.includes("token")}
                />
              </FormGroup>
              <FormGroup className="my-2 py-3 px-2">
                <Label for="userId">User ID</Label>
                <Input
                  type="text" name="userId" id="userId" value={state.userId}
                  onChange={changeValue} placeholder="" className="ml-3 mr-5 w-25" autoComplete="off"
                  invalid={state.error.includes("userId")}
                />
                <Label for="access">Access</Label>
                <Input
                  type="select" name="access" id="access" value={state.access}
                  onChange={e => changeValue(e)} placeholder="" className="ml-3 w-50" autoComplete="off">
                  <option value="coupon">coupon</option>
                  <option value="license">licence</option>
                  <option value="user">user</option>
                </Input>
              </FormGroup>

              <CardFooter className="border border-0 bg-light d-flex justify-content-end">
                <div className="pb-4 pt-4 w-25">
                  <ButtonPremium type="button" primary onClick={handleSubmit}>Submit request</ButtonPremium>
                </div>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default MarsupialBlinkTest