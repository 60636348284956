import React, { Component } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Container, Row, Col, Form, FormGroup, Input, FormFeedback } from 'reactstrap'
import validator from 'validator'
import _ from 'lodash'

import { withContent } from '../../utils/hocs'
import ButtonPremium from '../ButtonPremium/ButtonPremium'
import { Help } from '../Links/Links'
import { RecaptchaTerms } from '../Recaptcha/Recaptcha'

import axiosConfig from '../../config/axios'

const axiosClient = axios.create(Object.assign({}, axiosConfig, { timeout: 20000 }))

class PasswordForgot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      loading: false,
      success: undefined,
      errors: {
        email: false
      }
    }
  }

  async componentDidMount() {
  }

  handleInputChange = (event) => {
    event.preventDefault()
    const target = event.target;
    const value = target.value;

    this.setState({
      email: value,
      errors: {
        email: !validator.isEmail(value),
      }
    })
  }

  submit = (event) => {
    event.preventDefault()
    if(this.state.email !== '' && !this.state.errors.email) {
      this.setState({ loading: true }, async () => {
        const token = await this.props.googleReCaptchaProps.executeRecaptcha('password')
        axiosClient.post('/password/forgot',
          { token, email: this.state.email },
          { headers: { 'accept-language': this.props.locale } }
        )
          .then((response) => this.setState( { success: true, loading: false }))
          .catch((err) => {
            this.setState({ success: false, loading:false })
          })
      })
    }
  }

  render() {
    const { content } = this.props
    if(this.state.success) {
      return (
        <Container fluid={false} className="PasswordForgot">
          <Row>
            <Col><h1>{ content.useraccount.password_forgot_title }</h1></Col>
          </Row>
          <Row className="mt-3">
            <Col md={{size:8, offset:2}}><p className="text-center">{ content.useraccount.password_forgot_success }</p></Col>
          </Row>
          <Row>
            <Col md={{ size: 2, offset: 5}}>
              <ButtonPremium onClick={ () => this.props.history.push("/") } primary>{ content.sharedTerms.ACCEPT_BUTTON_TEXT }</ButtonPremium>
            </Col>
          </Row>
        </Container>
      )
    } else {

      return (
        <Container fluid={false} className="PasswordForgot">
          <Row>
            <Col><h1>{ content.useraccount.password_forgot_title }</h1></Col>
          </Row>
          <Row className="mt-3">
            <Col md={{size:8, offset:2}}><p className="text-center">{ content.useraccount.password_forgot_body }</p></Col>
          </Row>
          { (this.state.success === false) &&
            <Row>
              <Col md={{size:8, offset:2}}><p className="error text-center">{ content.useraccount.password_reset_error }</p></Col>
            </Row>
          }
          <Row>
            <Col md={{size:6, offset:3}}>
              <Form noValidate>
                <FormGroup>
                  <Input
                    id="email"
                    type="email"
                    name="email"
                    placeholder={ content.useraccount.email }
                    autoComplete="off"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    valid={!this.state.errors.email && this.state.email !== ''}
                    invalid={ this.state.errors.email }
                  />
                  <FormFeedback>{ content.useraccount.error_registration_email }</FormFeedback>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={{size:8, offset:2}}><p className="text-center"><Help topic="olvidado" /></p></Col>
          </Row>
          <Row className="mt-3">
            <Col md={{ size: 6, offset: 3}}>
              <Container>
                <Row>
                  <Col md={{ size: 4, offset: 1}}>
                    <ButtonPremium
                      onClick={() => this.props.history.push("/")}
                    >
                      { _.get(content, ['sharedTerms', 'CANCEL_BUTTON_TEXT'], 'Cancel') }
                    </ButtonPremium>
                  </Col>
                  <Col md={{ size: 4, offset: 1}}>
                    <ButtonPremium
                      disabled={(this.state.email === '') || this.state.errors.email || this.state.loading }
                      onClick={ this.submit }
                      primary
                    >
                      { content.useraccount.password_forgot_send }
                    </ButtonPremium>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={{size:8, offset:2}}><p className="text-center"><RecaptchaTerms /></p></Col>
          </Row>
        </Container>
      )
    }
  }
}

export default withRouter(withGoogleReCaptcha(withContent(PasswordForgot)))
