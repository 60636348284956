import React, { Component, Fragment } from 'react'
import { withUserInfo } from '../../../utils/hocs'
import _ from 'lodash'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Table } from 'reactstrap'
import userRoles from "../../../globals/userRoles"
import { getSZShareTitletData, getSZGlobalValues, putSZGlobalValues, putSZTokenValues, putSZBlockToken, getSZTokenValues } from "../../../services/administrator/request"
import { getStatusMessage } from "../../../utils/format"
import { formatDate } from "../../../utils/format"
import envSettings from '../../../globals/envSettings'
import ModalAccept from '../../ModalAccept/ModalAccept'
import Modal2Buttons from '../../Modal2Buttons/Modal2Buttons'
import StudentsZoneUpdate from './StudentsZoneUpdate'
import StudentsZoneBlock from './StudentsZoneBlock'
import validator from 'validator'
import StudentLog from"../StudentLog/StudentLog"
class StudentsZoneAdministrator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      noResults: false,
      oneFieldRequired: false,
      tokensList: [],
      todos: [],
      numberRecords: 0,
      openModal: false,
      openModalUpdate: false,       
      openModalBlock: false,
      blockErrors: false,
      token: '',
      title:undefined,
      showStudentsSearch:true,
      //Values for modal
      increasement_percentage: 0,
      different_devices: 0,
      updateErrors: { increasement_percentage: false
                    , different_devices: false
                    , withoutChanges: false
                    },
      // Global values
      previous_global: { increasement_percentage: 0
                       , different_devices: 0
                       },
      date: formatDate(new Date(), 'DD/MM/YYYY'),
      isCalendarOpen: false,
      selectedDate: new Date()
    }

    this.search = this.search.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.onClickEnter = this.onClickEnter.bind(this)
    this.toggle = this.toggle.bind(this)
    this.showError = this.showError.bind(this)
    this.modalUpdateSubmit = this.modalUpdateSubmit.bind(this)
    this.studentsSearch=this.studentsSearch.bind(this)
    this.onclickCalendar = this.onclickCalendar.bind(this)
    this.onChangeDate = this.onChangeDate.bind(this)
    this.modalBlockSubmit = this.modalBlockSubmit.bind(this)
    this.refreshGlobalValues = this.refreshGlobalValues.bind(this)
  }

  componentDidMount() {
    const { userInfo } = this.props
    window.scrollTo(0, 0)
    if (userInfo.role === userRoles.CUSTOMER_SUPPORT) this.refreshGlobalValues()
  }

  refreshGlobalValues = () => {
    getSZGlobalValues()
    .then(response => {
      this.setState({ increasement_percentage: response.data.increasement_percentage
                    , different_devices: response.data.different_devices
                    , previous_global: { increasement_percentage: response.data.increasement_percentage
                                       , different_devices: response.data.different_devices
                                       }
                  })
    })
    .catch((err) => this.showError(500))
  }

  handleRefreshGlobals = () => {
    getSZGlobalValues()
    .then(response => {
      this.setState({ openModalUpdate: true
                    , header: "Change Student Area Global Values"
                    , increasement_percentage: response.data.increasement_percentage
                    , different_devices: response.data.different_devices
                    , previous_global: { increasement_percentage: response.data.increasement_percentage
                                       , different_devices: response.data.different_devices
                                       }
                  })
    })
    .catch((err) => this.showError(500))
  }

  isValid = () => !_.compact(_.values(this.state.updateErrors)).length
  handleInputChange = () => this.setState({ noResults: false })

  toggle() {
    this.setState({ openModal: false })
  }

  showError(error) {
    this.setState({
      tokensList: [],
      popupMsg: getStatusMessage(error),
      openModal: true
    })
  }

  validateSearchedValues() {
    let link = ''
    let mark = false

    let search = {
      token: this.token.value.trim(),
      school: this.school.value.trim(),
      title: this.title.value.trim(),
      teacher: this.teacher.value.trim()
    }

    for (var key in search) {
      if (search[key]) {
        link += "&" + key + "=" + search[key]
        mark = true
      }
    }

    let url = mark ? "?" + link.substring(1, link.length) : ""
    return url
  }

  search() {
    const { userInfo } = this.props

    if (this.validateSearchedValues() && userInfo.role === userRoles.CUSTOMER_SUPPORT) {
      getSZShareTitletData(this.validateSearchedValues())
      .then(data => {
        if (data.status === 200) {
          // let formatedData = data.data.map(d => {return { ...d, creationDate: d.creationDate, endDate: formatDateTime(d.endDate)}})
          this.setState({
            tokensList: data.data,
            noResults: data.data.length > 0 ? false : true,
            oneFieldRequired: false,
            token: ''
            })
        }
        })
        .catch((err) => this.showError(err.response.status))
    }
    else if (!this.validateSearchedValues()) {
      this.setState({
        oneFieldRequired: true,
        noResults: false,
        tokensList: [],
        todos: []
      })
    }
  }

  onClickEnter({ key }) {
    if (key === 'Enter')
      this.search()
  }

  onclickCalendar() {
    this.setState(prevState => {
        return {
            isCalendarOpen: !prevState.isCalendarOpen
        }
    })
  }

  onChangeDate(e) {
    this.setState({
        date: formatDate(e, "DD/MM/YYYY"),
        selectedDate: e
    })
  }

  limitDate(days) {
    let date = new Date()
    return date.setDate(date.getDate() + days)
  }

  disableOperation(endDate) {
    const date = new Date(endDate)
    const today = new Date()
    return (date.getTime() < today.getTime())
  }

  handleInputChangeForUpdate = (event) => {
    const { name, value } = event.target

    const validRange = (value, min, max) => !!value && validator.isNumeric(value + '') && _.inRange(value, min, max)

    switch(name) {        
      case "increasement_percentage":
        this.setState((prevState) => {
          return {
            [name]: Number.parseInt(value),
            //global: {[name]: Number.parseInt(value)},
            updateErrors: {
              ...prevState.updateErrors,
              [name]: !validRange(Number.parseInt(value), 1, 101),
              withoutChanges: false
            }
          }
        })
        break
      case "different_devices":
        this.setState((prevState) => {
          return {
            [name]: Number.parseInt(value),
            //global: {[name]:  Number.parseInt(value)},
            updateErrors: {
              ...prevState.updateErrors,
              [name]: !validRange(Number.parseInt(value), 1, envSettings.STUDENTS_MAX_DEVICES + 1),
              withoutChanges: false
            }
          }
        })
        break
      default:
        this.setState({ [name]: value })
    }
  }

  modalUpdateSubmit = async () => {    
    const { previous, token } = this.state

    if(this.isValid()) {
      const dataToPick = [ 'increasement_percentage', 'different_devices' ]
      const data = _.pick(this.state, dataToPick)
      
      if(!_.isEqual(previous, data)) {
        // Different values
        if (token) {
          putSZTokenValues(data, token)
          .then((response) => {
            this.setState({ openModalUpdate: false
                          , updateErrors: { withoutChanges: false }
                          })
            })
            .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
        } else {
          putSZGlobalValues(data)
          .then((response) => {
            this.setState({ openModalUpdate: false
                          , updateErrors: { withoutChanges: false }
                          , previous_global: { increasement_percentage: data.increasement_percentage
                                             , different_devices: data.different_devices
                                      } 
                          })
            })
            .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
          }
      } else {
        this.setState({ updateErrors: { withoutChanges: true }})
      }
    }
  }

  modalBlockSubmit = async () => {    
    const { token, selectedDate } = this.state
    const data = Object.assign({}, {blockDate: formatDate(selectedDate, "YYYY-MM-DD")})

    if (token) {
      putSZBlockToken(data, token)
      .then((response) => {
        this.setState({ openModalBlock: false })
        })
        .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))
    } else {
      this.showError('400')  
    }  
  }

  handleUpdateToken = (token) => {
    getSZTokenValues(token)
    .then((response) => {
      this.setState({ openModalUpdate: true
        , token: token
        , header:`Change values for ${token}`
        , increasement_percentage: (!response.data.increasement_percentage ? 0 : response.data.increasement_percentage)
        , different_devices: (!response.data.different_devices ? 0 : response.data.different_devices)
        , updateErrors: { increasement_percentage: !response.data.increasement_percentage
                        , different_devices: !response.data.different_devices}
      })
      })
      .catch((err) => this.showError(err.code === 'ECONNABORTED' ? '408' :err.response.status))    
  }

  handleBlockToken = (token) => {
    this.setState({ openModalBlock: true
                  , token: token
                  , header:`Block token ${token}`                  
                })
  }

  handleRefresh = () => {
    this.refreshGlobalValues()
    if (this.state.token) this.search()
  }

  switchPage(data,token,title){
    this.setState({
      showStudentsSearch:data,
			token:token,
			title:title
		})
	}

  studentsSearch() { 
    const { userInfo, handleStudentZone, handlePanels } = this.props
    const { increasement_percentage, different_devices, updateErrors, header } = this.state
    return (
      <Fragment>
        <Container fluid>
          <div className="customer-user-screen-container">
            <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray">
              <Col xs="6" md="8"><h3 className="text-primary font-weight-bold mb-0">Student Area Search</h3></Col>
                <Col xs="6" md="4">
                  <div className="float-right">
                    <a href={`${envSettings.API_BASEURL}/admin/studentszone/excel`} target="_blank" rel="noopener noreferrer"><span className="glyphicon glyphicon-download-alt" aria-hidden="true"></span> Available schools and titles </a>
                  </div>
                </Col>
            </Row>
            <Form>
              <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray">
                <Col xs="12" sm="6" md="6" lg="3">
                  <FormGroup className="form-group">
                    <Label className="font-weight-bold" for="ID">Token</Label>
                    <Input type="text" size="100" name="id" id="ID" placeholder="" innerRef={el => this.token = el} onChange={this.handleInputChange} onKeyPress={this.onClickEnter} />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="6" md="6" lg="3">
                  <FormGroup className="form-group">
                    <Label className="font-weight-bold" for="name">School</Label>
                    <Input type="text" size="100" name="name" id="name" placeholder="" innerRef={el => this.school = el} onChange={this.handleInputChange} onKeyPress={this.onClickEnter} />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="6" md="6" lg="3">
                  <FormGroup className="form-group">
                    <Label className="font-weight-bold" for="firstSurname">Title</Label>
                    <Input type="text" size="100" name="surname1" id="firstSurname" placeholder="" innerRef={el => this.title = el} onChange={this.handleInputChange} onKeyPress={this.onClickEnter} />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="6" md="6" lg="2">
                  <FormGroup className="form-group">
                    <Label className="font-weight-bold" for="ID">Teacher</Label>
                    <Input type="text" size="100" name="id" id="ID" placeholder="" innerRef={el => this.teacher = el} onChange={this.handleInputChange} onKeyPress={this.onClickEnter} />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="6" md="12" lg="1">
                  <FormGroup className="form-group">
                    <div className="search-button">
                      <Button className="font-weight-normal" color="secondary" size="md" onClick={this.search}>Search</Button>
                    </div>
                  </FormGroup>
                </Col>
                {this.state.noResults && <Col xs="12"><span className="font-weight-bold text-danger">No results found</span></Col>}
                {this.state.oneFieldRequired && <Col xs="12"><span className="font-weight-bold text-danger">Please enter at least one field</span></Col>}
                                            
              </Row>
            </Form>

            {userInfo.role === userRoles.CUSTOMER_SUPPORT &&
              <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray small">                            
                <Col xs="12" sm="2" md="2" lg="1"><span className="font-weight-bold">Global Values</span></Col>                                
                <Col xs="12" sm="10" md="10" lg="11">
                  <Row>
                    <Col xs="12" sm="auto" lg="auto" key='1'><div><span className="font-weight-bold"> % Increase: {this.state.previous_global.increasement_percentage} </span></div></Col>
                    <Col xs="12" sm="auto" lg="auto" key='2'><div><span className="font-weight-bold"> Devices:  {this.state.previous_global.different_devices} </span></div></Col>
                    <Col xs="12" sm="2" md="2" lg="2"><div>
                      <Button className="font-weight-normal" color="secondary" size="sm" onClick={() => this.handleRefreshGlobals()}>Update values</Button></div>
                    </Col>
                    <Col xs="12" sm="2" md="2" lg="2"><div><Button className="font-weight-normal" color="secondary" size="sm" onClick={() => this.switchPage(false)}>Activity Log</Button></div></Col>
                    {/*    {this.state.countTeachers.length > 0 && this.state.countTeachers.map((data, index) => (
                            <Col xs="12" sm="auto" lg="auto" key={index}><div><span className="font-weight-bold"> {`${data.role} : ${data.count}`}  </span></div></Col>
                                     ))}*/}
                  </Row>
                </Col>
              </Row>
            }

            <Row className="gray small">
              <Col xs="12" sm="12" md="12" lg="12">
                <Table size = "sm" responsive={true}>
                  <thead>
                    <tr bgcolor="#5393d4">
                      <th>Token</th>
                      <th>School</th>
                      <th>Title</th>
                      <th>Teacher</th>
                      <th>% Increase</th>
                      <th>Devices</th>
                      <th>Create Date</th>
                      <th>End Date</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      {/*
                        <th className="text-underline" onClick={() => this.sortBy('createDate')}>Create Date <span className={this.state.sortByCreateDateAsc ? "glyphicon glyphicon-triangle-top" : "glyphicon glyphicon-triangle-bottom"} aria-hidden="true"></span></th>
                        <th className="text-underline" onClick={() => this.sortBy('lastLogin')}>Last Login <span className={this.state.sortByLastLoginAsc ? "glyphicon glyphicon-triangle-top" : "glyphicon glyphicon-triangle-bottom"} aria-hidden="true"></span></th>
                      */}
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.tokensList.length > 0 && this.state.tokensList.map((data, index) => (
                    <tr key={index}>
                      <td>{data.token}</td>
                      <td>{data.school}</td>
                      <td>{data.title}</td>
                      <td>{data.teacher}</td>
                      <td>{data.increasement_percentage}</td>
                      <td>{data.different_devices}</td>
                      <td>{formatDate(data.creationDate, "DD/MM/YYYY")}</td>
                      <td>{formatDate(data.endDate, "DD/MM/YYYY")}</td>
                      <td>
                        <div className="float-right">
                          <Button disabled={this.disableOperation(data.endDate)} title="Cancel Token" className="glyphicon glyphicon-remove btn-sm" aria-hidden="true"  onClick={() => this.handleBlockToken(data.token)}></Button>
                        </div>
                      </td>
                      <td>
                        <div className="float-right">
                          <Button disabled={this.disableOperation(data.endDate)} title="Update values" className="glyphicon glyphicon-upload btn-sm" aria-hidden="true" onClick={() => this.handleUpdateToken(data.token)}></Button>
                        </div>
                      </td>
                      <td>
                        <div className="float-right">
                          <Button title="Activity Log" className="glyphicon glyphicon-eye-open btn-sm" aria-hidden="true" onClick={()=>{this.switchPage(false,data.token,data.title)}}></Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </Col>
            </Row>                       
            <ModalAccept isOpen={this.state.openModal} toggle={this.toggle} header={'Alert'}>
              <div className="text-center font-weight-bold">{this.state.popupMsg}</div>
            </ModalAccept>
            <Modal2Buttons
              isOpen={ this.state.openModalUpdate }
              toggle={() => this.setState({ openModalUpdate: false })}
              header={header}
              primaryButtonLabel="Accept"
              secondaryButtonLabel="Cancel"
              primaryButtonOnClick={ () => this.modalUpdateSubmit() }
              secondaryButtonOnClick={ () => this.setState({ openModalUpdate: false 
                                                           , updateErrors: { withoutChanges: false }
                                                           })}>
              <StudentsZoneUpdate 
                increasement_percentage={increasement_percentage} 
                different_devices={different_devices} 
                onChange={this.handleInputChangeForUpdate}
                updateErrors={updateErrors}
                handleRefresh={this.handleRefresh}/>                             
            </Modal2Buttons>

            <Modal2Buttons
              isOpen={ this.state.openModalBlock }
              toggle={() => this.setState({ openModalBlock: false })}
              header={header}
              primaryButtonLabel="Accept"
              secondaryButtonLabel="Cancel"
              primaryButtonOnClick={ () => this.modalBlockSubmit() }
              secondaryButtonOnClick={ () => this.setState({ openModalBlock: false
                                                           , date: formatDate(new Date(), 'DD/MM/YYYY') 
                                                           , selectedDate: new Date()
                                                          }
                                                           )}>
              <StudentsZoneBlock
                isCalendarOpen={this.state.isCalendarOpen}
                selected={this.state.selectedDate}
                onInputClick={this.onclickCalendar}
                value={this.state.date}
                onChange={this.onChangeDate}
                handleRefresh={this.handleRefresh}
                minDate={this.limitDate(envSettings.STUDENTS_SUBSTRACT_DAYS)}
                maxDate={this.limitDate(envSettings.STUDENTS_ADD_DAYS)}/>
            </Modal2Buttons>

            <Row className="pt-2 pb-2 ml-1 mr-1">
			        <Col xs="12"sm="6"md="6">
                {/* <Button className="font-weight-normal"color="secondary"size="md" onClick={()=> handleStudentZone(false, false)}>Go back</Button> */}
                <Button className="font-weight-normal"color="secondary"size="md" onClick={handlePanels}>Go back</Button>
			        </Col>
			      </Row>            
          </div>
        </Container>
      </Fragment>
    )
  }

  render(){
		const{showStudentsSearch,token,title}=this.state
		if(showStudentsSearch)
      return this.studentsSearch() 
		else
			return <StudentLog token={token}title={title}back={(data)=>this.switchPage(data)}/>
	}
}

export default withUserInfo(StudentsZoneAdministrator)