import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { Container, Row, Col } from 'reactstrap'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import queryString from 'query-string'
import validator from 'validator'

import axiosConfig from '../../config/axios'
import { getLocale } from '../../language'
import envSettings from '../../globals/envSettings'
import CMSContent from '../CMSContent/CMSContent'
import { withContent } from '../../utils/hocs'
import { RecaptchaTerms } from '../Recaptcha/Recaptcha'

import ButtonPremium from '../ButtonPremium/ButtonPremium'
import ModalAccept from '../ModalAccept/ModalAccept'

import TitleJumbotron from '../Jumbotron/TitleJumbotron'
import Product from './components/Product'
import Resources from './components/Resources'
import Tabs from '../Tabs/Tabs'

const axiosClient = axios.create({ ...axiosConfig, headers: { 'Accept-Language': getLocale() } })

class StudentsZone extends Component {
  constructor(props) {
    super(props)

    this.state = {
      terms: true,
      title: undefined,
      products: [],
      errors: {
        concurrency: false,
        registration: false,
        invalidToken: false,
        other: false
      }
    }
  }

  ping(token) {
    if(!token) this.props.history.push('/error/')
    setTimeout(() => {
      axiosClient.post(`/student/${token}/ping`)
        .then(() => this.ping(token))
        .catch(() => {})
    }, envSettings.STUDENTS_PING_INTERVAL)
  }

  getContent = () => {
    const { match: { params: { token } } } = this.props

    this.ping(token)

    this.getTitle(token)
      .then(({ data, err }) => {
        const { products, title } = data || {}

        if(err) {
          if(err === 401001) {
            this.setState({
              errors: {
                registration: true,
                concurrency: false,
                invalidToken: false,
                other: false
              }
            })
          } else if(err === 401002) {
            this.setState({
              errors: {
                registration: false,
                concurrency: true,
                invalidToken: false,
                other: false
              }
            })
          } else if(err === 404) {
            this.setState({
              errors: {
                registration: false,
                concurrency: false,
                invalidToken: true,
                other: false
              }
            })
          } else {
            this.setState({
              errors: {
                registration: false,
                concurrency: false,
                invalidToken: false,
                other: true
              }
            })
          }
        } else {
          this.setState({
            products,
            title,
            terms: false,
            errors: {
              registration: false,
              concurrency: false,
              invalidToken: false,
              other: false
            }
          })
        }
      })
      .catch(() => this.setState({ error: 500 }))
  }

  getTitle = (token) => (
    new Promise(async (resolve) => {
      try {
        const recaptchaToken = await this.props.googleReCaptchaProps.executeRecaptcha('studentszone')
        axiosClient.get(`/student/${token}?token=${ recaptchaToken }`)
          .then(response => {
            if(response.data) return resolve({ data: response.data, err: undefined })
            else return resolve({ data: undefined, err: undefined })
          })
          .catch(err => {
            if(err.response && err.response.data && err.response.data.code && (err.response.data.code === 401001)) return resolve({ data: undefined, err: 401001 })
            if(err.response && err.response.data && err.response.data.code && (err.response.data.code === 401002)) return resolve({ data: undefined, err: 401002 })
            if(err.response && (err.response.status === 404)) return resolve({ data: undefined, err: 404 })
            return resolve({ data: undefined, err: (err.response && err.response.status) || 500 })
          })
      } catch(err) {
        return resolve({ data: undefined, err: 500})
      }
    })
  )

  accessProduct = (e, url) => {
    e.preventDefault()
    const newTab = window.open("", "_blank")
    newTab.document.write(`<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"/>`
    + `<div class="progress"><div class="progress-bar progress-bar-animated progress-bar-striped" style="width: 100%; background-color: #0294d3;" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">&nbsp;</div></div>`)
    return axiosClient.get(url)
      .then(response => {
        const productUrl = response && response.data && response.data.url ? response.data.url : null
        if (productUrl && validator.isURL(productUrl)) {
          newTab.location.href = productUrl
        } else {
          newTab.close()
          console.error('KO product access : Invalid url', productUrl)
          this.setState({ errors: { registration: false, concurrency: false, invalidToken: false, other: true }})
        }
      }).catch(error => {
        newTab.close()
        console.error('KO product access :', error)
        const code = error.response && error.response.data ?
          (
            error.response.data.code && [401001, 401002].includes(error.response.data.code) ?
              error.response.data.code
              :
              error.response.status ?  error.response.status : 500
          )
          :
          500
        this.setState({ errors: {
          registration: code === 401001, concurrency: code === 401002, invalidToken: [403, 404].includes(code), other: ![401001, 401002, 403, 404].includes(code)
        }})
    })
  }

  render() {
    const { content, locale } = this.props
    const { match: { params: { token, catid, subcatid } } } = this.props
    const { title, products, terms, errors } = this.state

    if(terms) {
      return (
        <Container>
          <Row><Col><CMSContent pageid="STUDENTZONE_STUD-AGREEMENT" language={locale} /></Col></Row>
          <Row><Col className="py-3" xs={{ size: 4, offset:4 }}><ButtonPremium primary onClick={ this.getContent }>{ content.student.accept_and_continue }</ButtonPremium></Col></Row>
          <Row><Col className="py-3 text-center"><RecaptchaTerms /></Col></Row>
          <ModalAccept
            isOpen={errors.concurrency}
            toggle={() => this.setState({ errors: { concurrency: false, registration: false, invalidToken: false, other: false } })}
          >
            <CMSContent pageid="STUDENTZONE_NUMBERUSERSEXCEEDED" language={locale} />
          </ModalAccept>
          <ModalAccept
            isOpen={errors.registration}
            toggle={() => this.setState({ errors: { concurrency: false, registration: false, invalidToken: false, other: false } })}
          >
            <CMSContent pageid="STUDENTZONE_DEVICESEXCEEDED" language={locale} />
          </ModalAccept>
          <ModalAccept
            isOpen={errors.invalidToken}
            toggle={() => this.setState({ errors: { concurrency: false, registration: false, invalidToken: false, other: false } })}
          >
            <CMSContent pageid="STUDENTZONE_INVALIDLINKSTUD" language={locale} />
          </ModalAccept>
          <ModalAccept
            isOpen={errors.other}
            toggle={() => this.setState({ errors: { concurrency: false, registration: false, invalidToken: false, other: false } })}
          >
            <CMSContent pageid="STUDENTZONE_ERRORLINKSTUD" language={locale} />
          </ModalAccept>
        </Container>
      )
    } else {
      const parsed = queryString.parse(window.location.search)
      const hasResources = !!(title && title.resources && title.resources.length)
      const hasProducts = !!(products && products.length)
      const checkCurrentView = () => {
        if(!parsed.initTab) {
          if(hasResources) {
            return StudentsZone.tabs.RESOURCES
          }
          if(hasProducts) {
            return StudentsZone.tabs.DIGITAL
          }
          return StudentsZone.tabs.UNSELECTED
        }
        if(parsed.initTab
          && parseInt(parsed.initTab) === StudentsZone.tabs.RESOURCES
          && hasResources) {
          return StudentsZone.tabs.RESOURCES
        }
        if(parsed.initTab
          && parseInt(parsed.initTab) === StudentsZone.tabs.DIGITAL
          && hasProducts) {
          return StudentsZone.tabs.DIGITAL
        }
        return StudentsZone.tabs.UNSELECTED
      }
      return (
        <Fragment>
          <TitleJumbotron
            id="titleJumbotron"
            title={ Object.assign({}, this.state.title.info, { name: `${this.state.title.info.name} - ${content.student.students_zone}` }) }
          />
          <Container fluid className="studentszone-container">
            { (hasResources || hasProducts)
              && (
                <Tabs
                  currentView={checkCurrentView()}
                  content={content}
                  hasResources={hasResources}
                  hasPlusPremium={hasProducts}
                  students
                />
              )
            }
            { (checkCurrentView() === StudentsZone.tabs.RESOURCES) &&
              <Row>
                <Col>
                  <Resources
                    title={title}
                    token={token}
                    catid={catid ? catid : 'all'}
                    subcatid={subcatid ? subcatid : 'all'} />
                </Col>
              </Row>
            }
            { (checkCurrentView() === StudentsZone.tabs.DIGITAL) ?
                products && products.length ?
                <Row>
                  { products.map(product => <Col key={product.uuid} xs="12" sm="6" md="3" lg="2"><Product data={product} token={token} open={this.accessProduct} /></Col>) }
                </Row>
                :
                <Row className="d-flex justify-content-center">
                  <CMSContent pageid="PREM_NO_RESULTS" language={locale} />
                </Row>
              : null
            }
            { (checkCurrentView() === StudentsZone.tabs.UNSELECTED) &&
              <Row className="py-5 justify-content-center font-weight-bold">{ content.student.no_content }</Row>
            }

            <ModalAccept
              isOpen={this.state.errors.concurrency}
              toggle={() => this.setState({ errors: { concurrency: false, registration: false, invalidToken: false, other: false }})}
            >
              <CMSContent pageid="STUDENTZONE_NUMBERUSERSEXCEEDED" language={locale} />
            </ModalAccept>
            <ModalAccept
              isOpen={this.state.errors.registration}
              toggle={() => this.setState({ errors: { concurrency: false, registration: false, invalidToken: false, other: false }})}
            >
              <CMSContent pageid="STUDENTZONE_DEVICESEXCEEDED" language={locale} />
            </ModalAccept>
            <ModalAccept
              isOpen={this.state.errors.invalidToken}
              toggle={() => this.setState({ errors: { concurrency: false, registration: false, invalidToken: false, other: false }})}
            >
              <CMSContent pageid="STUDENTZONE_INVALIDLINKSTUD" language={locale} />
            </ModalAccept>
            <ModalAccept
              isOpen={this.state.errors.other}
              toggle={() => this.setState({ errors: { concurrency: false, registration: false, invalidToken: false, other: false }})}
            >
              <CMSContent pageid="STUDENTZONE_ERRORLINKSTUD" language={locale} />
            </ModalAccept>

          </Container>
        </Fragment>
      )
    }
  }
}

StudentsZone.tabs = {
  UNSELECTED: -1,
  RESOURCES: 0,
  DIGITAL: 1
}

export default withContent(withGoogleReCaptcha(StudentsZone))
