import React,{Component}from'react'
import{getCustomerSupportUserLicenses}from"../../../services/administrator/request"
import{getStatusMessage}from"../../../utils/format"
import ModalAccept from'../../ModalAccept/ModalAccept'
import{Row,Col,Container,Form,Button,Table}from'reactstrap'
import'./formStyle.scss'
import envSettings from'../../../globals/envSettings'
import ROLES from'../../../globals/userRoles'
import{withUserInfo}from'../../../utils/hocs'
import queryString from 'query-string'
class UserLicenses extends Component{
  constructor(props){
    super(props)
    this.state={
      openModal:false
    }
    this.toggle=this.toggle.bind(this)
    this.showError=this.showError.bind(this)
  }
  componentDidMount(){
    if(this.props.userInfo.role&&this.props.userInfo.role===ROLES.CUSTOMER_SUPPORT){
      window.scrollTo(0,0)
      const{match:{params}}=this.props
      getCustomerSupportUserLicenses(params.userId)
      .then(result=>{if(result.status===200)this.setState({"licenses":result.data})})
      .catch((err)=>this.showError(err.code==='ECONNABORTED'?'408':err.response.status))
    }
  }
  showError(error){
    this.setState({
      popupMsg:getStatusMessage(error),
      openModal:true
    })
  }
  toggle(){
    this.setState({openModal:false},()=>{
      this.props.history.push('/admin');
    })
  }
  render(){
    if(!this.props.userInfo.role||(this.props.userInfo.role!==ROLES.CUSTOMER_SUPPORT)){
      this.props.history.push('/admin')
      return(<></>)
    }
    const{match:{params}}=this.props;
    const qparams=queryString.parse(decodeURIComponent(this.props.location.search));
    return(
      <React.Fragment>
        <Container>
          <Form>
            <div className='formContainer'>
              <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray">
                <Col xs="6"md="8"><h3 className="text-primary font-weight-bold mb-0">{qparams.name} {qparams.surname1} Licences</h3></Col>
	      </Row>
	      <Row className="pt-2 pb-2 ml-1 mr-1 border border-left-0 border-top-0 border-right-0 border-bottom gray small">
                <Col xs="12" sm="12" md="12" lg="12">
                  <Table responsive={true}>
                    <thead>
                      <tr bgcolor="#5393d4">
                        <th>Token</th>
                        <th>Title</th>
	                <th>Product</th>
                        <th>ISBN</th>
                        <th>Type</th>
                        <th>Expires</th>
                        <th>Total Teachers</th>
                        <th>Consumed Teachers</th>
                        <th>Total Students</th>
                        <th>Consumed Students</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.licenses&&this.state.licenses.map((data,index)=>(
                      <tr key={index}>
                        <td>{data.token}</td>
                        <td>{data.titulosim}</td>
                        <td>{data.tituloproducto}</td>
                        <td>{data.isbn}</td>
                        <td>{data.tipoproducto}</td>
                        <td>{data.expires}</td>
                        <td>{data.totalteachers}</td>
                        <td>{data.consumedteachers}</td>
                        <td>{data.totalstudents}</td>
                        <td>{data.consumedstudents}</td>
                      </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row className='pt-2 pb-2 ml-1 mr-1'>
                <Col xs="12"sm="6"md="6"lg="3">
                  <Button className="font-weight-normal"color="secondary"size="md"onClick={() =>{this.props.history.push(`/edit/customer/user/${qparams.rol}/${params.userId}`)}}>Go back</Button>
                </Col>
                <Col xs="12"sm="6"md="6"lg="3">
                {this.state.licenses&&this.state.licenses.length>0&&(
                  <Button className="font-weight-normal"color="secondary"size="md"onClick={() =>{window.open(`${envSettings.API_BASEURL}/admin/customer/user/${params.userId}/licences?excel`,"_blank")}}>Download</Button>)}
                </Col>
              </Row>
	    </div>
	  </Form>
          <ModalAccept isOpen={this.state.openModal}toggle={this.toggle}header={'Alert'}>
            <div className="text-center font-weight-bold">{this.state.popupMsg}</div>
          </ModalAccept>
        </Container>
      </React.Fragment>
    )
  }
}
export default withUserInfo(UserLicenses)
