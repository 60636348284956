import React, { Fragment } from 'react'
import { Modal as ModalRT, ModalHeader, ModalBody } from 'reactstrap'
import cx from 'classnames'
import { Colors } from '../Header/components/Colors'
import PropTypes from 'prop-types'
import CloseButton from '../Buttons/CloseButton'

const Modal = (props) => {
  const { isOpen, toggle, className, children, header, subtitle, size, external, rainbow = true } = props
  const colors = ['red', 'pink', 'yellow', 'green', 'aqua', 'blue']

  return (
    <ModalRT
      isOpen={isOpen}
      toggle={toggle}
      className={cx('modalPremium', className)}
      size={size}
      external={external}
    >
      <CloseButton
        className="position-absolute modalPremium-close"
        onClick={toggle}
      />
      {header &&
        <ModalHeader className="modalPremium__header text-center">
          {header}
          { subtitle && <Fragment><br /><span className='small'>{subtitle}</span></Fragment>}
        </ModalHeader>
      }
      {rainbow && <Colors colors={colors} />}
      <ModalBody>
        {children}
      </ModalBody>
    </ModalRT>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.string,
  header: PropTypes.string
}

export default Modal
