import React, { useState } from 'react'
import _ from 'lodash'
import { Container, Row, Col } from 'reactstrap'
import axios from 'axios'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

import ButtonCoupons from '../ButtonCoupons/ButtonCoupons'
import { withContent } from '../../utils/hocs'
import { parseToReactElement } from '../../utils/html'
import axiosConfig from '../../config/axios'
import { useScrollToTop } from '../../hooks'
import { addScrollToTop } from '../../utils/buttons'

const axiosClient = axios.create({ ...axiosConfig, timeout: 60000 })

const RedeemCouponSuccessful = (props) => {
  const { content, onRedeemCoupon, email } = props
  const [messageSent, setMessageSent] = useState(false)
  useScrollToTop()

  const sendEmail = async () => {
    const { googleReCaptchaProps } = props
    const token = await googleReCaptchaProps.executeRecaptcha('coupons')

    axiosClient.post('/coupons/send', { email, token })
      .then(() => setMessageSent(true))
      .catch(() => {})
  }

  const sendButton = <button className="aslink" onClick={sendEmail}>{content.coupons.redemption_success_email_send}</button>
  const replaceSendButtonParserOptions = {
    replace: ({ attribs }) => {
      if(!attribs) {
        return
      }
      if(attribs.id === 'sendButton') {
        return sendButton
      }
    }
  }

  if(messageSent) {
    return (
      <Container>
        <Row className="my-3"><Col><h1>{ _.get(content, 'coupons.redemption_forgot_success_title') }</h1></Col></Row>
        <Row className="my-3"><Col>{ parseToReactElement(_.get(content, 'coupons.redemption_forgot_success_body'), null, { ADD_ATTR: ['target'] }) }</Col></Row>
        <Row className="my-3">
          <Col>
            <ButtonCoupons
              primary
              onClick={() => setMessageSent(false)}
            >
              { _.get(content, 'coupons.redemption_forgot_success_button') }
            </ButtonCoupons>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container>
      <Row className="my-3"><Col><h1>{ content.coupons.redemption_success_title }</h1></Col></Row>
      <Row className="my-3"><Col>{ parseToReactElement(content.coupons.redemption_success_body, null, { ADD_ATTR: ['target'] }) }</Col></Row>
      <Row className="my-3"><Col><strong>{ email }</strong></Col></Row>
      <Row className="my-3"><Col><h1>{ content.coupons.redemption_success_missingmail_title }</h1></Col></Row>
      <Row className="my-3"><Col>{ parseToReactElement(content.coupons.redemption_success_missingmail_body, replaceSendButtonParserOptions, { ADD_ATTR: ['target'] }) }</Col></Row>
      <Row className="my-3"><Col><h1>{ content.coupons.redemption_success_redeemanother_title }</h1></Col></Row>
      <Row className="my-3"><Col>{ parseToReactElement(content.coupons.redemption_success_redeemanother_body, null, { ADD_ATTR: ['target'] }) }</Col></Row>
      <Row className="my-3">
        <Col>
          <ButtonCoupons
            primary
            onClick={addScrollToTop(onRedeemCoupon)}
          >
            { content.coupons.redeem_another_coupon_button }
          </ButtonCoupons>
        </Col>
      </Row>
      <Row className="my-3"><Col className="text-center">{ parseToReactElement(content.coupons.redemption_success_survey, null, null) }</Col></Row>
    </Container>
  )
}

export default withGoogleReCaptcha(withContent(RedeemCouponSuccessful))
